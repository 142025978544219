import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Mouse from '@material-ui/icons/Mouse';
import TouchApp from '@material-ui/icons/TouchApp';
import { ModalPopups } from '@enums';
import {
	signature,
	app,
} from '@redux';
import {
	Modal,
	Button,
} from '@components';
import { cabinetLog } from '@global';
import { SignatureArea } from './components';

const styles = theme => ({
	body: {
		marginTop: '24px',
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	button: {
		marginTop: '32px',
	},
	title: {
		...theme.typography.style.subheading,
		color: theme.typography.color.primary,
	},
	instructionsContainer: {
		display: 'flex',
		margin: '25px 0 15px',
	},
	instructionsText: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	icon: {
		marginRight: '10px',
	},
	formError: {
		...theme.typography.style.body,
		color: theme.typography.color.red,
		textAlign: 'left',
		marginTop: '15px',
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		saveSignatureRequest: (account, image, guid) =>
			dispatch(signature.actions.saveSignatureRequest(account, image, guid)),
	},
});

const mapStateToProps = state => ({
	resolution: app.selectors.resolution(state),
	openModalData: signature.selectors.openModalData(state),
	submitting: signature.selectors.submitting(state),
});

class CreateSignatureModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isEmpty: false,
		};
		this.timerId = null;
		this.signatureAreaInstance = null;
		this.submit = this.submit.bind(this);
	}

	componentWillUnmount() {
		if (!this.timerId) {
			clearTimeout(this.timerId);
		}
	}

	submit() {
		if (this.signatureAreaInstance.isEmpty()) {
			cabinetLog('isEmpty');
			const self = this;
			if (!this.timerId) {
				this.setState({ isEmpty: true });
				this.timerId = setTimeout(() => {
					self.setState({ isEmpty: false });
					self.timerId = null;
				}, 3000);
			}
		} else {
			const { openModalData: { account, guid } } = this.props;
			const image = this.signatureAreaInstance.getImage();
			this.props.actions.saveSignatureRequest(account, image, guid);
		}
	}

	render() {
		const {
			onClose,
			classes,
			resolution,
			submitting,
		} = this.props;

		return (
			<Modal
				title="Create Signature"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CREATE_SIGNATURE}`}
			>
				<div className={classes.body}>
					<div className={classes.title}>
						Create signature for <strong>{this.props.openModalData.personFullName}</strong>
					</div>
					{(resolution <= app.CONFIG.RESOLUTIONS_ENUM.sm) &&
					<div className={classes.instructionsContainer}>
						<div className={classes.icon}>
							<TouchApp />
						</div>

						<div className={classes.instructionsText}>
							Draw signature with any finger you have and you want
						</div>
					</div>
					}
					{(resolution > app.CONFIG.RESOLUTIONS_ENUM.sm) &&
					<div className={classes.instructionsContainer}>
						<div className={classes.icon}>
							<Mouse />
						</div>
						<div className={classes.instructionsText}>
							Draw signature with mouse or trackpad in field below
						</div>
					</div>
					}
					<SignatureArea
						onRef={(ref) => { this.signatureAreaInstance = ref; }}
					/>
					<div className={classes.formError}>
						{this.state.isEmpty ? 'No Signature' : ''}
					</div>
					<div className={classes.button}>
						<Button
							color="primary"
							variant="outlined"
							fullWidth
							onClick={this.submit}
						>
							{submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								: 'Submit Signature'
							}
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
}

CreateSignatureModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CreateSignatureModal);
