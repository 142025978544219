import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { acatDeposit, userAccounts } from '@redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import {
	FormSelect,
	FormInput,
	Divider,
	Button,
	LimeLogo,
	SuccessMessage,
} from '@components';
import { ModalPopups, CloseAccountFlow } from '@enums';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '10px',
	},
	item: {
		marginTop: '30px',
		padding: '0 24px',
	},
	divider: {
		marginTop: '30px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
});


const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		TradeCode: ownProps.tradeAccount.tradeCode,
		ClearerFirm: ownProps.tradeAccount.clearerFirm,
	},
	closeAccountFlow: acatDeposit.selectors.modals(state)[ModalPopups.CLOSE_ACCOUNT].flow,
	currentAccountData: userAccounts.selectors.currentAccountData(state),
});


class CloseAccountForm extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.TradeCode) {
			errors.TradeCode = 'required';
		}

		if (!values.ClearerFirm) {
			errors.ClearerFirm = 'required';
		}

		if (!values.ClosePrompt) {
			errors.ClosePrompt = 'required';
		}

		return errors;
	}

	render() {
		const {
			classes,
			onClose,
			tradeAccount,
			handleSubmit,
			submitSucceeded,
			closeAccountFlow,
			currentAccountData: {
				baseParams,
			},
			...props
		} = this.props;

		let buttonText;
		if (!submitSucceeded &&
			(((baseParams && baseParams.CashCurrentValue <= 0) && closeAccountFlow === CloseAccountFlow.PROFILE) ||
				closeAccountFlow === CloseAccountFlow.CASH_MANAGMENT)) {
			buttonText = 'Close account';
		} else {
			buttonText = 'Next';
		}

		return (
			<div>
				{
					submitSucceeded ? <SuccessMessage
						onClick={onClose}
						text={
							<p>
								Thank you for your time!<br />
								Request Received. Please allow at least 24 hours for processing and email confirmation.
							</p>
						}
						buttonText="Back To Account"
					/> :
						(
							<form
								onSubmit={handleSubmit(acatDeposit.actions.closeAccountFormSubmitHandler)}
								className={classes.root}
							>
								<div className={classes.accountContainer}>
									<div className={classes.row}>
										<div className={classes.logo}>
											<LimeLogo />
										</div>
										<Field component={FormSelect} name="TradeCode" label="From Trade Account" disabled>
											<MenuItem value={tradeAccount && tradeAccount.tradeCode}>
												<div className={classes.inputContainer}>
													<div className={classes.account}>{tradeAccount && tradeAccount.tradeCode}</div>
												</div>
											</MenuItem>
										</Field>
									</div>
								</div>
								<div className={classes.divider}>
									<Divider />
								</div>
								<p>
									We are sad to see you leave but to better guide you through the process,
									please answer he following questions.
								</p>
								<div className={classes.inputItem}>
									<Field
										name="ClosePrompt"
										type="text"
										component={FormInput}
										placeholder="Close reason"
										additionalText="What prompted you to close your Lime Trading account? Please provide a brief explanation."
									/>
								</div>
								<div className={classes.inputItem}>
									<Field
										name="Suggestions"
										type="text"
										component={FormInput}
										placeholder="Suggestions"
										additionalText="Are there any services that you feel should be added? If so, what are they?"
									/>
								</div>
								<div className={classes.button}>
									<Button
										fullWidth
										type="submit"
										variant="contained"
										color="primary"
										disabled={props.invalid || props.submitting}
									>
										{props.submitting ?
											<CircularProgress
												style={{ color: '#1a96ff' }}
												size={18}
											/>
											: buttonText
										}
									</Button>
								</div>
							</form>
						)
				}
			</div>);
	}
}

CloseAccountForm.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	tradeAccount: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	closeAccountFlow: PropTypes.oneOf([CloseAccountFlow.PROFILE, CloseAccountFlow.CASH_MANAGMENT]).isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: 'closeAccountForm',
		validate: CloseAccountForm.validate,
	}),
)(CloseAccountForm);
