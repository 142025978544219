import React from 'react';
import NumberFormat from 'react-number-format';

function DigitsOnlyField(props) {
	const {
		inputRef,
		input: {
			onChange,
			onFocus,
			onBlur,
		},
		meta: {
			touched,
			error,
			warning,
		},
		...restProps
	} = props;

	const styles = {
		color: 'red',
		marginTop: '5px',
		textAlign: 'center',
	};

	return (
		<div>
			<NumberFormat
				{...restProps}
				getInputRef={inputRef}
				onValueChange={(values) => {
					onChange(values.value || '');
				}}
				onFocus={onFocus}
				onBlur={onBlur}
				format="### ###"
			/>
			{ touched && (error || warning) &&
			<div style={styles}>{ error || warning }</div>
			}
		</div>
	);
}

export default DigitsOnlyField;
