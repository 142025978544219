const NetWorthValues = {
	UNDER_50_000: 'Under $50,000',
	UNDER_150_000: '$50,000 - $149,999',
	UNDER_500_000: '$150,000 - $499,999',
	UNDER_1_000_000: '$500,000 - $999,999',
	UNDER_5_000_000: '$1,000,000 - $4,999,999',
	OVER_5_000_000: 'Over $5,000,000',
};

const NetWorthList = [
	{
		value: NetWorthValues.UNDER_50_000,
		text: NetWorthValues.UNDER_50_000,
		level: 1,
	},
	{
		value: NetWorthValues.UNDER_150_000,
		text: NetWorthValues.UNDER_150_000,
		level: 2,
	},
	{
		value: NetWorthValues.UNDER_500_000,
		text: NetWorthValues.UNDER_500_000,
		level: 3,
	},
	{
		value: NetWorthValues.UNDER_1_000_000,
		text: NetWorthValues.UNDER_1_000_000,
		level: 4,
	},
	{
		value: NetWorthValues.UNDER_5_000_000,
		text: NetWorthValues.UNDER_5_000_000,
		level: 5,
	},
	{
		value: NetWorthValues.OVER_5_000_000,
		text: NetWorthValues.OVER_5_000_000,
		level: 6,
	},
];

const AnnualIncomeInstitutionalList = [
	{ value: 'Under $1,000,000', text: 'Under $1,000,000', level: 1 },
	{ value: '$1,000,000 - $2,999,999', text: '$1,000,000 - $2,999,999', level: 2 },
	{ value: '$3,000,000 - $4,999,999', text: '$3,000,000 - $4,999,999', level: 3 },
	{ value: '$5,000,000 - $9,999,999', text: '$5,000,000 - $9,999,999', level: 4 },
	{ value: '$10,000,000 - $24,999,999', text: '$10,000,000 - $24,999,999', level: 5 },
	{ value: '$25,000,000 - $49,999,999', text: '$25,000,000 - $49,999,999', level: 6 },
	{ value: '$50,000,000 - $99,999,999', text: '$50,000,000 - $99,999,999', level: 7 },
	{ value: '$100,000,000+', text: '$100,000,000+', level: 8 },
];

const AnnualIncomeList = [
	{ value: 'Under $25,000', text: 'Under $25,000', level: 1 },
	{ value: '$25,000 - $49,999', text: '$25,000 - $49,999', level: 2 },
	{ value: '$50,000 - $74,999', text: '$50,000 - $74,999', level: 3 },
	{ value: '$75,000 - $124,999', text: '$75,000 - $124,999', level: 4 },
	{ value: '$125,000 - $199,999', text: '$125,000 - $199,999', level: 5 },
	{ value: '$200,000 - $349,999', text: '$200,000 - $349,999', level: 6 },
	{ value: '$350,000 - $499,999', text: '$350,000 - $499,999', level: 7 },
	{ value: '$500,000 - $749,999', text: '$500,000 - $749,999', level: 8 },
	{ value: '$750,000 - $999,999', text: '$750,000 - $999,999', level: 9 },
	{ value: '$1,000,000+', text: '$1,000,000+', level: 10 },
];

const TaxBracketList = [
	{ value: '0 - 15%', text: '0 - 15%', level: 1 },
	{ value: '16 - 25%', text: '16 - 25%', level: 2 },
	{ value: '26 - 30%', text: '26 - 30%', level: 3 },
	{ value: '31 - 35%', text: '31 - 35%', level: 4 },
	{ value: 'Over 35%', text: 'Over 35%', level: 5 },
];

export { NetWorthValues, NetWorthList, AnnualIncomeInstitutionalList, AnnualIncomeList, TaxBracketList };
