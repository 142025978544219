import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { ValidationMessageMode } from '@enums';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	label: {
		top: '10px',
		left: '24px',
		...theme.typography.style.title,
	},
	additionalText: {
		padding: '0px 24px',
		color: theme.typography.color.secondary,
		...theme.typography.style.body,

	},
	errorText: {
		padding: '0px 24px',
		color: theme.typography.color.red,
		...theme.typography.style.body,

	},
});
class InputFormControl extends React.Component {
	render() {
		const {
			name,
			placeholder,
			fullWidth,
			error,
			additionalText,
			showLabel,
			classes,
			helpertext,
			validationMessageMode,
			children,
			qaAttribute,
		} = this.props;

		return (
			<FormControl error={error} fullWidth={fullWidth}>
				{showLabel &&
					<InputLabel
						classes={{ formControl: classes.label }}
						htmlFor={name}
						{...qaAttribute}
					>
						{placeholder}
					</InputLabel>
				}
				{children}

				{additionalText && !(error && helpertext && validationMessageMode === ValidationMessageMode.INSTEAD_OF_ADDITIONAL) &&
					<FormHelperText classes={{ root: classes.additionalText }}>
						{additionalText}
					</FormHelperText>
				}
				{error && validationMessageMode !== ValidationMessageMode.NONE &&
					<FormHelperText classes={{ root: classes.errorText }}>
						{helpertext}
					</FormHelperText>
				}
			</FormControl>
		);
	}
}

InputFormControl.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	showLabel: PropTypes.bool,
	additionalText: PropTypes.string,
	/**
	 * If `true`, the input will be focused during the first mount.
	 */
	autoFocus: PropTypes.bool,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object,
	/**
	 * The default input value.
	 */
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * If `true`, the input will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * End `InputAdornment` for this component.
	 */
	endAdornment: PropTypes.node,
	/**
	 * If `true`, the input will indicate an error.
	 */
	error: PropTypes.bool,
	/**
	 * If `true`, the input will take up the full width of its container.
	 */
	fullWidth: PropTypes.bool,
	/**
	 * Текст ошибки валидации, отображаемый при props.error == true
	 */
	helpertext: PropTypes.string,
	/**
	 * The id of the `input` element.
	 */
	id: PropTypes.string,
	/**
	 * The component used for the native input.
	 * Either a string to use a DOM element or a component.
	 */
	inputComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	/**
	 * Properties applied to the `input` element.
	 */
	inputProps: PropTypes.object,
	/**
	 * Use that property to pass a ref callback to the native input component.
	 */
	inputRef: PropTypes.func,
	/**
	 * If `true`, a textarea element will be rendered.
	 */
	multiline: PropTypes.bool,
	/**
	 * Name attribute of the `input` element.
	 */
	name: PropTypes.string,
	/**
	 * Callback fired when the value is changed.
	 */
	onChange: PropTypes.func,
	/**
	 * The short hint displayed in the input before the user enters a value.
	 */
	placeholder: PropTypes.string,
	/**
	 * Number of rows to display when multiline option is set to true.
	 */
	rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Maximum number of rows to display when multiline option is set to true.
	 */
	rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * Start `InputAdornment` for this component.
	 */
	startAdornment: PropTypes.node,
	/**
	 * Type of the input element. It should be a valid HTML5 input type.
	 */
	type: PropTypes.string,
	/**
	 * Разрешает выводить сообщение валидации.
	 */
	validationMessageMode: PropTypes.oneOf([
		ValidationMessageMode.NONE,
		ValidationMessageMode.INSTEAD_OF_ADDITIONAL,
		ValidationMessageMode.AFTER_ADDITIONAL,
	]),
	/**
	 * The input value, required for a controlled component.
	 */
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	]),
	InputLabelProps: PropTypes.object,
	qaAttribute: PropTypes.object,
};
InputFormControl.defaultProps = {
	showLabel: true,
	additionalText: undefined,
	autoFocus: false,
	classes: {},
	defaultValue: undefined,
	disabled: false,
	endAdornment: null,
	error: false,
	fullWidth: true,
	helpertext: undefined,
	id: null,
	inputComponent: 'input',
	inputProps: {},
	InputLabelProps: {},
	inputRef: null,
	multiline: false,
	name: null,
	onChange: null,
	placeholder: '',
	rows: 4,
	rowsMax: 10,
	startAdornment: null,
	type: 'text',
	validationMessageMode: 'none',
	value: undefined,
	qaAttribute: {},
};

export default withStyles(styles)(InputFormControl);
