import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { profile } from '@redux';
import {
	Modal,
	A,
	InfoMessage,
} from '@components';
import { ModalPopups } from '@enums';

const styles = {
};
const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		hideProfileModal: modalId => dispatch(profile.actions.hideProfileModal(modalId)),
	},
});

class ConfirmProfileModal extends React.Component {
	handleConfirm = () => {
		const { actions } = this.props;
		actions.setCurrentPage('/dashboard/ConfirmPersonalInfo');
		actions.hideProfileModal(ModalPopups.CONFIRM_PROFILE);
	}
	render() {
		const {
			classes,
			onClose,
		} = this.props;

		return (
			<Modal
				onClose={onClose}
				title="Personal Info Update"
				className={`QA-${ModalPopups.CONFIRM_PROFILE}`}
			>
				<InfoMessage
					title="Personal Info Update"
					onClick={this.handleConfirm}
					text={
						<p>
							Please review and confirm the accuracy of personal info.
						</p>
					}
					buttonText="Confirm"
				/>
			</Modal>
		);
	}
}

ConfirmProfileModal.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(ConfirmProfileModal);
