import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'absolute',
		left: props => `calc(50% - ${props.size / 2}px)`,
		top: props => `calc(50% - ${props.size / 2}px)`,
	},
}));


const FullScreenProgressContainer = (props) => {
	const {
		submitting,
		size,
		timeShift = 300, // Отображать крутилку не сразу, а через N милисекунд, чтобы не было заметно моргание
	} = props;

	const [timeShiftFinished, setTimeShiftFinished] = useState(false);
	useEffect(() => {
		let tm = window.setTimeout(() => {
			setTimeShiftFinished(true);
			window.clearTimeout(tm);
			tm = null;
		}, timeShift);
		return () => {
			if (tm !== null) {
				window.clearTimeout(tm);
				tm = null;
			}
		};
	}, []);

	const classes = useStyles(props);
	if (submitting) {
		if (!timeShiftFinished) {
			// пустой тег, чтобы на странице html обработчик не посчитал что приложение не загрузилось
			return (<div></div>);
		}
		return (
			<div className={classes.root}>
				<CircularProgress
					color="primary"
					size={size}
				/>
			</div>
		);
	}

	return props.children;
};

FullScreenProgressContainer.defaultProps = {
	display: 'center',
	size: 58,
};

FullScreenProgressContainer.propTypes = {
	/**
	 * main event: display progress if true.
	 */
	submitting: PropTypes.bool.isRequired,

	size: PropTypes.number,
};

export default FullScreenProgressContainer;
