import React from 'react';
import { change, formValueSelector, reduxForm, Field, touch } from 'redux-form';
import { push } from 'connected-react-router';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { profile, signature, userAccounts } from '@redux';
import { 
	Button, 
	ChangeLink,
	Divider, 
	FormRadioGroup,
	LimeLogo,
	Select, 
	SvgIcon, 
	Radio,
} from '@components';
import { connect } from 'react-redux';
import UpgradeAccountFormContent from './UpgradeAccountFormContent';
// import j2tlogo from './j2t.png';


const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	divider: {
		marginTop: '30px',
	},
	inputSelectItem: {
		marginTop: '10px',
		paddingLeft: '32px',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	justify: {
		textAlign: 'justify',
	},
	additionalText: {
		color: theme.typography.color.secondary,
		...theme.typography.style.body,
	},
	error: {
		marginLeft: '40px',
		display: 'block',
	},
});


function valueOrEmpty(val) {
	if (val) return val;
	return '';
}

const UPGRADE_ACCOUNT_FORM_ID = 'UpgradeAccountForm';
const formSelector = formValueSelector(UPGRADE_ACCOUNT_FORM_ID);


const mapStateToProps = (state) => {
	const currentAccountData = userAccounts.selectors.currentAccountData(state);
	let upgradeMarginTypeData = userAccounts.selectors.upgradeMarginTypeData(state);
	if (!upgradeMarginTypeData.tradeAccount) {
		upgradeMarginTypeData = {
			tradeAccount: userAccounts.selectors.currentTradeAccount(state),
			marginParams: currentAccountData.baseParams.MarginOptionParameters,
		};
	}
	return {
		initialValues: {
			TradeCode: upgradeMarginTypeData.tradeAccount.tradeCode,
			Clearer: upgradeMarginTypeData.tradeAccount.clearerFirm,
			NewMarginType: valueOrEmpty(upgradeMarginTypeData.marginParams.MarginType),
		},
		selectedMarginType: formSelector(state, 'NewMarginType'),
		upgradeMarginTypeData,
		currentAccountData,
		profile: profile.selectors.profile(state),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		setHasMarginAgree: () => dispatch(change(UPGRADE_ACCOUNT_FORM_ID, 'HasMarginAgree', true)),
		setHasDayTraderAgree: () => dispatch(change(UPGRADE_ACCOUNT_FORM_ID, 'HasDayTraderAgree', true)),
		setUpdateInvestmentProfile: value => dispatch(change(UPGRADE_ACCOUNT_FORM_ID, 'UpdatedInvestmentProfile', value)),
		showChangeInvestmentProfileModal: () => {
			const changeAction = profile.actions.showChangeInvestmentProfileModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		setFormField: (fieldName, value = null) => dispatch(change(UPGRADE_ACCOUNT_FORM_ID, fieldName, value)),
		reduxFormTouch: fieldname => dispatch(touch(UPGRADE_ACCOUNT_FORM_ID, fieldname)),
	},
});

const allAccTypes = [
	{
		value: 'Cash',
		text: 'Cash',
		level: 0,
		additionalText: ['T+1 Settlement', 'Trade on Settled Cash'],
	},
	{
		value: 'MarginX1',
		text: 'MarginX1',
		level: 1,
		disabled: true,
		additionalText: ['T+0 settlement', '1:1 buying power', 'No more than 3 day trades within 5 business days'],
	},
	{
		value: 'MarginX2',
		text: 'Margin',
		level: 2,
		additionalText: ['Trade with 2:1 Buying Power', 'No more than 3 day trades in the previous 5 trading days'],
	},
	{
		value: 'DayTrader',
		text: 'DayTrader',
		level: 3,
		additionalText: ['Trade with 4:1 Buying Power intraday', '2:1 Buying Power overnight', 'No limit on day trades'],
	},
];
class UpgradeAccountForm extends React.Component {
	static validate(values, props) {
		const errors = {};

		if (!values.NewMarginType) {
			errors.NewMarginType = 'required';
		}
		if (!values.MustUpdateProfile) {
			errors.MustUpdateProfile = 'required';
		}
		if (!values.HasMarginAgree) {
			errors.HasMarginAgree = 'required';
		}
		if (!values.HasDayTraderAgree) {
			errors.HasDayTraderAgree = 'required';
		}

		const errs = UpgradeAccountForm.marginTypeValidate(values.NewMarginType, props);

		if (errs && errs.length > 0) {
			errors.NewMarginType =
			(
				<span className={props.classes.error}>Minimum requirements are not met. They need to upgrade:<br /><br />
					{errs.length > 1
						? <ul>{errs.map((err, index) => <li key={`margintype_error_${index}`}>{err}</li>)}</ul>
						: errs[0]
					}
				</span>
			);
		}
		return errors;
	}
	static marginTypeValidate(newMarginType, props) {
		const result = [];
		const {
			showChangeInvestmentProfileModal,
			// showUpgradeAccountModal,
			setCurrentPage,
		} = props.actions;

		const {
			// currentTradeAccount,
			// currentAccountData,
			profile: {
				InvestmentProfile,
				PendingOrders,
			},
			upgradeMarginTypeData: {
				marginParams,
			},
			currentAccountData: {
				portfolio,
			},
			currentAccountData: { baseParams },
		} = props;

		// const showUpgradeAccountForm = () => showUpgradeAccountModal(currentTradeAccount, currentAccountData.baseParams.MarginOptionParameters);
		const goToDashboard = () => setCurrentPage('/dashboard');

		// const marginType = baseParams && baseParams.MarginOptionParameters && baseParams.MarginOptionParameters.MarginType;

		const isChangeInvestmentProfileInactive = PendingOrders && PendingOrders.InvestmentProfile;
		const selectedLevel = allAccTypes.find(item => item.value === newMarginType);
		const curLevel = allAccTypes.find(item => item.value === marginParams.MarginType);
		const noChanges = !newMarginType || marginParams.MarginType === newMarginType;
		const isUpgrade = selectedLevel && curLevel.level < selectedLevel.level;
		const isDowngrade = !isUpgrade;
		const noCashMoney = (selectedLevel && selectedLevel.level < 2 && !noChanges && isDowngrade
			&& baseParams && baseParams.CashCurrentValue < 0);
		const hasShortPositions = !noChanges && isDowngrade && portfolio && portfolio.Positions &&
			portfolio.Positions.filter(it => it.Quantity < 0).length > 0;

		if (newMarginType === 'Cash') {
			if (noCashMoney) {
				result.push('In order to downgrade the margin type of the account please ensure you\'re not in use of any borrowing means');
			}
			if (hasShortPositions) {
				result.push('In order to downgrade the margin type of the account please ensure your current portfolio contains no short positions.');
			}
		}
		if (!noChanges && selectedLevel.level > 0) {
			if (!InvestmentProfile || !InvestmentProfile.RiskTolerance || InvestmentProfile.RiskTolerance !== 'Speculative') {
				result.push(this.createLink(
					'Risk Tolerance: Speculative',
					showChangeInvestmentProfileModal,
					isChangeInvestmentProfileInactive,
				));
			}
			if (!InvestmentProfile || !InvestmentProfile.Goal || InvestmentProfile.Goal !== 'Speculation') {
				result.push(this.createLink(
					'Investment Objectives: Speculation',
					showChangeInvestmentProfileModal,
					isChangeInvestmentProfileInactive,
				));
			}
			if (!InvestmentProfile || !InvestmentProfile.Liquid || InvestmentProfile.Liquid === 'Under $50,000') {
				result.push(this.createLink(
					'Liquid net worth: $50,000 or more',
					showChangeInvestmentProfileModal,
					isChangeInvestmentProfileInactive,
				));
			}
			if (newMarginType === 'DayTrader' && baseParams && baseParams.EquityValue < 25000) {
				result.push(this.createLink('Minimum Account Equity: $25,000', goToDashboard));
			}
		}
		return result;
	}
	static createLink(title, onClickFunc, isInactive) {
		return <span>{ title }<br /><ChangeLink onClick={onClickFunc} havePendingOrder={isInactive} /></span>;
	}

	onChangeMarginType = () => {
		const { actions } = this.props;
		actions.reduxFormTouch('NewMarginType');
	}

	render() {
		const {
			classes,
			actions,
			invalid,
			submitting,
			handleSubmit,
			selectedMarginType,
			upgradeMarginTypeData: {
				tradeAccount,
				marginParams,
			},
			currentAccountData: {
				baseParams,
				portfolio,
			},
		} = this.props;

		const selectedLevel = allAccTypes.find(item => item.value === selectedMarginType);
		const curLevel = allAccTypes.find(item => item.value === marginParams.MarginType);
		const noChanges = marginParams.MarginType === selectedMarginType;
		const isUpgrade = selectedLevel && curLevel.level < selectedLevel.level;
		const isDowngrade = !isUpgrade;

		const noEnoughMoney = (selectedMarginType === 'DayTrader' && !noChanges && baseParams && baseParams.EquityValue < 25000);
		const noCashMoney = (selectedLevel && selectedLevel.level < 2 && !noChanges && isDowngrade
			&& baseParams && baseParams.CashCurrentValue < 0);

		const hasShortPositions = !noChanges && isDowngrade && portfolio && portfolio.Positions &&
			portfolio.Positions.filter(it => it.Quantity < 0).length > 0;
		const isMarginAgree = !noChanges && isUpgrade && marginParams.MarginType === 'Cash';
		const isDayTraderAgree = !noChanges && isUpgrade && selectedLevel.level > 2;
		return (
			<div className={classes.root}>
				<div className={classes.row}>
					<div className={classes.logo}>
						<LimeLogo />
					</div>
					<Select value={tradeAccount && tradeAccount.tradeCode} label="Trade Account" disabled>
						<MenuItem value={tradeAccount && tradeAccount.tradeCode}>
							<div className={classes.inputContainer}>
								<div className={classes.account}>{tradeAccount && tradeAccount.tradeCode}</div>
							</div>
						</MenuItem>
					</Select>
				</div>

				<div className={classes.divider}>
					<Divider />
				</div>

				<form
					onSubmit={handleSubmit(userAccounts.actions.upgradeAccountFormSubmitHandler)}
					className={classes.root}
				>
					<p className={classes.justify}>
						You are about to update account&apos;s margin type:
					</p>
					<div className={classes.inputSelectItem}>
						<Field
							fullWidth
							name="NewMarginType"
							orientation="vertical"
							component={FormRadioGroup}
							onChange={this.onChangeMarginType}
						>
							{allAccTypes.filter(item => !item.disabled).map(item => (
								<Radio
									classes={{ label: classes.accItem, root: classes.item }}
									key={item.value}
									value={item.value}
									label={
										<div className={classes.labelText}>
											{item.text}
											<ul className={classes.additionalText}>
												{item.additionalText.map((txt, index) => <li key={`adtext_${index}`}>{txt}</li>)}
											</ul>
										</div>
									}
									disabled={item.disabled}
								/>
							))}
						</Field>
						<FormHelperText classes={{ root: classes.additionalText }}>
							Please note that it might take up to 24 hours.
						</FormHelperText>
					</div>
					<div className={classes.divider} />

					<UpgradeAccountFormContent
						isMarginAgree={!!isMarginAgree}
						isDayTraderAgree={!!isDayTraderAgree}
						clearerFirm={tradeAccount.clearerFirm}
						actions={{
							setHasMarginAgree: actions.setHasMarginAgree,
							setHasDayTraderAgree: actions.setHasDayTraderAgree,
						}}
					/>

					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={invalid || submitting || noChanges || noEnoughMoney || noCashMoney || hasShortPositions}
						>
							{submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								: 'Apply'
							}
						</Button>
					</div>

				</form>
			</div>
		);
	}
}

UpgradeAccountForm.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,

	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	selectedMarginType: PropTypes.string,

	upgradeMarginTypeData: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
};

UpgradeAccountForm.defaultProps = {
	selectedMarginType: null,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: UPGRADE_ACCOUNT_FORM_ID,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		validate: UpgradeAccountForm.validate,
	}),
)(UpgradeAccountForm);
