import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import { toUsaFormat } from '@global';
import moment from 'moment';

const styles = theme => ({
	root: {
		'box-shadow': '0 1px 2px 0 rgba(61, 65, 71, 0.12)',
		padding: '13px 10px',
		width: '160px',
		'background-color': 'white',
		height: '215px',
		'&:hover': {
			backgroundColor: '#e3edf5',
		},
		'margin-right': '20px',
	},
	symbol: {
		...theme.typography.style.subheadingSm,
		color: theme.typography.color.primary,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	companyName: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	mt10: {
		'margin-top': '10px',
	},
	mt15: {
		'margin-top': '15px',
	},
	mt5: {
		marginTop: '5px',
	},
	splitter: {
		'border-bottom': '1px solid #deeaef',
	},
	title: {
		...theme.typography.style.subheading,
		color: theme.typography.color.primary,
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	amount: {
		...theme.typography.style.subheading,
		'min-height': '40px',
	},
	colored: {
		...theme.typography.style.body,
		color: '#11d87c',
	},
});

class DividendItem extends React.Component {
	render() {
		const {
			isOld,
			item,
			classes,
		} = this.props;

		return (
			<div className={classes.root}>

				<div className={classes.colored}>
					DIVIDEND
				</div>
				<div className={`${classes.title} ${classes.mt10}`}>
					{item.Symbol}
				</div>
				<div className={classes.companyName}>
					{item.CompanyName}
				</div>
				<div className={` ${classes.splitter} ${classes.mt10}`} />
				{isOld ?
					<div className={`${classes.date}  ${classes.mt5}`}>
						EX: {moment(item.ExDate).utcOffset(-5).format('D MMM YY')}
					</div>
					:
					<div className={`${classes.date}  ${classes.mt5}`}>
						EX: {moment(item.ExDate).format('D MMM YY')}
					</div>
				}
				<div className={` ${classes.amount}`}>
					{item.Value &&
					`${toUsaFormat(item.Value)}`}
				</div>
				{isOld ?
					<div className={`${classes.date} `}>
						{moment(item.PayDate).utcOffset(-5).format('D MMM YY')}
					</div>
					:
					<div className={`${classes.date} `}>
						{moment(item.PayDate).format('D MMM YY')}
					</div>
				}
			</div>
		);
	}
}

DividendItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	SafeComponent,
)(DividendItem);
