import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@components';
import { createInputQAAttributes, valueOrEmpty } from '@global';

const qaAttr = {
	status: createInputQAAttributes('employment_status'),
	company: createInputQAAttributes('employment_company'),
	industry: createInputQAAttributes('employment_industry'),
	position: createInputQAAttributes('employment_position'),
	address: createInputQAAttributes('employment_address'),
};

const EmploymentPanel = ({ profile }) => (
	<Fragment>
		<Input placeholder="Status" showLabel value={valueOrEmpty(profile.Status)} disabled qaAttributes={qaAttr.status} />
		<Input placeholder="Company" showLabel value={valueOrEmpty(profile.Company)} disabled qaAttributes={qaAttr.company} />
		<Input placeholder="Industry" showLabel value={valueOrEmpty(profile.Industry)} disabled qaAttributes={qaAttr.industry} />
		<Input placeholder="Position" showLabel value={valueOrEmpty(profile.Position)} disabled qaAttributes={qaAttr.position} />
		<Input
			placeholder="Address"
			showLabel
			multiline
			value={valueOrEmpty(profile.Address ? profile.Address.FullAddress : '')}
			disabled
			qaAttributes={qaAttr.address}
		/>
	</Fragment>
);

EmploymentPanel.propTypes = {
	profile: PropTypes.object.isRequired,
};

export default EmploymentPanel;
