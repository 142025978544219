import { useSelector } from 'react-redux';
import { app } from '@redux';
import { toMoneyFormat } from '@global';

export const useMoneyFormat = () => {
	const language = useSelector(app.selectors.language);
	const moneyFormat = (amount, currencyCode, options) => {
		const { nullReplace = '-' } = options || {};
		if (amount || amount === 0) {
			return `${toMoneyFormat(amount, language)} ${currencyCode}`;
		}
		return nullReplace;
	};
	return moneyFormat;
};
