import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { profile, snackbar } from '@redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import appConfig from 'app_config';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	title: {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
	},
	secretKeyText: {
		...theme.typography.style.body,
		color: theme.palette.color.primary.black,
		fontWeight: 'bold',
	},
	copyButton: {
		...theme.typography.style.body,
		borderRadius: '4px',
		backgroundColor: '#ffffff',
		textAlign: 'center',
		margin: '10px 0',
		padding: '6px 25px 6px 25px',
		cursor: 'pointer',
		color: '#78aaff',
		border: 'solid 1px #78aaff',
		'&:hover': {
			background: 'lightgray',
		},
		'&:active': {
			opacity: '0.7',
		},
	},
	qrCodeImage: {
		margin: '10px 0',
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	qrCodeImageError: {
		...theme.typography.style.body,
		color: theme.typography.color.red,
	},
	mainText: {
		...theme.typography.style.body,
		color: theme.palette.color.primary.black,
	},
}));

const splitStringToChunks = (str) => {
	if (str === null || str === undefined) {
		return '';
	}
	const parts = str.match(/(.{1,4})/g);
	return parts.join(' ');
};

const QrCodeStep = () => {
	const [copied, setCopied] = useState(false);
	const [qrCodeLoaded, setQrCodeLoaded] = useState(false);
	const [qrCodeError, setQrCodeError] = useState(false);

	const classes = useStyles();
	const clientProfile = useSelector(profile.selectors.profile);
	const clientSecretCode = clientProfile.ClientSecretCode;
	const qrCode = clientProfile.QRCode;

	const splittedSecretCode = splitStringToChunks(clientSecretCode);

	const onQrCodeImageLoaded = () => setQrCodeLoaded(true);
	const onQrCodeImageError = () => setQrCodeError(true);

	const dispatch = useDispatch();
	useEffect(() => {
		if (clientSecretCode === null) {
			dispatch(snackbar.actions.showErrorMessage({
				text: 'The Secret key for 2FA is missing, corrupted or could not be decrypted. Please, contact support',
			}));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className={classes.title}>Your secret key</div>
			<div className={classes.secretKeyText}>{ splittedSecretCode }</div>
			<div>
				<CopyToClipboard text={clientSecretCode} onCopy={() => setCopied(true)}>
					<button type="button" className={classes.copyButton}>
						<span>Copy</span>
					</button>
				</CopyToClipboard>
				{copied ? <span style={{ color: '#78aaff' }}>  Copied</span> : null}
			</div>
			<div className={classes.qrCodeImage}>
				{qrCodeError &&
				<div className={classes.qrCodeImageError}>Cannot load QR-code image</div>
				}
				{!qrCodeError &&
				<img
					src={`${appConfig.backendUrl}/account/api_getQrCode?link=${qrCode}`}
					alt="qrCode"
					onLoad={onQrCodeImageLoaded}
					onError={onQrCodeImageError}
				/>
				}
				{!qrCodeError && !qrCodeLoaded &&
				<CircularProgress
					style={{ color: '#1a96ff' }}
					size={30}
				/>
				}
			</div>
			<div className={classes.mainText}>
				<p>1. Copy the secret key</p>
				<p>2. Open code generation app and paste the secret key</p>
				<p>3. Get a one-time code and go back to the Lime app</p>
			</div>
		</Fragment>
	);
};

export default QrCodeStep;
