module.exports = {
	ModalPopups: {
		ADD_BANK_ACCOUNT: 'AddBankAccount',
		CREATE_APIKEY: 'CreateApiKey',
		CY_CHANGE_PASSWORD: 'ChangePassword',
		CY_NON_TRADING_ORDER_COMMON: 'NonTradingOrderCommonModal',
		CY_NON_TRADING_ORDER_IDENTITY: 'NonTradingOrderIdentityModal',
		CY_NON_TRADING_ORDER_REGISTRATION: 'NonTradingOrderRegistrationModal',
		CY_NON_TRADING_ORDER_LEVERAGE: 'NonTradingOrderLeverageModal',
		CY_TRANSFER_BETWEEN_ACCOUNTS: 'TransferBetweenAccountsModal',
		CY_WITHDRAWAL_BANK_TRANSFER: 'WithdrawalBankTransferModal',
		CY_WITHDRAWAL_CRYPTOWALLET: 'WithdrawalCryptoWallet',
		CY_NON_TRADING_OPEN_ACCOUNT: 'NonTradingOpenAccount',
		CY_NON_TRADING_OPEN_DEMO_ACCOUNT: 'NonTradingOpenDemoAccount',
		CY_WITHDRAWAL_MALAYSIAN_BANK: 'WithdrawalMalaysianBank',
		DEPOSIT_FUNDS_ACH: 'DepositFundsAch',
		DEPOSIT_FUNDS_BROKER: 'DepositFundsBroker',
		DEPOSIT_FUNDS_IRA: 'DepositFundsIra',
		DEPOSIT_FUNDS_WIRE: 'DepositFundsWire',
		WITHDRAW_FUNDS_ACH: 'WithdrawFundsAch',
		WITHDRAW_FUNDS_WIRE: 'WithdrawFundsWire',
		WITHDRAW_FUNDS_BROKER: 'WithdrawFundsBroker',
		WITHDRAW_FUNDS_IRA: 'WithdrawFundsIra',
		ACAT_FUND: 'AcatFund',
		CLOSE_ACCOUNT: 'CloseAccount',
		CLOSE_ACCOUNT_FORM: 'CloseAccountForm',
		J2T_LITE: 'LimeTradingPlatform',
		J2T_PLUS: 'J2tPlus',
		J2T_PRO: 'J2tPro',
		MT5: 'Mt5',
		ST_PRO: 'StPro',
		MESSAGE: 'Message',
		CHANGE_DELIVERY_TYPE: 'ChangeDeliveryType',
		CHANGE_PROFILE_ADDRESS: 'ChangeProfileAddress',
		CHANGE_PROFILE_POST_ADDRESS: 'ChangeProfilePostAddress',
		CHANGE_PROFILE_EMAIL: 'ChangeProfileEmail',
		CHANGE_PROFILE_PHONE: 'ChangeProfilePhone',
		CHANGE_DEPENDENTS: 'ChangeDependents',
		CHANGE_MARITAL_STATUS: 'ChangeMaritalStatus',
		CHANGE_EMPLOYMENT: 'ChangeEmployment',
		CHANGE_INVESTMENT_PROFILE: 'ChangeInvestmentProfile',
		CHANGE_INVESTMENT_EXPERIENCE: 'ChangeInvestmentExperience',
		CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL: 'ChangeMarketDataSubscriberStatus',
		CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL: 'ChangeMarketDataSubscriptions',
		ENABLE_THIRD_PARTY_MARKETDATA_MODAL: 'EnableThirdPartyMarketData',
		CHANGE_TARIFF_PER_SHARE: 'ChangeTariffPerShare',
		CHANGE_TARIFF_PER_TRADE: 'ChangeTariffPerTrade',
		CHANGE_TARIFF_ZERO: 'ChangeTariffZero',
		CHANGE_TWO_FACTOR_AUTHORIZATION: 'ChangeTwoFactor',
		CHANGE_TAX_FORM_MODAL: 'ChangeTaxForm',
		COMMISSION_FREE_MODAL: 'CommissionFreeModal',
		CONFIRM_MODAL: 'ConfirmModal',
		CONFIRM_PROFILE_EMAIL: 'ConfirmProfileEmail',
		CONFIRM_PROFILE: 'ConfirmProfile',
		CREATE_SIGNATURE: 'CreateSignature',
		IPO_CREATE_ORDER: 'IpoCreateOrder',
		PLAID_SELECT_ACCOUNT: 'PlaidSelectAccount',
		UPGRADE_MARGIN_TYPE: 'UpgradeMarginType',
		UPGRADE_OPTION_LEVEL: 'UpdateOptionLevel',
		WELCOME_PAGE: 'WelcomePage',
		IPO_EXTENDED_INFO_MOBILE: 'IpoExtendedInfoMobile',
		DEMO_ACCOUNT_EXPIRATION: 'DemoAccountExpiration',
		RESET_DEMO_ACCOUNT: 'ResetDemoAccount',
		OPEN_SECOND_ACCOUNT: 'OpenSecondAccount',
	},
};
