import actions from './actions';
import reducer from './reducer';
import selectors from './selectors';
import saga from './saga';

export default {
	actions,
	reducer,
	selectors,
	saga,
};

