import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalPopups } from '@enums';
import { ipo } from '@redux';
import { Modal, A } from '@components';

const styles = theme => ({
	linkContainer: {
		textAlign: 'right',
	},
});

const mapStateToProps = state => ({
	ipoOrderExtendedInfo: ipo.selectors.ipoOrderExtendedInfo(state),
});

class IpoExtendedInfoMobileModal extends React.Component {
	render() {
		const {
			ipoOrderExtendedInfo,
			onClose,
			classes,
		} = this.props;

		const offering = ipoOrderExtendedInfo.Offering;

		let prospUrl = offering.ProsepectusUrl;
		if (prospUrl && prospUrl.indexOf('http') < 0) {
			prospUrl = `https://${prospUrl}`;
		}

		return (
			<Modal
				title={offering.Name}
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.IPO_EXTENDED_INFO_MOBILE}`}
			>
				{offering.Description}
				<br />
				<p className={classes.linkContainer}>
					<A href={prospUrl} key={offering.Symbol} target="_blank">Prospectus</A>
				</p>
			</Modal>
		);
	}
}

IpoExtendedInfoMobileModal.propTypes = {
	ipoOrderExtendedInfo: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(IpoExtendedInfoMobileModal);
