import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { profile, userAccounts } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	change as reduxFormChange,
	formValueSelector,
} from 'redux-form';
import {
	Button,
	ErrorMessage,
	FormInput,
	ProgressContainer,
	SuccessMessage,
	SelectField,
} from '@components';
import { ModalPopups } from '@enums';
import { constants } from '@config';
import { RequireSignatureModal } from '../../..';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
		marginBottom: '40px',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputSelectItem: {
		marginTop: '10px',
		borderRadius: '8px',
	},
	button: {
		paddingTop: '12px',
	},
	spanItem: {
		marginTop: '20px',
		marginBottom: '8px',
		textAlign: 'center',
	},
	header: {
		textAlign: 'center',
		margin: '0px',
	},
	inputItem: {
		'& div > div': {
			background: theme.palette.color.primary.attestationBackground,
		},
	},
});

const CHANGE_PROFILE_POST_ADDRESS_FORM = 'ChangeProfilePostAddressForm';
const formSelector = formValueSelector(CHANGE_PROFILE_POST_ADDRESS_FORM);

const mapStateToProps = (state) => {
	const modalParams = profile.selectors.modals(state)[ModalPopups.CHANGE_PROFILE_POST_ADDRESS];
	const userProfile = modalParams || profile.selectors.profile(state);
	const userInfo = userAccounts.selectors.userInfo(state);

	const userPostAddress = userProfile.PersonalInformation.PostAddress;
	const country = (userPostAddress && userPostAddress.Country) || '';
	const stateAbbr = (userPostAddress && userPostAddress.StateAbbr) || '';

	return {
		initialValues: {
			clientId: userProfile.ClientId,
			guid: userProfile.Guid,
			streetAddress: (userPostAddress && userPostAddress.StreetAddress) || '',
			house: (userPostAddress && userPostAddress.House) || '',
			building: (userPostAddress && userPostAddress.Building) || '',
			apartment: (userPostAddress && userPostAddress.Apartment) || '',
			city: (userPostAddress && userPostAddress.City) || '',
			zipCode: (userPostAddress && userPostAddress.ZipCode) || '',
			country,
			stateAbbr,
		},
		initialAddressValue: profile.selectors.profile(state).PersonalInformation.FullPostAddress,
		addressValue: formSelector(state, 'Value'),
		countries: profile.selectors.getCountries(state),
		usaStates: profile.selectors.getUsaStates(state),
		profileSubmitting: userProfile.submitting || userAccounts.selectors.loading(state),
		pendingOrdersAddress: userProfile.PendingOrders.PostAddress,
		isForeign: userInfo.IsForeign,
		isSelfEdit: (!userProfile.Guid && !userProfile.ClientId)
			|| (userProfile.Guid && userInfo.Guid.toLowerCase() === userProfile.Guid.toLowerCase()),
		country,
		stateAbbr,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		refreshCountriesList: () => dispatch(profile.actions.getCountries.request()),
		refreshUsaStatesList: () => dispatch(profile.actions.getUsaStates.request()),
		setFormField: (fieldName, value = null) => dispatch(reduxFormChange(CHANGE_PROFILE_POST_ADDRESS_FORM, fieldName, value)),
	},
});

const validate = (values) => {
	const errors = {};
	if (!values.country) {
		errors.country = 'required';
	}
	if (!values.streetAddress) {
		errors.streetAddress = 'required';
	} else if (values.streetAddress.length > constants.maxCharsNumber) {
		errors.streetAddress = constants.maxCharsNumberError;
	}
	if (!values.city) {
		errors.city = 'required';
	} else if (values.city.length > constants.maxCharsNumber) {
		errors.city = constants.maxCharsNumberError;
	}
	if (!values.stateAbbr && values.country === 'US') {
		errors.stateAbbr = 'required';
	}
	if (!values.zipCode) {
		errors.zipCode = 'required';
	} else if (values.zipCode.length > constants.maxCharsNumber) {
		errors.zipCode = constants.maxCharsNumberError;
	}
	if (values.house && values.house.length > constants.maxCharsNumber) {
		errors.house = constants.maxCharsNumberError;
	}
	if (values.building && values.building.length > constants.maxCharsNumber) {
		errors.building = constants.maxCharsNumberError;
	}
	if (values.apartment && values.apartment.length > constants.maxCharsNumber) {
		errors.apartment = constants.maxCharsNumberError;
	}
	return errors;
};

const ChangeProfilePostAddressModal = ({
	country,
	classes,
	onClose,
	handleSubmit,
	invalid,
	profileSubmitting,
	pendingOrdersAddress,
	submitting,
	submitSucceeded,
	addressValue,
	initialAddressValue,
	actions,
	countries,
	usaStates,
	stateAbbr,
}) => {
	const [countryValue, setCountryValue] = useState(country);

	const isPristine = addressValue === initialAddressValue;

	useEffect(() => {
		if (!countries) {
			actions.refreshCountriesList();
		}
		if (!usaStates) {
			actions.refreshUsaStatesList();
		}
	}, [countries, usaStates, actions]);

	const countryChanged = (event) => {
		const selectedCountry = event.target.value;
		setCountryValue(selectedCountry);
		if (selectedCountry !== 'US') {
			actions.setFormField('stateAbbr', null);
		}
	};

	const sortUsFirst = (a, b) => {
		if (a.CodeLat2 === 'US') {
			return -1;
		}
		if (b.CodeLat2 === 'US') {
			return 1;
		}
		if (a.Name === b.Name) {
			return 0;
		}
		return a.Name < b.Name ? -1 : 1;
	};

	return (
		<RequireSignatureModal
			title="Change Mailing Address"
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
			className={`QA-${ModalPopups.CHANGE_PROFILE_POST_ADDRESS}`}
		>
			{submitSucceeded && <SuccessMessage onClick={onClose} text="Your request to change mailing address is being processed." />}
			{!submitSucceeded && !profileSubmitting && pendingOrdersAddress &&
				<ErrorMessage
					onClick={onClose}
					text="Another request for mailing address change is in progress. Please try again later."
				/>
			}
			{!submitSucceeded && !pendingOrdersAddress && (
				<ProgressContainer submitting={profileSubmitting}>
					<form
						onSubmit={handleSubmit(profile.actions.changeProfilePostAddressFormSubmitHandler)}
						className={classes.root}
					>
						<h2 className={classes.header}>Please enter your new mailing address</h2>
						<div className={classes.inputSelectItem}>
							<SelectField
								label="Country"
								name="country"
								onChange={countryChanged}
								items={
									(countries && countries
										.sort(sortUsFirst)
										.map(cntr => ({ value: cntr.CodeLat2, text: cntr.Name }))) || []}
								value={countryValue}
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="streetAddress"
								placeholder="Street address"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="house"
								placeholder="House"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="building"
								placeholder="Building"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="apartment"
								placeholder="Apt/Suite"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="city"
								placeholder="City"
							/>
						</div>
						{countryValue === 'US' &&
							<div className={classes.inputSelectItem}>
								<SelectField
									label="State"
									name="stateAbbr"
									items={
										(usaStates && usaStates.map(st => ({ value: st.Abbr, text: st.Name }))) || []}
									value={stateAbbr}
								/>
							</div>
						}
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="zipCode"
								placeholder="Zip Code"
							/>
						</div>
						<div className={classes.button}>
							<Button
								fullWidth
								type="submit"
								variant="contained"
								color="primary"
								disabled={invalid || isPristine || submitting}
							>
								{submitting ?
									<CircularProgress
										style={{ color: '#1a96ff' }}
										size={18}
									/>
									: 'Change Mailing Address'
								}
							</Button>
						</div>
					</form>
				</ProgressContainer>
			)}
		</RequireSignatureModal>
	);
};

ChangeProfilePostAddressModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	countries: PropTypes.array,
	usaStates: PropTypes.array,
	country: PropTypes.string.isRequired,
	stateAbbr: PropTypes.string.isRequired,
	actions: PropTypes.object,
	profileSubmitting: PropTypes.bool,
	pendingOrdersAddress: PropTypes.bool,
	addressValue: PropTypes.string,
	initialAddressValue: PropTypes.string,
};

ChangeProfilePostAddressModal.defaultProps = {
	countries: undefined,
	usaStates: undefined,
	actions: undefined,
	profileSubmitting: false,
	pendingOrdersAddress: false,
	addressValue: undefined,
	initialAddressValue: undefined,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CHANGE_PROFILE_POST_ADDRESS_FORM,
		validate,
	}),
)(ChangeProfilePostAddressModal);
