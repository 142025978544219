import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { A, FormCheckbox } from '@components';


const styles = theme => ({
	alertText: {
		color: theme.typography.color.secondary,
		...theme.typography.style.title,
	},
});


class UpgradeAccountFormContent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isMarginDocShown: false,
			isDayTraderDocShown: false,
		};
	}

	onClickMarginAgreement = () => {
		this.setState({	isMarginDocShown: true });
		this.props.actions.setHasMarginAgree();
	}

	onClickDayTraderAgreement = () => {
		this.setState({	isDayTraderDocShown: true });
		this.props.actions.setHasDayTraderAgree();
	}

	render() {
		const {
			isMarginAgree,
			isDayTraderAgree,
			clearerFirm,
		} = this.props;

		return (
			<div>
				
				{isMarginAgree &&
					<Field
						component={FormCheckbox}
						name="HasMarginAgree"
						label={
							<p>
								I confirm that I read and agree with terms and conditions of the &nbsp;
								<A
									target="_blank"
									href={clearerFirm === 'cor' ? '/static/cor/axos-margin-agreement.pdf' : '/static/vision/vision_margin_agreement.pdf?v=2'}
									onClick={this.onClickMarginAgreement}
								>
									Margin Agreement
								</A>
							</p>
						}
						disabled={!this.state.isMarginDocShown}
					/>
				}
				{isDayTraderAgree &&
					<Field
						component={FormCheckbox}
						name="HasDayTraderAgree"
						label={
							<p>
								I confirm that I read and agree with terms and conditions of the &nbsp;
								<A
									target="_blank"
									href={clearerFirm === 'vision' ? '/static/vision/vision_day_trading.pdf' : '/static/cor/Lime-Day-Trading-Disclosure-Document.pdf'}
									onClick={this.onClickDayTraderAgreement}
								>
									Day Trading Supplement Agreement
								</A>
							</p>
						}
						disabled={!this.state.isDayTraderDocShown}
					/>
				}
			</div>
		);
	}
}

UpgradeAccountFormContent.propTypes = {
	actions: PropTypes.object.isRequired,
	isMarginAgree: PropTypes.bool.isRequired,
	isDayTraderAgree: PropTypes.bool.isRequired,
	clearerFirm: PropTypes.string.isRequired,
};

export default compose(withStyles(styles))(UpgradeAccountFormContent);
