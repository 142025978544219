import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm,	Field, formValueSelector } from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateSecureString } from '@global';
import {
	FileInput,
	FormInput,
	Button,
	R,
} from '@components';
import { userAccounts } from '@redux';
import { RequireConfirmationCodeItem } from '@shared';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	divider: {
		marginTop: '30px',
	},
	fileUploader: {
		marginTop: '16px',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
});


const CY_NON_TRADING_ORDER_REGISTRATION_FORM_ID = 'CYNonTradingOrderRegistrationForm';

const mapStateToProps = (state, ownProps) => {
	let currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	let formSelector = formValueSelector(CY_NON_TRADING_ORDER_REGISTRATION_FORM_ID);
	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
		attachmentsRegistrationFiles: formSelector(state, 'RegistrationFiles'),
	};
};


class CYNonTradingOrderRegistrationForm extends React.Component {

	static validate(formvalues, props) {

		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (!values.RegistrationFiles || values.RegistrationFiles.length === 0) {
			errors.RegistrationFiles = 'required';
		}

		if (!values.Address) {
			errors.Address = 'required';
		}

		if (!values.ConfirmationCode) {
			errors.ConfirmationCode = 'required';
		}
		
		return errors;
	}


	onFilesUpload = (field, files) => this.props.change(field, files);

	render() {
		const {
			attachmentsRegistrationFiles,
			classes,
			handleSubmit,
			theme,
			...props
		} = this.props;

		const noAttachments = (!attachmentsRegistrationFiles || attachmentsRegistrationFiles.length === 0);

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Address"
						placeholder={<R id="ccommon_order_param_address_copy" defaultValue="New address" />}
						label="New address"
						multiline
						validate={[validateSecureString]}
					/>
				</div>
				<p>
					<R id="common_nontrading_order_identity_passport_files_copy" defaultValue="Copy of pages of ID document" />
				</p>
				<div className={classes.fileUploader}>
					<FileInput
						onFilesUpdate={files => this.onFilesUpload('RegistrationFiles', files)}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Comment"
						placeholder={<R id="shared_comment" defaultValue="Comment" />}
						label="Comment"
						multiline
						validate={[validateSecureString]}
					/>
				</div>
				<RequireConfirmationCodeItem
					customClasses={classes}
					name="ConfirmationCode"
					buttonTitle={<R id="common_send_button_order" defaultValue="Send" />}
					reason="NontradingOrderApply"
					disabled={props.invalid || props.submitting || noAttachments}
				>

					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.typography.color.white }}
								size={18}
							/>
							: <R id="common_confirm_order" defaultValue="Confirm" />
						}
					</Button>

				</RequireConfirmationCodeItem>

			</form>
		);
	}
}

CYNonTradingOrderRegistrationForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		form: CY_NON_TRADING_ORDER_REGISTRATION_FORM_ID,
		validate: CYNonTradingOrderRegistrationForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			if (errors._error && props.onFail) {
				props.onFail();
			}
		},
	}),
)(CYNonTradingOrderRegistrationForm);
