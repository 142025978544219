/* eslint-disable react/no-did-update-set-state,no-param-reassign,consistent-return */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { SvgIcon } from '@components';
import InputBase from '@material-ui/core/InputBase';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { precisionRound } from '@global';
import InputFormControl from '../Input/InputFormControl';

const styles = theme => ({
	root: {
		backgroundColor: 'rgba(255,255,255,0.05)',
		color: theme.typography.color.primary,
		'& input::placeholder': {
			opacity: 0.75,
		},
		fontSize: theme.typography.pxToRem(18),
	},
	container: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		margin: '16px 0px 0px 0px',
		padding: '12px 24px 5px',
		background: theme.palette.color.primary.attestationBackground,
		borderRadius: '8px',
	},
	input: {
	},
	buttons: {
		width: '72px',
		display: 'flex',
		alignItems: 'center',
		padding: '2px 0',
	},
	iconPlus: {
		cursor: 'pointer',
		outline: 'none',
	},
	iconMinus: {
		cursor: 'pointer',
		marginRight: '24px',
		outline: 'none',
	},
});

function NumberFormatCustom(props) {
	const {
		inputRef,
		onChange,
		prefix = '$',
		suffix = '',
		decimalScale,
		...restProps
	} = props;
	return (
		<NumberFormat
			{...restProps}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange(values.value || '');
			}}
			thousandSeparator
			prefix={prefix}
			suffix={suffix}
			decimalScale={decimalScale}
		/>
	);
}

class InputNumber extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
		this.onInputChange = this.onInputChange.bind(this);
		this.onPlusButtonClick = this.onPlusButtonClick.bind(this);
		this.onMinusButtonClick = this.onMinusButtonClick.bind(this);
		this.onBlurHandler = this.onBlurHandler.bind(this);
	}
	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value && this.props.value !== this.state.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}
	onInputChange(value) {
		this.setState({
			value,
		}, () => {
			this.props.onChange(value);
		});
	}

	onPlusButtonClick() {
		const val = Number(this.state.value) + this.props.step;
		this.onInputChange(precisionRound(val));
		this.onBlurHandler();
	}

	onMinusButtonClick() {
		const { value } = this.state;
		if ((value - this.props.step) > 0) {
			this.onInputChange(Number(precisionRound(value - this.props.step)));
			this.onBlurHandler();
		}
	}

	onBlurHandler() {
		if (this.props.onBlur) {
			this.props.onBlur(this.state.value);
		}
	}
	render() {
		const {
			allowIconButtons,
			name,
			placeholder,
			fullWidth,
			error,
			additionalText,
			showLabel,
			classes,
			helpertext,
			validationMessageMode,
			onChange,
			onBlur,
			value,
			disabled,
			prefix,
			suffix,
			decimalScale,
			...other
		} = this.props;
		return (
			<InputFormControl
				name={name}
				placeholder={placeholder}
				fullWidth={fullWidth}
				error={error}
				additionalText={additionalText}
				showLabel={showLabel}
				helpertext={helpertext}
				validationMessageMode={validationMessageMode}
			>
				<div className={classes.container}>
					<div className={classes.input}>
						<InputBase
							name={name}
							id={name}
							placeholder={showLabel ? '' : placeholder}
							classes={{ root: classes.root }}
							fullWidth={fullWidth}
							onChange={this.onInputChange}
							value={this.state.value}
							onBlur={this.onBlurHandler}
							inputComponent={NumberFormatCustom}
							inputProps={{ prefix, suffix, decimalScale }}
							disabled={disabled}
							{...other}
						/>
					</div>
					{!disabled && allowIconButtons &&
						<div className={classes.buttons}>
							<div className={classes.iconMinus} onClick={this.onMinusButtonClick} role="button" tabIndex="-1">
								<SvgIcon icon="Minus" />
							</div>
							<div className={classes.iconPlus} onClick={this.onPlusButtonClick} role="button" tabIndex="-1">
								<SvgIcon icon="Plus" />
							</div>
						</div>
					}
				</div>
			</InputFormControl>
		);
	}
}

InputNumber.propTypes = {
	step: PropTypes.number,
	classes: PropTypes.object.isRequired,
	onBlur: PropTypes.func,
	allowIconButtons: PropTypes.bool,
	prefix: PropTypes.string,
};

InputNumber.defaultProps = {
	step: 1000,
	onBlur: null,
	showLabel: true,
	allowIconButtons: true,
	prefix: '$',
};

export default compose(withStyles(styles))(InputNumber);
