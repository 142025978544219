import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
	cyMoneyOutSimple,
} from '@api';
import { BaseGetSagaHandler, userAccounts } from '@redux';
import actions from './actions';

function* cyWithdrawalEmoneyFormSagaHandler({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: cyMoneyOutSimple,
		errorText: 'Withdrawal Funds',
		handler: actions.cyWithdrawalEmoneyForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.NonOrderData.TradeCode, payload.NonOrderData.ClearingFirm);
	}
}

function* watchcyWithdrawalEmoneyFormSubmitRequest() {
	yield takeEvery(actions.cyWithdrawalEmoneyForm.REQUEST, cyWithdrawalEmoneyFormSagaHandler);
}

// Root saga
const rootSaga = [
	fork(watchcyWithdrawalEmoneyFormSubmitRequest),
];

export default {
	rootSaga,
};
