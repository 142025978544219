import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SafeComponent from 'react-safe-component';
import compose from 'recompose/compose';
import moment from 'moment';
import {
	ExpansionPanel,
	ProgressContainer,
	ShowMore,
	R,
} from '@components';
import { constants, env } from '@config';
import { snackbar, userAccounts } from '@redux';
import PropTypes from 'prop-types';
import { CYItem } from '@components';
import { CYTransactionsFilters, Item, TransactionsFilters } from './components';


const styles = (theme) => {
	const filtersCss = {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		'& > span': {
			marginRight: '40px',
			whiteSpace: 'nowrap',
		},
	};

	return {
		itemContainer: {
			padding: '0 16px',
			margin: '0px -16px',
			'&:hover': {
				backgroundColor: '#e3edf5',
			},
		},
		item: {
			borderBottom: '1px solid #deeaef',
			padding: '16px 0',
		},
		tranFilters: filtersCss,
		tranHeader: {
			...filtersCss,
			padding: '10px 0',
			[theme.breakpoints.up('md')]: {
				flexWrap: 'nowrap',
			},
		},
	};
};

const mapStateToProps = state => ({
	cashActivities: userAccounts.selectors.cashActivities(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		loadPage: payload => dispatch(userAccounts.actions.getTransactionsHistory.request(payload)),
		showError: message => dispatch(snackbar.actions.showErrorMessage({ text: message })),
	},
});

class TransactionsHistory extends React.Component {
	constructor(props) {
		super(props);
		this.startDateInit = new Date(moment().year(), moment().month() - 2, moment().date());
		this.endDateInit = new Date();
		this.actionsTypeInit = 0;
		this.tranSearchInit = '';

		this.filter = {
			startDate: this.startDateInit,
			endDate: this.endDateInit,
			actionsType: this.actionsTypeInit,
			tranSearch: this.tranSearchInit,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentTradeAccount.tradeCode !== this.props.currentTradeAccount.tradeCode) {
			this.filter = {
				startDate: this.startDateInit,
				endDate: this.endDateInit,
				actionsType: this.actionsTypeInit,
				tranSearch: this.tranSearchInit,
			};
		}
	}

	changeFilter = (filter) => {
		this.filter = filter;
		this.props.actions.loadPage({
			TradeCode: this.props.currentTradeAccount.tradeCode,
			ClearerFirm: this.props.currentTradeAccount.clearerFirm,
			StartDate: moment(this.filter.startDate).format('YYYYMMDD'),
			EndDate: moment(this.filter.endDate).format('YYYYMMDD'),
			Page: 1,
			// pageSize: 8,
			Type: this.filter.actionsType,
			Search: this.filter.tranSearch,
		});
	}

	loadMore = () => {
		const {
			currentTradeAccount,
			cashActivities: {
				pagination: {
					Page,
				},
			},
		} = this.props;

		this.props.actions.loadPage({
			TradeCode: currentTradeAccount.tradeCode,
			ClearerFirm: currentTradeAccount.clearerFirm,
			StartDate: moment(this.filter.startDate).format('YYYYMMDD'),
			EndDate: moment(this.filter.endDate).format('YYYYMMDD'),
			Page: Page + 1,
			// pageSize: 8,
			Type: this.filter.actionsType,
			Search: this.filter.tranSearch,
		});
	}

	render() {
		const {
			classes,
			cashActivities: {
				loading,
				items,
				pagination: {
					Page,
					PageSize,
				},
			},
		} = this.props;

		return (
			<ExpansionPanel
				header={
					<div className={classes.tranHeader}>
						<span><R id="common_menu_transaction_history" defaultValue="Transactions History" /></span>
						<div className={classes.tranFilters}>
							{env.firm === constants.LIME_FIN 
								?
								<TransactionsFilters
									filter={this.filter}
									changeFilter={this.changeFilter}
								/>
								:
								<CYTransactionsFilters
									filter={this.filter}
									changeFilter={this.changeFilter}
								/>
							}
						</div>
					</div>
				}
				id="transaction-history"
			>
				<ProgressContainer submitting={loading && items && items.length === 0} display="left" id="test-transaction">
					{items.map((item, index) => (
						<div className={classes.itemContainer} key={index}>
							{/* filter technicals */}
							{item && !item.IsTechnical &&
								<div className={classes.item}>
									{env.firm === constants.LIME_FIN 
										? <Item item={item} />
										: <CYItem item={item} />
									}
								</div>
							}
						</div>
					))}
					<ShowMore
						submitting={loading && items && items.length > 0}
						onClick={this.loadMore}
						showMoreButton={(Page * PageSize) === items.length}
					/>
				</ProgressContainer>
			</ExpansionPanel>
		);
	}
}

TransactionsHistory.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	cashActivities: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	SafeComponent,
)(TransactionsHistory);
