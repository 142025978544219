import moment from 'moment-timezone';
import { constants, env } from '@config';

function toUsaFormat(num, precision = 2) {
	return num.toLocaleString('en-US', { minimumFractionDigits: precision, maximumFractionDigits: precision });
}

module.exports = {
	toUsaFormat,
	toUsaBigNumFormat(num) {
		if (Math.abs(num) >= 1.0e+9) {
			return `${toUsaFormat(num / 1.0e+9)}B`;
		} else if (Math.abs(num) >= 1.0e+6) {
			return `${toUsaFormat(num / 1.0e+6)}M`;
		} else if (Math.abs(num) >= 1.0e+3) {
			return `${toUsaFormat(num / 1.0e+3)}K`;
		}
		return `${toUsaFormat(num)}`;
	},
	toUsaSizeFormat(num) {
		if (Math.abs(num) >= 1.0e+9) {
			return `${toUsaFormat(num / 1.0e+9, 0)} Bb`;
		} else if (Math.abs(num) >= 1.0e+6) {
			return `${toUsaFormat(num / 1.0e+6, 0)} Mb`;
		} else if (Math.abs(num) >= 1.0e+3) {
			return `${toUsaFormat(num / 1.0e+3, 0)} Kb`;
		}
		return `${toUsaFormat(num, 0)} bytes`;
	},
	toUsaDateFormat(date) {
		return moment(date).format('D MMM YYYY');
	},
	toUsaDateFormatFromUtc(date, format = 'D MMM YYYY') {
		return date ? moment.utc(date).local().format(format) : '';
	},
	toUsaMoneyFormat(num, precision = 2) {
		return typeof num !== 'number' ? '' :
			num.toLocaleString('en-US', {
				minimumFractionDigits: precision, maximumFractionDigits: precision, currency: 'USD', style: 'currency',
			});
	},
	toMoneyFormat(summ, language, precision = 2) {
		if (constants.LIME_LME !== env.firm) {
			return this.toUsaMoneyFormat(summ, precision);
		}
		return typeof summ !== 'number' ? '' :
			summ.toLocaleString(language, {
				minimumFractionDigits: precision, maximumFractionDigits: precision,
			});
	},
	formatDateAsTimeZone(date, format = 'L LTS') {
		return date ? moment.tz(date, 'America/New_York').format(format) : '';
	},
	formatDateAsUtc(date, format = 'L') {
		return date ? moment.utc(date).format(format) : '';
	},
	parseMomentDate(date) {
		if ((typeof date) === 'object') {
			return date.toISOString();
		}
		return undefined;
	},
	dateDiffInDays(firstDate, secondDate) {
		const MS_PER_DAY = 1000 * 60 * 60 * 24;
		return Math.floor((firstDate - secondDate) / MS_PER_DAY);
	},
	getDateTimeEst(date = new Date()) {
		if (typeof date === 'string') {
			return new Date(new Date(date).toLocaleString('en-US', {
				timeZone: 'America/New_York',
			}));
		}

		return new Date(date.toLocaleString('en-US', {
			timeZone: 'America/New_York',
		}));
	},
	precisionRound(number, precision = 2) {
		const factor = 10 ** precision;
		return Math.round((number + Number.EPSILON) * factor) / factor;
	},
};
