/* eslint-disable no-plusplus */
import { put, fork, takeEvery, call } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { loginLocal } from '@api';
import { cabinetLog } from '@global';
import { auth } from '@redux';

import actions from './actions';

function* loginFormSubmit({ payload: { username, password } }) {
	try {
		const loginResponse = yield call(loginLocal, username, password);
		if (typeof loginResponse.data === 'string') {
			// if was error with status 200
			Raven.captureMessage('loginResponse.data === string', { extra: { text: loginResponse.data.substr(0, 200) } });
			loginResponse.data = {
				Success: false,
				Errors: [{
					Code: 'Unknown',
					Message: 'An internal server error',
				}],
			};
		}

		if (!loginResponse.data.Success) {
			if (loginResponse.data.Errors[0].Code === 'Authorized') {
				yield put(actions.loginFormSuccess());
				cabinetLog('Authorized auth error', loginResponse.data);
				yield put(auth.actions.loginLocal({ username, token: loginResponse.data.SignalRToken }));
			}
			if (loginResponse.data.Errors) {
				const errors = [];
				for (let i = 0; i < loginResponse.data.Errors.length; i++) {
					errors.push(`${loginResponse.data.Errors[i].Message}`);
				}
				yield put(actions.loginFormFailure(new SubmissionError({
					_error: errors,
				})));
			}
		} else {
			yield put(actions.loginFormSuccess());
			cabinetLog('success auth', loginResponse.data);
			yield put(auth.actions.loginLocal({ username, token: loginResponse.data.SignalRToken }));
		}
	} catch (error) {
		Raven.captureMessage('loginFormSubmit exception', { extra: error });
		yield put(actions.loginFormFailure(new SubmissionError({
			_error: ['NETWORK ERROR'],
		})));
		console.error(error);
	}
}

// Watchers
function* watchLoginFormSubmit() {
	yield takeEvery(actions.LOGIN_FORM_SUBMIT_REQUEST, loginFormSubmit);
}

// Root saga
const rootSaga = [
	fork(watchLoginFormSubmit),
];

export default {
	rootSaga,
};
