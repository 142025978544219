import { reduxForm, Field, formValueSelector } from 'redux-form';
import {
	Button,
	ErrorMessage,
	ProgressContainer,
	SuccessMessage,
	SelectField,
	FormCheckbox,
	SvgIcon,
	FormInput,
} from '@components';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups, ClientTypes, ValidationMessageMode } from '@enums';
import { profile } from '@redux';
import { constants } from '@config';
import {
	ACCREDITED_INVESTOR_TOOLTIP_TEXT,
	QUALIFIED_CLIENT_TOOLTIP_TEXT,
	INVESTMENT_TIMELINE_RANGES,
	LIQUIDITY_IMPORTANCE_RANGES,
} from '@utils';
import { connect } from 'react-redux';
import { RequireSignatureModal } from '../../..';
import {
	NetWorthValues,
	NetWorthList,
	AnnualIncomeInstitutionalList,
	AnnualIncomeList,
	TaxBracketList,
} from './AssetItems';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	subHeader: {
		...theme.typography.style.title,
		color: theme.typography.color.secondary,
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputSelectItem: {
		borderRadius: '8px',
		marginTop: '16px',
		'& > div > div:first-child': {
			background: theme.palette.color.primary.attestationBackground,
			borderRadius: '8px',
		},
		'& > div > div:first-child > label': {
			paddingLeft: '25px',
		},
		'& > div > div:first-child > div': {
			paddingLeft: '25px',
		},
	},
	divider: {
		marginTop: '20px',
	},
	button: {
		paddingTop: '12px',
	},
	inputItem: {
		'& div > div': {
			background: theme.palette.color.primary.attestationBackground,
		},
	},
});

const CHANGE_INVESTMENT_PROFILE_FORM_ID = 'ChangeInvestmentProfileForm';
const formSelector = formValueSelector(CHANGE_INVESTMENT_PROFILE_FORM_ID);

const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	const investmentProfile = userProfile.InvestmentProfile;
	const initialValues = (!userProfile.submitting && !userProfile.error) ? {
		goal: investmentProfile.Goal,
		timeline: investmentProfile.Timeline,
		riskTolerance: investmentProfile.RiskTolerance,
		taxBracket: investmentProfile.TaxBracket,
		liquid: investmentProfile.Liquid,
		experience: investmentProfile.Experience,
		annualIncome: investmentProfile.AnnualIncome,
		netWorth: investmentProfile.NetWorth,
		liquidity: investmentProfile.Liquidity,
		accreditedInvestor: investmentProfile.AccreditedInvestor,
		qualifiedClient: investmentProfile.QualifiedClient,
	} : {};
	return {
		initialValues,
		investmentProfile,
		profileSubmitting: userProfile.submitting,
		pendingOrder: userProfile.PendingOrders.InvestmentProfile,
		pendingOrderEmployment: userProfile.PendingOrders.Employment,
		clientType: userProfile.Type,
		liquidValue: formSelector(state, 'liquid'),
		totalValue: formSelector(state, 'netWorth'),
		annualIncomeValue: formSelector(state, 'annualIncome'),
		taxBracketValue: formSelector(state, 'taxBracket'),
	};
};

function valueOrEmpty(val) {
	if (val) return val;
	return '';
}

class ChangeInvestmentProfileModal extends React.Component {
	static validate(values) {
		const errors = {};
		const requiredError = 'Required';
		const maxCharsError = `Up to ${constants.maxCharsNumber} characters allowed`;
		const changeReasonRegexp = /^[A-Za-z0-9.,\-"'/:;()\s]+$/;

		if (!values.goal) {
			errors.goal = requiredError;
		}
		if (!values.timeline) {
			errors.timeline = requiredError;
		}
		if (!values.experience) {
			errors.experience = requiredError;
		}
		if (!values.riskTolerance) {
			errors.riskTolerance = requiredError;
		}
		if (!values.liquidity) {
			errors.liquidity = requiredError;
		}
		if (!values.liquid) {
			errors.liquid = requiredError;
		}
		if (!values.netWorth) {
			errors.netWorth = requiredError;
		}
		if (!values.annualIncome) {
			errors.annualIncome = requiredError;
		}
		if (!values.taxBracket) {
			errors.taxBracket = requiredError;
		}
		if (!values.changeReason) {
			errors.changeReason = requiredError;
		} else if (values.changeReason.length > constants.maxCharsNumber) {
			errors.changeReason = maxCharsError;
		} else if (!(changeReasonRegexp.test(values.changeReason))) {
			errors.changeReason = 'Latin characters and digits only, no special characters except for: . , - / " \' : ; () space';
		}

		const defaultLevel = { level: 0 };
		const totalNetWorth = NetWorthList.find(v => v.value === values.netWorth) || defaultLevel;
		const liquidNetWorth = NetWorthList.find(v => v.value === values.liquid) || defaultLevel;
		if (totalNetWorth.level < liquidNetWorth.level) {
			errors.liquid = 'Liquid net worth cannot be more than Total net worth';
			errors.netWorth = 'Total net worth cannot be less than Liquid net worth';
		}

		return errors;
	}

	constructor(props) {
		super(props);

		this.changeReason = '';
	}

	showChangeReasonField = () => {
		const isMarginCorellated = () => {
			const {
				initialValues: {
					liquid: initialLiquidValue,
				},
				liquidValue,
			} = this.props;

			const cor = (liquidValue === NetWorthValues.UNDER_150_000 || liquidValue === NetWorthValues.UNDER_500_000) &&
				(liquidValue !== initialLiquidValue);
			return cor;
		};

		const isAssetsChangesSignificant = () => {
			const calcShift = (items, initialValue, currentValue) => {
				const defaultLevel = { level: 0 };
				const initialItem = items.find(v => v.value === initialValue) || defaultLevel;
				const currentItem = items.find(v => v.value === currentValue) || defaultLevel;
				return Math.abs(initialItem.level - currentItem.level);
			};

			const {
				initialValues: {
					liquid: initialLiquidValue,
					netWorth: initialTotalValue,
					annualIncome: initialAnnualIncomeValue,
					taxBracket: initialTaxBracketValue,
				},
				liquidValue,
				totalValue,
				annualIncomeValue,
				taxBracketValue,
			} = this.props;

			const liquidShift = calcShift(NetWorthList, initialLiquidValue, liquidValue);
			const totalShift = calcShift(NetWorthList, initialTotalValue, totalValue);
			const annualShift = calcShift(AnnualIncomeList, initialAnnualIncomeValue, annualIncomeValue);
			const taxBracketShit = calcShift(TaxBracketList, initialTaxBracketValue, taxBracketValue);

			const maxShift = 1;
			const sign = liquidShift > maxShift ||
				totalShift > maxShift ||
				annualShift > maxShift ||
				taxBracketShit > maxShift;
			return sign;
		};

		const isAssetsOversThresholds = () => {
			const getItemLevel = (items, currentValue) => {
				const defaultLevel = { level: 0 };
				return (items.find(v => v.value === currentValue) || defaultLevel).level;
			};

			const {
				initialValues: {
					liquid: initialLiquidValue,
					netWorth: initialTotalValue,
					annualIncome: initialAnnualIncomeValue,
					taxBracket: initialTaxBracketValue,
				},
				liquidValue,
				totalValue,
				annualIncomeValue,
				taxBracketValue,
				clientType,
			} = this.props;

			const annualIncomeItems = clientType === ClientTypes.INSTITUTIONAL ? AnnualIncomeInstitutionalList : AnnualIncomeList;

			const liquidLevel = getItemLevel(NetWorthList, liquidValue);
			const totalLevel = getItemLevel(NetWorthList, totalValue);
			const annualIncomeLevel = getItemLevel(annualIncomeItems, annualIncomeValue);
			const taxBracketLevel = getItemLevel(TaxBracketList, taxBracketValue);

			const annualThresholdLevel = clientType === ClientTypes.INSTITUTIONAL ? 1 : 8; // >= $500,000

			return (
				(liquidLevel >= 5 && liquidValue !== initialLiquidValue) || // >= $1,000,000
				(totalLevel >= 5 && totalValue !== initialTotalValue) || //  >= $1,000,000
				(annualIncomeLevel >= annualThresholdLevel && annualIncomeValue !== initialAnnualIncomeValue) ||
				(taxBracketLevel >= 4 && taxBracketValue !== initialTaxBracketValue) // >= 31%
			);
		};

		const {
			pendingOrderEmployment,
		} = this.props;

		if (pendingOrderEmployment) {
			this.changeReason = 'Employment change (system comment)';
			return false;
		} else if (isMarginCorellated()) {
			return true;
		} else if (isAssetsChangesSignificant()) {
			return true;
		} else if (isAssetsOversThresholds()) {
			return true;
		}

		this.changeReason =
			'Insignificant change, below the thresholds, no correlation with margin / option level requirements (system comment)';
		return false;
	}

	formSubmit = (values, dispatch, props) => {
		const newValues = { ...values };

		if (this.changeReason) {
			newValues.changeReason = this.changeReason;
		}

		return profile.actions.changeProfileInvestmentProfileFormSubmitHandler(newValues, dispatch, props);
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			pristine,
			profileSubmitting,
			pendingOrder,
			submitting,
			submitSucceeded,
			investmentProfile: InvestmentProfile,
			clientType,
		} = this.props;

		const annualIncomeItems = clientType === ClientTypes.INSTITUTIONAL ? AnnualIncomeInstitutionalList : AnnualIncomeList;

		return (
			<RequireSignatureModal
				title="Change Investment Profile"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_INVESTMENT_PROFILE}`}
			>
				{submitSucceeded &&
					<SuccessMessage onClick={onClose} text="Your request to change investment profile is being processed." />}
				{!submitSucceeded && !profileSubmitting && pendingOrder &&
					<ErrorMessage
						onClick={onClose}
						text="Another request for investment profile change is in progress. Please try again later."
					/>
				}
				{!submitSucceeded && !pendingOrder &&
					<ProgressContainer submitting={profileSubmitting}>
						<form
							onSubmit={handleSubmit(this.formSubmit)}
							className={classes.root}
						>
							<div className={classes.subHeader}>Investment</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New goal"
									name="goal"
									items={[{ value: 'Speculation', text: 'Speculation' },
										{ value: 'Income', text: 'Income' },
										{ value: 'Appreciation', text: 'Capital Appreciation' },
										{ value: 'Preservation', text: 'Capital Preservation' }]}
									value={valueOrEmpty(InvestmentProfile.Goal)}
								/>
							</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New timeline"
									name="timeline"
									items={[INVESTMENT_TIMELINE_RANGES.LESS_THAN_A_YEAR,
										INVESTMENT_TIMELINE_RANGES.ONE_TO_FIVE_YEARS,
										INVESTMENT_TIMELINE_RANGES.FIVE_TO_TEN_YEARS,
										INVESTMENT_TIMELINE_RANGES.TEN_TO_FIFTEEN_YEARS,
										INVESTMENT_TIMELINE_RANGES.OVER_FIFTEEN_YEARS,
									]}
									value={valueOrEmpty(InvestmentProfile.Timeline)}
								/>
							</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New knowledge"
									name="experience"
									items={[{ value: 'Limited', text: 'Limited' },
										{ value: 'Good', text: 'Good' },
										{ value: 'Excellent', text: 'Excellent' }]}
									value={valueOrEmpty(InvestmentProfile.Experience)}
								/>
							</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New risk tolerance"
									name="riskTolerance"
									items={[{ value: 'Low', text: 'Low' },
										{ value: 'Moderate', text: 'Moderate' },
										{ value: 'Aggressive', text: 'Aggressive' },
										{ value: 'Speculative', text: 'Speculative' }]}
									value={valueOrEmpty(InvestmentProfile.RiskTolerance)}
								/>
							</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New liquidity"
									name="liquidity"
									items={[LIQUIDITY_IMPORTANCE_RANGES.VERY_IMPORTANT,
										LIQUIDITY_IMPORTANCE_RANGES.IMPORTANT,
										LIQUIDITY_IMPORTANCE_RANGES.SOMEWHAT_IMPORTANT,
										LIQUIDITY_IMPORTANCE_RANGES.DOES_NOT_MATTER]}
									value={valueOrEmpty(InvestmentProfile.Liquidity)}
								/>
							</div>
							<div className={classes.divider} />
							<div className={classes.subHeader}>Assets</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New liquid net worth"
									name="liquid"
									items={NetWorthList}
									value={valueOrEmpty(InvestmentProfile.Liquid)}
								/>
							</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New total net worth"
									name="netWorth"
									items={NetWorthList}
									value={valueOrEmpty(InvestmentProfile.NetWorth)}
								/>
							</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New annual income"
									name="annualIncome"
									items={annualIncomeItems}
									value={valueOrEmpty(InvestmentProfile.AnnualIncome)}
								/>
							</div>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="New tax bracket"
									name="taxBracket"
									items={TaxBracketList}
									value={valueOrEmpty(InvestmentProfile.TaxBracket)}
								/>
							</div>
							{!pristine && this.showChangeReasonField() &&
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="changeReason"
										placeholder="Reason for change"
										validationMessageMode={ValidationMessageMode.AFTER_ADDITIONAL}
									/>
								</div>
							}
							<div className={classes.divider} />
							<Field
								name="accreditedInvestor"
								component={FormCheckbox}
								label="Accredited investor"
							/>
							<Tooltip title={ACCREDITED_INVESTOR_TOOLTIP_TEXT}>
								<IconButton>
									<SvgIcon icon="Help" />
								</IconButton>
							</Tooltip>
							<div className={classes.divider} />
							<Field
								name="qualifiedClient"
								component={FormCheckbox}
								label="Qualified client"
							/>
							<Tooltip title={QUALIFIED_CLIENT_TOOLTIP_TEXT}>
								<IconButton>
									<SvgIcon icon="Help" />
								</IconButton>
							</Tooltip>

							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={invalid || submitting || pristine}
								>
									{submitting ?
										<CircularProgress
											style={{ color: '#1a96ff' }}
											size={18}
										/>
										: 'Change Investment Profile'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeInvestmentProfileModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	clientType: PropTypes.oneOf(Object.values(ClientTypes)),
	pristine: PropTypes.bool.isRequired,
	profileSubmitting: PropTypes.bool.isRequired,
	pendingOrder: PropTypes.bool,
	investmentProfile: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	liquidValue: PropTypes.string,
	totalValue: PropTypes.string,
	annualIncomeValue: PropTypes.string,
	taxBracketValue: PropTypes.string,
	pendingOrderEmployment: PropTypes.bool,
};

ChangeInvestmentProfileModal.defaultProps = {
	pendingOrder: undefined,
	clientType: undefined,
	liquidValue: undefined,
	totalValue: undefined,
	annualIncomeValue: undefined,
	taxBracketValue: undefined,
	pendingOrderEmployment: undefined,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: CHANGE_INVESTMENT_PROFILE_FORM_ID,
		validate: ChangeInvestmentProfileModal.validate,
		enableReinitialize: true,
	}),
)(ChangeInvestmentProfileModal);
