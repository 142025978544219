import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { toUsaFormat } from '@global';

const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'left',
		justifyContent: 'left',
	},
	rightColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'right',
		justifyContent: 'right',
	},
	title: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		width: '120px',
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	amount: {
		...theme.typography.style.subheading,
	},
	status: {
		...theme.typography.style.body,
	},
	amountData: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'flex-start',
		textAlign: 'right',
	},
	red: {
		color: theme.typography.color.red,
	},
	green: {
		color: theme.typography.color.green,
	},
	black: {
		color: theme.typography.color.primary,
	},
	grey: {
		color: theme.typography.color.secondary,
	},
});

class TradeItem extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		const colorCssClass = classNames({
			[classes.red]: item.Color === 'red',
			[classes.green]: item.Color === 'green',
			[classes.black]: item.Color === 'black',
			[classes.grey]: item.Color === 'grey',
		});

		let directionText;

		switch (item.Direction) {
		case 'buy':
			directionText = 'Buy';
			break;

		case 'sell':
			directionText = 'Sell';
			break;

		default:
			directionText = 'Unknown';
		}

		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					<div className={classes.title}>
						{item.Quantity} &times; {item.Symbol}
					</div>
					<div className={classes.date}>
						{moment(item.OrderDate).format('D MMM YYYY')}
					</div>
				</div>
				<div className={classes.rightColumn}>
					<div className={classes.amountData}>
						<div className={classes.amount}>
							<div className={colorCssClass}>
								{toUsaFormat(item.Amount)}
							</div>
						</div>
						<div className={classes.status}>
							<div className={colorCssClass}>
								{item.Status}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

TradeItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(TradeItem);
