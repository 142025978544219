import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { app, linkedAccounts, userAccounts } from '@redux';
import { Button } from '@components';


const styles = theme => ({
	root: {},
	button: {
		marginTop: '32px',
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
		unlinkBankAccount: payload => dispatch(linkedAccounts.actions.achUnlinkBankAccount.request(payload)),
	},
});


class AchLinkRemoveComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
		};
	}

	unlinkAccount = (bankAccount) => {
		this.props.actions.showConfirmModal({
			action: onEndRequest => this.props.actions.unlinkBankAccount({
				tradeCode: this.props.tradeAccount.tradeCode,
				bankCode: bankAccount.Code,
				onEndRequest,
			}),
		});
	}

	render() {
		const {	achBankAccount, classes } = this.props;
		const { submitting } = this.state;

		return (
			<React.Fragment>
				<p>
					You already have a linked bank account.<br />
					{achBankAccount.CanUnlink &&
						<span>Would you like to remove it and link a new one?</span>
					}
				</p>
				{achBankAccount.CanUnlink &&
					<div className={classes.button}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							type="submit"
							onClick={() => this.unlinkAccount(achBankAccount)}
							disabled={submitting}
						>
							{submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								:
								<span>
									{/* <SvgIcon icon="Delete" /> */}
									Unlink
								</span>
							}
						</Button>
					</div>
				}
			</React.Fragment>
		);
	}
}

AchLinkRemoveComponent.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	tradeAccount: PropTypes.object.isRequired,
	achBankAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(AchLinkRemoveComponent);
