import { providerWithAuth, provider } from '@api';
import { env } from '@config';

module.exports = {
	autologon: autologin => providerWithAuth().post('/account/api_autologon', { autologin }),
	// login: (username, password) => providerWithAuth().post('/account/api_logon', { username, password }),
	loginLocal: (username, password) => providerWithAuth().post('/account/apiui_logon', { username, password }),
	language: (lang) => provider.get(`/account/apiui_changeculture/${lang}`),
	logout: () => providerWithAuth().get('/account/api_logoff'),
	pingauth: () =>	providerWithAuth().get('/account/apiui_pingauth'),
	sendconfirmationcode: (phone, reason) => providerWithAuth().post('/account/api_sendconfirmationcode', { phone, reason }),
	sendconfirmationcodeemail: email => providerWithAuth().post('/account/api_sendtwofactorconfirmationcode_email', { email }),
	version: () =>	providerWithAuth().get('/account/api_version/' + (env.firmCode ? env.firmCode : '')),
};
