import { fork, takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BaseGetSagaHandler, callApi, snackbar, userAccounts } from '@redux';
import { getNotifications, markNewsAsRead, markNewsAllAsRead } from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl } from '@global';
import actions from './actions';

function* getNotificationsRequestSaga({ payload: { page, pageSize } }) {
	try {
		const request = yield callApi(getNotifications, page, pageSize);

		if (!request.data.Success) {
			yield put(actions.getNotificationsFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Get Notifications', text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.getNotificationsSuccess(
				{
					page,
					pageSize,
				},
				request.data.Notifications,
			));
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get Notifications', text: error.message }));
		yield put(actions.getNotificationsFailure(error.message));
	}
}

function* getMoreNotificationsRequestSaga({ payload: { page, pageSize } }) {
	try {
		const request = yield callApi(getNotifications, page, pageSize);

		if (!request.data.Success) {
			yield put(actions.getMoreNotificationsFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Get More Notifications', text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.getMoreNotificationsSuccess(
				{
					page,
					pageSize,
				},
				request.data.Notifications ? request.data.Notifications.Notifications : [],
			));
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get More Notifications', text: error.message }));
		yield put(actions.getMoreNotificationsFailure(error.message));
	}
}

function* getShowMessageModalRequestSaga({ payload: { message } }) {
	try {
		// get current location
		const location = yield select(state => state.router.location);
		// show modal
		yield put(push(addHashToUrl(location, ModalPopups.MESSAGE)));

		if (!message.Read) {
			const response = yield callApi(markNewsAsRead, message.Id);
			if (response.data.Success) {
				yield put(actions.markNewsAsRead(message.Id));
			}
		}
	} catch (error) {
		window.Raven.captureException(error);
	}
}

function* hideMessageModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.MESSAGE)));
}
function* markNewsAllAsReadSaga({ payload: { onEndRequest, ...others } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: markNewsAllAsRead,
		errorText: 'Notifications - Mark all as read',
		handler: actions.markNewsAllAsRead,
		onEndRequest,
	}, { payload: others });

	if (isSuccess) {
		const { tradeCode } = yield select(userAccounts.selectors.currentTradeAccount);
		yield put(actions.getNotificationsRequest(tradeCode));
	}
}

function* watchGetNotificationsRequest() {
	yield takeEvery(actions.GET_NOTIFICATIONS_REQUEST, getNotificationsRequestSaga);
}
function* watchGetMoreNotificationsRequest() {
	yield takeEvery(actions.GET_MORE_NOTIFICATIONS_REQUEST, getMoreNotificationsRequestSaga);
}
function* watchShowMessageModalRequest() {
	yield takeEvery(actions.SHOW_MESSAGE_MODAL, getShowMessageModalRequestSaga);
}
function* watchHideMessageModal() {
	yield takeEvery(actions.HIDE_MESSAGE_MODAL, hideMessageModal);
}

function* watchMarkNewsAllAsReadRequest() {
	yield takeEvery(actions.markNewsAllAsRead.REQUEST, markNewsAllAsReadSaga);
}

// Root saga
const rootSaga = [
	fork(watchGetNotificationsRequest),
	fork(watchGetMoreNotificationsRequest),
	fork(watchShowMessageModalRequest),
	fork(watchHideMessageModal),
	fork(watchMarkNewsAllAsReadRequest),
];

export default {
	rootSaga,
};
