import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { A } from '@components';
import { toUsaDateFormat } from '@global';

const styles = theme => ({
	title: {
		...theme.typography.style.subheading,
	},
	table: {
		width: '100%',
		verticalAlign: 'top',
		borderCollapse: 'collapse',
		'& th': {
			...theme.typography.style.body,
			color: theme.typography.color.secondary,
			padding: '10px',
			verticalAlign: 'top',
			textAlign: 'center',
		},
		'& td': {
			padding: '10px',
			textAlign: 'center',
		},
		'& tbody': {
			verticalAlign: 'top',
		},
	},
	tableRow: {
		borderBottom: '1px solid #deeaef',
		'& td': {
		},
	},
	dateCol: {
		whiteSpace: 'nowrap',
	},
});


class EarningsTable extends React.Component {
	render() {
		const {
			data,
			classes,
		} = this.props;

		if (!data || !Array.isArray(data) || data.length === 0) {
			return (<p className={classes.title}>No Earnings Today</p>);
		}

		return (
			<div>
				<p>* = BTO - Before open, MDT - During trading, AMC - After close</p>
				<table className={classes.table}>
					<thead>
						<tr>
							<th style={{ textAlign: 'left' }}>Ticker</th>
							<th style={{ textAlign: 'left' }}>Sector</th>
							<th style={{ textAlign: 'left' }}>Industry</th>
							<th>Report Date</th>
							<th style={{ textAlign: 'right' }}>Actual EPS</th>
							<th style={{ textAlign: 'right' }}>Consensus EPS</th>
							<th style={{ textAlign: 'right' }}>Estimated EPS</th>
							<th>Announce Time*</th>
							<th style={{ textAlign: 'right' }}>EPS Surprise</th>
							<th>Fiscal Period</th>
							<th>Fiscal End Date</th>
						</tr>
					</thead>
					<tbody>
						{data && data.map((item, index) => (
							<tr className={classes.tableRow} key={index}>
								<td style={{ textAlign: 'left' }}>
									<A target="_blank" href={`https://stocks-adviser.com/${item.Symbol}/about/`}>{item.Symbol}</A>
								</td>
								<td style={{ textAlign: 'left' }}>{item.Sector}</td>
								<td />
								<td className={classes.dateCol}>{toUsaDateFormat(item.EpsReportDate)}</td>
								<td style={{ textAlign: 'right' }}>{item.ActualEps}</td>
								<td style={{ textAlign: 'right' }}>{item.ConsensusEps}</td>
								<td style={{ textAlign: 'right' }}>{item.EstimatedEps}</td>
								<td>{item.AnnounceTime}</td>
								<td style={{ textAlign: 'right' }}>{item.EpsSurpriseDollar}</td>
								<td>{item.FiscalPeriod}</td>
								<td className={classes.dateCol}>{toUsaDateFormat(item.FiscalEndDate)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

EarningsTable.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.array.isRequired,
};

export default compose(withStyles(styles))(EarningsTable);
