import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { SvgIcon } from '@components';
import { toUsaMoneyFormat } from '@global';
import { ipo } from '@redux';


const styles = theme => ({
	root: {
		display: 'flex',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '105px',
	},
	secondColumn: {
		marginLeft: '16px',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		width: '100px',
	},
	middleColumn: {
		marginLeft: '16px',
		// flexGrow: '2',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		maxWidth: '200px',
	},
	rightColumn: {
		flexGrow: '2',
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'flex-start',
		textAlign: 'right',
	},
	rightPadding: {
		width: '100%',
		marginRight: '62px',
	},
	prevRight: {
		width: '100%',
		marginRight: '14px',
	},
	title: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	companyName: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	amount: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		fontSize: '18px',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		textAlign: 'right',
	},
	logo: {
		maxWidth: '85px',
		maxHeight: '48px',
	},
});


const mapDispatchToProps = dispatch => ({
	actions: {
		showIpoCreateOrderModal: payload => dispatch(ipo.actions.showIpoCreateOrderModal(payload)),
	},
});


class OfferingMainInfo extends React.Component {
	render() {
		const {
			actions,
			classes,
			item,
		} = this.props;
		const sPrice = [toUsaMoneyFormat(item.MinPrice || 0), toUsaMoneyFormat(item.MaxPrice)].filter(x => !!x).join(' - ');

		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					{item.LogoSmall &&
						<img className={classes.logo} src={item.LogoSmall} alt={`${item.Symbol} logo`} />
					}
				</div>
				<div className={classes.secondColumn}>
					<div className={classes.title}>
						{item.Symbol}
					</div>
					{item.TradeDate &&
						<div className={classes.date}>
							{moment.utc(item.TradeDate).format('D MMM YYYY')}
						</div>
					}
				</div>
				<div className={classes.middleColumn}>
					<div className={classes.companyName}>
						{item.Name}
					</div>
					<div className={classes.date}>
						{item.OfferingTypeName}
					</div>
				</div>
				<div className={classes.rightColumn}>
					<div className={classes.prevRight}>
						<div className={classes.amount}>
							<span>{sPrice}</span>
						</div>
					</div>
					{item.AllowOrderEffective &&
						<IconButton
							onClick={!item.HaveOrders ?
								() => actions.showIpoCreateOrderModal({ OfferingId: item.OfferingId }) :
								x => x
							}
							disabled={item.HaveOrders}
						>
							<SvgIcon
								className={classes.headToggleIcon}
								icon="Gavel"
								htmlColor={!item.HaveOrders ? 'green' : 'gray'}
							/>
						</IconButton>
					}
				</div>
			</div>
		);
	}
}

OfferingMainInfo.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(OfferingMainInfo);
