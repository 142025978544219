import { Input } from '@components';
import { createComponent, mapErrorAfterInit } from '../utils';

export default createComponent(
	Input,
	({
		defaultValue,
		...props
	}) => ({
		...mapErrorAfterInit(props),
	}),
);
