import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profile, userAccounts } from '@redux';
import { Modal, SuccessMessage, ErrorMessage, ProgressContainer, R } from '@components';
import { CYChangePasswordForm } from '@shared';


const styles = {};

const mapStateToProps = (state) => ({
	submitting: userAccounts.selectors.userInfo(state).submitting,
});


class CYChangePasswordModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 'openform',
		};

		this.resetForm = this.resetForm.bind(this);
	}

	onFormSuccess = () => {
		this.setState({ step: 'succeeded' });
	}

	onFormFail = () => this.setState({ step: 'failed' });

	resetForm = () => this.setState({ step: 'openform' });

	onSuccessedClick = () => {
		const {
			onClose,
			onSuccess,
		} = this.props;

		if (onSuccess) {
			onSuccess();
			this.resetForm();
		}
		else {
			onClose();
		}
	}

	render() {
		const {
			onClose,
			submitting,
		} = this.props;

		const { step } = this.state;
		return (
			<Modal 				
				title={<R id="comon_settings_password_copy" defaultValue="Change password" />}
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
			>
				<ProgressContainer submitting={submitting}>
					<div>
						{step === 'succeeded' &&
							<SuccessMessage onClick={this.onSuccessedClick} text={<R id="common_change_password" defaultValue="Change password completed" />} />
						}
						{step === 'failed' &&
							<ErrorMessage onClick={this.resetForm} text={<R id="common_could_not_change_password" defaultValue="Could not change password" />} />
						}
						{step === 'openform' &&
							<CYChangePasswordForm
								enableReinitialize
								forceUnregisterOnUnmount
								onSubmit={profile.actions.changePasswordFormSubmit}
								onSuccess={this.onFormSuccess}
								onSubmitFail={this.onFormFail}
							/>
						}
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

CYChangePasswordModal.propTypes = {
	submitting: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(CYChangePasswordModal);
