import { InputNumber } from '@components';
import { createComponent, mapError } from '../utils';

export default createComponent(
	InputNumber,
	({
		defaultValue,
		...props
	}) => ({
		...mapError(props),
	}),
);
