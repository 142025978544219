import { fork, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	createApiKey,
	getApiKeys,
	revokeApiKey,
	apiMarketDataAgreement,
} from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl } from '@global';
import { BaseGetSagaHandler, etnaAuth, userAccounts } from '@redux';
import actions from './actions';


function* watchGetApiKeysRequest() {
	yield takeEvery(actions.getApiKeys.REQUEST, BaseGetSagaHandler, {
		apiMethod: getApiKeys,
		errorText: 'Get Clients',
		handler: actions.getApiKeys,
	});
}

function* revokeApiKeySagaHandler({ payload: { onEndRequest, ...others } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: revokeApiKey,
		errorText: 'API Key Revoke',
		handler: actions.revokeApiKey,
		onEndRequest,
	}, { payload: others });

	if (isSuccess) {
		yield put(actions.getApiKeys.request());
	}
}

function* createApiKeyRequestSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: createApiKey,
		errorText: 'Create API Key',
		handler: actions.createApiKey,
	}, { payload });

	if (isSuccess) {
		yield put(actions.getApiKeys.request());
	}
}

function GetModalPopupType(type) {
	switch (type) {
		case actions.SHOW_CREATE_APIKEY_MODAL:
			return ModalPopups.CREATE_APIKEY;
		default:
			return '';
	}
}

function* showModal({ type }) {
	const location = yield select(state => state.router.location);
	yield put(push(addHashToUrl(location, GetModalPopupType(type))));
}

function* hideModal({ payload }) {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, payload)));
}

function* apiKeyModalInit() {
	let clients = yield select(etnaAuth.selectors.clients);
	const currentAccount = yield select(userAccounts.selectors.currentTradeAccount);

	if (!clients.submitting && !clients.loaded) {
		yield put(actions.getApiKeys.request());
	}

	clients = yield select(etnaAuth.selectors.clients);

	let defaultClientId = `trading-app-${currentAccount.tradeCode}`;
	let i = 2;
	while (clients.items.some(x => x.ClientId.toLowerCase() === defaultClientId)) {
		defaultClientId = `trading-app-${currentAccount.tradeCode}-${i++}`;
	}

	yield put(actions.createApiKey.request({ clientId: defaultClientId }));
}

function* watchRevokeApiKeyRequest() {
	yield takeEvery(actions.revokeApiKey.REQUEST, revokeApiKeySagaHandler);
}

function* watchCreateApiKeyRequest() {
	yield takeEvery(actions.createApiKey.REQUEST, createApiKeyRequestSaga);
}

function* watchShowModal() {
	yield takeEvery([
		actions.SHOW_CREATE_APIKEY_MODAL,
	], showModal);
}

function* watchHideModal() {
	yield takeEvery(actions.HIDE_MODAL, hideModal);
}

function* watchApiKeyModalInit() {
	yield takeEvery(actions.apiKeyModalInit.REQUEST, apiKeyModalInit);
}

function* apiMarketDataAgreementSaga({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: apiMarketDataAgreement,
		errorText: 'Api Market Data Agreement',
		handler: actions.apiMarketDataAgreement,
	}, { payload });
}

function* watchChangeApiMarketDataAgreement() {
	yield takeEvery(actions.apiMarketDataAgreement.REQUEST, apiMarketDataAgreementSaga);
}

// Root saga
const rootSaga = [
	fork(watchGetApiKeysRequest),
	fork(watchRevokeApiKeyRequest),
	fork(watchCreateApiKeyRequest),
	fork(watchShowModal),
	fork(watchHideModal),
	fork(watchApiKeyModalInit),
	fork(watchChangeApiMarketDataAgreement),
];

export default {
	rootSaga,
};
