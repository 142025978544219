/* eslint-disable import/extensions */
import React from 'react';
import { auth } from '@redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
});

const mapDispatchToProps = dispatch => ({
	actions: {
		autologinRequest: autologin => dispatch(auth.actions.autologinRequest(autologin)),
	},
});

class AutoLogin extends React.Component {
	componentDidMount() {
		this.props.actions.autologinRequest(this.props.autologin);
	}

	render() {
		return (
			<CircularProgress
				style={{ color: '#f9fcfd' }}
				size={58}
			/>
		);
	}
}

AutoLogin.propTypes = {
	actions: PropTypes.object.isRequired,
	autologin: PropTypes.string.isRequired,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(AutoLogin);
