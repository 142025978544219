import { ModalPopups } from '@enums';
import actions from './actions';
import auth from '../auth';

const initialState = {
	closeAccountData: {
		request: null,
		currentStep: null,
		cashAmount: null,
		pennyStockAttachments: null,
		submitting: false,
		submitSucceeded: false,
	},
	tradeAccount: null,
	closeAccountValidation: {
		submitting: false,
		submitSucceeded: false,
		isValid: false,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.SHOW_ACAT_DEPOSIT_MODAL:
			return {
				...state,
				...payload,
				closeAccountData: {},
			};
		case actions.HIDE_ACAT_DEPOSIT_MODAL:
			return {
				...state,
				closeAccountData: {},
				tradeAccount: null,
			};

		case actions.CLOSE_ACCOUNT_FORM_SUBMIT_REQUEST:
		case actions.CLOSE_ACCOUNT_CONTINUE_REQUEST:
			return {
				...state,
				closeAccountData: {
					...state.closeAccountData,
					request: payload,
					submitting: true,
					submitSucceeded: false,
				},
			};
		case actions.CLOSE_ACCOUNT_FORM_SUBMIT_FAILURE:
			return {
				...state,
				closeAccountData: {
					...state.closeAccountData,
					submitting: false,
					submitSucceeded: false,
				},
			};
		case actions.CLOSE_ACCOUNT_CONTINUE_FAILURE:
			return {
				...state,
				closeAccountData: {
					...state.closeAccountData,
					request: {
						...state.closeAccountData.request,
						CloseAccountOrderId: (payload && payload.OrderCode !== null)
							? payload.OrderCode
							: state.closeAccountData.request.CloseAccountOrderId,
					},
					currentStep: (payload && payload.ErrorCode !== null) ? payload.ErrorCode : state.closeAccountData.currentStep,
					cashAmount: payload && payload.CashAmount,
					pennyStockAttachments: payload && payload.PennyStockAttachments,
					submitting: false,
					submitSucceeded: false,
				},
			};
		case actions.CLOSE_ACCOUNT_FORM_SUBMIT_SUCCESS:
		case actions.CLOSE_ACCOUNT_CONTINUE_SUCCESS:
			return {
				...state,
				closeAccountData: {
					...state.closeAccountData,
					submitting: false,
					submitSucceeded: true,
				},
			};

		case actions.SHOW_CLOSE_ACCOUNT_FORM_MODAL:
			return {
				...state,
				tradeAccount: null,
				closeAccountData: {
					...state.closeAccountData,
				},
			};
		case actions.HIDE_CLOSE_ACCOUNT_FORM_MODAL:
			return {
				...state,
				closeAccountData: {
					submitting: false,
					submitSucceeded: false,
				},
				tradeAccount: null,
			};
		case auth.actions.LOGOUT_SUCCESS:
			return { ...initialState };
		case actions.SHOW_CLOSE_ACCOUNT_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CLOSE_ACCOUNT]: payload,
				},
			};
		case actions.closeAccountValidation.REQUEST:
			return {
				...state,
				closeAccountValidation: {
					...state.closeAccountValidation,
					submitting: true,
					submitSucceeded: false,
				},
			};
		case actions.closeAccountValidation.SUCCESS:
			return {
				...state,
				closeAccountValidation: {
					...state.closeAccountValidation,
					submitting: false,
					submitSucceeded: true,
				},
			};
		case actions.closeAccountValidation.FAILURE:
			return {
				...state,
				closeAccountValidation: {
					...state.closeAccountValidation,
					submitting: false,
					submitSucceeded: false,
				},
			};
		default:
			return state;
	}
};
