import { createApiActions } from '../extensions';

// reports for lime international
const actions = {
	generateBrokerReport: createApiActions('REPORTS/GENERATE_BROKER_REPORT'),
	getBrokerReports: createApiActions('REPORTS/GET_BROKER_REPORTS'),
	
	// getReportFile: createApiActions('REPORTS/GET_REPORT_FILE'),
};

export default actions;
