import { fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SubmissionError } from 'redux-form';
import { BaseGetSagaHandler, callApi, snackbar, userAccounts, profile as profileModule } from '@redux';
import {
	profile,
	changeTariff,
	changeAddress,
	changeAddressForClient,
	changePostAddress,
	changePostAddressForClient,
	changeEmail,
	changeEmailForClient,
	changePassword,
	changePhone,
	changePhoneForClient,
	confirmEmail,
	changeDeliveryType,
	changeDependents,
	changeMaritalStatus,
	changeEmployment,
	changeEmploymentForClient,
	changeInvestmentExperience,
	changeInvestmentProfile,
	changeMarketDataSubscriberStatus,
	changeMarketDataSubscriptions,
	changeTwoFactorAuthentication,
	changeTaxFormUpload,
	changeTaxFormFillIn,
	confirmProfile,
	enableThirdPartyMarketData,
	getCountries,
	getHolderProfiles,
	getUsaStates,
	sendEmailConfirmationCode,
	enableTotpAuthentication,
	changeTwoFactorAuthenticationEmail,
} from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl, cabinetLog, captureException, removeHashFromUrl } from '@global';
import actions from './actions';

function* profileSaga() {
	try {
		const response = yield callApi(profile);
		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get User Profile',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.getProfileFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.getProfileSuccess(response.data.ProfileData));
		}
	} catch (error) {
		captureException('profileSaga', error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get User Profile', text: error.message }));
		yield put(actions.getProfileFailure(error.message));
	}
}

function* confirmProfileFormHideAndRedirect() {
	yield put(push('/dashboard'));
}

function* confirmProfileFormSubmit({ payload }) {
	try {
		const request = yield callApi(confirmProfile, payload);
		if (!request.data.Success) {
			yield put(actions.confirmProfileFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.confirmProfileFormSuccess());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.confirmProfileFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* confirmProfileFormSubmitSuccessHandler() {
	yield put(actions.getProfileRequest());
}

function* isCurrentUser({ guid, clientId }) {
	if (!guid) {
		return !clientId;
	}
	const userInfo = yield select(userAccounts.selectors.userInfo);
	return 	guid.toLowerCase() === userInfo.Guid.toLowerCase();
}
function* refreshClientOrHolderProfile(isCurrent) {
	if (isCurrent) {
		yield put(actions.getProfileRequest());
	} else {
		yield put(actions.getHolderProfiles.request());
	}
}

function* changeAddressFormSubmit({ payload }) {
	try {
		const { clientId, guid, ...otherPayload } = payload;
		const isCurrent = yield* isCurrentUser({ guid, clientId });
		let request;
		if (isCurrent) {
			request = yield callApi(changeAddress, otherPayload);
		} else {
			request = yield callApi(changeAddressForClient, otherPayload, clientId);
		}
		cabinetLog('change address', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileAddressFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileAddressFormSuccess());
			yield* refreshClientOrHolderProfile(isCurrent);
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileAddressFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changePostAddressFormSubmit({ payload }) {
	try {
		const { clientId, guid, ...otherPayload } = payload;
		const isCurrent = yield* isCurrentUser({ guid, clientId });
		let request;
		if (isCurrent) {
			request = yield callApi(changePostAddress, otherPayload);
		} else {
			request = yield callApi(changePostAddressForClient, otherPayload, clientId);
		}
		cabinetLog('change post address', request);

		if (!request.data.Success) {
			yield put(actions.changeProfilePostAddressFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfilePostAddressFormSuccess());
			yield* refreshClientOrHolderProfile(isCurrent);
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfilePostAddressFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeDependentsFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeDependents, payload);
		cabinetLog('change Dependents', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileDependentsFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileDependentsFormSuccess());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileDependentsFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeMaritalStatusFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeMaritalStatus, payload);
		cabinetLog('change MaritalStatus', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileMaritalStatusFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileMaritalStatusFormSuccess());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileMaritalStatusFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeEmploymentFormSubmit({ payload }) {
	try {
		const { clientId, guid, ...otherPayload } = payload;
		const isCurrent = yield* isCurrentUser({ guid, clientId });
		let request;
		if (isCurrent) {
			request = yield callApi(changeEmployment, otherPayload);
		} else {
			request = yield callApi(changeEmploymentForClient, otherPayload, clientId);
		}
		cabinetLog('change Employment(', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileEmploymentFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileEmploymentFormSuccess());
			yield* refreshClientOrHolderProfile(isCurrent);
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileEmploymentFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeInvestmentProfileFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeInvestmentProfile, payload);
		cabinetLog('change InvestmentProfile', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileInvestmentProfileFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileInvestmentProfileFormSuccess());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileInvestmentProfileFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeInvestmentExperienceFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeInvestmentExperience, payload);
		cabinetLog('change InvestmentExperience', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileInvestmentExperienceFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileInvestmentExperienceFormSuccess());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileInvestmentExperienceFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}


function* changeEmailFormSubmit({ payload }) {
	try {
		const { clientId, guid, ...otherPayload } = payload;
		const isCurrent = yield* isCurrentUser({ guid, clientId });
		let request;
		if (isCurrent) {
			request = yield callApi(changeEmail, otherPayload);
		} else {
			request = yield callApi(changeEmailForClient, otherPayload, clientId);
		}
		cabinetLog('change Email', request);

		if (!request.data.Success) {
			yield put(actions.changeProfileEmailFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfileEmailFormSuccess());
			yield* refreshClientOrHolderProfile(isCurrent);
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfileEmailFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}


function* changePhoneFormSubmit({ payload }) {
	try {
		const { clientId, guid, ...otherPayload } = payload;
		const isCurrent = yield* isCurrentUser({ guid, clientId });
		let request;
		if (isCurrent) {
			request = yield callApi(changePhone, otherPayload);
		} else {
			request = yield callApi(changePhoneForClient, otherPayload, clientId);
		}
		cabinetLog('change Phone', request);

		if (!request.data.Success) {
			yield put(actions.changeProfilePhoneFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeProfilePhoneFormSuccess());
			yield* refreshClientOrHolderProfile(isCurrent);
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeProfilePhoneFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeTwoFactorAuthenticationFormSubmit({ payload }) {
	try {
		const currentAccount = yield select(userAccounts.selectors.currentTradeAccount);
		const request = yield callApi(changeTwoFactorAuthentication, {
			TradeCode: currentAccount.tradeCode,
			ClearerFirm: currentAccount.clearerFirm,
			ConfirmationCode: payload.ConfirmationCode,
			EnableSecondFactor: payload.EnableSecondFactor,
		});
		cabinetLog('change TwoFactorAuthentication', request);

		if (!request.data.Success) {
			yield put(actions.changeTwoFactorAuthorizationForm.failure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeTwoFactorAuthorizationForm.success());
			// refresh IsSecondFactorAuthorization
			yield put(userAccounts.actions.getAccountsRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeTwoFactorAuthorizationForm.failure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeMarketDataSubscriberStatusFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeMarketDataSubscriberStatus, payload);
		cabinetLog('change MarketDataSubscriberStatus', request);

		if (!request.data.Success) {
			yield put(actions.changeMarketDataSubscriberStatusForm.failure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeMarketDataSubscriberStatusForm.success());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeMarketDataSubscriberStatusForm.failure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}
function* changeMarketDataSubscriptionsFormSubmit({ payload }) {

	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: changeMarketDataSubscriptions,
		errorText: 'Change Market Data Subscriptions',
		handler: actions.changeMarketDataSubscriptionsForm,
	}, { payload });

	// if (isSuccess) {
	// 	const currentTradeAccount = yield select(userAccounts.selectors.currentTradeAccount);
	// 	yield put(userAccounts.actions.getAccountInfoRequest(currentTradeAccount));
	// }
}

function* enableThirdPartyMarketDataFormSubmit() {
	try {
		const request = yield callApi(enableThirdPartyMarketData);
		cabinetLog('enable ThirdPartyMarketData', request);

		if (!request.data.Success) {
			yield put(actions.enableThirdPartyMarketDataForm.failure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.enableThirdPartyMarketDataForm.success());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.enableThirdPartyMarketDataForm.failure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeAccountTariffSubmit({ payload: { tradeCode, tariff } }) {
	try {
		const response = yield callApi(changeTariff, tradeCode, tariff);
		cabinetLog('change Account Tariff', response);

		if (!response.data.Success) {
			yield put(actions.changeAccountTariffFailure(response.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Change Account Tariff', text: response.data.Errors[0].Message }));
		} else {
			yield put(actions.changeAccountTariffSuccess());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeAccountTariffFailure(error));
		yield put(snackbar.actions.showErrorMessage({ title: 'Change Account Tariff', text: error.message }));
	}
}


function* hideConfirmEmailModal() {
	const { hash } = yield select(state => state.router.location);
	const request = yield put(push(`/dashboard${(hash || '')}`));
}


function* changeDeliveryTypeFormSubmitSaga({ payload }) {
	try {
		const response = yield callApi(changeDeliveryType, payload);
		if (!response.data.Success) {
			yield put(actions.changeDeliveryTypeFormFailure(new SubmissionError({
				_error: response.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: response.data.Errors[0].Message }));
		} else {
			yield put(actions.getProfileRequest());
			yield put(actions.changeDeliveryTypeFormSuccess());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.changeDeliveryTypeFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* changeTaxFormUploadFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeTaxFormUpload, payload);
		cabinetLog('change tax form upload', request);

		if (!request.data.Success) {
			yield put(actions.changeTaxFormUploadFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeTaxFormUploadFormSuccess());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeTaxFormUploadFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}
function* changeTaxFormFillInFormSubmit({ payload }) {
	try {
		const request = yield callApi(changeTaxFormFillIn, payload);
		cabinetLog('change tax form upload', request);

		if (!request.data.Success) {
			yield put(actions.changeTaxFormFillInFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changeTaxFormFillInFormSuccess());
			yield put(actions.getProfileRequest());
		}
	} catch (error) {
		Raven.captureException(error);
		yield put(actions.changeTaxFormFillInFormFailure(error));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}
function GetModalPopupType(actionType) {
	switch (actionType) {
	case actions.SHOW_CHANGE_DELIVERY_TYPE_MODAL:
		return ModalPopups.CHANGE_DELIVERY_TYPE;
	case actions.SHOW_CHANGE_ADDRESS_MODAL:
		return ModalPopups.CHANGE_PROFILE_ADDRESS;
	case actions.SHOW_CHANGE_POST_ADDRESS_MODAL:
		return ModalPopups.CHANGE_PROFILE_POST_ADDRESS;
	case actions.SHOW_CHANGE_EMAIL_MODAL:
		return ModalPopups.CHANGE_PROFILE_EMAIL;
	case actions.SHOW_CHANGE_PHONE_MODAL:
		return ModalPopups.CHANGE_PROFILE_PHONE;
	case actions.SHOW_CHANGE_DEPENDENTS_MODAL:
		return ModalPopups.CHANGE_DEPENDENTS;
	case actions.SHOW_CHANGE_MARITAL_STATUS_MODAL:
		return ModalPopups.CHANGE_MARITAL_STATUS;
	case actions.SHOW_CHANGE_EMPLOYMENT_MODAL:
		return ModalPopups.CHANGE_EMPLOYMENT;
	case actions.SHOW_CHANGE_INVESTMENT_PROFILE_MODAL:
		return ModalPopups.CHANGE_INVESTMENT_PROFILE;
	case actions.SHOW_CHANGE_INVESTMENT_EXPERIENCE_MODAL:
		return ModalPopups.CHANGE_INVESTMENT_EXPERIENCE;
	case actions.SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL:
		return ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL;
	case actions.SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL:
		return ModalPopups.CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL;
	case actions.SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL:
		return ModalPopups.ENABLE_THIRD_PARTY_MARKETDATA_MODAL;
	case actions.SHOW_CHANGE_TARIFF_PER_TRADE_MODAL:
		return ModalPopups.CHANGE_TARIFF_PER_TRADE;
	case actions.SHOW_CHANGE_TARIFF_PER_SHARE_MODAL:
		return ModalPopups.CHANGE_TARIFF_PER_SHARE;
	case actions.SHOW_CHANGE_TARIFF_ZERO_MODAL:
		return ModalPopups.CHANGE_TARIFF_ZERO;
	case actions.SHOW_CHANGE_TWO_FACTOR_AUTHORIZATION_MODAL:
		return ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION;
	case actions.SHOW_CONFIRM_PROFILE_MODAL:
		return ModalPopups.CONFIRM_PROFILE;
	case actions.SHOW_CHANGE_TAX_FORM_MODAL:
		return ModalPopups.CHANGE_TAX_FORM_MODAL;
	case actions.SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL:
		return ModalPopups.DEMO_ACCOUNT_EXPIRATION;
	default:
		return '';
	}
}

function* showProfileModal({ type }) {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, GetModalPopupType(type))));
}

function* hideProfileModal({ payload }) {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, payload)));
}

function* changePasswordFormSubmitSaga({ payload }) {
	try {
		const request = yield callApi(changePassword, payload);
		if (!request.data.Success) {
			yield put(actions.changePasswordFormSubmit.failure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Change Password', text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.changePasswordFormSubmit.success());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.changePasswordFormSubmit.failure(error));
	}
}

function* sendEmailConfirmationCodeRequestSaga({ payload: { email } }) {
	try {
		const request = yield callApi(sendEmailConfirmationCode, email);
		if (!request.data.Success) {
			yield put(actions.sendEmailConfirmationCode.failure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Send Confirmation Code', text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.sendEmailConfirmationCode.success());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.sendEmailConfirmationCode.failure(error));
	}
}

function* changeTotpAuthenticationRequestSaga({ payload }) {
	const errorTitle = 'Cannot change TOTP authentication';
	const profileState = yield select(profileModule.selectors.profile);
	const apiMethod = enableTotpAuthentication;
	const request = {
		code: payload.SecurityCode,
		guid: profileState.Guid,
	};

	try {
		const response = yield callApi(apiMethod, request);
		if (!response.data.Success) {
			yield put(actions.changeTotpAuthentication.failure(response.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: errorTitle, text: response.data.Errors[0].Message }));
		} else {
			yield put(actions.changeTotpAuthentication.success());
			// refresh 2FA Auth mark
			yield put(userAccounts.actions.getAccountsRequest());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.changeTotpAuthentication.failure(error));
		yield put(snackbar.actions.showErrorMessage({
			title: errorTitle,
			text: error.message,
		}));
	}
}

function* enableTwoFactorAuthEmailRequestSaga({ payload }) {
	const errorTitle = 'Cannot enable 2FA authentication';
	const apiMethod = changeTwoFactorAuthenticationEmail;
	const request = {
		code: payload.ConfirmationCode,
	};

	try {
		const response = yield callApi(apiMethod, request);
		if (!response.data.Success) {
			yield put(actions.enableTwoFactorAuthEmail.failure(response.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: errorTitle, text: response.data.Errors[0].Message }));
		} else {
			yield put(actions.enableTwoFactorAuthEmail.success());
			// refresh 2FA Auth mark
			yield put(userAccounts.actions.getAccountsRequest());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.enableTwoFactorAuthEmail.failure(error));
		yield put(snackbar.actions.showErrorMessage({
			title: errorTitle,
			text: error.message,
		}));
	}
}

// Watchers
function* watchProfile() {
	yield takeEvery(actions.GET_PROFILE_REQUEST, profileSaga);
}
function* watchGetHolderProfilesRequest() {
	yield takeEvery(actions.getHolderProfiles.REQUEST, BaseGetSagaHandler, {
		apiMethod: getHolderProfiles,
		errorText: 'Get Holder Profiles',
		handler: actions.getHolderProfiles,
	});
}
function* watchChangeAddressFormSubmit() {
	yield takeEvery(actions.CHANGE_ADDRESS_FORM_SUBMIT_REQUEST, changeAddressFormSubmit);
}
function* watchChangePostAddressFormSubmit() {
	yield takeEvery(actions.CHANGE_POST_ADDRESS_FORM_SUBMIT_REQUEST, changePostAddressFormSubmit);
}
function* watchChangeEmailFormSubmit() {
	yield takeEvery(actions.CHANGE_EMAIL_FORM_SUBMIT_REQUEST, changeEmailFormSubmit);
}
function* watchChangePhoneFormSubmit() {
	yield takeEvery(actions.CHANGE_PHONE_FORM_SUBMIT_REQUEST, changePhoneFormSubmit);
}
function* watchChangeTwoFactorAuthorizationFormSubmit() {
	yield takeEvery(actions.changeTwoFactorAuthorizationForm.REQUEST, changeTwoFactorAuthenticationFormSubmit);
}
function* watchConfirmEmailGetUrl() {
	yield takeEvery(actions.confirmEmailGetUrl.REQUEST, BaseGetSagaHandler, {
		apiMethod: confirmEmail,
		errorText: 'Confirm Email',
		handler: actions.confirmEmailGetUrl,
	});
}
function* watchChangeAccountTariffSubmit() {
	yield takeEvery(actions.CHANGE_ACCOUNT_TARIFF_REQUEST, changeAccountTariffSubmit);
}
function* watchHideConfirmEmailModal() {
	yield takeEvery(actions.HIDE_CONFIRM_EMAIL_MODAL, hideConfirmEmailModal);
}
function* watchChangeDependentsFormSubmit() {
	yield takeEvery(actions.CHANGE_DEPENDENTS_FORM_SUBMIT_REQUEST, changeDependentsFormSubmit);
}
function* watchChangeMaritalStatusFormSubmit() {
	yield takeEvery(actions.CHANGE_MARITAL_STATUS_FORM_SUBMIT_REQUEST, changeMaritalStatusFormSubmit);
}
function* watchChangeEmploymentFormSubmit() {
	yield takeEvery(actions.CHANGE_EMPLOYMENT_FORM_SUBMIT_REQUEST, changeEmploymentFormSubmit);
}
function* watchChangeInvestmentProfileFormSubmit() {
	yield takeEvery(actions.CHANGE_INVESTMENT_PROFILE_FORM_SUBMIT_REQUEST, changeInvestmentProfileFormSubmit);
}
function* watchChangeInvestmentExperienceFormSubmit() {
	yield takeEvery(actions.CHANGE_INVESTMENT_EXPERIENCE_FORM_SUBMIT_REQUEST, changeInvestmentExperienceFormSubmit);
}
function* watchConfirmProfileFormSubmit() {
	yield takeEvery(actions.CONFIRM_PROFILE_FORM_SUBMIT_REQUEST, confirmProfileFormSubmit);
}
function* watchConfirmProfileFormSubmitSuccess() {
	yield takeEvery(actions.CONFIRM_PROFILE_FORM_SUBMIT_SUCCESS, confirmProfileFormSubmitSuccessHandler);
}
function* watchConfirmProfileFormHideAndRedirect() {
	yield takeEvery(actions.CONFIRM_PROFILE_FORM_HIDE_AND_REDIRECT, confirmProfileFormHideAndRedirect);
}
function* watchChangeDeliveryTypeFormSubmit() {
	yield takeEvery(actions.PROFILE_CHANGE_DELIVERY_TYPE_FORM_SUBMIT_REQUEST, changeDeliveryTypeFormSubmitSaga);
}

function* watchChangeMarketDataSubscriberStatusFormSubmit() {
	yield takeEvery(actions.changeMarketDataSubscriberStatusForm.REQUEST, changeMarketDataSubscriberStatusFormSubmit);
}
function* watchChangeMarketDataSubscriptionsFormSubmit() {
	yield takeEvery(actions.changeMarketDataSubscriptionsForm.REQUEST, changeMarketDataSubscriptionsFormSubmit);
}
function* watchEnableThirdPartyMarketDataFormSubmit() {
	yield takeEvery(actions.enableThirdPartyMarketDataForm.REQUEST, enableThirdPartyMarketDataFormSubmit);
}
function* watchChangeTaxFormUploadFormSubmit() {
	yield takeEvery(actions.CHANGE_TAXFORM_UPLOAD_FORM_SUBMIT_REQUEST, changeTaxFormUploadFormSubmit);
}
function* watchChangeTaxFormFillInFormSubmit() {
	yield takeEvery(actions.CHANGE_TAXFORM_FILLIN_FORM_SUBMIT_REQUEST, changeTaxFormFillInFormSubmit);
}

function* watchShowProfileModal() {
	yield takeEvery([
		actions.SHOW_CHANGE_DELIVERY_TYPE_MODAL,
		actions.SHOW_CHANGE_ADDRESS_MODAL,
		actions.SHOW_CHANGE_POST_ADDRESS_MODAL,
		actions.SHOW_CHANGE_EMAIL_MODAL,
		actions.SHOW_CHANGE_PHONE_MODAL,
		actions.SHOW_CHANGE_DEPENDENTS_MODAL,
		actions.SHOW_CHANGE_MARITAL_STATUS_MODAL,
		actions.SHOW_CHANGE_EMPLOYMENT_MODAL,
		actions.SHOW_CHANGE_INVESTMENT_PROFILE_MODAL,
		actions.SHOW_CHANGE_INVESTMENT_EXPERIENCE_MODAL,
		actions.SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL,
		actions.SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL,
		actions.SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL,
		actions.SHOW_CHANGE_TARIFF_PER_TRADE_MODAL,
		actions.SHOW_CHANGE_TARIFF_PER_SHARE_MODAL,
		actions.SHOW_CHANGE_TARIFF_ZERO_MODAL,
		actions.SHOW_CHANGE_TAX_FORM_MODAL,
		actions.SHOW_CHANGE_TWO_FACTOR_AUTHORIZATION_MODAL,
		actions.SHOW_CONFIRM_PROFILE_MODAL,
		actions.SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL,
	], showProfileModal);
}
function* watchHideProfileModal() {
	yield takeEvery(actions.HIDE_PROFILE_MODAL, hideProfileModal);
}
function* watchGetCountriesRequest() {
	yield takeLatest(actions.getCountries.REQUEST, BaseGetSagaHandler, {
		apiMethod: getCountries,
		errorText: 'Get countries',
		handler: actions.getCountries,
	});
}
function* watchGetUsaStatesRequest() {
	yield takeLatest(actions.getUsaStates.REQUEST, BaseGetSagaHandler, {
		apiMethod: getUsaStates,
		errorText: 'Get USA states',
		handler: actions.getUsaStates,
	});
}

function* watchChangePasswordFormSubmit() {
	yield takeEvery(actions.changePasswordFormSubmit.REQUEST, changePasswordFormSubmitSaga);
}

function* watchSendConfirmationCodeRequest() {
	yield takeEvery(actions.sendEmailConfirmationCode.REQUEST, sendEmailConfirmationCodeRequestSaga);
}

function* watchChangeTotpAuthenticationRequest() {
	yield takeEvery(actions.changeTotpAuthentication.REQUEST, changeTotpAuthenticationRequestSaga);
}

function* watchEnableTwoFactorAuthEmailRequest() {
	yield takeEvery(actions.enableTwoFactorAuthEmail.REQUEST, enableTwoFactorAuthEmailRequestSaga);
}

// Root saga
const rootSaga = [
	fork(watchProfile),
	fork(watchGetHolderProfilesRequest),
	fork(watchChangeAddressFormSubmit),
	fork(watchChangePostAddressFormSubmit),
	fork(watchChangeEmailFormSubmit),
	fork(watchChangePhoneFormSubmit),
	fork(watchChangeTwoFactorAuthorizationFormSubmit),
	fork(watchChangeAccountTariffSubmit),
	fork(watchConfirmEmailGetUrl),
	fork(watchHideConfirmEmailModal),
	fork(watchChangeDependentsFormSubmit),
	fork(watchChangeMaritalStatusFormSubmit),
	fork(watchChangeEmploymentFormSubmit),
	fork(watchChangeInvestmentExperienceFormSubmit),
	fork(watchChangeInvestmentProfileFormSubmit),
	fork(watchConfirmProfileFormSubmit),
	fork(watchConfirmProfileFormSubmitSuccess),
	fork(watchConfirmProfileFormHideAndRedirect),
	fork(watchChangeDeliveryTypeFormSubmit),
	fork(watchChangeMarketDataSubscriberStatusFormSubmit),
	fork(watchChangeMarketDataSubscriptionsFormSubmit),
	fork(watchEnableThirdPartyMarketDataFormSubmit),
	fork(watchChangeTaxFormUploadFormSubmit),
	fork(watchChangeTaxFormFillInFormSubmit),
	fork(watchShowProfileModal),
	fork(watchHideProfileModal),
	fork(watchGetCountriesRequest),
	fork(watchGetUsaStatesRequest),
	fork(watchChangePasswordFormSubmit),
	fork(watchSendConfirmationCodeRequest),
	fork(watchChangeTotpAuthenticationRequest),
	fork(watchEnableTwoFactorAuthEmailRequest),
];

export default {
	rootSaga,
};
