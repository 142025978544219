import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toUsaMoneyFormat } from '@global';
import { userAccounts } from '@redux';
import AccountPropsView from './AccountPropsView';


const mapStateToProps = (state, ownProps) => {
	const { baseParams, submitting } = userAccounts.selectors.currentAccountData(state);
	return {
		tradeCode: ownProps.tradeCode || userAccounts.selectors.currentTradeAccount(state).tradeCode,
		loading: !!submitting,
		cashToWithdrawValue: !submitting && baseParams && baseParams.CashToWithdrawValue,
		totalAccountValue: !submitting && baseParams && baseParams.EquityValue,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
	},
});

class WithdrawInfo extends React.Component {
	handleRefresh = () => {
		const { actions, tradeCode } = this.props;
		actions.refreshAccountPortfolio(tradeCode);
	}

	render() {
		const {
			classes,
			loading,
			cashToWithdrawValue,
			totalAccountValue,
			theme,
			...props
		} = this.props;

		return (
			<AccountPropsView loading={loading} classes={classes} handleRefresh={this.handleRefresh}>
				<div>{`Total account value: ${toUsaMoneyFormat(totalAccountValue)}`}</div>
				<div>{`Cash to withdraw: ${toUsaMoneyFormat(cashToWithdrawValue)}`}</div>
			</AccountPropsView>
		);
	}
}

WithdrawInfo.propTypes = {
	classes: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	tradeCode: PropTypes.string.isRequired,
	cashToWithdrawValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
	totalAccountValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
};
WithdrawInfo.defaultProps = {
	classes: undefined,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
)(WithdrawInfo);
