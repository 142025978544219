import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { app } from '@redux';
import { makeStyles } from '@material-ui/core/styles';
import {
	DropDownMenu,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
} from '@components';
import { getAllowedLanguages, env } from '@config';

const useStyles = makeStyles({
	languageIcon: {
		width: '30px',
		height: '30px',
	},
});

const LanguageSelector = () => {
	const classes = useStyles();
	const language = useSelector(app.selectors.language);
	const dispatch = useDispatch();
 
	const allowedAndHiddenLanguages = getAllowedLanguages();
	const allowedLanguages = env.hiddenLangs
		? allowedAndHiddenLanguages.filter(it => !env.hiddenLangs.includes(it.language)) 
		: allowedAndHiddenLanguages;

	const getCurrentLanguageIcon = lang => (allowedAndHiddenLanguages.find(it => it.language === lang) ||
				allowedLanguages.find(it => it.language === 'en')).icon;

	const changeLanguage = lang => () => {
		dispatch(app.actions.changeLanguage(lang));
	};

	return (
		<React.Fragment>
			{ allowedLanguages && allowedLanguages.length > 1 &&
			<DropDownMenu
				openPosition="left"
				openButton={onClick => (
					<IconButton
						onClick={onClick}
					>
						<img
							className={classes.languageIcon}
							src={getCurrentLanguageIcon(language)}
							alt=""
						/>
					</IconButton>)
				}
			>
				<List>
					{allowedLanguages && allowedLanguages.map((item, index) => (
						<ListItem button key={index} onClick={changeLanguage(item.language)} >
							<ListItemIcon>
								<img
									className={classes.languageIcon}
									src={item.icon}
									alt={item.displayName}
								/>
							</ListItemIcon>
							<ListItemText
								primary={item.displayName}
							/>
						</ListItem>
					))}
				</List>
			</DropDownMenu>
			}
		</React.Fragment>
	);
};

export default LanguageSelector;
