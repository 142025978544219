module.exports = {
	scrollTo(elementId) {
		const target = document.getElementById(elementId || 'dashboard-top');
		if (target) {
			setTimeout(() => {
				window.scroll(0, target.offsetTop);
			}, 400);
		}
	},

	scrollToTop() {
		const target = document.getElementById('dashboard-top');
		if (target) {
			setTimeout(() => {
				window.scroll(0, target.offsetTop);
			}, 300);
		}
	},

	scrollIntoView(elementId) {
		let target = document.getElementById(elementId);
		target.scrollIntoView();
	},

};

// export const isStateLess = Component => !Component.prototype.render;