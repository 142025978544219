import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { userAccounts } from '@redux';
import { ChartThumbnail } from '@components';
import { toUsaMoneyFormat } from '@global';
import PropTypes from 'prop-types';
import { ValueChange } from '../';

const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexFlow: 'wrap',
			alignItems: 'flex-start',
		},
	},
	leftColumn: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	middleColumn: {
		[theme.breakpoints.down('sm')]: {
			order: 1,
		},
	},
	chartThumbnailPeriod: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		textAlign: 'center',
		marginTop: '-6px',
	},
	rightColumn: {
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			marginTop: '24px',
		},
	},
	value: {
		...theme.typography.style.subheading,
		color: theme.typography.color.primary,
	},
	leftValueContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	rightValueContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'right',
	},
	percentsChange: {
		marginLeft: '24px',
	},
	description: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
});


const mapStateToProps = state => ({
	charts: userAccounts.selectors.charts(state),
});

class BriefInfo extends React.Component {
	render() {
		const {
			classes,
			charts,
			showChart,
			positionMarketValue,
			totalGainPercent,
			cashCurrent,
			cashToWithdraw,
			unsettledCash,
		} = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					<div className={classes.leftValueContainer}>
						<div className={classes.value}>
							{toUsaMoneyFormat(positionMarketValue)}
						</div>
						{!!totalGainPercent &&
							<div className={classes.percentsChange}>
								<ValueChange
									value={totalGainPercent}
								/>
							</div>
						}
					</div>
					<div className={classes.description}>
						Securities Market Value
					</div>
					<div className={classes.leftValueContainer}>
						<div className={classes.value}>
							{toUsaMoneyFormat(cashToWithdraw)}
						</div>
					</div>
					<div className={classes.description}>
						Cash Available for Withdrawal
					</div>
				</div>
				{showChart &&
					<div className={classes.middleColumn}>
						{charts &&
						charts.Data &&
						(charts.Data.length > 0) &&
							<React.Fragment>
								<div>
									<ChartThumbnail
										width="120px"
										height="80px"
										data={charts.Data}
									/>
								</div>
								<div className={classes.chartThumbnailPeriod}>1Y</div>
							</React.Fragment>
						}
					</div>
				}
				<div className={classes.rightColumn}>
					<div className={classes.rightValueContainer}>
						<div className={classes.value}>
							{toUsaMoneyFormat(cashCurrent)}
						</div>
					</div>
					<div className={classes.description}>
						Cash
					</div>
					<div className={classes.rightValueContainer}>
						<div className={classes.value}>
							{toUsaMoneyFormat(unsettledCash)}
						</div>
					</div>
					<div className={classes.description}>
						Unsettled Cash
					</div>
				</div>
			</div>
		);
	}
}

BriefInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	showChart: PropTypes.bool,
	positionMarketValue: PropTypes.any,
	totalGainPercent: PropTypes.any,
	cashCurrent: PropTypes.any,
	cashToWithdraw: PropTypes.number,
	unsettledCash: PropTypes.number,
};

BriefInfo.defaultProps = {
	showChart: true,
	positionMarketValue: 0,
	totalGainPercent: 0,
	cashCurrent: 0,
	cashToWithdraw: 0,
	unsettledCash: 0,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(BriefInfo);
