import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ipo, userAccounts } from '@redux';
import { ValidationMessageMode } from '@enums';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
	A,
	FormSelect,
	Divider,
	Button,
	FormCheckbox,
	LimeLogo,
	ProgressContainer,
	SvgIcon,
	WithdrawAmount,
} from '@components';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';


const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	divider: {
		marginTop: '30px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
});


const IPO_CREATE_ORDER_FORM_ID = 'IpoCreateOrderForm';
const formSelector = formValueSelector(IPO_CREATE_ORDER_FORM_ID);

const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		...ownProps.order,
		TradeCode: ownProps.currentTradeAccount.tradeCode,
		ClearerFirm: ownProps.currentTradeAccount.clearerFirm,
		Amount: ownProps.amount,
	},
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	hasAttestationFinraAgree: formSelector(state, 'HasAttestationFinraAgree'),
	currentOffering: ipo.selectors.currentOffering(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
		refreshCurrentOffering: offeringId =>
			dispatch(ipo.actions.getOffering.request(offeringId)),
	},
});


class IpoCreateOrderForm extends React.Component {
	static getCashToWithdraw(props) {
		const { currentAccountData: { baseParams, submitting } } = props;
		return !submitting && baseParams && baseParams.CashToWithdrawValue;
	}

	static validate(values, props) {
		const errors = {};
		const cashToWithdraw = IpoCreateOrderForm.getCashToWithdraw(props);
		const {
			currentOffering: {
				submitting: offeringSubmitting,
				value: offering,
			},
		} = props;

		if (!offeringSubmitting && offering && values.Amount) {
			if (offering.MinTicketSize && values.Amount < offering.MinTicketSize) {
				errors.Amount = `The minimum order volume for participation in this placement is $${
					offering.MinTicketSize.toLocaleString('en-US')
				}. See the prospectus for details.`;
			}
			if (offering.MaxTicketSize && values.Amount > offering.MaxTicketSize) {
				errors.Amount = `The maximum order volume for participation in this placement is $${
					offering.MaxTicketSize.toLocaleString('en-US')
				}. See the prospectus for details.`;
			}
			if (offering.UnitIncrement && values.Amount / offering.UnitIncrement !== Math.floor(values.Amount / offering.UnitIncrement)) {
				errors.Amount = `The volume of the order to participate in this placement must be a multiple of $${
					offering.UnitIncrement.toLocaleString('en-US')
				}.`;
			}
		}

		if (!values.Amount || values.Amount <= 0) {
			errors.Amount = 'Required';
		} else if (typeof cashToWithdraw === 'number') {
			if (cashToWithdraw < values.Amount) {
				errors.Amount = 'Available amount is less than requested';
			}
		}

		return errors;
	}

	componentDidMount() {
		const {
			actions,
			currentOffering,
			order,
		} = this.props;
		if (!currentOffering.submitting
			&& !(currentOffering.value && currentOffering.value.OfferingId === order.OfferingId)
		) {
			actions.refreshCurrentOffering(order.OfferingId);
		}
	}

	render() {
		const {
			classes,
			currentTradeAccount,
			handleSubmit,
			hasAttestationFinraAgree,
			amount,
			theme,
			currentOffering,
			currentAccountData: {
				submitting,
			},
			...props
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit(ipo.actions.createIpoOrder)}
				className={classes.root}
			>
				<div className={classes.row}>
					<div className={classes.logo}>
						<LimeLogo />
					</div>
					<Field component={FormSelect} name="TradeCode" label="Account" disabled>
						<MenuItem value={currentTradeAccount.tradeCode}>
							<div className={classes.inputContainer}>
								<div className={classes.account}>{currentTradeAccount.tradeCode}</div>
							</div>
						</MenuItem>
					</Field>
				</div>
				<WithdrawAmount
					loading={submitting || false}
					withdrawLabelText="Amount available"
					availableAmount={IpoCreateOrderForm.getCashToWithdraw(this.props)}
					disabled={amount != null}
					onClick={() => { this.props.actions.refreshAccountPortfolio(currentTradeAccount.tradeCode); }}
					validationMessageMode={ValidationMessageMode.AFTER_ADDITIONAL}
				/>
				<div className={classes.divider}>
					<Divider />
				</div>
				<Field
					component={FormCheckbox}
					name="HasAttestationFinraAgree"
					label={
						<p>
							I affirm that I have read and understand the prohibitions of Rules&nbsp;
							<A href="https://www.finra.org/rules-guidance/rulebooks/finra-rules/5130" target="_blank">5130</A>&nbsp;
							and <A href="https://www.finra.org/rules-guidance/rulebooks/finra-rules/5131" target="_blank">5131</A>,
							and I attest that I am not a “restricted person” pursuant to the Rules.
						</p>
					}
					// disabled={!this.state.isMarginDocShown}
				/>
				<ProgressContainer submitting={currentOffering.submitting} display="center">
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={props.invalid || props.submitting || !hasAttestationFinraAgree}
						>
							{props.submitting ?
								<CircularProgress
									style={{ color: theme.typography.color.blue }}
									size={18}
								/>
								: 'Place Order'
							}
						</Button>
					</div>
				</ProgressContainer>
			</form>
		);
	}
}

IpoCreateOrderForm.propTypes = {
	theme: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	hasAttestationFinraAgree: PropTypes.bool,
};

IpoCreateOrderForm.defaultProps = {
	hasAttestationFinraAgree: false,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: IPO_CREATE_ORDER_FORM_ID,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		validate: IpoCreateOrderForm.validate,
	}),
)(IpoCreateOrderForm);
