import React from 'react';

const Svg = (
	<g fill="0B0500" fillRule="evenodd">
		<path d="M24.1863 44.8297C16.383 43.4282 5.71985 41.6858 0 34.9813C0 34.9813 17.0838 53.9775 26.2128 53.9775C30.0576 53.9775 33.713 52.6517 33.713 50.0191C33.732 47.3676 30.891 46.0608 24.1863 44.8297Z" />
		<path d="M78.9606 2.32953H34.3382C41.2891 3.69316 47.5582 6.47726 52.8046 10.7576L41.8573 25.2651C35.967 20.7954 28.3721 18.5606 22.9932 18.5606C17.9173 18.5606 16.7998 20.3788 16.7998 22.1212C16.7998 24.7538 19.5461 25.6818 26.7433 26.8939C37.8989 28.8257 54.4334 32.0644 54.4334 47.6894C54.4334 59.375 47.615 67.178 35.9291 70.0189H63.3541V48.2765H78.9795C94.8133 48.2765 103.317 38.125 103.317 25.5492C103.298 12.8788 94.7944 2.32953 78.9606 2.32953ZM76.3279 31.231H63.3352V19.356H76.3279C79.775 19.356 82.5213 21.6856 82.5213 25.3409C82.5024 28.9015 79.775 31.231 76.3279 31.231Z" />
		<path d="M104.416 18.3902C105.476 18.1629 106.594 18.0492 107.749 18.0492C114.34 18.0492 119.625 22.6136 121.348 27.8977L139 19.678C134.947 10.3409 126.026 0 107.749 0C102.067 0 96.7451 1.09848 92.0102 3.06818C98.677 6.30682 102.73 12.0076 104.416 18.3902Z" />
		<path d="M107.749 51.9318C102.446 51.9318 98.0519 49.5076 95.2109 45.7007C90.8737 48.6363 85.3433 50.303 78.9416 50.303H74.3581C80.0969 62.4242 92.6541 70 107.73 70C125.988 70 135.023 59.2424 138.981 50.2273L121.329 42.1023C119.624 47.3674 114.359 51.9318 107.749 51.9318Z" />
	</g>
);

export default Svg;

