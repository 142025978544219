import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { A, R } from '@components';

const styles = theme => ({
	pendingInfoText: {
		display: 'inline-block',
		color: theme.typography.color.red,
		margin: '0 0',
		textTransform: 'uppercase',
	},
});

class ChangeLink extends React.Component {
	render() {
		const {
			classes,
			className,
			onClick,
			activeLinkText,
			inactiveLinkText,
			qaAttribute,
		} = this.props;

		const oActiveLinkText = activeLinkText || <R id="shared_change" defaultValue="CHANGE" />;
		const oInactiveLinkText = inactiveLinkText ||
			<R id="shared_change_request_in_progress" defaultValue="CHANGE REQUEST IN PROGRESS" />;
		return (
			<span className={className}>
				{!this.props.havePendingOrder &&
					<A onClick={onClick} upper {...qaAttribute}>{oActiveLinkText}</A>
				}
				{this.props.havePendingOrder &&
					<p className={classes.pendingInfoText}>{oInactiveLinkText}</p>
				}
			</span>
		);
	}
}

ChangeLink.propTypes = {
	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	havePendingOrder: PropTypes.bool,
	activeLinkText: PropTypes.string,
	inactiveLinkText: PropTypes.string,
	className: PropTypes.string,
	qaAttribute: PropTypes.object,
};


ChangeLink.defaultProps = {
	activeLinkText: undefined,
	inactiveLinkText: undefined,
	havePendingOrder: false,
	className: undefined,
	qaAttribute: {},
};

export default compose(
	withTheme,
	withStyles(styles),
)(ChangeLink);
