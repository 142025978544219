import React from 'react';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import { connect } from 'react-redux';
import {
	ErrorMessage,
	SuccessMessage,
	ProgressContainer,
} from '@components';
import { UpgradeOptionLevelForm } from './components';
import { RequireSignatureModal } from '../';

const mapStateToProps = (state) => {
	const currentAccountData = userAccounts.selectors.currentAccountData(state);
	const upgradeOptionLevelData = userAccounts.selectors.upgradeOptionLevelData(state);
	return {
		accountInfo: userAccounts.selectors.accountInfo(state),
		accountStatus: userAccounts.selectors.currentAccountStatus(state),
		ready: !!(upgradeOptionLevelData.tradeAccount || (
			!currentAccountData.submitting && currentAccountData.baseParams && currentAccountData.baseParams.MarginOptionParameters
		)),
	};
};

class UpgradeOptionLevelModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			succeeded: false,
		};
	}

	onSuccess = () => {
		this.setState({ succeeded: true });
	}

	render() {
		const {
			accountInfo,
			accountStatus,
			onClose,
			ready,
		} = this.props;
		const canUpgradeOptionLevel = accountStatus && accountStatus.CanOptionsUpgrade && accountInfo && accountInfo.IsPrimaryHolder;
		const pendingOrder = accountInfo && accountInfo.PendingOrders && accountInfo.PendingOrders.UpgradeOptionLevel;
		const error = (pendingOrder || !canUpgradeOptionLevel);
		return (
			<RequireSignatureModal
				title="Update Option Level"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.UPGRADE_OPTION_LEVEL}`}
			>
				{this.state.succeeded &&
					<SuccessMessage
						onClick={onClose}
						text={
							<p>
								Your request was received.<br />
								Our manager will get back to you when the option level is upgraded.
							</p>
						}
						buttonText="Back To Account"
					/>
				}
				{!this.state.succeeded && (
					error && ready ?
						(
							<ErrorMessage
								onClick={onClose}
								text={pendingOrder ?
									'Another request for option level change is in progress. Please try again later.' :
									'You can not change option level for this account.'
								}
							/>
						) :
						(
							<ProgressContainer submitting={!ready}>
								<div>
									<UpgradeOptionLevelForm onSuccess={this.onSuccess} />
								</div>
							</ProgressContainer>
						)
				)}
			</RequireSignatureModal>
		);
	}
}

UpgradeOptionLevelModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	ready: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(UpgradeOptionLevelModal);
