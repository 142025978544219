import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import moment from 'moment';

const styles = theme => ({
	root: {
		'box-shadow': '0 1px 2px 0 rgba(61, 65, 71, 0.12)',
		padding: '13px 10px',
		width: '160px',
		height: '215px',
		'&:hover': {
			backgroundColor: '#e3edf5',
		},
		'background-color': 'white',
		'margin-right': '20px',
	},
	symbol: {
		...theme.typography.style.subheadingSm,
		color: theme.typography.color.primary,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	companyName: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	mt10: {
		'margin-top': '10px',
	},
	mt5: {
		'margin-top': '5px',
	},
	mt15: {
		'margin-top': '15px',
	},
	splitter: {
		'border-bottom': '1px solid #deeaef',
	},
	title: {
		...theme.typography.style.subheading,
		color: theme.typography.color.primary,
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	invisibleScrollbar: {
		'&::-webkit-scrollbar': {
			display: 'none!important',
		},
	},
	description: {
		overflowY: 'scroll',
		'word-wrap': 'normal',
		height: '60px',
		textOverflow: 'ellipsis',
		overflowX: 'hidden',
		...theme.typography.style.body,
		'font-size': '13px',
	},
	colored: {
		...theme.typography.style.body,
		color: '#7D9EB7',
	},
});

class OtherItem extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;
		return (
			<div className={classes.root}>

				<div className={classes.colored}>
					OTHER
				</div>
				<div className={`${classes.title} ${classes.mt10}`}>
					{item.Symbol}
				</div>
				<div className={classes.companyName}>
					{item.CompanyName}
				</div>
				<div className={` ${classes.splitter} ${classes.mt10}`} />
				<div className={` ${classes.description} ${classes.mt5} ${classes.invisibleScrollbar}`}>
					{item.Description && item.Description}
				</div>
				<div className={`${classes.date} ${classes.mt5}`}>
					{moment(item.Date).utcOffset(-5).format('D MMM YY')}
				</div>
			</div>
		);
	}
}

OtherItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	SafeComponent,
)(OtherItem);
