import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';


const styles = {
	root: {
		marginBottom: '30px',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
};

class CloseAccountContinue extends React.Component {
	render() {
		const {
			classes,
		} = this.props;

		return (
			<div className={classes.root}>
				<div className={classes.progress}>
					<CircularProgress
						style={{ color: '#1a96ff' }}
						size={58}
					/>
				</div>
			</div>
		);
	}
}

CloseAccountContinue.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(CloseAccountContinue);
