import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

const styles = theme => ({
	root: {
		maxWidth: '200px',
		display: 'flex',
		alignItems: 'center',
	},
	baseColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
	},
	middleColumn: {
		marginLeft: '16px',
		flexGrow: '2',
		width: '145px'
	},
	symbol: {
		...theme.typography.style.subheadingSm,
		color: theme.typography.color.primary,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	companyName: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
});

class Ticker extends React.Component {
	render() {
		const {
			classes,
			logoIcon,
			companyName,
			symbol,
		} = this.props;
		return (
			<div className={classes.root}>
				{logoIcon &&
					<div className={classes.baseColumn}>
						<img src={logoIcon}></img>
					</div>
				}
				<div className={classNames({
					[classes.baseColumn]: true,
					[classes.middleColumn]: !!logoIcon,
				})}>
					<div className={classes.symbol}>{symbol}</div>
					<div className={classes.companyName}>{companyName}</div>
				</div>	
			</div>
		);
	}
}

Ticker.propTypes = {
	companyName: PropTypes.string.isRequired,
	symbol: PropTypes.string.isRequired,
};

export default compose(withStyles(styles))(Ticker);
