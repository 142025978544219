import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import { CloseAccountForm } from '@shared';
import { Modal } from '@components';
import { connect } from 'react-redux';
// import { CloseAccountForm } from './components';

const styles = {
};

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
});

class CloseAccountModal extends React.Component {
	render() {
		const {
			onClose,
			currentTradeAccount,
		} = this.props;

		return (
			<Modal
				title="Close Account"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CLOSE_ACCOUNT}`}
			>
				<CloseAccountForm
					tradeAccount={currentTradeAccount}
					onClose={onClose}
				/>
			</Modal>
		);
	}
}

CloseAccountModal.propTypes = {
	currentTradeAccount: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(CloseAccountModal);
