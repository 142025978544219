import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import { toUsaFormat } from '@global';
import { BankLogo } from '@components';
import moment from 'moment';
import classNames from 'classnames';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	leftEmptyColumn: {
		width: '40px',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
	},
	logoColumn: {
		display: 'flex'
	},
	logoIcon: {
	    width: '40px',
    	height: '40px',
    	alignSelf: 'center',
	},
	middleColumn: {
		marginLeft: '5px',
		flexGrow: '2',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
	},
	rightColumn: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'flex-start',
		textAlign: 'right',
	},
	title: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	titleSymbol: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		fontSize: '18px',
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	amount: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		fontSize: '18px',
	},
	status: {
		...theme.typography.style.body,
	},
	amountData: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		textAlign: 'right',
	},
	yellow: {
		color: theme.typography.color.yellow,
	},
	red: {
		color: theme.typography.color.red,
	},
	green: {
		color: theme.typography.color.green,
	},
	black: {
		color: theme.typography.color.primary,
	},
	grey: {
		color: theme.typography.color.secondary,
	},
});

class FundItem extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		const colorCssClass = classNames({
			[classes.red]: item.Color === 'red',
			[classes.green]: item.Color === 'green',
			[classes.black]: item.Color === 'black',
			[classes.black]: item.Color === 'gray',
		});

		let directionText;

		let amount = '';
		if (item.Amount != null) {
			if (item.Amount !== 0) {
				amount = item.Amount > 0 ? '+' : '-';
			}
			amount += toUsaFormat(Math.abs(item.Amount));
		}

		let fundTitle = null;

		if (item.IsUserTransaction) {
			switch (item.Direction) {
			case 'deposit':
				fundTitle = 'Deposit';
				break;

			case 'withdrawal':
				fundTitle = 'Withdraw';
				break;

			default:
				fundTitle = 'Unknown';
			}
		}

		if (item.Description) {
			if (!fundTitle) {
				fundTitle = item.Description;
			} else {
				fundTitle += ` ${item.Description}`;
			}
		}

		const logoFileName = item.Bank ? `${item.Bank}.png` : null;

		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					{!item.IsUserTransaction &&
						(
							item.LogoIconUrl ?
							<div className={classes.logoColumn}>
								<img className={classes.logoIcon} src={item.LogoIconUrl}></img>
							</div>
							:
							<div className={classes.leftEmptyColumn} />
						) 
					}
					{item.IsUserTransaction &&
						<BankLogo bankAccount={{ LogoFileName: logoFileName, BankName: item.Bank }} />
					}
				</div>
				<div className={classes.middleColumn}>
					{item.Symbol &&
						<div className={classes.titleSymbol}>
							{item.Symbol}
						</div>
					}
					<div className={classes.title}>
						{fundTitle}
					</div>
					<div className={classes.date}>
						{/* american time zone (no utc) */}
						{moment(item.Date).format('D MMM YYYY')}
					</div>
				</div>
				<div className={classes.rightColumn}>
					<div className={classes.amountData}>
						<div className={classes.amount}>
							<div className={colorCssClass}>
								{amount}
							</div>
						</div>
						{item.Status &&
							<div className={classes.status}>
								<div className={colorCssClass}>
									{item.Status}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

FundItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	SafeComponent,
)(FundItem);