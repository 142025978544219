import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { profile } from '@redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
	Button,
	ErrorMessage,
	ProgressContainer,
	SelectField,
	SuccessMessage,
} from '@components';
import { RequireSignatureModal } from '../../..';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		paddingTop: '12px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	inputSelectItem: {
		marginTop: '10px',
	},
	inputContainerItem: {
		width: '100%',
		textAlign: 'left',
	},
});

const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	return {
		initialValues: {
			Value: userProfile.PersonalInformation.MaritalStatus,
		},
		profileSubmitting: userProfile.submitting,
		pendingOrder: userProfile.PendingOrders.MaritalStatus,
	};
};

class ChangeMaritalStatusModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.Value) {
			errors.Value = 'required';
		}
		return errors;
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			pristine,
			pendingOrder,
			profileSubmitting,
			submitting,
			submitSucceeded,
			initialValues,
		} = this.props;

		return (
			<RequireSignatureModal
				title="Change Marital Status"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_MARITAL_STATUS}`}
			>
				{submitSucceeded && <SuccessMessage onClick={onClose} text="Your request to change marital status is being processed." />}
				{!submitSucceeded && !profileSubmitting && pendingOrder &&
					<ErrorMessage
						onClick={onClose}
						text="Another request for marital status change is in progress. Please try again later."
					/>}
				{!submitSucceeded && !pendingOrder &&
					<ProgressContainer submitting={profileSubmitting}>
						<form
							onSubmit={handleSubmit(profile.actions.changeProfileMaritalStatusFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.inputSelectItem}>
								<SelectField
									label="Please enter your new marital status"
									name="Value"
									items={[
										{ value: 'Single', text: 'Single' },
										{ value: 'Married', text: 'Married' },
										{ value: 'Divorced', text: 'Divorced' },
									]}
									value={initialValues.Value || ''}
								/>
							</div>
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={invalid || submitting || pristine}
								>
									{submitting ?
										<CircularProgress
											style={{ color: '#1a96ff' }}
											size={18}
										/>
										: 'Change Marital Status'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeMaritalStatusModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: 'ChangeProfileMaritalStatusForm',
		validate: ChangeMaritalStatusModal.validate,
		enableReinitialize: true,
	}),
)(ChangeMaritalStatusModal);
