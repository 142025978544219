import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@components';
import { ModalPopups } from '@enums';
import { cabinetLog } from '@global';
import { app, userAccounts } from '@redux'


const useStyles = makeStyles((theme) => ({
	root: {
		margin: '24px 74px',
		padding: '10px 16px',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0',
			padding: '10px 16px',
		},
	},
	warning: {
		backgroundColor: theme.palette.color.secondary.limitOrder,
		color: theme.typography.color.primary,
	},
	alert: {
		backgroundColor: theme.typography.color.pink,
		color: theme.palette.color.secondary.downDark,
	},
	content: {
		...theme.typography.style.body,
	},
	actions: {
	},
	button: {
		color: theme.typography.color.link,
	},
	redButton: {
		padding: '4px 20px',
		backgroundColor: theme.typography.color.darkRed,
		color: theme.typography.color.white,
		'&:hover': {
			backgroundColor: theme.typography.color.red,
			color: theme.typography.color.white,
			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: theme.typography.color.red,
			},
		},
	},
}));



const DocumentDebtTypes = [
	{type: "TYPE:DOC:IDENT:PSRF", title: 'Russian passport'},
	{type: "TYPE:DOC:IDENT:UDL", title: 'Identity card'},
	{type: "TYPE:DOC:IDENT:PSEXT", title: 'Passport of a foreign citizen'},
	{type: "TYPE:DOC:IDENT:ZP", title: 'Russian foreign passport'},
	{type: "TYPE:DOC:IDENT:TEMPRF", title: 'Temporary identity card of the Russian citizen'},
	{type: "TYPE:DOC:IDENT:DRIVELIC", title: 'Driver\'s license'},
	{type: "TYPE:DOC:IDENT:VB", title: 'Military card'},
	{type: "TYPE:DOC:IDENT:IDCARD", title: 'Identity card'},
	{type: "TYPE:DOC:CONTRACT:BANK", title: 'Bank account agreement'},
	{type: "TYPE:DOC:OTHER:COMMUNAL", title: 'Utility bill'},
	{type: "TYPE:DOC:CONTRACT:OTHER", title: 'Agreement with other organizations'},
	{type: "TYPE:DOC:OTHER:BNKINFO", title: 'Bank reference'},
	{type: "TYPE:DOC:IDENT:SVREG:INDIVIDUAL", title: 'Certificate of residence registration (individual)'},
	{type: "TYPE:DOC:OTHER:ADDRINFO", title: 'Address reference'},
	{type: "TYPE:DOC:IDENT:OTHER", title: 'Other'},
];



export const DocumentDebtsAlert = ({ isMoneyOutPage }) => {

	const classes = useStyles();
	const dispatch = useDispatch();

	const userInfo = useSelector((state) => userAccounts.selectors.userInfo(state));
	let hasBlockers = userInfo.Debts && userInfo.Debts.some(it => it.IsBlockingMoneyOut);

	const cssClasses = classNames({
		[classes.root]: true,
		[classes.alert]: isMoneyOutPage && hasBlockers,
		[classes.warning]: !isMoneyOutPage || !hasBlockers,
	});

	const getDebtsInfo = (debts) => {

		let identityDebts = debts && debts.find(it => it.CategoryCode === 'IDENT');
		let registrationDebts = debts && debts.find(it => it.CategoryCode === 'ADDRESS');

		let debtsInfo = [];
		if (identityDebts) {
			debtsInfo.push({
				code: 'IDENT',
				title: 'personal identification',
				onClickModal: ModalPopups.CY_NON_TRADING_ORDER_IDENTITY,
				debtDocs: identityDebts.DocumentTypes.map(docType => 
					DocumentDebtTypes.find(it => it.type === docType || it.type === 'TYPE:DOC:IDENT:OTHER')
				)
			});
		}
		if (registrationDebts) {
			debtsInfo.push({
				code: 'ADDRESS',
				title: 'address confirmation',
				onClickModal: ModalPopups.CY_NON_TRADING_ORDER_REGISTRATION,
				debtDocs: registrationDebts.DocumentTypes.map(docType => 
					DocumentDebtTypes.find(it => it.type === docType || it.type === 'TYPE:DOC:IDENT:OTHER')
				)
			});
		}
		return debtsInfo;
	}

	if (isMoneyOutPage && hasBlockers) {

		let debtsInfo = getDebtsInfo(userInfo.Debts.filter(it => it.IsBlockingMoneyOut));
		return ( 
			<div>
				{debtsInfo && debtsInfo.map(item => (
					<div key={`js_alert_${item.code}`} className={cssClasses}>
						<div className={classes.content}>
							Withdrawal limits are applied to your account since one of the documents provided is absent/has expired.<br/>
							You can upload document to remove restrictions.
						</div>
						<div className={classes.actions}>
							<Button
								onClick={() => dispatch(app.actions.showBaseModal(item.onClickModal, item.debtDocs))}
								className={classes.redButton}
							>
								Upload
							</Button>
						</div>
					</div>
				))}
			</div>
		);
	}

	let debtsInfo = getDebtsInfo(userInfo.Debts);

	return ( 
		<div>
			{debtsInfo && debtsInfo.map(item => (
				<div key={`js_alert_${item.code}`} className={cssClasses}>
					<div className={classes.content}>
						You have arrears on the documents:<br/>
						Document: {item.title}
					</div>
					<div className={classes.actions}>
						<Button
							onClick={() => dispatch(app.actions.showBaseModal(item.onClickModal, item.debtDocs))}
							className={classes.button}
						>
							Add Document
						</Button>
					</div>
				</div>
			))}
		</div>
	);
}