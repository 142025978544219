import { providerWithAuth } from '@api';

module.exports = {
	getLinkedAccounts: payload => providerWithAuth().get(`/linkedaccounts/api_list/${payload.tradeCode}`),

	accountDetails: (tradeCode, token) => providerWithAuth().post('/linkedaccounts/api_accountdetails', {
		TradeCode: tradeCode,
		Token: token,
	}),
	accountSelect: (tradeCode, linkId) => providerWithAuth().post('/linkedaccounts/api_accountchoice', {
		TradeCode: tradeCode,
		LinkAccountNo: linkId,
	}),

	achLinking: payload =>	providerWithAuth().post('/linkedaccounts/api_achlinking', payload),
	achUnlinkBankAccount: payload => providerWithAuth().get(`/linkedaccounts/api_remove/${payload.tradeCode}/${payload.bankCode}`),

	wireLinking: payload =>	providerWithAuth().post('/linkedaccounts/api_wirelinking', payload),
	wireUnlinkBankAccount: payload =>
		providerWithAuth().get(`/linkedaccounts/api_wireremove/${payload.clearerFirm}/${payload.tradeCode}/${payload.bankCode}`),
	getIntrnationalBanksRequisites: query =>
		providerWithAuth().post('/linkedaccounts/api_internationalbanksrequisites', { query }),
	getDomesticBanksRequisites: query =>
		providerWithAuth().post('/linkedaccounts/api_domesticbanksrequisites', { query }),
};
