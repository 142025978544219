import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { profile, userAccounts } from '@redux';
import { Button, SvgIcon } from '@components';

const styles = theme => ({
	root: {
		height: '291px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		borderRadius: '10px',
		padding: '24px',
		marginBottom: '24px',
		color: theme.typography.color.white,
	},
	description: {
		...theme.typography.style.title,
	},
	sum: {
		...theme.typography.style.headline2,
		paddingRight: '5px',
		fontWeight: 'normal',
	},
	measureUnit: {
		...theme.typography.style.body,
	},
	buttonContainer: {
		marginTop: '12px',
	},
	button: {
		padding: '6px 24px',
	},
	tariffIcon: {
		width: '48px',
		height: '48px',
	},
	doneIcon: {
		height: '18px',
		paddingRight: '5px',
		stroke: theme.typography.color.white,
	},
	disabledButton: {
		display: 'flex',
		color: theme.typography.color.white,
	},
	hiddenButton: {
		visibility: 'hidden',
	},
	light: {
		backgroundColor: theme.typography.color.blue,
	},
	dark: {
		backgroundColor: theme.typography.color.primary,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		showChangeTariffPerShareModal: () => dispatch(profile.actions.showChangeTariffPerShareModal()),
		showChangeTariffPerTradeModal: () => dispatch(profile.actions.showChangeTariffPerTradeModal()),
		showChangeTariffZeroModal: () => dispatch(profile.actions.showChangeTariffZeroModal()),
	},
});

const mapStateToProps = state => ({
	tariffs: userAccounts.selectors.tariffs(state),
});


class TariffCard extends React.Component {
	chooseTariffHandler = () => {
		if (this.props.info.tariff === 'PerShare') {
			this.props.actions.showChangeTariffPerShareModal();
		}
		if (this.props.info.tariff === 'PerTrade') {
			this.props.actions.showChangeTariffPerTradeModal();
		}
		if (this.props.info.tariff === 'Zero') {
			this.props.actions.showChangeTariffZeroModal();
		}
	}

	render() {
		const {
			theme,
			classes,
			tariffs: {
				ActiveTariff,
				PendingTariff,
			},
			info,
		} = this.props;

		const rootCssClass = classNames({
			[classes.root]: true,
			[classes.light]: info.color === 'light',
			[classes.dark]: info.color === 'dark',
		});

		const isActive = ActiveTariff === info.tariff;
		const isPending = PendingTariff === info.tariff;
		const canChange = !PendingTariff;

		const buttonCssClass = classNames({
			[classes.buttonContainer]: true,
			[classes.hiddenButton]: !(canChange || isActive || isPending),
		});

		let buttonText = <span>Choose</span>;
		if (isActive) {
			buttonText = <span className={classes.disabledButton}><SvgIcon classes={{ root: classes.doneIcon }} icon="Done" /><span>Active</span></span>;
		}
		if (isPending) {
			buttonText = <span className={classes.disabledButton}>Pending</span>;
		}

		return (
			<div className={rootCssClass}>
				<div className={classes.description}>
					<SvgIcon classes={{ root: classes.tariffIcon }} icon={info.icon} viewBox="10 10 52 52" />
					<div>
						{info.text}
					</div>
				</div>
				<div>
					<div>
						<span className={classes.sum}>{info.sum}</span>
						<span className={classes.measureUnit}>{info.measureUnit}</span>
					</div>
					<div className={buttonCssClass}>
						<Button
							fullWidth
							className={classes.button}
							onClick={this.chooseTariffHandler}
							variant="contained"
							color="secondary"
							disabled={isActive || isPending || !canChange}
						>
							<span>{buttonText}</span>
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

TariffCard.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	tariffs: PropTypes.object.isRequired,
	info: PropTypes.object.isRequired,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(TariffCard);
