import actions from './actions';
import auth from '../auth';

const initialState = {
	depositWireInfo: { // for preopened clients
		isVisible: false,
	},
	depositWireModal: {
		bankAccount: null,
	},
	depositAchModal: {
		bankAccount: null,
		tradeAccount: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.SHOW_DEPOSIT_FUNDS_MODAL:
		return {
			...state,
			depositAchModal: {
				bankAccount: payload.bankAccount,
				tradeAccount: payload.tradeAccount,
			},
		};
	case actions.HIDE_DEPOSIT_FUNDS_MODAL:
		return {
			...state,
			depositAchModal: {
				bankAccount: null,
				tradeAccount: null,
			},
		};
	case actions.SHOW_DEPOSIT_FUNDS_WIRE_MODAL:
		return {
			...state,
			depositWireModal: {
				bankAccount: payload.bankAccount,
			},
		};
	case actions.HIDE_DEPOSIT_FUNDS_WIRE_MODAL:
		return {
			...state,
			depositWireModal: {
				bankAccount: null,
			},
		};
	case actions.WIRE_DEPOSIT_INFO_FORM_SUBMIT_SUCCESS:
		return {
			...state,
			depositWireInfo: {
				...payload,
				isVisible: true,
			},
		};
	case actions.INIT_DEPOSIT_WIRE_INFO:
		return {
			...state,
			depositWireInfo: {
				isVisible: false,
			},
		};
	case auth.actions.LOGOUT_SUCCESS:
		return { ...initialState };
	default:
		return state;
	}
};
