import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MUISnackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import IconButton from '@material-ui/core/IconButton';
import blue from '@material-ui/core/colors/blue';
import { Button, SvgIcon } from '@components';
import { snackbar } from '@redux';


const styles = theme => ({
	root: {
		backgroundColor: theme.typography.color.pink,
		color: theme.palette.color.secondary.downDark,
		flexWrap: 'nowrap',
	},
	success: {
		backgroundColor: blue[50],
		color: theme.typography.color.darkBlue,
		flexWrap: 'nowrap',
	},
});

const mapStateToProps = state => ({
	snackbarInfo: snackbar.selectors.snackbarInfo(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		hide: () => dispatch(snackbar.actions.hideErrorMessage()),
	},
});

class Snackbar extends React.Component {
	render() {
		const {
			classes,
			textColor,
			bgColor,
			hasHtml,
			snackbarInfo: {
				isVisible,
				message,
			},
			actions,
		} = this.props;
		
		return (
			<div>
				<MUISnackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					ContentProps={{
						classes: {
							root: (message && message.type && message.type !== 'error') ? classes[message.type] : classes.root,
						}, // only root (can be message and action)
					}}
					open={isVisible}
					autoHideDuration={20000}
					onClose={actions.hide}
					message={message &&
						<div>
							{message.title &&
								<p>{message.title}</p>
							}
							{/* eslint-disable-next-line react/no-danger */}
							{message.hasHtml &&	<div dangerouslySetInnerHTML={{ __html: message.text }} />}
							{!message.hasHtml && <p>{message.text}</p>}
						</div>
					}
					action={
						<IconButton onClick={actions.hide}>
							<SvgIcon icon="Close" htmlColor={textColor} />
						</IconButton>
					}
				/>
			</div>
		);
	}
}

Snackbar.propTypes = {
	classes: PropTypes.object.isRequired,
	textColor: PropTypes.string,
	hasHtml: PropTypes.bool,
};

Snackbar.defaultProps = {
	textColor: '#fff',
	hasHtml: false,
};
export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(Snackbar);
