import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { profile } from '@redux';
import {
	ChangeLink,
	Input,
} from '@components';
import { valueOrEmpty } from '@global';

const useActions = (userProfile) => {
	const dispatch = useDispatch();
	return ({
		showChangeEmploymentModal: () => dispatch(profile.actions.showChangeEmploymentModal(userProfile)),
	});
};

const EmploymentPanel = ({
	profile: {
		EmploymentProfile,
		PendingOrders,
		Guid,
		ClientId,
	},
	classes,
}) => {
	const actions = useActions({
		EmploymentProfile,
		PendingOrders,
		Guid,
		ClientId,
	});
	return (
		<div className={classes.employmentPanel}>
			<div className={classes.blockTitle}>
				Employment
				<span>
					<ChangeLink
						onClick={actions.showChangeEmploymentModal}
						havePendingOrder={PendingOrders.Employment}
					/>
				</span>
			</div>
			<Input placeholder="Status" showLabel value={valueOrEmpty(EmploymentProfile.Status)} disabled />
			<Input placeholder="Company" showLabel value={valueOrEmpty(EmploymentProfile.Company)} disabled />
			<Input placeholder="Position" showLabel value={valueOrEmpty(EmploymentProfile.Position)} disabled />
			<Input
				placeholder="Address"
				showLabel
				multiline
				value={valueOrEmpty(EmploymentProfile.Address ? EmploymentProfile.Address.FullAddress : '')}
				disabled
			/>
		</div>
	);
};

EmploymentPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,

};

export default EmploymentPanel;
