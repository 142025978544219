import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toUsaFormat } from '@global';

const styles = theme => ({
	root: {
		textAlign: 'right',
	},
	gain: {
		...theme.typography.style.subheadingSm,
	},
	gainPercent: {
		...theme.typography.style.body,
	},
	red: {
		color: theme.typography.color.red,
	},
	green: {
		color: theme.typography.color.green,
	},
});

class Yield extends React.Component {
	render() {
		const {
			classes,
			gain,
			gainPercent,
		} = this.props;
		const rootCssClass = classNames({
			[classes.root]: true,
			[classes.red]: gain < 0,
			[classes.green]: gain > 0,
		});
		return (
			<div className={rootCssClass}>
				<div className={classes.gain}>{toUsaFormat(gain)}</div>
				<div className={classes.gainPercent}>{toUsaFormat(gainPercent)}%</div>
			</div>
		);
	}
}

Yield.propTypes = {
	gain: PropTypes.number.isRequired,
	gainPercent: PropTypes.number.isRequired,
};

export default compose(withStyles(styles))(Yield);
