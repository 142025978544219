import { createApiActions } from '../extensions';

const API_KEY_MODAL_INIT = 'ETNAAUTH/API_KEY_MODAL_INIT';

const actions = {

	getApiKeys: createApiActions('ETNAAUTH/GET_CLIENTS'),
	revokeApiKey: createApiActions('ETNAAUTH/REVOKE'),
	createApiKey: createApiActions('ETNAAUTH/CREATE_APIKEY'),

	SHOW_CREATE_APIKEY_MODAL: 'ETNAAUTH/SHOW_CREATEAPIKEY_MODAL',
	showCreateApiKeyModal: () => ({
		type: actions.SHOW_CREATE_APIKEY_MODAL,
	}),

	HIDE_MODAL: 'ETNAAUTH/HIDE_MODAL',
	hideModal: modalId => ({
		type: actions.HIDE_MODAL,
		payload: modalId,
	}),

	apiKeyModalInit: createApiActions(API_KEY_MODAL_INIT),
	apiMarketDataAgreement: createApiActions('ETNAAUTH/API_MARKET_DATA_AGREEMENT'),
};

export default actions;
