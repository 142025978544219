import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import { A } from '@components';

const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between',
		},
		marginRight: '62px',
	},
	leftColumn: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		display: 'flex',
		flexDirection: 'column',
	},
	rightColumn: {
		[theme.breakpoints.up('sm')]: {
			width: '25%',
		},
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
	},
});

class OfferingExtendedInfo extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		let prospUrl = item.ProsepectusUrl;
		if (prospUrl && prospUrl.indexOf('http') < 0) {
			prospUrl = `https://${prospUrl}`;
		}

		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					{item.Description}
				</div>
				{prospUrl &&
					<div className={classes.rightColumn}>
						<A href={prospUrl} target="_blank">Prospectus</A>
					</div>
				}
			</div>
		);
	}
}

OfferingExtendedInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	SafeComponent,
)(OfferingExtendedInfo);
