import { ProgressContainer } from '@components';
import { withTheme } from '@material-ui/core/styles';
import React from 'react';
import compose from 'recompose/compose';
import { OperationsTable } from './components'

function TransactionsHistoryLME() {
	return (
		<ProgressContainer submitting={!true}>
			<div>
				<OperationsTable />
			</div>
		</ProgressContainer>
	);
}

export default compose(
	withTheme,
)(TransactionsHistoryLME);
