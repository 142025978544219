import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { toUsaFormat } from '@global';

const styles = theme => ({
	root: {
		display: 'flex',
		textAlign: 'right',
		justifyContent: 'flex-end',
	},
	currentPrice: {
		...theme.typography.style.subheadingSm,
		color: theme.typography.color.primary,
	},
});

class CurrentPrice extends React.Component {
	render() {
		const {
			classes,
			currentPrice,
		} = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.currentPrice}>
					{toUsaFormat(currentPrice)}
				</div>
			</div>
		);
	}
}

CurrentPrice.propTypes = {
	currentPrice: PropTypes.number.isRequired,
};

export default compose(withStyles(styles))(CurrentPrice);
