
export const initTableData = (sortField, sortDirection) => ({
	Items: [],
	Count: 0,
	Pagination: {
		Page: 0,
		PageSize: 20,
	},
	Sorting: {
		Field: sortField,
		Direction: sortDirection,
	},
	Filter: [],
});

export const createApiActions = prefix => ({
	REQUEST: `${prefix}_REQUEST`,
	request: payload => ({
		type: `${prefix}_REQUEST`,
		payload,
	}),
	SUCCESS: `${prefix}_SUCCESS`,
	success: payload => ({
		type: `${prefix}_SUCCESS`,
		payload,
	}),
	FAILURE: `${prefix}_FAILURE`,
	failure: error => ({
		type: `${prefix}_FAILURE`,
		payload: {
			error,
		},
	}),
});
