const actions = {
	// actions

	SET_RESOLUTION: 'APP/SET_RESOLUTION',
	setResolution: resolution => ({
		type: actions.SET_RESOLUTION,
		payload: {
			resolution,
		},
	}),

	SHOW_CONFIRM_MODAL: 'APP/SHOW_CONFIRM_MODAL',
	HIDE_CONFIRM_MODAL: 'APP/HIDE_CONFIRM_MODAL',

	showConfirmModal: payload => ({
		type: actions.SHOW_CONFIRM_MODAL,
		payload,
	}),
	hideConfirmModal: () => ({
		type: actions.HIDE_CONFIRM_MODAL,
	}),

	SHOW_BASE_MODAL: 'APP/SHOW_BASE_MODAL',
	HIDE_BASE_MODAL: 'APP/HIDE_BASE_MODAL',

	showBaseModal: (modalName, modalData) => ({
		type: actions.SHOW_BASE_MODAL,
		payload: {
			modalName,
			modalData
		},
	}),
	hideBaseModal: (modalName) => ({
		type: actions.HIDE_BASE_MODAL,
		payload: {
			modalName,
		},
	}),

	CHECK_MIN_VERSION: 'APP/CHECK_MIN_VERSION',

	checkMinVersionRequest: () => ({
		type: actions.CHECK_MIN_VERSION,
	}),

	CHANGE_LANGUAGE: 'APP/CHANGE_LANGUAGE',
	changeLanguage: lang => ({
		type: actions.CHANGE_LANGUAGE,
		payload: {
			lang,
		},
	}),

	SHOW_DICWORDS: 'APP/SHOW_DICWORDS',
	HIDE_DICWORDS: 'APP/HIDE_DICWORDS',

	showDicwords: () => ({
		
		type: actions.SHOW_DICWORDS,
	}),
	hideDicwords: () => ({
		type: actions.HIDE_DICWORDS,
	}),
	
};


export default actions;
