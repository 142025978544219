const actions = {

	LOGIN_LOCAL: 'AUTH/LOGIN_LOCAL',

	loginLocal: payload => ({
		type: actions.LOGIN_LOCAL,
		payload,
	}),
	AUTH_WIDGET: 'AUTH/AUTH_WIDGET',
	setWidget: payload => ({
		type: actions.AUTH_WIDGET,
		payload,
	}),


	LOGIN_USER: 'AUTH/LOGIN_USER',
	loginUser: payload => ({
		type: actions.LOGIN_USER,
		payload,
	}),


	LOGOUT: 'AUTH/LOGOUT',
	logout: () => ({
		type: actions.LOGOUT,
	}),

	LOGOUT_SUCCESS: 'AUTH/LOGOUT_SUCCESS',
	logoutSuccess: () => ({
		type: actions.LOGOUT_SUCCESS,
	}),

	CHECK_AUTH_REQUEST: 'AUTH/CHECK_AUTH_REQUEST',

	checkAuthRequest: location => ({
		type: actions.CHECK_AUTH_REQUEST,
		payload: {
			location,
		},
	}),

	AUTOLOGIN_REQUEST: 'AUTH/AUTOLOGIN_REQUEST',

	autologinRequest: autologin => ({
		type: actions.AUTOLOGIN_REQUEST,
		payload: {
			autologin,
		},
	}),

	SEND_PING_AUTH_REQUEST: 'AUTH/SEND_PING_AUTH_REQUEST',

	sendPingAuthRequest: () => ({
		type: actions.SEND_PING_AUTH_REQUEST,
	}),

	SEND_CONFIRMATION_CODE_REQUEST: 'AUTH/SEND_CONFIRMATION_CODE_REQUEST',
	SEND_CONFIRMATION_CODE_SUCCESS: 'AUTH/SEND_CONFIRMATION_CODE_SUCCESS',
	SEND_CONFIRMATION_CODE_FAILURE: 'AUTH/SEND_CONFIRMATION_CODE_FAILURE',

	sendConfirmationCodeRequest: (phone, reason) => ({
		type: actions.SEND_CONFIRMATION_CODE_REQUEST,
		payload: {
			phone,
			reason,
		},
	}),
	sendConfirmationCodeSuccess: () => ({
		type: actions.SEND_CONFIRMATION_CODE_SUCCESS,
	}),
	sendConfirmationCodeFailure: error => ({
		type: actions.SEND_CONFIRMATION_CODE_FAILURE,
		payload: {
			error,
		},
	}),

	SEND_CONFIRMATION_CODE_EMAIL_REQUEST: 'AUTH/SEND_CONFIRMATION_CODE_EMAIL_REQUEST',
	SEND_CONFIRMATION_CODE_EMAIL_SUCCESS: 'AUTH/SEND_CONFIRMATION_CODE_EMAIL_SUCCESS',
	SEND_CONFIRMATION_CODE_EMAIL_FAILURE: 'AUTH/SEND_CONFIRMATION_CODE_EMAIL_FAILURE',

	sendConfirmationCodeEmailRequest: email => ({
		type: actions.SEND_CONFIRMATION_CODE_EMAIL_REQUEST,
		payload: {
			email,
		},
	}),

	sendConfirmationCodeEmailSuccess: () => ({
		type: actions.SEND_CONFIRMATION_CODE_EMAIL_SUCCESS,
	}),

	sendConfirmationCodeEmailFailure: error => ({
		type: actions.SEND_CONFIRMATION_CODE_EMAIL_FAILURE,
		payload: {
			error,
		},
	}),

	SET_AFTER_AUTH_URL: 'AUTH/SET_AFTER_AUTH_URL',
	setAfterAuthUrl: payload => ({
		type: actions.SET_AFTER_AUTH_URL,
		payload,
	}),
};

export default actions;
