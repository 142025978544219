import { put, fork, select, takeEvery } from 'redux-saga/effects';
import { acatDeposit, acatOutgoing, closeAccount, acatIraDeposit, closeAccountValidation } from '@api';
import { SubmissionError } from 'redux-form';
import { BaseGetSagaHandler, callApi, snackbar, userAccounts } from '@redux';
import { push } from 'connected-react-router';
import { ModalPopups, CloseAccountFlow } from '@enums';
import { cabinetLog, addHashToUrl, changeHashInUrl, removeHashFromUrl, removeHashesFromUrl } from '@global';
import actions from './actions';
import * as userAccountsActions from './../userAccounts/actions';

function* acatDepositFormSubmit({ payload }) {
	try {
		const dataToSend = {
			DestinationAccountNumber: payload.TradeCode,
			SourceFirmInfo: {
				SourceFirmName: payload.SourceFirmName,
				SourceFirmAddress: payload.SourceFirmAddress,
				SourceAccountTitle: payload.SourceAccountTitle,
				SourceAccountNumber: payload.SourceAccountNumber,
				SourceFirmTelephoneNumber: payload.SourceFirmTelephoneNumber,
				SourceAccountType: payload.SourceAccountType,
			},
			TransferInstructionsType: payload.TransferInstructionsType,
			Attachments: payload.Attachments,
		};

		if (dataToSend.TransferInstructionsType === 'Brokerage') {
			if (payload.AssetsTransferType === 'All') {
				dataToSend.AssetsTransferType = 'All';
			}
			if (payload.AssetsTransferType === 'Parts') {
				dataToSend.AssetsTransferType = 'Parts';
				dataToSend.AssetsTransfers = [];
				payload.BrokerageParts.forEach((item) => {
					if (item.AssetsTransferSymbol && item.AssetsTransferShares) {
						dataToSend.AssetsTransfers.push({
							Symbol: item.AssetsTransferSymbol,
							Shares: item.AssetsTransferShares,
						});
					}
				});
			}
		}

		if (dataToSend.TransferInstructionsType === 'MutualFund') {
			dataToSend.FundTransfers = [];
			payload.MutualFund.forEach((item) => {
				if (
					item.FundTransferSymbol &&
					item.FundTransferShares &&
					item.FundTransferName &&
					item.FundTransferType
				) {
					dataToSend.FundTransfers.push({
						Symbol: item.FundTransferSymbol,
						Shares: item.FundTransferShares,
						Name: item.FundTransferName,
						Type: item.FundTransferType,
					});
				}
			});
		}

		const response = yield callApi(acatDeposit, dataToSend);
		if (!response.data.Success) {
			yield put(actions.acatDepositFormFailure(new SubmissionError({
				_error: response.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: response.data.Errors[0].Message }));
		} else {
			yield put(actions.acatDepositFormSuccess());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.acatDepositFormFailure(new SubmissionError({
			_error: error.message,
		})));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}


function* acatOutgoingFormSubmit({ payload }) {
	try {
		const response = yield callApi(acatOutgoing, payload);
		if (!response.data.Success) {
			yield put(actions.acatOutgoingFormFailure(new SubmissionError({
				_error: response.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: response.data.Errors[0].Message }));
		} else {
			yield put(actions.acatOutgoingFormSuccess());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.acatOutgoingFormFailure(new SubmissionError({
			_error: error.message,
		})));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* acatDepositIraFormSubmit({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: acatIraDeposit,
		errorText: 'Ira Deposit Funds',
		handler: actions.acatDepositIraForm,
	}, { payload });
}

function* closeAccountFormSubmit({ payload }) {
	try {
		const request = { ...payload };
		const closeAccountFlow = yield select(state => state.acatDeposit.modals[ModalPopups.CLOSE_ACCOUNT]);

		const response = yield callApi(closeAccount, request);

		if (!response.data.Success) {
			yield put(actions.closeAccountFormFailure(new SubmissionError({
				_error: response.data.Errors[0].Code,
			})));
			yield put(snackbar.actions.showErrorMessage({
				text: response.data.Errors[0].Message,
			}));
		} else {
			const { baseParams } = yield select(userAccounts.selectors.currentAccountData);
			if (closeAccountFlow.flow === CloseAccountFlow.PROFILE && baseParams.CashCurrentValue > 0) {
				// Failure лишь переводит state в нужное состояние, это не сигнал об неуспешности.
				// Иначе, при повторном открытии окна, будет success popup
				yield put(actions.closeAccountFormFailure(new SubmissionError({
					_error: '',
				})));
				yield put(actions.showCloseAccountFormModal());
			} else {
				yield put(actions.closeAccountFormSuccess());
				const currentAccount = yield select(userAccounts.selectors.currentTradeAccount);
				yield put(userAccountsActions.default.getCurrentAccountBaseDataRequest({
					tradeCode: currentAccount.tradeCode,
					clearerFirm: currentAccount.clearerFirm,
				}));
			}
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.closeAccountFormFailure(new SubmissionError({
			_error: error.message,
		})));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}


function* closeAccountContinueSubmit({ payload }) {
	try {
		const response = yield callApi(closeAccount, payload);
		cabinetLog('continue submit', response);

		if (!response.data.Success) {
			const hasNextStep = (response.data.Errors[0].Code === 'HasUnmarketablePositions'
				|| response.data.Errors[0].Code === 'HasPositions'
				|| response.data.Errors[0].Code === 'HasCash');

			yield put(actions.closeAccountContinueFailure({
				ErrorCode: response.data.Errors[0].Code,
				OrderCode: response.data.OrderCode,
				CashAmount: response.data.CashAmount,
				PennyStockAttachments: response.data.PennyStockAttachments,
			}));

			if (!hasNextStep) {
				yield put(snackbar.actions.showErrorMessage({ text: response.data.Errors[0].Message }));
			}
		} else {
			yield put(actions.closeAccountContinueSuccess());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.closeAccountContinueFailure(new SubmissionError({
			_error: error.message,
		})));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* showAcatDepositModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(changeHashInUrl(location, ModalPopups.CLOSE_ACCOUNT_FORM, ModalPopups.ACAT_FUND)));
}

function* hideAcatDepositModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashesFromUrl(location, [ModalPopups.CLOSE_ACCOUNT_FORM, ModalPopups.ACAT_FUND])));
}

function* showCloseAccountModal({ payload }) {
	// get current location
	const location = yield select(state => state.router.location);

	if (payload.flow === CloseAccountFlow.PROFILE) {
		const currentAccount = yield select(userAccounts.selectors.currentTradeAccount);

		try	{
			yield put(actions.closeAccountValidation.request());
			const validationResponse = yield callApi(closeAccountValidation, {
				tradeCode: currentAccount.tradeCode,
				clearerFirm: currentAccount.clearerFirm,
			});
			if (!validationResponse.data.Success) {
				yield put(actions.closeAccountValidation.failure());
				yield put(snackbar.actions.showErrorMessage({ text: validationResponse.data.Errors[0].Message }));
				return;
			}
		} catch (error) {
			window.Raven.captureException(error);
			yield put(actions.closeAccountValidation.failure());
			yield put(snackbar.actions.showErrorMessage({ text: error.message }));
			return;
		}

		yield put(actions.closeAccountValidation.success());
		// show modal
		yield put(push(addHashToUrl(location, ModalPopups.CLOSE_ACCOUNT)));
	}

	// Implicitly (payload.flow === CloseAccountFlow.CASH_MANAGMENT)
	if (payload.from === 'ACH') {
		yield put(push(changeHashInUrl(location, ModalPopups.WITHDRAW_FUNDS_ACH, ModalPopups.CLOSE_ACCOUNT)));
	} else if (payload.from === 'WIRE') {
		yield put(push(changeHashInUrl(location, ModalPopups.WITHDRAW_FUNDS_WIRE, ModalPopups.CLOSE_ACCOUNT)));
	}
}

function* hideCloseAccountModal() {
	// get current location
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, ModalPopups.CLOSE_ACCOUNT)));
}

function* showCloseAccountFormModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	// yield put(push(changeHashInUrl(location, ModalPopups.ACAT_FUND, ModalPopups.CLOSE_ACCOUNT_FORM)));
	yield put(push(changeHashInUrl(location, ModalPopups.CLOSE_ACCOUNT, ModalPopups.CLOSE_ACCOUNT_FORM)));
}

function* hideCloseAccountFormModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashesFromUrl(location, [ModalPopups.CLOSE_ACCOUNT, ModalPopups.CLOSE_ACCOUNT_FORM, ModalPopups.ACAT_FUND])));
}


// Watchers
function* watchAcatDepositFormSubmit() {
	yield takeEvery(actions.ACAT_DEPOSIT_FORM_SUBMIT_REQUEST, acatDepositFormSubmit);
}
function* watchAcatDepositIraFormSubmit() {
	yield takeEvery(actions.acatDepositIraForm.REQUEST, acatDepositIraFormSubmit);
}
function* watchAcatOutgoingFormSubmit() {
	yield takeEvery(actions.ACAT_OUTGOING_FORM_SUBMIT_REQUEST, acatOutgoingFormSubmit);
}

function* watchCloseAccountFormSubmit() {
	yield takeEvery(actions.CLOSE_ACCOUNT_FORM_SUBMIT_REQUEST, closeAccountFormSubmit);
}

function* watchCloseAccountContinueSubmit() {
	yield takeEvery(actions.CLOSE_ACCOUNT_CONTINUE_REQUEST, closeAccountContinueSubmit);
}

function* watchShowAcatDepositModal() {
	yield takeEvery(actions.SHOW_ACAT_DEPOSIT_MODAL, showAcatDepositModal);
}
function* watchHideAcatDepositModal() {
	yield takeEvery(actions.HIDE_ACAT_DEPOSIT_MODAL, hideAcatDepositModal);
}
function* watchShowCloseAccountModal() {
	yield takeEvery(actions.SHOW_CLOSE_ACCOUNT_MODAL, showCloseAccountModal);
}
function* watchHideCloseAccountModal() {
	yield takeEvery(actions.HIDE_CLOSE_ACCOUNT_MODAL, hideCloseAccountModal);
}
function* watchShowCloseAccountFormModal() {
	yield takeEvery(actions.SHOW_CLOSE_ACCOUNT_FORM_MODAL, showCloseAccountFormModal);
}
function* watchHideCloseAccountFormModal() {
	yield takeEvery(actions.HIDE_CLOSE_ACCOUNT_FORM_MODAL, hideCloseAccountFormModal);
}


// Root saga
const rootSaga = [
	fork(watchAcatDepositFormSubmit),
	fork(watchAcatOutgoingFormSubmit),
	fork(watchAcatDepositIraFormSubmit),
	fork(watchCloseAccountFormSubmit),
	fork(watchCloseAccountContinueSubmit),
	fork(watchShowAcatDepositModal),
	fork(watchHideAcatDepositModal),
	fork(watchHideCloseAccountModal),
	fork(watchShowCloseAccountFormModal),
	fork(watchHideCloseAccountFormModal),
	fork(watchShowCloseAccountModal),
];

export default {
	rootSaga,
};
