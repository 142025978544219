import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@components';
import { SOURCE_OF_FUNDING } from '@utils';
import { valueOrEmpty } from '@global';

const RetiredPanel = ({ profile }) => (
	<Fragment>
		<Input placeholder="Status" showLabel value={valueOrEmpty(profile.Status)} disabled />
		<Input placeholder="Source of funding" showLabel value={valueOrEmpty(profile.SourceOfFunding)} disabled />
		{profile.SourceOfFunding === SOURCE_OF_FUNDING.OTHER &&
		<Input placeholder="Explanation" showLabel value={valueOrEmpty(profile.ExplanationSourceOfFunding)} disabled />
		}
	</Fragment>
);

RetiredPanel.propTypes = {
	profile: PropTypes.object.isRequired,
};

export default RetiredPanel;
