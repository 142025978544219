import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { SvgIcon } from '@components';
import moment from 'moment';
import classNames from 'classnames';
import { toUsaFormat, capitalizeFirstLetter } from '@global';


const styles = theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	leftEmptyColumn: {
		width: '40px',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
	},
	middleColumn: {
		display: 'flex',
		flexDirection: 'row',
		alignContent: 'center',
		justifyContent: 'flex-start',
		flexGrow: 2
	},
	logoColumn: {
		display: 'flex'
	},
	logoIcon: {
	    width: '40px',
    	height: '40px',
    	alignSelf: 'center',
	},
	infoColumn: {
		marginLeft: '5px',
	},
	rightColumn: {
		width: '30%',
	},
	title: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		fontSize: '18px',
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	price: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		verticalAlign: 'text-top',
	},
	status: {
		...theme.typography.style.body,
	},
	amountData: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		textAlign: 'right',
	},
	cancel: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '22px',
	},
	red: {
		color: theme.typography.color.red,
	},
	green: {
		color: theme.typography.color.green,
	},
	black: {
		color: theme.typography.color.primary,
	},
	grey: {
		color: theme.typography.color.secondary,
	},
});

class TradeItem extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		const colorCssClass = classNames({
			[classes.red]: item.Amount < 0,
			[classes.green]: item.Amount > 0,
			[classes.grey]: item.Amount === 0,
		});

		let amount = '';
		if (item.Amount != null) {
			if (item.Amount !== 0) {
				amount = item.Amount > 0 ? '+' : '-';
			}
			amount += toUsaFormat(Math.abs(item.Amount));
		}

		return (
			<div className={classes.root}>
				<div className={classes.middleColumn}>
					{
						item.LogoIconUrl ? 
						<div className={classes.logoColumn}>
							<img className={classes.logoIcon} src={item.LogoIconUrl}></img>
						</div>
						:
						<div className={classes.leftEmptyColumn} />
					}
					<div className={classes.infoColumn}>
						<div className={classes.title}>
							{item.Symbol}
						</div>
						<div className={classes.date}>
							{/* american time zone (no utc) */}
							{moment(item.Date).format('D MMM YYYY')}
						</div>
					</div>
				</div>
				{!!item.Price &&
					<div className={classes.title}>
						<span>{capitalizeFirstLetter(item.Direction)} {item.Quantity} </span>
						<span className={classes.price}>@ </span>
						{toUsaFormat(item.Price)}
					</div>
				}
				<div className={classes.rightColumn}>
					<div className={classes.amountData}>
						<div className={classes.title}>
							<div className={colorCssClass}>
								{amount}
							</div>
						</div>
						{item.Status &&
						<div className={classes.status}>
							<div className={colorCssClass}>
								{item.Status}
							</div>
						</div>
						}
					</div>
					{item.CanBeCanceled &&
						<div className={classes.cancel}>
							<SvgIcon icon="Close" />
						</div>
					}
				</div>
			</div>
		);
	}
}

TradeItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(TradeItem);
