import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field } from 'redux-form';
import { toUsaMoneyFormat, translate } from '@global';
import {
	FormInputNumber,
	SvgIcon,
	R,
} from '@components';
import { connect } from 'react-redux';
import { app } from '@redux';

const styles = theme => ({
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	cashToWithdrawInfo: {
		alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-start',
	},
	cashToWithdrawInfoText: {
		fontSize: '14px',
		marginTop: 0,
		paddingTop: 0,
		color: theme.typography.color.secondary,
		paddingLeft: '24px',
	},
	cashToWithdrawRefreshIcon: {
		marginLeft: '5px', cursor: 'pointer',
	},
	cashToWithdrawLoadingIcon: {
		alignItems: 'center', display: 'flex', justifyContent: 'flex-start', margin: 'auto',
	},
});

const mapStateToProps = state => ({
	resolution: app.selectors.resolution(state),
});

class WithdrawAmount extends React.Component {
	render() {
		const {
			classes,
			loading,
			availableAmount,
			additionalText,
			withdrawLabelText,
			theme,
			disabled,
			name,
			resolution,
			onClick,
			onChange,
			onBlur,
			...props
		} = this.props;

		return (
			<div className={classes.inputItem}>
				<Field
					component={FormInputNumber}
					name={name}
					placeholder={resolution === app.CONFIG.RESOLUTIONS_ENUM.xs ? 'Amount' : <R id="common_enter_the_amount_here" defaultValue="Enter the Amount here" />}
					label="Amount"
					additionalText={additionalText}
					disabled={disabled}
					{...props}
					onChange={onChange}
					onBlur={onBlur}
					decimalScale={2}
				/>
				<div className={classes.cashToWithdrawInfo}>

					{!loading &&
						<div className={classes.cashToWithdrawInfo}>
							<div className={classes.cashToWithdrawInfoText}>
								{withdrawLabelText === undefined ? translate('common_amount_available_to_withdraw', 'Amount available to withdraw') : withdrawLabelText}: {toUsaMoneyFormat(availableAmount)}
							</div>
							<SvgIcon
								className={classes.cashToWithdrawRefreshIcon}
								icon="Refresh"
								htmlColor={theme.typography.color.secondary}
								onClick={() => {
									if (onClick) {
										onClick();
									}
								}}
							/>
						</div>
					}
					{loading &&
						<div className={classes.cashToWithdrawLoadingIcon}>
							<CircularProgress
								style={{ color: '#1a96ff' }}
								size={34}
							/>
						</div>
					}
				</div>
			</div>

		);
	}
}

WithdrawAmount.propTypes = {
	name: PropTypes.string,
	onClick: PropTypes.func,
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	availableAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
	withdrawLabelText: PropTypes.string,
};

WithdrawAmount.defaultProps = {
	name: 'Amount',
	onClick: null,
	withdrawLabelText: undefined,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
)(WithdrawAmount);
