import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { WireDeposit } from '@shared';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import { Modal, ProgressContainer } from '@components';

const mapStateToProps = state => ({
	ready: !userAccounts.selectors.accountInfo(state).loading,
});

class DepositFundsWireModal extends React.Component {
	render() {
		const {
			onClose,
			ready,
		} = this.props;

		return (
			<Modal
				title="Deposit"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.DEPOSIT_FUNDS_WIRE}`}
			>
				<ProgressContainer submitting={!ready}>
					<div>
						<WireDeposit />
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

DepositFundsWireModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	ready: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(DepositFundsWireModal);
