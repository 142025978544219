export const INDUSTRIES = [
	'Agriculture, Forestry, Fishing and Hunting',
	'Mining, Quarrying, and Oil and Gas Extraction',
	'Utilities',
	'Construction',
	'Manufacturing',
	'Wholesale Trade',
	'Retail Trade',
	'Transportation and Warehousing',
	'Information',
	'Finance and Insurance',
	'Monetary Authorities-Central Bank',
	'Credit Intermediation and Related Activities',
	'Depository Credit Intermediation',
	'Nondepository Credit Intermediation',
	'Activities Related to Credit Intermediation',
	'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
	'Securities and Commodity Contracts Intermediation and Brokerage',
	'Securities and Commodity Exchanges',
	'Other Financial Investment Activities',
	'Investment Management with fees paid by customers',
	'Investment Management',
	'Insurance Carriers and Related Activities',
	'Insurance Carriers',
	'Agencies, Brokerages, and Other Insurance Related Activities',
	'Funds, Trusts, and Other Financial Vehicles',
	'Insurance and Employee Benefit Funds',
	'Trusts Estates and Agency Accounts',
	'Other Investment Pools and Funds',
	'Real Estate and Rental and Leasing',
	'Professional, Scientific, and Technical Services',
	'Management of Companies and Enterprises',
	'Holding Company',
	'Offices of Bank Holdings Company',
	'Offices of Other Holdings Company',
	'Administrative and Support and Waste Management and Remediation Services',
	'Educational Services',
	'Health Care and Social Assistance',
	'Arts, Entertainment, and Recreation',
	'Accommodation and Food Services',
	'Other Services (except Public Administration)',
	'Public Administration',
	'Other',
];

export const SOURCE_OF_FUNDING = {
	EARNINGS: 'Earnings/Employment',
	SOCIAL_SECURITY: 'Social Security Benefits',
	PENSION: 'Pension/IRA/Savings',
	GIFT: 'Gift',
	SALE_OF_BUSINESS: 'Sale of Business or Property',
	INHERITANCE: 'Inheritance',
	INSURANCE: 'Insurance',
	OTHER: 'Other',
};

export const EMPLOYMENT_STATUS = {
	EMPLOYED: 'Employed',
	SELF_EMPLOYED: 'Self-Employed',
	RETIRED: 'Retired',
	UNEMPLOYED: 'Unemployed',
	STUDENT: 'Student',
};

export const OCCUPATIONS = [
	'Accountant/Auditor/Bookkeeper',
	'Adjuster',
	'Advertiser/Marketer/PR Professional',
	'Air Traffic Controller',
	'Ambassador/Consulate Professional',
	'Analyst',
	'Appraiser',
	'Architect/Designer',
	'Artist/Performer/Actor/Dancer',
	'Assistant/Executive Assistant',
	'Athlete',
	'Attorney/Judge/Legal Professional',
	'Auctioneer',
	'Banker/Lending Professional',
	'Barber/Beautician/Hairstylist',
	'Broker/Registered Rep',
	'Business Executive',
	'Business Owner',
	'Caregiver',
	'Carpenter/Construction Worker/Contractor',
	'Cashier',
	'Chef/Cook',
	'Chiropractor',
	'Civil Servant',
	'Clergy',
	'Clerk',
	'Compliance/Regulatory Professional',
	'Consultant',
	'Counselor/Therapist',
	'Customer Service Representative',
	'Dealer',
	'Dentist',
	'Distributor',
	'Doctor/Surgeon/Physician',
	'Driver',
	'Engineer',
	'Exterminator',
	'Factory/Warehouse Worker',
	'Farmer/Rancher',
	'Financial Planner/Advisor',
	'Flight Attendant',
	'Human Resources Professional',
	'Importer/Exporter',
	'Inspector/Investigator',
	'Investor',
	'IT Professional/IT Associate',
	'Janitor',
	'Jeweler',
	'Laborer',
	'Landscaper',
	'Mechanic',
	'Military, Officer or Associated',
	'Mortician/Funeral Director',
	'Nurse',
	'Office Associate',
	'Pharmacist',
	'Physical Therapist',
	'Pilot',
	'Police Officer/Firefighter/Law Enforcement Professional',
	'Politician',
	'Project Manager',
	'Real Estate Professional',
	'Researcher',
	'Salesperson',
	'Scientist',
	'Seamstress/Tailor',
	'Security Guard',
	'Social Worker',
	'Teacher/Professor',
	'Technician',
	'Teller',
	'Tradesperson/Craftsperson',
	'Trainer/Instructor',
	'Underwriter',
	'Veterinarian',
	'Writer/Journalist/Editor',
	'Other',
];

export const ACCREDITED_INVESTOR_TOOLTIP_TEXT = 'Accredited investor: An Accredited Investor is a person whose individual net worth, ' +
'or joint net worth with a spouse, is over $1 million (excluding the value of your primary residence) ' +
'or who had an individual income in excess of $200,000 in each of the two most recent years, or ' +
'joint income with a spouse in excess of $300,000 in each of those years and reasonably expects to ' +
'reach the same income level in the current year';

export const QUALIFIED_CLIENT_TOOLTIP_TEXT = 'Qualified client: A Qualified Client is a person who has at least $1,100,000 ' +
'under management with an SEC registered investment advisor or individual net worth in excess of $2,200,000.';

export const INVESTMENT_TIMELINE_RANGES = {
	NONE: { value: 'None', text: 'None' },
	LESS_THAN_A_YEAR: { value: 'Less than 1 year', text: 'Less than 1 year' },
	ONE_TO_FIVE_YEARS: { value: '1 to 5 years', 	 text: '1 to 5 years' },
	FIVE_TO_TEN_YEARS: { value: '5 to 10 years', 	 text: '5 to 10 years' },
	SIX_TO_TEN_YEARS: { value: '6 to 10 years', 	 text: '6 to 10 years' },
	TEN_TO_FIFTEEN_YEARS: { value: '10 to 15 years', text: '10 to 15 years' },
	OVER_TEN_YEARS: { value: 'Over 10 years', 	 text: 'Over 10 years' },
	OVER_FIFTEEN_YEARS: { value: 'Over 15 years', text: 'Over 15 years' },
};

export const LIQUIDITY_IMPORTANCE_RANGES = {
	VERY_IMPORTANT: { value: 'Very Important (Less Than 1 year)', text: 'Very Important (Less Than 1 year)' },
	IMPORTANT: { value: 'Important (1 - 5 years)', text: 'Important (1 - 5 years)' },
	SOMEWHAT_IMPORTANT: { value: 'Somewhat Important (5 - 15 years)', text: 'Somewhat Important (5 - 15 years)' },
	DOES_NOT_MATTER: { value: 'Does Not Matter (Over 15 years)', text: 'Does Not Matter (Over 15 years)' },
};
