import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { constants, env } from '@config';
import { dashboard, userAccounts } from '@redux';
import { getDateTimeEst, dateDiffInDays } from '@global';
import {
	Alerts,
	CYAlerts,
	BlockModals,
	CYProfile,
	FreshChat,
	HubSpotChat,
	Icons,
	MainHeader,
	MainContent,
	Profile,
	AccountFeatures,
	Contacts,
	Documents,
	TradingPlatforms,
	ConfirmProfile,
	Reports,
	TerminalMt5,
	Notifications,
	Modals,
	Sidebar,
	TradingAssistance,
	ThirdPartyTools,
	CashManagementContent,
	MainFooterLME,
} from './components';

const styles = theme => ({
	'@global': {
		body: {
			minHeight: '100vh', // for Payment Gate Widget
			backgroundColor: theme.palette.color.primary.white,
		},
	},
	rootContainer: {
		maxWidth: '1440px',
		margin: '0 auto',
	},
	root: {
		flexGrow: 1,
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%',
	},
	content: {
		width: '100%',
		minWidth: 0,
		minHeight: '50vh ',
	},
	sidebar: {
	},
	demoAccountNotification: {
		margin: '5px 0px 5px 48px',
		fontWeight: 'bold',
	},
	demoAccountExpirationWarning: {
		...theme.typography.style.body,
		color: theme.typography.color.red,
		margin: '5px 0px 5px 48px',
		fontWeight: 'bold',
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	userInfo: userAccounts.selectors.userInfo(state),
	currentAccountIsDemo: userAccounts.selectors.currentAccountIsDemo(state),
	currentAccountIsPaper: userAccounts.selectors.currentAccountIsPaper(state),
	demoAccount: userAccounts.selectors.getDemoAccount(state),
	anyActiveAccountExists: userAccounts.selectors.anyActiveAccountExists(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		init: tradeCode => dispatch(dashboard.actions.init(tradeCode)),
	},
});

function composeExpirationWarningText(days) {
	if (days === 0) {
		return 'Your demo account will expire today';
	}
	return `Your demo account will expire in ${days + 1} days`;
}

class DashboardView extends React.Component {
	componentDidMount() {
		// init application when start (no current account)
		if (!this.props.currentTradeAccount.tradeCode) {
			const currentTradeCode = this.props.match.params.accid;
			this.props.actions.init(currentTradeCode);
		}
	}

	render() {
		const {
			classes,
			match,
			fullWidth,
			userInfo: { NeedChangePass },
			currentAccountIsDemo,
			currentAccountIsPaper,
			demoAccount,
			anyActiveAccountExists,
		} = this.props;

		if (NeedChangePass) {
			// forcing the client to do the necessary action
			return (
				<React.Fragment>
					<BlockModals />
				</React.Fragment>
			);
		}

		let days = 0;
		if (demoAccount) {
			const dateNowWithUSOffset = getDateTimeEst();
			const dateExpiration = new Date(demoAccount.ExpirationDate);
			days = dateDiffInDays(dateExpiration, dateNowWithUSOffset);
		}

		return (
			<React.Fragment>
				<div className={classes.rootContainer}>
					<div className={classes.root}>
						<div className={classes.sidebar}>
							<Sidebar />
						</div>
						<div id="dashboard-top" className={classes.content}>
							<Grid container spacing={3}>
								<Grid item lg={fullWidth ? 12 : 9} md={12} sm={12} xs={12}>
									<MainHeader />
									{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm) &&
										<CYAlerts match={match} />
									}
									{(currentAccountIsDemo || currentAccountIsPaper) &&
										<p className={classes.demoAccountNotification}>
											Demo account, not real money
										</p>}
									{env.firm === constants.LIME_FIN && currentAccountIsDemo && !anyActiveAccountExists &&
										<p className={classes.demoAccountExpirationWarning}>
											{composeExpirationWarningText(days)}
										</p>}
									{env.firm === constants.LIME_FIN && !(currentAccountIsDemo || currentAccountIsPaper) &&
										<Alerts />
									}
									<Switch>
										<Route path={`${match.url}/cashmanagement`} component={CashManagementContent} />
										{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm) &&
											<Route exact path={`${match.url}/settings`} component={CYProfile} />
										}
										{env.firm === constants.LIME_FIN &&
											<Route exact path={`${match.url}/settings`} component={Profile} />
										}
										<Route exact path={`${match.url}/features`} component={AccountFeatures} />
										<Route exact path={`${match.url}/contacts`} component={Contacts} />
										<Route exact path={`${match.url}/documents`} component={Documents} />
										<Route exact path={`${match.url}/tradingplatforms`} component={TradingPlatforms} />
										<Route exact path={`${match.url}/confirmPersonalInfo`} component={ConfirmProfile} />
										<Route exact path={`${match.url}/reports`} component={Reports} />
										<Route exact path={`${match.url}/terminalMt5`} component={TerminalMt5} />
										{/* functionality was closed by task WEBCAB-5925
										<Route exact path={`${match.url}/ipo`} component={IpoContent} /> */}
										<Route exact path={`${match.url}/thirdpartytools`} component={ThirdPartyTools} />
										{/* functionality was closed by task WEBCAB-3983
										<Route exact path={`${match.url}/promotions`} component={Promotions} /> */}
										<Route exact path={`${match.url}/icons`} component={Icons} />
										{env.WEBCAB_3434_tradingAssistance &&
											<Route exact path={`${match.url}/tradingAssistance`} component={TradingAssistance} />
										}
										<Route path={`${match.url}`} component={MainContent} />
									</Switch>
									{env.firm === constants.LIME_LME &&
										<MainFooterLME />
									}
								</Grid>
							</Grid>
						</div>
						<div>
							<Notifications hidden={fullWidth} />
						</div>
					</div>
				</div>
				<Modals />
				{env.WEBCAB_5222_hubspotChat ? <HubSpotChat /> : <FreshChat />}
			</React.Fragment>
		);
	}
}

DashboardView.propTypes = {
	classes: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	fullWidth: PropTypes.bool,
	demoAccount: PropTypes.object,
	anyActiveAccountExists: PropTypes.bool.isRequired,
	currentAccountIsDemo: PropTypes.bool,
	currentAccountIsPaper: PropTypes.bool,
	userInfo: PropTypes.shape({
		NeedChangePass: PropTypes.bool,
	}),
};

DashboardView.defaultProps = {
	fullWidth: false,
	demoAccount: null,
	currentAccountIsDemo: false,
	currentAccountIsPaper: false,
	userInfo: {
		NeedChangePass: false,
	},
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(DashboardView);
