import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import { toUsaFormat } from '@global';
import moment from 'moment';

const styles = theme => ({
	root: {
		'box-shadow': '0 1px 2px 0 rgba(61, 65, 71, 0.12)',
		padding: '13px 10px',
		width: '160px',
		height: '215px',
		'background-color': 'white',
		'&:hover': {
			backgroundColor: '#e3edf5',
		},
		'margin-right': '20px',
	},
	symbol: {
		...theme.typography.style.subheadingSm,
		color: theme.typography.color.primary,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	companyName: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	mt10: {
		'margin-top': '10px',
	},
	mt15: {
		'margin-top': '15px',
	},
	splitter: {
		'border-bottom': '1px solid #deeaef',
	},
	title: {
		...theme.typography.style.subheading,
		color: theme.typography.color.primary,
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	amount: {
		...theme.typography.style.subheading,
		'min-height': '50px',
	},
	ratio: {
		marginLeft: '10px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		...theme.typography.style.body,
		fontSize: '14px',
		minHeight: '50px',
	},
	invisibleScrollbar: {
		'&::-webkit-scrollbar': {
			display: 'none!important',
		},
	},
	description: {
		...theme.typography.style.body,
		lineHeight: '20px',
		fontSize: '13px',
		marginTop: '5px',
		overflowY: 'auto',
		wordWrap: 'normal',
		height: '60px',
		textOverflow: 'ellipsis',
		overflowX: 'hidden',
	},
	colored: {
		...theme.typography.style.body,
		color: '#F9D113',
	},
});

class SplitItem extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		return (
			<div className={classes.root}>
				<div className={classes.colored}>
					SPLIT
				</div>
				<div className={`${classes.title} ${classes.mt10}`}>
					{item.Symbol}
				</div>
				<div className={classes.companyName}>
					{item.CompanyName}
				</div>
				<div className={` ${classes.splitter} ${classes.mt10}`} />
				<div className={` ${classes.description} ${classes.invisibleScrollbar}`}>
					{item.ExpectedQuantity &&
						<div>expected <span>{toUsaFormat(item.ExpectedQuantity, 0)}</span> of shares</div>
					}
					{item.Ratio &&
						<div>ratio	<span className={classes.ratio}>{toUsaFormat(item.Ratio)}</span></div>
					}
				</div>
				<div className={`${classes.date}`}>
					{moment(item.ExecutionDate).utcOffset(-5).format('D MMM YY')}
				</div>
			</div>
		);
	}
}

SplitItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	SafeComponent,
)(SplitItem);
