import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { auth, profile } from '@redux';
import { constants } from '@config';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	A,
	Button,
	ErrorMessage,
	FormInput,
	FormInputPhoneIconNumber,
	ProgressContainer,
	SuccessMessage,
} from '@components';
import { RequireSignatureModal } from '../../..';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	confirmLink: {
		marginTop: '16px',
		paddingLeft: '24px',
	},
	link: {
		textTransform: 'uppercase',
	},
	button: {
		paddingTop: '24px',
	},
	inputPhoneItem: {
		'& > div > div:last-child': {
			background: theme.palette.color.primary.attestationBackground,
			borderRadius: '8px',
		},
	},
	inputVerificationItem: {
		'& > div > div': {
			background: theme.palette.color.primary.attestationBackground,
			borderRadius: '8px',
		},
	},
});

const formSelector = formValueSelector('ChangeProfilePhoneForm');
const emptyIfHidden = phone => ((phone && phone.indexOf('*') >= 0) ? '' : phone);
const mapStateToProps = (state) => {
	const modalParams = profile.selectors.modals(state)[ModalPopups.CHANGE_PROFILE_PHONE];
	const userProfile = modalParams || profile.selectors.profile(state);
	return {
		initialValues: {
			Value: emptyIfHidden(userProfile.PersonalInformation.Phone),
			clientId: userProfile.ClientId,
			guid: userProfile.Guid,
		},
		phoneValue: formSelector(state, 'Value'),
		profileSubmitting: !!userProfile.submitting,
		pendingOrder: userProfile.PendingOrders.Phone,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		sendConfirmationCode: (phone, reason) => dispatch(auth.actions.sendConfirmationCodeRequest(phone, reason)),
	},
});

class ChangeProfilePhoneModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.Value) {
			errors.Value = 'required';
		}

		if (!values.ConfirmationCode) {
			errors.ConfirmationCode = 'required';
		}

		return errors;
	}

	constructor(props) {
		super(props);
		this.state = {
			hasConfirmationCode: false,
			showPassword: false,
			password: '',
			currentCount: 0,
			intervalId: null,
			phoneState: {
				determined: false,
				completed: false,
			},
		};
		this.onRequestVerificationCode = this.onRequestVerificationCode.bind(this);
		this.handleClickShowSmsCode = this.handleClickShowSmsCode.bind(this);
		this.onPhoneChange = this.onPhoneChange.bind(this);
		this.timer = this.timer.bind(this);
	}

	componentWillUnmount() {
		// use intervalId from the state to clear the interval
		clearInterval(this.state.intervalId);
	}

	onRequestVerificationCode() {
		// this.props.change('Attachments', files);

		this.props.actions.sendConfirmationCode(this.props.phoneValue, constants.EtnaGateConfirmCode);
		const intervalId = setInterval(this.timer, 1000);
		this.setState({
			hasConfirmationCode: true,
			currentCount: 30,
			intervalId,
		});
	}

	onPhoneChange(match) {
		if (match) {
			this.setState({
				phoneState: match,
			});
		}
	}

	handleClickShowSmsCode() {
		this.setState({ showPassword: !this.state.showPassword });
	}

	timer() {
		const timeRemains = this.state.currentCount - 1;
		if (timeRemains === 0) {
			clearInterval(this.state.intervalId);
		}
		this.setState({ currentCount: timeRemains });
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			profileSubmitting,
			pendingOrder,
			submitting,
			submitSucceeded,
			phoneValue,
			initialValues,
		} = this.props;

		const {
			hasConfirmationCode,
			currentCount,
			phoneState,
		} = this.state;

		return (
			<RequireSignatureModal
				title="Change Phone"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_PROFILE_PHONE}`}
			>
				{submitSucceeded && <SuccessMessage onClick={onClose} text="Your request to change phone is being processed." />}
				{!submitSucceeded && !profileSubmitting && pendingOrder &&
					<ErrorMessage onClick={onClose} text="Another request for phone change is in progress. Please try again later." />
				}
				{!submitSucceeded && !pendingOrder &&
					<ProgressContainer submitting={profileSubmitting}>
						<form
							onSubmit={handleSubmit(profile.actions.changeProfilePhoneFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.inputPhoneItem}>
								<Field
									component={FormInputPhoneIconNumber}
									name="Value"
									placeholder="New phone"
									label="New phone"
									onPhoneChange={this.onPhoneChange}
								/>
							</div>
							<div className={classes.inputVerificationItem}>
								{hasConfirmationCode &&
									<Field
										component={FormInput}
										name="ConfirmationCode"
										placeholder="Verification Code"
										label="Verification Code"
										type={this.state.showPassword ? 'text' : 'password'}
										value={this.state.password}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={this.handleClickShowSmsCode}
													// onMouseDown={this.handleMouseDownPassword}
												>
													{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										}
									/>
								}
								<div className={classes.confirmLink}>
									{currentCount === 0 && initialValues.Value !== phoneValue && phoneState.completed &&
										<A
											upper
											onClick={this.onRequestVerificationCode}
										>
											{hasConfirmationCode ? 'Request Code Again' : 'Request Verification Code'}
										</A>
									}
									{currentCount > 0 &&
										<p>
											You can request new code in {currentCount} seconds
										</p>
									}
								</div>
							</div>
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={!hasConfirmationCode || invalid || submitting}
								>
									{submitting ?
										<CircularProgress
											style={{ color: '#1a96ff' }}
											size={18}
										/>
										: 'Change Phone'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeProfilePhoneModal.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	profileSubmitting: PropTypes.bool.isRequired,
	pendingOrder: PropTypes.bool.isRequired,
	initialValues: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'ChangeProfilePhoneForm',
		validate: ChangeProfilePhoneModal.validate,
	}),
)(ChangeProfilePhoneModal);
