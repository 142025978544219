import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	userAccounts,
	depositFunds,
} from '@redux';
import { IraTypes } from '@enums';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	A,
	Link,
	FormSelect,
	FormInputNumber,
	Button,
	Divider,
	FileInput,
	LimeLogo,
	SuccessMessage,
	SvgIcon,
} from '@components';
import MenuItem from '@material-ui/core/MenuItem';
import { connect, useDispatch } from 'react-redux';

const styles = theme => ({
	row: {
		display: 'flex',
	},
	amount: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	accountContainer: {
		// marginTop: '-15px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	divider: { marginTop: '24px' },
	button: {
		marginTop: '45px',
	},
});

const DEPOSIT_FUNDS_IRA_FORM = 'depositFundsIraForm';
const formSelector = formValueSelector(DEPOSIT_FUNDS_IRA_FORM);

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const accountInfo = userAccounts.selectors.accountInfo(state);
	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
			Amount: ownProps.amount,
			Attachments: [],
		},
		currentTradeAccount,
		getFormValue: fieldName => formSelector(state, fieldName),
		iraType: accountInfo.IraType,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		hideDepositFundsIraModal: () => dispatch(depositFunds.actions.hideDepositFundsIraModal()),
	},
});

const CorDepositInstruction = (tradeAccount) => {
	const dispatch = useDispatch();
	const actions = {
		showDepositFundsWireModal: () =>
			dispatch(depositFunds.actions.showDepositFundsWireModal(null)),
	};
	return (
		<React.Fragment>
			<p>IRA Contributions may be made by Check, Wire or Journal.  Additional documentation may be required depending on how the contribution is submitted.</p>
			<ol>
				<li>Checks may be made payable to Axos Clearing and mailed to 1200 Landmark Center, Suite 800 Omaha, NE 68102. Submit a note on the memo line or a separate letter of instruction to indicate if the contribution is a current or prior year contribution.</li>	
				<li>Wire – Please see wire instructions <A onClick={actions.showDepositFundsWireModal}>here</A> and indicate the contribution year in the memo line. Please note that you don't need to submit a request using form below.</li>
				<li>Journal - Fill and submit  LOA for Internal Transfer form (<A target="_blank" href="/static/cor/ira/LOA_for_Internal_Transfer.pdf">LOA_for_Internal_Transfer.pdf</A>) if you have a retail account that you want to transfer from.</li>
				<li>ACAT – Fill and submit the ACAT form (<A target="_blank" href="/static/cor/ira/ACAT_Account_Transfer_Form.pdf">ACAT_Account_Transfer_Form.pdf</A> ) along with a copy of your recent IRA statement from contra firm. Please note ACAT transfer is available from another IRA account only.</li>
			</ol>
			<p>Link</p>
			<p>Please note Partial recurring transfer and ACH are not an available method of distribution.</p>
		</React.Fragment>
	);
};
const VisionDepositInstruction = ({ iraType }) => {
	switch (iraType) {
	case IraTypes.STRATA: return (
		<React.Fragment>
			<p>IRA Contributions may be made by Check or Wire.  Additional documentation may be required depending on how the contribution is submitted.</p>
			<ol>
				<li>Fill and submit the Deposit Certification form (<A target="_blank" href="/static/vision/ira/STRATA_Trust_IRA_Deposit_Certification.pdf">STRATA_Trust_IRA_Deposit_Certification.pdf</A>). Instructions for Check or Wire are on the deposit form. Please be sure to indicate your contribution year on the check or wire memo.</li>
				<li>ACAT – instructions available in a separate <Link to="#AcatFund">form</Link></li>
			</ol>
			<p>Please note ACH is not available as a method of deposit.</p>
		</React.Fragment>
	);
	case IraTypes.EQUITY_TRUST: return (
		<React.Fragment>
			<p>IRA Contributions may be made by Check or Wire. Additional documentation may be required depending on how the contribution is submitted.</p>
			<ol>
				<li>Fill and submit the Deposit slip for contribution (<A target="_blank" href="/static/vision/ira/Equity_Institutional_IRA_Deposits.pdf">Equity_Institutional_IRA_Deposits.pdf</A>). Instructions for Check or Wire are on the Deposit coupon. Please be sure to indicate your contribution year on the check or wire memo. In addition, Fill and submit the DOI form (<A target="_blank" href="/static/vision/ira/Trading Account Buy DOI-Rev092419.pdf">Trading Account Buy DOI-Rev092419.pdf</A>)</li>
				<li>ACAT – instructions available in a separate <Link to="#AcatFund">form</Link></li>
			</ol>
		</React.Fragment>
	);
	default: return <React.Fragment />;
	}
};

const DepositInstruction = ({ tradeAccount, iraType }) => {
	switch (tradeAccount.clearerFirm) {
	case 'cor': return <CorDepositInstruction tradeAccount={tradeAccount} />;
	case 'vision': return <VisionDepositInstruction iraType={iraType} />;
	default: return <React.Fragment />;
	}
};

class DepositFundsIraForm extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.Amount) {
			errors.Amount = 'required';
		}

		if (!values.Attachments || values.Attachments.length === 0) {
			errors.Attachments = 'required';
		}
		return errors;
	}

	render() {
		const {
			classes,
			actions,
			iraType,
			tradeAccount,
			handleSubmit,
			submitSucceeded,
			getFormValue,
			currentTradeAccount,
			invalid,
			pristine,
			change,
			submitting,
		} = this.props;
		const noAttachments = (getFormValue('Attachments') || []).length === 0;
		return submitSucceeded ? <SuccessMessage
			onClick={actions.hideDepositFundsIraModal}
			text="Banking transaction completed"
			buttonText="Thank you"
		/> :
			(
				<form onSubmit={handleSubmit(depositFunds.actions.depositIraFundsForm)}>
					<div className={classes.accountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								<LimeLogo />
							</div>
							<Field component={FormSelect} name="TradeCode" label="Account" disabled>
								<MenuItem value={currentTradeAccount.tradeCode}>
									<div className={classes.inputContainer}>
										<div className={classes.account}>{currentTradeAccount.tradeCode}</div>
									</div>
								</MenuItem>
							</Field>
						</div>
					</div>
					<div className={classes.amount}>
						<Field component={FormInputNumber} name="Amount" placeholder="Enter the Amount here" />
					</div>
					<div className={classes.divider}>
						<Divider />
					</div>
					<DepositInstruction tradeAccount={tradeAccount} iraType={iraType} />
					<div className={classes.fileUploader}>
						<FileInput
							onFilesUpdate={data => change('Attachments', data)}
						/>
					</div>
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={invalid || submitting || pristine || noAttachments}
						>
							{submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								: 'Deposit'
							}
						</Button>
					</div>
				</form>
			);
	}
}

DepositFundsIraForm.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	tradeAccount: PropTypes.shape({
		tradeCode: PropTypes.string.isRequired,
		clearerFirm: PropTypes.string.isRequired,
	}).isRequired,
	getFormValue: PropTypes.func.isRequired,
	submitSucceeded: PropTypes.bool,
	iraType: PropTypes.oneOf(Object.values(IraTypes)),
};
DepositFundsIraForm.defaultProps = {
	submitSucceeded: null,
	iraType: null,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: DEPOSIT_FUNDS_IRA_FORM,
		validate: DepositFundsIraForm.validate,
	}),
)(DepositFundsIraForm);
