import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@components';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	iconButtonRoot: {
		height: 'auto',
	},
};

const VisibilityButton = ({ isVisible, onClick, classes }) => {
	const Icon = isVisible ?
		<SvgIcon icon="VisibilityOn" htmlColor="white" />
		: <SvgIcon icon="VisibilityOff" htmlColor="white" />;

	return (
		<IconButton
			onClick={onClick}
			classes={{
				root: classes.iconButtonRoot,
			}}
		>
			{Icon}
		</IconButton>
	);
};

VisibilityButton.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	onClick: PropTypes.func,
	classes: PropTypes.object.isRequired,
};

VisibilityButton.defaultProps = {
	onClick: null,
};

export default withStyles(styles)(VisibilityButton);
