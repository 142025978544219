import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import compose from 'recompose/compose';
import {
	ExpansionPanel,
	FileDownload,
	ProgressContainer,
	ShowMore,
	DateRangePicker,
	Select,
	SvgIcon,
} from '@components';
import { snackbar, userAccounts } from '@redux';
import { env } from '@config';
import { connect } from 'react-redux';
import { Item } from './components';


const styles = (theme) => {
	const filtersCss = {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		'& > span': {
			marginRight: '40px',
			whiteSpace: 'nowrap',
		},
	};
	return {
		root: {
			display: 'flex',
			alignItems: 'center',
			flexWrap: 'wrap',
		},
		corpactionsFilters: filtersCss,
		corpactionsHeader: {
			...filtersCss,
			padding: '10px 0',
			[theme.breakpoints.up('md')]: {
				flexWrap: 'nowrap',
			},
		},
		line_break: {
			width: '100%',
		},
		itemContainer: {
			padding: '0 16px',
			margin: '0px -16px',
		},
		item: {
			'margin-bottom': '16px',
		},
		selectType: {
			marginTop: '5px!important',
			color: '#1a96ff',
		},
		inputType: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	};
};

const mapStateToProps = state => ({
	corporateActions: userAccounts.selectors.corporateActions(state),
	oldCorporateActions: userAccounts.selectors.oldCorporateActions(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
});

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		loadPage: payload => dispatch(userAccounts.actions.getCorporateActions.request(payload)),
		oldLoadPage: payload => dispatch(userAccounts.actions.getOldCorporateActions.request(payload)),
		showError: message => dispatch(snackbar.actions.showErrorMessage({ text: message })),
	},
});

class CorporateActions extends React.Component {
	startDate = new Date(moment().year(), moment().month() - 2, moment().date());
	endDate = new Date(moment().year() + 1, moment().month(), moment().date());
	actionsType = 'any';

	startDateInit = this.startDate;
	endDateInit = this.endDate;
	actionsTypeInit = this.actionsType;
	currentAccount = this.props.currentTradeAccount.tradeCode;

	startDateChange = (date) => {
		this.startDate = date;
		this.reLoad();
	};

	endDateChange = (date) => {
		this.endDate = date;
		this.reLoad();
		};

	actionsTypeChange = (event) => {
		this.actionsType = event.target.value;
		this.reLoad();
	}

	loadMore = () => {
		const {
			currentTradeAccount,
			corporateActions,
			oldCorporateActions,
			isOld,
		} = this.props;

		let func = (isOld) ? this.props.actions.oldLoadPage : this.props.actions.loadPage;
		let page = (isOld) ? oldCorporateActions.pagination.page : corporateActions.pagination.page;
		func({
			tradeCode: currentTradeAccount.tradeCode,
			startDate: moment(this.startDate).format('YYYYMMDD'),
			endDate: moment(this.endDate).format('YYYYMMDD'),
			page: page + 1,
			pageSize: 8,
			type: this.actionsType,
		});
	}

	reLoad = () => {
		const {
			currentTradeAccount,
			isOld,
		} = this.props;

		let func = (isOld) ? this.props.actions.oldLoadPage : this.props.actions.loadPage;
		func({
			tradeCode: currentTradeAccount.tradeCode,
			startDate: moment(this.startDate).format('YYYYMMDD'),
			endDate: moment(this.endDate).format('YYYYMMDD'),
			page: 1,
			pageSize: 8,
			type: this.actionsType,
			isOld,
		});
	}

	render() {
		const {
			actions,
			classes,
			currentTradeAccount,
			corporateActions,
			oldCorporateActions,
			isOld,
		} = this.props;

		let loading = isOld ? oldCorporateActions.loading : corporateActions.loading;
		let items = isOld ? oldCorporateActions.items : corporateActions.items;
		let page = isOld ? oldCorporateActions.pagination.page : corporateActions.pagination.page;
		let pageSize = isOld ? oldCorporateActions.pagination.pageSize : corporateActions.pagination.pageSize;

		if (this.currentAccount !== currentTradeAccount.tradeCode) {
			this.startDate = this.startDateInit;
			this.endDate = this.endDateInit;
			this.actionsType = this.actionsTypeInit;
			this.currentAccount = currentTradeAccount.tradeCode;
		}

		return (
			<ExpansionPanel
				id="corporate-actions"
				header={
					<div className={classes.corpactionsHeader}>
						<span>{isOld ? 'Old' : ''} Corporate Actions</span>
						<div className={classes.corpactionsFilters}>
							<DateRangePicker
								endDate={this.endDate}
								startDate={this.startDate}
								onEndDateChange={this.endDateChange}
								onStartDateChange={this.startDateChange}
							/>
							<span>
								<Select
									fullWidth={false}
									value={this.actionsType}
									className={classes.selectType}
									onChange={this.actionsTypeChange}
									inputProps={{
										className: classes.inputType,
									}}
								>
									<MenuItem value="any" key="1">ANY KIND</MenuItem>
									<MenuItem value="dividend" key="21">DIVIDENDS</MenuItem>
									<MenuItem value="split" key="31">SPLITS</MenuItem>
									<MenuItem value="other" key="41">OTHER</MenuItem>
								</Select>
							</span>
							<span>
								<FileDownload
									url={`${env.backendUrl}useraccounts/api_corporateactionsreport/`}
									color="green"
									data={{
										TradeCode: currentTradeAccount.tradeCode,
										startDate: moment(this.startDate).format('YYYYMMDD'),
										endDate: moment(this.endDate).format('YYYYMMDD'),
										type: this.actionsType || 'any',
									}}
									onError={data => actions.showError((data && data.Errors && data.Errors[0].Message) || data)}
									fileName="Corporate actions.xlsx"
									buttonTitle={<React.Fragment><SvgIcon icon="DocXls" />EXPORT</React.Fragment>}
								/>
							</span>
						</div>
					</div>
				}
			>

				<ProgressContainer submitting={loading && items && items.length === 0} display="left">
					{items && items.length > 0 &&
						<div className={classes.root}>
							{items.map((item, index) => (
								<div className={classes.itemContainer} key={index}>
									<div className={classes.item}>
										<Item item={item} isOld={isOld} />
									</div>
								</div>
							))}

							<div className={classes.line_break}>
								<ShowMore
									submitting={loading && items && items.length > 0}
									onClick={this.loadMore}
									showMoreButton={(page * pageSize) === items.length}
								/>
							</div>
						</div>
					}
				</ProgressContainer>
			</ExpansionPanel>
		);
	}
}

CorporateActions.propTypes = {
	actions: PropTypes.object.isRequired,
	corporateActions: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CorporateActions);
