/* eslint-disable import/extensions */
import React from 'react';
import { auth } from '@redux';
import { connect } from 'react-redux';
import * as qs from 'query-string';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
	A,
	LanguageSelector,
	R,
} from '@components';
import { constants, env } from '@config';
import { template } from '@templates';
import { AutoLogin, CheckAuth, LoginForm, LoginFormLocal } from './components';

const styles = theme => ({
	'@global': {
		body: {
			backgroundColor: theme.typography.color.white,
		},
	},
	container: {
		textAlign: 'center',
		marginTop: '42px',
	},
	logo: {
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '312px',
		},
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			maxHeight: '150px',
		},
		color: theme.typography.color.primary,
		stroke: theme.typography.color.primary,
	},
	lmeLogo: {
		maxWidth: '280px',
	},
	limeLogo: {
		marginBottom: '32px',
	},
	nsrLogo: {
	},
	text: {
		...theme.typography.style.headline2,
		color: theme.typography.color.primary,
	},
	languageGrid: {
		display: 'flex',
		justifyContent: 'end',
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		setAfterAuthUrl: payload => dispatch(auth.actions.setAfterAuthUrl(payload)),
	},
});

class LoginView extends React.Component {
	componentDidMount() {
		const { actions, location } = this.props;
		actions.setAfterAuthUrl(location.pathname + location.hash);
	}

	render() {
		const {
			classes,
			location,
			checkAuth,
		} = this.props;

		const parsed = qs.parse(location.search);

		return (
			<Grid container justifyContent="center" spacing={0} className={classes.container}>
				<Grid item md={3}>
					<div className={classes.languageGrid}>
						{ env.allowedLangs && <LanguageSelector /> }
					</div>
					<div>
						{template.loginLogo &&
							<A target="_blank" href={env.brokerSiteUrl}>
								<img
									alt="Logo"
									className={classNames({
										[classes.logo]: true,
										[classes.nsrLogo]: env.firm === constants.LIME_NSR,
										[classes.limeLogo]: env.firm !== constants.LIME_NSR,
										[classes.lmeLogo]: env.firm === constants.LIME_LME,
									})}
									src={template.loginLogo}
								/>
							</A>
						}
					</div>

					<h2 className={classes.text}>
						<R id="my_account_sign_in" defaultValue="My Account Sign In" />
					</h2>
					{/* {authWidget ? <LoginForm auth={authWidget} /> : <CheckAuth /> } */}
					{(parsed && parsed.autologin)
						? <AutoLogin autologin={parsed.autologin} />
						:
						<div>
							{checkAuth && <CheckAuth />}
							{!checkAuth && (env.useLocalAuth ? <LoginFormLocal /> : <LoginForm />)}
						</div>
					}
				</Grid>
			</Grid>
		);
	}
}

LoginView.propTypes = {
	classes: PropTypes.object,
	location: PropTypes.object.isRequired,
	checkAuth: PropTypes.bool,
};

LoginView.defaultProps = {
	classes: {},
	checkAuth: false,
};

export default compose(withStyles(styles), connect(null, mapDispatchToProps))(LoginView);
