import React, { useState }  from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, SuccessMessage, ErrorMessage, ProgressContainer, R } from '@components';
import { nontradingOrders, userAccounts } from '@redux';
import CYNonTradingOrderIdentityForm from './CYNonTradingOrderIdentityForm';

const useStyles = makeStyles(theme => ({
	warningContainer: {
		padding: '10px 16px',
		backgroundColor: theme.palette.color.secondary.limitOrder,
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0',
			padding: '10px 16px',
		},
	},
	content: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	list: {
		margin: 0,
	},
	button: {
		color: theme.typography.color.link,
	},
}));


export const CYNonTradingOrderIdentityModal = ({ onClose }) => {

	const classes = useStyles();
	const [step, setStep] = useState('openform');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);
	const debtDocs = useSelector(state => nontradingOrders.selectors.debtDocs(state));

	return (
		<Modal
			title={<R id="common_identification_document_uploading_copy" defaultValue="Identification document uploading" />}
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={onClose}
							text={
								<span>
									<R
										id="common_document_send_success_info_copy"
										defaultValue="Your document is being verified.<br/> Should the verification be successfully completed, current limitations on the account will be removed"
										enableHtml
									/>
								</span>}
						/>
					}
					{step === 'failed' &&
						<ErrorMessage
							onClick={onClose}
							text={<R id="common_free_form_order_failed" defaultValue="Could not complete order" />}
						/>
					}
					{step === 'openform' &&
						<React.Fragment>
							{debtDocs && 
								<div className={classes.warningContainer}>
									<div className={classes.content}>
										Your documents have expired:
										<ul className={classes.list}>
											{debtDocs && debtDocs.map(docDebt => (
												<li key={`js__${docDebt.type.replace(':', '')}`}>{docDebt.title}</li>
											))}
										</ul>
										Withdrawal limits are applied to your account.
									</div>
								</div>
							}
							<CYNonTradingOrderIdentityForm
								enableReinitialize
								forceUnregisterOnUnmount
								onSubmit={nontradingOrders.actions.sendNonTradingOrderIdentityForm}
								onSuccess={() => setStep('succeeded')}
								onFail={() => setStep('failed')}
							/>
						</React.Fragment>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
};
