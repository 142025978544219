import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { profile, userAccounts } from '@redux';
import { connect } from 'react-redux';
import { env } from '@config';
import {
	A,
	Button,
	SvgIcon,
	Modal,
	SuccessMessage,
} from '@components';

const styles = {
	root: {
		display: 'block',
		position: 'relative',
	},
	docLink: {
		display: 'flex',
	},
	docText: {
		paddingLeft: '5px',
	},
	button: {
		paddingTop: '36px',
	},
};


const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	changeTariff: profile.selectors.changeTariff(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		activateTariff: (tradeCode, tariff) =>
			dispatch(profile.actions.changeAccountTariffRequest(tradeCode, tariff)),
		changeAccountTariffReset: () => dispatch(profile.actions.changeAccountTariffReset()),
		getAccountInfo: account =>
			dispatch(userAccounts.actions.getAccountInfoRequest(account)),
	},
});

const TARIFF = 'PerTrade';

class ChangeTariffPerTradeModal extends React.Component {
	activateTariffHandler = () => {
		this.props.actions.activateTariff(
			this.props.currentTradeAccount.tradeCode,
			TARIFF,
		);
	}

	succeededCloseHandler = () => {
		this.props.actions.changeAccountTariffReset();
		this.props.actions.getAccountInfo(this.props.currentTradeAccount);
		this.props.onClose();
	}

	render() {
		const {
			classes,
			onClose,
			changeTariff: {
				submitting,
				submitSucceeded,
			},
		} = this.props;

		return (
			<Modal
				title="Confirmation"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_TARIFF_PER_TRADE}`}
			>
				{submitSucceeded ? <SuccessMessage onClick={this.succeededCloseHandler} text="Your request to change commission plan is processed." /> : (
					<div>
						<p>
							Do you want to switch to the standard $2.50 per trade commission plan?
						</p>
						<div className={classes.button}>
							<Button
								fullWidth
								onClick={this.activateTariffHandler}
								type="submit"
								variant="contained"
								color="primary"
								disabled={submitting}
							>
								{submitting ?
									<CircularProgress
										style={{ color: '#1a96ff' }}
										size={18}
									/>
									: 'I Agree'
								}
							</Button>
						</div>
					</div>
				)}
			</Modal>
		);
	}
}

ChangeTariffPerTradeModal.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	changeTariff: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(ChangeTariffPerTradeModal);
