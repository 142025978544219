import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { userAccounts, withdrawFunds } from '@redux';
import { SuccessMessage } from '@components';
import { AchWithdrawalForm,	WireWithdrawalShortForm } from '@shared';
import { CloseAccountFlow } from '@enums';
import { connect } from 'react-redux';
import WithdrawFundsWireTab from './WithdrawFundsWireTab';

const styles = theme => ({
	root: {
		position: 'relative',
		paddingBottom: '24px',
	},
	row: {
		display: 'flex',
	},
	divider: {
		marginTop: '30px',
	},
	accountContainer: {
	},
	tabHeader: {
		fontSize: '16px',
		fontWeight: 'normal',
		minWidth: '100px',
		maxWidth: '100%',
		flexGrow: 1,
	},
	wrapper: {
		fontSize: '0.875rem',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '72px',
		height: '60px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
});


const mapStateToProps = state => ({
	linkedAccounts: userAccounts.selectors.linkedAccounts(state),
});

const ACH_TYPE = 'ACH';
const WIRE_TYPE = 'WIRE';
const WITHDRAWAL = 'WITHDRAWAL';
const WIRE_WITHDRAWAL = `${WIRE_TYPE}_${WITHDRAWAL}`;
const ACH_WITHDRAWAL = `${ACH_TYPE}_${WITHDRAWAL}`;

const GetTabsComponent = (achBankAccount, wireBankAccount) => {
	const tabs = [];

	const wireTabLong = { value: WIRE_WITHDRAWAL, title: WIRE_TYPE, component: WithdrawFundsWireTab };
	const wireTabShort = { value: WIRE_WITHDRAWAL, title: WIRE_TYPE, component: WireWithdrawalShortForm };
	const achTab = { value: ACH_WITHDRAWAL, title: ACH_TYPE, component: AchWithdrawalForm };

	if (!achBankAccount && !wireBankAccount) {
		tabs.push(wireTabLong);
	} else if (achBankAccount && !wireBankAccount) {
		tabs.push(achTab);
	} else if (!achBankAccount && wireBankAccount) {
		tabs.push(wireTabShort);
	} else if (achBankAccount && wireBankAccount) {
		tabs.push(achTab);
		tabs.push(wireTabShort);
	}

	return tabs;
};

class CloseAccountWithdrawalCash extends React.Component {
	constructor(props) {
		super(props);

		const { linkedAccounts } = this.props;

		if (linkedAccounts) {
			this.achBankAccount = linkedAccounts.find(it => it.Type === ACH_TYPE);
			this.wireBankAccount = linkedAccounts.find(it => it.Type === WIRE_TYPE);
		} else {
			this.achBankAccount = null;
			this.wireBankAccount = null;
		}

		this.state = {
			currentTab: null,
			succeeded: false,
		};
	}

	onSuccess = (type) => {
		this.setState({ succeeded: true });
		this.SuccessType = type;
	}

	onClose = () => {
		this.props.onClose(this.SuccessType);
	}

	setCurrentTab = (event, currentTab) => {
		this.setLinkedBankAccount(currentTab);
		this.setState({ currentTab });
	}

	setLinkedBankAccount = (currentTab) => {
		if (currentTab === WIRE_WITHDRAWAL) {
			this.linkedBankAccount = this.wireBankAccount;
		} else if (currentTab === ACH_WITHDRAWAL) {
			this.linkedBankAccount = this.achBankAccount;
		} else {
			this.linkedBankAccount = null;
		}
	}

	render() {
		const {
			classes,
			closeAccountData: {
				request,
				cashAmount,
			},
		} = this.props;

		const tabs = GetTabsComponent(this.achBankAccount, this.wireBankAccount);

		const { currentTab, succeeded } = this.state;
		const curTab = currentTab ? tabs.find(it => it.value === currentTab) : tabs[0];

		if (curTab) {
			this.setLinkedBankAccount(curTab.value);
		}

		const handleAmountSubmit = this.linkedBankAccount && this.linkedBankAccount.Template ?
			withdrawFunds.actions.withdrawFundsWireForm :
			withdrawFunds.actions.linkAndwithdrawFundsWireForm;

		return (
			<div>
				{succeeded ? <SuccessMessage onClick={this.onClose} text="Order Created" />
					:
					(
						<div>
							<p>You have to transfer cash from your account before you can close it.</p>
							<div className={classes.root}>
								<Tabs
									value={curTab.value}
									onChange={this.setCurrentTab}
									indicatorColor="primary"
									textColor="primary"
								>
									{tabs.map(item => (
										<Tab
											classes={{ root: classes.tabHeader, wrapper: classes.wrapper }}
											label={item.title}
											key={`CASH_WITHDRAWAL_TAB_HEADER_${item.value}`}
											value={item.value}
										/>
									))}
								</Tabs>
							</div>
							<curTab.component
								destroyOnUnmount={false}
								enableReinitialize
								forceUnregisterOnUnmount
								fromUserAccount={{ tradeCode: request.TradeCode, clearer: request.ClearerFirm }}
								toBankAccount={this.linkedBankAccount}
								form="wireLinkAndWithdrawalForm"
								amount={cashAmount}
								onSubmit={handleAmountSubmit}
								onSuccess={this.onSuccess}
								closeAccountFlow={CloseAccountFlow.PROFILE}
							/>
						</div>
					)
				}
			</div>);
	}
}

CloseAccountWithdrawalCash.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	closeAccountData: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(CloseAccountWithdrawalCash);
