import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { env } from '@config';
import { cabinetLog, createQAAttribute } from '@global';
import { SvgIcon, Divider } from '@components';

const useStyles = makeStyles(theme => ({
	button: {
		color: theme.typography.color.white,
		backgroundColor: theme.palette.lime.primary.default,
		'&:hover': {
			backgroundColor: theme.palette.lime.primary.hover,
			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: theme.palette.lime.primary.hover,
			},
		},
		'&:active': {
			backgroundColor: theme.palette.lime.primary.pressed,
		},
	},
	menuItem: {
		color: theme.typography.color.white,
		backgroundColor: 'rgba(119, 191, 27, 1)',
		'&:hover': {
			backgroundColor: theme.palette.lime.primary.default,
		},
	},
	list: {
		padding: '0',
	},
	chevron: {
		stroke: theme.typography.color.white,
	},
}));

export default function GoToTrading(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const classes = useStyles();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleGoToLimeTrading = async () => {
		const {
			widget,
			tradeUrl,
		} = props;

		const token = await widget.oneTimeToken(env.widgetProvider).then(
			res => res,
			(error) => {
				cabinetLog(error);
			},
		);

		const questionMark = '?';
		const ottSeparator = tradeUrl.includes(questionMark) ? '&' : questionMark;
		const gotoTradingUrl = `${tradeUrl}${ottSeparator}ott=${token}`;
		window.open(gotoTradingUrl, '_blank');
		handleClose();
	};

	const handleGoToTakeProfit = () => {
		window.open(env.takeProfitSiteUrl, '_blank');
		handleClose();
	};

	return (
		<div>
			<Button
				className={classes.button}
				onClick={handleClick}
				variant="outlined"
				size="small"
				{...createQAAttribute('go2trading')}
			>
				Go to trading &nbsp;&nbsp;&nbsp;
				<SvgIcon viewBox="0 0 24 24" className={classNames('MuiSelect-icon', classes.chevron)} icon="ArrowDown" />
			</Button>
			<Menu
				classes={{ list: classes.list }}
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted={false}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem className={classes.menuItem} onClick={handleGoToLimeTrading} {...createQAAttribute('lt_item')}>
					Lime Trader
				</MenuItem>
				<Divider />
				<MenuItem className={classes.menuItem} onClick={handleGoToTakeProfit} {...createQAAttribute('tp_item')}>
					TakeProfit
				</MenuItem>
			</Menu>
		</div>
	);
}
