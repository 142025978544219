import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { profile, userAccounts } from '@redux';
import { connect } from 'react-redux';
import { env } from '@config';
import {
	A,
	Button,
	FileDownload,
	SvgIcon,
	Modal,
	SuccessMessage,
} from '@components';

const styles = {
	root: {
		display: 'block',
		position: 'relative',
	},
	docLink: {
		display: 'flex',
	},
	docText: {
		paddingLeft: '5px',
	},
	button: {
		paddingTop: '36px',
	},
};


const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	changeTariff: profile.selectors.changeTariff(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		activateTariff: (tradeCode, tariff) =>
			dispatch(profile.actions.changeAccountTariffRequest(tradeCode, tariff)),
		changeAccountTariffReset: () => dispatch(profile.actions.changeAccountTariffReset()),
		getAccountInfo: account =>
			dispatch(userAccounts.actions.getAccountInfoRequest(account)),
	},
});

const TARIFF = 'PerShare';

class ChangeTariffPerShareModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invalid: true,
		};
	}

	docPdfHandler = () => {
		this.setState({	invalid: false });
	}

	activateTariffHandler = () => {
		this.props.actions.activateTariff(
			this.props.currentTradeAccount.tradeCode,
			TARIFF,
		);
	}

	succeededCloseHandler = () => {
		this.props.actions.changeAccountTariffReset();
		this.props.actions.getAccountInfo(this.props.currentTradeAccount);
		this.props.onClose();
	}

	render() {
		const {
			classes,
			onClose,
			currentAccountData: {
				baseParams,
			},
			changeTariff: {
				submitting,
				submitSucceeded,
			},
		} = this.props;

		const noMoney = (baseParams && baseParams.EquityValue && baseParams.EquityValue < 25000);

		return (
			<Modal
				title="Confirmation"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_TARIFF_PER_SHARE}`}
			>
				{submitSucceeded ? <SuccessMessage onClick={this.succeededCloseHandler} text="Your request to change commission plan is processed." /> : (
					<div>
						{noMoney ?
							<p>
								To switch to Per-Share Commission Plan the minimum account
								balance of $25,000 is required
							</p>
							:
							<div>
								<p>
									The Just2trade per share plan has terms and conditions noted below that differs
									from the standard offered plans.
								</p>
								<p>
									A minimum commission charge of $333.00 (three hundred thirty three dollars)
									based on pro-rata-basis a month must be met by using this plan, If the total
									amount of commissions charged on your account during the month is less than
									the minimum amount, Just2Trade will debit the difference.
								</p>
								<p>
									After you activated Per-share plan, if you decide to switch to the other
									commission plan and apply for this change, the new commission plan goes
									live the first day of the following month.
								</p>
								<p>
									You have to click on the link below to read all terms and conditions applied
									before you can press ‘I Agree’ button.
								</p>
								<FileDownload
									url={`${env.backendUrl}useraccounts/document/PerShare`}
									onClick={this.docPdfHandler}
									showButtonTitleWhenLoading
									buttonTitle={
										<div className={classes.docLink}>
											<SvgIcon icon="DocPdf" />
											<span className={classes.docText}>PerShareDisclosure</span>
										</div>
									}
								/>

								<div className={classes.button}>
									<Button
										fullWidth
										onClick={this.activateTariffHandler}
										type="submit"
										variant="contained"
										color="primary"
										disabled={this.state.invalid || submitting}
									>
										{submitting ?
											<CircularProgress
												style={{ color: '#1a96ff' }}
												size={18}
											/>
											: 'I Agree'
										}
									</Button>
								</div>
							</div>
						}
					</div>
				)}
			</Modal>
		);
	}
}

ChangeTariffPerShareModal.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	changeTariff: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(ChangeTariffPerShareModal);
