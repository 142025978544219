import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { profile, userAccounts } from '@redux';
import {
	A,
	ChangeLink,
	ExpansionPanel,
} from '@components';
import { InputEditableItem } from '@shared';
import { env } from '@config';
import { TwoFactorAutenticationType } from '@enums';
import { createInputQAAttributes, createQAAttribute } from '@global';

const useActions = () => {
	const dispatch = useDispatch();
	return ({
		showChangeTwoFactorAuthorizationModal: () => dispatch(profile.actions.showChangeTwoFactorAuthorizationModal()),
	});
};

const AccountCredentialsPanel = (props) => {
	const {
		onToggle,
		panelId,
		isOpen,
		classes,
	} = props;
	const {
		PersonalInformation,
	} = useSelector(profile.selectors.profile) || {};
	const userInfo = useSelector(userAccounts.selectors.userInfo);
	const hasOpenAccount = useSelector(userAccounts.selectors.hasOpenAccount);
	const actions = useActions();

	const getAuthenticationState = () => {
		switch (userInfo.TwoFactorAutenticationType) {
			case TwoFactorAutenticationType.SMS:
				return 'Enabled (SMS)';
			case TwoFactorAutenticationType.TOTP:
				return 'Enabled (TOTP)';
			case TwoFactorAutenticationType.EMAIL:
				return 'Enabled (EMAIL)';
			default:
				return 'Disabled';
		}
	};

	return (
		<ExpansionPanel
			header="Account Credentials"
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
			qaAttributes={{
				title: createQAAttribute('creds'),
				icon: createQAAttribute('creds_accord'),
			}}
		>
			<InputEditableItem
				title="Password"
				value="*************"
				editControl={hasOpenAccount &&
					<A target="_blank" href={`${env.OAuthServiceUrl}/password/reset`} {...createQAAttribute('password_change')}>CHANGE</A>
				}
				qaAttributes={createInputQAAttributes('password')}
			/>
			<InputEditableItem
				title="Two-factor authentication"
				value={getAuthenticationState()}
				editControl={hasOpenAccount &&
					PersonalInformation.Phone &&
					<ChangeLink
						onClick={actions.showChangeTwoFactorAuthorizationModal}
						havePendingOrder={false}
						qaAttribute={createQAAttribute('2fa_change')}
					/>
				}
				qaAttributes={createInputQAAttributes('2fa')}
			/>
		</ExpansionPanel>
	);
};

AccountCredentialsPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

AccountCredentialsPanel.defaultProps = {
	isOpen: false,
};

export default AccountCredentialsPanel;
