import DatePicker from './DatePicker';
import { createComponent, mapError } from '../utils';

function mapDatePickerProps(props) {
	const { dateFormat, variant, ...otherprops } = props;
	return {
		...otherprops,
		format: dateFormat,
		inputVariant: variant,
		onFocus: () => undefined,
	};
}

export default createComponent(DatePicker, ({ ...props }) => ({
	...mapDatePickerProps(mapError(props)),
}));
