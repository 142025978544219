import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ModalPopups } from '@enums';
import {
	tradePlatforms,
	userAccounts,
} from '@redux';
import {
	Modal,
	Button,
	A,
	InfoMessage,
} from '@components';
import { connect } from 'react-redux';
import limeLogo from './imgs/lime_trading_logo.png';

const styles = theme => ({
	title: {
		...theme.typography.style.display,
		color: theme.typography.color.primary,
	},
	body: {
		marginTop: '24px',
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	description: {
		marginBottom: '5px',
	},
	list: {
		margin: '0',
		listStyleType: 'none',
		lineHeight: '35px',
		padding: '0',
		'& li:': {
			'&:before': {
				content: '"—"',
				marginRight: '20px',
			},
		},
	},
	button: {
		marginTop: '32px',
		backgroundColor: '#e3edf5',
	},
	buttonDownload: {
		marginTop: '32px',
	},
	noteText: {
		marginTop: '32px',
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	spclogo: {
		position: 'relative',
		height: '48px',
		marginRight: 'auto',
		marginLeft: 'auto',
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		activatePlatform: (account, tradePlatform) =>
			dispatch(tradePlatforms.actions.activatePlatformRequest(account, tradePlatform)),
	},
});

const mapStateToProps = state => ({
	platforms: userAccounts.selectors.platforms(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	submitting: tradePlatforms.selectors.submitting(state),
});

const PLATFORM = 'Transaq';

class J2tLiteModal extends React.Component {
	activatePlatform = () => {
		this.props.actions.activatePlatform(
			this.props.currentTradeAccount,
			PLATFORM,
		);
	}

	render() {
		const {
			onClose,
			classes,
			submitting,
			platforms: {
				ActiveTradePlatform,
				PendingTradePlatform,
			},
			currentAccountData: { baseParams },
		} = this.props;

		return (
			<div>
				{baseParams && baseParams.HasOptions ?
					<Modal
						onClose={onClose}
						title="Has Options"
						className={`QA-${ModalPopups.J2T_LITE}`}
					>
						<InfoMessage
							onClick={onClose}
							text={
								<p>
									This trading platform doesn&quot;t support options trading.<br />
									In order to switch between platforms you have to close all the option positions in your portfolio.
								</p>
							}
						/>
					</Modal>
					:
					<Modal
						title={<img className={classes.spclogo} src={limeLogo} />}
						onClose={onClose}
						bgColor="#f9fcfd"
						textColor="#345464"
					>
						<div className={classes.title}>
						Lime Trader
						</div>
						<div className={classes.body}>
							<p className={classes.description}>
								Access to US Stock and equity options markets using a host of advanced, customizable, easy-to-use trading tools. The Lime Trader offers traders:
							</p>
							<ul className={classes.list}>
								<li>Clean, uncluttered, web-based interface</li>
								<li>Access to US stocks, Equity Options, ETFs &amp; ADRs</li>
								<li>Multi-Leg Option Orders &amp; Position Grouping</li>
								<li>Real time, streaming quotes</li>
								<li>Watchlists synced with mobile app</li>
								<li>Borrow Hard to Borrow stocks</li>
								<li>Trading from fully customizable charts</li>
								<li>Hot Keys for Order Entry</li>
							</ul>
						</div>
						{PendingTradePlatform && PendingTradePlatform === PLATFORM &&
						<div className={classes.button}>
							<Button
								color="secondary"
								variant="contained"
								fullWidth
								disabled
							>
							ACTIVATION REQUESTED
							</Button>
						</div>
						}
						{!PendingTradePlatform && ActiveTradePlatform !== PLATFORM &&
						<div className={classes.button}>
							<Button
								color="primary"
								variant="contained"
								fullWidth
								onClick={this.activatePlatform}
								disabled={submitting}
							>
								{submitting ?
									<CircularProgress
										style={{ color: '#1a96ff' }}
										size={18}
									/>
									: 'Activate'
								}
							</Button>
						</div>
						}
						{ActiveTradePlatform === PLATFORM &&
						<div className={classes.buttonDownload}>
							<A target="_blank" href="https://tx.just2trade.com/">
								<Button
									color="primary"
									variant="outlined"
									fullWidth
								>
								Access Lime Trader
								</Button>
							</A>
						</div>
						}

						<div className={classes.noteText}>
						Trading platform becomes available on the next day after activation.<br />
						Please note that only one trading platform may be used at a time.
						</div>
					</Modal>
				}
			</div>);
	}
}

J2tLiteModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(J2tLiteModal);
