import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
	ExpansionPanel,
	ProgressContainer,
	ShowMore,
} from '@components';
import { analytics } from '@redux';
import { EarningsTable, MostActiveTable } from './components';


const styles = theme => ({

	header: {
		...theme.typography.style.headline1,
		...theme.typography.color.primary,
		padding: '8px 0',
	},
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	table: {
		overflowX: 'auto',
		color: theme.typography.color.primary,
	},
});

const mapStateToProps = state => ({
	earningsToday: analytics.selectors.earningsToday(state),
	mostActive: analytics.selectors.mostActive(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		getEarnings: (page, pageSize) => dispatch(analytics.actions.getEarningsTodayRequest(page, pageSize)),
		getMostActive: (page, pageSize) => dispatch(analytics.actions.getMostActiveRequest(page, pageSize)),
	},
});

class TradingAssistance extends React.Component {
	constructor(props) {
		super(props);

		this.earningsPageSige = 5;
		this.mostActivePageSige = 5;

		this.state = {
			earningsCount: this.earningsPageSige,
			mostActiveCount: this.mostActivePageSige,
		};
	}

	componentDidMount() {
		const {
			earningsToday,
			mostActive,
		} = this.props;
		if (!earningsToday.earnings || earningsToday.earnings.length === 0) {
			this.props.actions.getEarnings();
		}
		if (!mostActive.items || mostActive.items.length === 0) {
			this.props.actions.getMostActive();
		}
	}

	earningsShowMore = () => {
		this.setState({
			earningsCount: this.state.earningsCount + this.earningsPageSige,
		});
	}

	earningsShowLess = () => {
		if (this.state.earningsCount > this.earningsPageSige) {
			this.setState({
				earningsCount: this.state.earningsCount - this.earningsPageSige,
			});
		}
	}

	mostActiveShowMore = () => {
		this.setState({
			mostActiveCount: this.state.mostActiveCount + this.mostActivePageSige,
		});
	}

	mostActiveShowLess = () => {
		if (this.state.mostActiveCount > this.mostActivePageSige) {
			this.setState({
				mostActiveCount: this.state.mostActiveCount - this.mostActivePageSige,
			});
		}
	}

	render() {
		const {
			classes,
			earningsToday,
			mostActive,
		} = this.props;

		return (
			<div className={classes.content} >
				<div className={classes.header}>
					Trading Assistance
				</div>
				<ExpansionPanel
					header="Earnings Today"
					id="trading-assistance-earnings"
				>
					<ProgressContainer submitting={earningsToday.loading} display="none">
						{earningsToday && earningsToday.earnings &&
							<div>
								<div className={classes.table}>
									<EarningsTable data={earningsToday.earnings.slice(0, this.state.earningsCount)} />
								</div>
								<ShowMore
									onClick={this.earningsShowMore}
									showMoreButton={this.state.earningsCount < earningsToday.earnings.length}
									onShowLessClick={this.earningsShowLess}
									showLessButton={this.state.earningsCount > this.earningsPageSige}
								/>
							</div>
						}
					</ProgressContainer>
				</ExpansionPanel>
				<ExpansionPanel
					header="Most Active"
					id="trading-assistance-mostactive"
				>
					<ProgressContainer submitting={mostActive.loading} display="none">
						{mostActive && mostActive.items &&
							<div>
								<div className={classes.table}>
									<MostActiveTable data={mostActive.items.slice(0, this.state.mostActiveCount)} />
								</div>
								<ShowMore
									onClick={this.mostActiveShowMore}
									showMoreButton={this.state.mostActiveCount < mostActive.items.length}
									onShowLessClick={this.mostActiveShowLess}
									showLessButton={this.state.mostActiveCount > this.mostActivePageSige}
								/>
							</div>
						}
					</ProgressContainer>
				</ExpansionPanel>
			</div>
		);
	}
}

TradingAssistance.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	earningsToday: PropTypes.object.isRequired,
	mostActive: PropTypes.object.isRequired,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(TradingAssistance);
