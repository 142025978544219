import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toUsaMoneyFormat, toUsaFormat } from '@global';
import { userAccounts, wallet } from '@redux';
import { formValueSelector } from 'redux-form';
import AccountPropsView from './AccountPropsView';


const mapStateToProps = (state, ownProps) => {
	const { cashToWithdrawValue, totalAccountValue, currencyCode, submitting } = formValueSelector(ownProps.form)(state, ownProps.name);
	return {
		tradeCode: ownProps.tradeCode || userAccounts.selectors.currentTradeAccount(state).tradeCode,
		loading: !!submitting,
		cashToWithdrawValue: (!submitting && cashToWithdrawValue) || null,
		totalAccountValue: (!submitting && totalAccountValue) || null,
		currencyCode: (!submitting && currencyCode) || null,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
		getWithdrawCash: (account, form, name) =>
			dispatch(wallet.actions.getWithdrawCash.request({ account, form, name })),
	},
});

const toMoneyFormat = (num, currency) =>
	((!currency || currency === 'USD') ? toUsaMoneyFormat(num) : `${toUsaFormat(num)} ${currency}`);

class BrokerAccountInfo extends React.Component {
	componentDidMount() {
		this.handleRefresh();
	}

	handleRefresh = () => {
		const { actions, account, form, name } = this.props;
		actions.getWithdrawCash(account, form, name );
	}

	render() {
		const {
			classes,
			loading,
			cashToWithdrawValue,
			totalAccountValue,
			currencyCode,
			theme,
			...props
		} = this.props;

		return (
			<AccountPropsView loading={loading} classes={classes} handleRefresh={this.handleRefresh}>
				<div>{`Total account value: ${toMoneyFormat(totalAccountValue, currencyCode)}`}</div>
				<div>{`Cash to withdraw: ${toMoneyFormat(cashToWithdrawValue, currencyCode)}`}</div>
			</AccountPropsView>
		);
	}
}

BrokerAccountInfo.propTypes = {
	classes: PropTypes.object,
	loading: PropTypes.bool.isRequired,
	account: PropTypes.string.isRequired,
	form: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	cashToWithdrawValue: PropTypes.number,
	totalAccountValue: PropTypes.number,
	currencyCode: PropTypes.string,
};
BrokerAccountInfo.defaultProps = {
	classes: undefined,
	cashToWithdrawValue: null,
	totalAccountValue: null,
	currencyCode: 'USD',
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
)(BrokerAccountInfo);
