import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { dashboard, userAccounts } from '@redux';
import { scrollTo } from '@global';

const styles = theme => ({
	root: {},
	listItemText: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
});

const mapStateToProps = state => ({
	tariffs: userAccounts.selectors.tariffs(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose())
	},
});

class ProfileQuickLinks extends React.Component {
	scrollTo = (elementId) => {
		scrollTo(elementId);
		this.props.actions.sidebarClose();
	}

	render() {
		const {
			classes,
			tariffs,
		} = this.props;
		return (
			<div>
				<List>
					<ListItem button onClick={() => this.scrollTo('dashboard-top')}>
						<ListItemText
							className={classes.listItemText}
							primary="Account Credentials"
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={() => this.scrollTo('profile-personal-information')}>
						<ListItemText
							className={classes.listItemText}
							primary="Personal Information"
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={() => this.scrollTo('profile-employment')}>
						<ListItemText
							className={classes.listItemText}
							primary="Employment"
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={() => this.scrollTo('profile-documents')}>
						<ListItemText
							className={classes.listItemText}
							primary="Documents"
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={() => this.scrollTo('profile-investment-profile')}>
						<ListItemText
							className={classes.listItemText}
							primary="Investment Profile"
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={() => this.scrollTo('profile-investment-experience')}>
						<ListItemText
							className={classes.listItemText}
							primary="Investment Experience"
							disableTypography
						/>
					</ListItem>
					{tariffs &&
						<ListItem button onClick={() => this.scrollTo('profile-commission-plan')}>
							<ListItemText
								className={classes.listItemText}
								primary="Commission Plan"
								disableTypography
							/>
						</ListItem>
					}
				</List>
			</div>
		);
	}
}

ProfileQuickLinks.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(ProfileQuickLinks);
