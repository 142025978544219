import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues, Field } from 'redux-form';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
	FormInput,
	FormRadioGroup,
	Radio,
} from '@components';

const useStyles = makeStyles(theme => createStyles({
	editValue: {},
	editable: {},
	preFieldLabel: {
		margin: '10px 24px 0px 0px',
		lineHeight: '21px',
		fontSize: '18px',
		color: '#345464',
	},
	withLeftMarginField: {
		marginLeft: '-24px',
		'& input': {
			background: theme.palette.color.primary.attestationBackground,
			borderRadius: '8px'
		}
	},
	noLeftMarginField: {
		marginLeft: '0px',
	},
}));
const extractPropsClasses = ({
	editValue, editable, preFieldLabel, withLeftMarginField, noLeftMarginField,
}) => ({
	editValue, editable, preFieldLabel, withLeftMarginField, noLeftMarginField,
});

const InstitutionalQuestion = ({
	classes: _classes,
	form,
}) => {
	const classes = useStyles({ classes: extractPropsClasses(_classes) });
	const values = useSelector(state => getFormValues(form)(state)) || {};
	return (
		<React.Fragment>
			<div className={classes.editable}>
				<div className={classes.editValue}>
					<p className={classes.preFieldLabel}>
						Were there any changes in Beneficial Owners since the previous attestation?
					</p>
					<div className={classes.noLeftMarginFiled}>
						<Field
							name="HasChangesInBenOwners"
							orientation="horizontal"
							component={FormRadioGroup}
							validationMessageMode="withErrorMessage"
						>
							<Radio value="Yes" label="Yes" />
							<Radio value="No" label="No" />
						</Field>
					</div>
					{values.HasChangesInBenOwners === 'Yes' &&
						<div className={classes.withLeftMarginField}>
							<Field
								name="ChangesInBenOwners"
								component={FormInput}
								placeholder="Please explain"
							/>
						</div>
					}
				</div>
			</div>
		</React.Fragment>
	);
};

InstitutionalQuestion.propTypes = {
	form: PropTypes.string.isRequired,
	classes: PropTypes.object,
};

InstitutionalQuestion.defaultProps = {
	classes: {},
};

export default InstitutionalQuestion;
