import React, { useState } from 'react';
import { change, formValueSelector, Field, ReduxFormContext } from 'redux-form';
import PropTypes from 'prop-types';
import { A, FormCheckbox } from '@components';
import { useDispatch, useSelector } from 'react-redux';


const AgreementField = ({
	name,
	href,
	docName,
	form,
	classes,
}) => {
	// текущее значение. Если задано  - значит считаем что и документ смотрели
	const currentVal = !!form && useSelector(state => formValueSelector(form)(state, name));
	const [docShown, setDocShown] = useState(currentVal);
	const dispatch = useDispatch();
	const handleDocClick = () => {
		setDocShown(true);
		dispatch(change(form, name, true));
	};

	return (
		<Field
			component={FormCheckbox}
			name={name}
			label={
				<p>
						I confirm that I read and agree with terms and conditions of the &nbsp;
					<A
						target="_blank"
						href={href}
						onClick={handleDocClick}
					>
						{docName}
					</A>


				</p>
			}
			classes={classes}
			disabled={!docShown}
		/>
	);
};

AgreementField.propTypes = {
	name: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	docName: PropTypes.string.isRequired,
	form: PropTypes.string.isRequired,
};
const AgreementFormField = props => (
	<ReduxFormContext.Consumer>
		{value => (<AgreementField form={value.form} {...props} />)}
	</ReduxFormContext.Consumer>
);
export default AgreementFormField;
