import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SafeComponent from 'react-safe-component';
import compose from 'recompose/compose';
import {
	ExpansionPanel,
	ProgressContainer,
	ShowMore,
} from '@components';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { app, ipo, userAccounts } from '@redux';
import PropTypes from 'prop-types';
import { IpoCalendarItem, IpoCalendarItemMobile } from './components';

const styles = theme => ({
	item: {
		borderBottom: '1px solid #deeaef',
		padding: '16px 0',
	},
	wider: {
		overflowX: 'auto',
	},
	panel: {
		minWidth: '700px',
	},
	mobyleGridItem: {
		width: '100%',
		padding: '5px 1px',
	},
	['@media (min-width: 460px)']: {
		mobyleGridItem: {
			width: '50%',
			'&:nth-child(2n)': {
				paddingRight: '1px',
				paddingLeft: '5px',
			},
			'&:nth-child(2n+1)': {
				paddingLeft: '1px',
				paddingRight: '5px',
			},
		},
	},
	headContent: {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
		width: '100%',
		padding: '10px 0',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		'& > span': {
			marginRight: '40px',
			whiteSpace: 'nowrap',
		},
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	offerings: ipo.selectors.offerings(state),
	resolution: app.selectors.resolution(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		getOfferings: payload => dispatch(ipo.actions.getOfferings.request(payload)),
	},
});

class IpoCalendar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			holdersCount: 10,
		};
	}

	componentDidMount() {
		const {
			actions,
			currentTradeAccount,
			offerings,
		} = this.props;

		if (!offerings.succeed && currentTradeAccount.tradeCode) {
			actions.getOfferings({
				TradeCode: currentTradeAccount.tradeCode,
				ClearerFirm: currentTradeAccount.clearerFirm,
				page: 1,
			});
		}
	}

	showMore = () => {
		this.setState({
			holdersCount: this.state.holdersCount + 10,
		});
	}

	render() {
		const {
			classes,
			offerings: {
				submitting,
				items,
			},
			resolution,
		} = this.props;

		const viewedItems = items && items.slice(0, this.state.holdersCount);

		return (
			<ExpansionPanel
				header={<span>All Offerings</span>}
				id="ipo-calendar"
			>
				<div className={classes.wider}>

					<ProgressContainer
						display="left"
						id="ipo-calendar-container"
						submitting={submitting && items && items.length === 0}
					>

						{resolution == app.CONFIG.RESOLUTIONS_ENUM.xs ? (
							<Grid container direction="row" justify="flex-start" alignItems="flex-start">
								{viewedItems.map((item, index) => (
									<Grid item md={12} className={classes.mobyleGridItem}>
										<div key={index}>
											{item &&
											<div>
												<IpoCalendarItemMobile item={item} />
											</div>
											}
										</div>
									</Grid>
								))}
							</Grid>)
							: (
								<React.Fragment>
									{viewedItems.map((item, index) => (

										<div key={index}>
											{item &&
											<div className={classes.item}>
												<IpoCalendarItem item={item} />
											</div>
											}
										</div>

									))}
								</React.Fragment>
							)}

						<ShowMore
							onClick={this.showMore}
							showMoreButton={items.length > this.state.holdersCount}
						/>
					</ProgressContainer>

				</div>
			</ExpansionPanel>
		);
	}
}

IpoCalendar.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	resolution: PropTypes.number.isRequired,
	offerings: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	SafeComponent,
)(IpoCalendar);
