import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { userAccounts } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	getFormValues,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import {
	FormSelect,
	FormInput,
	Divider,
	Button,
	WithdrawAmount,
	R,
} from '@components';
import { RequireConfirmationCodeItem } from '@shared';
import { template } from '@templates';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
		'& div > div': {
			background: theme.palette.color.primary.attestationBackground,
		},
	},
	item: {
		marginTop: '30px',
		paddingLeft: '74px',
	},
	divider: {
		marginTop: '30px',
	},
	bankAccountContainer: {
		marginTop: '-15px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '36px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
});

function MapForm(ownProps) {
	const accountTemplate = (ownProps && ownProps.toBankAccount && ownProps.toBankAccount.Template)
		? ownProps.toBankAccount.Template
		: null;

	if (!accountTemplate) return {};

	return {
		...accountTemplate,
		Name: accountTemplate.FirstName,
		Surname: accountTemplate.LastName,
		BankName: accountTemplate.ReceivingBankName,
		BankRoutingNumber: accountTemplate.ReceivingBankRoutingNumber,
		BankSwiftCode: accountTemplate.ReceivingBankSwiftCode,
		BankAccountName: accountTemplate.ReceivingBankAccountName,
		BankAddress: accountTemplate.ReceivingBankAddress,
		BankAccountNumber: accountTemplate.ReceivingBankAccountNumber,
		UseIntermediaryBank: !!accountTemplate.IntermediaryBankName, // toBoolean
		Additional: accountTemplate.AdditionalInformation,
	};
}

const CY_BANK_WITHDRAWAL_FORM_ID = 'CYWithdrawalAmountForm';
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const formValues = getFormValues(ownProps.form)(state);
	const prevStepValues = ownProps.toBankAccount.Template ? {} : {
		wireLink: {
			...formValues.wireLink,
			UseConfirmationBySms: true,
		},
	};

	return {
		initialValues: {
			...prevStepValues,
			...MapForm(ownProps),
			wireWithdrawal: {
				...MapForm(ownProps),
				TradeCode: currentTradeAccount.tradeCode,
				ClearingFirm: currentTradeAccount.clearerFirm,
				Summ: ownProps.amount,
			},
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
		currentAccountData: userAccounts.selectors.currentAccountData(state),
		currentTradeAccount,
	};
};


const mapDispatchToProps = dispatch => ({
	actions: {
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
	},
});


class CYWithdrawalAmountForm extends React.Component {
	static getCashToWithdraw(props) {
		const { currentAccountData: { baseParams, submitting } } = props;
		return !submitting && baseParams && baseParams.CashToWithdrawValue;
	}

	static validate(formvalues, props) {
		const values = formvalues.wireWithdrawal;
		const errors = {};
		const cashToWithdraw = CYWithdrawalAmountForm.getCashToWithdraw(props);

		if (!values) {
			return errors;
		}

		if (!values.Summ || values.Summ <= 0) {
			errors.Summ = 'required';
		} else if (typeof cashToWithdraw === 'number') {
			if (cashToWithdraw < values.Summ) {
				errors.Summ = 'Available amount for withdrawal is less than requested';
			}
		}

		if (!values.ConfirmationCode) {
			errors.ConfirmationCode = 'required';
		}

		return { wireWithdrawal: errors };
	}


	render() {
		const {
			classes,
			fromUserAccount,
			handleSubmit,
			selectedBankAccount,
			submitSucceeded,
			amount,
			theme,
			currentTradeAccount,
			currentAccountData: {
				submitting,
			},
			...props
		} = this.props;

		const commissionText = '';

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field component={FormSelect} name="wireWithdrawal.TradeCode" label="Account" disabled>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentTradeAccount.tradeCode}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<WithdrawAmount
					name="wireWithdrawal.Summ"
					loading={submitting || false}
					availableAmount={CYWithdrawalAmountForm.getCashToWithdraw(this.props)}
					disabled={amount != null}
					additionalText={commissionText}
					onClick={() => { this.props.actions.refreshAccountPortfolio(currentTradeAccount.tradeCode); }}
				/>

				<div className={classes.divider}>
					<Divider />
				</div>
				<p className={classes.warning}>
					<ul>
						<li>
							<R
								id="common_orders_important_inout_copy"
								defaultValue="When funding a trading account with a bank card,
									withdrawal of funds is available only five working days after the funding date."
							/>
						</li>
						<li>
							<R
								id="common_orders_important_time_copy"
								defaulValue="Bank transfers are processed within 1 working day
									however it usually takes 3-5 working days for the funds to be credited to your Bank account."
							/>
						</li>
						<li>
							<R
								id="common_just2Trade_online_ltd_text"
								defaultValue="Just2Trade Online Ltd. does not charge for Bank Withdrawals;
									however any fees will be deducted directly by the Banks from the amount requested to be transferred."
							/>
						</li>
						<li>
							<R
								id="common_orders_important_settlement_date_copy"
								defaultValue="Pay attention to the fact that on US and European exchanges
									there is a concept of “settlement date” which means the date on which
									the trade settles and ultimate transfer of cash and securities occurs.
									Therefore withdrawal of available balance can be only made after
									the settlement date comes, i.e. on 3rd (Xetra) or 4th (USA) working day."
							/>
						</li>
						<li>
							<R
								id="common_orders_important_scan_copy"
								defaultValue="In case of a partial withdrawal at least 20 USD / 30 EUR / 1500 RUB
									should remain on your account."
							/>
						</li>
						<li>
							<R
								id="common_important_check_moneyout_availability"
								defaultValue="Please specify in your bank whether it is possible to deposit funds
									into your bank account."
							/>
						</li>
					</ul>
				</p>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="wireWithdrawal.ReasonComment"
						placeholder={<R id="common_reason_for_withdrawal" defaultValue="Reason for withdrawal" />}
						label="Reason"
					/>
				</div>
				<RequireConfirmationCodeItem
					customClasses={classes}
					name="wireWithdrawal.ConfirmationCode"
					buttonTitle="Withdraw"
					reason="MoneyOutApply"
					disabled={props.invalid || props.submitting}
				>

					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.typography.color.white }}
								size={18}
							/>
							: <R id="common_confirm_order" defaultValue="Confirm" /> // "Withdraw"
						}
					</Button>

				</RequireConfirmationCodeItem>

			</form>
		);
	}
}

CYWithdrawalAmountForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	toBankAccount: PropTypes.object,
	selectedBankAccount: PropTypes.object,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CY_BANK_WITHDRAWAL_FORM_ID,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		validate: CYWithdrawalAmountForm.validate,
	}),
)(CYWithdrawalAmountForm);
