import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { R, ProgressContainer, SvgIcon } from '@components';
import { constants, env } from '@config';
import { ModalPopups } from '@enums';
import { translate } from '@global';
import { app, linkedAccounts as linkedAccountsRedux, userAccounts } from '@redux';
import { usePaymentGateWidgetHook } from '@hooks';
import {
	BankAccountItem,
	BankAccountItemCryptoWalletCY,
	BankAccountItemMalayBankCY,
} from './components';

const useStyles = makeStyles(theme => ({
	root: {
		...theme.typography.style.button,
		color: theme.typography.color.link,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		marginTop: '20px',
		marginBottom: '20px',
	},
	text: {
		paddingLeft: '14px',
	},
	listItemContainer: {
		padding: '0 16px',
		margin: '0px -16px',
	},
	item: {
		borderBottom: '1px solid #deeaef',
		padding: '10px 0',
	},

}));


export const CYCashManagement = ({}) => {

	const theme = useTheme();
	const classes = useStyles();
	const [initPaymentGateWidgetId, loading, success] = usePaymentGateWidgetHook();

	const language = useSelector(app.selectors.language);
	const userInfo = useSelector(userAccounts.selectors.userInfo);
	let hasBlockers = userInfo.Debts && userInfo.Debts.some(it => it.IsBlockingMoneyOut);

	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);
	const accounts = useSelector(userAccounts.selectors.accounts);
	const accountInfo = useSelector(userAccounts.selectors.accountInfo);
	const accountStatus = useSelector(userAccounts.selectors.currentAccountStatus);
	const linkedAccounts = useSelector(userAccounts.selectors.linkedAccounts);
	const linkedAccountsSubmitting = useSelector(userAccounts.selectors.linkedAccountsSubmitting);
	const hasMaxTemplatesCount = useSelector(userAccounts.selectors.hasMaxTemplatesCount);
	const dispatch = useDispatch();

	const showDepositFundsModal = (bankAccount) => {
		dispatch(app.actions.showBaseModal(ModalPopups.DEPOSIT_FUNDS_WIRE, { bankAccount }));
	};

	const showWithdrawFundsModal = (bankAccount) => {
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};

		dispatch(app.actions.showBaseModal(ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER, { userAccount, bankAccount }));
	};

	const showTransferBetweenAccountsModal = (fakeAccount) => {
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};

		dispatch(app.actions.showBaseModal(ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS, { userAccount, bankAccount: fakeAccount }));
	};

	const unlinkAccount = (bankAccount) => {
		const text = `Please confirm if you want to remove template with ${bankAccount.BankName}`;
		dispatch(app.actions.showConfirmModal({
			text,
			action: onEndRequest => dispatch(linkedAccountsRedux.actions.wireUnlinkBankAccount.request({
				...currentTradeAccount,
				bankCode: bankAccount.Code,
				onEndRequest,
			})),
		}));
	};

	return (
		<div>
			{[constants.LIME_INT, constants.LIME_LME].some(x => x === env.firm) &&
				<div
					id={initPaymentGateWidgetId}
					className={classes.root}
					style={{ visibility: success && !loading ? 'visible' : 'hidden' }}
				>
					<SvgIcon icon="Deposit" />
					<div className={classes.text}>
						<R id="common_funds_deposit_title" defaultValue="Fund your account" />
					</div>
				</div>

			}

			{env.WEBCAB_5786_allow_withdrawals && !hasBlockers &&
				<ProgressContainer submitting={linkedAccountsSubmitting} disableCheckChildren display="left">
					{accountStatus && !accountStatus.IsDisabled && !accountStatus.IsClosed &&
						<div>
							{/* templates for withdrawal */}
							{linkedAccounts.map((bankAccount, index) => (
								<div className={classes.listItemContainer} key={index}>
									<div className={classes.item}>
										<BankAccountItem
											isLimeInternationalStyle
											bankAccount={bankAccount}
											hasDepositButton={() => (env.firm === constants.LIME_NSR)}
											hasWithdrawalButton={() => accountStatus.CanWithdrawal}
											onDepositFundsModal={showDepositFundsModal}
											onWithdrawFundsModal={showWithdrawFundsModal}
											onUnlinkAccount={unlinkAccount}
										/>
									</div>
								</div>
							))}
							{!hasMaxTemplatesCount && accountStatus.CanWireLink &&
								constants.LIME_LME !== env.firm &&
								<div className={classes.listItemContainer} key="defaultWireLink">
									<div className={classes.item}>
										<BankAccountItem
											isLimeInternationalStyle
											bankAccount={{ Type: 'BankTransfer', displayNumber: translate('common_account_not_linked', 'Bank\'s Account is not linked') }}
											hasDepositButton={() => (env.firm === constants.LIME_NSR)}
											hasWithdrawalButton={() => accountStatus.CanWithdrawal}
											onDepositFundsModal={showDepositFundsModal}
											onWithdrawFundsModal={showWithdrawFundsModal}
											onUnlinkAccount={unlinkAccount}
										/>
									</div>
								</div>
							}
							{accountStatus.CanWithdrawal &&
								constants.LIME_LME === env.firm &&
								<div className={classes.listItemContainer}>
									<div className={classes.item}>
										<BankAccountItemCryptoWalletCY />
									</div>
								</div>
							}
							{accountStatus.CanWithdrawal &&
								constants.LIME_LME === env.firm &&
								(language === 'ms' || language === 'en') &&
								<div className={classes.listItemContainer}>
									<div className={classes.item}>
										<BankAccountItemMalayBankCY />
									</div>
								</div>
							}
						</div>
					}
				</ProgressContainer>
			}


			{accounts && accountStatus.CanTransferFrom && 
				accounts.filter(it => it.TradeCode !== currentTradeAccount.tradeCode && it.CanTransferTo).length > 0 &&

				<div className={classes.listItemContainer} key="TransferBetweenAccounts">
					<div className={classes.item}>
						<BankAccountItem
							isLimeInternationalStyle
							bankAccount={{ 
								Type: 'TransferBetweenAccounts',
								displayNumber: translate('common_transfer_between_accounts', 'Transfer between accounts'),
								LogoFileName: 'accounts-transfer.png',
								LogoColors: {
									primary: 'white',
									border: `1px dashed ${theme.palette.color.secondary.gray}`
								},
							}}
							hasDepositButton={() => false}
							hasWithdrawalButton={() => accountStatus.CanTransferFrom}
							onWithdrawFundsModal={showTransferBetweenAccountsModal}
						/>
					</div>
				</div>
			}

		</div>
	);
}