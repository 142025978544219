import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	profile,
	signature,
} from '@redux';
import compose from 'recompose/compose';
import {
	ChangeLink,
	Input,
	InputPhoneIconNumber,
} from '@components';
import {
	InputEditableItem,
	CustomEditableItem,
} from '@shared';

function valueOrEmpty(val) {
	if (val) return val;
	return '';
}

const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		showChangeAddressModal: () => {
			const changeAddressAction = profile.actions.showChangeAddressModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAddressAction));
		},
		showChangeEmailModal: () => {
			const changeEmailAction = profile.actions.showChangeEmailModal({ forceConfirmationEmail: true });
			dispatch(signature.actions.collectSignaturesProcessStart(changeEmailAction));
		},
		showChangePhoneModal: () => {
			const changePhoneAction = profile.actions.showChangePhoneModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changePhoneAction));
		},
		showChangeDependentsModal: () => {
			const changeAction = profile.actions.showChangeDependentsModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showChangeMaritalStatusModal: () => {
			const changeAction = profile.actions.showChangeMaritalStatusModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},

	},
});


class PersonalInformation extends React.Component {
	render() {
		const {
			classes,
			actions,
			profile: {
				PersonalInformation,
				PendingOrders,
			},
		} = this.props;

		return (
			<React.Fragment>
				<div className={classes.blockTitle}>Personal Information</div>
				<Input placeholder="Name" showLabel value={valueOrEmpty(PersonalInformation.Name)} disabled />
				<CustomEditableItem
					valueControl={
						<Input
							multiline
							style={{ maxWidth: '80%' }}
							placeholder="Address"
							showLabel
							value={valueOrEmpty(PersonalInformation.FullAddress)}
							disabled
						/>
					}
					editControl={
						<ChangeLink onClick={actions.showChangeAddressModal} havePendingOrder={PendingOrders.Address} />
					}
				/>
				<div className={classes.editable}>
					<div className={classes.editValue}>
						<Input placeholder="Email" showLabel value={valueOrEmpty(PersonalInformation.Email)} disabled />
					</div>
					<div className={classes.editItem}>
						<ChangeLink onClick={actions.showChangeEmailModal} havePendingOrder={PendingOrders.Email} />
					</div>
				</div>
				<CustomEditableItem
					classes={{ editValue: classes.editValuePhone }}
					valueControl={
						<InputPhoneIconNumber
							placeholder="Phone"
							showLabel
							onPhoneChange={() => {}}
							onChange={() => {}}
							value={valueOrEmpty(PersonalInformation.Phone)}
							disabled
							hideNumberMode
						/>
					}
					editControl={
						<ChangeLink onClick={actions.showChangePhoneModal} havePendingOrder={PendingOrders.Phone} />
					}
				/>
				<InputEditableItem
					title="Marital Status"
					value={PersonalInformation.MaritalStatus}
					editControl={
						<ChangeLink onClick={actions.showChangeMaritalStatusModal} havePendingOrder={PendingOrders.MaritalStatus} />
					}
				/>
				<InputEditableItem
					title="Dependents"
					value={PersonalInformation.Dependents}
					editControl={
						<ChangeLink onClick={actions.showChangeDependentsModal} havePendingOrder={PendingOrders.Dependents} />
					}
				/>
			</React.Fragment>
		);
	}
}

PersonalInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PersonalInformation);

