import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { env } from '@config';
import { userAccounts, profile } from '@redux';
import { toUsaDateFormat } from '@global';
import PropTypes from 'prop-types';
import GenericTextAlert from './GenericTextAlert';

const mapStateToProps = (state) => {
	const userInfo = userAccounts.selectors.userInfo(state);
	const loading = userAccounts.selectors.loading(state);
	const needAlert = !loading && userInfo && userInfo.Guid && !userInfo.TwoFactorAutenticationType;
	return {
		needAlert: !!needAlert,
		userInfo,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		showModal: () => dispatch(profile.actions.showChangeTwoFactorAuthorizationModal()),
	},
});


class TwoFactorAlert extends React.Component {
	render() {
		const {
			needAlert,
			actions,
		} = this.props;
		return (
			<React.Fragment>
				{needAlert && env.WEBCAB_4523_dateRequireSecondFactor && env.WEBCAB_4523_dateRequireSecondFactor >= new Date() &&
					<GenericTextAlert
						text={`After ${toUsaDateFormat(env.WEBCAB_4523_dateRequireSecondFactor)}, two-factor authentication will be enabled for all accounts. It is recommended to enable it before this time on your own.`}
						buttonText="Enable"
						onClick={actions.showModal}
					/>
				}
			</React.Fragment>
		);
	}
}

TwoFactorAlert.propTypes = {
	actions: PropTypes.object.isRequired,
	needAlert: PropTypes.bool.isRequired,
	userInfo: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TwoFactorAlert);
