import { put, select, fork, takeEvery } from 'redux-saga/effects';
import {
	achWithdraw,
	brokerWithdraw,
	iraWithdraw,
	transferBetweenAccounts,
	wireWithdraw,
	wireLinking,
	closeAccountValidation,
} from '@api';
import { SubmissionError } from 'redux-form';
import { BaseGetSagaHandler, callApi, linkedAccounts, userAccounts, snackbar, acatDeposit } from '@redux';
import { push } from 'connected-react-router';
import { ModalPopups, CloseAccountFlow } from '@enums';
import { addHashToUrl, removeHashFromUrl, changeHashInUrl } from '@global';
import actions from './actions';
import { MapWireLinkPayload } from '../linkedAccounts/saga';

function* reloadLinkedAccounts(tradeCode) {
	yield put(linkedAccounts.actions.getLinkedAccounts.request({ tradeCode }));
}

// function* reloadTransactionHistory(tradeCode, clearerFirm) {
// 	const cashActivities = yield select(userAccounts.selectors.cashActivities);
// 	const {
// 		StartDate,
// 		EndDate,
// 	} = cashActivities.pagination;

// 	// reload first page
// 	yield put(userAccounts.actions.getTransactionsHistory.request({
// 		TradeCode: tradeCode,
// 		ClearerFirm: clearerFirm,
// 		StartDate,
// 		EndDate,
// 		Page: 1,
// 		PageSize: 10,
// 	}));
// }

function* withdrawFormAchSubmit({ payload }) {
	try {
		const withdrawResponse = yield callApi(achWithdraw, payload);
		if (!withdrawResponse.data.Success) {
			yield put(actions.withdrawAchFundsForm.failure(new SubmissionError({
				_error: withdrawResponse.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: withdrawResponse.data.Errors[0].Message }));
			return;
		}
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearerFirm);
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.withdrawAchFundsForm.failure(new SubmissionError({
			_error: 'NETWORK ERROR',
		})));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
		return;
	}

	// Если это флоу закрытия аккаунта, то дополнительные серверные валидации не нужны
	if (payload.CloseAccountFlow === CloseAccountFlow.PROFILE) {
		yield put(actions.withdrawAchFundsForm.success());
		return;
	}

	const { baseParams: { CashCurrentValue } } = yield select(userAccounts.selectors.currentAccountData);

	if (CashCurrentValue !== parseFloat(payload.Amount)) {
		yield put(actions.withdrawAchFundsForm.success());
		return;
	}

	try	{
		const validationResponse = yield callApi(closeAccountValidation, {
			tradeCode: payload.TradeCode,
			clearerFirm: payload.ClearerFirm,
		});
		if (!validationResponse.data.Success) {
			yield put(acatDeposit.actions.closeAccountValidation.failure());
			yield put(actions.withdrawAchFundsForm.success());
			return;
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(acatDeposit.actions.closeAccountValidation.failure());
		yield put(actions.withdrawAchFundsForm.success());
		return;
	}

	yield put(acatDeposit.actions.closeAccountValidation.success());
	yield put(actions.withdrawAchFundsForm.success());
}


function* withdrawFormBrokerSubmit({ payload }) {
	const createTransferModel = {
		TradeCode: payload.TradeCode,
		ClearingFirm: payload.ClearingFirm,
		Amount: payload.Amount,
		Direction: payload.Direction,
		BrokerAccount: payload.bankAccountNumber,
	};
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: brokerWithdraw,
		errorText: 'BROKER Withdraw Funds',
		handler: actions.withdrawFundsBrokerForm,
	}, { payload: createTransferModel });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearingFirm);
	}
}

function* withdrawFormIraSubmit({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: iraWithdraw,
		errorText: 'Ira Withdraw Funds',
		handler: actions.withdrawFundsIraForm,
	}, { payload });
}


function* transferBetweenAccountsSubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: transferBetweenAccounts,
		errorText: 'Transfer between accounts',
		handler: actions.transferBetweenAccountsSubmitForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCodeFrom, payload.ClearingFirmFrom);
	}
}


function MapWireWithdrawalPayload(payload) {
	return {
		...payload.wireWithdrawal,
		Name: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.Name : 'Name',
		Surname: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.Surname : 'Surname',
		Address: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.Address : 'Address',
		ZipCode: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.ZipCode : 'ZipCode',
		Phone: payload.wireWithdrawal.ClearingFirm === 'cor' ? payload.wireWithdrawal.Phone : '123456789',
		BankRoutingNumber: payload.wireWithdrawal.WireType === 'International' ? '123456789' : payload.wireWithdrawal.BankRoutingNumber,
		BankSwiftCode: payload.wireWithdrawal.WireType === 'Domestic' ? '123456789' : payload.wireWithdrawal.BankSwiftCode,
		UseIntermediaryBank: payload.wireWithdrawal.UseIntermediaryBank ? 'FME' : false,
		IntermediaryBankRoutingNumber: (payload.wireWithdrawal.UseIntermediaryBank && payload.wireWithdrawal.WireType === 'International')
			? '123456789'
			: payload.wireWithdrawal.IntermediaryBankRoutingNumber,
		IntermediaryBankSwiftCode: (payload.wireWithdrawal.UseIntermediaryBank && payload.wireWithdrawal.WireType === 'Domestic')
			? '123456789'
			: payload.wireWithdrawal.IntermediaryBankSwiftCode,
		ConfirmationCode: payload.wireWithdrawal.ConfirmationCode,
		Summ: payload.wireWithdrawal.Summ,
	};
}

function* withdrawFormWireSubmit({ payload }) {
	try {
		const response = yield callApi(wireWithdraw, MapWireWithdrawalPayload(payload));
		if (!response.data.Success) {
			yield put(actions.withdrawFundsWireFormFailure(new SubmissionError({
				_error: response.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: response.data.Errors[0].Message }));
			return;
		}
		yield* reloadLinkedAccounts(payload.wireWithdrawal.TradeCode);
		yield* userAccounts.saga.reloadTransactionHistory(payload.wireWithdrawal.TradeCode, payload.wireWithdrawal.ClearingFirm);
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.withdrawFundsWireFormFailure(new SubmissionError({
			_error: 'NETWORK ERROR',
		})));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
		return;
	}

	const { baseParams: { CashCurrentValue } } = yield select(userAccounts.selectors.currentAccountData);

	// Если это флоу закрытия аккаунта, то дополнительные серверные валидации не нужны
	if (payload.CloseAccountFlow === CloseAccountFlow.PROFILE) {
		yield put(actions.withdrawFundsWireFormSuccess());
		return;
	}

	if (CashCurrentValue !== parseFloat(payload.wireWithdrawal.Amount)) {
		yield put(actions.withdrawFundsWireFormSuccess());
		return;
	}

	try	{
		const validationResponse = yield callApi(closeAccountValidation, {
			tradeCode: payload.wireWithdrawal.TradeCode,
			clearerFirm: payload.wireWithdrawal.ClearingFirm,
		});
		if (!validationResponse.data.Success) {
			yield put(acatDeposit.actions.closeAccountValidation.failure());
			yield put(actions.withdrawFundsWireFormSuccess());
			return;
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(acatDeposit.actions.closeAccountValidation.failure());
		yield put(actions.withdrawFundsWireFormSuccess());
		return;
	}

	yield put(acatDeposit.actions.closeAccountValidation.success());
	yield put(actions.withdrawFundsWireFormSuccess());
}

function* linkAndWithdrawFundsWireFormSubmit({ payload }) {
	try {
		const wireLinkinkPayload = MapWireLinkPayload(payload);
		const wireWithdrawalPayload = {
			...MapWireWithdrawalPayload(payload),
			WireType: wireLinkinkPayload.WireType,
			BankAccountName: wireLinkinkPayload.BankAccountName,
			BankAccountNumber: wireLinkinkPayload.BankAccountNumber,
			BankAddress: wireLinkinkPayload.BankAddress,
			BankName: wireLinkinkPayload.BankName,
			BankRoutingNumber: wireLinkinkPayload.BankRoutingNumber,
			BankSwiftCode: wireLinkinkPayload.BankSwiftCode,
		};
		const request = yield callApi(wireLinking, {
			...wireLinkinkPayload,
			WireWithdrawal: wireWithdrawalPayload,
			ConfirmationCode: wireWithdrawalPayload.ConfirmationCode,
			Summ: wireWithdrawalPayload.Summ,
		});

		if (!request.data.Success) {
			yield put(actions.linkAndWithdrawFundsWireFormFailure(new SubmissionError({
				_error: request.data.Errors[0].Message,
			})));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield* reloadLinkedAccounts(payload.wireLink.TradeCode);
			yield* userAccounts.saga.reloadTransactionHistory(payload.wireLink.TradeCode, payload.wireLink.ClearingFirm);
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}

	// Если это флоу закрытия аккаунта, то дополнительные серверные валидации не нужны
	if (payload.CloseAccountFlow === CloseAccountFlow.PROFILE) {
		yield put(actions.linkAndWithdrawFundsWireFormSuccess());
		return;
	}

	const { baseParams: { CashCurrentValue } } = yield select(userAccounts.selectors.currentAccountData);

	if (CashCurrentValue !== parseFloat(payload.wireWithdrawal.Amount)) {
		yield put(actions.linkAndWithdrawFundsWireFormSuccess());
		return;
	}

	try	{
		const validationResponse = yield callApi(closeAccountValidation, {
			tradeCode: payload.wireLink.TradeCode,
			clearerFirm: payload.wireLink.ClearingFirm,
		});

		if (!validationResponse.data.Success) {
			yield put(acatDeposit.actions.closeAccountValidation.failure());
			yield put(actions.linkAndWithdrawFundsWireFormSuccess());
			return;
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(acatDeposit.actions.closeAccountValidation.failure());
		yield put(actions.linkAndWithdrawFundsWireFormSuccess());
		return;
	}

	yield put(acatDeposit.actions.closeAccountValidation.success());
	yield put(actions.linkAndWithdrawFundsWireFormSuccess());
}

function* showWithdrawFundsAchModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.WITHDRAW_FUNDS_ACH)));
}

function* hideWithdrawFundsAchModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.WITHDRAW_FUNDS_ACH)));
}

function* showWithdrawFundsWireModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.WITHDRAW_FUNDS_WIRE)));
}

function* hideWithdrawFundsWireModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.WITHDRAW_FUNDS_WIRE)));
}
function* showWithdrawFundsBrokerModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.WITHDRAW_FUNDS_BROKER)));
}

function* hideWithdrawFundsBrokerModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.WITHDRAW_FUNDS_BROKER)));
}

function* showWithdrawFundsIraModal() {
	const location = yield select(state => state.router.location);
	yield put(push(addHashToUrl(location, ModalPopups.WITHDRAW_FUNDS_IRA)));
}
function* hideWithdrawFundsIraModal() {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, ModalPopups.WITHDRAW_FUNDS_IRA)));
}

// Watchers
function* watchWithdrawFormAchSubmit() {
	yield takeEvery(actions.withdrawAchFundsForm.REQUEST, withdrawFormAchSubmit);
}
function* watchWithdrawFormBrokerSubmit() {
	yield takeEvery(actions.withdrawFundsBrokerForm.REQUEST, withdrawFormBrokerSubmit);
}
function* watchWithdrawFormIraSubmit() {
	yield takeEvery(actions.withdrawFundsIraForm.REQUEST, withdrawFormIraSubmit);
}
function* watchTransferBetweenAccountsSubmit() {
	yield takeEvery(actions.transferBetweenAccountsSubmitForm.REQUEST, transferBetweenAccountsSubmitSaga);
}
function* watchLinkAndWithdrawFundsWireFormSubmit() {
	yield takeEvery(actions.linkAndWithdrawFundsWireForm.REQUEST, linkAndWithdrawFundsWireFormSubmit);
}
function* watchWithdrawFormWireSubmit() {
	yield takeEvery(actions.WITHDRAW_FUNDS_WIRE_FORM_SUBMIT_REQUEST, withdrawFormWireSubmit);
}
function* watchShowWithdrawFundsAchModal() {
	yield takeEvery(actions.SHOW_WITHDRAW_FUNDS_ACH_MODAL, showWithdrawFundsAchModal);
}
function* watchHideWithdrawFundsAchModal() {
	yield takeEvery(actions.HIDE_WITHDRAW_FUNDS_ACH_MODAL, hideWithdrawFundsAchModal);
}
function* watchShowWithdrawFundsWireModal() {
	yield takeEvery(actions.SHOW_WITHDRAW_FUNDS_WIRE_MODAL, showWithdrawFundsWireModal);
}
function* watchHideWithdrawFundsWireModal() {
	yield takeEvery(actions.HIDE_WITHDRAW_FUNDS_WIRE_MODAL, hideWithdrawFundsWireModal);
}
function* watchShowWithdrawFundsBrokerModal() {
	yield takeEvery(actions.SHOW_WITHDRAW_FUNDS_BROKER_MODAL, showWithdrawFundsBrokerModal);
}
function* watchHideWithdrawFundsBrokerModal() {
	yield takeEvery(actions.HIDE_WITHDRAW_FUNDS_BROKER_MODAL, hideWithdrawFundsBrokerModal);
}
function* watchShowWithdrawFundsIraModal() {
	yield takeEvery(actions.SHOW_WITHDRAW_FUNDS_IRA_MODAL, showWithdrawFundsIraModal);
}
function* watchHideWithdrawFundsIraModal() {
	yield takeEvery(actions.HIDE_WITHDRAW_FUNDS_IRA_MODAL, hideWithdrawFundsIraModal);
}

// Root saga
const rootSaga = [
	fork(watchWithdrawFormAchSubmit),
	fork(watchWithdrawFormBrokerSubmit),
	fork(watchLinkAndWithdrawFundsWireFormSubmit),
	fork(watchWithdrawFormWireSubmit),
	fork(watchShowWithdrawFundsAchModal),
	fork(watchHideWithdrawFundsAchModal),
	fork(watchShowWithdrawFundsWireModal),
	fork(watchHideWithdrawFundsWireModal),
	fork(watchShowWithdrawFundsBrokerModal),
	fork(watchHideWithdrawFundsBrokerModal),
	fork(watchShowWithdrawFundsIraModal),
	fork(watchHideWithdrawFundsIraModal),
	fork(watchWithdrawFormIraSubmit),
	fork(watchTransferBetweenAccountsSubmit),
];

export default {
	rootSaga,
};
