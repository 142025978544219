import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SafeComponent from 'react-safe-component';
import compose from 'recompose/compose';
import {
	ExpansionPanel,
	ProgressContainer,
	ShowMore,
} from '@components';
import { connect } from 'react-redux';
import { ipo, userAccounts } from '@redux';
import PropTypes from 'prop-types';
import { IpoOrderItem } from './components';

const styles = theme => ({
	itemContainer: {
		padding: '0 16px',
		margin: '0px -16px',
		// '&:hover': {
		// 	backgroundColor: '#e3edf5',
		// },
	},
	item: {
		borderBottom: '1px solid #deeaef',
		padding: '16px 0',
	},
	panel: {
		// minWidth: '300px',
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	orders: ipo.selectors.orders(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		getOrders: payload => dispatch(ipo.actions.getOrders.request(payload)),
	},
});


class IpoOrders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			holdersCount: 5,
		};
	}

	componentDidMount() {
		const {
			actions,
			currentTradeAccount,
			orders,
		} = this.props;

		if (!orders.succeed && currentTradeAccount.tradeCode) {
			actions.getOrders({
				TradeCode: currentTradeAccount.tradeCode,
				ClearerFirm: currentTradeAccount.clearerFirm,
				page: 1,
			});
		}
	}

	showMore = () => {
		this.setState({
			holdersCount: this.state.holdersCount + 5,
		});
	}

	render() {
		const {
			classes,
			orders: {
				submitting,
				items,
			},
		} = this.props;

		const viewedItems = items && items.slice(0, this.state.holdersCount);

		return (
			<ExpansionPanel
				header={<span>Orders</span>}
				id="ipo-orders"
			>
				<div className={classes.panel}>
					<ProgressContainer
						display="left"
						id="ipo-orders-container"
						submitting={submitting && items && items.length === 0}
					>
						{viewedItems.map((item, index) => (
							<div className={classes.itemContainer} key={index}>
								{item &&
									<div className={classes.item}>
										<IpoOrderItem item={item} />
									</div>
								}
							</div>
						))}
						<ShowMore
							onClick={this.showMore}
							showMoreButton={items.length > this.state.holdersCount}
						/>
					</ProgressContainer>
				</div>
			</ExpansionPanel>
		);
	}
}

IpoOrders.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	orders: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	SafeComponent,
)(IpoOrders);
