import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { Divider, R, SvgIcon, Button } from '@components';
import { constants, env } from '@config';
import { agent, userAccounts } from '@redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import {
	AccountSelection,
	AccountActions,
	LinkedAccountsList,
	EmptyLinks,
	QuickLinks,
	ReportsQuickLinks,
	DocumentsQuickLinks,
} from './components';

const styles = theme => ({
	root: {
		backgroundColor: '#fff',
	},
	accountSelection: {
	},
	accountActions: {
	},
	linkedAccountsList: {
	},
	actions: {
	},
	actionsDivider: {
		padding: '0 24px',
	},
	buttonsWrapper: {
		margin: '16px 24px',
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		partnerCabinetRedirect: () => dispatch(agent.actions.partnerCabinetRedirect.request()),
		agentRegistration: () => dispatch(agent.actions.agentRegistration.request({
			AgentRewardType: 'IB',
			ApplyAgreement: true,
		})),
	},
});

const mapStateToProps = state => ({
	accounts: userAccounts.selectors.accounts(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	agentPartnerCabinetRedirect: agent.selectors.partnerCabinetRedirect(state),
	userInfo: userAccounts.selectors.userInfo(state),
	userInfoLoading: userAccounts.selectors.loading(state) || userAccounts.selectors.userInfo(state).submitting,
});


class SidebarContent extends React.Component {
	componentWillUnmount() {
		if (!this.props.location.hash) {
			// if no Modals when show main scroll for mobiles
			document.querySelector('body').style.overflow = 'auto';
		} else {
			// otherwise - hide main scroll for mobiles
			document.querySelector('body').style.overflow = 'hidden';
		}
	}

	render() {
		const {
			actions,
			classes,
			match,
			agentPartnerCabinetRedirect,
			userInfo,
			userInfoLoading,
		} = this.props;

		return (
			<div className={classes.root}>
				<Scrollbars autoHide style={{ height: '100vh' }}>
					<div className={classes.accountSelection}>
						<AccountSelection />
					</div>
					<Divider />
					{(env.firm === constants.LIME_FIN) &&
						<div className={classes.linkedAccountsList}>
							<LinkedAccountsList />
						</div>
					}
					<Divider />
					<div className={classes.action}>
						<div className={classes.accountActions}>
							<AccountActions />
						</div>
						<div className={classes.actionsDivider}>
							<Divider />
						</div>
						{(env.firm === constants.LIME_FIN) &&
							<div className={classes.quickLinks}>
								<Switch>
									<Route exact path={`${match.url}/settings`} component={EmptyLinks} />
									<Route exact path={`${match.url}/ipo`} component={EmptyLinks} />
									<Route exact path={`${match.url}/reports`} component={ReportsQuickLinks} />
									{/* functionality was closed by task WEBCAB-3983
									<Route exact path={`${match.url}/promotions`} /> */}
									<Route exact path={`${match.url}/features`} component={EmptyLinks} />
									<Route exact path={`${match.url}/documents`} component={DocumentsQuickLinks} />
									<Route exact path={`${match.url}/cashmanagement`} component={EmptyLinks} />
									<Route exact path={`${match.url}/thirdpartytools`} component={EmptyLinks} />
									<Route path={`${match.url}`} component={QuickLinks} />
								</Switch>
							</div>
						}
					</div>
					<div className={classes.buttonsWrapper}>
						{constants.LIME_LME === env.firm && !userInfo.IsAgent &&
							<Button
								className="QA-BecomePartner"
								fullWidth
								onClick={actions.agentRegistration}
								variant="contained"
								color="primary"
								disabled={!agentPartnerCabinetRedirect || agentPartnerCabinetRedirect.submitting || userInfoLoading}
								startIcon={
									<SvgIcon
										icon="Handshake"
										submitting={this.props.agentPartnerCabinetRedirect.submitting || userInfoLoading}
									/>
								}
							>
								<R id="bt_become_partner" defaultValue="Become a Partner" />
							</Button>
						}
						{constants.LIME_LME === env.firm && userInfo.IsAgent &&
							<Button
								className="QA-PartnerAccount"
								fullWidth
								onClick={actions.partnerCabinetRedirect}
								variant="contained"
								color="primary"
								disabled={!agentPartnerCabinetRedirect || agentPartnerCabinetRedirect.submitting}
								startIcon={
									<SvgIcon
										icon="Handshake"
										submitting={this.props.agentPartnerCabinetRedirect.submitting}
									/>
								}
							>
								<R id="menu_partner_cabinet" defaultValue="Partner Account" />
							</Button>
						}
					</div>
				</Scrollbars>
			</div>
		);
	}
}

SidebarContent.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	agentPartnerCabinetRedirect: PropTypes.object.isRequired,
	userInfo: PropTypes.object.isRequired,
	userInfoLoading: PropTypes.bool.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(SidebarContent);
