import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import { A } from '@components';
import { isNumber, toUsaMoneyFormat } from '@global';


const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between',
		},
		marginRight: '62px',
	},
	description: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		textAlign: 'justify',
		marginTop: '30px',
		marginRight: '62px',
	},
	leftColumn: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		display: 'flex',
		flexDirection: 'column',
		paddingRight: '30px',
	},
	rightColumn: {
		[theme.breakpoints.up('sm')]: {
			width: '35%',
		},
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
	},
	companyName: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
});

class OrderExtendedInfo extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		let prospUrl = item.Offering.ProsepectusUrl;
		if (prospUrl && prospUrl.indexOf('http') < 0) {
			prospUrl = `https://${prospUrl}`;
		}

		return (
			<React.Fragment>
				<div className={classes.root}>
					<div className={classes.mobileAdapter} />
					<div className={classes.leftColumn}>
						<span>Company Name</span>
						<span>Price Range</span>
						<span>IPO Date</span>
						{isNumber(item.Quantity) &&
							<span>Quantity</span>
						}
						{isNumber(item.DistributedShares) &&
							<span>Distributed Units</span>
						}
						{prospUrl &&
							<span>Prospectus</span>
						}
					</div>
					<div className={classes.rightColumn}>
						<span className={classes.companyName}>{item.Offering.Name}</span>
						<span>{toUsaMoneyFormat(item.Offering.MinPrice)} - {toUsaMoneyFormat(item.Offering.MaxPrice)}</span>
						<span>{moment(item.Offering.TradeDate).format('D MMM YYYY')}</span>
						{isNumber(item.Quantity) &&
							<span>{item.Quantity}</span>
						}
						{isNumber(item.DistributedShares) &&
							<span>{item.DistributedShares}</span>
						}
						{prospUrl &&
							<A href={prospUrl} target="_blank">Prospectus</A>
						}
					</div>
				</div>
				{item.Offering.Description &&
					<div className={classes.description}>
						{item.Offering.Description}
					</div>
				}
			</React.Fragment>
		);
	}
}

OrderExtendedInfo.propTypes = {
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	SafeComponent,
)(OrderExtendedInfo);
