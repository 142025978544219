import React from 'react';
import { SubmissionError } from 'redux-form';
import { put, call } from 'redux-saga/effects';
import { cabinetLog, captureException } from '@global';
import { auth, snackbar } from '@redux';


export function* callApiWrapper(apiFn, ...args) {
	try {
		const request = call(apiFn, ...args);
		let response = yield request;
		if (response.data.Errors && response.data.Errors[0] && response.data.Errors[0].Code === 'NeedReCall') {
			// need call this method again
			cabinetLog('need call this method again');
			response = yield request;
		}
		cabinetLog(apiFn.name || 'respcall', response);
		if (response.data.Errors && response.data.Errors[0] && response.data.Errors[0].Code === 'Unauthorized') {
			yield put(auth.actions.logout());
			// window.Raven.captureException(new Error('Client Unauthorized'));
		}
		if (typeof response.data === 'string') {
			// if was error with status 200
			window.Raven.captureMessage('response.data === string', { extra: { text: response.data.substr(0, 200) } });
			response.data = {
				Success: false,
				Errors: [{
					Code: 'Unknown',
					Message: 'Unexpected server resoponse. The information sent to developers. Try to repeat your actions or contact support.',
				}],
			};
		}
		return response;
	}	catch (e) {
		if (e.response) {
			if (e.response.data && e.response.data.Errors && Array.isArray(e.response.data.Errors)) {
				throw new Error(e.response.data.Errors[0].Message);
			} else if (e.response.statusText) {
				throw new Error(e.response.statusText);
			} else {
				throw new Error(e);
			}
		} else throw new Error(e);
	}
}

export function* callApi(apiFn, ...args) {
	return yield call(callApiWrapper, apiFn, ...args);
}


export function* BaseGetSagaHandler(
	{
		/**
		 * Rest service api method for request data from server.
		 */
		apiMethod,
		/**
		 * Message text when error is occured.
		 */
		errorText,
		/**
		 * Redux callback that processing response from the service.
		 */
		handler,
		/**
		 * Defaults settings for request.
		 */
		defaults,
		/**
		 * UI callback fired when the response was received from the service.
		 * It's needed for special components like ConfirmModal.
		 */
		onEndRequest,
	},
	{ payload },
) {
	try {
		const request = defaults ? { ...defaults, ...payload } : payload;
		const response = yield callApi(apiMethod, request);
		if (onEndRequest) {	onEndRequest();	}
		if (!response.data.Success) {
			const errData = {};

			if (response.data.Errors && response.data.Errors.length > 0) {
				// eslint-disable-next-line no-underscore-dangle
				errData._error = response.data.Errors[0].Message;
			}

			response.data.ValidationErrors.forEach((item) => {
				// display field's validation errors on redux-form
				errData[item.Code] = item.Message;
			});

			const err = new SubmissionError(errData);
			if (response.data.ValidationErrors.length === 0) {
				yield put(snackbar.actions.showErrorMessage({
					title: errorText,
					text: response.data.Errors.length > 1
						? <ul>{response.data.Errors.map(errItem => <li>{errItem.Message}</li>)}</ul>
						: response.data.Errors[0].Message,
					hasHtml: true,
				}));
			}
			yield put(handler.failure(err));
			return false;
		}
		yield put(handler.success({ request, response: response.data }));
		return true;
	} catch (error) {
		if (onEndRequest) {	onEndRequest();	}
		captureException(handler.REQUEST, error);
		yield put(snackbar.actions.showErrorMessage({
			title: errorText,
			text: error.message,
		}));
		yield put(handler.failure(new SubmissionError({
			_error: error.message,
		})));
		console.error(error);
		return false;
	}
}
