import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { ExpansionPanel, SvgIcon } from '@components';
import { env } from '@config';
import { createQAAttribute } from '@global';
import ApiKeysPanelContent from './ApiKeysPanelContent';

const useStyles = makeStyles(theme => ({
	expansionPanelHeadContent: {
		padding: '0px',
	},
	lightTooltip: {
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}));

const ApiKeysPanel = (props) => {
	const {
		onToggle,
		panelId,
		isOpen,
	} = props;

	const classes = {
		...useStyles(),
		...props.classes,
	};

	return (
		<ExpansionPanel
			header={
				<span>
					<span {...createQAAttribute('api_keys')}>API Keys</span>
					<span>
						<Tooltip
							classes={{ tooltip: classes.lightTooltip }}
							title="In order to use the API, your application needs be registered with us
								first to receive your client id and client secret"
						>
							<IconButton
								target="_blank"
								href={env.anketaDocumentationUrl}
								{...createQAAttribute('api_keys_dis')}
							>
								<SvgIcon icon="Help" htmlColor="black" />
							</IconButton>
						</Tooltip>
					</span>
				</span>
			}
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{
				root: classes.expansionPanelRoot,
				content: classes.expansionPanelContent,
				headContent: classes.expansionPanelHeadContent,
			}}
			qaAttributes={{ icon: createQAAttribute('api_keys_accord') }}
		>
			<ApiKeysPanelContent />
		</ExpansionPanel>
	);
};

ApiKeysPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

ApiKeysPanel.defaultProps = {
	isOpen: false,
};

export default ApiKeysPanel;
