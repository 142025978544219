const actions = {
	CONNECT_SIGNAL_R: 'SIGNALR/CONNECT',
	DISCONNECT_SIGNAL_R: 'SIGNALR/DISCONNECT',
	RECONNECT_SIGNAL_R: 'SIGNALR/RECONNECT',

	connectSignalR: () => ({
		type: actions.CONNECT_SIGNAL_R,
	}),

	disconnectSignalR: () => ({
		type: actions.DISCONNECT_SIGNAL_R,
	}),

	reconnectSignalR: () => ({
		type: actions.RECONNECT_SIGNAL_R,
	}),
};

export default actions;
