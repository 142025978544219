import { put, fork, takeEvery, select, call } from 'redux-saga/effects';
import { achDeposit, brokerDeposit, wireDepositInfo, iraDeposit } from '@api';
import { SubmissionError } from 'redux-form';
import { BaseGetSagaHandler, snackbar, userAccounts, callApi } from '@redux';
import { push } from 'connected-react-router';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl } from '@global';
import actions from './actions';

// function* reloadTransactionHistory(tradeCode, clearerFirm) {
// 	const cashActivities = yield select(userAccounts.selectors.cashActivities);
// 	const {
// 		StartDate,
// 		EndDate,
// 	} = cashActivities.pagination;
	
// 	// reload first page
// 	yield put(userAccounts.actions.getTransactionsHistory.request({
// 		TradeCode: tradeCode,
// 		ClearerFirm: clearerFirm,
// 		StartDate,
// 		EndDate,
// 		Page: 1,
// 		PageSize: 10,
// 	}));
// }

function* depositFormSubmit({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: achDeposit,
		errorText: 'ACH Deposit Funds',
		handler: actions.depositAchFundsForm,
	}, { payload });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.tradeCode, payload.clearerFirm);
	}
}
function* depositFormBrokerSubmit({ payload }) {
	const createTransferModel = {
		TradeCode: payload.TradeCode,
		ClearingFirm: payload.ClearingFirm,
		Amount: payload.Amount,
		Direction: payload.Direction,
		BrokerAccount: payload.bankAccountNumber,
	};
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: brokerDeposit,
		errorText: 'Broker Deposit Funds',
		handler: actions.depositBrokerFundsForm,
	}, { payload: createTransferModel });

	if (isSuccess) {
		yield* userAccounts.saga.reloadTransactionHistory(payload.TradeCode, payload.ClearingFirm);
	}
}
function* depositFormIraSubmit({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: iraDeposit,
		errorText: 'Ira Deposit Funds',
		handler: actions.depositIraFundsForm,
	}, { payload });
}

function* wireDepositInfoFormSubmit({ payload }) {
	try {
		const response = yield callApi(wireDepositInfo, payload);
		if (!response.data.Success) {
			yield put(actions.wireDepositInfoFormFailure(response.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: response.data.Errors[0].Message }));
		} else {
			yield put(actions.wireDepositInfoFormSuccess({
				...payload,
				Url: response.data.Url,
			}));
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.wireDepositInfoFormFailure(new SubmissionError({
			_error: 'NETWORK ERROR',
		})));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* showDepositFundsAchModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.DEPOSIT_FUNDS_ACH)));
}

function* hideDepositFundsAchModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.DEPOSIT_FUNDS_ACH)));
}
function* showDepositFundsBrokerModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.DEPOSIT_FUNDS_BROKER)));
}

function* hideDepositFundsBrokerModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.DEPOSIT_FUNDS_BROKER)));
}

function* showDepositFundsIraModal() {
	const location = yield select(state => state.router.location);
	yield put(push(addHashToUrl(location, ModalPopups.DEPOSIT_FUNDS_IRA)));
}

function* hideDepositFundsIraModal() {
	const location = yield select(state => state.router.location);
	yield put(push(removeHashFromUrl(location, ModalPopups.DEPOSIT_FUNDS_IRA)));
}
function* showDepositFundsWireModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.DEPOSIT_FUNDS_WIRE)));
}

function* hideDepositFundsWireModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.DEPOSIT_FUNDS_WIRE)));
}


// Watchers
function* watchDepositFormSubmit() {
	yield takeEvery(actions.depositAchFundsForm.REQUEST, depositFormSubmit);
}
function* watchDepositFormBrokerSubmit() {
	yield takeEvery(actions.depositBrokerFundsForm.REQUEST, depositFormBrokerSubmit);
}
function* watchDepositFormIraSubmit() {
	yield takeEvery(actions.depositIraFundsForm.REQUEST, depositFormIraSubmit);
}
function* watchWireDepositInfoFormSubmit() {
	yield takeEvery(actions.WIRE_DEPOSIT_INFO_FORM_SUBMIT_REQUEST, wireDepositInfoFormSubmit);
}
function* watchShowDepositFundsAchModal() {
	yield takeEvery(actions.SHOW_DEPOSIT_FUNDS_MODAL, showDepositFundsAchModal);
}
function* watchHideDepositFundsAchModal() {
	yield takeEvery(actions.HIDE_DEPOSIT_FUNDS_MODAL, hideDepositFundsAchModal);
}
function* watchShowDepositFundsBrokerModal() {
	yield takeEvery(actions.SHOW_DEPOSIT_FUNDS_BROKER_MODAL, showDepositFundsBrokerModal);
}
function* watchHideDepositFundsBrokerModal() {
	yield takeEvery(actions.HIDE_DEPOSIT_FUNDS_BROKER_MODAL, hideDepositFundsBrokerModal);
}
function* watchShowDepositFundsIraModal() {
	yield takeEvery(actions.SHOW_DEPOSIT_FUNDS_IRA_MODAL, showDepositFundsIraModal);
}
function* watchHideDepositFundsIraModal() {
	yield takeEvery(actions.HIDE_DEPOSIT_FUNDS_IRA_MODAL, hideDepositFundsIraModal);
}
function* watchShowDepositFundsWireModal() {
	yield takeEvery(actions.SHOW_DEPOSIT_FUNDS_WIRE_MODAL, showDepositFundsWireModal);
}
function* watchHideDepositFundsWireModal() {
	yield takeEvery(actions.HIDE_DEPOSIT_FUNDS_WIRE_MODAL, hideDepositFundsWireModal);
}


// Root saga
const rootSaga = [
	fork(watchDepositFormSubmit),
	fork(watchDepositFormBrokerSubmit),
	fork(watchDepositFormIraSubmit),
	fork(watchWireDepositInfoFormSubmit),
	fork(watchShowDepositFundsAchModal),
	fork(watchHideDepositFundsAchModal),
	fork(watchShowDepositFundsBrokerModal),
	fork(watchHideDepositFundsBrokerModal),
	fork(watchShowDepositFundsIraModal),
	fork(watchHideDepositFundsIraModal),
	fork(watchShowDepositFundsWireModal),
	fork(watchHideDepositFundsWireModal),
];

export default {
	rootSaga,
};
