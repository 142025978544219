import React, { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TranslateContext } from './TranslateContext';

const htmlTags = {
	b: <strong/>,
};

export const useTranslate = (showDicwordsKeys = false) => {
	const { t, i18n, ready } = useTranslation();
	const translateContextData = useContext(TranslateContext);

	const extractArgs = (args) => {
		if (!args || args.length === 0) {
			throw new Error('DicWord code not specified');
		}
		if (args.length === 1 && Object.isObject(args[0])) {
			return args[0];
		}
		return {
			id: args[0],
			defaultValue: args.length > 1 ? args[1] : null,
		};
	};
	const retFunc = useMemo(() => (
		(...args) => {
			const {
				id, translateParameters, components, defaultValue, enableHtml,
			} = extractArgs(args);

			const transComponents =
				enableHtml || components
					? {
						...(enableHtml ? htmlTags : {}),
						...(components || {}),
					}
					: undefined;
			if (!ready) {
				return defaultValue || '';
			}
			if (enableHtml && !components) {
				return (
					<span
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: translateContextData.t(id, {
								...(translateParameters || {}),
								defaultValue,
								transComponents,
							}),
						}}
					/>
				);
			}

			const res = translateContextData.t(id, {
				...(translateParameters || {}),
				defaultValue,
				transComponents,
			});
			return res;
		}
	), [showDicwordsKeys, translateContextData, ready]);

	return retFunc;
};

const R = (props) => {
	const {
		id, showDicwordsKeys = false, translateParameters, components, defaultValue, enableHtml,
	} = props;
	const { t, i18n, ready } = useTranslation();
	const transComponents =
		enableHtml || components
			? {
				...(enableHtml ? htmlTags : {}),
				...(components || {}),
			}
			: undefined;
	const tOptions = { transSupportBasicHtmlNodes: enableHtml };
	return (
		<TranslateContext.Consumer>
			{(translateContextData) => {
				if (!ready) {
					return defaultValue || '';
				}
				if (enableHtml && !components) {
					return (
						<span
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: translateContextData.t(id, {
									...(translateParameters || {}),
									defaultValue,
									components,
								}),
							}}
						/>
					);
				}
				return (
					<React.Fragment>
						{(showDicwordsKeys || translateContextData.showDicwordsKeys) &&
							`[[${id}]] `}
						<Trans
							t={t}
							i18nKey={id}
							defaults={defaultValue}
							values={translateParameters}
							components={transComponents}
							tOptions={tOptions}
						/>
					</React.Fragment>
				);
			}}
		</TranslateContext.Consumer>
	);
};

R.propTypes = {
	id: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	showDicwordsKeys: PropTypes.bool,
	enableHtml: PropTypes.bool,
	translateParameters: PropTypes.object,
	components: function componentValidate(props, propName, componentName) {
		const name = componentName || 'R';
		if (props[propName]) {
			const value = props[propName];

			if (!value) {
				return null;
			}

			if (value && (typeof value !== 'object')) {
				return new Error(`${propName} in ${name} shoud be Object but it is ${typeof value}`);
			}

			const resticted = Object.keys(value).filter(x => ['link', 'img', 'media'].some(b => b === x.toLowerCase()));
			if (resticted.length > 0) {
				return new Error(`${propName}  in ${name} contains bad component names: ${resticted.join(',')}. For aditinal info see https://react.i18next.com/latest/trans-component.`);
			}
		}
		// assume all ok
		return null;
	},
};

R.defaultProps = {
	showDicwordsKeys: false,
	defaultValue: undefined,
	translateParameters: undefined,
	enableHtml: false,
	components: undefined,
};
export default R;
