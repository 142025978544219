import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { profile, userAccounts } from '@redux';
import {
	ChangeLink,
	ExpansionPanel,
} from '@components';
import { EMPLOYMENT_STATUS } from '@utils';
import { createQAAttribute } from '@global';
import { EmployedPanel, StudentPanel, RetiredPanel } from './components';

const useActions = (userProfile) => {
	const dispatch = useDispatch();
	return ({
		showChangeEmploymentModal: () => dispatch(profile.actions.showChangeEmploymentModal(userProfile)),
	});
};

const EmploymentPanel = ({
	onToggle,
	panelId,
	isOpen,
	classes,
	profile: {
		EmploymentProfile,
		PendingOrders,
		Guid,
		ClientId,
	},
}) => {
	const actions = useActions({
		EmploymentProfile,
		PendingOrders,
		Guid,
		ClientId,
	});

	const hasOpenAccount = useSelector(userAccounts.selectors.hasOpenAccount);

	let content = null;
	if (EmploymentProfile.Status === EMPLOYMENT_STATUS.EMPLOYED ||
		EmploymentProfile.Status === EMPLOYMENT_STATUS.SELF_EMPLOYED) {
		content = <EmployedPanel profile={EmploymentProfile} />;
	}
	if (EmploymentProfile.Status === EMPLOYMENT_STATUS.RETIRED ||
		EmploymentProfile.Status === EMPLOYMENT_STATUS.UNEMPLOYED) {
		content = <RetiredPanel profile={EmploymentProfile} />;
	}
	if (EmploymentProfile.Status === EMPLOYMENT_STATUS.STUDENT) {
		content = <StudentPanel profile={EmploymentProfile} />;
	}

	return (
		<ExpansionPanel
			header={
				<span>
					<span className={classes.mr60} {...createQAAttribute('employment')}>Employment</span>
					<span>
						{ hasOpenAccount &&
							<ChangeLink
								onClick={actions.showChangeEmploymentModal}
								havePendingOrder={PendingOrders.Employment}
								qaAttribute={createQAAttribute('employment_change')}
							/>
						}
					</span>
				</span>}
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
			qaAttributes={{ icon: createQAAttribute('employment_accord') }}
		>
			{content}
		</ExpansionPanel>
	);
};

EmploymentPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

EmploymentPanel.defaultProps = {
	isOpen: false,
};

export default EmploymentPanel;
