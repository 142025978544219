import { LimeLogo, NSRLogo, J2tLogo } from '@components';
import { constants, env } from '@config'
import limeBg from './lime/lime_bg_logo.png';
import j2tBg from './limeltd/j2t_logo.png'
import limeTradingLogo from './lime/lime_trading_logo.png';

import LimeInternationalLogo from './international/international_logo.svg';

import LendingRobotLogo from './lendingRobot/lendingrobot_logo.svg';
import NSRLoginSvg from './lendingRobot/lendingrobot_logotype.svg';


const template = {

	bgLogo: undefined,
	firmLogo: undefined,
	loginLogo: undefined,
};

if (env.firm === constants.LIME_FIN) {

	template.bgLogo = limeBg;
	template.firmLogo = LimeLogo;
	template.loginLogo = limeTradingLogo;
}

if (env.firm === constants.LIME_INT) {
	
	template.bgLogo = limeBg;
	template.firmLogo = LimeLogo;
	template.loginLogo = LimeInternationalLogo;
}

if (env.firm === constants.LIME_NSR) {
	
	template.bgLogo = LendingRobotLogo;
	template.firmLogo = NSRLogo;
	template.loginLogo = NSRLoginSvg;
}

if (env.firm === constants.LIME_LME) {
	
	template.bgLogo = j2tBg;
	template.firmLogo = J2tLogo;
	template.loginLogo = j2tBg;
}
export default template;
