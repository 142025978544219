import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ModalPopups } from '@enums';
import { nontradingOrders } from '@redux';
import {
	CYNonTradingOrderCommonModal,
	CYNonTradingOrderIdentityModal,
	CYNonTradingOrderLeverageModal,
	CYNonTradingOrderRegistrationModal,
	CYNonTradingOpenAccountModal,
	CYNonTradingOpenDemoAccountModal,
} from './components';


export const CYNonTradingOrderModals = ({}) => {
	const location = useLocation();
	const dispatch = useDispatch();

	const getNonTradingOrderModal = (item) => {
		switch (item) {
			case ModalPopups.CY_NON_TRADING_ORDER_COMMON:
				return (
					<CYNonTradingOrderCommonModal
						key={`${ModalPopups.CY_NON_TRADING_ORDER_COMMON}Modal`}
						onClose={() => closeModal(ModalPopups.CY_NON_TRADING_ORDER_COMMON)}
					/>);
			case ModalPopups.CY_NON_TRADING_ORDER_IDENTITY:
				return (
					<CYNonTradingOrderIdentityModal
						key={`${ModalPopups.CY_NON_TRADING_ORDER_IDENTITY}Modal`}
						onClose={() => closeModal(ModalPopups.CY_NON_TRADING_ORDER_IDENTITY)}
					/>);
			case ModalPopups.CY_NON_TRADING_ORDER_LEVERAGE:
				return (
					<CYNonTradingOrderLeverageModal
						key={`${ModalPopups.CY_NON_TRADING_ORDER_LEVERAGE}Modal`}
						onClose={() => closeModal(ModalPopups.CY_NON_TRADING_ORDER_LEVERAGE)}
					/>);
			case ModalPopups.CY_NON_TRADING_ORDER_REGISTRATION:
				return (
					<CYNonTradingOrderRegistrationModal
						key={`${ModalPopups.CY_NON_TRADING_ORDER_REGISTRATION}Modal`}
						onClose={() => closeModal(ModalPopups.CY_NON_TRADING_ORDER_REGISTRATION)}
					/>);
			case ModalPopups.CY_NON_TRADING_OPEN_ACCOUNT:
				return (
					<CYNonTradingOpenAccountModal
						key={`${ModalPopups.CY_NON_TRADING_OPEN_ACCOUNT}Modal`}
						onClose={() => closeModal(ModalPopups.CY_NON_TRADING_OPEN_ACCOUNT)}
					/>);
			case ModalPopups.CY_NON_TRADING_OPEN_DEMO_ACCOUNT:
				return (
					<CYNonTradingOpenDemoAccountModal
						key={`${ModalPopups.CY_NON_TRADING_OPEN_DEMO_ACCOUNT}Modal`}
						onClose={() => closeModal(ModalPopups.CY_NON_TRADING_OPEN_DEMO_ACCOUNT)}
					/>);
			default:
				return null;
		}
	};
	const closeModal = id => dispatch(nontradingOrders.actions.hideNonTradingOrderModal(id));

	const modals = location.hash && location.hash.substr(1).split('/');
	const topModal = modals && modals.length > 0 && getNonTradingOrderModal(modals && modals.pop());

	return (
		<React.Fragment>
			{topModal}
		</React.Fragment>
	);
};
