import { put, select, fork, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { version, language } from '@api';
import { env, constants } from '@config';
import { ModalPopups } from '@enums';
import { addHashToUrl, cabinetLog, removeHashFromUrl, captureException } from '@global';
import { callApi, notifications, userAccounts, snackbar } from '@redux';
import { Cookies } from 'react-cookie';
import actions from './actions';


function* changeLanguage({ type, payload: { lang } }) {
	try {
		yield language(lang);
	} catch (ex) {
		captureException(type, ex);
		yield put(snackbar.actions.showErrorMessage({
			title: 'Application error',
			text: 'Please contact with our support team',
		}));
	}
	const account = yield select(userAccounts.selectors.currentTradeAccount);

	if (yield select(state => state.userAccounts.currentAccount)) {
		yield put(notifications.actions.getNotificationsRequest(account.tradeCode));

		// WEBCAB-6463
		// при изменении языка инициируем обновление секции FundingInfo
		if (env.firm !== constants.LIME_FIN) {
			yield put(userAccounts.actions.getAccountInfoRequest(account));
		}
	}
}

function* showConfirmModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.CONFIRM_MODAL)));
}

function* hideConfirmModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.CONFIRM_MODAL)));
}

function* showBaseModal({ payload: { modalName } }) {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, modalName)));
}

function* hideBaseModal({ payload: { modalName } }) {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, modalName)));
}


function* checkMinVersionSaga() {
	try {
		const response = yield callApi(version);
		if (response.data.Success) {
			if (!env.version || env.version < response.data.Version) {
				window.location.reload(true);
			}
		}
	} catch (error) {
		cabinetLog(error);
	}
}

function* showDicWordsKeysSaga() {
	const exp = new Date();
	exp.setTime(exp.getTime() + 24 * 60 * 60 * 1000);
	const cookies = new Cookies();
	cookies.set('show_dicwords_keys', '1', {path: "/", expires: exp});
}

function* hideDicWordsKeysSaga() {
	const cookies = new Cookies();
	cookies.remove('show_dicwords_keys', { path: '/' });
}

function* watchShowDicWordsKeysRequest() {
	yield takeEvery(actions.SHOW_DICWORDS, showDicWordsKeysSaga);
}

function* watchHideDicWordsKeysRequest() {
	yield takeEvery(actions.HIDE_DICWORDS, hideDicWordsKeysSaga);
}

function* watchangeLanguage() {
	yield takeEvery(actions.CHANGE_LANGUAGE, changeLanguage);
}

function* watchShowConfirmModal() {
	yield takeEvery(actions.SHOW_CONFIRM_MODAL, showConfirmModal);
}
function* watchHideConfirmModal() {
	yield takeEvery(actions.HIDE_CONFIRM_MODAL, hideConfirmModal);
}
function* watchShowBaseModal() {
	yield takeEvery(actions.SHOW_BASE_MODAL, showBaseModal);
}
function* watchHideBaseModal() {
	yield takeEvery(actions.HIDE_BASE_MODAL, hideBaseModal);
}
function* watchMinVersionRequest() {
	yield takeEvery(actions.CHECK_MIN_VERSION, checkMinVersionSaga);
}

// Root saga
const rootSaga = [
	fork(watchShowConfirmModal),
	fork(watchHideConfirmModal),
	fork(watchShowBaseModal),
	fork(watchHideBaseModal),
	fork(watchMinVersionRequest),
	fork(watchShowDicWordsKeysRequest),
	fork(watchHideDicWordsKeysRequest),
	fork(watchangeLanguage),

];

export default {
	rootSaga,
};
