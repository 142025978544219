/* eslint-disable react/no-did-update-set-state,no-param-reassign,consistent-return */
import { userAccounts } from '@redux';
import React from 'react';
import PropTypes from 'prop-types';
import { ModalPopups, TwoFactorAutenticationType } from '@enums';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
	Button,
	Modal,
	ProgressContainer,
} from '@components';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TotpAuthenticationForm, SmsAuthenticationForm, EmailAuthenticationForm } from './components';

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	navButton: {
		margin: '10px 10px',
		padding: '5px',
		width: '120px',
	},
};

const mapStateToProps = state => ({
	userInfo: userAccounts.selectors.userInfo(state),
	loading: userAccounts.selectors.loading(state),
});

const AuthTypeRadioItem = ({ currentValue, value, label }) => {
	const disabled = value === currentValue;
	return (
		<FormControlLabel disabled={disabled} value={value} control={<Radio style={{ color: '#1a96ff' }} />} label={label} />
	);
};

AuthTypeRadioItem.propTypes = {
	currentValue: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

class ChangeTwoFactorAuthorizationModal extends React.Component {
	constructor(props) {
		super(props);

		const {
			userInfo,
		} = props;

		// || TwoFactorAutenticationType.NONE - для фикса ворнинга: "RadioGroup uncontrolled component become controlled"
		this.state = {
			selectedAuthType: userInfo.TwoFactorAutenticationType || TwoFactorAutenticationType.NONE,
			page: 0,
		};
	}

	// чтобы игнорировать get_user_info_success с новым типом auth
	shouldComponentUpdate(nextProps) {
		if (this.state.page === 1 && this.props.userInfo.TwoFactorAutenticationType !== nextProps.userInfo.TwoFactorAutenticationType) {
			return false;
		}
		return true;
	}

	// чтобы в режиме перехода по прямой ссылке проставить текущий auth type как только придут данные.
	componentDidUpdate(prevProps) {
		if (this.props.userInfo.TwoFactorAutenticationType !== prevProps.userInfo.TwoFactorAutenticationType) {
			this.setState({
				selectedAuthType: this.props.userInfo.TwoFactorAutenticationType,
			});
		}
	}

	onCloseHandler = () => this.props.onClose();

	getAuthComponent = () => {
		const {
			onClose,
		} = this.props;

		const {
			selectedAuthType,
		} = this.state;

		if (selectedAuthType === TwoFactorAutenticationType.SMS) {
			return <SmsAuthenticationForm onClose={onClose} />;
		}

		if (selectedAuthType === TwoFactorAutenticationType.TOTP) {
			return <TotpAuthenticationForm onClose={onClose} />;
		}

		if (selectedAuthType === TwoFactorAutenticationType.EMAIL) {
			return <EmailAuthenticationForm onClose={onClose} />;
		}

		return null;
	}

	nextClick = () => {
		this.setState({
			page: 1,
		});
	}

	handleAuthTypeChange = (e) => {
		this.setState({
			selectedAuthType: e.target.value,
		});
	}

	render() {
		const {
			classes,
			onClose,
			userInfo: {
				TwoFactorAutenticationType: currentAuthType = TwoFactorAutenticationType.NONE,
			},
			loading,
		} = this.props;

		const {
			page,
			selectedAuthType,
		} = this.state;

		return (
			<Modal
				title="Change two-factor authentication"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION}`}
			>
				{ page === 0 &&
					<ProgressContainer submitting={loading} disableCheckChildren>
						<div className={classes.root}>
							<RadioGroup value={selectedAuthType} onChange={e => this.handleAuthTypeChange(e)} row={false}>
								<AuthTypeRadioItem
									currentValue={currentAuthType}
									value={TwoFactorAutenticationType.SMS}
									label="Text message (SMS)"
								/>
								<AuthTypeRadioItem
									currentValue={currentAuthType}
									value={TwoFactorAutenticationType.TOTP}
									label="Authenticator app"
								/>
								<AuthTypeRadioItem
									currentValue={currentAuthType}
									value={TwoFactorAutenticationType.EMAIL}
									label="Text message (EMAIL)"
								/>
							</RadioGroup>
							{currentAuthType !== selectedAuthType &&
								<Button
									fullWidth
									type="button"
									onClick={this.nextClick}
									variant="contained"
									color="primary"
								>
									Enable
								</Button>
							}
						</div>
					</ProgressContainer>
				}
				{ page === 1 && this.getAuthComponent() }
			</Modal>
		);
	}
}

ChangeTwoFactorAuthorizationModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	userInfo: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
)(ChangeTwoFactorAuthorizationModal);
