import actions from './actions';
import auth from '../auth';

const initialState = {
	bankAccounts: {
		loading: true,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.GET_PLAID_ACCOUNTS_REQUEST:
		return {
			...state,
			bankAccounts: {
				loading: true,
			},
		};

	case actions.GET_PLAID_ACCOUNTS_SUCCESS:
		return {
			...state,
			bankAccounts: {
				loading: false,
				accounts: payload.BankAccounts,
				error: null,
			},
		};

	case actions.GET_PLAID_ACCOUNTS_FAILURE:
		return {
			...state,
			bankAccounts: {
				loading: false,
				error: payload.error,
			},
		};
	case auth.actions.LOGOUT_SUCCESS:
		return { ...initialState };

	default:
		return state;
	}
};
