export { default as ChangeDeliveryTypeModal } from './ChangeDeliveryTypeModal/ChangeDeliveryTypeModal';
export { default as ChangeProfileAddressModal } from './ChangeProfileAddressModal/ChangeProfileAddressModal';
export { default as ChangeProfilePostAddressModal } from './ChangeProfilePostAddressModal/ChangeProfilePostAddressModal';
export { default as ChangeProfileEmailModal } from './ChangeProfileEmailModal/ChangeProfileEmailModal';
export { default as ChangeProfilePhoneModal } from './ChangeProfilePhoneModal/ChangeProfilePhoneModal';
export { default as ChangeTariffPerShareModal } from './ChangeTariffPerShareModal/ChangeTariffPerShareModal';
export { default as ChangeTariffPerTradeModal } from './ChangeTariffPerTradeModal/ChangeTariffPerTradeModal';
export { default as ChangeTariffZeroModal } from './ChangeTariffZeroModal/ChangeTariffZeroModal';
export { default as ChangeTaxFormModal } from './ChangeTaxFormModal/ChangeTaxFormModal';
export { default as ConfirmProfileEmailModal } from './ConfirmProfileEmailModal/ConfirmProfileEmailModal';
export { default as ChangeDependentsModal } from './ChangeDependentsModal/ChangeDependentsModal';
export { default as ChangeMaritalStatusModal } from './ChangeMaritalStatusModal/ChangeMaritalStatusModal';
export { default as ChangeMarketDataSubscriberStatusModal } from './ChangeMarketDataSubscriberStatusModal/ChangeMarketDataSubscriberStatusModal';
export { default as ChangeMarketDataSubscriptionsModal } from './ChangeMarketDataSubscriptionsModal/ChangeMarketDataSubscriptionsModal';
export { default as ChangeEmploymentModal } from './ChangeEmploymentModal/ChangeEmploymentModal';
export { default as ChangeInvestmentExperienceModal } from './ChangeInvestmentExperienceModal/ChangeInvestmentExperienceModal';
export { default as ChangeInvestmentProfileModal } from './ChangeInvestmentProfileModal/ChangeInvestmentProfileModal';
export { default as ConfirmProfileModal } from './ConfirmProfileModal/ConfirmProfileModal';
export { default as CYChangePasswordModal } from './CYChangePasswordModal/CYChangePasswordModal';
export { default as ChangeTwoFactorAuthorizationModal } from './ChangeTwoFactorAuthorizationModal/ChangeTwoFactorAuthorizationModal';
export { default as EnableThirdPartyMarketDataModal } from './EnableThirdPartyMarketDataModal/EnableThirdPartyMarketDataModal';
