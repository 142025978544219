import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { acatDeposit } from '@redux';
import {
	A,
	Button,
	Checkbox,
	Divider,
	FileDownload,
	LimeLogo,
	Select,
	SuccessMessage,
	SvgIcon,
} from '@components';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { env } from '@config';
// import j2tlogo from './j2t.png';


const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	divider: {
		marginTop: '30px',
	},
	pennyText: {
		textAlign: 'justify',
	},
	additionalText: {
		padding: '0px 24px',
		color: theme.typography.color.secondary,
		...theme.typography.style.body,
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	pennyListEntry: {
		marginLeft: '20px',
	},
});


const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		TradeCode: ownProps.closeAccountData.request.TradeCode,
		ClearerFirm: ownProps.closeAccountData.request.ClearerFirm,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		CloseAccountContinueRequest: request => dispatch(acatDeposit.actions.closeAccountContinueRequest(request)),
	},
});

class CloseAccountPennyForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDocShown: false,
			hasAgree: false,
		};
	}

	onChangePennyAgree = (event, checked) => {
		this.setState({ hasAgree: checked });
	}

	onClickPennyAgreeDocument = () => {
		this.setState({
			isDocShown: true,
			hasAgree: true,
		});
	}

	pennyAgree = () => {
		this.props.actions.CloseAccountContinueRequest({
			...this.props.closeAccountData.request,
			IsPennyAgree: true,
		});
	}

	pennyDisagree = () => {
		this.props.actions.CloseAccountContinueRequest({
			...this.props.closeAccountData.request,
			IsPennyAgree: false,
		});
	}

	pennyStockDocLink = file => `${env.backendUrl}Account/ClientDocument?id=${file.Id}&token=${file.Token}&name=${file.FileName}`

	render() {
		const {
			classes,
			onClose,
			closeAccountData: {
				request,
				submitting,
				submitSucceeded,
				pennyStockAttachments,
			},
		} = this.props;

		return (
			<div>
				{
					submitSucceeded ? <SuccessMessage
						onClick={onClose}
						text={<p>Thank you for your time!<br />You will receive an email shortly with further instructions.</p>}
						buttonText="Back To Account"
					/> :
						(
							<div className={classes.root}>
								<div className={classes.row}>
									<div className={classes.logo}>
										<LimeLogo />
									</div>
									<Select value={request && request.TradeCode} label="Close Trade Account" disabled>
										<MenuItem value={request && request.TradeCode}>
											<div className={classes.inputContainer}>
												<div className={classes.account}>{request && request.TradeCode}</div>
											</div>
										</MenuItem>
									</Select>
								</div>
								<div className={classes.divider}>
									<Divider />
								</div>
								<p className={classes.pennyText}>
									We are unable to locate a ready market or an independent transfer agent for the securities
									in your portfolio to conduct a security transfer. As there is no market or transfer agent
									for these securities, you may request Vision Financial Markets LLC to sell the securities
									from your account for one penny ($.01) for the lot.
								</p>
								<p className={classes.pennyText}>
									You hereby agree to sell to Vision, and request that Vision purchase, the securities,
									for a purchase price of one penny ($0.01) for the lot. You represent that you are the owner,
									free and clear, of the securities.
								</p>
								{pennyStockAttachments && (pennyStockAttachments.length > 0) &&
									<div>
										<Checkbox
											label={
												<div>
													{pennyStockAttachments.length === 1 ?
														<p>
															I hereby confirm and agree with &nbsp;
															<FileDownload
																url={this.pennyStockDocLink(pennyStockAttachments[0])}
																fileName="Penny for the Lot instruction and acknowledgement"
																buttonTitle="Penny for the Lot instruction and acknowledgement"
																onClick={this.onClickPennyAgreeDocument}
															/>
														</p>
														:
														<div>
															<p className={classes.pennyListEntry}>I hereby confirm and agree with &nbsp;</p>
															<ol>
																{pennyStockAttachments.map((file, index) => (
																	<li key={index}>
																		<FileDownload
																			url={this.pennyStockDocLink(file)}
																			fileName={`Penny for the Lot instruction (part ${index + 1})`}
																			buttonTitle={`Penny for the Lot instruction (part ${index + 1})`}
																			onClick={this.onClickPennyAgreeDocument}
																		/>
																	</li>
																))}
															</ol>
														</div>
													}
												</div>
											}
											checked={this.state.hasAgree}
											onChange={this.onChangePennyAgree}
											disabled={!this.state.isDocShown}
										/>
										<FormHelperText classes={{ root: classes.additionalText }}>
											In order to check the box above, you need to open and read the document.
										</FormHelperText>
									</div>
								}
								{submitting ?
									<div className={classes.progress}>
										<CircularProgress
											style={{ color: '#1a96ff' }}
											size={58}
										/>
									</div>
									:
									<div className={classes.button}>
										<Button
											fullWidth
											onClick={this.pennyAgree}
											type="button"
											variant="contained"
											color="primary"
											disabled={!this.state.hasAgree}
										>
											Agree
										</Button>
									</div>
								}
							</div>
						)
				}
			</div>);
	}
}

CloseAccountPennyForm.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	closeAccountData: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CloseAccountPennyForm);
