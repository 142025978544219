import React from 'react';
import PropTypes from 'prop-types';
import {
	ChangeLink,
	Input,
	Checkbox,
	SvgIcon,
} from '@components';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { ACCREDITED_INVESTOR_TOOLTIP_TEXT, QUALIFIED_CLIENT_TOOLTIP_TEXT } from '@utils';

function valueOrEmpty(val) {
	if (val) return val;
	return '';
}

const InvestmentProfilePanel = ({
	profile: {
		InvestmentProfile,
		PendingOrders,
	},
	classes,
	actions,
}) => (
	<React.Fragment>
		<div className={classes.blockTitle}>
                Investment Profile
			<span>
				<ChangeLink
					onClick={actions.showChangeInvestmentProfileModal}
					havePendingOrder={PendingOrders.InvestmentProfile}
				/>
			</span>
		</div>

		<div className={classes.subHeader}>Investment</div>
		<Input placeholder="Goal" showLabel value={valueOrEmpty(InvestmentProfile.Goal)} disabled />
		<Input placeholder="Timeline" showLabel value={valueOrEmpty(InvestmentProfile.Timeline)} disabled />
		<Input placeholder="Knowledge" showLabel value={valueOrEmpty(InvestmentProfile.Experience)} disabled />
		<Input placeholder="Risk Tolerance" showLabel value={valueOrEmpty(InvestmentProfile.RiskTolerance)} disabled />
		<Input placeholder="Liquidity" showLabel value={valueOrEmpty(InvestmentProfile.Liquidity)} disabled />

		<div className={classes.subHeader}>Assets</div>
		<Input placeholder="Liquid Net Worth" showLabel value={valueOrEmpty(InvestmentProfile.Liquid)} disabled />
		<Input placeholder="Total Net Worth" showLabel value={valueOrEmpty(InvestmentProfile.NetWorth)} disabled />
		<Input placeholder="Annual Income" showLabel value={valueOrEmpty(InvestmentProfile.AnnualIncome)} disabled />
		<Input placeholder="Tax Bracket" showLabel value={valueOrEmpty(InvestmentProfile.TaxBracket)} disabled />

		<Checkbox label="Accredited investor" checked={InvestmentProfile.AccreditedInvestor} disabled />
		<Tooltip title={ACCREDITED_INVESTOR_TOOLTIP_TEXT}>
			<IconButton>
				<SvgIcon icon="Help" />
			</IconButton>
		</Tooltip>
		<div className={classes.divider} />
		<Checkbox label="Qualified client" checked={InvestmentProfile.QualifiedClient} disabled />
		<Tooltip title={QUALIFIED_CLIENT_TOOLTIP_TEXT}>
			<IconButton>
				<SvgIcon icon="Help" />
			</IconButton>
		</Tooltip>

	</React.Fragment>
);

InvestmentProfilePanel.propTypes = {
	classes: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default InvestmentProfilePanel;
