export function getPlatformFullName(platform) {
	switch (platform) {
		case 'LIME:MT:USD': return 'FOREX MT4 (USD)';
		case 'LIME:MT:EUR': return 'FOREX MT4 (EUR)';
		case 'LIME:MT:RUB': return 'FOREX MT4 (RUB)';
		case 'LIME:MMA2:USD': return 'MT5 Global (USD)';
		case 'LIME:MMA2:EUR': return 'MT5 Global (EUR)';
		case 'LIME:MMA2:RUB': return 'MT5 Global (RUB)';
		default:
			return platform;
	}
}

export function getAccountProductName(account) {
	switch (account.ProductType) {
		case 'ForexStandart': return 'Forex Standard';
		case 'ForexEcn': return 'Forex ECN';
		case 'MMA2': return 'MT5 Global';
		default:
			return getPlatformFullName(account.Platform);
	}
}
