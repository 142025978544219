import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
	profile,
	signature,
	userAccounts,
	acatDeposit,
} from '@redux';
import compose from 'recompose/compose';
import { env, constants } from '@config';
import { CloseAccountFlow } from '@enums';
import {
	ChangeLink,
	ExpansionPanel,
	Input,
	InputPhoneIconNumber,
	ProgressContainer,
} from '@components';

import {
	InputEditableItem,
	CustomEditableItem,
} from '@shared';
import { createQAAttribute, createInputQAAttributes, scrollTo } from '@global';
import {
	AccountCredentialsPanel,
	ApiKeysPanel,
	EmploymentPanel,
	MarketDataSubscriberPanel,
	MarketDataSubscriptionsPanel,
} from './components';


const styles = theme => ({
	root: {
		border: '1px solid red',
	},
	header: {
		...theme.typography.style.headline1,
		padding: '8px 0',
	},
	subHeader: {
		...theme.typography.style.title,
		color: theme.typography.color.secondary,
	},
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	splitter: {
		height: '30px',
	},
	editable: {
		display: 'flex',
		flexDirection: 'row',
	},
	editItem: {
		display: 'flex',
		padding: '24px 32px 12px 0',
		textAlign: 'right',
	},
	editValue: {
		flexGrow: 2,
	},
	editValuePhone: {
		'& div:first-child': {
			display: 'block!important',
		},
	},
	mr60: {
		marginRight: '60px',
	},
	documentContainer: {
		'&:last-child div': {
			borderBottom: 'none',
		},
	},
	documentItem: {
		...theme.typography.style.body,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 14px',
		borderBottom: '1px solid #deeaef',
		'& div': {
			padding: '10px',
		},
	},
	documentText: {
		color: 'inherit',
		flexGrow: 2,
	},
	closeAccountButton: {
		...theme.typography.style.title,
		color: theme.typography.color.primary,
		margin: '14px 0',
	},
	expansionPanelStaticContent: {
		margin: '10px 24px 5px 0px',
		color: theme.palette.text.secondary,
	},
	expansionPanelContent: {
		'& $expansionPanelRoot': {
			marginLeft: '24px',
		},
		'& $preFieldLabel': {
			marginLeft: '24px',
		},
		'& $noLeftMarginField': {
			marginLeft: '24px',
		},
	},
	preFieldLabel: {},
	noLeftMarginField: {
		marginLeft: '0px',
	},
	withLeftMarginField: {
		marginLeft: '-24px',
		'$expansionPanelContent &': {
			marginLeft: '0px',
		},
	},
	expansionPanelRoot: {
		marginLeft: '0px',
	},
});

function valueOrEmpty(val) {
	if (val) return val;
	return '';
}


const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
	accountStatus: userAccounts.selectors.currentAccountStatus(state),
	userInfo: userAccounts.selectors.userInfo(state),
	accounts: userAccounts.selectors.accounts(state),
	anyActiveAccountExists: userAccounts.selectors.anyActiveAccountExists(state),
	hasOpenAccount: userAccounts.selectors.hasOpenAccount(state),
	currentAccountIsDemo: userAccounts.selectors.currentAccountIsDemo(state),
	currentAccountIsPaper: userAccounts.selectors.currentAccountIsPaper(state),
	closeAccountValidation: acatDeposit.selectors.closeAccountValidation(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		showProfile: () => dispatch(profile.actions.getProfileRequest()),
		showChangeAddressModal: () => {
			const changeAddressAction = profile.actions.showChangeAddressModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAddressAction));
		},
		showChangePostAddressModal: () => {
			const changePostAddressAction = profile.actions.showChangePostAddressModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changePostAddressAction));
		},
		showChangeEmailModal: () => {
			const changeEmailAction = profile.actions.showChangeEmailModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeEmailAction));
		},
		showChangePhoneModal: () => {
			const changePhoneAction = profile.actions.showChangePhoneModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changePhoneAction));
		},
		showChangeDependentsModal: () => {
			const changeAction = profile.actions.showChangeDependentsModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showChangeMaritalStatusModal: () => {
			const changeAction = profile.actions.showChangeMaritalStatusModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showChangeEmploymentModal: () => {
			const changeAction = profile.actions.showChangeEmploymentModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showChangeInvestmentExperienceModal: () => {
			const changeAction = profile.actions.showChangeInvestmentExperienceModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showChangeInvestmentProfileModal: () => {
			const changeAction = profile.actions.showChangeInvestmentProfileModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showChangeTwoFactorAuthorizationModal: () => dispatch(profile.actions.showChangeTwoFactorAuthorizationModal()),
		showCloseAccountModal: () => dispatch(acatDeposit.actions.showCloseAccountModal({ flow: CloseAccountFlow.PROFILE })),
	},
});

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openPanelId: 'profile-account-credentials',
		};
	}

	componentDidUpdate() {
		if (this.props.location.hash) {
			scrollTo(this.props.location.hash.substr(1));
		} else if (this.props.location.search) {
			const parsed = qs.parse(this.props.location.search);
			if (parsed && parsed.commission && parsed.commission.toLowerCase() === 'zero') {
				scrollTo('profile-commission-plan');
			}
		}
	}

	showUpgradeOptionLevelModal = () => {
		const { actions, currentTradeAccount, currentAccountData } = this.props;
		actions.showUpgradeOptionLevelModal(currentTradeAccount, currentAccountData.baseParams.MarginOptionParameters);
	}

	toggleOpen = (open, id) => {
		this.setState({ openPanelId: open ? id : null });
	};

	render() {
		const {
			classes,
			actions,
			accountInfo,
			accountStatus,
			profile: {
				Guid,
				ClientId,
				PersonalInformation,
				InvestmentProfile,
				EmploymentProfile,
				Experiences,
				submitting,
				PendingOrders,
			},
			userInfo,
			form,
			anyActiveAccountExists,
			hasOpenAccount,
			currentAccountIsDemo,
			currentAccountIsPaper,
			closeAccountValidation,
		} = this.props;

		let stocks = Experiences ? Experiences.find(val => val.Type === 'Stocks') : null;
		stocks = stocks ? stocks.Value : '';
		let options = Experiences ? Experiences.find(val => val.Type === 'Option') : null;
		options = options ? options.Value : '';
		let margins = Experiences ? Experiences.find(val => val.Type === 'Margin') : null;
		margins = margins ? margins.Value : '';
		let mutualFunds = Experiences ? Experiences.find(val => val.Type === 'Mutual Funds') : null;
		mutualFunds = mutualFunds ? mutualFunds.Value : '';
		let shortSales = Experiences ? Experiences.find(val => val.Type === 'Short Sale') : null;
		shortSales = shortSales ? shortSales.Value : '';

		const parsed = qs.parse(this.props.location.search);
		const withZero = (parsed && parsed.commission && parsed.commission.toLowerCase() === 'zero');

		const tariffsList = [
			{
				tariff: 'PerTrade',
				icon: 'TariffPerTrade',
				text: 'High quality execution of low price',
				color: 'light',
				sum: '$2.5',
				measureUnit: '/ trade',
			},
			{
				tariff: 'PerShare',
				icon: 'TariffPerShare',
				text: 'Special plan for active traders',
				color: 'dark',
				sum: '$0.001',
				measureUnit: '/ share',
			},
		];
		// hide comission plan section WEBCAB-3948
		if (false && accountInfo.Tariffs && accountInfo.Tariffs.EnableZero &&
				(withZero || (accountInfo.Tariffs.ActiveTariff === 'Zero') || (accountInfo.Tariffs.PendingTariff === 'Zero'))) {
			tariffsList.push({
				tariff: 'Zero',
				icon: 'TariffZero',
				text: 'Potential to lose up to 5% of market price',
				color: 'light',
				sum: '$0',
				measureUnit: '/ trade',
			});
		}

		return (
			<div className={classes.content}>
				<div>
					<div className={classes.header} {...createQAAttribute('title')}>
						My Profile
					</div>
				</div>
				<ProgressContainer submitting={submitting} display="left" disableCheckChildren>
					{/* hide comission plan section WEBCAB-3948 */}
					{/* <ProgressContainer submitting={loading} display="left">
						{accountInfo.Tariffs &&
							<ExpansionPanel
								header="Commission Plan"
								id="profile-commission-plan"
								isOpen
							>
								<Grid container spacing={24}>
									{tariffsList && tariffsList.map((item, i) => (
										<Grid item lg={4} md={4} sm={6} xs={12} key={i}>
											<TariffCard	info={item} />
										</Grid>
									))}
								</Grid>
							</ExpansionPanel>
						}
					</ProgressContainer> */}

					<AccountCredentialsPanel
						classes={classes}
						onToggle={this.toggleOpen}
						panelId="profile-account-credentials"
						isOpen={this.state.openPanelId === 'profile-account-credentials'}
						profile={this.props.profile}
					/>
					<ApiKeysPanel
						classes={classes}
						onToggle={this.toggleOpen}
						panelId="profile-apikeys"
						isOpen={this.state.openPanelId === 'profile-apikeys'}
						profile={this.props.profile}
					/>
					{ (!env.WEBCAB_5839_allow_demo_account || anyActiveAccountExists) &&
					<Fragment>
						<ExpansionPanel
							header="Personal Information"
							id="profile-personal-information"
							onToggleOpen={this.toggleOpen}
							isOpen={this.state.openPanelId === 'profile-personal-information'}
							classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
							qaAttributes={{
								title: createQAAttribute('personal'),
								icon: createQAAttribute('personal_accord'),
							}}
						>
							<Input
								placeholder="Name"
								value={valueOrEmpty(PersonalInformation.Name)}
								showLabel
								disabled
								qaAttributes={createInputQAAttributes('personal_name')}
							/>
							<InputEditableItem
								title="Email"
								value={PersonalInformation.Email}
								editControl={hasOpenAccount &&
									<ChangeLink
										onClick={actions.showChangeEmailModal}
										havePendingOrder={PendingOrders.Email}
										qaAttribute={createQAAttribute('personal_email_change')}
									/>
								}
								qaAttributes={createInputQAAttributes('personal_email')}
							/>
							<CustomEditableItem
								valueControl={
									<Input
										multiline
										style={{ maxWidth: '80%' }}
										placeholder="Address"
										showLabel
										value={valueOrEmpty(PersonalInformation.FullAddress)}
										disabled
										qaAttributes={createInputQAAttributes('personal_address')}
									/>
								}
								editControl={hasOpenAccount &&
									<ChangeLink
										onClick={actions.showChangeAddressModal}
										havePendingOrder={PendingOrders.Address}
										qaAttribute={createQAAttribute('personal_address_change')}
									/>
								}
							/>
							<CustomEditableItem
								valueControl={
									<Input
										multiline
										style={{ maxWidth: '80%' }}
										placeholder="Mailing address"
										showLabel
										value={valueOrEmpty(PersonalInformation.FullPostAddress)}
										disabled
										qaAttributes={createInputQAAttributes('personal_mailing_address')}
									/>
								}
								editControl={hasOpenAccount &&
									<ChangeLink
										onClick={actions.showChangePostAddressModal}
										havePendingOrder={PendingOrders.PostAddress}
										qaAttribute={createQAAttribute('personal_mailing_address_change')}
									/>
								}
							/>
							<CustomEditableItem
								classes={{ editValue: classes.editValuePhone }}
								valueControl={
									<InputPhoneIconNumber
										placeholder="Phone"
										showLabel
										onPhoneChange={() => {}}
										onChange={() => {}}
										value={valueOrEmpty(PersonalInformation.Phone)}
										qaAttributes={createInputQAAttributes('personal_phone')}
										disabled
										hideNumberMode
									/>
								}
								editControl={hasOpenAccount &&
									<ChangeLink
										onClick={actions.showChangePhoneModal}
										havePendingOrder={PendingOrders.Phone}
										qaAttribute={createQAAttribute('personal_phone_change')}
									/>
								}
							/>
							<InputEditableItem
								title="Marital Status"
								value={PersonalInformation.MaritalStatus}
								editControl={hasOpenAccount &&
									<ChangeLink
										onClick={actions.showChangeMaritalStatusModal}
										havePendingOrder={PendingOrders.MaritalStatus}
										qaAttribute={createQAAttribute('personal_marital_status_change')}
									/>
								}
								qaAttributes={createInputQAAttributes('personal_marital_status')}
							/>
							<InputEditableItem
								title="Dependents"
								value={PersonalInformation.Dependents}
								editControl={hasOpenAccount &&
									<ChangeLink
										onClick={actions.showChangeDependentsModal}
										havePendingOrder={PendingOrders.Dependents}
										qaAttribute={createQAAttribute('personal_dependents_change')}
									/>
								}
								qaAttributes={createInputQAAttributes('personal_dependents')}
							/>
							<Input
								placeholder="SSN"
								value={valueOrEmpty(PersonalInformation.Ssn)}
								qaAttributes={createInputQAAttributes('personal_ssn')}
								showLabel
								disabled
							/>
						</ExpansionPanel>
						{ env.WEBCAB_5142_marketData_subscriptions_enable &&
						<Fragment>
							<MarketDataSubscriberPanel
								onToggle={this.toggleOpen}
								panelId="marketdata-subscriber"
								isOpen={this.state.openPanelId === 'marketdata-subscriber'}
							/>
							<MarketDataSubscriptionsPanel
								onToggle={this.toggleOpen}
								panelId="marketdata-subscriptions"
								isOpen={this.state.openPanelId === 'marketdata-subscriptions'}
							/>
						</Fragment>
						}
						<EmploymentPanel
							classes={classes}
							onToggle={this.toggleOpen}
							panelId="profile-employment"
							isOpen={this.state.openPanelId === 'profile-employment'}
							profile={{
								Guid,
								ClientId,
								EmploymentProfile,
								PendingOrders,
							}}
						/>
						<ExpansionPanel
							header={
								<span>
									<span className={classes.mr60} {...createQAAttribute('profile')}>Investment Profile</span>
									<span>
										{ hasOpenAccount &&
											<ChangeLink
												onClick={actions.showChangeInvestmentProfileModal}
												havePendingOrder={PendingOrders.InvestmentProfile}
												qaAttribute={createQAAttribute('profile_change')}
											/>
										}
									</span>
								</span>}
							id="profile-investment-profile"
							onToggleOpen={this.toggleOpen}
							isOpen={this.state.openPanelId === 'profile-investment-profile'}
							classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
							qaAttributes={{ icon: createQAAttribute('profile_accord') }}
						>
							<div className={classes.subHeader} {...createQAAttribute('investment_profile')}>Investment</div>
							<Input
								placeholder="Goal"
								showLabel
								value={valueOrEmpty(InvestmentProfile.Goal)}
								disabled
								qaAttributes={createInputQAAttributes('investment_profile_goal')}
							/>
							<Input
								placeholder="Timeline"
								showLabel
								value={valueOrEmpty(InvestmentProfile.Timeline)}
								disabled
								qaAttributes={createInputQAAttributes('investment_profile_timeline')}
							/>
							<Input
								placeholder="Knowledge"
								showLabel
								value={valueOrEmpty(InvestmentProfile.Experience)}
								disabled
								qaAttributes={createInputQAAttributes('investment_profile_knowledge')}
							/>
							<Input
								placeholder="Risk Tolerance"
								showLabel
								value={valueOrEmpty(InvestmentProfile.RiskTolerance)}
								disabled
								qaAttributes={createInputQAAttributes('investment_profile_risk_tolerance')}
							/>
							<Input
								placeholder="Liquidity"
								showLabel
								value={valueOrEmpty(InvestmentProfile.Liquidity)}
								disabled
								qaAttributes={createInputQAAttributes('investment_profile_liquidity')}
							/>
							<div className={classes.subHeader} {...createQAAttribute('investment_assets')}>Assets</div>
							<Input
								placeholder="Liquid Net Worth"
								showLabel
								value={valueOrEmpty(InvestmentProfile.Liquid)}
								disabled
								qaAttributes={createInputQAAttributes('investment_liquid')}
							/>
							<Input
								placeholder="Total Net Worth"
								showLabel
								value={valueOrEmpty(InvestmentProfile.NetWorth)}
								disabled
								qaAttributes={createInputQAAttributes('investment_net_worth')}
							/>
							<Input
								placeholder="Annual Income"
								showLabel
								value={valueOrEmpty(InvestmentProfile.AnnualIncome)}
								disabled
								qaAttributes={createInputQAAttributes('investment_annual_income')}
							/>
							<Input
								placeholder="Tax Bracket"
								showLabel
								value={valueOrEmpty(InvestmentProfile.TaxBracket)}
								disabled
								qaAttributes={createInputQAAttributes('investment_tax_bracket')}
							/>
						</ExpansionPanel>
						<ExpansionPanel
							header={
								<span>
									<span className={classes.mr60} {...createQAAttribute('investment_experience')}>
										Investment Experience
									</span>
									<span>
										{hasOpenAccount &&
											<ChangeLink
												onClick={actions.showChangeInvestmentExperienceModal}
												havePendingOrder={PendingOrders.InvestmentExperience}
												qaAttribute={createQAAttribute('investment_experience_change')}
											/>}
									</span>
								</span>}
							id="profile-investment-experience"
							onToggleOpen={this.toggleOpen}
							isOpen={this.state.openPanelId === 'profile-investment-experience'}
							classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
							qaAttributes={{ icon: createQAAttribute('investment_experience_accord') }}
						>
							<Input
								placeholder="Stocks"
								showLabel
								value={stocks}
								disabled
								qaAttributes={createInputQAAttributes('investment_experience_stocks')}
							/>
							<Input
								placeholder="Option"
								showLabel
								value={options}
								disabled
								qaAttributes={createInputQAAttributes('investment_experience_options')}
							/>
							<Input
								placeholder="Margin"
								showLabel
								value={margins}
								disabled
								qaAttributes={createInputQAAttributes('investment_experience_margins')}
							/>
							<Input
								placeholder="Mutual Funds"
								showLabel
								value={mutualFunds}
								disabled
								qaAttributes={createInputQAAttributes('investment_experience_mutual_funds')}
							/>
							<Input
								placeholder="Short Sales"
								showLabel
								value={shortSales}
								disabled
								qaAttributes={createInputQAAttributes('investment_experience_short_sales')}
							/>
						</ExpansionPanel>
						{accountStatus &&
						!(accountStatus.IsDisabled || accountStatus.IsClosed) &&
							((env.firm === constants.LIME_FIN && !(currentAccountIsDemo || currentAccountIsPaper)) ||
							(env.firm !== constants.LIME_FIN)) && (
								closeAccountValidation.submitting ?
									<CircularProgress
										style={{ color: '#1a96ff' }}
										size={18}
									/> :
									<div className={classes.closeAccountButton}>
										<ChangeLink
											onClick={actions.showCloseAccountModal}
											activeLinkText="Close Account"
											inactiveLinkText="Close account request in progress"
											havePendingOrder={
												accountInfo && accountInfo.PendingOrders && accountInfo.PendingOrders.CloseAccount
											}
											qaAttribute={createQAAttribute('close')}
										/>
									</div>
							)
						}
					</Fragment>
					}
				</ProgressContainer>
			</div>
		);
	}
}

Profile.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	accountInfo: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	userInfo: PropTypes.object.isRequired,
	currentAccountIsDemo: PropTypes.bool,
	currentAccountIsPaper: PropTypes.bool,
	anyActiveAccountExists: PropTypes.bool.isRequired,
	hasOpenAccount: PropTypes.bool.isRequired,
	closeAccountValidation: PropTypes.object.isRequired,
};

Profile.defaultProps = {
	currentAccountIsDemo: false,
	currentAccountIsPaper: false,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(Profile);
