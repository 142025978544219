import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { profile } from '@redux';

const HubSpotChat = (props) => {
	const userProfile = useSelector(profile.selectors.profile);
	const email = (userProfile.PersonalInformation && userProfile.PersonalInformation.Email) || '';
	const token = userProfile.HubSpotChatToken || '';

	useEffect(() => {
		const HubSpotConversationsReady = () => {
			window.hsConversationsSettings = {
				loadImmediately: false,
				identificationEmail: email,
				identificationToken: token,
			};
			const status = window.HubSpotConversations.widget.status();
			if (status.loaded) {
				window.HubSpotConversations.widget.refresh();
			} else if (!userProfile.submitting) {
				window.HubSpotConversations.widget.load();
			}
		};
		if (window.HubSpotConversations) {
			HubSpotConversationsReady();
		} else {
			window.hsConversationsOnReady = [HubSpotConversationsReady];
		}
	}, [email, token, userProfile.submitting]);
	useEffect(() => {
		return () => {
			const HubSpotConversationsReady = () => {
				window.HubSpotConversations.widget.remove();
				window.HubSpotConversations.clear();
			};
			if (window.HubSpotConversations) {
				HubSpotConversationsReady();
			} else {
				window.hsConversationsOnReady = [HubSpotConversationsReady];
			}
		};
	}, []);
	return (
		<React.Fragment />
	);
};


export default HubSpotChat;
