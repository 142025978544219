import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import {
	FormCheckbox,
	FormRadioGroup,
	FormInputNumber,
	FormSelect,
	FormInput,
	Divider,
	Button,
	Radio,
	R,
} from '@components';
import { translate } from '@global';
import { userAccounts, app } from '@redux';
import { template } from '@templates';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	radioInputItem: {
		paddingLeft: '50px',
	},
	inputItem: {
		paddingLeft: '50px',
		'& div > div': {
			background: theme.palette.color.primary.attestationBackground,
		},
	},
	amountInputItem: {
		paddingLeft: '50px',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '20px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	preFieldLabel: {
		margin: '10px 24px 0px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	staticText: {
		margin: '10px 24px 10px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	fieldLeftAdditionalMargin: {
		marginLeft: '24px',
	},
	notesHeader: {
		fontSize: '18px',
	},
	notesText: {
		marginTop: '40px',
	},
	mb: {
		marginBottom: '24px',
	},
});

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const currentAccountDisplayFullName = userAccounts.selectors.currentAccountDisplayFullName(state);
	const language = app.selectors.language(state);
	const platform = userAccounts.selectors.accounts(state)
		.find(x => x.TradeCode === currentTradeAccount.tradeCode && x.ClearingFirm === currentTradeAccount.clearerFirm)
		.Platform;
	const initialValues = {
		NonOrderData: {
			ConfirmationCode: null,
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
		EMoneyOut: {
			SelectedAccount: `${currentTradeAccount.tradeCode};${platform}`,
			IsAllOut: false,
			IsCrypto: true,
			Urgently: false,
			Summ: 0,
			Currency: 'USD',
			AccountCurrency: 'MYR',
			Comment: '',
			Reason: 'MONEY:OUT:REASON:NEED_MONEY',
			ReasonComment: '',
			SelectTarget: 'MALAYSIANBANK',
			TargetAccount: '',
		},
	};
	const EMoneyOut = formValueSelector(ownProps.form)(state, 'EMoneyOut') || initialValues.EMoneyOut;
	return {
		initialValues,
		EMoneyOut,
		currentTradeAccount,
		currentAccountDisplayFullName,
		language,
	};
};

class CYWithdrawalMalaysianBankAmountForm extends React.Component {
	// eslint-disable-next-line no-unused-vars
	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};
		if (!values) {
			return errors;
		}

		if (values.EMoneyOut) {
			errors.EMoneyOut = {};

			if (!values.EMoneyOut.Summ) {
				errors.EMoneyOut.Summ = 'required';
			}
			if (!values.EMoneyOut.BankName) {
				errors.EMoneyOut.BankName = 'required';
			}
			if (!values.EMoneyOut.BeneficiaryName) {
				errors.EMoneyOut.BeneficiaryName = 'required';
			}
			if (!values.EMoneyOut.TargetAccount) {
				errors.EMoneyOut.TargetAccount = 'required';
			}
		}
		if (values.NonOrderData) {
			errors.NonOrderData = {};
			if (!values.NonOrderData.ConfirmAccountOwner) {
				errors.NonOrderData.ConfirmAccountOwner = 'required';
			}
			if (!values.NonOrderData.ConfirmDataCorrect) {
				errors.NonOrderData.ConfirmDataCorrect = 'required';
			}
		}

		return errors;
	}

	render() {
		const {
			classes,
			handleSubmit,
			theme,
			currentTradeAccount,
			currentAccountDisplayFullName,
			EMoneyOut,
			...props
		} = this.props;


		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field component={FormSelect} name="NonOrderData.TradeCode" label={translate('common_account', 'Account')} disabled>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentAccountDisplayFullName}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<div className={classes.divider}>
					<Divider />
				</div>
				<div className={classes.radioInputItem}>
					<p className={classes.preFieldLabel}>
						<R id="moneyout_emoney_currency_title" defaultValue="Currency of your wallet/account/card" />
					</p>
					<div className={classes.fieldLeftAdditionalMargin}>
						<Field
							name="EMoneyOut.Currency"
							orientation="horizontal"
							component={FormRadioGroup}
						>
							<Radio value={EMoneyOut.Currency} label={EMoneyOut.Currency} />)
						</Field>
					</div>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="EMoneyOut.TargetAccount"
						placeholder={translate('orders_targetaccount_malaysianbank', 'Beneficiary Account')}
						label={translate('orders_targetaccount_malaysianbank', 'Beneficiary Account')}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="EMoneyOut.BeneficiaryName"
						placeholder={translate('shared_beneficiary_name_title', 'Beneficiary name')}
						label={translate('shared_beneficiary_name_title', 'Beneficiary name')}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="EMoneyOut.BankName"
						placeholder={translate('shared_bank_name_title', 'Bank name')}
						label={translate('shared_bank_name_title', 'Bank name')}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="EMoneyOut.Comment"
						placeholder={translate('shared_comment', 'Comment')}
						label={translate('shared_comment', 'Comment')}
						multiline
					/>
				</div>
				<div className={classNames(classes.amountInputItem, classes.mb)}>
					<div className={classes.row}>
						<Field
							component={FormInputNumber}
							name="EMoneyOut.Summ"
							placeholder={translate('common_enter_the_amount_here', 'Enter the Amount here')}
							label={translate('moneyout_amount', 'Transfer amount')}
							allowIconButtons={false}
							prefix=""
						/>
						<div className={classes.notesHeader}>
							<h1>{EMoneyOut.Currency}</h1>
						</div>
					</div>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormCheckbox}
						name="NonOrderData.ConfirmAccountOwner"
						label={<R
							id="common_confirm_account_owner"
							defaultValue="I confirm that the monetary funds shall be transferred to my personal bank account."
						/>}
					/>
				</div>
				<div className={classNames(classes.inputItem, classes.mb)}>
					<Field
						component={FormCheckbox}
						name="NonOrderData.ConfirmDataCorrect"
						label={<R
							id="common_moneyout_confirm_data_correct"
							defaultValue="I confirm that all the details specified in the order are true."
						/>}
					/>
				</div>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					disabled={props.invalid || props.submitting}
				>
					{props.submitting ?
						<CircularProgress
							style={{ color: theme.typography.color.white }}
							size={18}
						/>
						: <R id="common_btn_continue" defaultValue="Continue" />
					}
				</Button>
				<div className={classes.notesText}>
					<div className={classes.notesHeader}>
						<R id="important_notes_header" defaultValue="Important Notes" />
					</div>
					<R
						id="orders_important_note_malaysianbank"
						// eslint-disable-next-line max-len
						defaultValue="<ul><li>Withdrawal requests are processed within an hour.</li><li>Pay attention to the fact that on US and European exchanges there is a concept of “settlement date” which means the date on which the trade settles and ultimate transfer of cash and securities occurs. Therefore withdrawal of available balance can be only made after the settlement date comes, i.e. on 3rd (Xetra) or 4th (USA) working day.</li></ul>"
						enableHtml
					/>
				</div>
			</form>
		);
	}
}

CYWithdrawalMalaysianBankAmountForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountDisplayFullName: PropTypes.string.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		form: 'CYWithdrawalMalaysianBankAmountForm',
		validate: CYWithdrawalMalaysianBankAmountForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
	}),
)(CYWithdrawalMalaysianBankAmountForm);
