import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormValues, Field } from 'redux-form';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
	FormInput,
	FormRadioGroup,
	Radio,
} from '@components';

const useStyles = makeStyles(theme => {
	const headerQuestionColor = '#345464';

	return createStyles({
		editValue: {},
		editable: {},
		preFieldLabel: {
			margin: '10px 24px 0px 0px',
			lineHeight: '21px',
			fontSize: '18px',
			color: headerQuestionColor,
		},
		withLeftMarginField: {
			marginLeft: '-24px',
			'& input': {
				background: theme.palette.color.primary.attestationBackground,
				borderRadius: '8px'
			}
		},
		noLeftMarginField: {
			marginLeft: '0px',
		},
		header: {
			color: headerQuestionColor,
			lineHeight: '28px',
			fontSize: '18px',
		}
	})
});
const extractPropsClasses = ({
	editValue, editable, preFieldLabel, withLeftMarginField, noLeftMarginField,
}) => ({
	editValue, editable, preFieldLabel, withLeftMarginField, noLeftMarginField,
});

const PersonalInformationQuestion = ({
	classes: _classes,
	form,
}) => {
	const classes = useStyles({ classes: extractPropsClasses(_classes) });
	const values = useSelector(state => getFormValues(form)(state)) || {};

	return (
		<React.Fragment>
			<p className={classes.header}>Affiliation</p>
			<div className={classes.editable}>
				<div className={classes.editValue}>
					<p className={classes.preFieldLabel}>
						Indicate whether you or an immediate family member, or other person who supports you or who you support to a material extent, or an additional account holder, is an employee of a broker/dealer or other member of the Financial Industry Regulatory Authority (FINRA).
					</p>
					<div className={classes.noLeftMarginFiled}>
						<Field
							name="WorksForAnotherBroker"
							orientation="horizontal"
							component={FormRadioGroup}
							validationMessageMode="withErrorMessage"
						>
							<Radio value="Yes" label="Yes" />
							<Radio value="No" label="No" />
						</Field>
					</div>
					{values.WorksForAnotherBroker === 'Yes' &&
						<div className={classes.withLeftMarginField}>
							<Field
								name="IndAssociatedPerson"
								component={FormInput}
								placeholder="Please explain"
							/>
						</div>
					}
				</div>
			</div>
		</React.Fragment>
	);
};

PersonalInformationQuestion.propTypes = {
	classes: PropTypes.object.isRequired,
	form: PropTypes.string.isRequired,
};

export default PersonalInformationQuestion;
