/* eslint-disable import/extensions */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { env } from '@config';
import { auth } from '@redux';


const CheckAuth = () => {
	const theme = useTheme();
	const dispatch = useDispatch();

	useEffect(() => {
		if (env.useLocalAuth) {
			dispatch(auth.actions.checkAuthRequest());
		}
	}, []);

	return (
		<CircularProgress
			style={{ color: theme.typography.color.primary }}
			size={58}
		/>
	);
};

export default CheckAuth;
