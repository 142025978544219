import React, { useState } from 'react';
import { change, Field, formValueSelector, reduxForm, touch } from 'redux-form';
import { push } from 'connected-react-router';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { env } from '@config';
import { profile, signature, userAccounts } from '@redux';
import {
	A,
	Button,
	Divider,
	FormCheckbox,
	FormRadioGroup,
	LimeLogo,
	Radio,
	Select,
	ChangeLink,
} from '@components';
import { connect, useDispatch } from 'react-redux';
import { INVESTMENT_TIMELINE_RANGES } from '@utils';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '72px',
		height: '60px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	divider: {
		marginTop: '30px',
	},
	inputSelectItem: {
		marginTop: '10px',
		paddingLeft: '32px',
	},
	labelText: {
		margin: '18px 0',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	justify: {
		textAlign: 'justify',
	},
	additionalText: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
});


function valueOrEmpty(val) {
	if (val) return val;
	return '';
}

const UPGRADE_OPTION_LEVEL_FORM = 'UpgradeOptionLevelForm';
const formSelector = formValueSelector(UPGRADE_OPTION_LEVEL_FORM);

const AgreementField = ({
	name,
	href,
	docName,
}) => {
	const [docShown, setDocShown] = useState(false);
	const dispatch = useDispatch();
	const handleDocClick = () => {
		setDocShown(true);
		dispatch(change(UPGRADE_OPTION_LEVEL_FORM, name, true));
	};

	return (
		<Field
			component={FormCheckbox}
			name={name}
			label={
				<p>
						I confirm that I read and agree with terms and conditions of the &nbsp;
					<A
						target="_blank"
						href={href}
						onClick={handleDocClick}
					>
						{docName}
					</A>


				</p>
			}
			disabled={!docShown}
		/>
	);
};


const mapStateToProps = (state) => {
	const currentAccountData = userAccounts.selectors.currentAccountData(state);
	let upgradeOptionLevelData = userAccounts.selectors.upgradeOptionLevelData(state);
	if (!upgradeOptionLevelData.tradeAccount) {
		upgradeOptionLevelData = {
			tradeAccount: userAccounts.selectors.currentTradeAccount(state),
			marginParams: currentAccountData.baseParams.MarginOptionParameters,
		};
	}

	const isVision = upgradeOptionLevelData.tradeAccount.clearerFirm === 'vision';
	const isCor = upgradeOptionLevelData.tradeAccount.clearerFirm === 'cor';

	return {
		initialValues: {
			TradeCode: upgradeOptionLevelData.tradeAccount.tradeCode,
			Clearer: upgradeOptionLevelData.tradeAccount.clearerFirm,
			NewOptionLevel: valueOrEmpty(upgradeOptionLevelData.marginParams.OptionLevel),
		},
		isVision,
		isCor,
		selectedOptionLevel: formSelector(state, 'NewOptionLevel'),
		upgradeOptionLevelData,
		currentAccountData: userAccounts.selectors.currentAccountData(state),
		profile: profile.selectors.profile(state),
		currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
		accountInfo: userAccounts.selectors.accountInfo(state),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		showChangeInvestmentProfileModal: () => {
			const changeAction = profile.actions.showChangeInvestmentProfileModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showChangeInvestmentExperienceModal: () => {
			const changeAction = profile.actions.showChangeInvestmentExperienceModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showUpgradeAccountModal: (tradeAccount, marginParams) => {
			const changeAction = userAccounts.actions.showUpgradeAccountModal({ tradeAccount, marginParams });
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		setCurrentPage: page => dispatch(push(page)),
		reduxFormTouch: fieldname => dispatch(touch(UPGRADE_OPTION_LEVEL_FORM, fieldname)),
		goToCashManagement: () => dispatch(push('/dashboard/cashmanagement')),
	},
});

const NONE_VALUE = INVESTMENT_TIMELINE_RANGES.NONE.value.toLowerCase();
const LESS_THAN_A_YEAR_VALUE = INVESTMENT_TIMELINE_RANGES.LESS_THAN_A_YEAR.value.toLowerCase();
const SIX_TO_TEN_YEARS_VALUE = INVESTMENT_TIMELINE_RANGES.SIX_TO_TEN_YEARS.value.toLowerCase();
const OVER_TEN_YEARS_VALUE = INVESTMENT_TIMELINE_RANGES.OVER_TEN_YEARS.value.toLowerCase();

class UpgradeOptionLevelForm extends React.Component {
	static validate(values, props) {
		const errors = {};
		const {
			selectedOptionLevel,
			upgradeOptionLevelData: {
				marginParams,
			},
			profile: {
				Documents,
			},
		} = props;
		const curLevel = Number.parseInt(marginParams.OptionLevel, 10);
		const noChanges = marginParams.OptionLevel === selectedOptionLevel;
		const upgradeLevel = !noChanges && (Number.isNaN(curLevel) || curLevel < Number.parseInt(selectedOptionLevel, 10));
		const needOpraDataAgreement = !(Documents && Documents.some(d => d.DocTypeExt === 'opra'));

		const { isVision } = props;
		if (!values.NewOptionLevel) {
			errors.NewOptionLevel = 'required';
		}
		if (!values.HasOptionsAgree) {
			errors.HasOptionsAgree = 'required';
		}
		if (needOpraDataAgreement && !values.HasOpraDataAgree) {
			errors.HasOpraDataAgree = 'required';
		}

		if (!values.HasRisksofStandardizedOptionsAgree && upgradeLevel) {
			errors.HasRisksofStandardizedOptionsAgree = 'required';
		}

		if (isVision && !values.HasOptionsRiskAgree && upgradeLevel) {
			errors.HasOptionsRiskAgree = 'required';
		}

		let errs = [];

		errs = UpgradeOptionLevelForm.visionValidate(values.NewOptionLevel, props);

		if (errs && errs.length > 0) {
			errors.NewOptionLevel =
			(<ul>{'Minimum requirements are not met. They need to upgrade:'}<br /><br />{errs.length > 1
				? errs.map((err, index) => <li key={index}>{err}</li>)
				: errs[0]
			}</ul>);
		}

		return errors;
	}

	static createLink(title, onClickFunc, isInactive) {
		return <div>{ title }<br /><ChangeLink onClick={onClickFunc} havePendingOrder={isInactive} /></div>;
	}

	static visionValidate(newOptionLevel, props) {
		const result = [];
		const {
			goToCashManagement,
			showChangeInvestmentProfileModal,
			showChangeInvestmentExperienceModal,
			showUpgradeAccountModal,
		} = props.actions;

		const {
			currentTradeAccount,
			currentAccountData: { baseParams },
			profile: {
				PendingOrders: pendingOrders,
				Experiences,
				InvestmentProfile,
			},
		} = props;


		const investmentProfile = InvestmentProfile;
		const ExperienceOption = Experiences.find(x => x.Type === 'Option');
		const experienceOptionValue = ExperienceOption && ExperienceOption.Value && ExperienceOption.Value.toLowerCase();
		const showUpgradeAccountForm = () =>
			showUpgradeAccountModal(currentTradeAccount, baseParams.MarginOptionParameters);

		const marginType = baseParams && baseParams.MarginOptionParameters && baseParams.MarginOptionParameters.MarginType;

		const isChangeInvestmentProfileInactive = pendingOrders && pendingOrders.InvestmentProfile;
		const isChangeInvestmentExperienceInactive = pendingOrders && pendingOrders.InvestmentExperience;
		const isUpgradeAccountFormInactive = pendingOrders && pendingOrders.UpgradeMarginType;

		switch (newOptionLevel) {
			case '0':
				break;
			case '1':
				if (baseParams && baseParams.EquityValue < 1000) {
					result.push(this.createLink('Minimum Account Equity: $1,000', goToCashManagement));
				}
				break;
			case '2':
				if (!investmentProfile || !investmentProfile.Liquid || investmentProfile.Liquid === 'Under $50,000') {
					result.push(this.createLink(
						'Minimum Liquid Net Worth: $50,000',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (baseParams && baseParams.EquityValue < 2000) {
					result.push(this.createLink('Minimum Account Equity: $2,000', goToCashManagement));
				}
				if (!investmentProfile || !investmentProfile.RiskTolerance || investmentProfile.RiskTolerance === 'Low') {
					result.push(this.createLink(
						'Risk Tolerance: Moderate/Aggressive/Speculative',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (!investmentProfile || !investmentProfile.Goal || investmentProfile.Goal === 'Preservation') {
					result.push(this.createLink(
						'Investment Objectives: Income/Capital Appreciation/Speculation',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				break;
			case '3':
				if (!investmentProfile || !investmentProfile.Liquid || investmentProfile.Liquid === 'Under $50,000') {
					result.push(this.createLink(
						'Minimum Liquid Net Worth: $50,000',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (baseParams && baseParams.EquityValue < 5000) {
					result.push(this.createLink('Minimum Account Equity: $5,000', goToCashManagement));
				}
				if (!investmentProfile || !investmentProfile.RiskTolerance || investmentProfile.RiskTolerance !== 'Speculative') {
					result.push(this.createLink(
						'Risk Tolerance: Speculative',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (!investmentProfile || !investmentProfile.Goal || investmentProfile.Goal !== 'Speculation') {
					result.push(this.createLink(
						'Investment Objectives: Speculation',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (!experienceOptionValue || experienceOptionValue === NONE_VALUE || experienceOptionValue === LESS_THAN_A_YEAR_VALUE) {
					result.push(this.createLink(
						'Minimum Option Trading Experience: 1 to 5 Years',
						showChangeInvestmentExperienceModal,
						isChangeInvestmentExperienceInactive,
					));
				}
				if (!marginType || marginType === 'Cash') {
					result.push(this.createLink(
						'Margin Type: Margin/DayTrader',
						showUpgradeAccountForm,
						isUpgradeAccountFormInactive,
					));
				}
				break;
			case '4':
				if (!investmentProfile || !investmentProfile.Liquid || investmentProfile.Liquid === 'Under $50,000' ||
					investmentProfile.Liquid === '$50,000 - $149,999') {
					result.push(this.createLink(
						'Minimum Liquid Net Worth: $150,000',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (baseParams && baseParams.EquityValue < 25000) {
					result.push(this.createLink('Minimum Account Equity: $25,000', goToCashManagement));
				}
				if (!investmentProfile || !investmentProfile.RiskTolerance || investmentProfile.RiskTolerance !== 'Speculative') {
					result.push(this.createLink(
						'Risk Tolerance: Speculative',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (!investmentProfile || !investmentProfile.Goal || investmentProfile.Goal !== 'Speculation') {
					result.push(this.createLink(
						'Investment Objectives: Speculation',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (!experienceOptionValue || experienceOptionValue === NONE_VALUE || experienceOptionValue === LESS_THAN_A_YEAR_VALUE) {
					result.push(this.createLink(
						'Minimum Option Trading Experience: 1 to 5 Years',
						showChangeInvestmentExperienceModal,
						isChangeInvestmentExperienceInactive,
					));
				}
				if (!marginType || marginType === 'Cash') {
					result.push(this.createLink('Margin Type: Margin/DayTrader', showUpgradeAccountForm, isUpgradeAccountFormInactive));
				}
				break;
			case '5':
				if (!investmentProfile || !investmentProfile.Liquid || investmentProfile.Liquid === 'Under $50,000' ||
					investmentProfile.Liquid === '$50,000 - $149,999') {
					result.push(this.createLink(
						'Minimum Liquid Net Worth: $150,000',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (baseParams && baseParams.EquityValue < 100000) {
					result.push(this.createLink('Minimum Account Equity: $100,000', goToCashManagement));
				}
				if (!investmentProfile || !investmentProfile.RiskTolerance || investmentProfile.RiskTolerance !== 'Speculative') {
					result.push(this.createLink(
						'Risk Tolerance: Speculative',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (!investmentProfile || !investmentProfile.Goal || investmentProfile.Goal !== 'Speculation') {
					result.push(this.createLink(
						'Investment Objectives: Speculation',
						showChangeInvestmentProfileModal,
						isChangeInvestmentProfileInactive,
					));
				}
				if (experienceOptionValue !== SIX_TO_TEN_YEARS_VALUE && experienceOptionValue !== OVER_TEN_YEARS_VALUE) {
					result.push(this.createLink(
						'Minimum Option Trading Experience: 6 to 10 Years',
						showChangeInvestmentExperienceModal,
						isChangeInvestmentExperienceInactive,
					));
				}
				if (!marginType || marginType === 'Cash') {
					result.push(this.createLink('Margin Type: Margin/DayTrader', showUpgradeAccountForm, isUpgradeAccountFormInactive));
				}
				break;
			default:
				result.push(`Receive unsupported option level = ${newOptionLevel}`);
				break;
		}
		return result;
	}

	constructor(props) {
		super(props);

		this.allOptionLevels = {
			cor: [
				{ value: '0', text: ['No option trading allowed'], level: 0 },
				{ value: '1', text: ['Writing Covered Calls'], level: 1 },
				{ value: '2', text: ['Buying Puts & Calls'], level: 2 },
				{
					value: '3', text: ['Combinations (Spreads)'], level: 3,
				},
				{ value: '4', text: ['Writing Covered & Uncovered Equity Puts'], level: 4 },
				{
					value: '5', text: ['Writing Covered & Uncovered Equity Calls'], level: 5, disabled: false,
				},
			],
			vision: [
				{ value: '0', text: ['No option trading allowed'], level: 0 },
				{ value: '1', text: ['Covered writing of equity calls'], level: 1 },
				{ value: '2', text: ['Purchase of equity calls and puts', 'Purchase of equity straddles or combinations'], level: 2 },
				{
					value: '3', text: ['Equity spreads', 'Covered writing of equity puts'], level: 3,
				},
				{ value: '4', text: ['Uncovered writing of equity puts'], level: 4 },
				{
					value: '5', text: ['Uncovered writing of straddles or combinations'], level: 5, disabled: false,
				},
			],
		};
	}

	onChangeOptionLevel = () => {
		const { actions } = this.props;
		actions.reduxFormTouch('NewOptionLevel');
	}

	getOptionLevels = () => {
		const {
			isCor,
			isVision,
			upgradeOptionLevelData: {
				tradeAccount,
			},
		} = this.props;

		if (!isVision && !isCor) {
			// for other clearing firms do logic as in vision
			return this.allOptionLevels.vision;
		}

		return this.allOptionLevels[tradeAccount && tradeAccount.clearerFirm];
	}

	render() {
		const {
			classes,
			isCor,
			isVision,
			invalid,
			submitting,
			handleSubmit,
			selectedOptionLevel,
			upgradeOptionLevelData: {
				tradeAccount,
				marginParams,
			},
			profile: {
				Documents,
			},
		} = this.props;

		const curLevel = this.getOptionLevels().find(item => item.value === marginParams.OptionLevel);
		const noChanges = marginParams.OptionLevel === selectedOptionLevel;
		const upgradeLevel = !noChanges && (!curLevel || curLevel.level < Number.parseInt(selectedOptionLevel, 10));
		const needOpraDataAgreement = !(Documents && Documents.some(d => d.DocTypeExt === 'opra'));

		return (
			<div className={classes.root}>
				<div className={classes.row}>
					<div className={classes.logo}>
						<LimeLogo />
					</div>
					<Select value={tradeAccount && tradeAccount.tradeCode} label="Trade Account" disabled>
						<MenuItem value={tradeAccount && tradeAccount.tradeCode}>
							<div className={classes.inputContainer}>
								<div className={classes.account}>{tradeAccount && tradeAccount.tradeCode}</div>
							</div>
						</MenuItem>
					</Select>
				</div>

				<div className={classes.divider}>
					<Divider />
				</div>

				<form
					onSubmit={handleSubmit(userAccounts.actions.upgradeOptionLevelForm)}
					className={classes.root}
				>
					<p className={classes.justify}>
						You are about to request an update to your account&apos;s option level.<br />
						Once approved, this change will take effect the next trading day.
					</p>
					<Field
						fullWidth
						name="NewOptionLevel"
						orientation="vertical"
						component={FormRadioGroup}
						onChange={this.onChangeOptionLevel}
					>
						{this.getOptionLevels().map(item => (
							<Radio
								classes={{ label: classes.accItem, root: classes.item }}
								key={item.value}
								value={item.value}
								label={
									<div className={classes.labelText}>
										Level {item.value}
										<ul className={classes.additionalText}>
											{item.level > 1 &&
												<li>Level {item.level - 1} plus</li>
											}
											{item.text.map((txt, index) => <li key={`level_${item.level}_${index}`}>{txt}</li>)}
										</ul>
									</div>
								}
								disabled={item.disabled || !curLevel}
							/>
						))}
					</Field>

					<div className={classes.divider} />

					{ isVision && !noChanges &&
						<AgreementField
							name="HasOptionsRiskAgree"
							href={env.visionOptionsSupplementUrl}
							docName="Options Agreement"
						/>
					}

					{ isCor && !noChanges &&
						<AgreementField
							name="HasOptionsRiskAgree"
							href="/static/cor/cor_option_agreement.pdf"
							docName="Options Agreement"
						/>
					}

					{(isVision || isCor) && upgradeLevel &&
						<AgreementField
							name="HasOptionsAgree"
							href={env.uncoveredWrtingRiskDisclosureUrl}
							docName="Options Risk Disclosure"
						/>
					}
					{upgradeLevel &&
						<AgreementField
							name="HasRisksofStandardizedOptionsAgree"
							href={env.characteristicsRisksStandardizedOptionsUrl}
							docName="Characteristics&RisksofStandardizedOptions"
						/>
					}
					{!noChanges && needOpraDataAgreement &&
						<AgreementField
							name="HasOpraDataAgree"
							href="/static/shared/OPRAdataAgreement.pdf"
							docName="OPRA Data Agreement"
						/>
					}
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={invalid || submitting || noChanges}
						>
							{submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								: 'Apply'
							}
						</Button>
					</div>

				</form>
			</div>
		);
	}
}

UpgradeOptionLevelForm.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	isCor: PropTypes.bool.isRequired,
	isVision: PropTypes.bool.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	selectedOptionLevel: PropTypes.string,
	upgradeOptionLevelData: PropTypes.object.isRequired,
};

UpgradeOptionLevelForm.defaultProps = {
	selectedOptionLevel: null,
};


export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: UPGRADE_OPTION_LEVEL_FORM,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		validate: UpgradeOptionLevelForm.validate,
	}),
)(UpgradeOptionLevelForm);
