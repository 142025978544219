import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { profile } from '@redux';
import GenericTextAlert from './GenericTextAlert';


const mapDispatchToProps = dispatch => ({
	actions: {
		showModal: () => dispatch(profile.actions.showEnableThirdPartyMarketDataModal()),
	},
});

class MarketDataDisabledAlert extends React.Component {
	render() {
		const {
			actions,
		} = this.props;
		return (
			<React.Fragment>
				<GenericTextAlert
					text={'Please sign the third party market data statement to enable trading.'}
					buttonText="Sign"
					onClick={actions.showModal}
				/>
			</React.Fragment>
		);
	}
}

export default compose(connect(null, mapDispatchToProps))(MarketDataDisabledAlert);
