import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	A,
	TabContainer,
	FormCheckbox,
	FormSelect,
	FormDatePicker,
	FormInput,
	FormInputNumber,
	Button,
	SelectField,
	ProgressContainer,
} from '@components';
import { profile } from '@redux';
import { constants } from '@config';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '14px',
		'& > div > div': {
			background: theme.palette.color.primary.attestationBackground,
			borderRadius: '8px',
		},
	},
	dateItem: {
		marginTop: '14px',
		'& > div': {
			background: theme.palette.color.primary.attestationBackground,
			borderRadius: '8px',
		},
		'& > div > div': {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},
	item: {
		marginTop: '30px',
	},
	infoText: {
		marginTop: '30px',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
});

const addressStr = addr => addr && [
	addr.House,
	addr.StreetAddress,
	addr.Building,
	addr.Apartment ? `#${addr.Apartment}` : null,
].filter(x => !!x).join(', ');
const cityStr = addr => addr && [
	addr.City,
	addr.ZipCode,
].filter(x => !!x).join(', ');

const TAX_FORM_FILLIN_FORM = 'taxFormFillInForm';
const mapStateToProps = (state, ownProps) => {
	const formSelector = formValueSelector(ownProps.form || TAX_FORM_FILLIN_FORM);
	const personalInformation = profile.selectors.profile(state).PersonalInformation;
	const countries = profile.selectors.getCountries(state);
	return {
		getFormValue: fieldName => formSelector(state, fieldName),
		initialValues: {
			Name: personalInformation.Name,
			DateOfBirth: personalInformation.DateOfBirth,
			Citizenship: personalInformation.CitizenshipCountry,
			ResidenceStreet: addressStr(personalInformation.Address),
			ResidenceCity: cityStr(personalInformation.Address),
			ResidentialCountry: personalInformation.Address && personalInformation.Address.Country,
			MailingAddress: addressStr(personalInformation.PostAddress),
			MailingCity: cityStr(personalInformation.PostAddress),
			MailingCountry: personalInformation.PostAddress && personalInformation.PostAddress.Country,
			TaxID: personalInformation.Ftin,
			TaxCountry: personalInformation.Address && personalInformation.Address.Country,
			UseMailingAddressValue: false,
			UseSSN: false,
			UseTaxCountry: true,
		},
		countries,
		loading: !(countries && !profile.submitting),
	};
};
const mapDispatchToProps = dispatch => ({
	actions: {
		refreshCountriesList: () => dispatch(profile.actions.getCountries.request()),
	},
});

const parseMomentDate = (d) => {
	if ((typeof d) === 'object') {
		return d.toISOString();
	}
	return undefined;
};

const numValue = (v) => {
	const res = Number(v);
	if (!Number.isNaN(res)) {
		return res;
	}
	return v;
};

class TaxFormFillInTabComponent extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values) {
			return errors;
		}
		if (!values.Citizenship) {
			errors.Citizenship = 'Required';
		}
		if (!values.ResidentialCountry) {
			errors.ResidentialCountry = 'Required';
		}
		if (!values.TaxID) {
			if (values.ResidentialCountry === 'RU' || !values.NoTINReason) {
				errors.TaxID = 'Required';
			}
		} else if (values.ResidentialCountry === 'RU' && !(/^(\d{12})$/.test(values.TaxID))) {
			errors.TaxID = 'Invalid number';
		} else if (values.TaxID.length > constants.maxCharsNumber) {
			errors.TaxID = constants.maxCharsNumberError;
		}
		if (!values.ResidenceCity) {
			errors.ResidenceCity = 'Required';
		} else if (values.ResidenceCity.length > constants.maxCharsNumber) {
			errors.ResidenceCity = constants.maxCharsNumberError;
		}
		if (!values.ResidenceStreet) {
			errors.ResidenceStreet = 'Required';
		} else if (values.ResidenceStreet.length > constants.maxCharsNumber) {
			errors.ResidenceStreet = constants.maxCharsNumberError;
		}

		if (values.UseMailingAddress && !values.MailingCountry) {
			errors.MailingCountry = 'Required';
		}
		if (values.UseMailingAddress) {
			if (!values.MailingCity) {
				errors.MailingCity = 'Required';
			} else if (values.MailingCity.length > constants.maxCharsNumber) {
				errors.MailingCity = constants.maxCharsNumberError;
			}
		}
		if (values.UseMailingAddress) {
			if (!values.MailingAddress) {
				errors.MailingAddress = 'Required';
			} else if (values.MailingAddress.length > constants.maxCharsNumber) {
				errors.MailingAddress = constants.maxCharsNumberError;
			}
		}
		if (values.UseSSN) {
			if (!values.SSN) {
				errors.SSN = 'Required';
			} else if (values.SSN.length > constants.maxCharsNumber) {
				errors.SSN = constants.maxCharsNumberError;
			}
		}
		if (values.UseReferenceNumber) {
			if (!values.ReferenceNumber) {
				errors.ReferenceNumber = 'Required';
			} else if (values.ReferenceNumber.length > constants.maxCharsNumber) {
				errors.ReferenceNumber = constants.maxCharsNumberError;
			}
		}
		if (values.UseTaxCountry) {
			if (!values.TaxCountry) {
				errors.TaxCountry = 'Required';
			}
			if (values.UseSpecialRates) {
				if (!values.Article) {
					errors.Article = 'Required';
				} else if (values.Article.length > constants.maxCharsNumber) {
					errors.Article = constants.maxCharsNumberError;
				}
				if (!values.Percent) {
					errors.Percent = 'Required';
				} else if (values.Percent.length > constants.maxCharsNumber) {
					errors.Percent = constants.maxCharsNumberError;
				}
				if (values.Percent && !(values.Percent > 0 && values.Percent < 100)) {
					errors.Percent = 'Invalid';
				}
				if (!values.Income) {
					errors.Income = 'Required';
				} else if (values.Income.length > constants.maxCharsNumber) {
					errors.Income = constants.maxCharsNumberError;
				}
				if (values.AdditionalConditions && values.AdditionalConditions.length > constants.maxCharsNumber) {
					errors.AdditionalConditions = constants.maxCharsNumberError;
				}
			}
		}
		return errors;
	}
	componentDidMount() {
		const { countries, actions } = this.props;
		if (!countries) {
			actions.refreshCountriesList();
		}
	}

	render() {
		const {
			getFormValue,
			classes,
			theme,
			handleSubmit,
			invalid,
			loading,
			submitting,
			countries,
			initialValues,
		} = this.props;
		const countryItems = (countries && countries
			.sort((a, b) => (a.Name < b.Name ? -1 : 1))
			.map(cntr => ({ value: cntr.CodeLat2, text: cntr.Name }))) || [];
		return (
			<TabContainer>
				<ProgressContainer submitting={loading} >
					<form
						onSubmit={handleSubmit(profile.actions.changeTaxFormFillInFormSubmitHandler)}
						className={classes.root}
					>
						<div className={classes.inputItem}>
							<Field
								name="Name"
								type="text"
								component={FormInput}
								placeholder="1. Name of the beneficial owner"
								disabled
							/>
						</div>
						<div className={classes.item}>
							<SelectField
								component={FormSelect}
								label="2. Country of citizenship"
								name="Citizenship"
								items={countryItems}
								value={initialValues.Citizenship || ''}
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								name="ResidenceStreet"
								type="text"
								component={FormInput}
								placeholder="3. Permanent residence address"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								name="ResidenceCity"
								type="text"
								component={FormInput}
								placeholder="3. City or town, state or province"
							/>
						</div>
						<div className={classes.item}>
							<SelectField
								component={FormSelect}
								label="3. Residential country"
								name="ResidentialCountry"
								onChange={this.countryChanged}
								items={countryItems}
								value={initialValues.ResidentialCountry || ''}
							/>
						</div>
						<div className={classes.item}>
							<Field
								component={FormCheckbox}
								name="UseMailingAddress"
								label="Mailing address differs from residential"
							/>
						</div>
						{getFormValue('UseMailingAddress') && (
							<React.Fragment>
								<div className={classes.inputItem}>
									<Field
										name="MailingAddress"
										type="text"
										component={FormInput}
										placeholder="4. Mailing address"
									/>
								</div>
								<div className={classes.inputItem}>
									<Field
										name="MailingCity"
										type="text"
										component={FormInput}
										placeholder="4. Mailing city or town, state or province"
									/>
								</div>
								<div className={classes.item}>
									<SelectField
										component={FormSelect}
										label="4. Mailing country"
										name="MailingCountry"
										items={countryItems}
										value={initialValues.MailingCountry || ''}
									/>
								</div>
							</React.Fragment>)
						}
						<div className={classes.item}>
							<Field
								component={FormCheckbox}
								name="UseSSN"
								label="I have SSN/ITIN"
							/>
						</div>
						{getFormValue('UseSSN') &&
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="SSN"
									placeholder="5. U.S. taxpayer identification number"
								/>
							</div>
						}
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="TaxID"
								placeholder="6. Foreign tax identifying number"
							/>
						</div>
						{!getFormValue('TaxID') && getFormValue('ResidentialCountry') !== 'RU' &&
							<div className={classes.item}>
								<Field
									component={FormCheckbox}
									name="NoTINReason"
									label="TIN is not required by domestic law"
								/>
							</div>
						}
						<div className={classes.item}>
							<Field
								component={FormCheckbox}
								name="UseReferenceNumber"
								label="I have a Reference number"
							/>
						</div>
						{getFormValue('UseReferenceNumber') &&
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="ReferenceNumber"
									placeholder="7. Reference Number"
								/>
							</div>
						}
						<div className={classes.dateItem}>
							<Field
								component={FormDatePicker}
								name="DateOfBirth"
								label="8. Date of birth"
								parse={parseMomentDate}
								onFocus={() => undefined}
								disabled
							/>
						</div>
						<div className={classes.item}>
							<Field
								component={FormCheckbox}
								name="UseTaxCountry"
								label="I can claim tax treaty benefits"
								disabled
							/>
						</div>
						{getFormValue('UseTaxCountry') &&
							<React.Fragment>
								<div className={classes.infoText}>
									I certify that the beneficial owner is a resident of the following country within the
									meaning of the income tax treaty between the United States and that country.
									Please visit the &nbsp;
									<A
										href="https://www.irs.gov/businesses/international-businesses/united-states-income-tax-treaties-a-to-z"
										target="_blank"
									>IRS website &nbsp;
									</A>
									to see if your country of Residence applies
								</div>
								<div className={classes.item}>
									<SelectField
										component={FormSelect}
										label="9. Country"
										name="TaxCountry"
										items={countryItems}
										value={initialValues.TaxCountry || ''}
									/>
								</div>
								<div className={classes.item}>
									<Field
										component={FormCheckbox}
										name="UseSpecialRates"
										label="I can apply for special rates and conditions"
									/>
								</div>
							</React.Fragment>
						}
						{getFormValue('UseTaxCountry') && getFormValue('UseSpecialRates') &&
							<React.Fragment>
								<div className={classes.infoText}>
									The beneficial owner is claiming the provisions of Article
								</div>
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="Article"
										placeholder="10. Article"
									/>
								</div>
								<div className={classes.infoText}>
									of the treaty identified on line 9 above to claim a
								</div>
								<div className={classes.inputItem}>
									<Field
										component={FormInputNumber}
										name="Percent"
										placeholder="10. Percent"
										allowIconButtons={false}
										prefix=""
										suffix="%"
										normalize={numValue}
									/>
								</div>
								<div className={classes.infoText}>
									rate of withholding on (specify type of income):
								</div>
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="Income"
										placeholder="10. Income"
									/>
								</div>
								<div className={classes.infoText}>
									Explain the additional conditions in the Article and paragraph the beneficial
									owner meets to be eligible for the rate of withholding:
								</div>
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="AdditionalConditions"
										placeholder="10. Additional conditions"
									/>
								</div>
							</React.Fragment>
						}
						<div className={classes.button}>
							<Button
								fullWidth
								type="submit"
								variant="contained"
								color="primary"
								disabled={submitting || invalid}
							>
								{submitting ?
									<CircularProgress
										style={{ color: theme.typography.color.blue }}
										size={18}
									/>
									: 'Update'
								}
							</Button>
						</div>
					</form>
				</ProgressContainer>
			</TabContainer>
		);
	}
}

TaxFormFillInTabComponent.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	countries: PropTypes.array,
	loading: PropTypes.bool.isRequired,
	initialValues: PropTypes.object.isRequired,
	getFormValue: PropTypes.func.isRequired,
};

TaxFormFillInTabComponent.defaultProps = {
	countries: null,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: TAX_FORM_FILLIN_FORM,
		validate: TaxFormFillInTabComponent.validate,
	}),
)(TaxFormFillInTabComponent);
