import { delay } from 'redux-saga';
import { all, call, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { auth, profile, signalR, userAccounts } from '@redux';
import { push } from 'connected-react-router';
import { constants, env } from '@config';
import { ModalPopups } from '@enums';
import { cabinetLog, addHashToUrl, getDateTimeEst, dateDiffInDays } from '@global';
import actions from './actions';

function* dashboardInitSaga({ payload: { tradeCode } }) {
	try {

		if (!!env.signalRUrl) {
			yield put(signalR.actions.connectSignalR());
		}

		yield put(userAccounts.actions.getAccountsRequest());

		yield take([
			userAccounts.actions.GET_ACCOUNTS_SUCCESS,
			userAccounts.actions.GET_ACCOUNTS_FAILURE,
		]);

		const demoAccount = yield select(userAccounts.selectors.getDemoAccount);
		const anyRealAccountExists = yield select(userAccounts.selectors.anyRealAccountExists);

		if (demoAccount && !anyRealAccountExists) {
			const currentDateTimeEst = getDateTimeEst();
			const expirationDate = new Date(demoAccount.ExpirationDate);
			const diffDays = dateDiffInDays(expirationDate, currentDateTimeEst);

			if (diffDays < 0 || demoAccount.IsClosed) {
				yield put(profile.actions.showDemoAccountExpirationModal());
				return;
			}
		}

		const accounts = (yield select(userAccounts.selectors.accounts));
		cabinetLog(accounts);
		if (!accounts || accounts.length === 0) {
			throw new Error('Has no account');
		}

		const currentAccount = localStorage.getItem('curacc');
		const accCurrent = accounts.find(it => it.TradeCode === tradeCode || it.TradeCode === currentAccount);

		// init start's popups
		yield put(actions.initComplete());
		cabinetLog('init complete');


		if (env.firm === constants.LIME_FIN) {
			yield all([
				yield put(userAccounts.actions.setCurrentAccount(!accCurrent ? accounts[0] : accCurrent)),
				yield put(profile.actions.getProfileRequest()),
			]);
		} else if (env.firm === constants.LIME_LME) {
			const liveAccount = accounts.find(it => !it.IsDemo) || accounts[0];
			yield all([
				yield put(userAccounts.actions.setCurrentAccount(!accCurrent ? liveAccount : accCurrent)),
			]);
		} else {
			yield all([
				yield put(userAccounts.actions.setCurrentAccount(!accCurrent ? accounts[0] : accCurrent)),
			]);
		}
	} catch (error) {
		window.Raven.captureException(error);
		console.error('init error', error);
		yield put(auth.actions.logout());
	}
}


function* dashboardInitWelcomePageSaga() {
	try {
		yield all([
			// welcome page required profile.IsInstantTrading
			take(profile.actions.GET_PROFILE_SUCCESS),
			// welcome page required accountInfo.platforms.ActiveTradePlatform
			take(userAccounts.actions.GET_ACCOUNT_INFO_SUCCESS),
		]);
		const userInfo = yield select(userAccounts.selectors.userInfo);
		if (userInfo && userInfo.IsPreopened) {
			// get current location
			const location = yield select(state => state.router.location);
			// show modal
			yield put(push(addHashToUrl(location, ModalPopups.WELCOME_PAGE)));
		}
	} catch (error) {
		window.Raven.captureException(error);
	}
}


function* dashboardInitAttestationProfileSaga() {

	if (env.firm !== constants.LIME_FIN) {
		// Attestation works for US Cabinet only
		return;
	}

	try {
		yield all([
			take(profile.actions.GET_PROFILE_SUCCESS),
		]);
		const userProfile = yield select(profile.selectors.profile);
		const location = yield select(state => state.router.location);

		if (env.WEBCAB_5863_allow_attestation && userProfile && userProfile.NeedUpdatePersonalInfo && location && location.pathname !== '/dashboard/ConfirmPersonalInfo') {
			yield put(profile.actions.showConfirmProfileModal());
		}
	} catch (error) {
		window.Raven.captureException(error);
	}
}


// function* dashboardPingAuthFlowSaga() {
// 	try {
// 		while (window.localToken) {
// 			yield call(auth.saga.pingAuthRequestSaga);
// 			yield call(delay, 60 * 1000);
// 		}
// 	} catch (error) {
// 		window.Raven.captureException(error);
// 	}
// }


// Watchers
function* watchInit() {
	yield takeEvery(actions.INIT, dashboardInitSaga);
}

function* watchInitWelcomePage() {
	yield takeLatest(actions.INIT_COMPLETED, dashboardInitWelcomePageSaga);
}

function* watchInitAttestationProfile() {
	yield takeLatest(actions.INIT_COMPLETED, dashboardInitAttestationProfileSaga);
}

// function* watchPingAuth() {
// 	// only one working thread (previous will be cancelled)
// 	yield takeLatest(actions.INIT_COMPLETED, dashboardPingAuthFlowSaga);
// }

// Root saga
const rootSaga = [
	fork(watchInit),
	fork(watchInitWelcomePage),
	fork(watchInitAttestationProfile),
	// fork(watchPingAuth),
];

export default {
	rootSaga,
};

