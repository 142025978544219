import { ModalPopups } from '@enums';
import actions from './actions';
import auth from '../auth';

const initialState = {
	clients: {
		submitting: false,
		loaded: false,
		items: [],
	},
	modals: {},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		// getApiKeys
		case actions.getApiKeys.REQUEST:
			return {
				...state,
				clients: {
					...state.clients,
					submitting: true,
				},
			};
		case actions.getApiKeys.SUCCESS:
			return {
				...state,
				clients: {
					loaded: true,
					submitting: false,
					items: [
						...payload.response.Clients,
					],
				},
			};
		case actions.getApiKeys.FAILURE:
			return {
				...state,
				clients: {
					...initialState.clients,
					submitting: false,
					loaded: true,
				},
			};

		// createApiKey
		case actions.createApiKey.REQUEST:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CREATE_APIKEY]: {
						submitting: true,
						error: false,
					},
				},
			};

		case actions.createApiKey.SUCCESS: {
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CREATE_APIKEY]: {
						CreatedSecretKey: payload.response.CreatedSecretKey,
						СlientId: payload.request.clientId,
						submitting: false,
						error: false,
					},
				},
			};
		}
		case actions.createApiKey.FAILURE:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CREATE_APIKEY]: {
						submitting: false,
						error: true,
					},
				},
			};
		case actions.SHOW_CREATE_APIKEY_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CREATE_APIKEY]: null,
				},
			};
		case actions.HIDE_ETNAAUTH_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[payload]: undefined,
				},
			};
		case auth.actions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
