import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { acatDeposit, userAccounts } from '@redux';
import { Modal } from '@components';
import { connect } from 'react-redux';
import { CloseAccountContinue, CloseAccountWithdrawalCash } from './components';

const styles = {
	root: {
		marginBottom: '30px',
	},
	tabHeader: {
		fontSize: '16px',
		fontWeight: 'normal',
		minWidth: '100px',
		maxWidth: '100%',
	},
};

const mapStateToProps = state => ({
	closeAccountData: acatDeposit.selectors.closeAccountData(state),
	accountStatus: userAccounts.selectors.currentAccountStatus(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		closeAccountContinueRequest: request => dispatch(acatDeposit.actions.closeAccountContinueRequest(request)),
	},
});


class CloseAccountFormModal extends React.Component {
	render() {
		const {
			classes,
			onClose,
			closeAccountData,
		} = this.props;

		let ModalComponent;
		let ModalTitle;

		if (closeAccountData.submitting) {
			ModalComponent = <CloseAccountContinue />;
			ModalTitle = 'Close Account';
		} else {
			ModalComponent = (<CloseAccountWithdrawalCash
				onClose={onClose}
				closeAccountData={closeAccountData}
			/>);
			ModalTitle = 'Cash Withdrawal';
		}

		return (
			<Modal
				title={ModalTitle}
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CLOSE_ACCOUNT_FORM}`}
			>
				<div className={classes.root}>
					{ ModalComponent }
				</div>
			</Modal>
		);
	}
}

CloseAccountFormModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	closeAccountData: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CloseAccountFormModal);
