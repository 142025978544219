import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { userAccounts, profile } from '@redux';
import { ModalPopups, TaxFormType } from '@enums';
import { ErrorMessage, ProgressContainer, SuccessMessage } from '@components';
import { connect } from 'react-redux';
import { TaxFormFillInTabComponent, TaxFormUploadTabComponent } from './components';
import { RequireSignatureModal } from '../../..';

const styles = theme => ({
	root: {
	},
	tabHeader: {
		fontSize: '16px',
		fontWeight: 'normal',
		minWidth: '100px',
		maxWidth: '100%',
		flexGrow: 1,
	},
	wrapper: {
		fontSize: '0.875rem',
	},
});

const getFormType = docType => docType.replaceAll('-', '').toUpperCase();
const isPending = (taxPendingOrders, taxForm) => !!(taxPendingOrders && taxPendingOrders.some(x => x === taxForm));
const getDefaultFormType = (taxForms, taxPendingOrders) => {
	if (!taxForms || taxForms.length === 0) {
		return null;
	}

	const tf = taxForms.map(a => ({
		Expired: a.ExpirationDate && new Date(a.ExpirationDate) <= new Date(),
		FormType: getFormType(a.DocType),
	})).sort((a, b) => {
		if (a.FormType === TaxFormType.W8_BEN) {
			return -1;
		}
		// expired first
		if (a.Expired) {
			return -1;
		}
		if (!taxPendingOrders) { 
			// no info about pending orders
			return -1;
		}
		// not pending first
		return isPending(taxPendingOrders, a.FormType) ? 1 : -1;
	});
	return tf[0];
};

const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	const loading = userProfile.submitting || userAccounts.selectors.loading(state);
	let FormType = userProfile.submitting ? null : profile.selectors.changeTaxForm(state).FormType;
	if (!userProfile.submitting && !FormType && userProfile.PendingOrders && userProfile.PendingOrders.TaxFormUpdate) {
		const taxForm = getDefaultFormType(userProfile.TaxForms, userProfile.PendingOrders.TaxFormUpdate);
		FormType = taxForm && taxForm.FormType;
	}
	const canChange = !loading && !!FormType;
	const accounts = userAccounts.selectors.accounts(state);
	const allowFillIn = !loading && (accounts && !accounts.some(x => !x.IsClosed && x.ClearerFirm === 'cor'));

	return {
		loading,
		canChange,
		accounts: userAccounts.selectors.accounts(state),
		allowFillIn,
		FormType,
		pendingOrder: !loading && FormType && isPending(userProfile.PendingOrders.TaxFormUpdate, FormType),
	};
};

class ChangeTaxFormModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTab: null,
			submitSucceeded: false,
		};
	}

	setCurrentTab = (event, currentTab) => {
		this.setState({ currentTab });
	}
	setSubmitSuccess = () => this.setState({ submitSucceeded: true });
	render() {
		const {
			classes,
			loading,
			allowFillIn,
			onClose,
			FormType,
			pendingOrder,
		} = this.props;
		const { submitSucceeded } = this.state;
		const { currentTab } = this.state;
		
		const tabs = [];
		if (FormType === TaxFormType.W8_BEN && allowFillIn) {
			tabs.push({ value: 'FILL_IN', title: 'FILL IN', component: TaxFormFillInTabComponent });
		}
		tabs.push({ value: 'UPLOAD', title: 'UPLOAD', component: TaxFormUploadTabComponent });

		const curTab = currentTab ? tabs.find(it => it.value === currentTab) : tabs[0];

		let message;
		if (submitSucceeded) {
			message = (<SuccessMessage onClick={onClose} text="Your request to change tax form is being processed." />);
		} else if (!loading) {
			if (pendingOrder) {
				message = (<ErrorMessage
					onClick={onClose}
					text="Another request for tax form update is in progress. Please try again later."
				/>);
			} else if (!FormType) {
				message = <ErrorMessage onClick={onClose} text="Unavailable" />;
			}
		}
		return (
			<RequireSignatureModal
				title="Update tax form"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_TAX_FORM_MODAL}`}
			>
				{message}
				{!message &&
					<ProgressContainer submitting={loading}>
						<div>
							<p>
							Fill in or upload scanned document if you already have it filled.
							</p>
							<Tabs
								value={curTab.value}
								onChange={this.setCurrentTab}
								indicatorColor="primary"
								textColor="primary"
							>
								{tabs.map(item => (
									<Tab
										classes={{ root: classes.tabHeader, wrapper: classes.wrapper }}
										label={item.title}
										key={`TAB_HEADER_${item.value}`}
										value={item.value}
									/>
								))}
							</Tabs>
						</div>
						<curTab.component onClose={onClose} FormType={FormType} onSubmitSuccess={this.setSubmitSuccess} />
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeTaxFormModal.propTypes = {
	classes: PropTypes.object.isRequired,
	pendingOrder: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	allowFillIn: PropTypes.bool.isRequired,
	FormType: PropTypes.oneOf(Object.values(TaxFormType)),
};
ChangeTaxFormModal.defaultProps = {
	FormType: undefined,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(ChangeTaxFormModal);
