/* eslint-disable react/no-children-prop */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';

const styles = theme => ({
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	inputContainerItem: {
		width: '100%',
		textAlign: 'left',
	},
	fullWidth: {
		width: '100%',
	},
	label: {
		...theme.typography.style.title,
		color: theme.typography.color.secondary,
		marginTop: '-8px',
		paddingLeft: '25px',
		'&.MuiInputLabel-shrink': {
			marginTop: '-4px',
		},
	},
	selectContainer: {
		borderRadius: '8px',
		background: theme.palette.color.primary.attestationBackground,
	},
	selectMenu: {
		width: '100%',
		height: '28px',
		paddingTop: '3px',
		paddingBottom: '4px',
		paddingLeft: '20px',
	},
	icon: {
		top: 'calc(50% - 19px)',
	},
	errorMsg: {
		textTransform: 'capitalize',
		paddingLeft: '25px',
		fontSize: '14px',
	},
});


class SelectField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state[`${this.props.name}`] = this.props.value;
		this.state.touched = false;
	}

	// Removed second focus from select element
	onExitedRegionSelect = () => {
		document.activeElement.blur();
	};

	handleChange = (event) => {
		if (event.target) {
			this.setState({ [event.target.name]: event.target.value });
		}
	};

	renderSelectField = ({
		input,
		label,
		meta: { error },
		children,
		...custom
	}) => {
		const {
			classes,
		} = this.props;
		const invalid = this.state.touched && !!error;

		return (
			<React.Fragment>
				<FormControl className={`${classes.fullWidth} ${classes.selectContainer}`} error={invalid}>
					<InputLabel
						htmlFor={this.props.name}
						className={classes.label}
					>
						{label}
					</InputLabel>
					<Select
						disableUnderline
						className={classes.fullWidth}
						value={this.state[`${this.props.name}`]}
						name={this.props.name}
						children={children}
						error={!!error}
						onClose={() => this.setState({ touched: true })}
						onChange={(event) => {
							input.onChange(event);
							this.handleChange(event);
							if (this.props.onChange) {
								this.props.onChange(event);
							}
						}}
						inputProps={{
							name: `${this.props.name}`,
						}}
						classes={{
							selectMenu: classes.selectMenu,
							icon: classes.icon,
						}}
						MenuProps={{ TransitionProps: { onExited: this.onExitedRegionSelect } }}
					/>
				</FormControl>
				{invalid && <FormHelperText error className={classes.errorMsg}>{error}</FormHelperText>}
			</React.Fragment>
		);
	};

	render() {
		const {
			classes,
		} = this.props;

		return (
			<div className={classes.inputSelectItem}>
				<Field
					component={this.renderSelectField}
					name={this.props.name}
					label={this.props.label}
				>
					{
						this.props.items.map((item, i) => (
							<MenuItem key={i} value={item.value} className={classes.selectItem}>
								<div className={classes.inputContainer}>
									<div className={classes.inputContainerItem}>{item.text}</div>
								</div>
							</MenuItem>
						))
					}
				</Field>
			</div>
		);
	}
}

SelectField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	items: PropTypes.array.isRequired,
	onChange: PropTypes.func,
};


export default compose(withStyles(styles))(SelectField);
