import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MURadio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {},
	primary: {
		color: theme.typography.color.primary,
	},
	red: {
		color: theme.typography.color.red,
	},
	checkedPrimary: {
		color: theme.palette.color.primary.lightBlue,
	},
	label: {
		...theme.typography.style.title,
	},
	additionalText: {
		padding: '0px 24px',
		color: theme.typography.color.secondary,
		...theme.typography.style.body,
	},
	checkedError: {
		color: `${theme.typography.color.red} !important`,
	},
});

const useStyles = props => makeStyles({
	radioLabel: {
		fontSize: `${props.labelSize}px`,
	},
	radioButton: {
		'& .MuiSvgIcon-root': {
			width: `${props.radioButtonSize}px`,
			height: `${props.radioButtonSize}px`,
		},
	},
});

const Radio = ({
	classes,
	disabled,
	error,
	helpertext,
	radioButtonSize,
	labelSize,
	...props
}) => {
	const { radioLabel, radioButton } = useStyles({ labelSize, radioButtonSize })();

	return (
		<React.Fragment>
			<FormControlLabel
				classes={{
					root: classes.root,
					label: classNames({
						[classes.label]: true,
						[classes.red]: props.checked && error,
						[classes.primary]: !props.checked || !error,
						[radioLabel]: !!labelSize,
					}),
				}}
				control={
					<MURadio
						classes={{
							root: classNames({
								[radioButton]: !!radioButtonSize,
							}),
							checked: classNames({
								[classes.checkedPrimary]: true,
								[classes.checkedError]: error,
							}),
							colorSecondary: classes.red,
						}}
						color="primary"
						disabled={disabled}
					/>
				}
				{...props}
			/>
			{props.checked && error &&
				<FormHelperText error classes={{ root: classes.additionalText }}>
					{helpertext}
				</FormHelperText>
			}
		</React.Fragment>
	);
};

Radio.propTypes = {
	checked: PropTypes.bool,
	classes: PropTypes.object,
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	helpertext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	radioButtonSize: PropTypes.number,
	labelSize: PropTypes.number,
};

Radio.defaultProps = {
	checked: false,
	classes: {},
	disabled: false,
	error: false,
	helpertext: null,
	radioButtonSize: undefined,
	labelSize: undefined,
};

export default withStyles(styles)(Radio);
