import { providerWithAuth } from '@api';

module.exports = {

	sendNonTradingCommonOrder: payload => providerWithAuth().post('/nontrading/api_common', payload),
	sendNonTradingIdentityOrder: payload => providerWithAuth().post('/nontrading/api_identity', payload),
	sendNonTradingLeverageOrder: payload => providerWithAuth().post('/nontrading/api_leverage', payload),
	sendNonTradingRegistrationOrder: payload => providerWithAuth().post('/nontrading/api_registration', payload),
	sendNonTradingOpenAccount: payload => providerWithAuth().post('/nontrading/api_openaccount', payload),
	sendNonTradingOpenDemoAccount: payload => providerWithAuth().post('/nontrading/api_opendemoaccount', payload),
};
