import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { dashboard } from '@redux';
import { scrollTo } from '@global';

const styles = theme => ({
	root: {},
	listItemText: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
});


const mapDispatchToProps = dispatch => ({
	actions: {
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose())
	},
});

class ReportsQuickLinks extends React.Component {
	scrollTo = (elementId) => {
		scrollTo(elementId);
		this.props.actions.sidebarClose();
	}

	render() {
		const {
			classes,
		} = this.props;
		return (
			<div />
		);
	}
}

ReportsQuickLinks.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(ReportsQuickLinks);
