import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, withTheme } from '@material-ui/core/styles';
import fileDownload from 'js-file-download';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { providerFileWithAuth } from '@api';
import { A } from '@components';
import { cabinetLog } from '@global';


const styles = theme => ({
	// класс для индикатора загрузки (для переопределения)
	progress: {
	},
	root: {
		display: 'flex',
	},
	rootLoading: {
	},
});

class FileDownload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			hasError: false,
		};
	}

	downloadFile = (event) => {
		const {
			onClick,
			clickBubblingStopped,
			url,
			data,
			onSuccess,
			onError,
		} = this.props;

		if (onClick) {
			onClick();
		}
		if (clickBubblingStopped) {
			event.preventDefault();
		}
		this.setState({ submitting: true, hasError: false });
		const request = data
			? providerFileWithAuth().post(url, data)
			: providerFileWithAuth().get(url);
		request.then(async (response) => {
			this.setState({ submitting: false });
			cabinetLog('file download response', response);
			let fileName = response.headers['content-disposition']
				? response.headers['content-disposition'].split('filename=')[1]
				: this.props.fileName;

			if (fileName) {
				fileName = fileName.replace(/['"]+/g, '');
			}

			// Сценарий, когда при попытке скачать файл произошла беда
			// и сервер вернул фактически не blob, а 'application/json'
			if (response.data instanceof Blob &&
				response.data.type &&
				response.data.type.toLowerCase().indexOf('json') !== -1) {
				const errorText = JSON.parse(await response.data.text());
				if (onError) {
					onError(errorText);
					return;
				}
			}

			fileDownload(response.data, fileName || 'report', response.headers['content-type']);
			if (onSuccess) {
				onSuccess();
			}
		}).catch((error) => {
			if (!error.response) {
			// network error
				console.error(error);
			} else {
				const reader = new FileReader();
				const self = this;
				// This fires after the blob has been read/loaded.
				reader.addEventListener('loadend', (e) => {
					const text = self.tryParseJson(e.srcElement.result);
					if (self.props.onError) {
						self.props.onError(text);
					} else {
						console.error(text);
					}
				});
				reader.readAsText(error.response.data);
			}
			this.setState({ submitting: false, hasError: true });
		});
	}

	tryParseJson = (text) => {
		try {
			return JSON.parse(text);
		} catch (ex) {
			return text;
		}
	}

	render() {
		const {
			theme,
			color,
			classes,
			showButtonTitleWhenLoading,
			buttonTitle,
		} = this.props;
		const {
			hasError,
			submitting,
		} = this.state;

		return (
			<React.Fragment>
				<A
					onClick={event => this.downloadFile(event)}
					color={hasError ? 'red' : color}
					classes={
						{
							root: classNames({
								[classes.root]: true,
								[classes.rootLoading]: submitting,
							}),
						}
					}
				>
					{submitting &&
						<CircularProgress
							style={{ color: theme.typography.color.blue }}
							size={18}
							classes={{ root: classes.progress }}
						/>
					}
					{(!submitting || showButtonTitleWhenLoading) && buttonTitle}
				</A>
			</React.Fragment>
		);
	}
}

FileDownload.propTypes = {
	classes: PropTypes.shape({
		progress: PropTypes.string,
	}).isRequired,
	showButtonTitleWhenLoading: PropTypes.bool,
	theme: PropTypes.object.isRequired,
	fileName: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	color: PropTypes.string,
	data: PropTypes.object, // used for post requests only
	buttonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	onClick: PropTypes.func,
	onError: PropTypes.func,
	onSuccess: PropTypes.func,
	clickBubblingStopped: PropTypes.bool,
};

FileDownload.defaultProps = {
	color: 'default',
	data: null,
	showButtonTitleWhenLoading: false,
	onClick: null,
	onSuccess: null,
	onError: null,
	clickBubblingStopped: false,
};

export default compose(
	withTheme,
	withStyles(styles),
)(FileDownload);
