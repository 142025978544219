import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Button } from '@components';
import { env } from '@config';
import { signature, userAccounts } from '@redux';
import PropTypes from 'prop-types';

const styles = theme => ({
	root: {
		margin: '24px 74px',
		padding: '10px 16px',
		backgroundColor: theme.palette.color.secondary.limitOrder,
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0',
			padding: '10px 16px',
		},
	},
	content: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	actions: {
	},
	button: {
		color: theme.typography.color.link,
	},
});

const mapStateToProps = state => ({
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	accountStatus: userAccounts.selectors.currentAccountStatus(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		collectMarginTypesProcessStart: () =>
			dispatch(signature.actions.collectMarginTypesProcessStart()),
		showUpgradeAccountModal: (tradeAccount, marginParams) => {
			const changeAction = userAccounts.actions.showUpgradeAccountModal({ tradeAccount, marginParams });
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
	},
});


class MarginTypeAlert extends React.Component {
	showUpgradeModal = () => {
		const { actions, currentTradeAccount, currentAccountData } = this.props;
		actions.showUpgradeAccountModal(currentTradeAccount, currentAccountData.baseParams.MarginOptionParameters);
	}
	render() {
		const {
			classes,
			accountStatus,
			accountInfo,
			currentAccountData: {
				baseParams,
			},
		} = this.props;
		const marginType = (baseParams && baseParams.MarginOptionParameters && baseParams.MarginOptionParameters.MarginType) || '';
		const havePendingOrder = accountInfo && accountInfo.PendingOrders && accountInfo.PendingOrders.UpgradeMarginType;
		return (
			<React.Fragment>
				{marginType === 'MarginX1' &&
					<div>
						<div className={classes.root}>
							<div className={classes.content}>
								MarginX1 type will discarded in the near future. Please submit a request for changing your margin level to MarginX2 or Cash.
							</div>
							{accountStatus && accountStatus.CanMarginUpgrade &&
								accountInfo && accountInfo.IsPrimaryHolder &&
								<div className={classes.actions}>
									<Button
										onClick={this.showUpgradeModal}
										className={classes.button}
										disabled={havePendingOrder}
									>
										Change
									</Button>
								</div>
							}
						</div>
					</div>
				}
			</React.Fragment>
		);
	}
}

MarginTypeAlert.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	accountStatus: PropTypes.object.isRequired,
	accountInfo: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(MarginTypeAlert);
