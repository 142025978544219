import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ipo } from '@redux';
import { OfferingMainInfoMobile } from '.';


const mapDispatchToProps = dispatch => ({
	actions: {
		showIpoCreateOrderModal: payload => dispatch(ipo.actions.showIpoCreateOrderModal(payload)),
		showIpoExtendedInfoMobileModal: payload => dispatch(ipo.actions.showIpoExtendedInfoMobileModal(payload)),
	},
});

class IpoCalendarItemMobile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	toggleOpen = () => {
		this.setState({
			open: !this.state.open,
		});
	}

	render() {
		const {
			item,
			actions,
		} = this.props;


		return (
			<React.Fragment>
				<OfferingMainInfoMobile
					item={item}
					createOrder={() => actions.showIpoCreateOrderModal({
						OfferingId: item.OfferingId,
					})}
					showExtendedInfo={() => actions.showIpoExtendedInfoMobileModal({ Offering: item })}
				/>
			</React.Fragment>
		);
	}
}

IpoCalendarItemMobile.propTypes = {
	item: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default compose(connect(null, mapDispatchToProps))(IpoCalendarItemMobile);
