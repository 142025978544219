import { createFormAction } from 'redux-form-saga';
import { createApiActions } from '../extensions';


const actions = {

	SHOW_IPO_CREATE_ORDER_MODAL: 'IPO/SHOW_IPO_CREATE_ORDER_MODAL',

	showIpoCreateOrderModal: payload => ({
		type: actions.SHOW_IPO_CREATE_ORDER_MODAL,
		payload,
	}),

	SHOW_IPO_EXTENDED_INFO_MOBILE_MODAL: 'IPO/SHOW_IPO_EXTENDED_INFO_MOBILE_MODAL',

	showIpoExtendedInfoMobileModal: payload => ({
		type: actions.SHOW_IPO_EXTENDED_INFO_MOBILE_MODAL,
		payload,
	}),

	getOfferings: createApiActions('IPO/GET_ALL_OFFERINGS'),
	getOffering: createApiActions('IPO/GET_OFFERING'),

	getOrders: createApiActions('IPO/GET_ORDERS'),

	createIpoOrder: createFormAction('IPO/CREATE_IPO_ORDER'),

	cancelIpoOrder: createApiActions('IPO/CANCEL_IPO_ORDER'),
};

export default actions;
