import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {
	ExpansionPanel,
	ProgressContainer,
	R,
	ShowMore,
} from '@components';
import { userAccounts } from '@redux';
import { connect } from 'react-redux';
import { CurrentHoldingsTable } from './components';


const styles = theme => ({
	subhead: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		marginLeft: '15px',
	},
	table: {
		overflowX: 'auto',
	},
	showMoreContainer: {
		padding: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
});

const mapStateToProps = state => ({
	accountData: userAccounts.selectors.currentAccountData(state),
});

const initialHoldersCount = 5;

class CurrentHoldings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			holdersCount: initialHoldersCount,
		};
		this.showMore = this.showMore.bind(this);
		this.showLess = this.showLess.bind(this);
	}

	showMore() {
		const { length } = this.props.accountData.portfolio.Positions;
		this.setState({
			holdersCount: this.state.holdersCount + length,
		});
	}

	showLess() {
		if (this.state.holdersCount > initialHoldersCount) {
			this.setState({
				holdersCount: initialHoldersCount,
			});
		}
	}

	render() {
		const {
			classes,
			accountData: {
				portfolio,
				submitting,
			},
		} = this.props;

		return (
			<ExpansionPanel
				header={
					<div>
						<R id="common_header_portfolio" defaultValue="Portfolio" />
						<span className={classes.subhead}>
							{(portfolio && portfolio.Positions) ? portfolio.Positions.length : ''}
						</span>
					</div>
				}
				id="current-holdings"
			>
				<ProgressContainer submitting={submitting} display="none">
					{portfolio && portfolio.Positions &&
						<Grid container>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<div className={classes.table}>
									<CurrentHoldingsTable
										positions={portfolio.Positions.slice(0, this.state.holdersCount)}
									/>
								</div>
								<ShowMore
									onClick={this.showMore}
									showMoreButton={portfolio.Positions.length > this.state.holdersCount}
									onShowLessClick={this.showLess}
									showLessButton={this.state.holdersCount > initialHoldersCount}
								/>
							</Grid>
						</Grid>
					}
				</ProgressContainer>
			</ExpansionPanel>

		);
	}
}

CurrentHoldings.propTypes = {
	accountData: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(CurrentHoldings);
