import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import {
	tradePlatforms,
	userAccounts,
} from '@redux';
import {
	Modal,
	Button,
	A,
} from '@components';
import { connect } from 'react-redux';
import logo from './imgs/j2t-plus.png';

const styles = theme => ({
	title: {
		...theme.typography.style.display,
		color: theme.typography.color.primary,
	},
	body: {
		marginTop: '24px',
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	description: {
		marginBottom: '5px',
	},
	list: {
		margin: '0',
		listStyleType: 'none',
		lineHeight: '35px',
		padding: '0',
		'& li:': {
			'&:before': {
				content: '"—"',
				marginRight: '20px',
			},
		},
	},
	button: {
		marginTop: '32px',
		backgroundColor: '#e3edf5',
	},
	buttonDownload: {
		marginTop: '32px',
	},
	noteText: {
		marginTop: '32px',
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		activatePlatform: (account, tradePlatform) =>
			dispatch(tradePlatforms.actions.activatePlatformRequest(account, tradePlatform)),
	},
});

const mapStateToProps = state => ({
	platforms: userAccounts.selectors.platforms(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	submitting: tradePlatforms.selectors.submitting(state),
});

const PLATFORM = 'Just2Trade';

class J2tPlusModal extends React.Component {
	constructor(props) {
		super(props);
		this.activatePlatform = this.activatePlatform.bind(this);
	}

	activatePlatform() {
		this.props.actions.activatePlatform(
			this.props.currentTradeAccount,
			PLATFORM,
		);
	}

	render() {
		const {
			onClose,
			classes,
			submitting,
			platforms: {
				ActiveTradePlatform,
				PendingTradePlatform,
			},
		} = this.props;

		return (
			<Modal
				title={<img alt="J2T Lite logo" src={logo} />}
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.J2T_PLUS}`}
			>
				<div className={classes.title}>
					Just2Trade +
				</div>
				<div className={classes.body}>
					<p className={classes.description}>
						<strong>Trade stocks & options</strong> with classic trading platform.
					</p>
					<ul className={classes.list}>
						<li>Customizable interface</li>
						<li>Access to U.S. exchanges</li>
						<li>Web-based</li>
						<li>Real-time streaming market data</li>
						<li>Real-time analysis</li>
						<li>Easy accessibility</li>
					</ul>
				</div>
				{PendingTradePlatform && PendingTradePlatform === PLATFORM &&
					<div className={classes.button}>
						<Button
							color="secondary"
							variant="contained"
							fullWidth
							disabled
						>
							ACTIVATION REQUESTED
						</Button>
					</div>
				}
				{!PendingTradePlatform && ActiveTradePlatform !== PLATFORM &&
					<div className={classes.button}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={this.activatePlatform}
							disabled={submitting}
						>
							{submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								: 'Activate'
							}
						</Button>
					</div>
				}
				{ActiveTradePlatform === PLATFORM &&
					<div className={classes.buttonDownload}>
						<A target="_blank" href="https://us-trading.just2trade.com/">
							<Button
								color="primary"
								variant="outlined"
								fullWidth
							>
								Access J2T+ Web
							</Button>
						</A>
					</div>
				}

				<div className={classes.noteText}>
					Trading platform becomes available on the next day after activation.<br />
					Please note that only one trading platform may be used at a time.
				</div>
			</Modal>
		);
	}
}

J2tPlusModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(J2tPlusModal);
