import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { profile } from '@redux';
import {
	Input,
} from '@components';
import { makeStyles } from '@material-ui/core/styles';
import JointHolders from './JointHolders';
import HoldersQuestion from './HoldersQuestion';
import AccountInformationQuestion from './AccountInformationQuestion';

const valueOrDash = val => val || '-';

const styles = {
    root: {
        marginLeft: '24px',
        marginTop: '20px'
    },
}

const useStyles = makeStyles(styles);

const AccountInformation = ({
	account,
	form,
	classes: _classes
}) => {

	const classes = {
		...useStyles(),
		..._classes,
	};

	const clientType = useSelector(profile.selectors.profile).Type;
	const primaryHolder = account.Holders.find(x => x.IsPrimary);
	const primaryHolderName = primaryHolder && primaryHolder.FullName;
	const id = `joint-holders-${account.TradeCode}`;

	return (
		<React.Fragment>
			<Input placeholder="Number" showLabel value={account.TradeCode} disabled />
			<Input placeholder="Name" showLabel value={valueOrDash(account.AccountName)} disabled />
			<Input placeholder="Primary Holder" showLabel value={valueOrDash(primaryHolderName)} disabled />
            
			<AccountInformationQuestion classes={classes} form={form} account={account} />

			{account.Holders.some(h => !h.IsPrimary) &&
                <div className={classes.root}>
                    Joint Holders
                    <JointHolders account={account} panelIdPrefix={id} classes={classes} />
                </div>
			}
			{clientType === 'Institutional' &&
				<HoldersQuestion
					classes={classes}
					account={account}
					form={form}
				/>
			}
			
		</React.Fragment>
	);
};

AccountInformation.defaultProps = {
	// confirmMode: false,
};

AccountInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	// actions: PropTypes.object.isRequired,
	account: PropTypes.shape({
		AccountName: PropTypes.string.isRequired,
		TradeCode: PropTypes.string.isRequired,
		Holders: PropTypes.arrayOf(PropTypes.shape({
			FullName: PropTypes.string.isRequired,
			Guid: PropTypes.string,
			ClientId: PropTypes.number.isRequired,
			IsPrimary: PropTypes.isRequired,
		})).isRequired,
	}).isRequired,
	form: PropTypes.string.isRequired,

};

export default AccountInformation;
