
export const phoneCssStyles = {

	uiFlagAD: {
		backgroundPosition: '-7px -7px',
	},
	uiFlagAE: {
		backgroundPosition: '-46px -7px',
	},
	uiFlagAF: {
		backgroundPosition: '-85px -7px',
	},
	uiFlagAG: {
		backgroundPosition: '-124px -7px',
	},
	uiFlagAI: {
		backgroundPosition: '-163px -7px',
	},
	uiFlagAL: {
		backgroundPosition: '-202px -7px',
	},

	uiFlagAM: {
		backgroundPosition: '-241px -7px',
	},
	uiFlagAN: {
		backgroundPosition: '-280px -7px',
	},
	uiFlagAO: {
		backgroundPosition: '-319px -7px',
	},

	uiFlagAR: {
		backgroundPosition: '-358px -7px',
	},
	uiFlagAS: {
		backgroundPosition: '-397px -7px',
	},
	uiFlagAT: {
		backgroundPosition: '-436px -7px',
	},

	uiFlagAU: {
		backgroundPosition: '-475px -7px',
	},
	uiFlagAW: {
		backgroundPosition: '-514px -7px',
	},
	uiFlagAX: {
		backgroundPosition: '-7px -39px',
	},

	uiFlagAZ: {
		backgroundPosition: '-46px -39px',
	},
	uiFlagBA: {
		backgroundPosition: '-85px -39px',
	},
	uiFlagBB: {
		backgroundPosition: '-124px -39px',
	},

	uiFlagBD: {
		backgroundPosition: '-163px -39px',
	},
	uiFlagBE: {
		backgroundPosition: '-202px -39px',
	},
	uiFlagBF: {
		backgroundPosition: '-241px -39px',
	},

	uiFlagBG: {
		backgroundPosition: '-280px -39px',
	},
	uiFlagBH: {
		backgroundPosition: '-319px -39px',
	},
	uiFlagBI: {
		backgroundPosition: '-358px -39px',
	},

	uiFlagBJ: {
		backgroundPosition: '-397px -39px',
	},
	uiFlagBM: {
		backgroundPosition: '-436px -39px',
	},
	uiFlagBN: {
		backgroundPosition: '-475px -39px',
	},

	uiFlagBO: {
		backgroundPosition: '-514px -39px',
	},
	uiFlagBR: {
		backgroundPosition: '-7px -70px',
	},
	uiFlagBS: {
		backgroundPosition: '-46px -70px',
	},

	uiFlagBT: {
		backgroundPosition: '-85px -70px',
	},
	uiFlagBV: {
		backgroundPosition: '-124px -70px',
	},
	uiFlagBW: {
		backgroundPosition: '-163px -70px',
	},


	uiFlagBY: {
		backgroundPosition: '-202px -70px',
	},
	uiFlagBZ: {
		backgroundPosition: '-241px -70px',
	},
	uiFlagCA: {
		backgroundPosition: '-280px -70px',
	},

	uiFlagcatalonia: {
		backgroundPosition: '-319px -70px',
	},
	uiFlagCC: {
		backgroundPosition: '-358px -70px',
	},
	uiFlagCD: {
		backgroundPosition: '-397px -70px',
	},

	uiFlagCF: {
		backgroundPosition: '-436px -70px',
	},
	uiFlagCG: {
		backgroundPosition: '-475px -70px',
	},
	uiFlagCH: {
		backgroundPosition: '-514px -70px',
	},

	uiFlagCI: {
		backgroundPosition: '-7px -102px',
	},
	uiFlagCK: {
		backgroundPosition: '-46px -102px',
	},
	uiFlagCL: {
		backgroundPosition: '-85px -102px',
	},

	uiFlagCM: {
		backgroundPosition: '-124px -102px',
	},
	uiFlagCN: {
		backgroundPosition: '-163px -102px',
	},
	uiFlagCO: {
		backgroundPosition: '-202px -102px',
	},

	uiFlagCR: {
		backgroundPosition: '-241px -102px',
	},
	uiFlagCS: {
		backgroundPosition: '-280px -102px',
	},
	uiFlagCU: {
		backgroundPosition: '-319px -102px',
	},

	uiFlagCV: {
		backgroundPosition: '-358px -102px',
	},
	uiFlagCX: {
		backgroundPosition: '-397px -102px',
	},
	uiFlagCY: {
		backgroundPosition: '-436px -102px',
	},

	uiFlagCZ: {
		backgroundPosition: '-475px -102px',
	},
	uiFlagDE: {
		backgroundPosition: '-514px -102px',
	},
	uiFlagDJ: {
		backgroundPosition: '-7px -133px',
	},


	uiFlagDK: {
		backgroundPosition: '-46px -133px',
	},
	uiFlagDM: {
		backgroundPosition: '-85px -133px',
	},
	uiFlagDO: {
		backgroundPosition: '-124px -133px',
	},

	uiFlagDZ: {
		backgroundPosition: '-163px -133px',
	},
	uiFlagEC: {
		backgroundPosition: '-202px -133px',
	},
	uiFlagEE: {
		backgroundPosition: '-241px -133px',
	},

	uiFlagEG: {
		backgroundPosition: '-280px -133px',
	},
	uiFlagEH: {
		backgroundPosition: '-319px -133px',
	},
	uiFlagEN: {
		backgroundPosition: '-358px -133px',
	},


	uiFlagER: {
		backgroundPosition: '-397px -133px',
	},
	uiFlagES: {
		backgroundPosition: '-436px -133px',
	},
	uiFlagET: {
		backgroundPosition: '-475px -133px',
	},

	uiFlagEU: {
		backgroundPosition: '-514px -133px',
	},
	uiFlageuropeanunion: {
		backgroundPosition: '-7px -165px',
	},
	uiFlagfam: {
		backgroundPosition: '-46px -165px',
	},

	uiFlagFI: {
		backgroundPosition: '-85px -165px',
	},
	uiFlagFJ: {
		backgroundPosition: '-124px -165px',
	},
	uiFlagFK: {
		backgroundPosition: '-163px -165px',
	},

	uiFlagFM: {
		backgroundPosition: '-202px -165px',
	},
	uiFlagFO: {
		backgroundPosition: '-241px -165px',
	},
	uiFlagFR: {
		backgroundPosition: '-280px -165px',
	},

	uiFlagGA: {
		backgroundPosition: '-319px -165px',
	},
	uiFlagGB: {
		backgroundPosition: '-358px -165px',
	},
	uiFlagGD: {
		backgroundPosition: '-397px -165px',
	},

	uiFlagGE: {
		backgroundPosition: '-436px -165px',
	},
	uiFlagGF: {
		backgroundPosition: '-475px -165px',
	},
	uiFlagGH: {
		backgroundPosition: '-514px -165px',
	},

	uiFlagGI: {
		backgroundPosition: '-7px -196px',
	},
	uiFlagGL: {
		backgroundPosition: '-46px -196px',
	},
	uiFlagGM: {
		backgroundPosition: '-85px -196px',
	},

	uiFlagGN: {
		backgroundPosition: '-124px -196px',
	},
	uiFlagGP: {
		backgroundPosition: '-163px -196px',
	},
	uiFlagGQ: {
		backgroundPosition: '-202px -196px',
	},

	uiFlagGR: {
		backgroundPosition: '-241px -196px',
	},
	uiFlagGS: {
		backgroundPosition: '-280px -196px',
	},
	uiFlagGT: {
		backgroundPosition: '-319px -196px',
	},

	uiFlagGU: {
		backgroundPosition: '-358px -196px',
	},
	uiFlagGW: {
		backgroundPosition: '-397px -196px',
	},
	uiFlagGY: {
		backgroundPosition: '-436px -196px',
	},

	uiFlagHK: {
		backgroundPosition: '-475px -196px',
	},
	uiFlagHM: {
		backgroundPosition: '-514px -196px',
	},
	uiFlagHN: {
		backgroundPosition: '-7px -228px',
	},

	uiFlagHR: {
		backgroundPosition: '-46px -228px',
	},
	uiFlagHT: {
		backgroundPosition: '-85px -228px',
	},
	uiFlagHU: {
		backgroundPosition: '-124px -228px',
	},

	uiFlagID: {
		backgroundPosition: '-163px -228px',
	},
	uiFlagIE: {
		backgroundPosition: '-202px -228px',
	},
	uiFlagIL: {
		backgroundPosition: '-241px -228px',
	},

	uiFlagIN: {
		backgroundPosition: '-280px -228px',
	},
	uiFlagHI: {
		backgroundPosition: '-280px -228px',
	},
	uiFlagIO: {
		backgroundPosition: '-319px -228px',
	},

	uiFlagIQ: {
		backgroundPosition: '-358px -228px',
	},
	uiFlagIR: {
		backgroundPosition: '-397px -228px',
	},
	uiFlagFA: {
		backgroundPosition: '-319px -228px',
	},

	uiFlagIS: {
		backgroundPosition: '-436px -228px',
	},
	uiFlagIT: {
		backgroundPosition: '-475px -228px',
	},
	uiFlagJM: {
		backgroundPosition: '-514px -228px',
	},

	uiFlagJO: {
		backgroundPosition: '-7px -259px',
	},

	uiFlagJP: {
		backgroundPosition: '-46px -259px',
	},
	uiFlagKE: {
		backgroundPosition: '-85px -259px',
	},
	uiFlagKG: {
		backgroundPosition: '-124px -259px',
	},

	uiFlagKH: {
		backgroundPosition: '-163px -259px',
	},
	uiFlagKI: {
		backgroundPosition: '-202px -259px',
	},
	uiFlagKM: {
		backgroundPosition: '-241px -259px',
	},

	uiFlagKN: {
		backgroundPosition: '-280px -259px',
	},
	uiFlagKP: {
		backgroundPosition: '-319px -259px',
	},
	uiFlagKR: {
		backgroundPosition: '-358px -259px',
	},

	uiFlagKW: {
		backgroundPosition: '-397px -259px',
	},
	uiFlagKY: {
		backgroundPosition: '-436px -259px',
	},
	uiFlagKZ: {
		backgroundPosition: '-475px -259px',
	},

	uiFlagLA: {
		backgroundPosition: '-514px -259px',
	},
	uiFlagLB: {
		backgroundPosition: '-7px -291px',
	},
	uiFlagLC: {
		backgroundPosition: '-46px -291px',
	},

	uiFlagLI: {
		backgroundPosition: '-85px -291px',
	},
	uiFlagLK: {
		backgroundPosition: '-124px -291px',
	},
	uiFlagLR: {
		backgroundPosition: '-163px -291px',
	},

	uiFlagLS: {
		backgroundPosition: '-202px -291px',
	},
	uiFlagLT: {
		backgroundPosition: '-241px -291px',
	},
	uiFlagLU: {
		backgroundPosition: '-280px -291px',
	},

	uiFlagLV: {
		backgroundPosition: '-319px -291px',
	},
	uiFlagLY: {
		backgroundPosition: '-358px -291px',
	},
	uiFlagMA: {
		backgroundPosition: '-397px -291px',
	},

	uiFlagMC: {
		backgroundPosition: '-436px -291px',
	},
	uiFlagMD: {
		backgroundPosition: '-475px -291px',
	},
	uiFlagME: {
		backgroundPosition: '-514px -291px',
	},

	uiFlagMG: {
		backgroundPosition: '-7px -324px',
	},
	uiFlagMH: {
		backgroundPosition: '-46px -324px',
	},
	uiFlagMK: {
		backgroundPosition: '-85px -324px',
	},

	uiFlagML: {
		backgroundPosition: '-124px -324px',
	},
	uiFlagMM: {
		backgroundPosition: '-163px -324px',
	},
	uiFlagMN: {
		backgroundPosition: '-202px -324px',
	},

	uiFlagMO: {
		backgroundPosition: '-241px -324px',
	},
	uiFlagMP: {
		backgroundPosition: '-280px -324px',
	},
	uiFlagMQ: {
		backgroundPosition: '-319px -324px',
	},

	uiFlagMR: {
		backgroundPosition: '-358px -324px',
	},
	uiFlagMS: {
		backgroundPosition: '-397px -324px',
	},
	uiFlagMT: {
		backgroundPosition: '-436px -324px',
	},

	uiFlagMU: {
		backgroundPosition: '-475px -324px',
	},
	uiFlagMV: {
		backgroundPosition: '-514px -324px',
	},
	uiFlagMW: {
		backgroundPosition: '-7px -355px',
	},

	uiFlagMX: {
		backgroundPosition: '-46px -355px',
	},
	uiFlagMY: {
		backgroundPosition: '-85px -355px',
	},
	uiFlagMZ: {
		backgroundPosition: '-124px -355px',
	},

	uiFlagNA: {
		backgroundPosition: '-163px -355px',
	},
	uiFlagNC: {
		backgroundPosition: '-202px -355px',
	},
	uiFlagNE: {
		backgroundPosition: '-241px -355px',
	},

	uiFlagNF: {
		backgroundPosition: '-280px -355px',
	},
	uiFlagNG: {
		backgroundPosition: '-319px -355px',
	},
	uiFlagNI: {
		backgroundPosition: '-358px -355px',
	},

	uiFlagNL: {
		backgroundPosition: '-397px -355px',
	},
	uiFlagNO: {
		backgroundPosition: '-436px -355px',
	},
	uiFlagNP: {
		backgroundPosition: '-475px -355px',
	},

	uiFlagNR: {
		backgroundPosition: '-336px -355px',
	},
	uiFlagNU: {
		backgroundPosition: '-7px -387px',
	},
	uiFlagNZ: {
		backgroundPosition: '-46px -387px',
	},

	uiFlagOM: {
		backgroundPosition: '-85px -387px',
	},
	uiFlagPA: {
		backgroundPosition: '-124px -387px',
	},
	uiFlagPE: {
		backgroundPosition: '-163px -387px',
	},

	uiFlagPF: {
		backgroundPosition: '-202px -387px',
	},
	uiFlagPG: {
		backgroundPosition: '-241px -387px',
	},
	uiFlagPH: {
		backgroundPosition: '-280px -387px',
	},

	uiFlagPK: {
		backgroundPosition: '-319px -387px',
	},
	uiFlagPL: {
		backgroundPosition: '-358px -387px',
	},
	uiFlagPM: {
		backgroundPosition: '-397px -387px',
	},

	uiFlagPN: {
		backgroundPosition: '-436px -387px',
	},
	uiFlagPR: {
		backgroundPosition: '-475px -387px',
	},
	uiFlagPS: {
		backgroundPosition: '-514px -387px',
	},

	uiFlagPT: {
		backgroundPosition: '-7px -418px',
	},
	uiFlagPW: {
		backgroundPosition: '-46px -418px',
	},
	uiFlagPY: {
		backgroundPosition: '-85px -418px',
	},

	uiFlagQA: {
		backgroundPosition: '-124px -418px',
	},
	uiFlagRE: {
		backgroundPosition: '-163px -418px',
	},
	uiFlagRO: {
		backgroundPosition: '-202px -418px',
	},

	uiFlagRS: {
		backgroundPosition: '-241px -418px',
	},
	uiFlagRU: {
		backgroundPosition: '-280px -418px',
	},
	uiFlagRW: {
		backgroundPosition: '-319px -418px',
	},

	uiFlagSA: {
		backgroundPosition: '-358px -418px',
	},
	uiFlagSB: {
		backgroundPosition: '-397px -418px',
	},
	uiFlagSC: {
		backgroundPosition: '-436px -418px',
	},

	uiFlagscotland: {
		backgroundPosition: '-475px -418px',
	},
	uiFlagSD: {
		backgroundPosition: '-514px -418px',
	},
	uiFlagSE: {
		backgroundPosition: '-7px -450px',
	},

	uiFlagSG: {
		backgroundPosition: '-46px -450px',
	},
	uiFlagSH: {
		backgroundPosition: '-85px -450px',
	},
	uiFlagSI: {
		backgroundPosition: '-124px -450px',
	},

	uiFlagSJ: {
		backgroundPosition: '-163px -450px',
	},
	uiFlagSK: {
		backgroundPosition: '-202px -450px',
	},
	uiFlagSL: {
		backgroundPosition: '-241px -450px',
	},

	uiFlagSM: {
		backgroundPosition: '-280px -450px',
	},
	uiFlagSN: {
		backgroundPosition: '-319px -450px',
	},
	uiFlagSO: {
		backgroundPosition: '-358px -450px',
	},

	uiFlagSR: {
		backgroundPosition: '-397px -450px',
	},
	uiFlagST: {
		backgroundPosition: '-436px -450px',
	},
	uiFlagSV: {
		backgroundPosition: '-475px -450px',
	},

	uiFlagSY: {
		backgroundPosition: '-514px -450px',
	},
	uiFlagSZ: {
		backgroundPosition: '-7px -481px',
	},

	uiFlagTC: {
		backgroundPosition: '-46px -481px',
	},
	uiFlagTD: {
		backgroundPosition: '-85px -481px',
	},
	uiFlagTF: {
		backgroundPosition: '-124px -481px',
	},

	uiFlagTG: {
		backgroundPosition: '-163px -481px',
	},
	uiFlagTH: {
		backgroundPosition: '-202px -481px',
	},
	uiFlagTJ: {
		backgroundPosition: '-241px -481px',
	},

	uiFlagTK: {
		backgroundPosition: '-280px -481px',
	},
	uiFlagTL: {
		backgroundPosition: '-319px -481px',
	},
	uiFlagTM: {
		backgroundPosition: '-358px -481px',
	},

	uiFlagTN: {
		backgroundPosition: '-397px -481px',
	},
	uiFlagTO: {
		backgroundPosition: '-436px -481px',
	},
	uiFlagTR: {
		backgroundPosition: '-475px -481px',
	},

	uiFlagTT: {
		backgroundPosition: '-514px -481px',
	},
	uiFlagTV: {
		backgroundPosition: '-7px -514px',
	},
	uiFlagTW: {
		backgroundPosition: '-46px -514px',
	},

	uiFlagTZ: {
		backgroundPosition: '-85px -514px',
	},
	uiFlagUA: {
		backgroundPosition: '-124px -514px',
	},
	uiFlagUG: {
		backgroundPosition: '-163px -514px',
	},

	uiFlagUM: {
		backgroundPosition: '-202px -514px',
	},
	uiFlagUS: {
		backgroundPosition: '-241px -514px',
	},
	uiFlagUY: {
		backgroundPosition: '-280px -514px',
	},

	uiFlagUZ: {
		backgroundPosition: '-319px -514px',
	},
	uiFlagVA: {
		backgroundPosition: '-358px -514px',
	},
	uiFlagVC: {
		backgroundPosition: '-397px -514px',
	},

	uiFlagVE: {
		backgroundPosition: '-436px -514px',
	},
	uiFlagVG: {
		backgroundPosition: '-475px -514px',
	},
	uiFlagVI: {
		backgroundPosition: '-514px -514px',
	},

	uiFlagVN: {
		backgroundPosition: '-553px -7px',
	},
	uiFlagVU: {
		backgroundPosition: '-553px -39px',
	},
	uiFlagwales: {
		backgroundPosition: '-546px -70px',
	},

	uiFlagWF: {
		backgroundPosition: '-553px -102px',
	},
	uiFlagWS: {
		backgroundPosition: '-553px -133px',
	},
	uiFlagYE: {
		backgroundPosition: '-553px -165px',
	},

	uiFlagYT: {
		backgroundPosition: '-553px -196px',
	},
	uiFlagZA: {
		backgroundPosition: '-553px -228px',
	},
	uiFlagZM: {
		backgroundPosition: '-553px -259px',
	},

	uiFlagZW: {
		backgroundPosition: '-553px -291px',
	},
	uiFlagnone: {
		backgroundPosition: '-553px -553px',
	},
};
export default phoneCssStyles;
