import actions from './actions';

const initialState = {
	authWidget: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.AUTH_WIDGET:
		return {
			...state,
			authWidget: payload,
		};
	case actions.LOGIN_USER:
		return {
			...state,
			...payload,
		};
	case actions.LOGOUT:
		return {
			...state,
		};
	case actions.SET_AFTER_AUTH_URL:
		return {
			...state,
			afterAuthUrl: payload,
		};
	default:
		return state;
	}
};
