import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from '@global';

import { ModalPopups } from '@enums';
import { app, userAccounts } from '@redux';
import BankAccountItem from '../BankAccountItem/BankAccountItem';

const BankAccountItemCryptoWalletCY = (props) => {
	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);
	const dispatch = useDispatch();

	const showWithdrawFundsModal = (bankAccount) => {
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};
		dispatch(app.actions.showBaseModal(ModalPopups.CY_WITHDRAWAL_CRYPTOWALLET, { userAccount, bankAccount }));
	};

	const handleWithdrawal = (bankAccount) => {
		showWithdrawFundsModal(bankAccount);
	};


	return (
		<BankAccountItem
			isLimeInternationalStyle
			bankAccount={{
				Type: 'CryptoWalletWithdraw',
				// displayNumber: translate('common_crypto_wallet', 'Crypto Wallet'),
				displayNumber: translate('shared_withdrawal_funds', 'Withdrawal Funds'),
			}}
			hasDepositButton={() => false}
			hasWithdrawalButton={() => true}
			onWithdrawFundsModal={handleWithdrawal}
		/>
	);
};

export default BankAccountItemCryptoWalletCY;
