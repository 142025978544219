import { ModalPopups } from '@enums';
import actions from './actions';
import authActions from '../auth/actions';

const initialState = {
	profile: {
		submitting: true,
		PersonalInformation: {},
		Experiences: [],
		InvestmentProfile: {},
		EmploymentProfile: {},
		PendingOrders: {},
		Documents: [],
		TaxForms: [],
		MarketDataSubscriptions: [],
		error: false,
	},
	changeTariff: {
		submitting: false,
		submitSucceeded: false,
	},
	changeTaxForm: {},
	forceCheckPaperless: false,
	confirmEmail: {
		submitting: true,
	},
	holders: {
		Profiles: [],
		submitting: false,
		loaded: false,
	},
	modals: {
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_PROFILE_REQUEST:
			return { ...state };
		case actions.GET_PROFILE_SUCCESS:
			return {
				...state,
				profile: {
					...payload,
					submitting: false,
					error: false,
				},
			};
		case actions.GET_PROFILE_FAILURE:
			return {
				...state,
				profile: {
					submitting: false,
					PersonalInformation: {},
					Experiences: [],
					InvestmentProfile: {},
					EmploymentProfile: {},
					PendingOrders: {},
					Documents: [],
					TaxForms: [],
					error: true,
				},
			};
		case actions.getHolderProfiles.REQUEST:
			return {
				...state,
				holders: {
					submitting: true,
					error: false,
				},
			};
		case actions.getHolderProfiles.SUCCESS:
			return {
				...state,
				holders: {
					submitting: false,
					Profiles: payload.response.Profiles,
					loaded: true,
					error: false,
				},
			};
		case actions.getHolderProfiles.FAILURE:
			return {
				...state,
				holders: {
					submitting: false,
					Profiles: [],
					loaded: true,
					error: true,
				},
			};
		case actions.SHOW_CHANGE_EMAIL_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_PROFILE_EMAIL]: payload,
				},
			};
		case actions.confirmEmailGetUrl.SUCCESS:
			return {
				...state,
				confirmEmail: {
					submitting: false,
					success: true,
				},
			};
		case actions.confirmEmailGetUrl.FAILURE:
			return {
				...state,
				confirmEmail: {
					submitting: false,
					success: false,
					error: payload.error.errors._error,
				},
			};
		case actions.GET_PROFILE_REQUEST:
			return { ...state };
		case actions.GET_PROFILE_SUCCESS:
			return {
				...state,
				profile: {
					...payload,
					submitting: false,
					error: false,
				},
			};
		case actions.GET_PROFILE_FAILURE:
			return {
				...state,
				profile: {
					submitting: false,
					PersonalInformation: {},
					Experiences: [],
					InvestmentProfile: {},
					EmploymentProfile: {},
					PendingOrders: {},
					Documents: [],
					TaxForms: [],
					error: true,
				},
			};
		case actions.getHolderProfiles.REQUEST:
			return {
				...state,
				holders: {
					submitting: true,
					error: false,
				},
			};
		case actions.getHolderProfiles.SUCCESS:
			return {
				...state,
				holders: {
					submitting: false,
					Profiles: payload.response.Profiles,
					loaded: true,
					error: false,
				},
			};
		case actions.getHolderProfiles.FAILURE:
			return {
				...state,
				holders: {
					submitting: false,
					Profiles: [],
					loaded: true,
					error: true,
				},
			};
		case actions.SHOW_CHANGE_EMAIL_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_PROFILE_EMAIL]: payload,
				},
			};
		case actions.confirmEmailGetUrl.SUCCESS:
			return {
				...state,
				confirmEmail: {
					submitting: false,
					success: true,
				},
			};
		case actions.confirmEmailGetUrl.FAILURE:
			return {
				...state,
				confirmEmail: {
					submitting: false,
					success: false,
					error: payload.error.errors._error,
				},
			};

		case actions.CHANGE_ACCOUNT_TARIFF_REQUEST:
			return {
				...state,
				changeTariff: {
					submitting: true,
					submitSucceeded: false,
				},
			};
		case actions.CHANGE_ACCOUNT_TARIFF_SUCCESS:
			return {
				...state,
				changeTariff: {
					submitting: false,
					submitSucceeded: true,
				},
			};
		case actions.CHANGE_ACCOUNT_TARIFF_RESET:
		case actions.CHANGE_ACCOUNT_TARIFF_FAILURE:
			return {
				...state,
				changeTariff: {
					submitting: false,
					submitSucceeded: false,
				},
			};
		case actions.SHOW_CHANGE_TAX_FORM_MODAL:
			return {
				...state,
				changeTaxForm: {
					FormType: payload.FormType,
				},
			};
		case actions.SHOW_CHANGE_DELIVERY_TYPE_MODAL:
			return {
				...state,
				forceCheckPaperless: payload.forceCheckPaperless,
			};
		case actions.CHANGE_ACCOUNT_TARIFF_REQUEST:
			return {
				...state,
				changeTariff: {
					submitting: true,
					submitSucceeded: false,
				},
			};
		case actions.CHANGE_ACCOUNT_TARIFF_SUCCESS:
			return {
				...state,
				changeTariff: {
					submitting: false,
					submitSucceeded: true,
				},
			};
		case actions.CHANGE_ACCOUNT_TARIFF_RESET:
		case actions.CHANGE_ACCOUNT_TARIFF_FAILURE:
			return {
				...state,
				changeTariff: {
					submitting: false,
					submitSucceeded: false,
				},
			};
		case actions.SHOW_CHANGE_TAX_FORM_MODAL:
			return {
				...state,
				changeTaxForm: {
					FormType: payload.FormType,
				},
			};
		case actions.SHOW_CHANGE_DELIVERY_TYPE_MODAL:
			return {
				...state,
				forceCheckPaperless: payload.forceCheckPaperless,
			};

		case actions.getCountries.SUCCESS:
			return {
				...state,
				CountryList: [...payload.response.Items],
			};
		case actions.getUsaStates.SUCCESS:
			return {
				...state,
				UsaStatesList: [...payload.response.Items],
			};
		case actions.SHOW_CHANGE_EMPLOYMENT_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_EMPLOYMENT]: payload,
				},
			};
		case actions.SHOW_CHANGE_ADDRESS_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_PROFILE_ADDRESS]: payload,
				},
			};
		case actions.SHOW_CHANGE_POST_ADDRESS_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_PROFILE_POST_ADDRESS]: payload,
				},
			};
		case actions.SHOW_CHANGE_PHONE_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[ModalPopups.CHANGE_PROFILE_PHONE]: payload,
				},
			};
		case actions.HIDE_PROFILE_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[payload]: undefined,
				},
			};
		case actions.UPDATE_MARKET_DATA_SUBSCRIPTION:
			return {
				...state,
				profile: {
					...state.profile,
					MarketDataSubscriptions: state.profile.MarketDataSubscriptions.map(sub =>
						(sub.MarketDataName === payload.MarketDataName ? { ...sub, ...payload } : sub)),
				},
			};
		case authActions.LOGIN_USER:
			return {
				...initialState,
			};
		case authActions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
