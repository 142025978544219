import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { cabinetLog, scrollIntoView } from '@global';
import { auth } from '@redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Button, FormInput, R } from '@components';

const styles = {
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	confirmLink: {
		marginTop: '16px',
		paddingLeft: '24px',
	},
	link: {
		textTransform: 'uppercase',
	},
	button: {
		paddingTop: '24px',
	},
};



const mapDispatchToProps = dispatch => ({
	actions: {
		sendConfirmationCode: (phone, reason) => dispatch(auth.actions.sendConfirmationCodeRequest(phone, reason || 'Confirmation')),
	},
});

class RequireConfirmationCodeItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			hasConfirmationCode: false,
			showPassword: false,
			password: '',
			currentCount: 0,
			intervalId: null,
		};
	}

	componentWillUnmount() {
		// use intervalId from the state to clear the interval
		clearInterval(this.state.intervalId);
	}


	componentDidUpdate() {
		const {
			hasConfirmationCode,
			currentCount,
		} = this.state;

		if (hasConfirmationCode && currentCount == 120) {
			scrollIntoView('js-confirm-code-place');
		}
	}

	onRequestVerificationCode = () => {
		const {
			actions,
			reason,
			onSendCode,
		} = this.props;

		if (onSendCode && (typeof onSendCode === 'function')) {
			onSendCode(null, reason);
		} else {
			actions.sendConfirmationCode(null, reason);
		}
		
		const intervalId = setInterval(this.timer, 1000);
		this.setState({
			hasConfirmationCode: true,
			currentCount: 120,
			intervalId,
		});
	}

	handleClickShowSmsCode = () => this.setState({ showPassword: !this.state.showPassword });

	timer = () => {
		if (this.state.currentCount === 0) {
			clearInterval(this.state.intervalId);
		} else {
			this.setState({ currentCount: this.state.currentCount - 1 });
		}
	}

	render() {
		const {
			classes,
			customClasses,
			disableConfirmation,
			name,
			children,
			buttonTitle,
			disabled,
			onSendCode, // for not includes in rest!!!
			...rest,
		} = this.props;

		const {
			hasConfirmationCode,
			currentCount,
		} = this.state;

		if(disableConfirmation) {
			return (
				<div className={(customClasses && customClasses.button) || classes.button}>
					{children}
				</div>)
		}

		return (
			<React.Fragment>
				<div id="js-confirm-code-place" className={(customClasses && customClasses.inputItem) || classes.inputItem}>
					{hasConfirmationCode && currentCount > 0 &&
						<Field
							component={FormInput}
							name={name}
							placeholder={< R id ="shared_verification_code" defaultValue="Verification Code" />}
							label={< R id ="shared_verification_code" defaultValue="Verification Code" />}
							type={this.state.showPassword ? 'text' : 'password'}
							value={this.state.password}
							{...rest}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="Toggle password visibility"
										onClick={this.handleClickShowSmsCode}
									>
										{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
					}
					<div className={(customClasses && customClasses.confirmLink) || classes.confirmLink}>
						{hasConfirmationCode &&
							<p>
							   <R id="common_code_phone_number"	
							  	  defaultValue="In case you have not received the code, please check the phone number above and retry."
							   />
							</p>
						}
						{currentCount > 0 &&
							<p>
								<R id="common_new_code_in_seconds" translateParameters={{ seconds: currentCount }} defaultValue="You can request new code in {seconds} seconds" />
							</p>
						}
					</div>
				</div>
				<div className={(customClasses && customClasses.button) || classes.button}>
					{hasConfirmationCode && currentCount > 0 &&
						<React.Fragment>
							{children}
						</React.Fragment>
					}
					{(currentCount === 0 || !hasConfirmationCode) &&
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={this.onRequestVerificationCode}
							disabled={disabled}
						>
							{ buttonTitle } 
						</Button>
					}
				</div>
			</React.Fragment>
		);
	}
}

RequireConfirmationCodeItem.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	/**
	 * При указании true не будет запрашиваться подтверждение, т.е. просто будет выведен контент компонента
	 */
	disableConfirmation: PropTypes.object,
};

RequireConfirmationCodeItem.defaultProps = {
	disableConfirmation: false,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(RequireConfirmationCodeItem);
