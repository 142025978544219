import { all, call, put, fork, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { activatePlatform, changeTariff } from '@api';
import { env } from '@config';
import { AccountPlatforms, AccountTariffs, ModalPopups } from '@enums';
import { addHashToUrl, captureException, removeHashesFromUrl } from '@global';
import { auth, callApi, snackbar, userAccounts } from '@redux';
import actions from './actions';


function* activatePlatformsRequest({ payload: { account, tradePlatform } }) {
	try {
		const response = yield callApi(activatePlatform, account.tradeCode, tradePlatform);

		if (!response.data.Success) {
			yield put(actions.activatePlatformFailure(response.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Activate Trade Platform', text: response.data.Errors[0].Message }));
		} else {
			yield put(userAccounts.actions.getAccountInfoRequest(account));
			yield put(actions.activatePlatformSuccess(response.data));
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Activate Trade Platform', text: error.message }));
		yield put(actions.activatePlatformFailure(error.message));
	}
}

function* activatePlatformsSuccess() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashesFromUrl(
		location,
		[ModalPopups.J2T_LITE, ModalPopups.J2T_PLUS, ModalPopups.J2T_PRO, ModalPopups.MT5, ModalPopups.ST_PRO],
	)));
}


function* showJ2tLiteModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.J2T_LITE)));
}

function* showJ2tPlusModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.J2T_PLUS)));
}

function* showJ2tProModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.J2T_PRO)));
}

function* showMt5Modal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.MT5)));
}

function* showStProModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.ST_PRO)));
}

function* activateCommissionFreeSaga({ payload: { account, accountInfo: { Tariffs }, platforms } })
{
	try {
		const mustChangePlatform = !platforms || (platforms.ActiveTradePlatform !== AccountPlatforms.PLATFORM_TRANSAQ
			&& platforms.PendingTradePlatform !== AccountPlatforms.PLATFORM_TRANSAQ);
		const mustChangeTariff = !Tariffs || (Tariffs.ActiveTariff !== AccountTariffs.TARIFF_ZERO
			&& Tariffs.PendingTariff !== AccountTariffs.TARIFF_ZERO);

		const tasks = [];
		if (mustChangePlatform) {
			tasks.push(yield callApi(activatePlatform, account.tradeCode, AccountPlatforms.PLATFORM_TRANSAQ));
		}
		if (mustChangeTariff) {
			tasks.push(yield callApi(changeTariff, account.tradeCode, AccountTariffs.TARIFF_ZERO));
		}

		if (tasks.length === 0) {
			yield put(actions.activateCommissionFree.success());
			return;
		}

		const responseArray = yield all(tasks);

		let index = 0;
		while (index < responseArray.length) {
			if (!responseArray[index].data.Success) {
				yield put(actions.activateCommissionFree.failure(responseArray[index].data.Errors[0].Message));
				yield put(snackbar.actions.showErrorMessage({
					title: 'Activate Commission Free',
					text: responseArray[index].data.Errors[0].Message,
				}));
				yield put(userAccounts.actions.getAccountInfoRequest(account));
				return;
			}
			index += 1;
		}
		yield put(userAccounts.actions.getAccountInfoRequest(account));
		yield put(actions.activateCommissionFree.success());
	} catch (error) {
		window.Raven.captureException(error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Activate Commission Free', text: error.message }));
		yield put(actions.activateCommissionFree.failure(error.message));
	}
}

function* openTradexUrlSaga({ payload })
{
	try	{
		const authWidget = yield select(auth.selectors.authWidget);
		if (authWidget) {
			const ott = yield call(authWidget.oneTimeToken, env.widgetProvider);
			window.open(`${payload}?ott=${ott}`, '_blank').focus();
		} else {
			window.open(payload, '_blank').focus();
		}
		yield put(actions.openTradexUrl.success());
		return true;

	} catch (error) {
		captureException(actions.openTradexUrl.REQUEST, error);
		yield put(snackbar.actions.showErrorMessage({
			title: 'Open Tradex Error',
			text: error.message,
		}));
		yield put(actions.openTradexUrl.failure());
		console.error(error);
		return false;
	}
}

// Watchers
function* watchActivatePlatformRequest() {
	yield takeEvery(actions.ACTIVATE_PLATFORM_REQUEST, activatePlatformsRequest);
}
function* watchActivatePlatformSuccess() {
	yield takeEvery(actions.ACTIVATE_PLATFORM_SUCCESS, activatePlatformsSuccess);
}
function* watchShowJ2tLiteModal() {
	yield takeEvery(actions.SHOW_J2T_LITE_MODAL, showJ2tLiteModal);
}
function* watchShowJ2tPlusModal() {
	yield takeEvery(actions.SHOW_J2T_PLUS_MODAL, showJ2tPlusModal);
}
function* watchShowJ2tProModal() {
	yield takeEvery(actions.SHOW_J2T_PRO_MODAL, showJ2tProModal);
}
function* watchShowMt5Modal() {
	yield takeEvery(actions.SHOW_MT5_MODAL, showMt5Modal);
}
function* watchShowStProModal() {
	yield takeEvery(actions.SHOW_STPRO_MODAL, showStProModal);
}
function* watchActivateCommissionFreeRequest() {
	yield takeEvery(actions.activateCommissionFree.REQUEST, activateCommissionFreeSaga);
}
function* watchOpenTradexUrlRequest() {
	yield takeEvery(actions.openTradexUrl.REQUEST, openTradexUrlSaga);
}

// Root saga
const rootSaga = [
	fork(watchActivatePlatformRequest),
	fork(watchActivatePlatformSuccess),
	fork(watchShowJ2tLiteModal),
	fork(watchShowJ2tPlusModal),
	fork(watchShowJ2tProModal),
	fork(watchShowMt5Modal),
	fork(watchShowStProModal),
	fork(watchActivateCommissionFreeRequest),
	fork(watchOpenTradexUrlRequest),
];

export default {
	rootSaga,
};
