import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	A,
	TabContainer,
	FileInput,
	FormSelect,
	FormDatePicker,
	FormInput,
	FormRadioGroup,
	Radio,
	Button,
	SelectField,
	ProgressContainer,
	FormCheckbox,
} from '@components';
import { profile } from '@redux';
import { TaxFormType } from '@enums';
import { constants } from '@config';
import moment from 'moment';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	inputItem: {
		marginTop: '14px',
		'& > div > div': {
			background: theme.palette.color.primary.attestationBackground,
			borderRadius: '8px',
		},
	},
	dateItem: {
		marginTop: '14px',
		'& > div': {
			background: theme.palette.color.primary.attestationBackground,
			borderRadius: '8px',
		},
		'& > div > div': {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},
	item: {
		marginTop: '30px',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	attachFileText: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		marginTop: '24px',
	},
	fileUploader: {
		marginTop: '16px',
	},
});

const TAX_FORM_UPLOAD_FORM = 'taxFormUploadForm';

const mapStateToProps = (state, ownProps) => {
	const formSelector = formValueSelector(ownProps.form || TAX_FORM_UPLOAD_FORM);
	const personalInformation = profile.selectors.profile(state).PersonalInformation;
	const countries = profile.selectors.getCountries(state);
	return {
		attachmentsValue: formSelector(state, 'Attachments'),
		getFormValue: fieldName => formSelector(state, fieldName),
		initialValues: {
			Type: ownProps.FormType,
			ResidentialCountry: personalInformation.Address && personalInformation.Address.Country,
			TaxID: personalInformation.Ftin,
			TaxCountry: personalInformation.Address && personalInformation.Address.Country,
			SignatureDate: moment().toISOString(),
		},
		countries,
		loading: !(countries && !profile.submitting),
	};
};
const mapDispatchToProps = dispatch => ({
	actions: {
		refreshCountriesList: () => dispatch(profile.actions.getCountries.request()),
	},
});

const parseMomentDate = (d) => {
	if ((typeof d) === 'object') {
		return d.toISOString();
	}
	return undefined;
};

class TaxFormUploadTabComponent extends React.Component {
	static validate(values, props) {
		const errors = {};

		if (!values) {
			return errors;
		}
		if (!values.ResidentialCountry) {
			errors.ResidentialCountry = 'Required';
		}

		if (!values.TaxID) {
			if (values.ResidentialCountry === 'RU' || !values.NoTINReason) {
				errors.TaxID = 'Required';
			}
		} else if (values.ResidentialCountry === 'RU' && !(/^(\d{12})$/.test(values.TaxID))) {
			errors.TaxID = 'Invalid number';
		} else if (values.TaxID.length > constants.maxCharsNumber) {
			errors.TaxID = constants.maxCharsNumberError;
		}

		return errors;
	}
	componentDidMount() {
		const { countries, actions } = this.props;
		if (!countries) {
			actions.refreshCountriesList();
		}
	}

	onFilesUpload = (field, files) => this.props.change(field, files);

	render() {
		const {
			attachmentsValue,
			getFormValue,
			classes,
			theme,
			handleSubmit,
			invalid,
			loading,
			submitting,
			countries,
			initialValues,
			FormType,
		} = this.props;
		const noAttachments = !attachmentsValue || attachmentsValue.length === 0;
		const countryItems = (countries && countries
			.sort((a, b) => (a.Name < b.Name ? -1 : 1))
			.map(cntr => ({ value: cntr.CodeLat2, text: cntr.Name }))) || [];
		return (
			<TabContainer>
				<ProgressContainer submitting={loading} >
					<form
						onSubmit={handleSubmit(profile.actions.changeTaxFormUploadFormSubmitHandler)}
						className={classes.root}
					>
						<div className={classes.item}>
							<SelectField
								component={FormSelect}
								label="Residential country"
								name="ResidentialCountry"
								items={countryItems}
								value={initialValues.ResidentialCountry || ''}
							/>
						</div>
						<div className={classes.item}>
							<SelectField
								component={FormSelect}
								label="Claim of Tax Treaty Benefits country"
								name="TaxCountry"
								items={countryItems}
								value={initialValues.TaxCountry || ''}
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="TaxID"
								placeholder="Foreign tax identifying number"
								label="Foreign tax identifying number"
							/>
						</div>
						{!getFormValue('TaxID') && getFormValue('ResidentialCountry') !== 'RU' &&
							<div className={classes.item}>
								<Field
									component={FormCheckbox}
									name="NoTINReason"
									label="TIN is not required by domestic law"
								/>
							</div>
						}
						<div className={classes.dateItem}>
							<Field
								component={FormDatePicker}
								name="SignatureDate"
								label="Signature date"
								parse={parseMomentDate}
								onFocus={() => undefined}
							/>
						</div>
						{FormType === TaxFormType.W8_BEN &&
							<div className={classes.attachFileText}>
								Empty form can be downloaded from the <A href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf" target="_blank">IRS website</A>
							</div>
						}
						<div className={classes.attachFileText}>
							Please fill in and upload scanned document
						</div>
						<div className={classes.fileUploader}>
							<FileInput
								onFilesUpdate={files => this.onFilesUpload('Attachments', files)}
							/>
						</div>
						<div className={classes.button}>
							<Button
								fullWidth
								type="submit"
								variant="contained"
								color="primary"
								disabled={noAttachments || submitting || invalid}
							>
								{submitting ?
									<CircularProgress
										style={{ color: theme.typography.color.blue }}
										size={18}
									/>
									: 'Update'
								}
							</Button>
						</div>
					</form>
				</ProgressContainer>
			</TabContainer>
		);
	}
}

TaxFormUploadTabComponent.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	countries: PropTypes.array,
	loading: PropTypes.bool.isRequired,
	FormType: PropTypes.string.isRequired,
};

TaxFormUploadTabComponent.defaultProps = {
	countries: null,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: TAX_FORM_UPLOAD_FORM,
		validate: TaxFormUploadTabComponent.validate,
	}),
)(TaxFormUploadTabComponent);
