import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import { R, LoadableScrollbars, SvgIcon } from '@components';
import { cabinetLog, createQAAttribute } from '@global';
import { connect } from 'react-redux';
import { app, notifications, userAccounts } from '@redux';
import { Item } from './components';


const styles = theme => ({
	root: {
		backgroundColor: '#f9fcfd',
		overflow: 'hidden',
	},
	rootMobile: {
		backgroundColor: '#f9fcfd',
		overflow: 'auto',
	},
	body: {
	},
	caption: {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
		height: '56px',
		padding: '14px 0 14px 12px',
		backgroundColor: '#f9fcfd',
		marginRight: 'auto',
	},
	itemContainer: {
		padding: '0 16px',
		'&:hover': {
			backgroundColor: '#e3edf5',
		},
	},
	item: {
		padding: '12px 0',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	text: {
		...theme.typography.style.title,
		color: theme.typography.color.secondary,
		textAlign: 'center',
	},
});

const mapStateToProps = state => ({
	items: notifications.selectors.notifications(state).items,
	pagination: notifications.selectors.notifications(state).pagination,
	unreadMessagesCount: notifications.selectors.notifications(state).unreadMessagesCount,
	currentAccount: userAccounts.selectors.currentAccount(state),
	loading: notifications.selectors.loading(state),
});

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		loadMore: (tradeCode, page) =>
			dispatch(notifications.actions.getMoreNotificationsRequest(tradeCode, page + 1)),
		markNewsAllAsRead: payload => dispatch(notifications.actions.markNewsAllAsRead.request(payload)),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
	},
});

class NotificationsContent extends React.Component {
	componentDidMount() {
		// window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		// window.removeEventListener('scroll', this.handleScroll);
	}

	scrollY = -1;

	handleScroll = () => {
		const {
			mobileView,
		} = this.props;

		if (mobileView) {
			return;
		}

		// stop loading for horizontal scroll
		if (this.scrollY >= 0 && this.scrollY === window.scrollY) {
			return;
		}

		this.scrollY = window.scrollY;

		this.loadNotificationsByScrollEvent();
	};

	loadNotificationsByScrollEvent = () => {
		const {
			currentAccount,
			items,
			loading,
			pagination: {
				page,
				pageSize,
			},
		} = this.props;

		const shouldLoadMore = (page * pageSize) === items.length;

		// stop loading new if loading now
		if (loading || !shouldLoadMore) {
			return;
		}

		const viewportHeight = Math.min(document.documentElement.clientHeight, window.innerHeight || 0);

		// get notifications block rectangle
		const rect = document.getElementById('notifications').getBoundingClientRect();

		// offset notifications block from top when should load new messages
		const notificationBlockTopOffsetForLoadingNew = viewportHeight * 0.6;

		// difference notifications block height with body height
		const notificationBlockWithBodyHeightDifference = Math.abs(rect.height - document.body.clientHeight);

		// minimal difference for enabling scrolling down. Expand body when notifications block grows to body height.
		const notificationBlockWithBodyHeightDifferenceForLoadingNew = viewportHeight;


		// enlarge window height for preventing stop scrolling down
		if (notificationBlockWithBodyHeightDifference < notificationBlockWithBodyHeightDifferenceForLoadingNew) {
			document.body.style.height = `${document.body.clientHeight + notificationBlockWithBodyHeightDifferenceForLoadingNew}px`;
			cabinetLog('loadMore');
			this.props.actions.loadMore(currentAccount, page);
		}

		// when scrolling down end of notifications block - load new notifications
		if (shouldLoadMore && rect.bottom - notificationBlockTopOffsetForLoadingNew < 0) {
			cabinetLog('loadMore2');
			this.props.actions.loadMore(currentAccount, page);
		}
	};


	loadMore = () => {
		const {
			currentAccount,
			pagination: {
				page,
			},
		} = this.props;
		this.props.actions.loadMore(currentAccount, page);
	};
	markAllAsRead = () => {
		const { actions, onClose, unreadMessagesCount } = this.props;
		if (onClose) {
			onClose();
		}
		actions.showConfirmModal({
			text: `Please confirm if you want to mark all notifications (${unreadMessagesCount}) as read`,
			action: onEndRequest => actions.markNewsAllAsRead({
				onEndRequest,
			}),
		});
	}

	render() {
		const {
			classes,
			mobileViewOpened,
			onClose,
			items,
			loading,
			pagination: {
				page,
				pageSize,
			},
			unreadMessagesCount,
		} = this.props;

		const shouldLoadMore = (page * pageSize) === items.length;
		const rootNotificationsClass = mobileViewOpened ? classes.rootMobile : classes.root;
		return (
			<div id="notifications" className={rootNotificationsClass}>
				<LoadableScrollbars
					autoHide
					style={{ height: '100vh' }}
					canLoadMore={shouldLoadMore}
					loading={loading}
					onLoadMore={this.loadMore}
				>
					<div className={classes.header}>
						<div className={classes.caption} {...createQAAttribute('notify_label')}>
							<R id="common_notifications" defaultValue="Notifications" />
						</div>
						{unreadMessagesCount > 0 &&
							<div className={classes.icon}>
								<IconButton onClick={this.markAllAsRead} {...createQAAttribute('mark_notify')}>
									<SvgIcon icon="MarkAsRead" />
								</IconButton>
							</div>
						}
						{onClose &&
							<div className={classes.icon}>
								<IconButton onClick={onClose}>
									<SvgIcon icon="Close" />
								</IconButton>
							</div>
						}
					</div>
					{items &&
						<div className={classes.body}>
							{items.map((item, index) => (
								<div className={classes.itemContainer} key={index}>
									<div className={classes.item}>
										<Item item={item} />
									</div>
								</div>
							))}
						</div>
					}
					{(!items || items.length === 0) && !loading &&
						<div className={classes.text}><R id="shared_no_data_available" defaultValue="No data available" /></div>
					}
				</LoadableScrollbars>
			</div>
		);
	}
}

NotificationsContent.propTypes = {
	mobileView: PropTypes.bool,
	mobileViewOpened: PropTypes.bool,
	unreadMessagesCount: PropTypes.number.isRequired,
};

NotificationsContent.defaultProps = {
	mobileView: false,
	mobileViewOpened: false,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(NotificationsContent);
