import React from 'react';
import compose from 'recompose/compose';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { ModalPopups } from '@enums';
import { changeHashInUrl, removeHashFromUrl, removeHashesFromUrl } from '@global';
import {
	acatDeposit,
	app,
	depositFunds,
	etnaAuth,
	signature,
	notifications,
	withdrawFunds,
	profile,
} from '@redux';
import {
	CloseAccountModal,
	CloseAccountFormModal,
	CreateApiKeyModal,
	CommissionFreeModal,
	ConfirmModal,
	AcatFundModal,
	AddBankAccountModal,
	PlaidSelectAccountModal,
	CYNonTradingOrderModals,
	CYTransferBetweenAccountsModal,
	CYWithdrawalBankTransferModal,
	CYWithdrawalCryptoWalletModal,
	CYWithdrawalMalaysianBankModal,
	DepositFundsAchModal,
	DepositFundsBrokerModal,
	DepositFundsIraModal,
	DepositFundsWireModal,
	IpoCreateOrderModal,
	J2tLiteModal,
	J2tPlusModal,
	J2tProModal,
	Mt5Modal,
	StProModal,
	MessageModal,
	WithdrawFundsWireModal,
	ProfileModals,
	CreateSignatureModal,
	WelcomePageModal,
	WithdrawFundsAchModal,
	WithdrawFundsBrokerModal,
	WithdrawFundsIraModal,
	UpgradeAccountModal,
	UpgradeOptionLevelModal,
	IpoExtendedInfoMobileModal,
	DemoAccountModal,
	ResetDemoBalanceModal,
	ConfirmProfileEmailModal,
	OpenSecondAccountModal,
} from './components';

const mapDispatchToProps = dispatch => ({
	actions: {
		depositFundsAchClose: () => dispatch(depositFunds.actions.hideDepositFundsModal()),
		depositFundsBrokerClose: () => dispatch(depositFunds.actions.hideDepositFundsBrokerModal()),
		depositFundsWireClose: () => dispatch(depositFunds.actions.hideDepositFundsWireModal()),
		hideDepositFundsIraModal: () => dispatch(depositFunds.actions.hideDepositFundsIraModal()),
		hideWithdrawFundsAchModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsAchModal()),
		hideWithdrawFundsWireModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsWireModal()),
		hideWithdrawFundsBrokerModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsBrokerModal()),
		hideWithdrawFundsIraModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsIraModal()),
		hideConfirmModal: () => dispatch(app.actions.hideConfirmModal()),
		hideCloseAccountModal: () => dispatch(acatDeposit.actions.hideCloseAccountModal()),
		hideCloseAccountFormModal: () => dispatch(acatDeposit.actions.hideCloseAccountFormModal()),
		hideAcatDepositModal: () => dispatch(acatDeposit.actions.hideAcatDepositModal()),
		hideMessageModal: () => dispatch(notifications.actions.hideMessageModal()),
		hideCreateSignatureModal: () => dispatch(signature.actions.hideCreateSignatureModal()),
		hideEtnaAuthModal: modalId => dispatch(etnaAuth.actions.hideModal(modalId)),
		hideConfirmProfileEmailModal: () => dispatch(profile.actions.hideConfirmEmailModal()),
	},
});

class Modals extends React.Component {
	getModal = (item) => {
		const {
			actions,
		} = this.props;

		// !!! Modals must be in current order !!!

		switch (item) {
			case ModalPopups.WELCOME_PAGE:
				return (
					<WelcomePageModal
						key={`${ModalPopups.WELCOME_PAGE}Modal`}
						onClose={() => this.closeModal(ModalPopups.WELCOME_PAGE)}
					/>);
			case ModalPopups.ADD_BANK_ACCOUNT:
				return (
					<AddBankAccountModal
						key={`${ModalPopups.ADD_BANK_ACCOUNT}Modal`}
						onClose={() => this.closeModals([ModalPopups.ADD_BANK_ACCOUNT, ModalPopups.PLAID_SELECT_ACCOUNT])}
					/>);
			case ModalPopups.CREATE_APIKEY:
				return (
					<CreateApiKeyModal
						key={`${ModalPopups.CREATE_APIKEY}Modal`}
						onClose={() => actions.hideEtnaAuthModal(ModalPopups.CREATE_APIKEY)}
					/>);
			case ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS:
				return (
					<CYTransferBetweenAccountsModal
						key={`${ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS)}
					/>);
			case ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER:
				return (
					<CYWithdrawalBankTransferModal
						key={`${ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER)}
					/>);
			case ModalPopups.CY_WITHDRAWAL_CRYPTOWALLET:
				return (
					<CYWithdrawalCryptoWalletModal
						key={`${ModalPopups.CY_WITHDRAWAL_CRYPTOWALLET}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_CRYPTOWALLET)}
					/>);
			case ModalPopups.CY_WITHDRAWAL_MALAYSIAN_BANK:
				return (
					<CYWithdrawalMalaysianBankModal
						key={`${ModalPopups.CY_WITHDRAWAL_MALAYSIAN_BANK}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_WITHDRAWAL_MALAYSIAN_BANK)}
					/>);
			case ModalPopups.DEPOSIT_FUNDS_ACH:
				return (
					<DepositFundsAchModal
						key={`${ModalPopups.DEPOSIT_FUNDS_ACH}Modal`}
						onClose={actions.depositFundsAchClose}
					/>);
			case ModalPopups.DEPOSIT_FUNDS_BROKER:
				return (
					<DepositFundsBrokerModal
						key={`${ModalPopups.DEPOSIT_FUNDS_BROKER}Modal`}
						onClose={actions.depositFundsBrokerClose}
					/>);
			case ModalPopups.DEPOSIT_FUNDS_IRA:
				return (
					<DepositFundsIraModal
						key={`${ModalPopups.DEPOSIT_FUNDS_IRA}Modal`}
						onClose={actions.hideDepositFundsIraModal}
					/>);
			case ModalPopups.DEPOSIT_FUNDS_WIRE:
				return (
					<DepositFundsWireModal
						key={`${ModalPopups.DEPOSIT_FUNDS_WIRE}Modal`}
						onClose={actions.depositFundsWireClose}
					/>);
			case ModalPopups.PLAID_SELECT_ACCOUNT:
				return (
					<PlaidSelectAccountModal
						key={`${ModalPopups.PLAID_SELECT_ACCOUNT}Modal`}
						onClose={() => this.redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT)}
					/>);
			case ModalPopups.WITHDRAW_FUNDS_ACH:
				return (
					<WithdrawFundsAchModal
						key={`${ModalPopups.WITHDRAW_FUNDS_ACH}Modal`}
						onClose={actions.hideWithdrawFundsAchModal}
					/>);
			case ModalPopups.WITHDRAW_FUNDS_WIRE:
				return (
					<WithdrawFundsWireModal
						key={`${ModalPopups.WITHDRAW_FUNDS_WIRE}Modal`}
						onClose={() => this.closeModal(ModalPopups.WITHDRAW_FUNDS_WIRE)}
					/>);
			case ModalPopups.WITHDRAW_FUNDS_BROKER:
				return (
					<WithdrawFundsBrokerModal
						key={`${ModalPopups.WITHDRAW_FUNDS_BROKER}Modal`}
						onClose={actions.hideWithdrawFundsBrokerModal}
					/>);
			case ModalPopups.WITHDRAW_FUNDS_IRA:
				return (
					<WithdrawFundsIraModal
						key={`${ModalPopups.WITHDRAW_FUNDS_IRA}Modal`}
						onClose={actions.hideWithdrawFundsIraModal}
					/>);
			case ModalPopups.ACAT_FUND:
				return (
					<AcatFundModal
						key={`${ModalPopups.ACAT_FUND}Modal`}
						onClose={actions.hideAcatDepositModal}
					/>);
			case ModalPopups.COMMISSION_FREE_MODAL:
				return (
					<CommissionFreeModal
						key={`${ModalPopups.COMMISSION_FREE_MODAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.COMMISSION_FREE_MODAL)}
					/>);
			case ModalPopups.CLOSE_ACCOUNT:
				return (
					<CloseAccountModal
						key={`${ModalPopups.CLOSE_ACCOUNT}Modal`}
						onClose={actions.hideCloseAccountModal}
					/>);
			case ModalPopups.CLOSE_ACCOUNT_FORM:
				return (
					<CloseAccountFormModal
						key={`${ModalPopups.CLOSE_ACCOUNT_FORM}Modal`}
						onClose={actions.hideCloseAccountFormModal}
					/>);
			case ModalPopups.CONFIRM_MODAL:
				return (
					<ConfirmModal
						key={`${ModalPopups.CONFIRM_MODAL}Modal`}
						onClose={actions.hideConfirmModal}
					/>);
			case ModalPopups.IPO_CREATE_ORDER:
				return (
					<IpoCreateOrderModal
						key={`${ModalPopups.IPO_CREATE_ORDER}Modal`}
						onClose={() => this.closeModal(ModalPopups.IPO_CREATE_ORDER)}
					/>);
			case ModalPopups.IPO_EXTENDED_INFO_MOBILE:
				return (
					<IpoExtendedInfoMobileModal
						key={`${ModalPopups.IPO_EXTENDED_INFO_MOBILE}Modal`}
						onClose={() => this.closeModal(ModalPopups.IPO_EXTENDED_INFO_MOBILE)}
					/>);
			case ModalPopups.J2T_LITE:
				return (
					<J2tLiteModal
						key={`${ModalPopups.J2T_LITE}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2T_LITE)}
					/>);
			case ModalPopups.J2T_PLUS:
				return (
					<J2tPlusModal
						key={`${ModalPopups.J2T_PLUS}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2T_PLUS)}
					/>);
			case ModalPopups.J2T_PRO:
				return (
					<J2tProModal
						key={`${ModalPopups.J2T_PRO}Modal`}
						onClose={() => this.closeModal(ModalPopups.J2T_PRO)}
					/>);
			case ModalPopups.MT5:
				return (
					<Mt5Modal
						key={`${ModalPopups.MT5}Modal`}
						onClose={() => this.closeModal(ModalPopups.MT5)}
					/>);
			case ModalPopups.ST_PRO:
				return (
					<StProModal
						key={`${ModalPopups.ST_PRO}Modal`}
						onClose={() => this.closeModal(ModalPopups.ST_PRO)}
					/>);
			case ModalPopups.MESSAGE:
				return (
					<MessageModal
						key={`${ModalPopups.MESSAGE}Modal`}
						onClose={actions.hideMessageModal}
					/>);
			case ModalPopups.CREATE_SIGNATURE:
				return (
					<CreateSignatureModal
						key={`${ModalPopups.CREATE_SIGNATURE}Modal`}
						onClose={actions.hideCreateSignatureModal}
					/>);
			case ModalPopups.UPGRADE_MARGIN_TYPE:
				return (
					<UpgradeAccountModal
						key={`${ModalPopups.UPGRADE_MARGIN_TYPE}Modal`}
						onClose={() => this.closeModal(ModalPopups.UPGRADE_MARGIN_TYPE)}
					/>);
			case ModalPopups.UPGRADE_OPTION_LEVEL:
				return (
					<UpgradeOptionLevelModal
						key={`${ModalPopups.UPGRADE_OPTION_LEVEL}Modal`}
						onClose={() => this.closeModal(ModalPopups.UPGRADE_OPTION_LEVEL)}
					/>);
			case ModalPopups.DEMO_ACCOUNT_EXPIRATION:
				return (
					<DemoAccountModal
						key={`${ModalPopups.DEMO_ACCOUNT_EXPIRATION}Modal`}
						onClose={false}
					/>);
			case ModalPopups.RESET_DEMO_ACCOUNT:
				return (
					<ResetDemoBalanceModal
						key={`${ModalPopups.RESET_DEMO_ACCOUNT}Modal`}
						onClose={() => this.closeModal(ModalPopups.RESET_DEMO_ACCOUNT)}
					/>);
			case ModalPopups.OPEN_SECOND_ACCOUNT:
				return (
					<OpenSecondAccountModal
						key={`${ModalPopups.OPEN_SECOND_ACCOUNT}Modal`}
						onClose={() => this.closeModal(ModalPopups.OPEN_SECOND_ACCOUNT)}
					/>);
			default:
				return null;
		}
	}

	closeModal = (id) => {
		this.props.history.push(removeHashFromUrl(this.props.location, id));
	}
	closeModals = (ids) => {
		this.props.history.push(removeHashesFromUrl(this.props.location, ids));
	}
	redirectModal = (from, to) => {
		this.props.history.push(changeHashInUrl(this.props.location, from, to));
	}

	render() {
		const {
			location,
			actions,
		} = this.props;

		const modals = location.hash && location.hash.substr(1).split('/');
		const topModal = modals && modals.length > 0 && this.getModal(modals && modals.pop());

		const choseModalToDisplay = ({ match }) => (
			match
				? <ConfirmProfileEmailModal onClose={actions.hideConfirmProfileEmailModal} />
				: (
					<React.Fragment>
						{topModal}
						<ProfileModals />
						<CYNonTradingOrderModals />
					</React.Fragment>
				)
		);

		return (
			<div>
				<Route path="/confirmEmail/:token">
					{choseModalToDisplay}
				</Route>
			</div>
		);
	}
}

Modals.propTypes = {
	actions: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	connect(null, mapDispatchToProps),
)(Modals);
