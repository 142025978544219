import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
	A,
	Button,
	Modal,
	ErrorMessage,
	ProgressContainer,
	SuccessMessage,
	SvgIcon,
} from '@components';
import { ModalPopups } from '@enums';
import { etnaAuth, profile, userAccounts } from '@redux';
import { env } from '@config';

const styles = theme => ({
	downloadJsonButton: {
		marginTop: '20px',
	},
	successContent: {
		textAlign: 'left',
		marginTop: '20px',
	},
	textTitle: {
		color: '#7d9eb7',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '14px',
	},
	textContent: {
		fontSize: '22px',
		fontWeight: 400,
	},
});


const mapStateToProps = (state, props) => {
	const userProfile = profile.selectors.profile(state);
	const clients = etnaAuth.selectors.clients(state);
	const modalParams = etnaAuth.selectors.modals(state)[ModalPopups.CREATE_APIKEY] || {};
	return {
		clients,
		email: userProfile.PersonalInformation.Email,
		ready: !userProfile.submitting && !clients.submitting && !modalParams.submitting,
		modalParams,
		userInfo: userAccounts.selectors.userInfo(state),
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		showChangeEmailModal: () => dispatch(profile.actions.showChangeEmailModal()),
		apiKeyModalInit: () => dispatch(etnaAuth.actions.apiKeyModalInit.request()),
	},
});

function createCredentialFile(content) {
	// create a file and put the content, name and type
	const file = new File([`${content}`], 'credentials.json', { type: 'text/json;charset=utf-8' });

	// create a ObjectURL in order to download the created file
	const url = window.URL.createObjectURL(file);

	// create a hidden link and set the href and click it
	const a = document.createElement('a');
	a.style = 'display: none';
	a.href = url;
	a.download = file.name;
	a.click();
	window.URL.revokeObjectURL(url);
}

class CreateApiKeyModal extends React.Component {
	componentDidMount() {
		this.props.actions.apiKeyModalInit();
	}

	handleDownloadJson() {
		const {
			userInfo,
			modalParams,
		} = this.props;

		const objToDownload =
		{
			username: userInfo.Login || '',
			password: '<your_password>',
			client_id: modalParams.СlientId,
			client_secret: modalParams.CreatedSecretKey,
			grant_type: 'password',
			base_url: env.UsaApiServiceUrl,
			auth_url: env.OAuthServiceUrl,
		};

		const content = JSON.stringify(objToDownload, null, '\t');

		createCredentialFile(content);
	}

	render() {
		const {
			classes,
			ready,
			onClose,
			actions,
			email,
			modalParams,
		} = this.props;

		return (
			<Modal
				title="Create API Key"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CREATE_APIKEY}`}
			>
				<ProgressContainer submitting={!ready} disableCheckChildren>
					{!email && <ErrorMessage
						onClick={onClose}
						text={
							<React.Fragment>
								<div>
									Email is required. If you would like to change the email please proceed&nbsp;
									<A onClick={actions.showChangeEmailModal}>here</A>.
								</div>
							</React.Fragment>
						}
					/>}
					{email && ready && !modalParams.error
						? (
							<SuccessMessage
								onClick={onClose}
								text={
									<div>
										<div>
										Please copy and save the Client ID and secret. This is the only time you can see the secret,
										it will disappear after navigating away from this page
										</div>
										<br />
										<div className={classes.successContent}>
											<div className={classes.textTitle}>
											Client Id:
											</div>
											<div className={classes.textContent}>
												{modalParams.СlientId}
												<CopyToClipboard text={modalParams.СlientId}>
													<IconButton>
														<SvgIcon icon="ContentCopy" />
													</IconButton>
												</CopyToClipboard>
											</div>
											<div className={classes.textTitle}>
											Client secret:
											</div>
											<div className={classes.textContent}>
												{modalParams.CreatedSecretKey}
												<CopyToClipboard text={modalParams.CreatedSecretKey}>
													<IconButton>
														<SvgIcon icon="ContentCopy" />
													</IconButton>
												</CopyToClipboard>
											</div>
											<Button
												className={classes.downloadJsonButton}
												fullWidth
												type="button"
												variant="outlined"
												color="primary"
												onClick={() => this.handleDownloadJson()}
											>
											DOWNLOAD CREDENTIALS IN JSON
											</Button>
										</div>
									</div>
								}
								buttonText="Back To Account"
							/>
						)
						: <ErrorMessage
							onClick={onClose}
							text="Secret key generation failed"
						/>}
				</ProgressContainer>
			</Modal>
		);
	}
}

CreateApiKeyModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	actions: PropTypes.object.isRequired,
	email: PropTypes.string,
	userInfo: PropTypes.object.isRequired,
	modalParams: PropTypes.object.isRequired,
	ready: PropTypes.bool,
};

CreateApiKeyModal.defaultProps = {
	email: null,
	ready: false,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CreateApiKeyModal);
