import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {
	Button,
	Modal,
	FormInputNumber,
	SelectPoints,
} from '@components';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { ModalPopups, ValidationMessageMode, MarginType, OptionLevel } from '@enums';
import { userAccounts } from '@redux';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

const styles = theme => ({
	title: {
		...theme.typography.style.title,
		color: theme.typography.color.secondary,
		textAlign: 'center',
		marginBottom: '0px',
	},
	fieldTitle: {
		margin: '10px 0px 0px 0px',
	},
	amountInput: {
		'& div > div': {
			display: 'block',
		},
		'& div > div > div > div': {
			padding: '12px 24px 5px',
		},
	},
	errorItem: {
		color: theme.typography.color.muiError,
	},
	button: {
		paddingTop: '12px',
	},
});

const RESET_DEMO_BALANCE_FORM_ID = 'ResetDemoBalanceForm';
const formSelector = formValueSelector(RESET_DEMO_BALANCE_FORM_ID);

const AMOUNT_FIELD = 'Amount';
const MARGIN_TYPE_FIELD = 'MarginType';
const OPTION_LEVEL_FIELD = 'OptionLevel';

const AMOUNT_1000 = 1000;
const AMOUNT_2000 = 2000;
const AMOUNT_5000 = 5000;
const AMOUNT_25000 = 25000;
const AMOUNT_100000 = 100000;

const mapStateToProps = state => ({
	initialValues: {
		[AMOUNT_FIELD]: `${AMOUNT_100000}`,
		[MARGIN_TYPE_FIELD]: MarginType.MARGIN_X2,
		[OPTION_LEVEL_FIELD]: OptionLevel.Level5,
	},
	amountValue: formSelector(state, AMOUNT_FIELD),
	marginTypeValue: formSelector(state, MARGIN_TYPE_FIELD),
	optionLevelValue: formSelector(state, OPTION_LEVEL_FIELD),
});

const MarginTypes = [
	{
		displayText: MarginType.CASH,
		value: MarginType.CASH,
		prompt: [
			{ text: 'Minimum equity required is $1,000', highlightable: true },
			{ text: 'T+1 Settlement' },
			{ text: 'Trade on Settled Cash' },
		],
	},
	{
		displayText: 'Margin X2',
		value: MarginType.MARGIN_X2,
		prompt: [
			{ text: 'Minimum equity required is $2,000', highlightable: true },
			{ text: 'Trade with 2:1 Buying Power' },
			{ text: 'No more than 3 day trades in the previous 5 trading days' },
		],
	},
	{
		displayText: 'Day Trader',
		value: MarginType.DAY_TRADER,
		prompt: [
			{ text: 'Minimum equity required is $25,000', highlightable: true },
			{ text: 'Trade with 4:1 Buying Power intraday' },
			{ text: '2:1 Buying Power overnight' },
			{ text: 'No limit on day trades' },
		],
	},
];

const OptionLevels = [
	{
		value: OptionLevel.Level0,
		prompt: [
			{ text: 'No option trading allowed' },
		],
		requiresMargin: false,
	},
	{
		value: OptionLevel.Level1,
		prompt: [
			{ text: 'Minimum equity required is $1,000', highlightable: true },
			{ text: 'Covered writing of equity calls' },
		],
		requiresMargin: false,
	},
	{
		value: OptionLevel.Level2,
		prompt: [
			{ text: 'Minimum equity required is $2,000', highlightable: true },
			{ text: 'Level 1 plus' },
			{ text: 'Purchase of equity and index calls and puts' },
			{ text: 'Purchase of equity and index straddles or combinations' },
		],
		requiresMargin: false,
	},
	{
		value: OptionLevel.Level3,
		prompt: [
			{ text: 'Minimum equity required is $5,000', highlightable: true },
			{ text: 'Level 2 plus' },
			{ text: 'Equity spreads' },
			{ text: 'Covered writing of equity puts' },
		],
		requiresMargin: true,
	},
	{
		value: OptionLevel.Level4,
		prompt: [
			{ text: 'Minimum equity required is $25,000', highlightable: true },
			{ text: 'Level 3 plus' },
			{ text: 'Uncovered writing of equity puts' },
		],
		requiresMargin: true,
	},
	{
		value: OptionLevel.Level5,
		prompt: [
			{ text: 'Minimum equity required is $100,000', highlightable: true },
			{ text: 'Level 4 plus' },
			{ text: 'Uncovered writing of straddles or combinations' },
		],
		requiresMargin: true,
	},
];

class ResetDemoBalanceModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.Amount || values.Amount < AMOUNT_1000) {
			errors.Amount = 'Minimum amount for the demo account is $1,000';
		}

		if (values.Amount && values.Amount > AMOUNT_100000) {
			errors.Amount = 'Maximum amount for the demo account is $100,000';
		}

		if (!values.MarginType) {
			errors.MarginType = 'Required';
		}

		if (!values.OptionLevel) {
			errors.OptionLevel = 'Required';
		}

		return errors;
	}

	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			margingTypedDisabled: new Set(),
			optionLevelDisabled: new Set(),
			optionLevelHighlighted: new Set(),
		};
	}

	handleAmountChanged = (_, newValue) => {
		const amountAsNumber = +newValue;
		this.calcMarginTypeDisabled(amountAsNumber);
		this.calcOptionLevelDisabled(amountAsNumber, this.props.marginTypeValue);
		this.calcOptionLevelHighlighted(amountAsNumber);
	}

	handleMarginChange = (event) => {
		this.setState({ selectedMarginLevel: event.target.value });
		this.calcOptionLevelDisabled(+this.props.amountValue, event.target.value);
	}

	handleOptionsChange = (event) => {
		this.setState({ selectedOptionLevel: event.target.value });
	}

	clearMarginType = () => {
		const { change } = this.props;
		change(MARGIN_TYPE_FIELD, '');
	}

	clearOptionLevel = () => {
		const { change } = this.props;
		change(OPTION_LEVEL_FIELD, '');
	}

	calcMarginTypeDisabled = (value) => {
		const {
			marginTypeValue,
		} = this.props;

		if (value >= AMOUNT_25000 && value <= AMOUNT_100000) {
			this.setState({
				margingTypedDisabled: new Set(),
			});
		}

		if (value >= AMOUNT_2000 && value < AMOUNT_25000) {
			this.setState({
				margingTypedDisabled: new Set([MarginType.DAY_TRADER]),
			});

			if (marginTypeValue === MarginType.DAY_TRADER) {
				this.clearMarginType();
			}
		}

		if (value >= AMOUNT_1000 && value < AMOUNT_2000) {
			this.setState({
				margingTypedDisabled: new Set([MarginType.MARGIN_X2, MarginType.DAY_TRADER]),
			});

			if (marginTypeValue === MarginType.DAY_TRADER || marginTypeValue === MarginType.MARGIN_X2) {
				this.clearMarginType();
			}
		}

		if (value < AMOUNT_1000) {
			this.setState({
				margingTypedDisabled: new Set([MarginType.CASH, MarginType.MARGIN_X2, MarginType.DAY_TRADER]),
			});
			this.clearMarginType();
		}
	}

	calcOptionLevelDisabled = (amountValue, marginTypeValue) => {
		const {
			optionLevelValue,
		} = this.props;

		if (marginTypeValue === MarginType.CASH) {
			if (amountValue >= AMOUNT_2000) {
				this.setState({
					optionLevelDisabled: new Set([
						OptionLevel.Level3,
						OptionLevel.Level4,
						OptionLevel.Level5,
					]),
				});

				if (optionLevelValue === OptionLevel.Level3 ||
					optionLevelValue === OptionLevel.Level4 ||
					optionLevelValue === OptionLevel.Level5) {
					this.clearOptionLevel();
				}
			} else if (amountValue >= AMOUNT_1000 && amountValue < AMOUNT_2000) {
				this.setState({
					optionLevelDisabled: new Set([
						OptionLevel.Level2,
						OptionLevel.Level3,
						OptionLevel.Level4,
						OptionLevel.Level5,
					]),
				});

				if (optionLevelValue === OptionLevel.Level2) {
					this.clearOptionLevel();
				}
			} else if (amountValue < AMOUNT_1000) {
				this.setState({
					optionLevelDisabled: new Set([
						OptionLevel.Level0,
						OptionLevel.Level1,
						OptionLevel.Level2,
						OptionLevel.Level3,
						OptionLevel.Level4,
						OptionLevel.Level5,
					]),
				});

				if (optionLevelValue === OptionLevel.Level2) {
					this.clearOptionLevel();
				}
			}
			return;
		}
		// marginTypeValue !== MarginType.CASH
		if (amountValue === AMOUNT_100000) {
			this.setState({
				optionLevelDisabled: new Set(),
			});
		} else if (amountValue >= AMOUNT_25000 && amountValue < AMOUNT_100000) {
			this.setState({
				optionLevelDisabled: new Set([
					OptionLevel.Level5,
				]),
			});

			if (optionLevelValue === OptionLevel.Level5) {
				this.clearOptionLevel();
			}
		} else if (amountValue >= AMOUNT_5000 && amountValue < AMOUNT_25000) {
			this.setState({
				optionLevelDisabled: new Set([
					OptionLevel.Level4,
					OptionLevel.Level5,
				]),
			});

			if (optionLevelValue === OptionLevel.Level4 ||
				optionLevelValue === OptionLevel.Level5) {
				this.clearOptionLevel();
			}
		} else if (amountValue >= AMOUNT_2000 && amountValue < AMOUNT_5000) {
			this.setState({
				optionLevelDisabled: new Set([
					OptionLevel.Level3,
					OptionLevel.Level4,
					OptionLevel.Level5,
				]),
			});

			if (optionLevelValue === OptionLevel.Level3 ||
				optionLevelValue === OptionLevel.Level4 ||
				optionLevelValue === OptionLevel.Level5) {
				this.clearOptionLevel();
			}
		} else if (amountValue >= AMOUNT_1000 && amountValue < AMOUNT_2000) {
			this.setState({
				optionLevelDisabled: new Set([
					OptionLevel.Level2,
					OptionLevel.Level3,
					OptionLevel.Level4,
					OptionLevel.Level5,
				]),
			});

			if (optionLevelValue === OptionLevel.Level2 ||
				optionLevelValue === OptionLevel.Level3 ||
				optionLevelValue === OptionLevel.Level4 ||
				optionLevelValue === OptionLevel.Level5) {
				this.clearOptionLevel();
			}
		} else if (amountValue < AMOUNT_1000) {
			this.setState({
				optionLevelDisabled: new Set([
					OptionLevel.Level0,
					OptionLevel.Level1,
					OptionLevel.Level2,
					OptionLevel.Level3,
					OptionLevel.Level4,
					OptionLevel.Level5,
				]),
			});
			this.clearOptionLevel();
		} else {
			this.setState({
				optionLevelDisabled: new Set(),
			});
		}
	}

	calcOptionLevelHighlighted = (amountValue) => {
		if (amountValue < AMOUNT_1000) {
			this.setState({
				optionLevelHighlighted: new Set([
					OptionLevel.Level1,
					OptionLevel.Level2,
					OptionLevel.Level3,
					OptionLevel.Level4,
					OptionLevel.Level5,
				]),
			});
		}
		else if (amountValue >= AMOUNT_1000 && amountValue < AMOUNT_2000) {
			this.setState({
				optionLevelHighlighted: new Set([
					OptionLevel.Level2,
					OptionLevel.Level3,
					OptionLevel.Level4,
					OptionLevel.Level5,
				]),
			});
		} else if (amountValue >= AMOUNT_2000 && amountValue < AMOUNT_5000) {
			this.setState({
				optionLevelHighlighted: new Set([
					OptionLevel.Level3,
					OptionLevel.Level4,
					OptionLevel.Level5,
				]),
			});
		} else if (amountValue >= AMOUNT_5000 && amountValue < AMOUNT_25000) {
			this.setState({
				optionLevelHighlighted: new Set([
					OptionLevel.Level4,
					OptionLevel.Level5,
				]),
			});
		} else if (amountValue >= AMOUNT_25000 && amountValue < AMOUNT_100000) {
			this.setState({
				optionLevelHighlighted: new Set([
					OptionLevel.Level5,
				]),
			});
		} else {
			this.setState({
				optionLevelHighlighted: new Set(),
			});
		}
	}

	render() {
		const {
			classes,
			onClose,
			theme,
			handleSubmit,
			invalid,
			submitting,
		} = this.props;

		return (
			<Modal
				title="Account settings"
				onClose={onClose}
				bgColor={theme.typography.color.white}
				textColor={theme.typography.color.primary}
				className={`QA-${ModalPopups.RESET_DEMO_ACCOUNT}`}
			>
				<form
					onSubmit={handleSubmit(userAccounts.actions.resetDemoAccountFormSubmit)}
					className={classes.root}
				>
					<p className={classes.title}>
						Set up your demo account
					</p>
					<h3 className={classes.fieldTitle}>Amount</h3>
					<div className={classes.amountInput}>
						<Field
							name={AMOUNT_FIELD}
							component={FormInputNumber}
							onChange={this.handleAmountChanged}
							allowIconButtons={false}
							decimalScale={2}
							validationMessageMode={ValidationMessageMode.AFTER_ADDITIONAL}
							fullWidth
						/>
					</div>
					{/* MARGIN */}
					<h3 className={classes.fieldTitle}>Account Type</h3>
					<div>
						<Field
							name={MARGIN_TYPE_FIELD}
							component={SelectPoints}
							value={this.state.selectedMarginLevel}
							renderValue={(selectedValue) => {
								if (!selectedValue) {
									return 'Choose your account type';
								}
								return selectedValue && MarginTypes.find(it => it.value === selectedValue).value;
							}}
							onChange={this.handleMarginChange}
							bulletPoints={(selectedValue) => {
								const displayedItem = selectedValue && MarginTypes.find(it => it.value === selectedValue);
								return displayedItem ? displayedItem.prompt.map((pr, i) => (<div key={i}>- {pr.text}</div>)) : null;
							}}
							fullWidth
						>
							{MarginTypes.map(it => (
								<MenuItem
									key={it.value}
									value={it.value}
									disabled={this.state.margingTypedDisabled.has(it.value)}
								>
									<div>
										{<b>{ it.displayText }</b>}
										{it.prompt.map((pr, i) => {
											const style = classNames({
												[classes.errorItem]: this.state.margingTypedDisabled.has(it.value) && pr.highlightable,
											});
											return (<div className={style} key={i}>- {pr.text}</div>);
										})}
									</div>
								</MenuItem>
							))}
						</Field>
					</div>
					{/* OPTION */}
					<h3 className={classes.fieldTitle}>Option Level</h3>
					<div>
						<Field
							name={OPTION_LEVEL_FIELD}
							component={SelectPoints}
							value={this.state.selectedOptionLevel}
							renderValue={(selectedValue) => {
								if (!selectedValue) {
									return 'Choose your option level';
								}
								const displayedOption = selectedValue && OptionLevels.find(it => it.value === selectedValue);
								return `Level ${displayedOption.value}`;
							}}
							onChange={this.handleOptionsChange}
							bulletPoints={(selectedValue) => {
								const displayedItem = selectedValue && OptionLevels.find(it => it.value === selectedValue);
								return displayedItem ? displayedItem.prompt.map((pr, i) => (<div key={i}>- {pr.text}</div>)) : null;
							}}
							fullWidth
						>
							{OptionLevels.map(it => (
								<MenuItem
									key={it.value}
									value={it.value}
									disabled={this.state.optionLevelDisabled.has(it.value)}
								>
									<div>
										<b>Level {it.value} {
											it.requiresMargin
												? <span className={classes.errorItem}>(Requires Margin)</span>
												: ''}
										</b>
										{it.prompt.map((pr, i) => {
											const style = classNames({
												[classes.errorItem]: this.state.optionLevelHighlighted.has(it.value) && pr.highlightable,
											});
											return (<div className={style} key={i}>- {pr.text}</div>);
										})}
									</div>
								</MenuItem>
							))}
						</Field>
					</div>
					<div className={classes.button}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							type="submit"
							disabled={invalid || submitting}
						>
							{this.state.submitting ?
								<CircularProgress
									style={{ color: theme.typography.color.white }}
									size={18}
								/>
								: 'Reset account'
							}
						</Button>
					</div>
				</form>
			</Modal>
		);
	}
}

ResetDemoBalanceModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	theme: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	amountValue: PropTypes.string,
	marginTypeValue: PropTypes.string,
	optionLevelValue: PropTypes.string,
	change: PropTypes.func.isRequired,
};

ResetDemoBalanceModal.defaultProps = {
	amountValue: `${AMOUNT_100000}`,
	marginTypeValue: MarginType.CASH,
	optionLevelValue: OptionLevel.Level5,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		form: RESET_DEMO_BALANCE_FORM_ID,
		validate: ResetDemoBalanceModal.validate,
	}),
)(ResetDemoBalanceModal);
