import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { A, Button, Modal, SuccessMessage } from '@components';
import { env } from '@config';
import { ModalPopups } from '@enums';
import { tradePlatforms, userAccounts } from '@redux';


const styles = theme => ({
	title: {
		...theme.typography.style.subheading,
		color: theme.typography.color.primary,
		wordWrap: 'break-word',
	},
	body: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		marginTop: '16px',
		wordWrap: 'break-word',
	},
	button: {
		paddingTop: '12px',
	},
	mainText: {
		...theme.typography.style.title,
		textAlign: 'justify',
	},
	warning: {
		padding: '12px 0',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
});

const mapStateToProps = state => ({
	accountInfo: userAccounts.selectors.accountInfo(state),
	platforms: userAccounts.selectors.platforms(state),
	commissionFreeData: tradePlatforms.selectors.commissionFreeData(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		activateCommissionFree: data =>
			dispatch(tradePlatforms.actions.activateCommissionFree.request(data)),
	},
});

class CommissionFreeModal extends React.Component {
	componentDidUpdate() {
		if (this.props.commissionFreeData && this.props.commissionFreeData.error) {
			// close modal when error
			this.props.onClose();
		}
	}

	submitButton = () => {
		const {
			actions,
			accountInfo,
			platforms,
			currentTradeAccount,
		} = this.props;

		actions.activateCommissionFree({ account: currentTradeAccount, accountInfo, platforms });
	}

	render() {
		const {
			classes,
			onClose,
			commissionFreeData: {
				submitting,
				submitSucceeded,
			},
		} = this.props;

		return (
			<Modal
				title={'"Affordable" trading just became commission-free!'}
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.COMMISSION_FREE_MODAL}`}
			>
				{submitSucceeded ? <SuccessMessage
					onClick={onClose}
					text="Your commission free trading will be enabled the next trading day"
					buttonText="Thank you"
				/>	:
					(
						<div>
							<p className={classes.mainText}>
								Lime Trading is proud to announce unlimited, commission-free trading on all
								US exchange-listed stocks and ETFs for all new and existing clients, starting TODAY!
							</p>
							<p className={classes.warning}>
								Сommission free trading only available through the J2TLite platform.
								Upon switching to commission free plan, all existing GTC orders will be cancelled.
								Free commission trading does not include options, OTCBB or Pink Sheets,
								GTC orders, or extended hours trading. Other fees including regulatory,
								service, or other fees may apply. Please visit
								our <A target="_blank" href={env.pricingUrl}>Fees Page</A> for further information.
								All investing incurs risk, including but not limited to loss of principal.
								Further information may be found on
								our <A target="_blank" href={env.disclosuresUrl}>Disclosures Page</A>.
							</p>
							<div className={classes.button}>
								<Button
									color="primary"
									variant="contained"
									fullWidth
									onClick={this.submitButton}
									disabled={submitting}
								>
									{submitting ?
										<CircularProgress
											style={{ color: '#1a96ff' }}
											size={18}
										/>
										: 'ENABLE COMMISSION FREE'
									}
								</Button>
							</div>
						</div>
					)
				}
			</Modal>
		);
	}
}

CommissionFreeModal.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	commissionFreeData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	platforms: PropTypes.object.isRequired,
	accountInfo: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CommissionFreeModal);
