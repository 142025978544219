/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@components';
import { INVESTMENT_TIMELINE_RANGES } from '@utils';

class InvestmentExperienceItem extends React.Component {
	render() {
		return (
			<SelectField
				label={this.props.label}
				name={this.props.name}
				items={[INVESTMENT_TIMELINE_RANGES.NONE,
					INVESTMENT_TIMELINE_RANGES.LESS_THAN_A_YEAR,
					INVESTMENT_TIMELINE_RANGES.ONE_TO_FIVE_YEARS,
					INVESTMENT_TIMELINE_RANGES.SIX_TO_TEN_YEARS,
					INVESTMENT_TIMELINE_RANGES.OVER_TEN_YEARS,
				]}
				value={this.props.value}
			/>
		);
	}
}

InvestmentExperienceItem.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
};


export default InvestmentExperienceItem;
