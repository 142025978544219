import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	root: {
		...theme.typography.style.body,
		padding: '24px 0',
		color: theme.typography.color.primary,
	},
});

const TabContainer = ({
	classes,
	...props
}) => (
	<Typography className={classes.root} component="div">
		{props.children}
	</Typography>
);


TabContainer.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.any.isRequired,
};

export default withStyles(styles)(TabContainer);
