import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { toUsaMoneyFormat } from '@global';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';

const styles = theme => ({
	logo: {
		maxWidth: '100%',
		maxHeight: '150px',
		minHeight: '150px',
		minWidth: '100%',
		width: 'auto',
		height: 'auto',
		margin: '5px auto',
		objectFit: 'contain',
	},
	header: {
		fontSize: '1.25rem',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontWeight: '400',
		lineHeight: '1.334',
		letterSpacing: '0em',
		width: '100%',
		minWidth: '135px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	symbol: {
		fontSize: '1.15rem',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontWeight: '600',
		lineHeight: '1.334',
		letterSpacing: '0em',
		width: '100%',
		minWidth: '135px',
		whiteSpace: 'nowrap',
	},
	cardContent: {
		borderTop: '1px solid lightgrey',
	},
	cardActions: {
		justifyContent: 'center',
	},
	cardButtons: {
		margin: '0px',
	},
	card: {
		maxHeight: '380px',
		minHeight: '380px',
	},
});


class OfferingMainInfoMobile extends React.Component {
	render() {
		const {
			classes,
			item,
			createOrder,
			showExtendedInfo,
		} = this.props;
		const sPrice = [toUsaMoneyFormat(item.MinPrice || 0), toUsaMoneyFormat(item.MaxPrice)].filter(x => !!x).join(' - ');

		const disabledBuyButton = item.HaveOrders;

		return (
			<React.Fragment>
				<Card className={classes.card}>

					<CardMedia
						component="img"
						className={classes.logo}
						image={item.LogoSmall}
						title="Contemplative Reptile"
					/>
					<CardContent className={classes.cardContent}>
						<div className={classes.header}>{item.Name}</div>
						<div className={classes.symbol}>{item.Symbol}</div>
						<div>{item.OfferingTypeName}</div>
						<div>{item.TradeDate ? moment.utc(item.TradeDate).format('D MMM YYYY') : 'Expected'}</div>
						<div>{sPrice}</div>
					</CardContent>
					<CardActions className={classes.cardActions}>
						{item.AllowOrderEffective &&
							<Button
								size="small"
								color="primary"
								className={classes.cardButtons}
								disabled={disabledBuyButton}
								onClick={() => createOrder()}
							>
								Place Order
							</Button>
						}
						<Button
							size="small"
							color="primary"
							className={classes.cardButtons}
							onClick={() => { showExtendedInfo(); }}
						>
							Read More
						</Button>
					</CardActions>
				</Card>
			</React.Fragment>
		);
	}
}

OfferingMainInfoMobile.propTypes = {
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
	createOrder: PropTypes.func.isRequired,
	showExtendedInfo: PropTypes.func.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(null, null),
)(OfferingMainInfoMobile);
