import React from 'react';
import MUIconButton from '@material-ui/core/IconButton';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { cabinetLog } from '@global';

class IconButton extends React.Component {
	handleClick = (e) => {
		if (process.env.NODE_ENV === 'production' && this.props.gaUrl) {
			cabinetLog(`send to ${this.props.gaUrl}`);
			ReactGA.modalview(this.props.gaUrl);
		}
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	render() {
		const {
			onClick,
			gaUrl,
			...props
		} = this.props;

		return (
			<MUIconButton
				onClick={this.handleClick}
				{...props}
			/>
		);
	}
}


IconButton.propTypes = {
	/**
	 * mouse click handler
	 */
	onClick: PropTypes.func,
	/**
	 * If exists, send this url to Google Analytics
	 */
	gaUrl: PropTypes.string,
	/**
	 * The content of the button.
	 */
	children: PropTypes.node.isRequired,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object,
	/**
	 * The color of the component. It supports those theme colors that make sense for this component.
	 */
	color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
	/**
	 * The component used for the root node.
	 * Either a string to use a DOM element or a component.
	 * The default value is a `button`.
	 */
	component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	/**
	 * If `true`, the button will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * If `true`, the ripple effect will be disabled.
	 */
	disableRipple: PropTypes.bool,
};

IconButton.defaultProps = {
	classes: null,
	color: 'default',
	disabled: false,
	component: undefined, // 'button',
	disableRipple: false,
	gaUrl: null,
	onClick: null,
};

export default IconButton;
