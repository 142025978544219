import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { depositFunds, snackbar, userAccounts } from '@redux';
import { constants } from '@config';
import {
	reduxForm,
	Field,
} from 'redux-form';
import {
	FormInput,
	FormInputNumber,
	Button,
} from '@components';
import { connect } from 'react-redux';

const styles = theme => ({
	root: {},
	button: {
		paddingTop: '12px',
	},
});

const mapStateToProps = state => ({
	initialValues: {
		TradeCode: userAccounts.selectors.currentAccount(state),
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
	},
});


class WireDepositInfoForm extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.BankName) {
			errors.BankName = 'Required';
		}
		if (!values.Amount) {
			errors.Amount = 'Required';
		} else if (!(constants.regExpAmount.test(values.Amount))) {
			errors.Amount = 'Invalid Format';
		}

		return errors;
	}

	render() {
		const {
			classes,
			handleSubmit,
			invalid,
			submitting,
		} = this.props;

		return (
			<div>
				<p>
					Please enter your account details and an amount you want to transfer.
				</p>
				<form
					onSubmit={handleSubmit(depositFunds.actions.wireDepositInfoFormSubmitHandler)}
					className={classes.formroot}
				>
					<div className={classes.inputItem}>
						<Field
							component={FormInput}
							name="BankName"
							placeholder="Bank Name"
							label="Bank Name"
						/>
					</div>
					<div className={classes.amount}>
						<Field component={FormInputNumber} name="Amount" placeholder="Enter the Amount here" />
					</div>
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={invalid || submitting}
						>
							{submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								: 'Deposit'
							}
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

WireDepositInfoForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: 'wireDepositInfoForm',
		validate: WireDepositInfoForm.validate,
	}),
)(WireDepositInfoForm);
