import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { env } from '@config';
import {
	A,
	Button,
	Modal,
} from '@components';
import { ModalPopups } from '@enums';
import { changeHashInUrl } from '@global';
import {
	acatDeposit,
	depositFunds,
	profile,
	signature,
	tradePlatforms,
	userAccounts,
} from '@redux';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	title: {
		...theme.typography.style.subheading,
		color: theme.typography.color.primary,
	},
	body: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	button: {
		paddingTop: '12px',
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	currentAccount: userAccounts.selectors.currentAccount(state),
	currentClearer: userAccounts.selectors.currentClearer(state),
	accounts: userAccounts.selectors.accounts(state),
	linkedAccounts: userAccounts.selectors.linkedAccounts(state),
	platforms: userAccounts.selectors.platforms(state),
	userInfo: userAccounts.selectors.userInfo(state),
	userProfile: profile.selectors.profile(state),
});

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		// showJ2tLiteModal: () => dispatch(tradePlatforms.actions.showJ2tLiteModal()),
		showJ2tPlusModal: () => dispatch(tradePlatforms.actions.showJ2tPlusModal()),
		showJ2tProModal: () => dispatch(tradePlatforms.actions.showJ2tProModal()),
		showStProModal: () => dispatch(tradePlatforms.actions.showStProModal()),
		showAcatDepositModal: (toUserAccount) => {
			const acatDepositAction = acatDeposit.actions.showAcatDepositModal(toUserAccount);
			dispatch(signature.actions.collectSignaturesProcessStart(acatDepositAction));
		},
		showDepositFundsWireModal: bankAccount =>
			dispatch(depositFunds.actions.showDepositFundsWireModal(bankAccount)),
		showDepositFundsModal: (bankAccount, tradeAccount) =>
			dispatch(depositFunds.actions.showDepositFundsModal(bankAccount, tradeAccount)),
	},
});

class WelcomePageModal extends React.Component {
	showAcatDepositModal = () => {
		this.props.actions.showAcatDepositModal(this.props.currentAccount);
	}

	showDepositWireModal = () => {
		this.props.actions.showDepositFundsWireModal();
	}

	redirectModal = (from, to) => {
		this.props.history.push(changeHashInUrl(this.props.location, from, to));
	}

	showDepositAchModal = () => {
		const {
			currentTradeAccount,
			linkedAccounts,
		} = this.props;

		const achBankAccount = linkedAccounts && linkedAccounts.find(it => it.Type === 'ACH');
		if (achBankAccount) {
			this.props.actions.showDepositFundsModal(achBankAccount, currentTradeAccount);
		} else {
			this.redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT);
		}
	}

	render() {
		const {
			classes,
			onClose,
			accounts,
			currentAccount,
			currentClearer,
			userInfo,
			userProfile,
			platforms: {
				ActiveTradePlatform,
				TradeUrl,
			},
			actions: {
				showJ2tPlusModal,
				showJ2tProModal,
				showStProModal,
			},
		} = this.props;

		let tradingAction = '';
		if (ActiveTradePlatform === 'Transaq') tradingAction = showJ2tProModal;
		if (ActiveTradePlatform === 'Just2Trade') tradingAction = showJ2tPlusModal;
		if (ActiveTradePlatform === 'Sterling') tradingAction = showStProModal;

		const accCurrent = accounts.find(it =>
			it.TradeCode === currentAccount && it.ClearingFirm === currentClearer);
		const externalGuid = accCurrent ? accCurrent.ExternalGuid : '';

		const onlyOneAccount = accounts.length === 1;
		const hasInstantTrading = userProfile && userProfile.IsInstantTrading;

		return (
			<Modal
				title="Welcome!"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.WELCOME_PAGE}`}
			>
				{hasInstantTrading &&
					<div>
						<p>Welcome to Lime Trading!</p>
						<p>
							With our <i>&quot;instant trading&quot;</i> feature,
							you can go to trading platform and start trading right now.
						</p>
						<p>
							In the meantime, this is your account management platform, where you can:
						</p>
						<ol>
							<li>Go to and switch between trading platforms;</li>
							<li>Deposit funds to your brokerage account;</li>
							<li>Check out your portfolio, balances and documents;</li>
							<li>Look through your transactions and other important events and notifications.</li>
						</ol>
						<p>
							Contact us in case you have any questions:<a href={`mailto:${env.supportMail}`}>{env.supportMail}</a>.
						</p>
					</div>
				}
				{!hasInstantTrading &&
					<div>
						<p>Dear {userInfo.LongName},</p>
						<p>
							Congratulations, your Lime Trading Demo Account {currentAccount} is now open! Trade, test your strategies, and get to know our platform for the next 30 days.
						</p>
						<p>
							With this demo account, experience <A target="_blank" href={TradeUrl}>Lime Trader</A> via web terminal, API, and mobile application.
						</p>
						<p>
							<b>Get started with API!</b> Check out our <A target="_blank" href={`${env.anketaDocumentationUrl}/trader/`}>API Documentation</A> to learn more. To generate your API Key, go to <i>My Profile</i>, then select <i>Generate API Key</i>.
						</p>
						<p>
							Get started with the mobile app! Download Lime Trader on the <A target="_blank" href={env.limeTraderAppStore}>App Store</A> or on <A target="_blank" href={env.limeTraderGooglePlay}>Google play</A> to trade on-the-go!
						</p>
						<p>
							Please note, any trading using the Lime Trading Demo is ‘paper trading,’ which means you are experiencing trading in the market without capital risk (and no potential to profit from any trading).  While “paper trading” allows the user to test a trading system and/or strategy, it does not necessarily provide the user with a true experience of how they would react were they to be trading their own capital in the live market.
						</p>
						<p>
							<b>Don’t forget – you can sign up to open a Lime Trading Account at any time by completing the online <A target="_blank" href={env.anketaSiteUrl}>application</A>. Happy Trading!</b>
						</p>
					</div>
				}
				{hasInstantTrading && tradingAction &&
					<div className={classes.button}>
						{(ActiveTradePlatform === 'Transaq') &&
							<A target="_blank" href={TradeUrl}>
								<Button
									fullWidth
									variant="contained"
									color="primary"
								>
									Start Trading
								</Button>
							</A>
						}
						{(ActiveTradePlatform !== 'Transaq') &&
							<Button
								fullWidth
								type="submit"
								variant="contained"
								color="primary"
								onClick={tradingAction}
							>
								Start Trading
							</Button>
						}
					</div>
				}
			</Modal>
		);
	}
}

WelcomePageModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(WelcomePageModal);
