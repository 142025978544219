import { createFormAction } from 'redux-form-saga';
import { createApiActions } from '../extensions';

const acatDepositFormSubmitActionPrefix = 'ACAT_DEPOSIT_FORM_SUBMIT';
const acatDepositFormActionCreator =
	createFormAction(acatDepositFormSubmitActionPrefix);

const acatOutgoingFormSubmitActionPrefix = 'ACAT_OUTGOING_FORM_SUBMIT';
const acatOutgoingFormActionCreator =
	createFormAction(acatOutgoingFormSubmitActionPrefix);

const closeAccountFormSubmitActionPrefix = 'CLOSE_ACCOUNT_FORM_SUBMIT';
const closeAccountFormActionCreator =
	createFormAction(closeAccountFormSubmitActionPrefix);

const actions = {
	// actions
	SHOW_ACAT_DEPOSIT_MODAL: 'ACAT_DEPOSIT/MODAL_SHOW',
	HIDE_ACAT_DEPOSIT_MODAL: 'ACAT_DEPOSIT/MODAL_HIDE',

	showAcatDepositModal: tradeAccount => ({
		type: actions.SHOW_ACAT_DEPOSIT_MODAL,
		payload: {
			tradeAccount,
		},
	}),
	hideAcatDepositModal: () => ({
		type: actions.HIDE_ACAT_DEPOSIT_MODAL,
	}),


	ACAT_DEPOSIT_FORM_SUBMIT_REQUEST: acatDepositFormActionCreator.REQUEST,
	ACAT_DEPOSIT_FORM_SUBMIT_SUCCESS: acatDepositFormActionCreator.SUCCESS,
	ACAT_DEPOSIT_FORM_SUBMIT_FAILURE: acatDepositFormActionCreator.FAILURE,

	acatDepositFormRequest: (...args) => acatDepositFormActionCreator.request({ ...args }),
	acatDepositFormSuccess: payload => acatDepositFormActionCreator.success(payload),
	acatDepositFormFailure: error => acatDepositFormActionCreator.failure(error),
	acatDepositFormSubmitHandler: acatDepositFormActionCreator,

	acatDepositIraForm: createFormAction('ACAT_DEPOSIT_IRA_FORM'),

	ACAT_OUTGOING_FORM_SUBMIT_REQUEST: acatOutgoingFormActionCreator.REQUEST,
	ACAT_OUTGOING_FORM_SUBMIT_SUCCESS: acatOutgoingFormActionCreator.SUCCESS,
	ACAT_OUTGOING_FORM_SUBMIT_FAILURE: acatOutgoingFormActionCreator.FAILURE,

	acatOutgoingFormRequest: (...args) => acatOutgoingFormActionCreator.request({ ...args }),
	acatOutgoingFormSuccess: payload => acatOutgoingFormActionCreator.success(payload),
	acatOutgoingFormFailure: error => acatOutgoingFormActionCreator.failure(error),
	acatOutgoingFormSubmitHandler: acatOutgoingFormActionCreator,


	CLOSE_ACCOUNT_FORM_SUBMIT_REQUEST: closeAccountFormActionCreator.REQUEST,
	CLOSE_ACCOUNT_FORM_SUBMIT_SUCCESS: closeAccountFormActionCreator.SUCCESS,
	CLOSE_ACCOUNT_FORM_SUBMIT_FAILURE: closeAccountFormActionCreator.FAILURE,

	closeAccountFormRequest: (...args) => closeAccountFormActionCreator.request({ ...args }),
	closeAccountFormSuccess: payload => closeAccountFormActionCreator.success(payload),
	closeAccountFormFailure: error => closeAccountFormActionCreator.failure(error),
	closeAccountFormSubmitHandler: closeAccountFormActionCreator,


	SHOW_CLOSE_ACCOUNT_MODAL: 'CLOSE_ACCOUNT/MODAL_SHOW',
	HIDE_CLOSE_ACCOUNT_MODAL: 'CLOSE_ACCOUNT/MODAL_HIDE',

	showCloseAccountModal: payload => ({
		type: actions.SHOW_CLOSE_ACCOUNT_MODAL,
		payload,
	}),

	hideCloseAccountModal: () => ({
		type: actions.HIDE_CLOSE_ACCOUNT_MODAL,
	}),

	SHOW_CLOSE_ACCOUNT_FORM_MODAL: 'CLOSE_ACCOUNT/MODAL_FORM_SHOW',
	HIDE_CLOSE_ACCOUNT_FORM_MODAL: 'CLOSE_ACCOUNT/MODAL_FORM_HIDE',

	showCloseAccountFormModal: () => ({
		type: actions.SHOW_CLOSE_ACCOUNT_FORM_MODAL,
	}),
	hideCloseAccountFormModal: () => ({
		type: actions.HIDE_CLOSE_ACCOUNT_FORM_MODAL,
	}),


	CLOSE_ACCOUNT_CONTINUE_REQUEST: 'CLOSE_ACCOUNT_CONTINUE_REQUEST',
	CLOSE_ACCOUNT_CONTINUE_SUCCESS: 'CLOSE_ACCOUNT_CONTINUE_SUCCESS',
	CLOSE_ACCOUNT_CONTINUE_FAILURE: 'CLOSE_ACCOUNT_CONTINUE_FAILURE',

	closeAccountContinueRequest: payload => ({
		type: actions.CLOSE_ACCOUNT_CONTINUE_REQUEST,
		payload,
	}),
	closeAccountContinueSuccess: payload => ({
		type: actions.CLOSE_ACCOUNT_CONTINUE_SUCCESS,
		payload,
	}),
	closeAccountContinueFailure: payload => ({
		type: actions.CLOSE_ACCOUNT_CONTINUE_FAILURE,
		payload,
	}),

	ACTIVE_ORDERS_REQUEST: 'CLOSE_ACCOUNT/ACTIVE_ORDERS_REQUEST',
	ACTIVE_ORDERS_COMPLETE: 'CLOSE_ACCOUNT/ACTIVE_ORDERS_COMPLETE',

	activeOrdersRequest: () => ({ type: actions.ACTIVE_ORDERS_REQUEST }),
	activeOrdersComplete: () => ({ type: actions.ACTIVE_ORDERS_COMPLETE }),

	closeAccountValidation: createApiActions('CLOSE_ACCOUNT/VALIDATION'),
};

export default actions;
