import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { constants } from '@config';
import { dashboard, notifications } from '@redux';
import { A, SvgIcon } from '@components';
import { createQAAttribute } from '@global';

const styles = theme => ({
	root: {
		cursor: 'pointer',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'left',
		justifyContent: 'left',
		maxWidth: '50%',
	},
	rightColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'right',
		justifyContent: 'right',
	},
	title: {
		// textOverflow: 'ellipsis',
		// overflow: 'hidden',
		// whiteSpace: 'nowrap',
		// width: '120px',
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		wordWrap: 'break-word',
		// fontWeight: 'bold',
	},
	body: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		wordWrap: 'break-word',
	},
	col: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	ddate: {
		display: 'block',
		float: 'right',
		paddingLeft: '10px',
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	amount: {
		...theme.typography.style.subheading,
	},
	status: {
		...theme.typography.style.body,
	},
	amountData: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'flex-start',
		textAlign: 'right',
	},
	unread: {
		// fontWeight: 'bold',
		fontWeight: 500,
		color: theme.typography.color.primary,
	},
	red: {
		color: theme.typography.color.red,
	},
	green: {
		color: theme.typography.color.green,
	},
	black: {
		color: theme.typography.color.primary,
	},
	grey: {
		color: theme.typography.color.secondary,
	},
	unreadIcon: {
		position: 'absolute',
		left: '-12px',
		top: '7px',
	},
	hidden: {
		display: 'none',
	},
});

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		showMessageModal: message =>
			dispatch(notifications.actions.showMessageModal(message)),
		notificationsClose: () => dispatch(dashboard.actions.notificationsClose()),
	},
});

class MessageItem extends React.Component {
	showMessage = () => {
		this.props.actions.notificationsClose();
		this.props.actions.showMessageModal(this.props.item);
	}

	render() {
		const {
			item,
			classes,
		} = this.props;

		const titleCssClass = classNames({
			[classes.title]: true,
			[classes.unread]: item.Read === false,
		});

		const bodyCssClass = classNames({
			[classes.body]: true,
			[classes.unread]: item.Read === false,
		});

		const date = item.MessageDate ? moment.utc(item.MessageDate) : null;
		let title = item.Title;
		let clearedText = null;
		if (item.Title.length > 90) {
			title = `${item.Title.substr(0, 90)} ...`;
		} else {
			const textLength = 90 - item.Title.length;
			if (textLength > 15) {
				const text = item.Short || item.Full;
				clearedText = text.replace(constants.regExpAllTags, '');
				if (clearedText.length > textLength) {
					clearedText = `${clearedText.substr(0, textLength)} ...`;
				}
			}
		}

		return (
			<A className={classes.root} onClick={this.showMessage} style={{ position: 'relative' }}>

				<SvgIcon icon="Circle" className={item.Read ? classes.hidden : classes.unreadIcon} {...createQAAttribute('point')} />

				<div>
					<div className={classes.ddate} {...createQAAttribute('notification_date')}>
						{date.fromNow()}
					</div>
					<span className={titleCssClass}>{title}</span>
					{clearedText &&	<span className={bodyCssClass} {...createQAAttribute('notification_text')}> - {clearedText}</span>}
				</div>
				{/* <div className={classes.header}>
					<div className={classes.leftColumn}>
						<div className={titleCssClass}>
							{item.Title}
						</div>
					</div>
					{date &&
						<div className={classes.rightColumn}>
							<div className={classes.date}>
								{date.fromNow()}
							</div>
						</div>
					}
				</div>
				{text &&
					<div>
						<div className={bodyCssClass}>
							{clearedText.substr(0, 70)}{(clearedText.length > 70) && ' ...'}
						</div>
					</div>
				} */}
			</A>
		);
	}
}

MessageItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(MessageItem);
