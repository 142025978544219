const actions = {
	/* GET_GAINERS_AND_LOSERS_REQUEST: 'ANALYTICS/GET_GAINERS_AND_LOSERS_REQUEST',
	GET_GAINERS_AND_LOSERS_SUCCESS: 'ANALYTICS/GET_GAINERS_AND_LOSERS_SUCCESS',
	GET_GAINERS_AND_LOSERS_FAILURE: 'ANALYTICS/GET_GAINERS_AND_LOSERS_FAILURE',

	getGainersAndLosersRequest: (page = 1, pageSize = 5, processingLimit = 5) => ({
		type: actions.GET_GAINERS_AND_LOSERS_REQUEST,
		payload: {
			page,
			pageSize,
			processingLimit,
		},
	}),
	getGainersAndLosersSuccess: (pagination, items) => ({
		type: actions.GET_GAINERS_AND_LOSERS_SUCCESS,
		payload: {
			pagination,
			items,
		},
	}),
	getGainersAndLosersFailure: error => ({
		type: actions.GET_GAINERS_AND_LOSERS_FAILURE,
		payload: {
			error,
		},
	}), */


	GET_EARNINGS_TODAY_REQUEST: 'ANALYTICS/GET_EARNINGS_TODAY_REQUEST',
	GET_EARNINGS_TODAY_SUCCESS: 'ANALYTICS/GET_EARNINGS_TODAY_SUCCESS',
	GET_EARNINGS_TODAY_FAILURE: 'ANALYTICS/GET_EARNINGS_TODAY_FAILURE',

	getEarningsTodayRequest: (processingLimit = 5) => ({
		type: actions.GET_EARNINGS_TODAY_REQUEST,
		payload: {
			processingLimit,
		},
	}),
	getEarningsTodaySuccess: items => ({
		type: actions.GET_EARNINGS_TODAY_SUCCESS,
		payload: {
			items,
		},
	}),
	getEarningsTodayFailure: error => ({
		type: actions.GET_EARNINGS_TODAY_FAILURE,
		payload: {
			error,
		},
	}),


	GET_MOST_ACTIVE_REQUEST: 'ANALYTICS/GET_MOST_ACTIVE_REQUEST',
	GET_MOST_ACTIVE_SUCCESS: 'ANALYTICS/GET_MOST_ACTIVE_SUCCESS',
	GET_MOST_ACTIVE_FAILURE: 'ANALYTICS/GET_MOST_ACTIVE_FAILURE',

	getMostActiveRequest: (processingLimit = 5) => ({
		type: actions.GET_MOST_ACTIVE_REQUEST,
		payload: {
			processingLimit,
		},
	}),
	getMostActiveSuccess: items => ({
		type: actions.GET_MOST_ACTIVE_SUCCESS,
		payload: {
			items,
		},
	}),
	getMostActiveFailure: error => ({
		type: actions.GET_MOST_ACTIVE_FAILURE,
		payload: {
			error,
		},
	}),

};

export default actions;
