import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { SvgIcon } from '@components';
import { toUsaMoneyFormat } from '@global';
import { app, ipo } from '@redux';


const styles = theme => ({
	root: {
		display: 'flex',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '105px',
	},
	secondColumn: {
		marginLeft: '16px',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		width: '100px',
	},
	rightColumn: {
		flexGrow: '2',
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'flex-start',
		textAlign: 'right',
	},
	rightPadding: {
		width: '100%',
		textAlign: 'center',
		marginRight: '62px',
	},
	prevRight: {
		width: '100%',
		marginRight: '14px',
	},
	title: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	date: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	amountData: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		textAlign: 'right',
	},
	amount: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		fontSize: '18px',
	},
	status: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	logo: {
		maxWidth: '85px',
		maxHeight: '48px',
	},
});


const mapDispatchToProps = dispatch => ({
	actions: {
		cancelIpoOrder: payload => dispatch(ipo.actions.cancelIpoOrder.request(payload)),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
	},
});


class OrderMainInfo extends React.Component {
	cancelOrder = () => {
		this.props.actions.showConfirmModal({
			text: 'Please click confirm you want to cancel the request',
			action: onEndRequest => this.props.actions.cancelIpoOrder({
				OrderId: this.props.item.OrderId,
				onEndRequest,
			}),
		});
	}

	render() {
		const {
			classes,
			item,
		} = this.props;

		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					{item.Offering.LogoSmall &&
						<img className={classes.logo} src={item.Offering.LogoSmall} alt="company logo" />
					}
				</div>
				<div className={classes.secondColumn}>
					<div className={classes.title}>
						{item.Offering.Symbol}
					</div>
					<div className={classes.date}>
						{/* american time zone (no utc) */}
						{moment(item.Date).format('D MMM YYYY')}
					</div>
				</div>
				{item.AllowCancel ?
					<div className={classes.rightColumn}>
						<div className={classes.prevRight}>
							<div className={classes.amountData}>
								<div className={classes.amount}>
									<span>{toUsaMoneyFormat(item.RequestedAmount)}</span>
								</div>
								{item.StatusName &&
									<div className={classes.status}>
										{item.StatusName}
									</div>
								}
							</div>
						</div>
						<IconButton onClick={this.cancelOrder}>
							<SvgIcon className={classes.headToggleIcon} icon="Close" />
						</IconButton>
					</div>
					:
					<div className={classes.rightColumn}>
						<div className={classes.rightPadding}>
							<div className={classes.amountData}>
								<div className={classes.amount}>
									<span>{toUsaMoneyFormat(item.RequestedAmount)}</span>
								</div>
								{item.StatusName &&
									<div className={classes.status}>
										{item.StatusName}
									</div>
								}
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

OrderMainInfo.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(OrderMainInfo);
