import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { userAccounts } from '@redux';
import { ErrorMessage, Modal, ProgressContainer } from '@components';
import { connect } from 'react-redux';
import { DepositFundsIraForm } from './components';

const styles = {};

const mapStateToProps = (state) => {
	const accountInfo = userAccounts.selectors.accountInfo(state);
	// default if not set
	const loading = userAccounts.selectors.loading(state) || accountInfo.loading;
	const tradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const accountStatus = userAccounts.selectors.currentAccountStatus(state);
	return {
		depositIraModal: {
			tradeAccount,
		},
		canShow: !!(accountStatus && accountStatus.CanFund && accountInfo.IsIra
			&& (tradeAccount.clearerFirm !== 'vision' || accountInfo.IraType)
		),
		loading,
	};
};

class DepositFundsIraModal extends React.Component {
	render() {
		const {
			canShow,
			loading,
			onClose,
			depositIraModal,
		} = this.props;

		return (
			<Modal
				title="Deposit"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
			>
				<ProgressContainer submitting={loading}>
					<div>
						{!canShow &&
							<ErrorMessage onClick={onClose} text={`Unavailable for account ${depositIraModal.tradeAccount.tradeCode}.`} />
						}
						{canShow &&
							<DepositFundsIraForm
								tradeAccount={depositIraModal.tradeAccount}
							/>
						}
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

DepositFundsIraModal.propTypes = {
	canShow: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	depositIraModal: PropTypes.shape({
		tradeAccount: PropTypes.shape({
			tradeCode: PropTypes.string.isRequired,
			clearerFirm: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(DepositFundsIraModal);
