import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	googleLink: {
		color: theme.typography.color.link,
		textDecoration: 'none',
	},
	title: {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
	},
	mainText: {
		...theme.typography.style.body,
		textAlign: 'center',
		padding: '24px 0px 0',
	},
}));

const GoogleInfoStep = () => {
	const classes = useStyles();
	return (
		<Fragment>
			<div className={classes.title}>Install code-generating app</div>
			<div className={classes.mainText}>
                Use any code-generating app. For example,
                <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        className={classes.googleLink}
                    >
                        &nbsp;Google Authenticator&nbsp;
                </a>
                <br />
                for Android.
			</div>
		</Fragment>
	);
};

export default GoogleInfoStep;
