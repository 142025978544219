import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { SvgIcon } from '@components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { toUsaFormat } from '@global';

const styles = theme => ({
	root: {
		...theme.typography.style.title,
		display: 'flex',
		alignItems: 'center',
	},
	value:{},
	icon: {},
	red: {
		color: theme.typography.color.red,
	},
	green: {
		color: theme.typography.color.green,
	},
});

const ValueChange = ({ classes, value, valueSymbol }) => {
	const cssClassName = classNames({
		[classes.root]: true,
		[classes.red]: value < 0,
		[classes.green]: value > 0,
	});
	const Icon = value > 0 ?
		<SvgIcon icon="PriceUp" />
		: <SvgIcon icon="PriceDown" />;

	return (value ?
		<div className={cssClassName}>
			<div className={classes.value}>{toUsaFormat(value)}{valueSymbol}</div>
			{Icon}
		</div>
		:
		''
	);
};

ValueChange.propTypes = {
	value: PropTypes.number,
	valueSymbol: PropTypes.string,
};

ValueChange.defaultProps = {
	valueSymbol: '%',
};

export default compose(withStyles(styles))(ValueChange);
