import React from 'react';
import PropTypes from 'prop-types';
import { InputNumberFormat } from '@components';
import { phoneCodes } from './phoneCodes';

class InputPhoneNumber extends React.Component {
	static masksSort(maskList) {
		maskList.sort((a, b) => {
			let ia = 0;
			let ib = 0;
			const match = /[0-9#]/;
			const key = 'mask';

			for (; (ia < a[key].length && ib < b[key].length);) {
				const cha = a[key].charAt(ia);
				const chb = b[key].charAt(ib);
				if (!match.test(cha)) {
					ia += 1;
				} else if (!match.test(chb)) {
					ib += 1;
				} else if ((cha !== '#') && (chb === '#')) {
					return -1;
				} else if ((cha === '#') && (chb !== '#')) {
					return 1;
				} else if (cha !== chb) {
					return cha < chb ? -1 : 1;
				} else {
					ia += 1;
					ib += 1;
				}
			}

			for (; (ia < a[key].length || ib < b[key].length);) {
				if (ia < a[key].length && !match.test(a[key].charAt(ia))) {
					ia += 1;
				} else if (ib < b[key].length && !match.test(b[key].charAt(ib))) {
					ib += 1;
				} else if (ia < a[key].length) {
					return 1;
				} else if (ib < b[key].length) {
					return -1;
				}
			}

			if (a.length < b.length) return -1;
			if (a.length > b.length) return 1;

			return 0;
		});
		return maskList;
	}

	static applyMask(maskTemplate, val, maskOpts, defs) {
		let index = 0;
		let pass = true;
		let maskIndex = 0;

		maskTemplate.mask.split('').some((chm, im) => {
			if (index >= val.length) return true;
			if (!maskOpts.match.test(chm) && !(chm in defs)) {
				maskIndex = im;
				return false;
			}
			if (((chm in defs) && defs[chm].test(val[index])) || (val[index] === chm)) {
				index += 1;
				maskIndex = im;
				return false;
			}
			pass = false;
			return true;
		});

		if (pass && index === val.length) {
			return {
				mask: maskTemplate.mask.replace(new RegExp([maskOpts.match.source].concat('#').join('|'), 'g'), maskOpts.replace),
				obj: maskTemplate,
				determined: maskTemplate.mask.substr(maskIndex + 1).search(maskOpts.match) === -1,
				completed: maskTemplate.mask.substr(maskIndex + 1).search(maskOpts.replace) === -1,
			};
		}
		return null;
	}

	constructor(props) {
		super(props);

		this.maskList = InputPhoneNumber.masksSort(phoneCodes);
		this.current = {
			formatted: '',
			unformatted: '',
			match: {
				mask: '+#(###)###-####',
			},
		};

		this.detectPhone = this.detectPhone.bind(this);
	}

	onRemoveFormatting = (formattedValue) => {
		const { hideNumberMode, disabled, readOnly } = this.props;
		if (this.current.formatted === formattedValue) {
			return this.current.unformatted;
		}
		if (hideNumberMode && (disabled || readOnly)) {
			return formattedValue.replace(/[^0-9\*]/g, '').replace(/\*+/g, '*');
		}
		return formattedValue.replace(/[^0-9]/g, '');
	}

	onFormatDetect = (unformatted) => {
		const { hideNumberMode, disabled, readOnly } = this.props;
		if (this.current.unformatted === unformatted) {
			return this.current.formatted;
		}

		const match = this.detectPhone(unformatted);
		let unformattedVal = unformatted;
		if (hideNumberMode && match && unformatted && unformatted.indexOf('*') >= 0) {
			const lenMask = match.mask.split('').filter(s => s === '#').length;
			const lenVal = unformatted.split('').filter(s => new RegExp(/[0-9]/).test(s)).length;
			if (lenMask > lenVal) {
				unformattedVal = unformatted.replace(/\*+/, new Array(lenMask - lenVal + 1).join('*'));
			}
		}
		let index = 0;
		let mtxt = '';
		const mask = match ? match.mask : this.current.match.mask;
		mask.split('').forEach((char) => {
			if ((char === '#') && (index < unformattedVal.length)) {
				mtxt += unformattedVal[index];
				index += 1;
			} else {
				mtxt += char;
			}
		});

		this.current = {
			unformatted,
			formatted: mtxt.replace(/#/g, '_'),
			match: match || this.current.match,
		};

		this.props.onPhoneChange(this.current.match);
		return this.current.formatted;
	}

	// Определение страны и маски телефона
	detectPhone(value) {
		const { hideNumberMode } = this.props;
		const maskOpts = {
			match: hideNumberMode ? /[0-9\*]/ : /[0-9]/,
			replace: '#',
			placeholder: '_',
		};

		const defs = {
			'#': hideNumberMode ? new RegExp(/[0-9\*]/) : new RegExp(/[0-9]/),
		};

		let mtxt = ''; // entered digits

		value.split('').some((char) => {
			if (char === maskOpts.placeholder) return true; // like break
			if (maskOpts.match.test(char)) {
				mtxt += char;
			}
			return false;
		});

		let result = null;
		this.maskList.some((item) => {
			result = InputPhoneNumber.applyMask(item, mtxt, maskOpts, defs);
			return !!result;
		});

		return result;
	}

	render() {
		const {
			onChange,
			onPhoneChange,
			hideNumberMode,
			...props
		} = this.props;

		return (
			<InputNumberFormat
				onChange={onChange}
				format={this.onFormatDetect}
				removeFormatting={this.onRemoveFormatting}
				{...props}
			/>
		);
	}
}

InputPhoneNumber.propTypes = {
	onChange: PropTypes.func.isRequired,
	onPhoneChange: PropTypes.func.isRequired,
	/**
	 * hideNumberMode - позволяет скрывать часть символов в середине номера телефона.
	 * Действует только одновременно с disabled или readOnly.
	 */
	hideNumberMode: PropTypes.bool,
	qaAttributes: PropTypes.shape({
		title: PropTypes.object,
		value: PropTypes.object,
	}),
};

InputPhoneNumber.defaultProps = {
	hideNumberMode: false,
	qaAttributes: {},
};


export default InputPhoneNumber;
