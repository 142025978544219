import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { AllSvgIcons, SvgIcon } from '@components';

const styles = theme => ({
	root: {
		padding: '20px',
	},
	image: {
		marginRight: '10px',
	},
	arrowIcon: {
		stroke: theme.typography.color.primary,
		marginRight: '10px',
	},
	doneIcon: {
		height: '18px',
		paddingRight: '5px',
		stroke: theme.typography.color.white,
	},
	bankImage: {
		width: '56px',
		height: '56px',
	},
});

class Icons extends React.Component {
	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<GridList cellHeight={80} className={classes.root} cols={6}>
					{AllSvgIcons.map((iconName, index) => (
						<GridListTile key={`${iconName}-${index}`} cols={1}>
							{iconName === 'Done' &&
								<SvgIcon classes={{ root: classes.doneIcon }} icon="Done" />
							}
							{iconName === 'Bank' &&
								<SvgIcon viewBox="0 0 56 56" className={classes.bankImage} icon="Bank" />
							}
							{iconName !== 'Done' && iconName !== 'Bank' &&
								<SvgIcon icon={iconName} className={iconName.indexOf('Arrow') >= 0 ? classes.arrowIcon : classes.image} />
							}
							<span>{iconName}</span>
						</GridListTile>
					))}
				</GridList>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(Icons);

