import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

const styles = theme => ({
	root: {
		width: '100%',
		display: 'flex',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: '2',
		alignContent: 'center',
		justifyContent: 'center',
	},
	rightColumn: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'flex-start',
		textAlign: 'right',
	},
	title: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	subtitle: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
});


class PlaidAccountRadioItem extends React.Component {
	render() {
		const { classes, item } = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					<div className={classes.title}>
						*{item.Numbers.account.substr(-4)}
					</div>
					<div className={classes.subtitle}>
						${item.Info.balances.available}
					</div>
				</div>
				<div className={classes.rightColumn}>
					<div className={classes.subtitle}>
						{item.Info.subtype}
					</div>
				</div>
			</div>
		);
	}
}

PlaidAccountRadioItem.propTypes = {
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(PlaidAccountRadioItem);
