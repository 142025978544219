import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ModalPopups } from '@enums';
import { userAccounts } from '@redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {
	ErrorMessage,
	SuccessMessage,
	ProgressContainer,
} from '@components';
import { UpgradeAccountForm } from './components';
import { RequireSignatureModal } from '../';

const styles = theme => ({
});

const mapStateToProps = (state) => {
	const currentAccountData = userAccounts.selectors.currentAccountData(state);
	const upgradeMarginTypeData = userAccounts.selectors.upgradeMarginTypeData(state);
	return {
		accountStatus: userAccounts.selectors.currentAccountStatus(state),
		accountInfo: userAccounts.selectors.accountInfo(state),
		ready: !!(upgradeMarginTypeData.tradeAccount || (
			!currentAccountData.submitting && currentAccountData.baseParams && currentAccountData.baseParams.MarginOptionParameters
		)),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
	},
});

class UpgradeAccountModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			succeeded: false,
		};
	}

	onSuccess = () => {
		this.setState({ succeeded: true });
	}

	render() {
		const {
			accountStatus,
			accountInfo,
			onClose,
			ready,
		} = this.props;
		const canMarginTypeChange = accountStatus && accountStatus.CanMarginUpgrade &&
			accountInfo && accountInfo.IsPrimaryHolder;
		const pendingOrder = accountInfo && accountInfo.PendingOrders && accountInfo.PendingOrders.UpgradeMarginType;
		const error = (pendingOrder || !canMarginTypeChange);
		return (
			<RequireSignatureModal
				title="Upgrade Account"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.UPGRADE_MARGIN_TYPE}`}
			>
				{this.state.succeeded &&
					<SuccessMessage
						onClick={onClose}
						text={
							<p>
								Your request was received.<br />
								Our manager will get back to you when the account is upgraded.
							</p>
						}
						buttonText="Back To Account"
					/>
				}
				{!this.state.succeeded && (
					error && ready ?
						(
							<ErrorMessage
								onClick={onClose}
								text={pendingOrder ? 'Another request for margin type change is in progress. Please try again later.' :
									'You can not change margin type for this account.'
								}
							/>
						) :
						(
							<ProgressContainer submitting={!ready}>
								<div>
									<UpgradeAccountForm onSuccess={this.onSuccess} />
								</div>
							</ProgressContainer>
						)
				)}
			</RequireSignatureModal>
		);
	}
}

UpgradeAccountModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	ready: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(UpgradeAccountModal);
