import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { app } from '@redux';
import {
	ChangeLink,
	ExpansionPanel,
	R,
} from '@components';
import { ModalPopups } from '@enums';
import { InputEditableItem } from '@shared';


const useActions = () => {
	const dispatch = useDispatch();
	return ({
		showChangePasswordModal: () => dispatch(app.actions.showBaseModal(ModalPopups.CY_CHANGE_PASSWORD)),
	});
};

const CYAccountCredentialsPanel = (props) => {
	const {
		onToggle,
		panelId,
		isOpen,
		classes,
	} = props;

	const actions = useActions();

	return (
		<ExpansionPanel
			header={<R id="common_header_account_credentials" defaultValue="Account Credentials" />}
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
		>
			<InputEditableItem
				title={<R id="common_password" defaultValue="Password" />}
				value="*************"
				editControl={
					<ChangeLink onClick={actions.showChangePasswordModal} />
				}
			/>
		</ExpansionPanel>
	);
};

CYAccountCredentialsPanel.propTypes = {
	classes: PropTypes.object.isRequired,
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

CYAccountCredentialsPanel.defaultProps = {
	isOpen: false,
};

export default CYAccountCredentialsPanel;
