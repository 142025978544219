import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { acatDeposit, userAccounts } from '@redux';
import { ErrorMessage, ProgressContainer } from '@components';
import { connect } from 'react-redux';
import { AcatFundForm, AcatFundIraForm } from './components';
import { RequireSignatureModal } from '../';

const styles = {
	root: {
		marginBottom: '30px',
	},
	tabHeader: {
		fontSize: '16px',
		fontWeight: 'normal',
		minWidth: '100px',
		maxWidth: '100%',
		flexGrow: 1,
	},
	wrapper: {
		fontSize: '0.875rem',
	},
};

const mapStateToProps = (state) => {
	const accountInfo = userAccounts.selectors.accountInfo(state);
	const acatTradeAccount = acatDeposit.selectors.tradeAccount(state);
	const currentAccountData = userAccounts.selectors.currentAccountData(state);
	return {
		tradeAccount: acatTradeAccount || userAccounts.selectors.currentTradeAccount(state),
		accountStatus: userAccounts.selectors.currentAccountStatus(state),
		currentAccountData,
		userInfo: userAccounts.selectors.userInfo(state),
		ready: acatTradeAccount || !(currentAccountData.submitting || accountInfo.loading),
		isIra: accountInfo.IsIra,
	};
};

class AcatFundModal extends React.Component {
	render() {
		const {
			onClose,
			tradeAccount,
			accountStatus,
			ready,
			isIra,
		} = this.props;
		const acatAvailable = accountStatus.CanFund && accountStatus.CanAcat;
		const FundForm = isIra ? AcatFundIraForm : AcatFundForm;
		return (
			<RequireSignatureModal
				title="Transfer Shares"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.ACAT_FUND}`}
			>
				<ProgressContainer submitting={!ready}>
					<div>
						{acatAvailable ?
							<FundForm
								tradeAccount={tradeAccount}
								onClose={onClose}
							/> :
							<ErrorMessage
								onClick={onClose}
								text={`Unavailable for account ${tradeAccount.tradeCode}.`}
							/>
						}
					</div>
				</ProgressContainer>
			</RequireSignatureModal>
		);
	}
}

AcatFundModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(AcatFundModal);
