import React, { useEffect, useState }  from 'react';
import {
	withStyles,
	withTheme,
} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { useDropzone }  from 'react-dropzone';
import { SvgIcon, R } from '@components';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { provider } from '@api';
import PropTypes, { object } from 'prop-types';
import acceptChecker from 'attr-accept';
import bytes from 'bytes';
import getHash from 'crypto-js/md5';

const styles = theme => ({
	root: {

	},
	uploader: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		border: `2px dashed ${theme.typography.color.secondary}`,
		display: 'flex',
		minHeight: '112px',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	activeClassName: {
		backgroundColor: theme.palette.color.secondary.select,
	},
	uploadedFilesContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	uploadedFilesItem: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 5px',
		'&:hover': {
			backgroundColor: '#fefefe',
		},
	},
	uploadedFileName: {},
	uploadedFileAction: {},
	errors: {},
	error: {
		...theme.typography.style.body,
		padding: '5px',
		color: theme.typography.color.red,
		margin: '5px 0',
		backgroundColor: '#fff',
		boxShadow: '0 1px 2px 0 rgba(61, 65, 71, 0.12)',
		borderRadius: '4px',
	},
});

const convertToMB = value => bytes(value, {
	decimalPlaces: null,
});

function FileInput(props) {
	const [loading, setLoading] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [errors, setErrors] = useState([]);
	useEffect(() => {
		props.onFilesUpdate(uploadedFiles);
	}, [uploadedFiles]);
	const onDeleteButtonClick = (fileItem) => {
		setUploadedFiles(uploadedFiles.filter(item => item !== fileItem));
		setErrors([]);
	}

	function isUploaded(file, hash) {
		return uploadedFiles.some(uploadedFile =>
			uploadedFile.Name.toLowerCase() === file.name.toLowerCase() ||
			(uploadedFile.Size === file.size && uploadedFile.Hash === hash)
		);
	}

	const onDropAccepted = async (acceptedFiles) => {
		const data = new FormData();
		let checked = 0;
		setLoading(true);
		const filesHash = [];
		const checkErrors = [];

		const checkAllChecked =  async () => {
			if (++checked === acceptedFiles.length) {
				const request = await provider.post('/api_upload/', data);
				if (request.data) {
					if (request.data.Success) {
						request.data.Data.forEach((f) => {
							f.Hash = filesHash[f.Name.toLowerCase()];
							f.Size = acceptedFiles.find(af => af.name.toLowerCase() === f.Name.toLowerCase()).size;
						});
						setUploadedFiles(uploadedFiles => [...uploadedFiles, ...request.data.Data]);
					} 
				}
				setErrors(checkErrors);
				if (checkErrors.length > 0 && props.onReject) {
					props.onReject();
				}
				setLoading(false);
			}
		};	
		acceptedFiles.forEach((file) => {
			if (isUploaded(file, null)) {
				checkErrors.push(`File with the same name or content was already uploaded (${file.name})`);
				checkAllChecked();
				return;
			}
			const reader = new FileReader()
			reader.onabort = () => { 
				checkErrors.push(`File reading was aborted (${file.name})}`)
				checkAllChecked();
			};
			reader.onerror = () => {
				checkErrors.push(`File reading has failed (${file.name})}`);
				checkAllChecked();
			};
			reader.onload = async () => {
				const binaryStr = reader.result;
				const hash = getHash(binaryStr).toString();

				if (isUploaded(file, hash) || Object.values(filesHash).some(h => h === hash)) {
					checkErrors.push(`File with the same name or content was already uploaded (${file.name})`);
				} else {
					filesHash[file.name.toLowerCase()] = hash;
					data.append('', file);
				}
				checkAllChecked();
			};
			// Start reading file
			reader.readAsBinaryString(file);
		});
	}

	const onDropRejected = (files) => {
		const errors = [];
		if (files.length > 1 && !props.multiple) {
			errors.push('Maximum files count: 1');
		}
		files.forEach((file) => {

			if (!acceptChecker(file, props.accept)) {
				errors.push(`Error uploading file ${file.name}. Allowed file extensions: ${props.accept}`);
			}
			if (file.size > props.maxSize) {
				errors.push(`Error uploading file ${file.name}. Maximum file size: ${convertToMB(props.maxSize)}`);
			}
			if (file.size < props.minSize) {
				errors.push(`Error uploading file ${file.name}. Minimum file size: ${convertToMB(props.minSize)}`);
			}
		});
		setErrors(errors);
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: props.accept,
		minSize: props.minSize,
		maxSize: props.maxSize,
		multiple: props.multiple,
		onDropAccepted,
		onDropRejected,
	});
	const {
		classes,
		theme,
	} = props;
	return (
		<div className={classes.root}>
			<div className={classes.uploadedFilesContainer}>
				{uploadedFiles.map((file, index) => (
					<div key={index} className={classes.uploadedFilesItem}>
						<div className={classes.uploadedFileName}>
							{file.Name}
						</div>
						<IconButton onClick={() => onDeleteButtonClick(file)}>
							<SvgIcon
								icon="Close"
								htmlColor={theme.typography.color.red}
							/>
						</IconButton>
					</div>
				))}
			</div>
			{errors.length > 0 &&
				<div className={classes.errors}>
					{errors.map((error, index) => (
						<div className={classes.error} key={index}>
							{error}
						</div>))}
				</div>
			}
			<div {...getRootProps()} className={classes.uploader}>
				<input {...getInputProps()} />
				{loading ?
					<CircularProgress />
					:
					<R
						id="common_drop_or_click_to_select_file"
						enableHtml
						defaultValue="<p>Drop files here to attach <br />or click to select files</p>"
					/>
				}
			</div>
		</div>
	);
}

FileInput.propTypes = {
	minSize: PropTypes.number,
	maxSize: PropTypes.number,
	accept: PropTypes.string,
	onFilesUpdate: PropTypes.func.isRequired,
	onReject: PropTypes.func,
	multiple: PropTypes.bool,
};

FileInput.defaultProps = {
	/**
	 * allow multiple upload
	 */
	multiple: true,
	/**
	 * maxSize in bytes
	 */
	maxSize: 26214400,
	accept: '.jpg,.jpeg,.gif,.png,.tiff,.pdf',
	minSize: 0,
};

export default compose(
	withStyles(styles),
	withTheme,
)(FileInput);
