import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

const styles = theme => ({
	root: {
		textDecoration: 'none',
		cursor: 'pointer',
	},
	default: {
		color: theme.palette.primary.light,
	},
	white: {
		color: 'white',
	},
	upper: {
		textTransform: 'uppercase',
	},
});

const Link = ({
	classes,
	upper,
	href,
	to,
	...props
}) => {
	const cssClasses = classNames({
		[classes.root]: true,
		[classes.default]: props.color === 'default',
		[classes.white]: props.color === 'white',
		[classes.upper]: upper,
	});

	return (
		<RouterLink className={cssClasses} to={to || href} {...props}>{props.children}</RouterLink>
	);
};

Link.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(['default', 'white']),
	upper: PropTypes.bool,
	href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Link.defaultProps = {
	color: 'default',
	upper: false,
	href: null,
	to: null,
};

export default withStyles(styles)(Link);
