import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { MuiThemeProvider, createTheme, Box } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { app } from '@redux';
import { R } from '@components';

const styles = () => ({
	'MuiInput-underline': {
		border: 'none!important',
		'&:before': {
			'border-bottom': 'none!important',
		},
	},
	calendarsSplit: {
		display: 'inline-flex', 
		color: '#1a96ff'
	},
});
const materialTheme = createTheme({
	overrides: {
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: '#1a96ff',
			},
		},
		MuiPickersCalendarHeader: {
			switchHeader: {
				fontSize: '14px',
				// backgroundColor: lightBlue.A200,
				// color: 'white',
			},
		},
		MuiTypography: {
			caption: {
				fontSize: '14px',
			},
		},
		MuiPickersDay: {
			day: {
				fontSize: '14px',
			},
			daySelected: {
				backgroundColor: '#1a96ff',
				'&:hover': {
					backgroundColor: '#1a96ff',
				},
			},
			current: {
				color: '#1a96ff',
			},
		},
		/*
			Более правильный вариант переопределения стилей в кнопках диалога, но изза баги в mui-pickers
			в консоли Warning:
			Material-UI: You are trying to override a style that does not exist.
			Fix the `dialogAction` key of `theme.overrides.MuiPickersModal`.
			about this bug: https://github.com.cnpmjs.org/mui-org/material-ui-pickers/issues/1376
			Вместо этого переопределения использовано MuiButton (не должно ни на что влиять,
				т.к. другх кнпок кроме кнопок самого диалога внутри тега не будет)

			MuiPickersModal: {
				dialogAction: {
					color: '#1a96ff',
				},
			},
		*/
		MuiButton: {
			label: {
				color: '#1a96ff',
			},
		},
		MuiInput: {
			input: {
				cursor: 'pointer',
				textAlign: 'inherit',
				padding: '4px 0 5px',
			},
			root: {
				color: '#1a96ff',
				'&$focused': {
					'&:before': {
						border: 'none!important',
					},
				},
			},
			underline: {
				'&:before': {
					border: 'none!important',
				},
				'&:after': {
					border: 'none!important',
				},
			},
		},
	},
});

const mapStateToProps = state => ({
	language: app.selectors.language(state),
});

class DateRangePicker extends React.Component {
	startDateChange = (date) => {
		if (this.props.onStartDateChange) {
			this.props.onStartDateChange(date);
		}
	};

	endDateChange = (date) => {
		if (this.props.onEndDateChange) {
			this.props.onEndDateChange(date);
		}
	};


	render() {
		const {
			classes,
			startDate,
			endDate,
			language,
			cancelLabel = <R id="shared_cancel" defaultValue="Cancel" />,
			okLabel = <R id="shared_ok" defaultValue="OK" />,
			todayLabel = <R id="shared_today_btn" defaultValue="today" />,
		} = this.props;

		return (

			<span>
				<MuiThemeProvider theme={materialTheme}>
					<MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
						<Box display="flex" justifyContent="center" alignItems="center">
							<DatePicker
								showTodayButton
								format="DD MMM YY"
								maxDate={endDate}
								style={{ maxWidth: 90, minWidth: 86, textAlign: 'end' }}
								value={startDate}
								onChange={this.startDateChange}
								cancelLabel={cancelLabel}
								okLabel={okLabel}
								todayLabel={todayLabel}
							/>
							<i style={{ marginLeft: 10 }} />
							<div className={classes.calendarsSplit}>&mdash;</div>
							<i style={{ marginLeft: 10 }} />
							<DatePicker
								minDate={startDate}
								format="DD MMM YY"
								showTodayButton
								style={{ maxWidth: 90, minWidth: 86 }}
								value={endDate}
								onChange={this.endDateChange}
								cancelLabel={cancelLabel}
								okLabel={okLabel}
								todayLabel={todayLabel}
							/>
						</Box>
					</MuiPickersUtilsProvider>
				</MuiThemeProvider>
			</span>);
	}
}

DateRangePicker.propTypes = {
	classes: PropTypes.object.isRequired,
	onStartDateChange: PropTypes.func,
	onEndDateChange: PropTypes.func,
	startDate: PropTypes.any,
	endDate: PropTypes.any,
};

DateRangePicker.defaultProps = {
	onStartDateChange: null,
	onEndDateChange: null,
	startDate: new Date(),
	endDate: new Date(),
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(DateRangePicker);
