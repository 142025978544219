module.exports = {
	AccountClearers: {
		UNKNOWN: '',
		COR: 'cor',
		VISION: 'vision',
		J2T: 'j2t',
		J2T_INC: 'j2tinc',
		VELOX: 'velox',
		SPC_FUTURES: 'spcfutures',
		DEMO: 'demo',
	},
};
