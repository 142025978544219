import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@components';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => createStyles({
	root: {
		margin: '24px 74px',
		padding: '10px 16px',
		backgroundColor: theme.palette.color.secondary.limitOrder,
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0',
			padding: '10px 16px',
		},
	},
	content: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	actions: {
		marginLeft: '16px',
	},
	button: {
		color: theme.typography.color.link,
	},
}));

const GenericTextAlert = ({
	onClick,
	buttonText,
	text,
	hideButton,
}) => {
	const classes = useStyles();
	const showButton = !hideButton && onClick && buttonText;

	return (
		<div>
			<div className={classes.root}>
				<div className={classes.content}>{text}</div>
				{showButton &&
					<div className={classes.actions}>
						<Button
							onClick={onClick}
							className={classes.button}
						>
							{buttonText}
						</Button>
					</div>
				}
			</div>
		</div>
	);
};

GenericTextAlert.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	buttonText: PropTypes.string,
	onClick: PropTypes.func,
	hideButton: PropTypes.bool,
};

GenericTextAlert.defaultProps = {
	onClick: null,
	hideButton: false,
	buttonText: null,
};

export default GenericTextAlert;
