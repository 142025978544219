import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userAccounts, profile } from '@redux';
import { A } from '@components';
import GenericTextAlert from './GenericTextAlert';


const useMarketDataStatuses = () => {
	const userProfile = useSelector(profile.selectors.profile);
	const currentAccountData = useSelector(userAccounts.selectors.currentAccountData) || {};
	const isEnabledSubscription = useSelector(userAccounts.selectors.anyActiveAccountExists) || false;

	const loading = userProfile.submitting || currentAccountData.submitting;
	const hasPendingOrderSubscription = !userProfile.loading && userProfile.PendingOrders.MarketDataSubscription;
	const hasPendingOrderStatus = !userProfile.submitting && userProfile.PendingOrders.MarketDataStatus;

	const needStatus = !loading && !userProfile.MarketDataStatus && !hasPendingOrderStatus;
	const needSubscription = !loading &&
					!hasPendingOrderSubscription &&
					userProfile.MarketDataSubscriptions &&
					!userProfile.MarketDataSubscriptions.some(x => x.IsOn) &&
					isEnabledSubscription;

	return {
		needAlert: needStatus || (needSubscription && isEnabledSubscription),
		needStatus,
		needSubscription,
		enabledSubscription: isEnabledSubscription,
	};
};

const useActions = () => {
	const dispatch = useDispatch();
	return ({
		showChangeMarketDataSubscriberStatusModal: () => dispatch(profile.actions.showChangeMarketDataSubscriberStatusModal()),
		showChangeMarketDataSubscriptionsModal: () => dispatch(profile.actions.showChangeMarketDataSubscriptionsModal()),
	});
};

const MarketDataAlert = () => {
	const {	needAlert, enabledSubscription } = useMarketDataStatuses();

	const actions = useActions();
	const linkSubscriptions = enabledSubscription ?
		<A onClick={actions.showChangeMarketDataSubscriptionsModal}>subscriptions</A> : <React.Fragment>subscriptions</React.Fragment>;

	return (
		<React.Fragment>
			{needAlert &&
				<GenericTextAlert
					text={
						<React.Fragment>
							Please update your Market data <A onClick={actions.showChangeMarketDataSubscriberStatusModal}>subscriber status</A> and select {linkSubscriptions}
						</React.Fragment>
					}
				/>
			}
		</React.Fragment>
	);
};

export default MarketDataAlert;
