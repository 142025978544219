import { createTheme, rgbToHex } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
// Based on https://app.zeplin.io/project/5a6ed307163440c3e15247d9/screen/5a97da1b61f517c8b851fe6e

const breakpoints = createBreakpoints({
	values: {
		lg: 1280,
		md: 1024,
		sm: 600,
		xl: 1920,
		xs: 0,
	},
});
const theme = {
	breakpoints,
	palette: {
		lime: {
			primary: {
				default: 'rgba(59, 145, 38, 1)',
				hover: 'rgba(53, 130, 60, 1)',
				pressed: 'rgba(38, 110, 57, 1)',
				textDefault: 'rgba(255, 255, 255, 1)',
			},
			secondary: {
				default: 'rgba(133, 219, 46, 0.16)',
				hover: 'rgba(127, 214, 41, 0.24)',
				pressed: 'rgba(110, 212, 42, 0.32)',
				textDefault: 'rgba(47, 138, 33, 1)',
				textHover: 'rgba(47, 115, 55, 1)',
				textPressed: 'rgba(32, 92, 50, 1)',
			},
		},
		text: {
			secondary: '#7d9eb7',
		},
		color: {
			primary: {
				black: '#345464',
				blue: '#1a96ff',
				lightBlue: '#78aaff',
				white: '#f9fcfd',
				attestationBackground: 'rgba(200, 237, 249, 0.3)',
			},
			secondary: {
				up: '#11d87c',
				upDark: '#04c070',
				down: '#ff6567',
				downDark: '#ed5153',
				limitOrder: '#f9d113',
				gray: '#7d9eb7',
				disable: '#b3c9d9',
				select: '#e3edf5',
				card: '#ffffff',
			},
		},
		primary: {
			main: '#1a96ff',
			dark: '#345464',
			light: '#78aaff',
			white: '#f9fcfd',
		},
		warning: {
			light: '#ffff57',
			main: '#f9d113',
			dark: '#c2a000',
			contrastText: '#000000',
		},
	},
	customstyles: {
		title: {
			fontSize: '18px',
			fontWeight: 'normal',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			lineHeight: '28px',
			letterSpacing: 'normal',
		},
		body: {
			fontSize: '14px',
			color: 'inherit',
			fontWeight: 'normal',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			lineHeight: '24px',
			letterSpacing: 'normal',
		},
	},
	typography: {
		color: {
			primary: '#345464',
			secondary: '#7d9eb7',
			disable: '#b3c9d9',
			link: '#78aaff',
			white: '#f9fcfd',
			yellow: '#f9d113',
			pink: '#f7eaeb',
			red: '#ff6567',
			muiError: '#f44336',
			darkRed: red[400],
			green: '#11d87c',
			darkGreen: green[400],
			lightGreen: green[200],
			blue: '#1a96ff',
			darkBlue: blue[400],
		},
		style: {
			display: {
				fontSize: '64px',
				color: 'inherit',
				fontWeight: 300,
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				letterSpacing: '-.4px',
				lineHeight: '72px',
			},
			headline1: {
				fontSize: '34px',
				color: 'inherit',
				fontWeight: 'normal',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '40px',
			},
			headlineSm: {
				fontSize: '30px',
				color: 'inherit',
				fontWeight: 'normal',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '36px',
			},
			headline2: {
				fontSize: '28px',
				color: 'inherit',
				fontWeight: 'bold',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '38px',
				letterSpacing: 'normal',
			},
			subheading: {
				fontSize: '24px',
				color: 'inherit',
				fontWeight: 'normal',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '32px',
				letterSpacing: '-0.2px',
			},
			subheadingSm: {
				fontSize: '22px',
				color: 'inherit',
				fontWeight: 'normal',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '32px',
				letterSpacing: '-0.2px',
			},
			title: {
				fontSize: '18px',
				fontWeight: 'normal',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '28px',
				letterSpacing: 'normal',
			},
			body: {
				fontSize: '14px',
				color: 'inherit',
				fontWeight: 'normal',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '24px',
				letterSpacing: 'normal',
			},
			button: {
				fontSize: '14px',
				color: 'inherit',
				fontWeight: '500',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '24px',
				textTransform: 'uppercase',
				letterSpacing: 'normal',
			},
			buttonLime: {
				fontSize: '16px',
				fontWeight: '500',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
				lineHeight: '24px',
				textTransform: 'none',
				letterSpacing: 'normal',
			},
			stickyButton: {
				backgroundColor: '#f9fcfd',
				bottom: 0,
				paddingTop: '15px',
				marginTop: '30px', // transparent back color when margin
				paddingBottom: '45px',
				position: 'sticky',
			},
		},
	},
	table: {
		default: {
			width: '100%',
			verticalAlign: 'top',
			borderCollapse: 'collapse',
			'& thead tr': {
				borderTop: '1px solid #deeaef',
				borderBottom: '3px solid #deeaef',
			},
			'& th': {
				fontSize: '14px',
				fontWeight: 'normal',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '24px',
				letterSpacing: 'normal',
				color: '#7d9eb7',
				padding: '5px 10px',
				verticalAlign: 'top',
				textAlign: 'left',
			},
			'& td': {
				padding: '5px 10px',
				textAlign: 'left',
			},
			'& tbody': {
				verticalAlign: 'top',
				'& tr': {
					borderBottom: '1px solid #deeaef',
				},
			},
		},
		divTable: {
			table: {
				display: 'table',
				width: '100%',
				verticalAlign: 'top',
				borderCollapse: 'collapse',
			},
			bodyRow: {
				display: 'table-row',
				borderBottom: '1px solid #deeaef',
			},
			headRow: {
				display: 'table-row',
				borderTop: '1px solid #deeaef',
				borderBottom: '3px solid #deeaef',
			},
			bodyCell: {
				display: 'table-cell',
				padding: '0 10px',
				lineHeight: '30px',
				textAlign: 'left',
			},
			headCell: {
				display: 'table-cell',
				fontSize: '14px',
				fontWeight: 'normal',
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				lineHeight: '24px',
				letterSpacing: 'normal',
				color: '#7d9eb7',
				padding: '0 10px',
				verticalAlign: 'top',
				textAlign: 'left',
			},
		},
	},
	fontSize: 18,
	overrides: {
		MuiInputBase: {
			formControl: {
				'label + &': {
					marginTop: '16px',
				},
			},
		},
		MuiSvgIcon: {
			root: {
				color: 'inherit',
			},
		},
		MuiMenuItem: {
			root: {
				paddingTop: '12px',
				paddingBottom: '12px',
			},
		},
		MuiListItem: {
			root: {
				'&.Mui-selected': {
					backgroundColor: 'rgba(0, 0, 0, 0.14)',
				},
				'&.Mui-selected:hover': {
					backgroundColor: 'rgba(0, 0, 0, 0.14)',
				},
			},
			gutters: {
				[breakpoints.up('sm')]: {
					paddingLeft: '24px',
					paddingRight: '24px',
				},
			},
			button: {
				'&:hover': {
					backgroundColor: 'rgba(0, 0, 0, 0.08)',
				},
			},
		},
		MuiFormLabel: {
			root: {
				'&.Mui-focused': {
					color: '#7d9eb7',
				},
			},
		},
	},
};

export default createTheme(theme);
