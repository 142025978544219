import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@components';
import CustomEditableItem from './CustomEditableItem';
// eslint-disable-next-line no-unused-vars

function valueOrDefault(val, defaultVal) {
	if (val) return val;
	return defaultVal;
}
const InputEditableItem = ({
	title,
	value,
	valueIfEmpty,
	qaAttributes,
	...otherProps
}) => {
	const valueControl = (<Input
		placeholder={title}
		value={valueOrDefault(value, valueIfEmpty)}
		qaAttributes={qaAttributes}
		showLabel
		disabled
	/>);
	return (
		<CustomEditableItem
			valueControl={valueControl}
			{...otherProps}
		/>
	);
};

InputEditableItem.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.string,
	valueIfEmpty: PropTypes.string,
	qaAttributes: PropTypes.shape({
		title: PropTypes.object,
		value: PropTypes.object,
	}),
};
InputEditableItem.defaultProps = {
	value: undefined,
	valueIfEmpty: '',
	qaAttributes: {},
};
export default InputEditableItem;
