import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profile } from '@redux';


const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
});


class FreshChat extends React.Component {
	constructor(props) {
		super(props);
		this.state = { chatInitialized: false };
	}

	componentDidMount() {
		// init application when start (no current account)
		this.initChat();
	}

	componentDidUpdate() {
		this.initChat();
	}

	initChat = () => {
		const { profile } = this.props;
		const { chatInitialized } = this.state;

		if (!chatInitialized && !profile.submitting && !profile.error) {
			const splittedName = (profile.PersonalInformation.Name || '').split(' ');
			const firstName = splittedName[0];
			const lastName = splittedName[1];

	
			try {
				window.fcWidget.init({
					token: '77139581-3add-49de-a043-79b11f714472',
					host: 'https://wchat.freshchat.com',
					tags: ['cabinet'],
					config: {
						content: {
							headers: {
								channel_response: {
									offline: 'We are currently away',
									online: {
										minutes: {
											one: 'You will get a reply in a minute',
											more: 'You will get a reply in 5 minutes',
										},
										hours: {
											one: 'You will get a reply in 5 minutes',
											more: 'We are currently away',
										},
									},
								},
							},
						},
					},
				});
				this.setState({ chatInitialized: true });
				window.fcWidget.setExternalId(profile.FreshChatId);
				window.fcWidget.user.setFirstName(firstName);
				window.fcWidget.user.setLastName(lastName);
				window.fcWidget.user.setEmail(profile.PersonalInformation.Email);
			} catch (error) {
				window.Raven.captureException(error);
			}
		}
	}

	render() {
		return (<React.Fragment />);
	}
}

FreshChat.propTypes = {
};

export default connect(mapStateToProps, null)(FreshChat);
