import { providerWithAuth } from '@api';

module.exports = {

	createIpoOrder: payload => providerWithAuth().post('/ipo/api_createOrder', payload),
	cancelIpoOrder: payload => providerWithAuth().post('/ipo/api_cancelOrder', payload),
	getOfferings: payload => providerWithAuth().post('/ipo/api_calendar', payload),
	getOffering: offeringId => providerWithAuth().get(`/ipo/api_ipogetoffering/${offeringId}`),
	getOrders: payload => providerWithAuth().post('/ipo/api_orders', payload),
};
