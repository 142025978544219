import { createFormAction } from 'redux-form-saga';
import { createApiActions } from '../extensions';

const changeProfileAddressFormSubmitActionPrefix = 'PROFILE_CHANGE_ADDRESS_FORM_SUBMIT';
const changeProfileAddressFormActionCreator =
	createFormAction(changeProfileAddressFormSubmitActionPrefix);

const changeProfilePostAddressFormSubmitActionPrefix = 'PROFILE_CHANGE_POST_ADDRESS_FORM_SUBMIT';
const changeProfilePostAddressFormActionCreator =
	createFormAction(changeProfilePostAddressFormSubmitActionPrefix);

const confirmProfileFormActionPrefix = 'PROFILE_CONFIRM_FORM_SUBMIT';
const confirmProfileFormActionCreator =
	createFormAction(confirmProfileFormActionPrefix);

const changeProfileEmailFormSubmitActionPrefix = 'PROFILE_CHANGE_EMAIL_FORM_SUBMIT';
const changeProfileEmailFormActionCreator =
	createFormAction(changeProfileEmailFormSubmitActionPrefix);

const changeProfilePhoneFormSubmitActionPrefix = 'PROFILE_CHANGE_PHONE_FORM_SUBMIT';
const changeProfilePhoneFormActionCreator =
	createFormAction(changeProfilePhoneFormSubmitActionPrefix);

const changeProfileDependentsFormSubmitActionPrefix = 'PROFILE_CHANGE_DEPENDENTS_FORM_SUBMIT';
const changeProfileDependentsFormSubmitActionCreator =
	createFormAction(changeProfileDependentsFormSubmitActionPrefix);

const changeProfileMaritalStatusFormSubmitActionPrefix = 'PROFILE_CHANGE_MARITAL_STATUS_FORM_SUBMIT';
const changeProfileMaritalStatusFormSubmitActionCreator =
	createFormAction(changeProfileMaritalStatusFormSubmitActionPrefix);

const changeProfileEmploymentFormSubmitActionPrefix = 'PROFILE_CHANGE_EMPLOYMENT_FORM_SUBMIT';
const changeProfileEmploymentFormSubmitActionCreator =
	createFormAction(changeProfileEmploymentFormSubmitActionPrefix);

const changeProfileInvestmentProfileFormSubmitActionPrefix = 'PROFILE_CHANGE_INVESTMENT_PROFILE_FORM_SUBMIT';
const changeProfileInvestmentProfileFormSubmitActionCreator =
	createFormAction(changeProfileInvestmentProfileFormSubmitActionPrefix);

const changeProfileInvestmentExperienceFormSubmitActionPrefix = 'PROFILE_CHANGE_INVESTMENT_EXPERIENCE_FORM_SUBMIT';
const changeProfileInvestmentExperienceFormSubmitActionCreator =
	createFormAction(changeProfileInvestmentExperienceFormSubmitActionPrefix);

const changeDeliveryTypeFormSubmitActionCreator = createFormAction('PROFILE_CHANGE_DELIVERY_TYPE_FORM_SUBMIT');

const changeTaxFormUploadFormSubmitActionPrefix = 'PROFILE_CHANGE_TAXFORM_UPLOAD_FORM_SUBMIT';
const changeTaxFormUploadFormActionCreator =
	createFormAction(changeTaxFormUploadFormSubmitActionPrefix);

const changeTaxFormFillInFormSubmitActionPrefix = 'PROFILE_CHANGE_TAXFORM_FILLIN_FORM_SUBMIT';
const changeTaxFormFillInFormActionCreator = createFormAction(changeTaxFormFillInFormSubmitActionPrefix);

const actions = {
	// actions

	GET_PROFILE_REQUEST: 'PROFILE/GET_PROFILE_REQUEST',
	GET_PROFILE_SUCCESS: 'PROFILE/GET_PROFILE_SUCCESS',
	GET_PROFILE_FAILURE: 'PROFILE/GET_PROFILE_FAILURE',

	getProfileRequest: () => ({
		type: actions.GET_PROFILE_REQUEST,
	}),
	getProfileSuccess: profile => ({
		type: actions.GET_PROFILE_SUCCESS,
		payload: {
			...profile,
		},
	}),
	getProfileFailure: error => ({
		type: actions.GET_PROFILE_FAILURE,
		payload: {
			error,
		},
	}),

	getHolderProfiles: createApiActions('PROFILE/GET_HOLDER_PROFILES'),

	SHOW_CHANGE_ADDRESS_MODAL: 'PROFILE/SHOW_CHANGE_ADDRESS_MODAL',

	showChangeAddressModal: profile => ({
		type: actions.SHOW_CHANGE_ADDRESS_MODAL,
		payload: profile,
	}),

	CHANGE_ADDRESS_FORM_SUBMIT_REQUEST: changeProfileAddressFormActionCreator.REQUEST,
	CHANGE_ADDRESS_FORM_SUBMIT_SUCCESS: changeProfileAddressFormActionCreator.SUCCESS,
	CHANGE_ADDRESS_FORM_SUBMIT_FAILURE: changeProfileAddressFormActionCreator.FAILURE,

	changeProfileAddressFormSubmitHandler: changeProfileAddressFormActionCreator,
	changeProfileAddressFormRequest: (...args) =>
		changeProfileAddressFormActionCreator.request({ ...args }),
	changeProfileAddressFormSuccess: payload =>
		changeProfileAddressFormActionCreator.success(payload),
	changeProfileAddressFormFailure: error => changeProfileAddressFormActionCreator.failure(error),

	SHOW_CHANGE_POST_ADDRESS_MODAL: 'PROFILE/SHOW_CHANGE_POST_ADDRESS_MODAL',

	showChangePostAddressModal: profile => ({
		type: actions.SHOW_CHANGE_POST_ADDRESS_MODAL,
		payload: profile,
	}),

	CHANGE_POST_ADDRESS_FORM_SUBMIT_REQUEST: changeProfilePostAddressFormActionCreator.REQUEST,
	CHANGE_POST_ADDRESS_FORM_SUBMIT_SUCCESS: changeProfilePostAddressFormActionCreator.SUCCESS,
	CHANGE_POST_ADDRESS_FORM_SUBMIT_FAILURE: changeProfilePostAddressFormActionCreator.FAILURE,

	changeProfilePostAddressFormSubmitHandler: changeProfilePostAddressFormActionCreator,
	changeProfilePostAddressFormRequest: (...args) =>
		changeProfilePostAddressFormActionCreator.request({ ...args }),
	changeProfilePostAddressFormSuccess: payload =>
		changeProfilePostAddressFormActionCreator.success(payload),
	changeProfilePostAddressFormFailure: error => changeProfilePostAddressFormActionCreator.failure(error),

	SHOW_CHANGE_EMAIL_MODAL: 'PROFILE/SHOW_CHANGE_EMAIL_MODAL',

	showChangeEmailModal: ({ profile, forceConfirmationEmail = false } = {}) => ({
		type: actions.SHOW_CHANGE_EMAIL_MODAL,
		payload: {
			profile,
			forceConfirmationEmail,
		},
	}),

	changePasswordFormSubmit: createFormAction('PROFILE/CHANGE_PASSWORD_FORM_SUBMIT'),

	CHANGE_EMAIL_FORM_SUBMIT_REQUEST: changeProfileEmailFormActionCreator.REQUEST,
	CHANGE_EMAIL_FORM_SUBMIT_SUCCESS: changeProfileEmailFormActionCreator.SUCCESS,
	CHANGE_EMAIL_FORM_SUBMIT_FAILURE: changeProfileEmailFormActionCreator.FAILURE,

	changeProfileEmailFormSubmitHandler: changeProfileEmailFormActionCreator,
	changeProfileEmailFormRequest: (...args) =>
		changeProfileEmailFormActionCreator.request({ ...args }),
	changeProfileEmailFormSuccess: payload =>
		changeProfileEmailFormActionCreator.success(payload),
	changeProfileEmailFormFailure: error => changeProfileEmailFormActionCreator.failure(error),

	sendEmailConfirmationCode: createApiActions('AUTH/SEND_EMAIL_CONFIRMATION_CODE'),

	SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL: 'PROFILE/SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL',
	showChangeMarketDataSubscriberStatusModal: () => ({
		type: actions.SHOW_CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL,
	}),
	changeMarketDataSubscriberStatusForm: createFormAction('PROFILE/CHANGE_MARKETDATA_SUBSCRIBER_STATUS_FORM_SUBMIT'),

	SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL: 'PROFILE/SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL',
	showChangeMarketDataSubscriptionsModal: () => ({
		type: actions.SHOW_CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL,
	}),
	changeMarketDataSubscriptionsForm: createFormAction('PROFILE/CHANGE_MARKETDATA_SUBSCRIPTIONS_FORM_SUBMIT'),

	SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL: 'PROFILE/SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL',
	showEnableThirdPartyMarketDataModal: () => ({
		type: actions.SHOW_ENABLE_THIRD_PARTY_MARKETDATA_MODAL,
	}),
	enableThirdPartyMarketDataForm: createFormAction('PROFILE/ENABLE_THIRD_PARTY_MARKETDATA_FORM_SUBMIT'),

	SHOW_CHANGE_MARITAL_STATUS_MODAL: 'PROFILE/SHOW_CHANGE_MARITAL_STATUS_MODAL',

	showChangeMaritalStatusModal: () => ({
		type: actions.SHOW_CHANGE_MARITAL_STATUS_MODAL,
	}),

	CHANGE_MARITAL_STATUS_FORM_SUBMIT_REQUEST: changeProfileMaritalStatusFormSubmitActionCreator.REQUEST,
	CHANGE_MARITAL_STATUS_FORM_SUBMIT_SUCCESS: changeProfileMaritalStatusFormSubmitActionCreator.SUCCESS,
	CHANGE_MARITAL_STATUS_FORM_SUBMIT_FAILURE: changeProfileMaritalStatusFormSubmitActionCreator.FAILURE,

	changeProfileMaritalStatusFormSubmitHandler: changeProfileMaritalStatusFormSubmitActionCreator,
	changeProfileMaritalStatusFormRequest: (...args) =>
		changeProfileMaritalStatusFormSubmitActionCreator.request({ ...args }),
	changeProfileMaritalStatusFormSuccess: payload =>
		changeProfileMaritalStatusFormSubmitActionCreator.success(payload),
	changeProfileMaritalStatusFormFailure: error => changeProfileMaritalStatusFormSubmitActionCreator.failure(error),

	SHOW_CHANGE_DEPENDENTS_MODAL: 'PROFILE/SHOW_CHANGE_DEPENDENTS_MODAL',

	showChangeDependentsModal: () => ({
		type: actions.SHOW_CHANGE_DEPENDENTS_MODAL,
	}),


	CHANGE_DEPENDENTS_FORM_SUBMIT_REQUEST: changeProfileDependentsFormSubmitActionCreator.REQUEST,
	CHANGE_DEPENDENTS_FORM_SUBMIT_SUCCESS: changeProfileDependentsFormSubmitActionCreator.SUCCESS,
	CHANGE_DEPENDENTS_FORM_SUBMIT_FAILURE: changeProfileDependentsFormSubmitActionCreator.FAILURE,

	changeProfileDependentsFormSubmitHandler: changeProfileDependentsFormSubmitActionCreator,
	changeProfileDependentsFormRequest: (...args) =>
		changeProfileDependentsFormSubmitActionCreator.request({ ...args }),
	changeProfileDependentsFormSuccess: payload =>
		changeProfileDependentsFormSubmitActionCreator.success(payload),
	changeProfileDependentsFormFailure: error => changeProfileDependentsFormSubmitActionCreator.failure(error),

	SHOW_CHANGE_EMPLOYMENT_MODAL: 'PROFILE/SHOW_CHANGE_EMPLOYMENT_MODAL',

	showChangeEmploymentModal: profile => ({
		type: actions.SHOW_CHANGE_EMPLOYMENT_MODAL,
		payload: profile,
	}),


	CHANGE_EMPLOYMENT_FORM_SUBMIT_REQUEST: changeProfileEmploymentFormSubmitActionCreator.REQUEST,
	CHANGE_EMPLOYMENT_FORM_SUBMIT_SUCCESS: changeProfileEmploymentFormSubmitActionCreator.SUCCESS,
	CHANGE_EMPLOYMENT_FORM_SUBMIT_FAILURE: changeProfileEmploymentFormSubmitActionCreator.FAILURE,

	changeProfileEmploymentFormSubmitHandler: changeProfileEmploymentFormSubmitActionCreator,
	changeProfileEmploymentFormRequest: (...args) =>
		changeProfileEmploymentFormSubmitActionCreator.request({ ...args }),
	changeProfileEmploymentFormSuccess: payload =>
		changeProfileEmploymentFormSubmitActionCreator.success(payload),
	changeProfileEmploymentFormFailure: error => changeProfileEmploymentFormSubmitActionCreator.failure(error),

	SHOW_CHANGE_INVESTMENT_PROFILE_MODAL: 'PROFILE/SHOW_CHANGE_INVESTMENT_PROFILE_MODAL',

	showChangeInvestmentProfileModal: () => ({
		type: actions.SHOW_CHANGE_INVESTMENT_PROFILE_MODAL,
	}),


	CHANGE_INVESTMENT_PROFILE_FORM_SUBMIT_REQUEST: changeProfileInvestmentProfileFormSubmitActionCreator.REQUEST,
	CHANGE_INVESTMENT_PROFILE_FORM_SUBMIT_SUCCESS: changeProfileInvestmentProfileFormSubmitActionCreator.SUCCESS,
	CHANGE_INVESTMENT_PROFILE_FORM_SUBMIT_FAILURE: changeProfileInvestmentProfileFormSubmitActionCreator.FAILURE,

	changeProfileInvestmentProfileFormSubmitHandler: changeProfileInvestmentProfileFormSubmitActionCreator,
	changeProfileInvestmentProfileFormRequest: (...args) =>
		changeProfileInvestmentProfileFormSubmitActionCreator.request({ ...args }),
	changeProfileInvestmentProfileFormSuccess: payload =>
		changeProfileInvestmentProfileFormSubmitActionCreator.success(payload),
	changeProfileInvestmentProfileFormFailure: error => changeProfileInvestmentProfileFormSubmitActionCreator.failure(error),

	SHOW_CHANGE_INVESTMENT_EXPERIENCE_MODAL: 'PROFILE/SHOW_CHANGE_INVESTMENT_EXPERIENCE_MODAL',

	showChangeInvestmentExperienceModal: () => ({
		type: actions.SHOW_CHANGE_INVESTMENT_EXPERIENCE_MODAL,
	}),


	CHANGE_INVESTMENT_EXPERIENCE_FORM_SUBMIT_REQUEST: changeProfileInvestmentExperienceFormSubmitActionCreator.REQUEST,
	CHANGE_INVESTMENT_EXPERIENCE_FORM_SUBMIT_SUCCESS: changeProfileInvestmentExperienceFormSubmitActionCreator.SUCCESS,
	CHANGE_INVESTMENT_EXPERIENCE_FORM_SUBMIT_FAILURE: changeProfileInvestmentExperienceFormSubmitActionCreator.FAILURE,

	changeProfileInvestmentExperienceFormSubmitHandler: changeProfileInvestmentExperienceFormSubmitActionCreator,
	changeProfileInvestmentExperienceFormRequest: (...args) =>
		changeProfileInvestmentExperienceFormSubmitActionCreator.request({ ...args }),
	changeProfileInvestmentExperienceFormSuccess: payload =>
		changeProfileInvestmentExperienceFormSubmitActionCreator.success(payload),
	changeProfileInvestmentExperienceFormFailure: error => changeProfileInvestmentExperienceFormSubmitActionCreator.failure(error),

	SHOW_CHANGE_PHONE_MODAL: 'PROFILE/SHOW_CHANGE_PHONE_MODAL',

	showChangePhoneModal: profile => ({
		type: actions.SHOW_CHANGE_PHONE_MODAL,
		payload: profile,
	}),

	CHANGE_PHONE_FORM_SUBMIT_REQUEST: changeProfilePhoneFormActionCreator.REQUEST,
	CHANGE_PHONE_FORM_SUBMIT_SUCCESS: changeProfilePhoneFormActionCreator.SUCCESS,
	CHANGE_PHONE_FORM_SUBMIT_FAILURE: changeProfilePhoneFormActionCreator.FAILURE,

	changeProfilePhoneFormSubmitHandler: changeProfilePhoneFormActionCreator,
	changeProfilePhoneFormRequest: (...args) =>
		changeProfilePhoneFormActionCreator.request({ ...args }),
	changeProfilePhoneFormSuccess: payload =>
		changeProfilePhoneFormActionCreator.success(payload),
	changeProfilePhoneFormFailure: error => changeProfilePhoneFormActionCreator.failure(error),

	SHOW_CHANGE_TWO_FACTOR_AUTHORIZATION_MODAL: 'PROFILE/SHOW_CHANGE_TWO_FACTOR_AUTHORIZATION_MODAL',

	showChangeTwoFactorAuthorizationModal: () => ({
		type: actions.SHOW_CHANGE_TWO_FACTOR_AUTHORIZATION_MODAL,
	}),
	changeTwoFactorAuthorizationForm: createFormAction('PROFILE/CHANGE_TWO_FACTOR_AUTHORIZATION_FORM_SUBMIT'),

	HIDE_CONFIRM_EMAIL_MODAL: 'PROFILE/HIDE_CONFIRM_EMAIL_MODAL',

	hideConfirmEmailModal: () => ({
		type: actions.HIDE_CONFIRM_EMAIL_MODAL,
	}),


	confirmEmailGetUrl: createApiActions('PROFILE/CONFIRM_EMAIL_GET_URL'),


	SHOW_CHANGE_TARIFF_PER_TRADE_MODAL: 'PROFILE/SHOW_CHANGE_TARIFF_PER_TRADE_MODAL',

	showChangeTariffPerTradeModal: () => ({
		type: actions.SHOW_CHANGE_TARIFF_PER_TRADE_MODAL,
	}),


	SHOW_CHANGE_TARIFF_PER_SHARE_MODAL: 'PROFILE/SHOW_CHANGE_TARIFF_PER_SHARE_MODAL',

	showChangeTariffPerShareModal: () => ({
		type: actions.SHOW_CHANGE_TARIFF_PER_SHARE_MODAL,
	}),


	SHOW_CHANGE_TARIFF_ZERO_MODAL: 'PROFILE/SHOW_CHANGE_TARIFF_ZERO_MODAL',

	showChangeTariffZeroModal: () => ({
		type: actions.SHOW_CHANGE_TARIFF_ZERO_MODAL,
	}),


	CHANGE_ACCOUNT_TARIFF_REQUEST: 'PROFILE/CHANGE_ACCOUNT_TARIFF_REQUEST',
	CHANGE_ACCOUNT_TARIFF_SUCCESS: 'PROFILE/CHANGE_ACCOUNT_TARIFF_SUCCESS',
	CHANGE_ACCOUNT_TARIFF_FAILURE: 'PROFILE/CHANGE_ACCOUNT_TARIFF_FAILURE',
	CHANGE_ACCOUNT_TARIFF_RESET: 'PROFILE/CHANGE_ACCOUNT_TARIFF_RESET',

	changeAccountTariffRequest: (tradeCode, tariff) => ({
		type: actions.CHANGE_ACCOUNT_TARIFF_REQUEST,
		payload: {
			tradeCode,
			tariff,
		},
	}),
	changeAccountTariffSuccess: payload => ({
		type: actions.CHANGE_ACCOUNT_TARIFF_SUCCESS,
		payload,
	}),
	changeAccountTariffFailure: error => ({
		type: actions.CHANGE_ACCOUNT_TARIFF_FAILURE,
		payload: {
			error,
		},
	}),
	changeAccountTariffReset: () => ({
		type: actions.CHANGE_ACCOUNT_TARIFF_RESET,
	}),

	SHOW_CONFIRM_PROFILE_MODAL: 'PROFILE/SHOW_CONFIRM_PROFILE_MODAL',

	showConfirmProfileModal: () => ({
		type: actions.SHOW_CONFIRM_PROFILE_MODAL,
	}),

	CONFIRM_PROFILE_FORM_HIDE_AND_REDIRECT: 'PROFILE/CONFIRM_PROFILE_FORM_HIDE_AND_REDIRECT',

	confirmProfileFormHideAndRedirect: () => ({
		type: actions.CONFIRM_PROFILE_FORM_HIDE_AND_REDIRECT,
	}),

	CONFIRM_PROFILE_FORM_SUBMIT_REQUEST: confirmProfileFormActionCreator.REQUEST,
	CONFIRM_PROFILE_FORM_SUBMIT_SUCCESS: confirmProfileFormActionCreator.SUCCESS,
	CONFIRM_PROFILE_FORM_SUBMIT_FAILURE: confirmProfileFormActionCreator.FAILURE,

	confirmProfileFormSubmitHandler: confirmProfileFormActionCreator,
	confirmProfileFormRequest: (...args) =>
		confirmProfileFormActionCreator.request({ ...args }),
	confirmProfileFormSuccess: payload =>
		confirmProfileFormActionCreator.success(payload),
	confirmProfileFormFailure: error => confirmProfileFormActionCreator.failure(error),


	SHOW_CHANGE_DELIVERY_TYPE_MODAL: 'PROFILE/CHANGE_DELIVERY_TYPE_MODAL/SHOW',

	showChangeDeliveryTypeModal: (forceCheckPaperless = false) => ({
		type: actions.SHOW_CHANGE_DELIVERY_TYPE_MODAL,
		payload: {
			forceCheckPaperless,
		},
	}),


	PROFILE_CHANGE_DELIVERY_TYPE_FORM_SUBMIT_REQUEST: changeDeliveryTypeFormSubmitActionCreator.REQUEST,
	PROFILE_CHANGE_DELIVERY_TYPE_FORM_SUBMIT_SUCCESS: changeDeliveryTypeFormSubmitActionCreator.SUCCESS,
	PROFILE_CHANGE_DELIVERY_TYPE_FORM_SUBMIT_FAILURE: changeDeliveryTypeFormSubmitActionCreator.FAILURE,

	changeDeliveryTypeFormSubmitHandler: changeDeliveryTypeFormSubmitActionCreator,
	changeDeliveryTypeFormRequest: (...args) =>
		changeDeliveryTypeFormSubmitActionCreator.request({ ...args }),
	changeDeliveryTypeFormSuccess: payload =>
		changeDeliveryTypeFormSubmitActionCreator.success(payload),
	changeDeliveryTypeFormFailure: error => changeDeliveryTypeFormSubmitActionCreator.failure(error),

	getCountries: createApiActions('PROFILE/GET_COUNTRIES'),
	getUsaStates: createApiActions('PROFILE/GET_USA_STATES'),

	SHOW_CHANGE_TAX_FORM_MODAL: 'PROFILE/SHOW_CHANGE_TAX_FORM_MODAL',

	showChangeTaxFormModal: FormType => ({
		type: actions.SHOW_CHANGE_TAX_FORM_MODAL,
		payload: {
			FormType,
		},
	}),

	CHANGE_TAXFORM_UPLOAD_FORM_SUBMIT_REQUEST: changeTaxFormUploadFormActionCreator.REQUEST,
	CHANGE_TAXFORM_UPLOAD_FORM_SUBMIT_SUCCESS: changeTaxFormUploadFormActionCreator.SUCCESS,
	CHANGE_TAXFORM_UPLOAD_FORM_SUBMIT_FAILURE: changeTaxFormUploadFormActionCreator.FAILURE,

	changeTaxFormUploadFormSubmitHandler: changeTaxFormUploadFormActionCreator,
	changeTaxFormUploadFormRequest: (...args) =>
		changeTaxFormUploadFormActionCreator.request({ ...args }),
	changeTaxFormUploadFormSuccess: payload =>
		changeTaxFormUploadFormActionCreator.success(payload),
	changeTaxFormUploadFormFailure: error => changeTaxFormUploadFormActionCreator.failure(error),

	CHANGE_TAXFORM_FILLIN_FORM_SUBMIT_REQUEST: changeTaxFormFillInFormActionCreator.REQUEST,
	CHANGE_TAXFORM_FILLIN_FORM_SUBMIT_SUCCESS: changeTaxFormFillInFormActionCreator.SUCCESS,
	CHANGE_TAXFORM_FILLIN_FORM_SUBMIT_FAILURE: changeTaxFormFillInFormActionCreator.FAILURE,

	changeTaxFormFillInFormSubmitHandler: changeTaxFormFillInFormActionCreator,
	changeTaxFormFillInFormRequest: (...args) =>
		changeTaxFormFillInFormActionCreator.request({ ...args }),
	changeTaxFormFillInFormSuccess: payload =>
		changeTaxFormFillInFormActionCreator.success(payload),
	changeTaxFormFillInFormFailure: error => changeTaxFormFillInFormActionCreator.failure(error),

	HIDE_PROFILE_MODAL: 'PROFILE/HIDE_PROFILE_MODAL',
	hideProfileModal: modalId => ({
		type: actions.HIDE_PROFILE_MODAL,
		payload: modalId,
	}),

	changeTotpAuthentication: createFormAction('PROFILE/ENABLE_TOTP_AUTH'),
	enableTwoFactorAuthEmail: createFormAction('PROFILE/ENABLE_TWO_FACTOR_AUTH_EMAIL'),

	SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL: 'PROFILE/SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL',

	showDemoAccountExpirationModal: () => ({
		type: actions.SHOW_DEMO_ACCOUNT_EXPIRATION_MODAL,
	}),

	UPDATE_MARKET_DATA_SUBSCRIPTION: 'PROFILE/UPDATE_MARKET_DATA_SUBSCRIPTION',
	updateMarketDataSubscription: subscription => ({
		type: actions.UPDATE_MARKET_DATA_SUBSCRIPTION,
		payload: subscription,
	}),
};

export default actions;
