import actions from './actions';
import authActions from '../auth/actions';
import userAccountsActions from '../userAccounts/actions';

const initialState = {
	currentOffering: {
		submitting: false,
		value: undefined,
	},
	ipoCreatingOrder: {},
	ipoOrderExtendedInfo: {},
	offerings: {
		submitting: true,
		pagination: {},
		items: [],
	},
	orders: {
		submitting: true,
		pagination: {},
		items: [],
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.SHOW_IPO_CREATE_ORDER_MODAL:
		return {
			...state,
			ipoCreatingOrder: payload,
		};
	case actions.SHOW_IPO_EXTENDED_INFO_MOBILE_MODAL:
		return {
			...state,
			ipoOrderExtendedInfo: payload,
		};

	case actions.getOfferings.REQUEST:
		return {
			...state,
			offerings: (payload.page === 1) ? {
				...initialState.offerings,
			} : {
				...state.offerings,
				submitting: true,
			},
		};
	case actions.getOfferings.SUCCESS:
		return {
			...state,
			offerings: {
				submitting: false,
				pagination: {
					...payload.request,
				},
				items: [
					...state.offerings.items,
					...payload.response.Items,
				],
				succeed: true,
			},
		};
	case actions.getOfferings.FAILURE:
		return {
			...state,
			offerings: {
				...state.offerings,
				submitting: false,
				succeed: false,
			},
		};

	case actions.getOffering.REQUEST:
		return {
			...state,
			currentOffering: {
				submitting: true,
				value: undefined,
			},
		};
	case actions.getOffering.SUCCESS:
		return {
			...state,
			currentOffering: {
				submitting: false,
				value: payload.response.Offering,
				succeed: true,
			},
		};
	case actions.getOffering.FAILURE:
		return {
			...state,
			currentOffering: {
				submitting: false,
				value: undefined,
				succeed: false,
			},
		};
	case actions.getOrders.REQUEST:
		return {
			...state,
			orders: (payload.page === 1) ? {
				...initialState.orders,
			} : {
				...state.orders,
				submitting: true,
			},
		};
	case actions.getOrders.SUCCESS:
		return {
			...state,
			orders: {
				submitting: false,
				pagination: {
					...payload.request,
				},
				items: [
					...state.orders.items,
					...payload.response.Items,
				],
				succeed: true,
			},
		};
	case actions.getOrders.FAILURE:
		return {
			...state,
			orders: {
				...state.orders,
				submitting: false,
				succeed: false,
			},
		};

	case userAccountsActions.SET_CURRENT_ACCOUNT:
		return {
			...initialState,
		};

	case authActions.LOGIN_USER:
		return {
			...initialState,
		};
	case authActions.LOGOUT_SUCCESS:
		return { ...initialState };

	default:
		return state;
	}
};
