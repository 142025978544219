const maxCharsNumber = 254;
const maxDependents = 20;
const constants = {
	regExpAmount: /^\d+(\.\d{1,2})?$/,
	regExpRoutingNumber: /^(\d{9})$/,
	regExpBankAccountNumber: /^[0-9a-zA-Z]+$/,
	regExpEmail: /^([\w-+.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
	regExpAllTags: /(<([^>]+)>)/ig,
	regExpMt5Password: /^(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[0-9].*)[A-Za-z0-9]{8,}$/,
	regExpPassword: /(?=^.{6,}$)(?=^[a-zA-Z0-9!\"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
	warningForNonTransferableSecuritiesHolders: 'Please Note: Accounts holding non-transferable and/or worthless securities will be required to maintain a minimum cash balance of $250.00 in the account (The balance is to offset the fees incurred for holding these types of securities, minimum amount required may be greater than $250.00 and will depend on the number of positions held).',

	maxCharsNumber,
	maxCharsNumberError: `The allowed number of characters is ${maxCharsNumber}`,
	maxDependents,

	regExpEmploymentCompanyName: /^[A-Za-z0-9\.,\-\+"\s]+$/,
	regExpEmploymentStreetAddress: /^[A-Za-z0-9\.,\-\+"\/\s]+$/,
	regExpEmploymentHouse: /^[A-Za-z0-9\.,-\/\s]+$/,
	regExpEmploymentCity: /^[A-Za-z0-9\.,\-\s]+$/,
	regExpEmploymentZip: /^[0-9a-zA-Z]+$/,
	regExpEmploymentForbidden: /(--)+/,

	EtnaGateConfirmCode: 'EtnaGateConfirmCode',

	LIME_FIN: 'Lime Trading',
	LIME_INT: 'Lime International',
	LIME_NSR: 'Lending Robot',
	LIME_LME: 'Lime LTD',
};

export default constants;
