import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { A } from '@components';


const styles = theme => ({
	documentContainer: {
		marginTop: '20px',
		overflowX: 'auto',
		'&:last-child div': {
			borderBottom: 'none',
		},
	},
	documentItem: {
		...theme.typography.style.body,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		padding: '0 14px',
		borderBottom: '1px solid #deeaef',
		'& div': {
			padding: '10px',
		},
	},
	documentText: {
		color: 'inherit',
		flexGrow: 2,
	},
	table: {
		...theme.table.default,
		'& th': {
			...theme.table.default['& th'],
			paddingRight: '20px',
		},
	},
});


class TransmissionItems extends React.Component {
	render() {
		const {
			classes,
			account: {
				tradeCode,
				clearerFirm,
			},
			items,
		} = this.props;

		return (
			<div className={classes.documentContainer}>
				<table className={classes.table}>
					<thead>
						<tr>
							<th style={{ textAlign: 'left' }}>
								Date
							</th>
							<th style={{ textAlign: 'left' }}>
								Issuer
							</th>
							<th style={{ textAlign: 'left' }}>
								CUSIP
							</th>
							<th style={{ textAlign: 'left' }}>
								Ticker
							</th>
							<th style={{ textAlign: 'right', paddingRight: '10px' }}>
								Shares
							</th>
							<th style={{ textAlign: 'left' }}>
								Documents
							</th>
						</tr>
					</thead>
					<tbody>
						{items && items.map((item, index) => (
							<tr key={index}>
								<td style={{ textAlign: 'left' }}>{item.FulfillmentDate}</td>
								<td style={{ textAlign: 'left' }}>{item.SecurityName}</td>
								<td style={{ textAlign: 'left' }}>{item.CUSIP}</td>
								<td style={{ textAlign: 'left' }}>{item.Ticker}</td>
								<td style={{ textAlign: 'right' }}>{item.Shares}</td>
								<td style={{ textAlign: 'left' }}>
									{item.Documents && item.Documents.map((doc, indx) => (
										<A key={`transdoc_${indx}`} target="_blank" href={doc.Url}>
											{doc.Label}
										</A>
									))}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

TransmissionItems.propTypes = {
	account: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
};

export default compose(
	withTheme,
	withStyles(styles),
)(TransmissionItems);
