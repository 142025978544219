import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { SvgIcon } from '@components';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = theme => ({
	root: {
	},
	headContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '4px 0',
	},
	headContent: {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
		width: '100%',
		padding: '10px 0',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		'& > span': {
			marginRight: '40px',
			whiteSpace: 'nowrap',
		},
	},
	headToggleIcon: {
		marginRight: '-15px',
	},
	arrowIcon: {
		stroke: theme.typography.color.primary,
	},
	content: {
		...theme.typography.style.body,
		transition: 'all 0.5s',
		marginTop: '12px',
	},
	collapsedBodyContent: {
		transition: 'all 0.5s',
		marginTop: '12px',
	},
	opened: {
		// height: '100%',
		display: 'block',
	},
	closed: {
		// height: '0px',
		display: 'none',
	},
});

class ExpansionPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: props.defaultOpen,
		};
	}

	toggleOpen = () => {
		const {
			isOpen,
			onToggleOpen,
		} = this.props;

		const status = (isOpen === null) ? !this.state.open : !isOpen;
		if (onToggleOpen) {
			onToggleOpen(status, this.props.id);
		}
		this.setState({
			open: status,
		});
	}

	render() {
		const {
			classes,
			onToggleOpen, // WHY!!!!????
			isOpen,
			children,
			header,
			headContentClass,
			defaultOpen,
			collapsedBodyContent,
			qaAttributes,
			...props
		} = this.props;

		const opened = (isOpen === null) ? this.state.open : isOpen;
		const Icon = opened ?
			<SvgIcon className={classes.arrowIcon} icon="ArrowUp" />
			: <SvgIcon className={classes.arrowIcon} icon="ArrowDown" />;
		const contentCssClass = classNames({
			[classes.content]: true,
			[classes.opened]: opened,
			[classes.closed]: !opened,
		});
		const headContentCssClass = classNames({
			[classes.headContent]: true,
			[classes.headContentUpdate]: classes.headContentUpdate !== undefined,
		});

		return (
			<div className={classes.root} {...props}>
				<div className={classes.headContainer}>
					<div className={headContentCssClass} {...qaAttributes.title}>
						{header}
					</div>
					<div className={classes.headToggleIcon}>
						<IconButton onClick={this.toggleOpen} {...qaAttributes.icon}>
							{Icon}
						</IconButton>
					</div>
				</div>
				{!opened && !!collapsedBodyContent &&
					<div className={classes.collapsedBodyContent}>
						{collapsedBodyContent}
					</div>
				}
				{opened &&
					<div className={contentCssClass}>
						{children}
					</div>
				}
			</div>
		);
	}
}

ExpansionPanel.propTypes = {
	children: PropTypes.any,
	classes: PropTypes.object.isRequired,
	collapsedBodyContent: PropTypes.any,
	defaultOpen: PropTypes.bool,
	header: PropTypes.any.isRequired,
	id: PropTypes.string.isRequired,
	onToggleOpen: PropTypes.func,
	isOpen: PropTypes.bool,
	qaAttributes: PropTypes.shape({
		title: PropTypes.object,
		icon: PropTypes.object,
	}),
};

ExpansionPanel.defaultProps = {
	onToggleOpen: null,
	defaultOpen: true,
	isOpen: null,
	qaAttributes: {},
};

export default compose(withStyles(styles))(ExpansionPanel);
