import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, SuccessMessage, ErrorMessage, ProgressContainer, R } from '@components';
import { makeStyles } from '@material-ui/core/styles';
import { nontradingOrders, ordersCy, userAccounts } from '@redux';
import {
	CYOpenDemoAccountChoosePlatform,
	CYOpenDemoAccountChooseType,
	CYOpenDemoAccountChooseLeverage,
	CYOpenDemoAccountChooseBalance,
} from './components';

const useStyles = makeStyles(theme => ({
	modalContainer: {
		maxWidth: '800px',
	},
}));

const CY_NON_TRADING_OPEN_DEMO_ACCOUNT_FORM_ID = 'CYNonTradingOpenDemoAccountModal';
const CYNonTradingOpenDemoAccountModal = ({ onClose }) => {
	const [step, setStep] = useState('chooseplatform');
	const [error, setError] = useState('');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);
	const classes = useStyles();

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);

	return (
		<Modal
			title={<R id="common_title_add_demo_accounts" defaultValue="Open additional demo accounts" />}
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
			classes={{ container: classes.modalContainer }}
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={onClose}
							text={<R id="common_open_demo_account_completed" defaultValue="Open demo account completed" />}
						/>
					}
					{step === 'failed' &&
						<ErrorMessage
							onClick={onClose}
							text={error || <R id="common_open_demo_account_failed" defaultValue="Could not complete new demo account" />}
						/>
					}
					{step === 'chooseplatform' &&
						<CYOpenDemoAccountChoosePlatform
							destroyOnUnmount={false}
							forceUnregisterOnUnmount
							form={CY_NON_TRADING_OPEN_DEMO_ACCOUNT_FORM_ID}
							enableReinitialize
							onSubmit={handleGotoPage('choosetype')}
							onSuccess={() => setStep('choosetype')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
					{step === 'choosetype' &&
						<CYOpenDemoAccountChooseType
							destroyOnUnmount={false}
							// forceUnregisterOnUnmount
							form={CY_NON_TRADING_OPEN_DEMO_ACCOUNT_FORM_ID}
							// enableReinitialize
							onSubmit={handleGotoPage('chooseleverage')}
							onSuccess={() => setStep('chooseleverage')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
					{step === 'chooseleverage' &&
						<CYOpenDemoAccountChooseLeverage
							destroyOnUnmount={false}
							// forceUnregisterOnUnmount
							form={CY_NON_TRADING_OPEN_DEMO_ACCOUNT_FORM_ID}
							// enableReinitialize
							onSubmit={handleGotoPage('choosebalance')}
							onSuccess={() => setStep('choosebalance')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
					{step === 'choosebalance' &&
						<CYOpenDemoAccountChooseBalance
							destroyOnUnmount={false}
                        	// enableReinitialize
							// forceUnregisterOnUnmount
							form={CY_NON_TRADING_OPEN_DEMO_ACCOUNT_FORM_ID}
							onSubmit={nontradingOrders.actions.sendNonTradingOpenDemoAccount}
							onSuccess={() => setStep('succeeded')}
							onSubmitFail={() => setStep('failed')}
							onSubmitSuccess={() => setStep('succeeded')}
						/>
				    }
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default CYNonTradingOpenDemoAccountModal;
