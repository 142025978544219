import { fork, put, takeEvery } from 'redux-saga/effects';
import {
	callApi,
	snackbar,
} from '@redux';
import { getEarningsToday, /* getGainersAndLosers, */ getMostActive } from '@api';
import { captureException } from '@global';
import actions from './actions';
/* 
function* getGainersAndLosersRequestSaga({ payload: { page, pageSize, processingLimit } }) {
	try {
		const response = yield callApi(getGainersAndLosers, page, pageSize);
		if (!response.data.Success) {
			if (response.data.Errors.length > 0 && response.data.Errors[0].Code === 'Processing') {
				if (processingLimit > 0) {
					yield put(actions.getGainersAndLosersRequest(page, pageSize, processingLimit - 1));
				}
				return;
			}
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get Gainers And Losers',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.getGainersAndLosersFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.getGainersAndLosersSuccess({ page, pageSize }, response.data.Items));
		}
	} catch (error) {
		captureException('getGainersAndLosersRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get Gainers And Losers', text: error.message }));
		yield put(actions.getGainersAndLosersFailure(error.message));
		console.error(error);
	}
}
 */

function* getEarningsTodayRequestSaga({ payload: { processingLimit } }) {
	try {
		const response = yield callApi(getEarningsToday);
		if (!response.data.Success) {
			if (response.data.Errors.length > 0 && response.data.Errors[0].Code === 'Processing') {
				if (processingLimit > 0) {
					yield put(actions.getEarningsTodayRequest(processingLimit - 1));
				} else {
					yield put(actions.getEarningsTodaySuccess([]));
				}
				return;
			}
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get Earnings Today',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.getEarningsTodayFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.getEarningsTodaySuccess(response.data.Items));
		}
	} catch (error) {
		captureException('getEarningsTodayRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get Earnings Today', text: error.message }));
		yield put(actions.getEarningsTodayFailure(error.message));
		console.error(error);
	}
}


function* getMostActiveRequestSaga({ payload: { processingLimit } }) {
	try {
		const response = yield callApi(getMostActive);
		if (!response.data.Success) {
			if (response.data.Errors.length > 0 && response.data.Errors[0].Code === 'Processing') {
				if (processingLimit > 0) {
					yield put(actions.getMostActiveRequest(processingLimit - 1));
				} else {
					yield put(actions.getMostActiveSuccess([]));
				}
				return;
			}
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get Most Active',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.getMostActiveFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.getMostActiveSuccess(response.data.Items));
		}
	} catch (error) {
		captureException('getMostActiveRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get Most Active', text: error.message }));
		yield put(actions.getMostActiveFailure(error.message));
		console.error(error);
	}
}


// Watchers
/* function* watchGainersAndLosersRequest() {
	yield takeEvery(actions.GET_GAINERS_AND_LOSERS_REQUEST, getGainersAndLosersRequestSaga);
} */
function* watchEarningsTodayRequest() {
	yield takeEvery(actions.GET_EARNINGS_TODAY_REQUEST, getEarningsTodayRequestSaga);
}
function* watchMostActiveRequest() {
	yield takeEvery(actions.GET_MOST_ACTIVE_REQUEST, getMostActiveRequestSaga);
}


// Root saga
const rootSaga = [
	// fork(watchGainersAndLosersRequest),
	fork(watchEarningsTodayRequest),
	fork(watchMostActiveRequest),
];

export default {
	rootSaga,
};

