import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {
	app,
	depositFunds,
	linkedAccounts,
	signature,
	userAccounts,
	withdrawFunds,
} from '@redux';
import { env } from '@config';

import { withStyles } from '@material-ui/core/styles';
import {
	BankAccountItem,
	ConnectBankAccountItem,
} from './components';


const styles = theme => ({
	root: {
		display: 'flex',
	},
	danger: {
		...theme.typography.style.title,
		color: theme.typography.color.red,
	},
	itemContainer: {
		padding: '0 16px',
		margin: '0px -16px',
		'&:hover': {
			backgroundColor: '#e3edf5',
		},
	},
	listItemContainer: {
		padding: '0 16px',
		margin: '0px -16px',
	},
	listConnectItemContainer: {
		padding: '0 16px',
		margin: '3px -16px',
	},
	item: {
		borderBottom: '1px solid #deeaef',
		padding: '10px 0',
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		showDepositFundsModal: (bankAccount, tradeAccount) =>
			dispatch(depositFunds.actions.showDepositFundsModal(bankAccount, tradeAccount)),
		showDepositFundsBrokerModal: (bankAccount, tradeAccount) =>
		 	dispatch(depositFunds.actions.showDepositFundsBrokerModal(bankAccount, tradeAccount)),
		showDepositFundsIraModal: () => dispatch(depositFunds.actions.showDepositFundsIraModal()),
		showDepositFundsWireModal: bankAccount =>
		 	dispatch(depositFunds.actions.showDepositFundsWireModal(bankAccount)),
		showWithdrawFundsAchModal: (fromUserAccount, toBankAccount) =>
		 	dispatch(withdrawFunds.actions.showWithdrawFundsAchModal(fromUserAccount, toBankAccount)),
		showWithdrawFundsWireModal: (fromUserAccount, toBankAccount) => {
		 	const wireWithdrawalAction =
		 		withdrawFunds.actions.showWithdrawFundsWireModal(fromUserAccount, toBankAccount);
		 	dispatch(signature.actions.collectSignaturesProcessStart(wireWithdrawalAction));
		},
		showWithdrawFundsBrokerModal: (fromUserAccount, toBankAccount) =>
		 	dispatch(withdrawFunds.actions.showWithdrawFundsBrokerModal(fromUserAccount, toBankAccount)),
		showWithdrawFundsIraModal: () => dispatch(withdrawFunds.actions.showWithdrawFundsIraModal()),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
		achUnlinkBankAccount: payload => dispatch(linkedAccounts.actions.achUnlinkBankAccount.request(payload)),
		wireUnlinkBankAccount: payload => dispatch(linkedAccounts.actions.wireUnlinkBankAccount.request(payload)),
	},
});

const mapStateToProps = (state) => {
	const accountInfo = userAccounts.selectors.accountInfo(state);
	return {
		linkedAccounts: userAccounts.selectors.linkedAccounts(state),
		accountStatus: userAccounts.selectors.currentAccountStatus(state),
		currentAccountData: userAccounts.selectors.currentAccountData(state),
		currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
		userInfo: userAccounts.selectors.userInfo(state),
		accountInfo,
	};
};

class CashManagement extends React.Component {

	showDepositFundsModal = (bankAccount) => {
		const { actions, currentTradeAccount } = this.props;
		switch (bankAccount.Type) {
			case 'ACH':
				actions.showDepositFundsModal(
					bankAccount,
					currentTradeAccount,
				);
				break;
			case 'WIRE':
				actions.showDepositFundsWireModal(bankAccount);
				break;
			case 'BROKER':
				actions.showDepositFundsBrokerModal(
					bankAccount,
					currentTradeAccount,
				);
				break;
			case 'IRA':
				actions.showDepositFundsIraModal();
				break;
			default: break;
		}
	}

	showWithdrawFundsModal = (bankAccount) => {
		const { actions, currentTradeAccount } = this.props;
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};
		switch (bankAccount.Type) {
		case 'ACH':
			actions.showWithdrawFundsAchModal(userAccount, bankAccount);
			break;
		case 'WIRE':
			actions.showWithdrawFundsWireModal(userAccount, bankAccount);
			break;
		case 'BROKER':
			actions.showWithdrawFundsBrokerModal(userAccount, bankAccount);
			break;
		case 'IRA':
			actions.showWithdrawFundsIraModal();
			break;
		default: break;
		}
	}

	unlinkAccount = (bankAccount) => {
		const { actions, currentTradeAccount } = this.props;
		const text = `Please confirm if you want to remove link with ${bankAccount.BankName}`;
		if (bankAccount.Type === 'ACH') {
			actions.showConfirmModal({
				text,
				action: onEndRequest => actions.achUnlinkBankAccount({
					tradeCode: currentTradeAccount.tradeCode,
					bankCode: bankAccount.Code,
					onEndRequest,
				}),
			});
		} else if (bankAccount.Type === 'WIRE') {
			actions.showConfirmModal({
				text,
				action: onEndRequest => actions.wireUnlinkBankAccount({
					...currentTradeAccount,
					bankCode: bankAccount.Code,
					onEndRequest,
				}),
			});
		}
	}

	hasDepositButton = (bankAccount) => {
		const { accountStatus } = this.props;
		return accountStatus.CanFund && (
			(bankAccount.Type === 'ACH' && bankAccount.Code) ||
			(bankAccount.Type === 'WIRE') ||
			(bankAccount.Type === 'BROKER') ||
			(bankAccount.Type === 'IRA')
		);
	}

	hasWithdrawalButton = (bankAccount) => {
		const { accountStatus } = this.props;
		return (accountStatus.CanWithdrawal && (
			(bankAccount.Type === 'ACH' && bankAccount.Code) ||
			(bankAccount.Type === 'WIRE' && (accountStatus.CanWireLink || bankAccount.Code)) ||
			(bankAccount.Type === 'BROKER')
		)) || (bankAccount.Type === 'IRA');
	}


	render() {
		const {
			classes,
			accountStatus,
			accountInfo,
			linkedAccounts,
			currentAccountData: {
				baseParams,
			},
			userInfo,
		} = this.props;
		const userGuid = userInfo.Guid && userInfo.Guid.toUpperCase();
		const isEnableWallet = userGuid && env.WEBCAB_5004_walletEnableUsers.includes(userGuid);

		if (baseParams && baseParams.IsCashAccount) {
			return '';
		}
		const allLinkAccounts = [
			...linkedAccounts,
			...['WIRE', 'ACH']
				.filter(t =>
					!linkedAccounts.some(a => a.Type === t) // нет имеющихся линков этого типа
					&& (
						(t === 'ACH' && accountStatus.CanAchLink) || // и ACH только если можно делать соответствующего типа
						(t === 'WIRE' && accountStatus.CanWireLink)
					),
				)
				.map(t => ({ Type: t })),
		];
		if (isEnableWallet) {
			const walletAccount = {
				Type: 'BROKER', Code: 'broker', Number: '458408', BankName: 'J2T',
			};
			walletAccount.displayNumber = walletAccount.Number.slice(-4);
			allLinkAccounts.push(walletAccount);
		}
		if (accountInfo.IsIra) {
			allLinkAccounts.push({ Type: 'IRA' });
		}

		return (
			<React.Fragment>
				{accountStatus && accountStatus.IsClosed &&
					<h3 className={classes.danger}>
						The account is closed. No money transfer orders can be submitted.
					</h3>
				}
				{accountStatus && accountStatus.IsDisabled &&
					<h3 className={classes.danger}>
						The account is currently Disabled. No withdrawal transfer orders can be submitted.
					</h3>
				}
				{accountStatus && !accountStatus.IsDisabled && !accountStatus.IsClosed &&
					<div>
						{allLinkAccounts.map((bankAccount, index) => (
							<div className={classes.listItemContainer} key={index}>
								<div className={classes.item}>
									<BankAccountItem
										bankAccount={bankAccount}
										onDepositFundsModal={this.showDepositFundsModal}
										onWithdrawFundsModal={this.showWithdrawFundsModal}
										onUnlinkAccount={this.unlinkAccount}
										hasDepositButton={this.hasDepositButton}
										hasWithdrawalButton={this.hasWithdrawalButton}
									/>
								</div>
							</div>
						))}
						{/* {(accountStatus.CanFund || accountStatus.CanWithdrawal) &&
							<div className={classes.listConnectItemContainer}>
								<ConnectBankAccountItem />
							</div>
						} */}
					</div>
				}
			</React.Fragment>
		);
	}
}

CashManagement.propTypes = {
	classes: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
	accountStatus: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CashManagement);
