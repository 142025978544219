import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userAccounts, withdrawFunds } from '@redux';
import { WireWithdrawalShortForm } from '@shared';
import { WireManuallyTabComponent } from '../../../AddBankAccountModal/components';

const styles = {};

const mapStateToProps = (state) => {
	const resProps = {
		toBankAccount: withdrawFunds.selectors.toBankAccount(state),
		canShow: true,
		loading: false,
	};
	if (resProps.toBankAccount) {
		return resProps;
	}
	// default if not set
	const loading = userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading;
	const linkedAccounts = userAccounts.selectors.linkedAccounts(state);
	const accountStatus = userAccounts.selectors.currentAccountStatus(state);
	const toBankAccount = (loading ? null : linkedAccounts.find(x => x.Type === 'WIRE')) || { Type: 'WIRE' };
	return {
		toBankAccount,
		canShow: !!(accountStatus && accountStatus.CanWithdrawal && (toBankAccount.Code || accountStatus.CanWireLink)),
		loading,
	};
};
const WIRE_LINK_AND_WITHDRAWAL_FORM = 'wireLinkAndWithdrawalForm';
class WithdrawFundsWireTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 'default',
		};
	}

	componentDidMount() {
		this.checkFirstPage();
	}

	componentDidUpdate() {
		this.checkFirstPage();
	}

	checkFirstPage = () => {
		const { step } = this.state;
		const { loading, canShow } = this.props;
		if (step === 'default' && !loading && canShow) {
			const isLinked = !!this.props.toBankAccount.Template;
			this.setState({ step: isLinked ? 'amountform' : 'linkform' });
		}
	}

	handleGotoAmount = () => {
		this.setState({ step: 'amountform' });
	}

	createSubmitHandleAction = (values, dispatch, props) => {
		const {
			currentAccountData: {
				baseParams: {
					CashToWithdrawValue: cashToWithdraw,
				},
			},
		} = props;

		if (parseFloat(values.wireWithdrawal.Amount) === cashToWithdraw) {
			this.isAccountClosing = true;
		}

		const isLinked = !!this.props.toBankAccount.Template;
		return isLinked ?
			withdrawFunds.actions.withdrawFundsWireForm(values, dispatch) :
			withdrawFunds.actions.linkAndWithdrawFundsWireForm(values, dispatch);
	};

	render() {
		const {
			toBankAccount,
			closeAccountFlow,
			onSuccess,
		} = this.props;

		const { step } = this.state;
		return (
			<div>
				{step === 'amountform' &&
					<WireWithdrawalShortForm
						enableReinitialize
						forceUnregisterOnUnmount
						/* keepDirtyOnReinitialize */
						form={WIRE_LINK_AND_WITHDRAWAL_FORM}
						toBankAccount={toBankAccount}
						onSubmit={this.createSubmitHandleAction}
						closeAccountFlow={closeAccountFlow}
						onSuccess={onSuccess}
					/>
				}
				{step === 'linkform' &&
					<WireManuallyTabComponent
						destroyOnUnmount={false}
						forceUnregisterOnUnmount
						form={WIRE_LINK_AND_WITHDRAWAL_FORM}
						enableReinitialize
						onSubmit={this.handleGotoAmount}
					/>
				}
			</div>
		);
	}
}

WithdrawFundsWireTab.propTypes = {
	toBankAccount: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canShow: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(WithdrawFundsWireTab);
