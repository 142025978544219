import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { constants, env } from '@config';
import { userAccounts, app } from '@redux';
import { Link } from 'react-router-dom';
import { A, R, SvgIcon } from '@components';
import { ModalPopups } from '@enums';
import { getAccountProductName } from '@utils';
import { usePaymentGateWidgetHook, useMoneyFormat } from '@hooks';
import { Mt4Img, Mt5Img } from '@content/mtLogos';


const useStyles = makeStyles(theme => ({
	rootTop: {
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		backgroundColor: '#ffffff',
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		paddingBottom: '15px',
		margin: '0 -25px',
	},
	rootMidl: {
		backgroundColor: '#DCDCDC',
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		padding: '15px 14px',
		margin: '0 -25px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	rootBottom: {
		borderBottomLeftRadius: '10px',
		borderBottomRightRadius: '10px',
		backgroundColor: '#ffffff',
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		padding: '15px 14px',
		margin: '0 -25px',
		fontSize: '12px',
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',

		},
		padding: '15px 14px',
	},
	leftSection: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '10px',

		},
	},
	middleSection: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: '315px',
			marginBottom: '10px',
			alignItems: 'center',
		},
	},

	middleSectionRow: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			width: '315px',
			marginBottom: '10px',
			alignItems: 'center',
		},
	},
	descriptionBlod: {
		fontWeight: 'Bold',
		[theme.breakpoints.down('sm')]: {
			width: '50%',
		},
	},
	descriptionBlodBlueColor: {
		fontWeight: 'Bold',
		color: '#1a96ff',
		[theme.breakpoints.down('sm')]: {
			width: '50%',
		},

	},
	descriptionBlodBlueColorTradeLogin: {
		fontWeight: 'Bold',
		color: '#1a96ff',
		[theme.breakpoints.down('sm')]: {
			width: '50%',
			fontSize: '18px',
		},

	},
	descriptionBlodBlueColorRow: {
		fontWeight: 'Bold',
		color: '#1a96ff',
	},
	wrapper: {
		display: 'flex',
		paddingBottom: '5px',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between',
			alignItems: 'start',
			width: '315px',
		},
	},
	stat: {
		marginRight: '10px',
		minWidth: '123px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
	buttons: {
		fontSize: '10px',
		'&:hover': {
			backgroundColor: '#1a96ff',
			color: '#fff',
		},
		[theme.breakpoints.down('sm')]: {
			width: '160px',
			padding: '5px 0px 5px 0px',
		},
	},
	wrapperRow: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '5px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingBottom: '10px',
		},
	},

	statRow: {
		marginRight: '5px',
		width: '90px',
		[theme.breakpoints.down('sm')]: {
			marginRight: '5px',
			width: '48px',
		},
	},
	description: {
		[theme.breakpoints.down('sm')]: {
			// width: '51%',
		},
	},
	Mt4Img: {
		width: '50px',
		heigth: '50px',
		borderRadius: '5px',
		boxShadow: '0px 3px 3px 1px #DCDCDC',
	},

	platformFullName: {
		padding: '0px 5px 0px 5px',
		fontSize: '0.875rem',
		backgroundColor: '#1a96ff',
		color: '#fff',
		borderRadius: '5px',
		boxShadow: '0px 3px 3px 1px #DCDCDC',
	},
	btEdit: {
		margin: '-14px -12px -12px -8px',
	},
	btEditSmallIcon: {
		fontSize: '1rem',
	},
}));

const useActions = () => {
	const dispatch = useDispatch();
	const showLeverageDialog = () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_LEVERAGE));
	return {
		showLeverageDialog,
	};
};
export const AccountBriefingPanelLME = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const moneyFormat = useMoneyFormat();

	const currentTradeAccount = useSelector(state => userAccounts.selectors.currentTradeAccount(state));
	const currentAccountData = useSelector(state => userAccounts.selectors.currentAccountData(state));
	const accounts = useSelector(state => state.userAccounts.accounts);
	const resolution = useSelector(app.selectors.resolution);
	const currentUserAccountsInfo = useSelector(userAccounts.selectors.accountInfo);
	const TradeLogin = currentUserAccountsInfo && currentUserAccountsInfo.TradeLogin;
	const tradeCode = currentUserAccountsInfo && currentUserAccountsInfo.TradeCode;
	const LeverageText = currentUserAccountsInfo && currentUserAccountsInfo.LeverageText;
	const serverName = currentUserAccountsInfo && currentUserAccountsInfo.ServerName;
	const loanValue = currentAccountData.baseParams && currentAccountData.baseParams.LoanValue;
	const freeMargin = currentAccountData.baseParams && currentAccountData.baseParams.FreeMargin;
	const balance = currentAccountData.baseParams && currentAccountData.baseParams.Balance;
	const Equity = currentAccountData.baseParams && currentAccountData.baseParams.EquityValue;
	const margin = currentAccountData.baseParams && currentAccountData.baseParams.Margin;
	const accountStatusISarchive = currentUserAccountsInfo && currentUserAccountsInfo.Status.IsArchive;
	const accountStatusIsClosed = currentUserAccountsInfo && currentUserAccountsInfo.Status.IsClosed;
	const accountStatusIsDisabled = currentUserAccountsInfo && currentUserAccountsInfo.Status.IsDisabled;
	const currentAccount = accounts.find(x => tradeCode === x.TradeCode);
	const terminalMt4Link = currentAccount && currentAccount.IsForex ? `${env.baseUrl}/static/mt4web.html${(TradeLogin && `?login=${TradeLogin}`) || ''}` : '';
	const terminalMt5Link = `${env.baseUrl}/static/webterminal.html${(TradeLogin && `?login=${TradeLogin}`) || ''}`;
	const currencyCode = currentUserAccountsInfo.MoneyInfo && currentUserAccountsInfo.MoneyInfo.CurrencyCode;
	const productName = currentAccount && getAccountProductName(currentAccount);
	const accountInfo = useSelector(state => userAccounts.selectors.accountInfo(state));

	const [paymentGateWidgetBtnId] = usePaymentGateWidgetHook();

	const showWithdrawFundsModal = (bankAccount) => {
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};
		dispatch(app.actions.showBaseModal(ModalPopups.CY_WITHDRAWAL_CRYPTOWALLET, { userAccount, bankAccount }));
	};
	const actions = useActions();

	return (
		<React.Fragment>
			<div className={classes.rootTop}>
				<div className={classes.container}>
					<div className={classes.middleSection}>
						<div className={classes.wrapper}>
							<div >
								{currentAccount && currentAccount.IsForex ?
									<img className={classes.Mt4Img} src={Mt4Img} alt="" />
									:
									<img className={classes.Mt4Img} src={Mt5Img} alt="" />
								}
							</div>
						</div>
						{resolution === app.CONFIG.RESOLUTIONS_ENUM.xs &&
							<div className={classes.wrapper}>
								<div className={classes.descriptionBlodBlueColor} style={{ fontSize: '18px' }} >
									{moneyFormat(balance, currencyCode)}
								</div>
							</div>
						}
					</div>
					<div className={classes.middleSection}>
						<div className={classes.wrapper}>
							<div className={classes.descriptionBlodBlueColorTradeLogin} style={{ paddingRight: '10px' }}>
								{TradeLogin}
							</div>
						</div>
						<div className={classes.wrapper}>
							<div className={classes.description}>
								<div className={classes.platformFullName} color="primary" variant="contained">{productName}</div>
							</div>
						</div>
					</div>
					<div className={classes.middleSectionRow}>
						<div className={classes.wrapperRow}>
							<div className={classes.statRow}>
								<R id="common_account_status" defaultValue="Status" />
							</div>
							<div className={classes.descriptionBlodBlueColorRow}>
								{accountStatusISarchive && 'Archive'}
								{accountStatusIsClosed && 'Closed'}
								{accountStatusIsDisabled && 'Disable'}
								{!accountStatusIsDisabled && !accountStatusIsClosed && !accountStatusISarchive && 'Active'}
							</div>
						</div>
						<div className={classes.wrapperRow}>

							<div className={classes.statRow}>
								<R id="common_useraccounts_trade_server" defaultValue="Server" />
							</div>
							<div className={classes.descriptionBlodBlueColorRow}>
								{serverName}
							</div>
						</div>
					</div>
					<div className={classes.leftSection}>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="common_account_currency" defaultValue="Account currency" />
							</div>
							<div className={classes.descriptionBlodBlueColor}>
								{currencyCode || '-'}
							</div>
						</div>
						<div className={classes.wrapper}>

							<div className={classes.stat}>
								<R id="common_account_leverage" defaultValue="Leverage" />
							</div>
							<div className={classes.descriptionBlodBlueColor}>
								{LeverageText || '-'}
								<IconButton onClick={actions.showLeverageDialog} classes={{ root: classes.btEdit }}>
									<SvgIcon icon="Edit" color="primary" fontSize="small" classes={{ fontSizeSmall: classes.btEditSmallIcon }} />
								</IconButton>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className={classes.rootMidl}>

				<div className={classes.container}>
					<div className={classes.leftSection}>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="shared_balance" defaultValue="Balance" />
							</div>
							<div className={classes.descriptionBlod}>
								{moneyFormat(balance, currencyCode)}
							</div>
						</div>
						<div className={classes.wrapper}>
							<div className={classes.stat} >
								<R id="account_profile_margin" defaultValue="Margin" />
							</div>
							<div className={classes.descriptionBlod}>
								{moneyFormat(margin, currencyCode)}
							</div>
						</div>
					</div>
					<div className={classes.leftSection}>
						<div className={classes.wrapper}>
							<div className={classes.stat} >
								<R id="common_loan" defaultValue="Loan" />
							</div>
							<div className={classes.descriptionBlod}>
								{loanValue && loanValue.length ? moneyFormat(loanValue[0].Amount, loanValue[0].CurrencyCode) : '-'}
							</div>
						</div>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="shared_freemargin" defaultValue="Free Margin" />
							</div>
							<div className={classes.descriptionBlod}>
								{moneyFormat(freeMargin, currencyCode)}
							</div>
						</div>
					</div>
					<div className={classes.leftSection}>
						<div className={classes.wrapper}>
							<div className={classes.stat}>
								<R id="shared_account_equity" defaultValue="Equity" />
							</div>
							<div className={classes.descriptionBlod}>
								{moneyFormat(Equity, currencyCode)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={classes.rootBottom}>

				<div className={classes.container}>
					{currentAccount && !currentAccount.IsDemo &&
					<div className={classes.leftSection}>
						<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
							{[constants.LIME_INT, constants.LIME_LME].some(x => x === env.firm) && accountInfo.FundingInfo && accountInfo.FundingInfo.WidgetJsScriptUrl &&
								<div id={paymentGateWidgetBtnId} style={{ paddingRight: '10px' }}>
									<Button className={classes.buttons} color="primary" variant="outlined">
										<R id="common_fund" defaultValue="Fund" />
									</Button>
								</div>
							}
							<div>
								<Link style={{ textDecoration: 'none' }} to="/dashboard/cashmanagement" >
									<Button className={classes.buttons} color="primary" variant="outlined">
										<R id="shared_withdraw" defaultValue="Withdraw" />
									</Button>
								</Link>
							</div>
						</div>
					</div>}
					<div className={classes.leftSection}>
						<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
							<div style={{ paddingRight: '10px' }}>
								<Link style={{ textDecoration: 'none' }} to="/dashboard/tradingplatforms" >
									<Button className={classes.buttons} color="primary" variant="outlined">
										<R id="common_menu_trading_platforms" defaultValue="Trading Platforms" />
									</Button>
								</Link>
							</div>
							<div>
								<A href={currentAccount && currentAccount.IsForex ? terminalMt4Link : terminalMt5Link} target="_blank">
									<Button className={classes.buttons} color="primary" variant="outlined">
										<R id="common_trade" defaultValue="Trade" />
									</Button>
								</A>

							</div>
						</div>
					</div>
					{currentAccount && !currentAccount.IsDemo &&
					<div className={classes.leftSection}>
						<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
							<div style={{ paddingRight: '10px' }} >
								<Link style={{ textDecoration: 'none' }} to="/dashboard/reports" >
									<Button className={classes.buttons} color="primary" variant="outlined">
										<R id="common_menu_reports" defaultValue="Reports" />
									</Button>
								</Link>
							</div>
						</div>
					</div>
					}
				</div>
			</div>
		</React.Fragment>
	);
};
