/* eslint-disable no-underscore-dangle,no-plusplus,prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { push } from 'connected-react-router';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { constants, env } from '@config';
import { FormInput, Button, A } from '@components';
import { globalState } from '@global';
import { auth, loginForm } from '@redux';
import { PasswordInput } from './components';

const styles = theme => ({
	input: {
		marginTop: '24px',
	},
	inputStyle: {
		'& input': {
			'&:-webkit-autofill': {
				transition: 'background-color 0.1s ease-in-out 0s',
				'-webkit-box-shadow': '0 0 0 1000px #269BFF inset',
			},
		},
	},
	forgotPassword: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		textAlign: 'right',
		marginTop: '8px',
	},
	formError: {
		...theme.typography.style.body,
		color: theme.typography.color.white,
		backgroundColor: theme.typography.color.red,
		borderRadius: '4px',
		padding: '6px 18px',
		textAlign: 'left',
		marginTop: '15px',

	},
	formErrorShake: {
		'-webkit-animation': 'shake .5s linear',
	},
	'@keyframes shake': {
		'8%, 41%': {
			'-webkit-transform': 'translateX(-10px)',
		},
		'25%, 58%': {
			'-webkit-transform': 'translateX(10px)',
		},
		'75%': {
			'-webkit-transform': 'translateX(-5px)',
		},
		'92%': {
			'-webkit-transform': 'translateX(5px)',
		},
		'0%, 100%': {
			'-webkit-transform': 'translateX(0)',
		},
	},
	submitButton: {
		marginTop: '24px',
	},
	newAccount: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		textAlign: 'center',
		marginTop: '40px',
	},

	authWidget: {
		'& input': {
		// 	padding: '12px 24px 5px',
			backgroundColor: 'rgba(255,255,255,0.05)',
		// 	borderRadius: '8px',
			color: theme.typography.color.primary,
		// 	'& input::placeholder': {
		// 		opacity: 0.75,
		// 	},
		// 	fontSize: theme.typography.pxToRem(18),
			lineHeight: '28px',
			height: '60px',
		},
		'& input:hover': {
			backgroundColor: 'rgba(255,255,255,0.05)',
			color: theme.typography.color.primary,
		},
		'& div.withLabel > p:not(.error)': {
			color: theme.typography.color.primary,
			// color: theme.typography.color.white,
		},
		'& label': {
			...theme.typography.style.title,
			color: theme.typography.color.primary,
			textAlign: 'left',
		},
		// additionalText: {
		// 	padding: '0px 24px',
		// 	color: theme.typography.color.secondary,
		// 	...theme.typography.style.body,
		// },
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
	},
});

const mapStateToProps = state => ({
	authWidget: auth.selectors.authWidget(state),
});

class LoginForm extends React.Component {
	static validate(values) {
		const errors = {};
		if (!values.username) {
			errors.username = 'required';
		}
		if (!values.password) {
			errors.password = 'required';
		}

		return errors;
	}

	constructor(props) {
		super(props);
		// this.formRef = React.createRef();
		this.state = { autoFillsCount: 0 };
		this.isWidgetMounted = false;
	}

	componentDidMount() {
		// if (this.formRef) {
		// 	const inputs = this.formRef.current.getElementsByTagName('input');
		// 	for (let i = 0; i < inputs.length; i++) {
		// 		// https://github.com/facebook/react/issues/1159 chrome autofill redux-forms bug
		// 		inputs[i].addEventListener('transitionend', this.autoFillCompleted, false);
		// 	}
		// }

		this.displayForm();
	}

	componentDidUpdate() {
		this.displayForm();
	}

	componentWillUnmount() {
		if (this.props.authWidget && this.isWidgetMounted) {
			this.props.authWidget.unmount();
			this.isWidgetMounted = false;
		}
		// if (globalState.authWidget && this.isWidgetMounted) {
		// 	globalState.authWidget.unmount();
		// }
	}

	displayForm = () => {
		if (this.props.authWidget && !this.isWidgetMounted) {
			const elm = document.getElementById('auth-widget');
			this.props.authWidget.mount(elm);
			this.isWidgetMounted = true;
		}
		// if (globalState.authWidget && !this.isWidgetMounted) {
		// 	const elm = document.getElementById('auth-widget');
		// 	globalState.authWidget.mount(elm);
		// 	this.isWidgetMounted = true;
		// }
	}

	autoFillCompleted = () => {
		this.setState({ autoFillsCount: this.state.autoFillsCount + 1 });
	}

	render() {
		const {
			actions,
			handleSubmit,
			...props
		} = this.props;

		let buttonDisabled = true;
		if (this.state.autoFillsCount === 2) {
			buttonDisabled = false;
		} else {
			buttonDisabled = props.pristine || props.invalid;
		}

		let formErrorShake = null;
		if (props.error) {
			formErrorShake = props.classes.formErrorShake;
		}

		return (
			<React.Fragment>
				<div className={props.classes.authWidget}>
					<div id="auth-widget" />
				</div>
				{(env.firm === constants.LIME_FIN) &&
					<React.Fragment>
						<div className={props.classes.forgotPassword}>
							<A target="_blank" href={`${env.OAuthServiceUrl}/password/reset`}>Forgot password?</A>
						</div>
						<div className={props.classes.newAccount}>
							Open a new <A target="_blank" href={env.anketaSiteUrl}>Individual</A> or a <A target="_blank" href={env.anketaSiteOldUrl}>different type</A> of an account
						</div>
					</React.Fragment>
				}
				{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm) &&
					<React.Fragment>
						<div className={props.classes.forgotPassword}>
							<A href="#" onClick={() => actions.setCurrentPage('/reset')}>Forgot password?</A>
						</div>
						<div className={props.classes.newAccount}>
							<A target="_blank" href={env.anketaSiteUrl}>Open New Account</A>
						</div>
					</React.Fragment>
				}
			</React.Fragment>

			// <form
			// 	onSubmit={handleSubmit(loginForm.actions.loginFormSubmitHandler)}
			// 	ref={this.formRef}
			// 	autoComplete="off"
			// 	className={formErrorShake}
			// >
			// 	<div className={props.classes.input}>
			// 		<Field
			// 			component={FormInput}
			// 			name="username"
			// 			autoComplete="off"
			// 			placeholder="Login"
			// 			className={props.classes.inputStyle}
			// 			showLabel={false}
			// 		/>
			// 	</div>
			// 	<div className={props.classes.input} >
			// 		<Field
			// 			component={PasswordInput}
			// 			name="password"
			// 			placeholder="Password"
			// 			autoComplete="off"
			// 			className={props.classes.inputStyle}
			// 			showLabel={false}
			// 		/>
			// 	</div>
			// 	<div className={props.classes.forgotPassword}>
			// 		<A target="_blank" href={`${env.OAuthServiceUrl}/password/reset`} color="white">Forgot password?</A>
			// 	</div>
			// 	{props.error &&
			// 		<div className={props.classes.formError}>
			// 			{props.error.map(error => <div>{error}</div>)}
			// 		</div>
			// 	}
			// 	<div className={props.classes.submitButton}>
			// 		<Button
			// 			fullWidth
			// 			type="submit"
			// 			variant="contained"
			// 			color="secondary"
			// 			disabled={buttonDisabled}
			// 		>
			// 			{props.submitting ?
			// 				<CircularProgress
			// 					style={{ color: '#1a96ff' }}
			// 					size={18}
			// 				/>
			// 				: 'Sign In'
			// 			}
			// 		</Button>
			// 	</div>
			// 	<div className={props.classes.newAccount}>
			// 		<A target="_blank" href={env.anketaSiteUrl} color="white">Open New Account</A>
			// 	</div>
			// </form>
		);
	}
}

LoginForm.propTypes = {
	authWidget: PropTypes.object,
	handleSubmit: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
	authWidget: null,
};

export default compose(
	reduxForm({
		form: 'loginForm',
		validate: LoginForm.validate,
	}),
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
)(LoginForm);
