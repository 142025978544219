import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '../Input/Input';
import VisibilityButton from './components/VisibilityButton';


const styles = {
	inputType: {
		height: 'auto',
	},
};

const WarningToolTip = withStyles(theme => ({
	arrow: {
		color: theme.palette.warning.main,
	},
	tooltip: {
		color: theme.palette.warning.contrastText,
		backgroundColor: theme.palette.warning.main,
	},
	tooltipPlacementBottom: { margin: '0px' },
}))(Tooltip);

class InputPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isPasswordVisible: false,
			capsLockWarning: false,
		};
		this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
	}

	togglePasswordVisibility() {
		this.setState({
			isPasswordVisible: !this.state.isPasswordVisible,
		});
	}

	keyDownHandler = (keyEvent) => {
		const { onKeyDown } = this.props;
		if (keyEvent.getModifierState('CapsLock')) {
			this.setState({ capsLockWarning: true });
		} else {
			this.setState({ capsLockWarning: false });
		}
		if (onKeyDown) {
			onKeyDown(keyEvent);
		}
	};

	blurHandler = (keyEvent) => {
		const { onBlur } = this.props;
		this.setState({ capsLockWarning: false });
		if (onBlur) {
			onBlur(keyEvent);
		}
	};

	render() {
		const { isPasswordVisible } = this.state;
		const {
			classes,
			inputProps,
			onKeyDown,
			onBlur,
			...otherprops
		} = this.props;
		return (
			<WarningToolTip
				open={this.state.capsLockWarning}
				title="CapsLock is on!"
				placement="bottom-start"
				arrow
			>
				<Input
					type={isPasswordVisible ? 'text' : 'password'}
					inputProps={{
						...inputProps,
						className: classes.inputType,
					}}
					endAdornment={
						<VisibilityButton
							isVisible={isPasswordVisible}
							onClick={this.togglePasswordVisibility}
						/>
					}
					onKeyDown={this.keyDownHandler}
					onBlur={this.blurHandler}
					{...otherprops}
				/>
			</WarningToolTip>
		);
	}
}

InputPassword.propTypes = {
	classes: PropTypes.object.isRequired,
	inputProps: PropTypes.object,
};
InputPassword.defaultProps = {
	inputProps: {},
};

export default withStyles(styles)(InputPassword);
