import { Input } from '@components';
import { createComponent, mapError } from '../utils';

// OBSOLETE: FormInput should be replaced by FormBaseInput 
// because in this component validation is triggered only after losing focus
// but it should be after entering a character
export default createComponent(
	Input,
	({
		defaultValue,
		...props
	}) => ({
		...mapError(props),
	}),
);
