import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { profile, userAccounts } from '@redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
	Button,
	ErrorMessage,
	ProgressContainer,
	SuccessMessage,
} from '@components';
import { InvestmentExperienceItem } from './components';
import { RequireSignatureModal } from '../../..';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		paddingTop: '12px',
	},
	divider: {
		marginTop: '30px',
	},
	inputSelectItem: {
		marginTop: '10px',
	},
});

const GetExperienceValues = (experiences) => {
	let stocks = experiences.find(val => val.Type === 'Stocks');
	stocks = stocks ? stocks.Value : '';
	let option = experiences.find(val => val.Type === 'Option');
	option = option ? option.Value : '';
	let margin = experiences.find(val => val.Type === 'Margin');
	margin = margin ? margin.Value : '';
	let mutualFunds = experiences.find(val => val.Type === 'Mutual Funds');
	mutualFunds = mutualFunds ? mutualFunds.Value : '';
	let shortSale = experiences.find(val => val.Type === 'Short Sale');
	shortSale = shortSale ? shortSale.Value : '';

	return {
		stocks,
		option,
		margin,
		mutualFunds,
		shortSale,
	};
};

const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	const experiences = GetExperienceValues(userProfile.Experiences);
	const initialValues = (!userProfile.submitting && !userProfile.error) ? {
		...experiences,
	} : {};
	return {
		experiences: userProfile.Experiences,
		initialValues,
		profileSubmitting: userProfile.submitting,
		pendingOrder: userProfile.PendingOrders.InvestmentExperience,
	};
};


class ChangeInvestmentExperienceModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.stocks) {
			errors.stocks = 'required';
		}
		if (!values.margin) {
			errors.margin = 'required';
		}
		if (!values.option) {
			errors.option = 'required';
		}
		if (!values.mutualFunds) {
			errors.mutualFunds = 'required';
		}
		if (!values.shortSale) {
			errors.shortSale = 'required';
		}

		return errors;
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			pristine,
			profileSubmitting,
			pendingOrder,
			submitting,
			submitSucceeded,
			experiences: Experiences,
		} = this.props;

		const experiences = GetExperienceValues(Experiences);

		return (
			<RequireSignatureModal
				title="Change Investment Experience"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_INVESTMENT_EXPERIENCE}`}
			>
				{submitSucceeded && <SuccessMessage onClick={onClose} text="Your request to change investment experience is being processed." />}
				{!submitSucceeded && !profileSubmitting && pendingOrder &&
					<ErrorMessage
						onClick={onClose}
						text="Another request for investment experience change is in progress. Please try again later."
					/>
				}
				{!submitSucceeded && !pendingOrder &&
					<ProgressContainer submitting={profileSubmitting}>
						<form
							onSubmit={handleSubmit(profile.actions.changeProfileInvestmentExperienceFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.inputSelectItem}>
								<InvestmentExperienceItem
									label="New stocks experience"
									name="stocks"
									value={experiences.stocks}
								/>
								<div className={classes.divider} />
								<InvestmentExperienceItem
									label="New option trading experience"
									name="option"
									value={experiences.option}
								/>
								<div className={classes.divider} />
								<InvestmentExperienceItem
									label="New margin trading experience"
									name="margin"
									value={experiences.margin}
								/>
								<div className={classes.divider} />
								<InvestmentExperienceItem
									label="New mutual funds trading experience"
									name="mutualFunds"
									value={experiences.mutualFunds}
								/>
								<div className={classes.divider} />
								<InvestmentExperienceItem
									label="New short sales trading experience"
									name="shortSale"
									value={experiences.shortSale}
								/>
								<div className={classes.divider} />
							</div>
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={invalid || submitting || pristine}
								>
									{submitting ?
										<CircularProgress
											style={{ color: '#1a96ff' }}
											size={18}
										/>
										: 'Change Investment Experience'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeInvestmentExperienceModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: 'ChangeProfileInvestmentExperienceForm',
		validate: ChangeInvestmentExperienceModal.validate,
		enableReinitialize: true,
	}),
)(ChangeInvestmentExperienceModal);
