import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MUISvgIcon from '@material-ui/core/SvgIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Icons from './Icons';

const iconNames = Object.keys(Icons);

const styles = {
	root: {
	},
};

const SvgIcon = ({ icon, submitting, ...props }) => {
	if (submitting) {
		return (<CircularProgress
			style={{ color: '#1a96ff' }}
			size={24}
		/>);
	}

	return (
		<MUISvgIcon {...props}>
			{Icons[icon]}
		</MUISvgIcon>);
};

SvgIcon.propTypes = {

	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object.isRequired,
	/**
	 * The color of the component. It supports those theme colors that make sense for this component.
	 * You can use the `htmlColor` property to apply a color attribute to the SVG element.
	 */
	color: PropTypes.oneOf(['action', 'disabled', 'error', 'inherit', 'primary', 'secondary']),
	/**
	 * Applies a color attribute to the SVG element.
	 */
	htmlColor: PropTypes.string,
	/**
	 * Provides a human-readable title for the element that contains it.
	 * https://www.w3.org/TR/SVG-access/#Equivalent
	 */
	titleAccess: PropTypes.string,
	/**
	 * Allows you to redefine what the coordinates without units mean inside an SVG element.
	 * For example, if the SVG element is 500 (width) by 200 (height),
	 * and you pass viewBox="0 0 50 20",
	 * this means that the coordinates inside the SVG will go from the top left corner (0,0)
	 * to bottom right (50,20) and each unit will be worth 10px.
	 */
	viewBox: PropTypes.string,

	icon: PropTypes.oneOf(iconNames).isRequired,
	submitting: PropTypes.bool,
};

SvgIcon.defaultProps = {
	color: 'inherit',
	htmlColor: '',
	titleAccess: '',
	viewBox: '0 0 24 24',
	submitting: false,
};

export default withStyles(styles)(SvgIcon);
