import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@components';
import compose from 'recompose/compose';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

const styles = theme => ({
	root: {
	},
	containerWrapper: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		position: 'fixed',
		width: '100%',
		height: '100%',
		top: '0',
		left: '0',
		zIndex: 200,
		overflowY: 'scroll',
	},
	containerWrapperMobile: {
		zIndex: 1400,
	},
	container: {
		padding: '0 24px',
		maxWidth: '496px',
		margin: '0 auto',
		position: 'relative',
	},
	backdrop: {
		position: 'fixed',
		width: '100%',
		height: '100%',
		opacity: '0.98',
		top: '0',
		left: '0',
		zIndex: 190,
	},
	backdropMobile: {
		zIndex: 1390,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '20px 0',
	},
	icon: {
		width: '72px',
		textAlign: 'center',
	},
	title: {
		...theme.typography.style.subheading,
		textAlign: 'center',
		fontWeight: 'bold',
		width: '100%',
	},
	body: {
		paddingBottom: '24px',
	},
});

class Modal extends React.Component {
	componentDidMount() {
		document.querySelector('body').style.overflow = 'hidden';
	}
	componentWillUnmount() {
		document.querySelector('body').style.overflow = 'auto';
	}
	render() {
		const {
			classes,
			className,
			bgColor,
			textColor,
			onClose,
			children,
			title,
			isXsResolution
		} = this.props;

		const cssContainer = classNames({
			[classes.containerWrapper]: true,
			[classes.containerWrapperMobile]: isXsResolution,
		});

		const cssBackdrop = classNames({
			[classes.backdrop]: true,
			[classes.backdropMobile]: isXsResolution,
		});

		return (
			<div className={classNames(classes.root, className)}>
				<div className={cssContainer}>
					<div className={classes.container} style={{ color: textColor }}>
						<div className={classes.header}>
							{onClose && // for symmetric
								<div className={classes.icon} />
							}
							<div className={classes.title}>
								{title}
							</div>
							{onClose &&
							<div className={classes.icon}>
								<IconButton onClick={onClose}>
									<SvgIcon icon="Close" htmlColor={textColor} />
								</IconButton>
							</div>
							}
						</div>
						<div className={classes.body}>
							{children}
						</div>
					</div>
				</div>
				<div
					className={cssBackdrop}
					style={{ backgroundColor: bgColor }}
				/>
			</div>
		);
	}
}

Modal.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	bgColor: PropTypes.string,
	textColor: PropTypes.string,
	children: PropTypes.any.isRequired,
	title: PropTypes.any.isRequired,
};

Modal.defaultProps = {
	bgColor: '#fff',
	textColor: '#000',
	className: undefined,
};

export default compose(withStyles(styles))(Modal);
