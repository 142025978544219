import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { dashboard, depositFunds, userAccounts } from '@redux';
import { changeHashInUrl, createQAAttribute, toUsaMoneyFormat } from '@global';
import {
	BankLogo,
	List,
	ListItemText,
	ListItem,
	ListItemIcon,
} from '@components';

const styles = theme => ({
	root: {
		maxHeight: '128px',
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	linkedAccounts: userAccounts.selectors.linkedAccounts(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		showDepositFundsModal: (bankAccount, tradeAccount) =>
			dispatch(depositFunds.actions.showDepositFundsModal(bankAccount, tradeAccount)),
		showDepositFundsWireModal: bankAccount =>
			dispatch(depositFunds.actions.showDepositFundsWireModal(bankAccount)),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
	},
});

class LinkedAccountsList extends React.Component {
	redirectModal = (from, to) => {
		this.props.history.push(changeHashInUrl(this.props.location, from, to));
		this.props.actions.sidebarClose();
	}

	showDepositModal = (bankAccount) => {
		if (bankAccount.Type === 'ACH') {
			this.props.actions.showDepositFundsModal(
				bankAccount,
				this.props.currentTradeAccount,
			);
		} else if (bankAccount.Type === 'WIRE') {
			this.props.actions.showDepositFundsWireModal(bankAccount);
		}
		this.props.actions.sidebarClose();
	}

	gaDepositUrl = (bankAccount) => {
		if (bankAccount.Type === 'ACH') {
			return '/deposit/ach/';
		} else if (bankAccount.Type === 'WIRE') {
			return '/deposit/wire/';
		}
		return null;
	}

	render() {
		const { linkedAccounts } = this.props;
		return (
			<div>
				<List>
					{linkedAccounts.map((account, index) => (
						<ListItem
							button
							key={index}
							gaUrl={this.gaDepositUrl(account)}
							onClick={() => this.showDepositModal(account)}
						>
							<ListItemIcon>
								<BankLogo
									bankAccount={account}
								/>
							</ListItemIcon>
							<ListItemText
								primary={`*${account.displayNumber}`}
								secondary={account.Balance && toUsaMoneyFormat(account.Balance)}
								{...createQAAttribute('linked_bank')}
							/>
						</ListItem>
					))}
					{/* connect new account link
						{(!linkedAccounts || linkedAccounts.length === 0) &&
						<ListItem
							button
							gaUrl="/deposit/addBankAccount/"
							onClick={() => this.redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT)}
						>
							<ListItemIcon>
								<BankLogo bankAccount={{}} />
							</ListItemIcon>
							<ListItemText
								primary={<span>Connect a New<br />Bank Account</span>}
							/>
						</ListItem>
					} */}

				</List>
			</div>
		);
	}
}

LinkedAccountsList.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(LinkedAccountsList);
