import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { app } from '@redux';
import {
	ChangeLink,
	ExpansionPanel,
	R,
} from '@components';
import { ModalPopups } from '@enums';
import { InputEditableItem } from '@shared';


export const CYPersonalDocumentsPanel = (props) => {
	const {
		onToggle,
		panelId,
		isOpen,
		classes,
	} = props;

	const dispatch = useDispatch();

	return (
		<ExpansionPanel
			header={<R id="common_header_personal_documents" defaultValue="Personal Documents" />}
			id={panelId}
			onToggleOpen={onToggle}
			isOpen={isOpen}
			classes={{ root: classes.expansionPanelRoot, content: classes.expansionPanelContent }}
		>
			<InputEditableItem
				title={<R id="common_Personal_identification" defaultValue="Personal Identification" />}
				// value="*************"
				editControl={
					<ChangeLink onClick={() => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_IDENTITY))} />
				}
			/>
			<InputEditableItem
				title={<R id="common_address_confirmation" defaultValue="Address Confirmation" />}
				// value="*************"
				editControl={
					<ChangeLink onClick={() => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_REGISTRATION))} />
				}
			/>
		</ExpansionPanel>
	);
};
