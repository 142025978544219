import {
	put,
	fork,
	takeEvery,
	takeLatest,
	select,
	take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	checkSignatures,
	saveSignature,
} from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl, removeHashFromUrl } from '@global';
import {
	BaseGetSagaHandler,
	callApi,
	userAccounts,
	snackbar,
} from '@redux';
import actions from './actions';
import selectors from './selectors';

function* saveSignatureRequest({ payload: { account, image, guid } }) {
	try {
		const request = yield callApi(saveSignature, account, image, guid);

		if (!request.data.Success) {
			yield put(actions.saveSignatureFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ title: 'Save Signature', text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.saveSignatureSuccess());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Save Signature', text: error.message }));
		yield put(actions.saveSignatureFailure(error));
	}
}

function* collectSignaturesProcessStart({ payload: { successAcion } }) {
	try {
		const currentAccount = yield select(userAccounts.selectors.currentTradeAccount);
		const needToGetSignaturePersonsArray = yield select(selectors.SignesHolders);

		for (const needToGetSignaturePerson of needToGetSignaturePersonsArray) {
			yield put(actions.showCreateSignatureModal(
				currentAccount.tradeCode,
				needToGetSignaturePerson.Guid,
				needToGetSignaturePerson.FullName,
			));
			yield take(actions.SAVE_SIGNATURE_SUCCESS);
			yield put(actions.hideCreateSignatureModal());
		}
		yield put(actions.checkSignatures.request(currentAccount));
		if (successAcion) yield put(successAcion);
	} catch (error) {
		window.Raven.captureException(error);
	}
}
function* reqireSignatureAndReturnHandler() {
	try {
		const location = yield select(state => state.router.location);
		const successAcion = push(location.pathname + location.hash);

		const currentAccount = yield select(userAccounts.selectors.currentTradeAccount);
		const needToGetSignaturePersonsArray = yield select(selectors.SignesHolders);
		let cancelled = false;
		for (const needToGetSignaturePerson of needToGetSignaturePersonsArray) {
			yield put(actions.showCreateSignatureModal(
				currentAccount.tradeCode,
				needToGetSignaturePerson.Guid,
				needToGetSignaturePerson.FullName,
			));
			const userAction = yield take([actions.SAVE_SIGNATURE_SUCCESS, actions.HIDE_CREATE_SIGNATURE_MODAL]);
			if (userAction.type === actions.HIDE_CREATE_SIGNATURE_MODAL) {
				cancelled = true;
				yield put(push(location.pathname));
			} else {
				yield put(actions.hideCreateSignatureModal());
			}
		}
		if (!cancelled) {
			yield put(actions.checkSignatures.request(currentAccount));
			if (successAcion) yield put(successAcion);
		}
	} catch (error) {
		window.Raven.captureException(error);
	}
}
function* showCreateSignatureModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.CREATE_SIGNATURE)));
}

function* hideCreateSignatureModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashFromUrl(location, ModalPopups.CREATE_SIGNATURE)));
}


// Watchers
function* watchSaveSignatureRequest() {
	yield takeEvery(actions.SAVE_SIGNATURE_REQUEST, saveSignatureRequest);
}
function* watchCheckSignaturesRequest() {
	yield takeEvery(actions.checkSignatures.REQUEST, BaseGetSagaHandler, {
		apiMethod: checkSignatures,
		errorText: 'Check Signatures',
		handler: actions.checkSignatures,
	});
}
function* watchCollectSignaturesProcessStart() {
	yield takeLatest(actions.COLLECT_SIGNATURES_PROCESS_START, collectSignaturesProcessStart);
}
function* watchShowCreateSignatureModal() {
	yield takeEvery(actions.SHOW_CREATE_SIGNATURE_MODAL, showCreateSignatureModal);
}
function* watchHideCreateSignatureModal() {
	yield takeEvery(actions.HIDE_CREATE_SIGNATURE_MODAL, hideCreateSignatureModal);
}

function* watchReqireSignatureAndReturn() {
	yield takeEvery(actions.REQUIRE_SIGNATURE_AND_RETURN, reqireSignatureAndReturnHandler);
}

// Root saga
const rootSaga = [
	fork(watchSaveSignatureRequest),
	fork(watchCheckSignaturesRequest),
	fork(watchCollectSignaturesProcessStart),
	fork(watchShowCreateSignatureModal),
	fork(watchHideCreateSignatureModal),
	fork(watchReqireSignatureAndReturn),
];

export default {
	rootSaga,
};
