import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import { Field } from 'redux-form';
import { Button } from '@components';
import DigitsOnlyField from './DigitsOnlyField';


const styles = theme => ({
	title: {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
	},
	input: {
		margin: '5px 0px',
		'& > div > input': {
			background: theme.palette.color.primary.attestationBackground,
			border: '1px solid #adaaaa',
			borderRadius: '5px',
			padding: '5px',
			textAlign: 'center',
		},
		'& > div > input:focus-visible': {
			outline: 'none',
		},
	},
	mainText: {
		...theme.typography.style.body,
		textAlign: 'center',
		padding: '24px 0px 0',
	},
	navButton: {
		margin: '10px 10px',
		padding: '5px',
		width: '120px',
	},
});


class AuthCodeForm extends React.Component {
	static validate(values, props) {
		const {
			securityCode,
		} = props;

		const errors = {};
		if (!securityCode) {
			errors.SecurityCode = 'enter security code';
		}
		if (securityCode && securityCode.length < 6) {
			errors.SecurityCode = 'code must be 6 symbols';
		}

		return errors;
	}

	// constructor(props) {
	// 	super(props);
	// 	this.inputCodeRef = React.createRef();
	// }

	// componentDidMount() {
	// 	this.inputCodeRef.current.focus();
	// }

	render() {
		const {
			classes,
			invalid,
			pristine,
			submitting,
		} = this.props;

		return (
			<Fragment>
				<div className={classes.title}>Enter the code from the code-generating app</div>
				<div className={classes.mainText}>6-digit code</div>
				<div className={classes.input}>
					<Field
						component={DigitsOnlyField}
						name="SecurityCode"
						placeholder=""
						parse={value => value.replaceAll(' ', '')}
					/>
				</div>
				<Button
					className={classes.navButton}
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					disabled={invalid || pristine || submitting}
				>
					{submitting ?
						<CircularProgress
							style={{ color: '#1a96ff' }}
							size={18}
						/>
						: 'Confirm'
					}
				</Button>
			</Fragment>
		);
	}
}

AuthCodeForm.propTypes = {
	classes: PropTypes.object.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	pristine: PropTypes.bool.isRequired,
};

export default compose(withStyles(styles))(AuthCodeForm);
