import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';

const styles = theme => ({
	fullWidth: {
		width: '100%',
	},
	selectItem: {
		padding: '12px 0px 5px 24px',
		background: theme.palette.color.primary.attestationBackground,
		borderRadius: '8px',
	},
	selectMenu: {
		width: '100%',
	},
	bulletPoints: {
		margin: '0px 0px 10px 0px',
		paddingLeft: '24px',
	},
});

class SelectPoints extends React.Component {
	render() {
		const {
			name,
			classes,
			label,
			meta: {
				error,
			},
			input: {
				onChange,
				value,
			},
			renderValue,
			children,
			bulletPoints,
			input,
			displayError,
		} = this.props;

		const invalid = !!(error);

		return (
			<FormControl className={classes.fullWidth}>
				<InputLabel
					htmlFor={name}
					className={classes.label}
				>
					{label}
				</InputLabel>
				<Select
					{...input}
					disableUnderline
					className={classes.selectItem}
					value={value}
					onChange={onChange}
					displayEmpty
					error={!!error}
					renderValue={renderValue}
					classes={{
						selectMenu: classes.selectMenu,
					}}
				>
					{children}
				</Select>
				{!invalid &&
					<div className={classes.bulletPoints}>
						{bulletPoints(value)}
					</div>
				}
				{displayError && invalid && <FormHelperText error>{error}</FormHelperText>}
			</FormControl>
		);
	}
}

SelectPoints.propTypes = {
	classes: PropTypes.object.isRequired,
	name: PropTypes.string,
	label: PropTypes.string,
	input: PropTypes.shape({
		value: PropTypes.any.isRequired,
		onChange: PropTypes.func.isRequired,
	}).isRequired,
	renderValue: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	meta: PropTypes.shape({
		error: PropTypes.string,
	}).isRequired,
	bulletPoints: PropTypes.func.isRequired,
	displayError: PropTypes.bool,
};

SelectPoints.defaultProps = {
	label: '',
	name: '',
	displayError: false,
};

export default compose(withStyles(styles))(SelectPoints);
