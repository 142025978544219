import { providerWithAuth } from '@api';

module.exports = {
	cancelOrder: ({ tradeCode, orderCode }) =>
		providerWithAuth().get(`/useraccounts/api_cancelcashactivity/${tradeCode}/${orderCode}/`),

	getAccountCharts: account => providerWithAuth().get(`/useraccounts/api_accountchart/${account.clearerFirm}/${account.tradeCode}/`),

	getAccountInfo: account => providerWithAuth().get(`/useraccounts/api_accountinfo/${account.clearerFirm}/${account.tradeCode}/`),

	getAccountPortfolio: payload => providerWithAuth().get(`/useraccounts/api_getaccountportfolio/${payload.tradeCode}`),

	getTradeSystemCredentials: account => providerWithAuth().get(`/useraccounts/api_gettradesystemcredentials/${account.clearerFirm}/${account.tradeCode}/`),

	getAccounts: () => providerWithAuth().get('/useraccounts/api_accounts'),
	getUserInfo: () => providerWithAuth().get('/useraccounts/api_userInfo'),

	getCorporateActions: ({ tradeCode, startDate, endDate, page, pageSize, type }) =>
		providerWithAuth().get(`/useraccounts/api_corporateactions?tradecode=${tradeCode}&startDate=${startDate}
		&endDate=${endDate}&page=${page || 1}&pageSize=${pageSize || 8}&type=${type || 'any'}`),

	getOldCorporateActions: ({ tradeCode, startDate, endDate, page, pageSize, type }) =>
		providerWithAuth().get(`/useraccounts/api_oldcorporateactions?tradecode=${tradeCode}&startDate=${startDate}
		&endDate=${endDate}&page=${page || 1}&pageSize=${pageSize || 8}&type=${type || 'any'}`),

	// non lme
	getTransactionHistory: payload => providerWithAuth().post('/useraccounts/api_transactionhistory/', payload),
	//lme - webcab-6331
	getMoneyTransactionHistory: payload => providerWithAuth().post('/useraccounts/api_money-transaction-history/', payload),
	getNonTradingOrdersHistory: payload => providerWithAuth().post('/useraccounts/api_non-trading-orders-history', payload),

	refreshReportsAndConfirmations: account =>
		providerWithAuth().get(`/useraccounts/api_refreshReportsAndConfirmations/${account.clearerFirm}/${account.tradeCode}/`),

	upgradeMarginType: payload => providerWithAuth().post('/useraccounts/api_upgradeAccount/', payload),

	upgradeOptionLevel: payload => providerWithAuth().post('/useraccounts/api_upgradeOptionLevel/', payload),
	changeMt5Password: payload => providerWithAuth().post('/useraccounts/api_mt5changepassword/', payload),

	addDemoAccount: () => providerWithAuth().post('useraccounts/api_addDemoAccount'),
	resetDemoAccount: payload => providerWithAuth().post('useraccounts/api_resetDemoAccount', payload),

	generateSecondAccountVisionAgreement:
		payload => providerWithAuth().post('useraccounts/api_generateSecondAccountVisionAgreement', payload),
	openAdditionalAccount:
		payload => providerWithAuth().post('useraccounts/api_openAdditionalAccount', payload),
};
