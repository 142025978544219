import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
	profile,
	signature,
	userAccounts,
} from '@redux';
import compose from 'recompose/compose';
import {
	ChangeLink,
	ProgressContainer,
} from '@components';
import { InputEditableItem } from '@shared';


const styles = theme => ({
	header: {
		...theme.typography.style.headline1,
		...theme.typography.color.primary,
		padding: '8px 0',
	},
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	splitter: {
		height: '30px',
	},
	editable: {
		display: 'flex',
		flexDirection: 'row',
	},
	editItem: {
		display: 'flex',
		padding: '24px 32px 12px 0',
		textAlign: 'right',
	},
	editValue: {
		flexGrow: 2,
	},
});

function valueOrEmpty(val) {
	if (val) return val;
	return '';
}


const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
	accountStatus: userAccounts.selectors.currentAccountStatus(state),
	userInfo: userAccounts.selectors.userInfo(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		showProfile: () => dispatch(profile.actions.getProfileRequest()),
		showUpgradeAccountModal: (tradeAccount, marginParams) => {
			const changeAction = userAccounts.actions.showUpgradeAccountModal({ tradeAccount, marginParams });
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showUpgradeOptionLevelModal: (tradeAccount, marginParams) => {
			const changeAction = userAccounts.actions.showUpgradeOptionLevelModal({ tradeAccount, marginParams });
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
	},
});

class AccountFeatures extends React.Component {
	constructor(props) {
		super(props);
	}

	showUpgradeModal = () => {
		const { actions, currentTradeAccount, currentAccountData } = this.props;
		actions.showUpgradeAccountModal(currentTradeAccount, currentAccountData.baseParams.MarginOptionParameters);
	}

	showUpgradeOptionLevelModal = () => {
		const { actions, currentTradeAccount, currentAccountData } = this.props;
		actions.showUpgradeOptionLevelModal(currentTradeAccount, currentAccountData.baseParams.MarginOptionParameters);
	}

	render() {
		const {
			classes,
			accountInfo,
			accountStatus,
			profile: {
				submitting,
			},
			currentAccountData: {
				baseParams,
				submitting: paramsSubmitting,
			},
		} = this.props;

		const marginType = (baseParams && baseParams.MarginOptionParameters)
			? valueOrEmpty(baseParams.MarginOptionParameters.MarginType) : '';
		const optionLevel = (baseParams && baseParams.MarginOptionParameters)
			? valueOrEmpty(baseParams.MarginOptionParameters.OptionLevel) : '';

		return (
			<div className={classes.content}>
				<div>
					<div className={classes.header}>
						Account Features
					</div>
				</div>
				<ProgressContainer submitting={submitting || paramsSubmitting} display="left" disableCheckChildren>
					<InputEditableItem
						title="Account Type"
						value={marginType}
						editControl={
							accountStatus && accountStatus.CanMarginUpgrade &&
							accountInfo && accountInfo.IsPrimaryHolder &&
							<ChangeLink
								onClick={this.showUpgradeModal}
								havePendingOrder={
									accountInfo && accountInfo.PendingOrders && accountInfo.PendingOrders.UpgradeMarginType
								}
							/>
						}
					/>
					<InputEditableItem
						title="Option Level"
						value={optionLevel}
						editControl={
							accountStatus && accountStatus.CanOptionsUpgrade && accountInfo && accountInfo.IsPrimaryHolder &&
							<ChangeLink
								onClick={this.showUpgradeOptionLevelModal}
								havePendingOrder={
									accountInfo && accountInfo.PendingOrders && accountInfo.PendingOrders.UpgradeOptionLevel
								}
							/>
						}
					/>
					<div className={classes.splitter} />
				</ProgressContainer>
			</div>
		);
	}
}

AccountFeatures.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	accountInfo: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};


export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(AccountFeatures);
