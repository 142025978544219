module.exports = {
    OperationType: {
        ALL: 'ALL',

        INPUT: 'INPUT',
        OUTPUT: 'OUTPUT',
        CREDIT_IN: 'CREDITIN',
        CREDIT_OUT: 'CREDITOUT',

        ASSIGNMENTORDER: 'ASSIGNMENTORDER',
        ADDITIOANLACCOUNT: 'ADDITIONALACCOUNT',
        CLIENTPASSWORDCHANGE: 'CLIENTPASSWORDCHANGE',
        LEVERAGE: 'LEVERAGE',

        CashOperations() {
            return [this.ALL, this.INPUT, this.OUTPUT, this.CREDIT_IN, this.CREDIT_OUT]
        },

        NonTraidingOperations() {
            return [this.ALL, this.ASSIGNMENTORDER, this.CLIENTPASSWORDCHANGE, this.LEVERAGE]
        },

        getTranslateId(ot) {
            switch (ot.toUpperCase()) {
                case this.ALL: return 'operation_type_all';
                case this.INPUT: return 'operation_type_deposit';
                case this.OUTPUT: return 'operation_type_withdrawal'; 
                case this.CREDIT_IN: return 'operation_type_credit_in'; 
                case this.CREDIT_OUT: return 'operation_type_credit_out'; 

                case this.ASSIGNMENTORDER: return 'order_type_assignment_order';
                case this.ADDITIOANLACCOUNT: return 'order_type_additional_account';
                case this.CLIENTPASSWORDCHANGE: return 'order_type_client_password_change'; 
                case this.LEVERAGE: return 'order_type_account_leverage';
                default: return ot;
            }
        }
    }
}