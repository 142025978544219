import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { acatDeposit } from '@redux';
import {
	Button,
	SvgIcon,
} from '@components';
import { CloseAccountFlow } from '@enums';


const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	icon: {
		marginTop: '16px',
	},
	message: {
		...theme.typography.style.subheading,
		marginTop: '16px',
		color: theme.typography.color.primary,
		fontWeight: 'bold',
	},
	question: {
		...theme.typography.style.title,
		marginTop: '16px',
		color: theme.typography.color.primary,
		textAlign: 'center',
		fontWeight: 'bold',
	},
	button: {
		paddingTop: '20px',
	},
}));

const CloseAccountSuggestionPopup = ({ from, onDontCloseAccount }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const handleCloseClick = () => {
		dispatch(acatDeposit.actions.showCloseAccountModal({ from, flow: CloseAccountFlow.CASH_MANAGMENT }));
	};

	const handleDontCloseClick = () => {
		onDontCloseAccount();
	};

	return (
		<Fragment>
			<div className={classes.root}>
				<div className={classes.icon}>
					<SvgIcon icon="Complete" viewBox="0 0 152 152" style={{ width: '152px', height: '152px' }} />
				</div>
				<div className={classes.message}>
					Order Created
				</div>
				<div className={classes.question}>
					We noticed you&apos;re withdrawing all your funds. Are you planning to close your brokerage account?
				</div>
			</div>
			<div className={classes.button}>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					onClick={handleDontCloseClick}
				>
					No, I&apos;m not closing my account
				</Button>
			</div>
			<div className={classes.button}>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="secondary"
					onClick={handleCloseClick}
				>
					Yes, I want to close my account
				</Button>
			</div>

		</Fragment>
	);
};

CloseAccountSuggestionPopup.propTypes = {
	from: PropTypes.string.isRequired,
	onDontCloseAccount: PropTypes.func.isRequired,
};

export default compose(withTheme)(CloseAccountSuggestionPopup);
