import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { SvgIcon } from '@components';
import { ModalPopups } from '@enums';
import { changeHashInUrl } from '@global';


const styles = theme => ({
	root: {
		...theme.typography.style.button,
		color: theme.typography.color.link,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	image: {
		width: '56px',
		height: '56px',
	},
	text: {
		paddingRight: '14px',
	},
});


class ConnectBankAccountItem extends React.Component {
	redirectModal = (from, to) => {
		this.props.history.push(changeHashInUrl(this.props.location, from, to));
	}

	render() {
		const { classes } = this.props;
		return (
			<div
				className={classes.root}
				onClick={() => this.redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT)}
			>
				<SvgIcon viewBox="0 0 56 56" className={classes.image} icon="Bank" />
				<div className={classes.text}>
					Connect a New Bank Account
				</div>
			</div>
		);
	}
}

ConnectBankAccountItem.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
)(ConnectBankAccountItem);
