import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { constants } from '@config';
import { ModalPopups } from '@enums';
import { profile, userAccounts } from '@redux';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	A,
	Button,
	FormInput,
	ProgressContainer,
	SuccessMessage,
} from '@components';
import { RequireSignatureModal } from '../../..';

const styles = (theme) => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	confirmLink: {
		marginTop: '16px',
		paddingLeft: '24px',
	},
	button: {
		paddingTop: '12px',
	},
	inputItem: {
		'& div > div': {
			background: theme.palette.color.primary.attestationBackground,	
		},
	}
});

const formSelector = formValueSelector('ChangeProfileEmailForm');

const mapStateToProps = (state) => {
	const modalParams = profile.selectors.modals(state)[ModalPopups.CHANGE_PROFILE_EMAIL];
	const userProfile = (modalParams && modalParams.profile) || profile.selectors.profile(state);
	const loading = userAccounts.selectors.loading(state);
	const forceConfirmationEmail = !!(modalParams && modalParams.forceConfirmationEmail);
	const prevEmail = userProfile.PersonalInformation.Email;
	return {
		initialValues: {
			clientId: userProfile.ClientId,
			guid: userProfile.Guid,
			Attachments: [],
			Value: forceConfirmationEmail ? prevEmail : '',
		},
		initialEmailValue: prevEmail,
		emailValue: formSelector(state, 'Value'),
		forceConfirmationEmail,
		loading,
	};
};

class ChangeProfileEmailModal extends React.Component {
	static validate(values, props) {
		const errors = {};

		if (!values.Value) {
			errors.Value = 'required';
		} else if (!(constants.regExpEmail.test(values.Value))) {
			errors.Value = 'Invalid Format';
		} else if (values.Value.length > constants.maxCharsNumber) {
			errors.Value = constants.maxCharsNumberError;
		}

		return errors;
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			submitting,
			submitSucceeded,
			loading,
			emailValue,
			initialEmailValue,
			forceConfirmationEmail, // true - if we want confirm prev email
		} = this.props;
		
		const isPristine = forceConfirmationEmail ? false : emailValue === initialEmailValue;

		return (
			<RequireSignatureModal
				title="Change Email"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_PROFILE_EMAIL}`}
			>
				{submitSucceeded && 
					<SuccessMessage
						onClick={onClose}
						text={
							<React.Fragment>
								<p>
									We have sent a email with a confirmation link to your new email.
								</p>
								<p>
									Please check your email and click on link to complete an update.
								</p>
							</React.Fragment>
						}
					/>
				}
				{!submitSucceeded &&
					<ProgressContainer submitting={loading}>
						<form
							onSubmit={handleSubmit(profile.actions.changeProfileEmailFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="Value"
									placeholder="New email"
									label="New email"
									inputProps={{ autoComplete: 'off' }}
								/>
							</div>
							<p>
								You will receive a confirmation email after you submit this form.
								Follow the link in this email to confirm the changes.
							</p>
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={
										invalid || submitting || isPristine 
									}
								>
									{submitting ?
										<CircularProgress
											style={{ color: '#1a96ff' }}
											size={18}
										/>
										: 'Change Email'
									}
								</Button>
							</div>
						</form>
					</ProgressContainer>
				}
			</RequireSignatureModal>
		);
	}
}

ChangeProfileEmailModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	forceConfirmationEmail: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps),
	reduxForm({
		form: 'ChangeProfileEmailForm',
		validate: ChangeProfileEmailModal.validate,
	}),
)(ChangeProfileEmailModal);