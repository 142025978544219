import React from 'react';
import { R } from '@components';
import PropTypes from 'prop-types';
import BaseMessage from './BaseMessage/BaseMessage';


const ErrorMessage = (props) => {
	const btText = props.buttonText === undefined ? <R id="common_popup_error_closebutton" defaultValue="Return" /> : props.buttonText;
	return <BaseMessage type="error" subtext={props.subtext} text={props.text} onClick={props.onClick} buttonText={btText}	/>;
};

ErrorMessage.defaultProps = {
	buttonText: undefined,
	subtext: '',
};

ErrorMessage.propTypes = {
	/**
	 * Button's handler, executed after click.
	 */
	onClick: PropTypes.func.isRequired,
	/**
	 * Text in the center of modal.
	 */
	text: PropTypes.node.isRequired,
	/**
	 * Text description under main text.
	 */
	subtext: PropTypes.node,
	/**
	 * Button's text at the bottom of modal.
	 */
	buttonText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
	]),
};

export default ErrorMessage;
