import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const styles = theme => ({
	root: {
		boxShadow: 'none',
		borderRadius: '8px',
		...theme.typography.style.buttonLime,
		'&:hover': {
			boxShadow: 'none',
			borderRadius: '8px',
		},
		'&:active': {
			boxShadow: 'none',
			borderRadius: '8px',
		},
	},
	containedPrimary: {
		color: theme.palette.lime.primary.textDefault,
		backgroundColor: theme.palette.lime.primary.default,
		'&:hover': {
			backgroundColor: theme.palette.lime.primary.hover,
			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: theme.palette.lime.primary.hover,
			},
		},
		'&:active': {
			backgroundColor: theme.palette.lime.primary.pressed,
		},

	},
	containedSecondary: {
		color: theme.palette.lime.secondary.textDefault, //
		backgroundColor: theme.palette.lime.secondary.default,
		'&:hover': {
			color: theme.palette.lime.secondary.textHover,
			backgroundColor: theme.palette.lime.secondary.hover,
			// Reset on mouse devices
			'@media (hover: none)': {
				color: theme.palette.lime.secondary.textHover,
				backgroundColor: theme.palette.lime.secondary.hover,
			},
		},
		'&:active': {
			color: theme.palette.lime.secondary.textPressed,
			backgroundColor: theme.palette.lime.secondary.pressed,
		},
	},
});

const LimeButton = props => <MUButton {...props} />;

LimeButton.propTypes = {
	/**
	 * mouse click handler
	 */
	onClick: PropTypes.func,
	/**
	 * The content of the button.
	 */
	children: PropTypes.node.isRequired,
	/**
	 * Useful to extend the style applied to components.
	 */
	classes: PropTypes.object.isRequired,
	/**
	 * The color of the component. It supports those theme colors that make sense for this component.
	 */
	color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
	/**
	 * If `true`, the button will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * If `true`, the  keyboard focus ripple will be disabled.
	 * `disableRipple` must also be true.
	 */
	disableFocusRipple: PropTypes.bool,
	/**
	 * If `true`, the ripple effect will be disabled.
	 */
	disableRipple: PropTypes.bool,
	/**
	 * If `true`, the button will take up the full width of its container.
	 */
	fullWidth: PropTypes.bool,
	/**
	 * The size of the button.
	 * `small` is equivalent to the dense button styling.
	 */
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	/**
	 * The type of button.
	 */
	variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
};

LimeButton.defaultProps = {
	color: 'default',
	disabled: false,
	disableRipple: false,
	disableFocusRipple: false,
	fullWidth: false,
	size: 'medium',
	variant: 'outlined',
	onClick: null,
};

export default withStyles(styles)(LimeButton);
