const DATA_QA_ATTR = 'data-qa';

function createQAAttribute(qaAttributeValue) {
	return qaAttributeValue ? { [DATA_QA_ATTR]: qaAttributeValue } : {};
}

function createInputQAAttributes(qaAttributeValue) {
	return qaAttributeValue
		? {
			title: { [DATA_QA_ATTR]: `${qaAttributeValue}_title` },
			value: { [DATA_QA_ATTR]: `${qaAttributeValue}_value` },
		}
		: {};
}

module.exports = { createQAAttribute, createInputQAAttributes };
