import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@components';
import { SOURCE_OF_FUNDING } from '@utils';
import { createInputQAAttributes, valueOrEmpty } from '@global';

const StudentPanel = ({ profile }) => (
	<Fragment>
		<Input
			placeholder="Status"
			showLabel
			value={valueOrEmpty(profile.Status)}
			disabled
			qaAttributes={createInputQAAttributes('employment_status')}
		/>
		<Input
			placeholder="School name"
			showLabel
			value={valueOrEmpty(profile.Company)}
			disabled
			qaAttributes={createInputQAAttributes('employment_school')}
		/>
		<Input
			placeholder="Source of funding"
			showLabel
			value={valueOrEmpty(profile.SourceOfFunding)}
			disabled
			qaAttributes={createInputQAAttributes('employment_source_of_funding')}
		/>
		{profile.SourceOfFunding === SOURCE_OF_FUNDING.OTHER &&
			<Input
				placeholder="Explanation"
				showLabel
				value={valueOrEmpty(profile.ExplanationSourceOfFunding)}
				disabled
				qaAttributes={createInputQAAttributes('employment_explanation')}
			/>
		}
	</Fragment>
);

StudentPanel.propTypes = {
	profile: PropTypes.object.isRequired,
};

export default StudentPanel;
