import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { A } from '@components';
import { toUsaFormat, toUsaBigNumFormat, toUsaDateFormat } from '@global';

const styles = theme => ({
	title: {
		...theme.typography.style.subheading,
	},
	table: {
		width: '100%',
		verticalAlign: 'top',
		borderCollapse: 'collapse',
		'& th': {
			...theme.typography.style.body,
			color: theme.typography.color.secondary,
			padding: '10px',
			verticalAlign: 'top',
			textAlign: 'right',
		},
		'& td': {
			padding: '10px',
			textAlign: 'right',
		},
		'& tbody': {
			verticalAlign: 'top',
		},
	},
	tableRow: {
		borderBottom: '1px solid #deeaef',
		'& td': {
		},
	},
	dateCol: {
		whiteSpace: 'nowrap',
	},
	numCol: {
		minWidth: '85px',
		width: '85px',
	},
});


class MostActiveTable extends React.Component {
	render() {
		const {
			data,
			classes,
		} = this.props;

		if (!data || !Array.isArray(data) || data.length === 0) {
			return (<p className={classes.title}>No Most Active Today</p>);
		}

		return (
			<table className={classes.table}>
				<thead>
					<tr>
						<th style={{ textAlign: 'left' }}>Ticker</th>
						<th>Last</th>
						<th>Market Cap</th>
						<th className={classes.numCol}>P/E Ratio High</th>
						<th className={classes.numCol}>P/E Ratio Low</th>
						<th>Latest EPS</th>
						<th>Latest EPS Date</th>
						<th>EPS Surprise</th>
						<th>Consensus EPS</th>
						<th>ttm EPS</th>
						<th>Dividend Rate</th>
						<th>Dividend Yield</th>
						<th className={classes.numCol}>Return on Equity</th>
						<th className={classes.numCol}>Return on Assets</th>
						<th>EBITDA</th>
						<th>Revenue</th>
						<th>Gross Profit</th>
						<th>Cash</th>
						<th>Debt</th>
						<th>Profit Margin</th>
					</tr>
				</thead>
				<tbody>
					{data && data.map((item, index) => (
						<tr className={classes.tableRow} key={index}>
							<td style={{ textAlign: 'left' }}>
								<A target="_blank" href={`https://stocks-adviser.com/${item.Symbol}/about/`}>{item.Symbol}</A>
							</td>
							<td />
							<td>{toUsaBigNumFormat(item.MarketCapital)}</td>
							<td>{toUsaFormat(item.PeRatioHigh)}</td>
							<td>{toUsaFormat(item.PeRatioLow)}</td>
							<td>{toUsaFormat(item.LatestEps)}</td>
							<td className={classes.dateCol}>{toUsaDateFormat(item.LatestEpsDate)}</td>
							<td>{toUsaFormat(item.EpsSurprisePercent)}</td>
							<td>{toUsaFormat(item.ConsensusEps)}</td>
							<td>{toUsaFormat(item.TtmEps)}</td>
							<td>{toUsaFormat(item.DividendRate)}</td>
							<td>{toUsaFormat(item.DividendYield)}</td>
							<td>{toUsaFormat(item.ReturnOnEquity)}</td>
							<td>{toUsaFormat(item.ReturnOnAssets)}</td>
							<td>{toUsaBigNumFormat(item.Ebitda)}</td>
							<td>{toUsaBigNumFormat(item.Revenue)}</td>
							<td>{toUsaBigNumFormat(item.GrossProfit)}</td>
							<td>{toUsaBigNumFormat(item.Cash)}</td>
							<td>{toUsaBigNumFormat(item.Debt)}</td>
							<td>{toUsaFormat(item.ProfitMargin)}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}
}

MostActiveTable.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.array.isRequired,
};

export default compose(withStyles(styles))(MostActiveTable);
