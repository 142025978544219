import { createApiActions } from '../extensions';

const actions = {
	// actions

	SHOW_MESSAGE_MODAL: 'NOTIFICATIONS/SHOW_MESSAGE_MODAL',
	HIDE_MESSAGE_MODAL: 'NOTIFICATIONS/HIDE_MESSAGE_MODAL',

	showMessageModal: message => ({
		type: actions.SHOW_MESSAGE_MODAL,
		payload: {
			message,
		},
	}),
	hideMessageModal: () => ({
		type: actions.HIDE_MESSAGE_MODAL,
	}),

	GET_NOTIFICATIONS_REQUEST: 'NOTIFICATIONS/GET_NOTIFICATIONS_REQUEST',
	GET_NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS/GET_NOTIFICATIONS_SUCCESS',
	GET_NOTIFICATIONS_FAILURE: 'NOTIFICATIONS/GET_NOTIFICATIONS_FAILURE',

	getNotificationsRequest: (tradeCode, page = 1, pageSize = 10) => ({
		type: actions.GET_NOTIFICATIONS_REQUEST,
		payload: {
			tradeCode,
			page,
			pageSize,
		},
	}),
	getNotificationsSuccess: (pagination, data) => ({
		type: actions.GET_NOTIFICATIONS_SUCCESS,
		payload: {
			pagination,
			data,
		},
	}),
	getNotificationsFailure: error => ({
		type: actions.GET_NOTIFICATIONS_FAILURE,
		payload: {
			error,
		},
	}),

	GET_MORE_NOTIFICATIONS_REQUEST: 'NOTIFICATIONS/GET_MORE_NOTIFICATIONS_REQUEST',
	GET_MORE_NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS/GET_MORE_NOTIFICATIONS_SUCCESS',
	GET_MORE_NOTIFICATIONS_FAILURE: 'NOTIFICATIONS/GET_MORE_NOTIFICATIONS_FAILURE',

	getMoreNotificationsRequest: (tradeCode, page = 1, pageSize = 10) => ({
		type: actions.GET_MORE_NOTIFICATIONS_REQUEST,
		payload: {
			tradeCode,
			page,
			pageSize,
		},
	}),
	getMoreNotificationsSuccess: (pagination, items) => ({
		type: actions.GET_MORE_NOTIFICATIONS_SUCCESS,
		payload: {
			pagination,
			items,
		},
	}),
	getMoreNotificationsFailure: error => ({
		type: actions.GET_MORE_NOTIFICATIONS_FAILURE,
		payload: {
			error,
		},
	}),

	MARK_NEWS_AS_READ: 'NOTIFICATIONS/MARK_NEWS_AS_READ',

	markNewsAsRead: messageId => ({
		type: actions.MARK_NEWS_AS_READ,
		payload: {
			messageId,
		},
	}),

	markNewsAllAsRead: createApiActions('NOTIFICATIONS/MARK_NEWS_ALL_AS_READ'),
};

export default actions;
