import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { env } from '@config';
import { app, linkedAccounts, userAccounts } from '@redux';
import { Button, TabContainer } from '@components';
import { connect } from 'react-redux';
import { AchLinkRemoveComponent } from './components';

const styles = theme => ({
	root: {},
	button: {
		marginTop: '32px',
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	linkedAccounts: userAccounts.selectors.linkedAccounts(state),
	userInfo: userAccounts.selectors.userInfo(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		getPlaidAccounts: (tradeCode, token) =>
			dispatch(linkedAccounts.actions.getPlaidAccountsRequest(tradeCode, token)),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
		unlinkBankAccount: payload => dispatch(linkedAccounts.actions.achUnlinkBankAccount.request(payload)),
	},
});


class AchAutoTabComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
		};
	}

	plaidHandle = () => {
		const self = this;
		const linkHandler = window.Plaid.create({
			env: env.plaidApiEnvironment,
			apiVersion: 'v2',
			clientName: self.props.userInfo.LongName,
			key: env.plaidApiPublicKey,
			product: 'auth',
			onExit(err, metadata) {
				// The user exited the Link flow.
				if (err != null) {
					// The user encountered a Plaid API error prior to exiting.
					window.Raven.captureMessage('Plaid link exit with error', {
						extra: {
							error: err,
							meta: metadata,
						},
					});
				} else {
					// metadata contains information about the institution
					// that the user selected and the most recent API request IDs.
					// Storing this information can be helpful for support.
					window.Raven.captureMessage('Plaid link exit without error', {
						extra: {
							meta: metadata,
						},
					});
				}
				self.setState({ submitting: false });
			},
			onLoad() {
			},
			onSuccess(publicToken, metadata) {
				self.props.actions.getPlaidAccounts(
					self.props.currentTradeAccount.tradeCode,
					publicToken,
				);
			},
		});
		linkHandler.open();
		this.setState({ submitting: true });
	}

	render() {
		const {	classes, currentTradeAccount, linkedAccounts } = this.props;
		const { submitting } = this.state;

		const achBankAccount = linkedAccounts && linkedAccounts.find(it => it.Type === 'ACH');
		if (achBankAccount) {
			return (
				<TabContainer>
					<AchLinkRemoveComponent
						achBankAccount={achBankAccount}
						tradeAccount={currentTradeAccount}
					/>
				</TabContainer>
			);
		}

		return (
			<TabContainer>
				<p>
					You can connect your bank account automatically by signing into
					your online bank account. The process is fast and secured.
					You can also connect your bank account manually by entering your
					bank account credentials.
				</p>
				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						onClick={this.plaidHandle}
						color="primary"
						disabled={submitting}
					>
						{submitting ?
							<CircularProgress
								style={{ color: '#1a96ff' }}
								size={18}
							/>
							: 'Connect bank account automatically'
						}
					</Button>
				</div>
			</TabContainer>
		);
	}
}

AchAutoTabComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
};

export default compose(
	withRouter,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(AchAutoTabComponent);
