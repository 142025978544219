import React from 'react';
import i18next from 'i18next';

let contextData = {
	showDicwordsKeys: false,
	t() { return 'not init'; },
};
const Context = React.createContext(contextData);

const TranslateContextProvider = (props) => {
	const { showDicwordsKeys = false, children } = props;

	if (contextData.showDicwordsKeys !== showDicwordsKeys) {
		// контекст оринтируется равенство по ссылке. Создаем новый объект состояния
		contextData = { showDicwordsKeys };
	}
	contextData.showDicwordsKeys = showDicwordsKeys;

	contextData.t = (key, options) => {
		return (showDicwordsKeys ? `[[${key}]] ` : '') + i18next.t(key, options);
	};
	return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export { TranslateContextProvider, Context as TranslateContext };

