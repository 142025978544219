import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { env } from '@config';
import { isNumber, toUsaSizeFormat, toUsaDateFormat } from '@global';
import { A, FileDownload } from '@components';


const styles = theme => ({
	documentContainer: {
		'&:last-child div': {
			borderBottom: 'none',
		},
	},
	documentItem: {
		...theme.typography.style.body,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		padding: '0 14px',
		borderBottom: '1px solid #deeaef',
		'& div': {
			padding: '10px',
		},
		'&>div:first-child': {
			minWidth: '105px',
		},
	},
	documentText: {
		color: 'inherit',
		flexGrow: 2,
	},
	downloadProgress: {
		position: 'relative',
		top: '30px',
		marginTop: '-16px',
		left: '18px',
	},
	downloadRootLoading: {
		marginLeft: '-18px',
	},
});


class ReportItem extends React.Component {
	render() {
		const {
			classes,
			account: {
				tradeCode,
				clearerFirm,
			},
			document,
			nodeKey,
		} = this.props;

		return (
			<div className={classes.documentContainer} key={nodeKey}>
				<FileDownload
					url={`${env.backendUrl}Account/Report/${clearerFirm}/${tradeCode}/${document.Download}`}
					fileName={document.Name}
					classes={{
						progress: classes.downloadProgress,
						rootLoading: classes.downloadRootLoading,
					}}
					showButtonTitleWhenLoading
					buttonTitle={
						<div className={classes.documentItem}>
							<div>
								{toUsaDateFormat(document.Date)}
							</div>
							{/* <SvgIcon icon="DocPdf" /> */}
							<div className={classes.documentText}>
								{document.Name}
							</div>
							{isNumber(document.Size) &&
								<div>
									{toUsaSizeFormat(document.Size * 1000)}
								</div>
							}
						</div>
					}
				/>
			</div>
		);
	}
}

ReportItem.propTypes = {
	account: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	document: PropTypes.object.isRequired,
	nodeKey: PropTypes.string.isRequired,
};

export default compose(
	withTheme,
	withStyles(styles),
)(ReportItem);
