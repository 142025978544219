import actions from './actions';

const initialState = {

	/* gainersAndLosers: {
		loading: true,
		pagination: {},
		gainers: [],
		losers: [],
	}, */
	earningsToday: {
		loading: true,
		earnings: [],
	},
	mostActive: {
		loading: true,
		items: [],
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	/*
	case actions.GET_GAINERS_AND_LOSERS_REQUEST:
		return {
			...state,
			gainersAndLosers: (payload.page === 1) ? {
				loading: true,
				pagination: {},
				gainers: [],
				losers: [],
			} : {
				...state.gainersAndLosers,
				loading: true,
			},
		};

	case actions.GET_GAINERS_AND_LOSERS_SUCCESS:
		return {
			...state,
			gainersAndLosers: {
				loading: false,
				pagination: {
					...payload.pagination,
				},
				gainers: [
					...state.gainersAndLosers.gainers,
					...payload.items.Gainers,
				],
				losers: [
					...state.gainersAndLosers.losers,
					...payload.items.Losers,
				],
			},
		};

	case actions.GET_GAINERS_AND_LOSERS_FAILURE:
		return {
			...state,
			gainersAndLosers: {
				...state.gainersAndLosers,
				loading: false,
			},
		};
	*/	
	case actions.GET_EARNINGS_TODAY_REQUEST:
		return {
			...state,
			earningsToday: {
				loading: true,
				earnings: [],
			},
		};

	case actions.GET_EARNINGS_TODAY_SUCCESS:
		return {
			...state,
			earningsToday: {
				loading: false,
				earnings: [
					...payload.items,
				],
			},
		};

	case actions.GET_EARNINGS_TODAY_FAILURE:
		return {
			...state,
			earningsToday: {
				loading: false,
				earnings: [],
			},
		};

	case actions.GET_MOST_ACTIVE_REQUEST:
		return {
			...state,
			mostActive: {
				loading: true,
				items: [],
			},
		};

	case actions.GET_MOST_ACTIVE_SUCCESS:
		return {
			...state,
			mostActive: {
				loading: false,
				items: [
					...payload.items,
				],
			},
		};

	case actions.GET_MOST_ACTIVE_FAILURE:
		return {
			...state,
			mostActive: {
				loading: false,
				items: [],
			},
		};

	default:
		return state;
	}
};
