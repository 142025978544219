/* eslint-disable max-len */
import React from 'react';
import {
	withStyles,
	withTheme,
} from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
	app,
	acatDeposit,
	dashboard,
	depositFunds,
	profile,
	signature,
	userAccounts,
} from '@redux';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import compose from 'recompose/compose';
import { constants, env } from '@config';
import { SvgIcon, ListItem, R } from '@components';
import { ModalPopups } from '@enums';
import { addHashToUrl, createQAAttribute, changeHashInUrl, scrollToTop } from '@global';
import PropTypes from 'prop-types';

const styles = theme => ({
	root: {},
	listItemText: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
});

const mapDispatchToProps = dispatch => ({
	actions: {
		showDepositFundsModal: (bankAccount, tradeAccount) =>
			dispatch(depositFunds.actions.showDepositFundsModal(bankAccount, tradeAccount)),
		setCurrentPage: page => dispatch(push(page)),
		openReportsPage: (page, account) => {
			dispatch(userAccounts.actions.openReportsPage({ page, account }));
		},
		showAcatDepositModal: (toUserAccount) => {
			const acatDepositAction = acatDeposit.actions.showAcatDepositModal(toUserAccount);
			dispatch(signature.actions.collectSignaturesProcessStart(acatDepositAction));
		},
		showNonTradingOrderCommon: () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_ORDER_COMMON)),
		showNonTradingOpenAccount: () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_OPEN_ACCOUNT)),
		showNonTradingOpenDemoAccount: () => dispatch(app.actions.showBaseModal(ModalPopups.CY_NON_TRADING_OPEN_DEMO_ACCOUNT)),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
	},
});

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	linkedAccounts: userAccounts.selectors.linkedAccounts(state),
	userInfo: userAccounts.selectors.userInfo(state),
	accounts: userAccounts.selectors.accounts(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	selectedAccount: userAccounts.selectors.selectedAccount(state),
	currentAccountStatus: userAccounts.selectors.currentAccountStatus(state),
	userProfile: profile.selectors.profile(state),
	language: app.selectors.language(state),
});

class AccountActions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openSubMenu: null,
		};
	}
	redirectModal = (from, to) => {
		this.props.history.push(changeHashInUrl(this.props.location, from, to));
	}

	showDepositFundsModal = () => {
		const achBankAccount = this.props.linkedAccounts && this.props.linkedAccounts.find(it => it.Type === 'ACH');
		if (achBankAccount) {
			this.props.actions.showDepositFundsModal(
				achBankAccount,
				this.props.currentTradeAccount,
			);
		} else {
			this.redirectModal(ModalPopups.PLAID_SELECT_ACCOUNT, ModalPopups.ADD_BANK_ACCOUNT);
		}
		this.props.actions.sidebarClose();
	}

	showAcatDepositModal = () => {
		this.props.actions.showAcatDepositModal(this.props.currentTradeAccount);
		this.props.actions.sidebarClose();
	}

	showNonTradingOrderCommonModal = () => {
		this.props.actions.showNonTradingOrderCommon();
		this.props.actions.sidebarClose();
	}
	showNonTradingOpenDemoAccountModal = () => {
		this.props.actions.showNonTradingOpenDemoAccount();
		this.props.actions.sidebarClose();
	}
	showNonTradingOpenAccountModal = () => {
		this.props.actions.showNonTradingOpenAccount();
		this.props.actions.sidebarClose();
	}

	showReportsPage = path => () => {
		const {
			actions,
			currentTradeAccount,
		} = this.props;
		if (currentTradeAccount.clearerFirm === 'cor') {
			actions.openReportsPage(path, currentTradeAccount);
		} else {
			actions.setCurrentPage(path);
		}
		scrollToTop();
		actions.sidebarClose();
	}
	showPage = path => () => {
		this.props.actions.setCurrentPage(path);
		scrollToTop();
		this.props.actions.sidebarClose();
	}
	showDemoAccounts = () => {
		this.showPage('/dashboard');
	}
	showModal = (id) => {
		this.props.history.push(addHashToUrl(this.props.location, id));
	}

	handleOpenSubMenu = (key) => {
		const { openSubMenu } = this.state;
		if (openSubMenu === key) {
			this.setState({ openSubMenu: null });
		} else {
			this.setState({ openSubMenu: key });
		}
	};

	render() {
		const {
			language,
			theme,
			classes,
			currentAccountStatus,
			userInfo,
			currentAccountData: {
				baseParams,
			},
			selectedAccount,
		} = this.props;

		const userGuid = userInfo.Guid && userInfo.Guid.toUpperCase();
		const isEnableThirdPartyTools = userGuid && (!env.WEBCAB_4509_transaqChangePwdAvalilableFor
			|| env.WEBCAB_4509_transaqChangePwdAvalilableFor.includes(userGuid));
		const { openSubMenu } = this.state;

		return (
			<div>
				<List>
					{userInfo.IsPreopened &&
						<ListItem button gaUrl="/welcome/" onClick={() => this.showModal(ModalPopups.WELCOME_PAGE)} className="QA-WelcomePage">
							<ListItemIcon>
								<SvgIcon
									icon="Star"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary="Welcome Page"
								disableTypography
							/>
						</ListItem>
					}
					{(env.firm === constants.LIME_LME) &&
						<React.Fragment>
							<ListItem button onClick={() => this.handleOpenSubMenu('accounts')}>
								<ListItemIcon>
									<SvgIcon
										icon="BriefcaseAccount"
										htmlColor={theme.palette.color.primary.black}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="shared__accounts" defaultValue="Accounts" />}
									disableTypography
								/>
								{openSubMenu === 'accounts' ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={openSubMenu === 'accounts'} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									{/* <ListItem button onClick={this.showPage('/dashboard')}>
										<ListItemText
											className={classes.nested}
											primary={<R id="common_menu_live_overview" defaultValue="Live accounts" />}
											disableTypography
										/>
									</ListItem> */}
									<ListItem button onClick={this.showNonTradingOpenAccountModal} {...createQAAttribute('overview')}>
										<ListItemText
											className={classes.nested}
											primary={<R id="common_menu_add_live_account" defaultValue="Open additional live account" />}
											disableTypography
										/>
									</ListItem>
									{/* accounts && accounts.filter(acc => acc.IsDemo).length > 0 &&
									<ListItem button onClick={this.showPage('/dashboard')}>
										<ListItemText
											className={classes.nested}
											primary={<R id="common_menu_demo_overview" defaultValue="Demo accounts" />}
											disableTypography
										/>
									</ListItem> */}
									<ListItem button onClick={this.showNonTradingOpenDemoAccountModal} className="QA-AccountOverview">
										<ListItemText
											className={classes.nested}
											primary={<R id="common_menu_add_demo_account" defaultValue="Open additional demo account" />}
											disableTypography
										/>
									</ListItem>
								</List>
							</Collapse>
						</React.Fragment>
					}
					{/* {!(baseParams && baseParams.IsCashAccount) &&
					<ListItem button gaUrl="/deposit/ach/" onClick={this.showDepositFundsModal}>
						<ListItemIcon>
							<SvgIcon
								icon="Deposit"
								htmlColor={theme.palette.color.primary.black}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary="Deposit Funds"
							disableTypography
						/>
					</ListItem>
					} */}
					{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_FIN].some(x => x === env.firm) &&
						<ListItem button onClick={this.showPage('/dashboard')} {...createQAAttribute('overview')}>
							<ListItemIcon>
								<SvgIcon
									icon="BriefcaseAccount"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={<R id="common_menu_account_overview" defaultValue="Account Overview" />}
								disableTypography
							/>
						</ListItem>
					}
					{!((baseParams && baseParams.IsCashAccount) || (selectedAccount && (selectedAccount.IsDemo || selectedAccount.IsPaper))) &&
						<ListItem button onClick={this.showPage('/dashboard/cashmanagement')} {...createQAAttribute('cash')}>
							<ListItemIcon>
								<SvgIcon
									icon="Dollar"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={<R id="common_menu_cash_management" defaultValue="Cash management" />}
								disableTypography
							/>
						</ListItem>
					}
					{constants.LIME_LME === env.firm &&
						<ListItem button onClick={this.showPage('/dashboard/tradingplatforms')} className="QA-TradingPlatforms">
							<ListItemIcon>
								<SvgIcon
									icon="App"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={<R id="common_menu_trading_platforms" defaultValue="Trading platforms" />}
								disableTypography
							/>
						</ListItem>
					}
					{(env.firm === constants.LIME_FIN) &&
						<React.Fragment>
							<ListItem button onClick={this.showPage('/dashboard/features')} {...createQAAttribute('features')}>
								<ListItemIcon>
									<SvgIcon
										icon="Settings"
										htmlColor={theme.palette.color.primary.black}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_account_features" defaultValue="Account Features" />}
									disableTypography
								/>
							</ListItem>
							<ListItem button onClick={this.showPage('/dashboard/documents')} {...createQAAttribute('tax_doc')}>
								<ListItemIcon>
									<SvgIcon
										icon="Document"
										htmlColor={theme.palette.color.primary.black}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_documents_disclosures" defaultValue="Documents & Disclosures" />}
									disableTypography
								/>
							</ListItem>
						</React.Fragment>
					}
					{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm) &&
						<ListItem button onClick={this.showNonTradingOrderCommonModal} className="QA-FreeFormOrder">
							<ListItemIcon>
								<SvgIcon
									icon="EditDocument"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={<R id="common_menu_free_form_order" defaultValue="Free form order" />}
								disableTypography
							/>
						</ListItem>
					}
					<ListItem button onClick={this.showPage('/dashboard/settings')} {...createQAAttribute('profile')}>
						<ListItemIcon>
							<SvgIcon
								icon="Person"
								htmlColor={theme.palette.color.primary.black}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary={<R id="common_menu_my_profile" defaultValue="My Profile" />}
							disableTypography
						/>
					</ListItem>
					{!(baseParams && baseParams.IsCashAccount) &&
						<ListItem button onClick={this.showReportsPage('/dashboard/reports')} {...createQAAttribute('reports')}>
							<ListItemIcon>
								<SvgIcon
									icon="Description"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={<R id="common_menu_reports" defaultValue="Reports" />}
								disableTypography
							/>
						</ListItem>
					}
					{[constants.LIME_INT, constants.LIME_NSR].some(x => x === env.firm) &&
						<React.Fragment>
							<ListItem component="a" href={env.disclosuresUrl} target="_blank" className="QA-DocumentsAndDisclosures">
								<ListItemIcon>
									<SvgIcon
										icon="Document"
										htmlColor={theme.palette.color.primary.black}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_disclosures" defaultValue="Disclosures" />}
									disableTypography
								/>
							</ListItem>
						</React.Fragment>
					}
					{[constants.LIME_LME].some(x => x === env.firm) &&
						<React.Fragment>
							<ListItem component="a" href={`https://j2t.global/${language}/solutions/calendar/`} target="_blank" className="QA-TradingAssistance">
								<ListItemIcon>
									<SvgIcon
										icon="Document"
										htmlColor={theme.palette.color.primary.black}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
									primary={<R id="common_menu_trading_assistance" defaultValue="Trading assinstance" />}
									disableTypography
								/>
							</ListItem>
						</React.Fragment>
					}
					{constants.LIME_LME === env.firm &&
						<ListItem button onClick={this.showPage('/dashboard/contacts')} className="QA-CashManagement">
							<ListItemIcon>
								<SvgIcon
									icon="Contacts"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={<R id="common_menu_contacts" defaultValue="Contacts" />}
								disableTypography
							/>
						</ListItem>
					}

					{/* functionality was closed by task WEBCAB-5925
					{clearerFirm !== 'spcfutures' && env.firm === constants.LIME_FIN &&
						<ListItem button onClick={this.showPage('/dashboard/ipo')} className="QA-InitialPublicOffering">
							<ListItemIcon>
								<SvgIcon
									icon="BaseChart"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary="Initial Public Offering"
								disableTypography
							/>
						</ListItem>
					} */}
					{currentAccountStatus.CanFund && currentAccountStatus.CanAcat && env.firm === constants.LIME_FIN &&
						<ListItem button gaUrl="/deposit/acat/" onClick={this.showAcatDepositModal} {...createQAAttribute('transfer')}>
							<ListItemIcon>
								<SvgIcon
									icon="Transfer"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={<R id="common_menu_transfer_shares" defaultValue="Transfer Shares" />}
								disableTypography
							/>
						</ListItem>
					}
					{isEnableThirdPartyTools && env.firm === constants.LIME_FIN &&
						<ListItem button onClick={this.showPage('/dashboard/thirdpartytools')} className="QA-ThirdPartyTools">
							<ListItemIcon>
								<SvgIcon
									icon="ThirdPartyTools"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={<R id="common_menu_third_party_tools" defaultValue="Third Party Tools" />}
								disableTypography
							/>
						</ListItem>
					}
					{/* functionality was closed by task WEBCAB-3983 */}
					{/* <ListItem button onClick={this.showPage('/dashboard/promotions')}>
						<ListItemIcon>
							<SvgIcon
								icon="Dollar"
								htmlColor={theme.palette.color.primary.black}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary="Promotions"
							disableTypography
						/>
					</ListItem> */}
					{env.WEBCAB_3434_tradingAssistance &&
						<ListItem button onClick={this.showPage('/wdashboard/tradingAssistance')} className="QA-TradingAssistance">
							<ListItemIcon>
								<SvgIcon
									icon="TableChart"
									htmlColor={theme.palette.color.primary.black}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary="Trading Assistance"
								disableTypography
							/>
						</ListItem>
					}
				</List>
			</div>
		);
	}
}

AccountActions.propTypes = {
	language: PropTypes.string.isRequired,
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	currentAccountStatus: PropTypes.object.isRequired,
	userInfo: PropTypes.object.isRequired,
	selectedAccount: PropTypes.object,
	history: PropTypes.object.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	actions: PropTypes.shape({
		showDepositFundsModal: PropTypes.func.isRequired,
		sidebarClose: PropTypes.func.isRequired,
		showAcatDepositModal: PropTypes.func.isRequired,
		showNonTradingOrderCommon: PropTypes.func.isRequired,
		showNonTradingOpenDemoAccount: PropTypes.func.isRequired,
		showNonTradingOpenAccount: PropTypes.func.isRequired,
		setCurrentPage: PropTypes.func.isRequired,
	}).isRequired,
	currentAccountData: PropTypes.shape({
		baseParams: PropTypes.object,
	}),
};

AccountActions.defaultProps = {
	selectedAccount: null,
	currentAccountData: PropTypes.shape({
		baseParams: null,
	}),
};


export default compose(
	withRouter,
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
)(AccountActions);
