export default {
	profile: state => state.profile.profile,
	confirmEmail: state => state.profile.confirmEmail,
	changeTariff: state => state.profile.changeTariff,
	holders: state => state.profile.holders,
	forceCheckPaperless: state => state.profile.forceCheckPaperless,
	getCountries: state => state.profile.CountryList,
	getUsaStates: state => state.profile.UsaStatesList,
	changeTaxForm: state => state.profile.changeTaxForm,
	modals: state => state.profile.modals,
	clientSecretCode: state => state.profile.ClientSecretCode,
	qrCode: state => state.profile.QRCode,
	totpAuthentication: state => state.profile.totpAuthentication,
};
