import appConfig from 'app_config';
import { constants } from '@config';
import { cabinetLog } from '@global';

const envConfig = {
	production: {
		anketaDocumentationUrl: 'https://docs.lime.co',
		anketaSiteUrl: 'https://open.lime.co/register',
		anketaSiteOldUrl: 'https://open-old.lime.co',
		backendUrl: 'https://myaccountapi.lime.co/',
		pricingUrl: 'https://lime.co/pricing/',
		OAuthServiceUrl: 'https://auth.lime.co',
		UsaApiServiceUrl: 'https://api.lime.co',
		plaidApiEnvironment: 'production',
		plaidApiPublicKey: 'b3ddce858a38efc6c36ccbf8d9b93e',
		GA_TRACKING_ID: 'UA-5574920-1',
		GTM_DATA_LAYER: 'GTM-KZH6ZPM',
		sterlingPcDownloadUrl: 'https://download.sterlingtrader.com/applications/ISSterlingTraderPro_10_3_0.exe',
		visionOptionsSupplementUrl: 'https://cdn-lpbr.lime.co/lpbr-static/Options_Supplement_9a362b1e05.pdf',
		uncoveredWrtingRiskDisclosureUrl: 'https://cdn-lpbr.lime.co/lpbr-static/Special_Options_Risk_Disclosure_Statement_1_8d27d2ff6e.pdf',
		characteristicsRisksStandardizedOptionsUrl: 'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document',
		languagesServerPath: '/translation/translations.{lng}.json',
		reloadDicwordsPeriod: 15 * 60 * 1000, // 15 мин
		allowedLangs: null,
		limeTraderAppStore: 'https://apps.apple.com/us/app/lime-trader/id1314411861',
		limeTraderGooglePlay: 'https://play.google.com/store/apps/details?id=com.just2trade.android&hl=en_US&gl=US&pli=',
		takeProfitSiteUrl: 'https://takeprofit.com/platform#lime',

		WEBCAB_3434_tradingAssistance: false,
		WEBCAB_3520_emailDelivery: false,
		WEBCAB_3534_checkBrokenEmail: false,
		WEBCAB_4509_transaqChangePwdAvalilableFor: [
			'3065A493-0379-4A66-AD28-0450F7392769',
			'DAF16251-7054-4975-94F2-342FD57675D7',
			'417AEE48-D64E-4CDC-8F85-205198B9AE78',
			'BC7BE6BE-CC1A-4D43-BDBA-7645DDCD5C20',
			'3065A493-0379-4A66-AD28-0450F7392769',
		],
		WEBCAB_4791_hideDepositRequisites: false,
		WEBCAB_5004_walletEnableUsers: [
			'00000000-0000-0000-0000-000000000649',
		],
		WEBCAB_5031_switch_trading_platforms: true,
		WEBCAB_5142_marketData_subscriptions_enable: false,
		WEBCAB_5222_hubspotChat: true,
		WEBCAB_5786_allow_withdrawals: true,
		WEBCAB_5863_allow_attestation: true,
		WEBCAB_5839_allow_demo_account: false,
		WEBCAB_4449_allow_old_corp_actions: false,
	},
	preprod: {
		anketaDocumentationUrl: 'https://docs.lime.co',
		anketaSiteUrl: 'https://open.lime.co/register',
		anketaSiteOldUrl: 'https://open-old.lime.co',
		backendUrl: 'https://uspreprod.tst.just2trade.online/',
		pricingUrl: 'https://lime.co/pricing/',
		OAuthServiceUrl: 'http://nyx-etnae1-ap01.corp.whotrades.eu/auth-uat',
		UsaApiServiceUrl: 'https://ftru03.just2trade.com/pp/usaapi',
		plaidApiEnvironment: 'sandbox',
		plaidApiPublicKey: 'b3ddce858a38efc6c36ccbf8d9b93e',
		sterlingPcDownloadUrl: 'https://download.sterlingtrader.com/applications/ISSterlingTraderPro_10_3_0.exe',
		visionOptionsSupplementUrl: 'https://cdn-lpbr.lime.co/lpbr-static/Options_Supplement_9a362b1e05.pdf',
		uncoveredWrtingRiskDisclosureUrl: 'https://cdn-lpbr.lime.co/lpbr-static/Special_Options_Risk_Disclosure_Statement_1_8d27d2ff6e.pdf',
		characteristicsRisksStandardizedOptionsUrl: 'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document',
		languagesServerPath: 'http://cab-lme.tstcabweb.corp.whotrades.eu/translation/translations.{lng}.json',
		reloadDicwordsPeriod: null,
		allowedLangs: null,

		limeTraderAppStore: 'https://apps.apple.com/us/app/lime-trader/id1314411861',
		limeTraderGooglePlay: 'https://play.google.com/store/apps/details?id=com.just2trade.android&hl=en_US&gl=US&pli=',
		takeProfitSiteUrl: 'https://takeprofit.com/platform#lime',

		WEBCAB_3434_tradingAssistance: false,
		WEBCAB_3520_emailDelivery: true,
		WEBCAB_3534_checkBrokenEmail: true,
		WEBCAB_4061_availableIpoUsers: ['sminkov', 'vremsha', 'whotrades2', 'wadam', 'ycartth2880'],
		WEBCAB_4509_transaqChangePwdAvalilableFor: [
			'3065A493-0379-4A66-AD28-0450F7392769',
			'DAF16251-7054-4975-94F2-342FD57675D7',
			'417AEE48-D64E-4CDC-8F85-205198B9AE78',
			'BC7BE6BE-CC1A-4D43-BDBA-7645DDCD5C20',
			'3065A493-0379-4A66-AD28-0450F7392769',
		],
		WEBCAB_4791_hideDepositRequisites: false,
		WEBCAB_5004_walletEnableUsers: [
			'00000000-0000-0000-0000-000000000649',
		],
		WEBCAB_5031_switch_trading_platforms: true,
		WEBCAB_5142_marketData_subscriptions_enable: false,
		WEBCAB_5222_hubspotChat: true,
		WEBCAB_5786_allow_withdrawals: true,
		WEBCAB_5863_allow_attestation: true,
		WEBCAB_4449_allow_old_corp_actions: false,
	},
	test: {
		anketaDocumentationUrl: 'https://docs.lime.co',
		anketaSiteUrl: 'https://open.lime.co/register',
		anketaSiteOldUrl: 'https://open-old.lime.co',
		backendUrl: 'https://ustest.tst.just2trade.online/',
		pricingUrl: 'https://lime.co/pricing/',
		OAuthServiceUrl: 'http://nyx-etnae1-ap01.corp.whotrades.eu/auth-uat',
		UsaApiServiceUrl: 'https://ftru03.just2trade.com/tst/usaapi',
		plaidApiEnvironment: 'sandbox',
		plaidApiPublicKey: 'b3ddce858a38efc6c36ccbf8d9b93e',
		sterlingPcDownloadUrl: 'https://download.sterlingtrader.com/applications/ISSterlingTraderPro_10_3_0.exe',
		visionOptionsSupplementUrl: 'https://cdn-lpbr.lime.co/lpbr-static/Options_Supplement_9a362b1e05.pdf',
		uncoveredWrtingRiskDisclosureUrl: 'https://cdn-lpbr.lime.co/lpbr-static/Special_Options_Risk_Disclosure_Statement_1_8d27d2ff6e.pdf',
		characteristicsRisksStandardizedOptionsUrl: 'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document',
		languagesServerPath: '/translation/translations.{lng}.json',
		reloadDicwordsPeriod: null,
		allowedLangs: null,

		limeTraderAppStore: 'https://apps.apple.com/us/app/lime-trader/id1314411861',
		limeTraderGooglePlay: 'https://play.google.com/store/apps/details?id=com.just2trade.android&hl=en_US&gl=US&pli=',
		takeProfitSiteUrl: 'https://takeprofit.com/platform#lime',

		WEBCAB_3434_tradingAssistance: false,
		WEBCAB_3520_emailDelivery: true,
		WEBCAB_3534_checkBrokenEmail: true,
		WEBCAB_4791_hideDepositRequisites: false,
		WEBCAB_5004_walletEnableUsers: [
			'00000000-0000-0000-0000-000000000649',
		],
		WEBCAB_5031_switch_trading_platforms: true,
		WEBCAB_5142_marketData_subscriptions_enable: true,
		WEBCAB_5222_hubspotChat: true,
		WEBCAB_5786_allow_withdrawals: true,
		WEBCAB_5863_allow_attestation: true,
		WEBCAB_5839_allow_demo_account: true,
		WEBCAB_4449_allow_old_corp_actions: false,
	},
	dev: {
		anketaDocumentationUrl: 'https://docs.lime.co',
		anketaSiteUrl: 'https://open.lime.co/register',
		anketaSiteOldUrl: 'https://open-old.lime.co',
		sentryRavenUrl: '',
		plaidApiEnvironment: 'sandbox',
		plaidApiPublicKey: 'b3ddce858a38efc6c36ccbf8d9b93e',
		languagesServerPath: 'http://cab-lme.tstcabweb.corp.whotrades.eu/translation/translations.{lng}.json',
		limeTraderAppStore: 'https://apps.apple.com/us/app/lime-trader/id1314411861',
		limeTraderGooglePlay: 'https://play.google.com/store/apps/details?id=com.just2trade.android&hl=en_US&gl=US&pli=',
		takeProfitSiteUrl: 'https://takeprofit.com/platform#lime',

		WEBCAB_4523_dateRequireSecondFactor: undefined, // Date.UTC(2021, 9, 1),
		WEBCAB_5004_walletEnableUsers: [
			'00000000-0000-0000-0000-000000000649',
		],
		WEBCAB_5031_switch_trading_platforms: true,
		WEBCAB_5142_marketData_subscriptions_enable: true,
		WEBCAB_5222_hubspotChat: true,
		WEBCAB_5786_allow_withdrawals: true,
		WEBCAB_5863_allow_attestation: true,
		WEBCAB_5839_allow_demo_account: true,
		WEBCAB_4449_allow_old_corp_actions: false,
	},
};

// eslint-disable-next-line import/no-mutable-exports
let env = {
	...envConfig.production,
	...appConfig,
};
cabinetLog(process.env.NODE_ENV);


if (process.env.NODE_ENV !== 'production') {
	env = {
		...envConfig.test,
		...appConfig,
	};
	env.J2tProPcDownloadUrl = `${env.backendUrl}files/programs/J2T_PRO.exe`;
}

if (IS_LOCAL !== undefined && IS_LOCAL) {
	env = {
		...appConfig,
		...envConfig.dev,
	};
}

if (IS_PREPROD !== undefined && IS_PREPROD) {
	env = {
		...envConfig.preprod,
		...appConfig,
	};
	env.J2tProPcDownloadUrl = `${env.backendUrl}files/programs/J2T_PRO.exe`;
}

if (!env.baseUrl) {
	env.baseUrl = window.location.origin;
}

// set application version for updating them on the client side
switch (env.firm) {
	case constants.LIME_FIN:
		env.version = 115;
		break;
	case constants.LIME_INT:
		env.version = 4;
		break;
	case constants.LIME_NSR:
		env.version = 1;
		break;
	case constants.LIME_LME:
		env.version = 1;
		break;
	default:
		env.version = 1;
}

cabinetLog('env', env);

export default env;
