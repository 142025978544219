import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


import {
	A,
	Button,
	Checkbox,
	DateRangePicker,
	ExpansionPanel,
	FileDownload,
	ProgressContainer,
	ShowMore,
	SvgIcon,
	R,
} from '@components';
import { env } from '@config';
import { snackbar, reports, userAccounts } from '@redux';
import { CYReportsTable } from './CYReportsTable';

const useStyles = makeStyles((theme) => {

	let filterDefaultSpaces = {
		marginRight: '40px',
		whiteSpace: 'nowrap',
	};
	let filterMobileSpaces = {
		marginRight: '10px',
		whiteSpace: 'nowrap',
	};

	let filtersCss = {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			'& > span': filterMobileSpaces,
			'& > div': filterMobileSpaces,
		},
		[theme.breakpoints.up('sm')]: {
			'& > span': filterDefaultSpaces,
			'& > div': filterDefaultSpaces,
		},
	};

	return {
		progress: {
			marginTop: '3px',
		},
		itemContainer: {
			padding: '0 16px',
			margin: '0px -16px',
			'&:hover': {
				backgroundColor: '#e3edf5',
			},
		},
		item: {
			borderBottom: '1px solid #deeaef',
			padding: '16px 0',
		},
		reportFilters: filtersCss,
		reportHeader: {
			...filtersCss,
			padding: '10px 0',
			[theme.breakpoints.up('md')]: {
				flexWrap: 'nowrap',
			},
		},
		rootLoading: {
			display: 'flex',
			alignItems: 'center',
		},
	}
});


export const CYReport = ({}) => {

	const theme = useTheme();
	const classes = useStyles();

	const [reportsCount, setReportsCount] = useState(5);

	const [isLite, setIsLite] = useState(false);
	const [endDate, setEndDate] = useState(new Date());
	const [startDate, setStartDate] = useState(new Date(moment().year(), moment().month() - 2, moment().date()));

	const currentTradeAccount = useSelector((state) => userAccounts.selectors.currentTradeAccount(state))
	const accountReports = useSelector((state) => reports.selectors.reports(state))
	let generateSubmitting = useSelector((state) => reports.selectors.generateSubmitting(state))
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(reports.actions.getBrokerReports.request({ tradeCode: currentTradeAccount.tradeCode }));
	}, [currentTradeAccount.tradeCode]);

	let showError = message => dispatch(snackbar.actions.showErrorMessage({ text: message }));

	let Page = 1;
	let pageSize = 5;

	let { Items, submitting } = accountReports;


	return (
		<ExpansionPanel
			header={
				<div className={classes.reportHeader}>
					{/* <span>Broker Reports</span> */}
					<div className={classes.reportFilters}>
						<DateRangePicker
							endDate={endDate}
							startDate={startDate}
							onEndDateChange={(date) => setEndDate(date)}
							onStartDateChange={(date) => setStartDate(date)}
						/>
						<Checkbox 
							label={<R id="reportsfilter_lite" defaultValue="Lite version" />}
							isCompact
							onChange={() => setIsLite(!isLite)}
						/>
						<A
							onClick={() => dispatch(reports.actions.generateBrokerReport.request({ 
								startDate: moment(startDate).utc().format(), 
								endDate: moment(endDate).utc().format(),
								isLiteVersion: isLite,
								tradeCode: currentTradeAccount.tradeCode
							}))}
							classes={{ root: classes.rootLoading }}
						>
							{generateSubmitting ?
								<CircularProgress
									style={{ color: theme.typography.color.blue }}
									size={18}
									classes={{ root: classes.progress }}
								/>
								:
								<SvgIcon icon="Report" />
							}
							&nbsp;<R id="shared_generate" defaultValue="Generate" />
						</A>

					</div>
				</div>
			}
			id="report-history"
		>
			<ProgressContainer submitting={submitting && accountReports.tradeCode !== currentTradeAccount.tradeCode} display="left" id="reports-progress">
				{Items && 
					<Grid container>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div className={classes.table}>
								<CYReportsTable 
									items={Items.slice(0, reportsCount)} 
									submitting={submitting} 
									showError={showError} 
								/>
							</div>
							<ShowMore
								submitting={submitting && Items && Items.length > 0}
								onClick={() => setReportsCount(reportsCount + pageSize)}
								showMoreButton={Items.length > reportsCount}
							/>
						</Grid>
					</Grid>
				}
			</ProgressContainer>
		</ExpansionPanel>

	);
}