import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { depositFunds, userAccounts } from '@redux';
import { constants, env } from '@config';
import { A, FileDownload } from '@components';
import { connect } from 'react-redux';

const styles = theme => ({
	root: {},
	title: {
		...theme.typography.style.title,
		marginBottom: 0,
	},
	table: {
		'& td': {
			paddingBottom: '8px',
			'&:first-child': {
				color: theme.typography.color.secondary,
				verticalAlign: 'top',
				// whiteSpace: 'nowrap',
				paddingRight: '10px',
			},
		},
	},
	warning: {
		backgroundColor: '#f9d113',
		padding: '12px 16px',
		borderRadius: '4px',
	},
	alert: {
		backgroundColor: theme.typography.color.pink,
		color: theme.palette.color.secondary.downDark,
		padding: '12px 16px',
		borderRadius: '4px',
	},
	step: {
		padding: '12px 0',
	},
	button: {},
});

function addBreaks(data) {
	if (!data) return '';
	if (Array.isArray(data)) {
		const newdata = [];
		data.forEach((value, index) => {
			if (index > 0) newdata.push(<br key={index} />);
			newdata.push(value);
		});
		return newdata;
	}
	return data;
}

const mapStateToProps = (state) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
		},
		currentTradeAccount,
		currentAccountStatus: userAccounts.selectors.currentAccountStatus(state),
		accountInfo: userAccounts.selectors.accountInfo(state),
		depositWireInfo: depositFunds.selectors.depositWireInfo(state),
		userInfo: userAccounts.selectors.userInfo(state),
	};
};

class WireDepositPage extends React.Component {
	render() {
		const {
			classes,
			currentTradeAccount,
			bankAccount,
			accountInfo,
			currentAccountStatus,
			depositWireInfo,
			userInfo,
		} = this.props;

		const hasBankUrl = ((bankAccount && bankAccount.BankCabinetUrl) || depositWireInfo.Url);
		const wireRequisites = (accountInfo && accountInfo.WireRequisites) || {};
		const hiddenSpcFuturesRequisites = env.WEBCAB_4791_hideDepositRequisites && currentTradeAccount.clearerFirm === 'spcfutures';
		return (
			<div>
				{currentAccountStatus.CanFund && !hiddenSpcFuturesRequisites &&
				<div>
					<p>
						To fund your Lime Trading account through a Wire Transfer, there is one single step below:
					</p>
					{env.firm !== constants.LIME_NSR &&
						<h4 className={classes.title}>Step 1.</h4>
					}
					{env.firm === constants.LIME_NSR &&
						<p className={classes.alert}>
							The transfer of funds must be notified by emailing team@lendingrobot.com indicating your name, the amount and date of the transaction
						</p>
					}
					<p>Complete the request for a Wire Transfer</p>
					<ol>
						{hasBankUrl &&
							<li>
								<A target="_blank" href={depositWireInfo.Url ? depositWireInfo.Url : bankAccount.BankCabinetUrl}>
									Login into your Online banking
								</A> (in case if your bank supports	an online-banking facility).
							</li>
						}
						{!hasBankUrl &&
							<li>
								Login into your Online banking (in case if your bank supports
								an online-banking facility).
							</li>
						}
						<li>Look for the {'\'Wire Transfer\''} option.</li>
						<li>Complete the Wire Transfer request, using the data below:</li>
					</ol>
					<table className={classes.table}>
						<tbody>
							{depositWireInfo.BankName &&
								<tr>
									<td>Clients Bank Name:</td>
									<td>{depositWireInfo.BankName}</td>
								</tr>
							}
							<tr>
								<td>Bank Name:</td>
								<td>{addBreaks(wireRequisites.BankName)}</td>
							</tr>
							<tr>
								<td>ABA Number:</td>
								<td>{wireRequisites.AbaNumber}</td>
							</tr>
							{wireRequisites.SwiftCode &&
								<tr>
									<td>International SWIFT Code:</td>
									<td>{wireRequisites.SwiftCode}</td>
								</tr>
							}
							{wireRequisites.ForCreditTo &&
								<tr>
									<td>For Credit To:</td>
									<td>{addBreaks(wireRequisites.ForCreditTo)}</td>
								</tr>
							}
							<tr>
								<td>Bank Account Number:</td>
								<td>{wireRequisites.BankAccountNumber}</td>
							</tr>
							{wireRequisites.BankAccountName &&
								<tr>
									<td>Bank Account Name:</td>
									<td>{addBreaks(wireRequisites.BankAccountName)}</td>
								</tr>
							}
							{env.firm === constants.LIME_FIN &&
								<React.Fragment>
									{currentTradeAccount.clearerFirm !== 'spcfutures' &&
										<tr>
											<td>For Further Credit To:</td>
											<td>
												Account: {currentTradeAccount.tradeCode.substring(0, 4)}-{currentTradeAccount.tradeCode.substring(4)}<br />
												Name: {accountInfo && accountInfo.AccountName}
											</td>
										</tr>
									}
									{currentTradeAccount.clearerFirm === 'spcfutures' &&
										<React.Fragment>
											<tr>
												<td>For Further Credit To:</td>
												<td>{accountInfo && accountInfo.AccountName}</td>
											</tr>
											<tr>
												<td>Futures Account Number: </td>
												<td>
													{currentTradeAccount.tradeCode.substring(0, 4)}-{currentTradeAccount.tradeCode.substring(4)}
												</td>
											</tr>
										</React.Fragment>
									}
								</React.Fragment>
							}
							{env.firm === constants.LIME_NSR &&
								<tr>
									<td>For Further Credit To:</td>
									<td>
										Account: {currentTradeAccount.tradeCode}<br />
										Name: {userInfo && userInfo.LongName}
									</td>
								</tr>
							}
							{depositWireInfo.Amount &&
								<tr>
									<td>Amount to Transfer:</td>
									<td>${depositWireInfo.Amount}</td>
								</tr>
							}
							<tr>
								<td />
								<td>
									<FileDownload
										url={`${env.backendUrl}api_funding/wire/print/${currentTradeAccount.clearerFirm}/${currentTradeAccount.tradeCode}`}
										fileName="Wire Transfer Requisites.pdf"
										buttonTitle="PRINT"
									/>
								</td>
							</tr>
						</tbody>
					</table>
					{env.firm === constants.LIME_FIN &&
						<p className={classes.warning}>
							Note: Please indicate your exact Lime Trading account name and your
							8 digit account number with the wire transfer. Failure to do so could
							result in delayed posting or return of your wire.
						</p>
					}
					<p className={classes.step}>
						Alternative step: If you do not have access to online banking,
						please visit any branch of your bank and ask a representative
						to initiate the Wire Transfer by providing the information above.
					</p>
				</div>
				}
				{hiddenSpcFuturesRequisites &&
					<p className={classes.warning}>
						Funding for Futures accounts will be available soon.
					</p>
				}
			</div>
		);
	}
}

WireDepositPage.defaultProps = {
	bankAccount: null,
};

WireDepositPage.propTypes = {
	classes: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountStatus: PropTypes.object.isRequired,
	bankAccount: PropTypes.object,
	accountInfo: PropTypes.object.isRequired,
	depositWireInfo: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(WireDepositPage);
