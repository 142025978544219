import React from 'react';
import { useSelector } from 'react-redux';
import { userAccounts } from '@redux';
import { ProgressContainer } from '@components';
import { createQAAttribute } from '@global';

const HeaderUserInfo = () => {
	const { submitting, FirstName, LastName } = useSelector(userAccounts.selectors.userInfo);
	return (
		<div>
			<ProgressContainer submitting={submitting} disableCheckChildren >
				<span {...createQAAttribute('name')}>{`${FirstName || ''} ${LastName || ''}`}</span>
			</ProgressContainer>
		</div>
	);
};

export default HeaderUserInfo;
