/* eslint-disable no-unused-vars */

const initialState = {
	loading: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	default:
		return state;
	}
};
