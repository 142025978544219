import { createFormAction } from 'redux-form-saga';

const withdrawFundsWireFormSubmitActionPrefix = 'WITHDRAW_FUNDS_WIRE_FORM_SUBMIT';
const withdrawFundsWireFormActionCreator =
	createFormAction(withdrawFundsWireFormSubmitActionPrefix);

const linkAndWithdrawFundsWireCreator = createFormAction('LINK_AND_WITHDRAW_WIRE_FORM_SUBMIT');
const actions = {
	// actions

	SHOW_WITHDRAW_FUNDS_ACH_MODAL: 'WITHDRAW_FUNDS/ACH_MODAL_SHOW',
	HIDE_WITHDRAW_FUNDS_ACH_MODAL: 'WITHDRAW_FUNDS/ACH_MODAL_HIDE',

	SHOW_WITHDRAW_FUNDS_WIRE_MODAL: 'WITHDRAW_FUNDS/WIRE_MODAL_SHOW',
	HIDE_WITHDRAW_FUNDS_WIRE_MODAL: 'WITHDRAW_FUNDS/WIRE_MODAL_HIDE',

	SHOW_WITHDRAW_FUNDS_BROKER_MODAL: 'WITHDRAW_FUNDS/BROKER_MODAL_SHOW',
	HIDE_WITHDRAW_FUNDS_BROKER_MODAL: 'WITHDRAW_FUNDS/BROKER_MODAL_HIDE',

	withdrawAchFundsForm: createFormAction('WITHDRAW_FUNDS/ACH_WITHDRAW_FUNDS_FORM'),

	WITHDRAW_FUNDS_WIRE_FORM_SUBMIT_REQUEST: withdrawFundsWireFormActionCreator.REQUEST,
	WITHDRAW_FUNDS_WIRE_FORM_SUBMIT_SUCCESS: withdrawFundsWireFormActionCreator.SUCCESS,
	WITHDRAW_FUNDS_WIRE_FORM_SUBMIT_FAILURE: withdrawFundsWireFormActionCreator.FAILURE,

	withdrawFundsWireForm: withdrawFundsWireFormActionCreator,

	withdrawFundsWireFormRequest: (...args) => {
		withdrawFundsWireFormActionCreator.request({ ...args.wireWithdrawal });
	},
	withdrawFundsWireFormSuccess: payload => withdrawFundsWireFormActionCreator.success(payload),
	withdrawFundsWireFormFailure: error => withdrawFundsWireFormActionCreator.failure(error),

	withdrawFundsBrokerForm: createFormAction('WITHDRAW_FUNDS/BROKER_WITHDRAW_FUNDS_FORM'),

	linkAndWithdrawFundsWireForm: linkAndWithdrawFundsWireCreator,
	linkAndWithdrawFundsWireFormFailure: error => linkAndWithdrawFundsWireCreator.failure(error),
	linkAndWithdrawFundsWireFormSuccess: payload => linkAndWithdrawFundsWireCreator.success(payload),

	showWithdrawFundsAchModal: (fromUserAccount, toBankAccount) => ({
		type: actions.SHOW_WITHDRAW_FUNDS_ACH_MODAL,
		payload: {
			fromUserAccount,
			toBankAccount,
		},
	}),
	hideWithdrawFundsAchModal: () => ({
		type: actions.HIDE_WITHDRAW_FUNDS_ACH_MODAL,
	}),
	showWithdrawFundsWireModal: (fromUserAccount, toBankAccount) => ({
		type: actions.SHOW_WITHDRAW_FUNDS_WIRE_MODAL,
		payload: {
			fromUserAccount,
			toBankAccount,
		},
	}),
	hideWithdrawFundsWireModal: () => ({
		type: actions.HIDE_WITHDRAW_FUNDS_WIRE_MODAL,
	}),
	showWithdrawFundsBrokerModal: (fromUserAccount, toBankAccount) => ({
		type: actions.SHOW_WITHDRAW_FUNDS_BROKER_MODAL,
		payload: {
			fromUserAccount,
			toBankAccount,
		},
	}),
	hideWithdrawFundsBrokerModal: () => ({
		type: actions.HIDE_WITHDRAW_FUNDS_BROKER_MODAL,
	}),

	SHOW_WITHDRAW_FUNDS_IRA_MODAL: 'WITHDRAW_FUNDS/IRA_MODAL_SHOW',
	HIDE_WITHDRAW_FUNDS_IRA_MODAL: 'WITHDRAW_FUNDS/IRA_MODAL_HIDE',
	showWithdrawFundsIraModal: (fromUserAccount, toBankAccount) => ({
		type: actions.SHOW_WITHDRAW_FUNDS_IRA_MODAL,
		payload: {
			fromUserAccount,
			toBankAccount,
		},
	}),
	hideWithdrawFundsIraModal: () => ({
		type: actions.HIDE_WITHDRAW_FUNDS_IRA_MODAL,
	}),
	withdrawFundsIraForm: createFormAction('WITHDRAW_FUNDS/IRA_WITHDRAW_FUNDS_FORM'),

	transferBetweenAccountsSubmitForm: createFormAction('WITHDRAW_FUNDS/TRANSFER_BETWEEN_ACCOUNTS_FORM'),
};

export default actions;
