import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
	profile,
	signature,
	userAccounts,
} from '@redux';
import compose from 'recompose/compose';
import {
	ProgressContainer,
	R,
} from '@components';
import { addHashToUrl, scrollTo } from '@global';
import { 
	CYAccountCredentialsPanel,
	CYPersonalDocumentsPanel,
} from './components';

const styles = theme => ({
	root: {
		border: '1px solid red',
	},
	header: {
		...theme.typography.style.headline1,
		padding: '8px 0',
	},
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	mr60: {
		marginRight: '60px',
	},
	expansionPanelStaticContent: {
		margin: '10px 24px 5px 0px',
		color: theme.palette.text.secondary,
	},
	expansionPanelContent: {
		'& $expansionPanelRoot': {
			marginLeft: '24px',
		},
		'& $preFieldLabel': {
			marginLeft: '24px',
		},
		'& $noLeftMarginField': {
			marginLeft: '24px',
		},
	},
	preFieldLabel: {},
	noLeftMarginField: {
		marginLeft: '0px',
	},
	expansionPanelRoot: {
		marginLeft: '0px',
	},
});

const mapStateToProps = state => ({
	// profile: profile.selectors.profile(state),
	// currentAccountData: userAccounts.selectors.currentAccountData(state),
	// currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	// accountInfo: userAccounts.selectors.accountInfo(state),
	// accountStatus: userAccounts.selectors.currentAccountStatus(state),
	// userInfo: userAccounts.selectors.userInfo(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		// showChangeEmailModal: () => {
		// 	const changeEmailAction = profile.actions.showChangeEmailModal();
		// 	dispatch(signature.actions.collectSignaturesProcessStart(changeEmailAction));
		// },
	},
});

class CYProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openPanelId: 'profile-account-credentials',
		};
	}

	componentDidUpdate() {

		if (this.props.location.hash) {
			scrollTo(this.props.location.hash.substr(1));
		} else if (this.props.location.search) {
			const parsed = qs.parse(this.props.location.search);
			if (parsed && parsed.commission && parsed.commission.toLowerCase() === 'zero') {
				scrollTo('profile-commission-plan');
			}
		}
	}

	showUpgradeOptionLevelModal = () => {
		const { actions, currentTradeAccount, currentAccountData } = this.props;
		actions.showUpgradeOptionLevelModal(currentTradeAccount, currentAccountData.baseParams.MarginOptionParameters);
	}

	showModal = (id) => {
		this.props.history.push(addHashToUrl(this.props.location, id));
	}

	toggleOpen = (open, id) => {
		this.setState({ openPanelId: open ? id : null });
	};

	render() {
		const {
			classes,
			actions,
		} = this.props;

		return (
			<div className={classes.content}>
				<div>
					<div className={classes.header}>
						<R id="common_menu_my_profile" defaultValue="My Profile" />
					</div>
				</div>
				{/* <ProgressContainer submitting={submitting} display="left"> */}
				<ProgressContainer submitting={false} disableCheckChildren display="left">
					<CYAccountCredentialsPanel
						classes={classes}
						onToggle={this.toggleOpen}
						panelId="profile-account-credentials"
						isOpen={this.state.openPanelId === 'profile-account-credentials'}
					/>
					<CYPersonalDocumentsPanel 
						classes={classes}
						onToggle={this.toggleOpen}
						panelId="profile-personal-documents"
						isOpen={this.state.openPanelId === 'profile-personal-documents'}
					/>
				</ProgressContainer>
			</div>
		);
	}
}

CYProfile.defaultProps = {
};

CYProfile.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};


export default compose(
	withRouter,
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CYProfile);
