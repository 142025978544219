import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { userAccounts, profile } from '@redux';
import { makeStyles } from '@material-ui/core/styles';
import JointHolderInformation from './JointHolderInformation';

const styles = (theme) => ({
    root: {
        marginLeft: '24px',
        marginTop: '20px'
    },
	divider: {
		border: '1px solid #345464',
        width: '100%',
	}
})

const useStyles = makeStyles(styles);

const JointHolders = ({
	account,
	classes: _classes,
	panelIdPrefix,
}) => {

	const classes = {
		...useStyles(),
		..._classes,
	};

	const holders = useSelector(profile.selectors.holders);
	const loading = useSelector(userAccounts.selectors.loading);

	if (loading && !holders.loaded) {
		return <React.Fragment />;
	}

    const filteredHolders = account.Holders.filter(h => !h.IsPrimary);

	return (
		<React.Fragment>
			{
				filteredHolders.map((holder, index) => {
					const idkey = `${panelIdPrefix}-${holder.ClientId}`;
					return (
                        <div className={classes.root} key={idkey}>
                            <div>{holder.FullName}</div>
                            <JointHolderInformation  classes={classes} holder={holder} />
                            {index < filteredHolders.length - 1 && <div className={classes.divider}></div>}
                        </div>
					);
				})
			}
		</React.Fragment>
	);
};


JointHolders.propTypes = {
	classes: PropTypes.object.isRequired,
	panelIdPrefix: PropTypes.string,
	account: PropTypes.shape({
		AccountName: PropTypes.string.isRequired,
		TradeCode: PropTypes.string.isRequired,
		Holders: PropTypes.arrayOf(PropTypes.shape({
			FullName: PropTypes.string.isRequired,
			ClientId: PropTypes.number.isRequired,
			Guid: PropTypes.string,
			IsPrimary: PropTypes.isRequired,
		})).isRequired,
	}).isRequired,
};
JointHolders.defaultProps = {
	panelIdPrefix: 'joint-holders',
};

export default JointHolders;
