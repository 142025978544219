import React, { useState }  from 'react';
import { useSelector } from 'react-redux';
import { Modal, SuccessMessage, ErrorMessage, ProgressContainer, R } from '@components';
import { nontradingOrders, userAccounts } from '@redux';
import CYNonTradingOrderLeverageForm from './CYNonTradingOrderLeverageForm';


export const CYNonTradingOrderLeverageModal = ({ onClose }) => {
	const [step, setStep] = useState('openform');
	const loading = useSelector(userAccounts.selectors.loading);
	const accountInfo = useSelector(userAccounts.selectors.accountInfo);
	const dataLoading = loading || accountInfo.loading;

	return (
		<Modal
			title={<R id="dialog_order_leverage_title" defaultValue="Change in leverage" />}
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
		>
			<ProgressContainer submitting={dataLoading}>
				<div>
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={onClose}
							text={<R id="order_leverage_success" defaultValue="Change in leverage completed" />}
						/>
					}
					{step === 'failed' &&
						<ErrorMessage
							onClick={onClose}
							text={<R id="shared_order_failed" defaultValue="Could not complete order" />}
						/>
					}
					{step === 'openform' &&
						<CYNonTradingOrderLeverageForm
							enableReinitialize
							forceUnregisterOnUnmount
							onSubmit={nontradingOrders.actions.sendNonTradingOrderLeverageForm}
							onSuccess={() => setStep('succeeded')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
}