import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import {
	profile,
	signature,
	userAccounts,
} from '@redux';
import compose from 'recompose/compose';
import { reduxForm, getFormValues } from 'redux-form';
import {
	Button,
	Modal,
	ProgressContainer,
	SuccessMessage,
} from '@components';
import ConfirmProfileNavigation from './ConfirmProfileNavigation';


const styles = theme => ({

	button: {
		paddingTop: '12px',
		paddingBottom: '50px',
		margin: 'auto',
		width: '30%',
	},
	header: {
		...theme.typography.style.headline1,
		...theme.typography.color.primary,
		padding: '8px 0',
	},
	subHeader: {
		...theme.typography.style.title,
		color: theme.typography.color.secondary,
	},
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},

	alertMessage: {
		...theme.typography.style.body,
		margin: '24px 74px 50px;',
		padding: '12px 16px',
		backgroundColor: theme.palette.color.secondary.limitOrder,
		borderRadius: '4px',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0 50px',
		},
		color: theme.typography.color.primary,
		textAlign: 'center',
	},
});

const CONFIRM_PROFILE_FORM = 'ConfirmProfileForm';

const mapStateToProps = (state) => {
	const profileData = profile.selectors.profile(state);
	const holders = profile.selectors.holders(state);
	const userInfo = userAccounts.selectors.userInfo(state);
	const accounts = userAccounts.selectors.accounts(state);
	const userAccountsLoading = userAccounts.selectors.loading(state);
	const userGuid = userInfo.Guid && userInfo.Guid.toLowerCase();

	return {
		profile: profileData,
		holders,
		loading: profileData.submitting || holders.submitting || userAccountsLoading,
		hasSignes: signature.selectors.HasSignes(state),
		allFormValues: getFormValues(CONFIRM_PROFILE_FORM)(state),
		accountsPrimary: accounts.filter(account =>
			!account.IsClosed &&
			!account.IsDemo &&
			!account.IsPaper &&
			account.Holders.some(h => h.IsPrimary && h.Guid && h.Guid.toLowerCase() === userGuid)),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		hideConfirmationModal: () => dispatch(profile.actions.confirmProfileFormHideAndRedirect()),
		checkSignature: () => dispatch(signature.actions.collectSignaturesProcessStart()),
		getHolderProfiles: () => dispatch(profile.actions.getHolderProfiles.request()),
	},
});

class ConfirmProfile extends React.Component {

	static touchVerifiableFields(props) {
		props.touch('WorksForAnotherBroker');
		props.touch('HasChangesInBenOwners');

		props.accountsPrimary.forEach((accPrimary) => {
			const tradeCode = accPrimary.TradeCode;
			props.touch(`HasJointOwnersChanges${tradeCode}`);

			['ExpectedAnnualTotalOfDeposits', 'ExpectedAnnualTotalOfWithdrawals'].forEach((fieldName) => {
				props.touch(`${fieldName}${tradeCode}`);
			});
		});
	}

	static validate(values, props) {
		const errors = {};

		if (!values.WorksForAnotherBroker) {
			errors.WorksForAnotherBroker = 'Required';
		}
		if (values.WorksForAnotherBroker === 'Yes' && !values.IndAssociatedPerson) {
			errors.IndAssociatedPerson = 'Required';
		}
		if (props.profile && props.profile.Type === 'Institutional') {
			if (!values.HasChangesInBenOwners) {
				errors.HasChangesInBenOwners = 'Required';
			}
			if (values.HasChangesInBenOwners === 'Yes' && !values.ChangesInBenOwners) {
				errors.ChangesInBenOwners = 'Required';
			}
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < props.accountsPrimary.length; i++) {
				const tradeCode = props.accountsPrimary[i].TradeCode;
				const hasJointOwnersChanges = `HasJointOwnersChanges${tradeCode}`;
				const jointOwnersChanges = `JointOwnersChanges${tradeCode}`;
				if (!values[hasJointOwnersChanges]) {
					errors[hasJointOwnersChanges] = 'Required';
				}
				if (values[hasJointOwnersChanges] === 'Yes' && !values[jointOwnersChanges]) {
					errors[jointOwnersChanges] = 'Required';
				}
			}
		}
		props.accountsPrimary.forEach((accPrimary) => {
			const tradeCode = accPrimary.TradeCode;
			['ExpectedAnnualTotalOfDeposits', 'ExpectedAnnualTotalOfWithdrawals'].forEach((f) => {
				const fieldName = `${f}${tradeCode}`;
				if (!values[fieldName]) {
					errors[fieldName] = 'Required';
				}
			});
		});
	
		return errors;
	}
	componentDidMount() {
		const { actions, holders, profile: { NeedUpdatePersonalInfo } } = this.props;
		if (!NeedUpdatePersonalInfo) {
			this.handleCloseConfirmation();
		}

		if (!holders.submitting && !holders.loaded) {
			actions.getHolderProfiles();
		}

		// Нужно "потрогать" валидируемые на форме, т.к. нетронутые не подлежат валидации.
		ConfirmProfile.touchVerifiableFields(this.props);
	}
	handleCloseConfirmation = () => {
		this.props.actions.hideConfirmationModal();
	};

	handleSubmitButtonClick = (e) => {
		if (!this.props.hasSignes) {
			e.preventDefault();
			this.props.actions.checkSignature();
		}
	}
	// eslint-disable-next-line no-unused-vars
	createSubmitHandleAction = (values, dispatch, props) => {
		const newValue = {
			IndAssociatedPerson: values.WorksForAnotherBroker === 'Yes' ? values.IndAssociatedPerson : undefined,
			ChangesInBenOwners: values.HasChangesInBenOwners === 'Yes' ? values.ChangesInBenOwners : undefined,
			JointOwnersChanges: props.accountsPrimary.map(account =>
				(values[`HasJointOwnersChanges${account.TradeCode}`] === 'Yes' ?
					`${account.TradeCode} - ${values[`JointOwnersChanges${account.TradeCode}`]}` : undefined))
				.filter(x => !!x),
			Accounts: props.accountsPrimary.map((account) => {
				const aProductsExpectedToBeTraded = values[`ProductsExpectedToBeTraded${account.TradeCode}`];
				return {
					ClearingNumber: account.TradeCode,
					ExpectedAnnualTotalOfDeposits: values[`ExpectedAnnualTotalOfDeposits${account.TradeCode}`],
					ExpectedAnnualTotalOfWithdrawals: values[`ExpectedAnnualTotalOfWithdrawals${account.TradeCode}`],
					ProductsExpectedToBeTraded: aProductsExpectedToBeTraded && aProductsExpectedToBeTraded.filter(x => !!x).join(', '),
				};
			}),
		};
		profile.actions.confirmProfileFormSubmitHandler(newValue, dispatch, props);
	}

	render() {
		const {
			classes,
			handleSubmit,
			loading,
			submitting,
			submitSucceeded,
			invalid,
			form,
			allFormValues,
			profile: { UpdateDate },
		} = this.props;

		// валидирует также поля в скрытых(свернутых) разделах формы
		const hiddenFieldInvalid = Object.keys(ConfirmProfile.validate(allFormValues || {}, this.props)).length > 0;

		return (
			<div>
				{submitSucceeded ?
					<Modal title="" onClose={this.handleCloseConfirmation}>
						<SuccessMessage
							onClick={this.handleCloseConfirmation}
							text="Thank you for your confirmation. Your personal data will be reviewed."
						/>
					</Modal>
					: (
						<form onSubmit={handleSubmit(this.createSubmitHandleAction)}>
							<div>
								<div className={classes.content} >
									<div className={` ${classes.header} `}>
										Confirm Personal Info
									</div>
									<div className={classes.subHeader}>Listed below, please find your account information as
										you completed it through the online application
										{!loading && UpdateDate && moment(UpdateDate).year() > 1900 &&
											` on ${moment(UpdateDate).format('MM/DD/YYYY')}`
										}.
										Please review and confirm the accuracy at the bottom of the page.
									</div>
								</div>
								<ProgressContainer submitting={loading}>
									<ConfirmProfileNavigation form={form} />
									<div className={classes.button}>
										<Button
											fullWidth
											type="submit"
											onClick={this.handleSubmitButtonClick}
											variant="contained"
											color="primary"
											disabled={submitting || invalid || hiddenFieldInvalid}
										>
											{submitting ?
												<CircularProgress
													style={{ color: '#1a96ff' }}
													size={18}
												/>
												: 'Confirm'
											}
										</Button>
									</div>
								</ProgressContainer>
							</div>
						</form>
					)}
			</div>);
	}
}

ConfirmProfile.propTypes = {
	classes: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	form: PropTypes.string.isRequired,
};

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CONFIRM_PROFILE_FORM,
		validate: ConfirmProfile.validate,
		// Фикс бага когда после дерегистрации компонента, форма забывала про него и его валидацию
		// https://lightrun.com/answers/redux-form-redux-form-validation-is-lost-when-unregistering-then-re-registering-fields-with-same-name
		shouldError: () => true
	}),
)(ConfirmProfile);
