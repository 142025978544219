import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { env } from '@config';
import { profile, signature, userAccounts } from '@redux';
import PropTypes from 'prop-types';
import {
	BrokenEmailAlert,
	MarginTypeAlert,
	MarketDataAlert,
	PaperDeliveryAlert,
	SignatureAlert,
	TwoFactorAlert,
} from './components';

const styles = theme => ({
	root: {
		margin: '24px 74px',
		padding: '10px 16px',
		backgroundColor: theme.palette.color.secondary.limitOrder,
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0',
			padding: '10px 16px',
		},
	},
	content: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	actions: {
	},
	button: {
		color: theme.typography.color.link,
	},
});


const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
	HasSignes: signature.selectors.HasSignes(state),
	hasOpenAccount: userAccounts.selectors.hasOpenAccount(state),
});


class Alerts extends React.Component {
	render() {
		const {
			HasSignes,
			profile: {
				HasBrokenEmail,
				HasPaperDelivery,
				PendingOrders,
				Documents,
				HasMarketDataDisabled,
				submitting,
			},
			hasOpenAccount,
		} = this.props;

		const hasThirdPartyMarketDataConsent = Documents.some(doc => doc.DocTypeExt === 'third_party_md');

		return (
			<div>
				{env.WEBCAB_3534_checkBrokenEmail && HasBrokenEmail &&
					<BrokenEmailAlert />
				}
				{hasOpenAccount && !HasSignes &&
					<SignatureAlert />
				}
				{env.WEBCAB_3520_emailDelivery && HasPaperDelivery && PendingOrders && !PendingOrders.DeliveryType &&
					<PaperDeliveryAlert />
				}
				<MarginTypeAlert />
				<TwoFactorAlert />
				{!HasMarketDataDisabled && hasOpenAccount && env.WEBCAB_5142_marketData_subscriptions_enable &&
					<MarketDataAlert />
				}
			</div>
		);
	}
}

Alerts.propTypes = {
	HasSignes: PropTypes.bool.isRequired,
	profile: PropTypes.object.isRequired,
	hasOpenAccount: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps),
)(Alerts);
