import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { constants } from '@config';
import { A, Button, FormCheckbox, Modal, SuccessMessage } from '@components';
import { ModalPopups } from '@enums';
import { profile } from '@redux';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	text: {
		paddingTop: '12px',
		paddingBottom: '50px',
		textAlign: 'justify',
	},
	checkbox: {
		paddingLeft: '24px',
	},
	warning: {
		backgroundColor: '#f9d113',
		padding: '12px 16px',
		borderRadius: '4px',
	},
	button: {
		paddingTop: '12px',
	},
});


const DELIVERY_TYPE_CHANGE_FORM_ID = 'DeliveryTypeChangeForm';
const formSelector = formValueSelector(DELIVERY_TYPE_CHANGE_FORM_ID);

const mapStateToProps = (state, props) => {
	const defaultValue = profile.selectors.profile(state).HasPaperDelivery;
	return {
		initialValues: {
			HasFullyPaperless: profile.selectors.forceCheckPaperless(state) || !defaultValue,
		},
		email: profile.selectors.profile(state).PersonalInformation.Email,
		hasFullyPaperlessDefault: !defaultValue,
		hasFullyPaperlessValue: formSelector(state, 'HasFullyPaperless'),
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		showChangeEmailModal: () => dispatch(profile.actions.showChangeEmailModal()),
	},
});

class ChangeDeliveryTypeModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (values.HasFullyPaperless) {
			if (!values.Email) {
				errors.Email = 'required';
			} else if (!(constants.regExpEmail.test(values.Email))) {
				errors.Email = 'Invalid Format';
			}
		}

		return errors;
	}

	constructor(props) {
		super(props);
		this.state = {
			hasOpenDisclosures: false,
		};
	}

	toggleDisclosures = () => {
		this.setState({ hasOpenDisclosures: !this.state.hasOpenDisclosures });
	}

	render() {
		const {
			classes,
			onClose,
			actions,
			email,
			hasFullyPaperlessDefault,
			hasFullyPaperlessValue,
			invalid,
			submitting,
			submitSucceeded,
			handleSubmit,
		} = this.props;

		return (
			<Modal
				title="Document delivery"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.CHANGE_DELIVERY_TYPE}`}
			>
				{submitSucceeded ?
					<SuccessMessage
						onClick={onClose}
						text={
							<p>
								Your request was received.<br />
								Our manager will get back to you when document&apos;s delivery type is changed.
							</p>
						}
						buttonText="Back To Account"
					/>
					:
					<div>
						<form
							onSubmit={handleSubmit(profile.actions.changeDeliveryTypeFormSubmitHandler)}
							className={classes.root}
						>
							<div className={classes.checkbox}>
								<Field
									component={FormCheckbox}
									name="HasFullyPaperless"
									label="Fully Paperless"
									onChange={this.changeDeliveryType}
								/>
							</div>
							{hasFullyPaperlessValue ?
								<div>
									<p>
										Go paperless! Select &quot;Fully paperless&quot; and automatically mark all documents
										for electronic delivery. Documents will be sent to the email <b>{email}</b> from your profile.
									</p>
									<p>
										If you would like to change the email please proceed&nbsp;
										<A onClick={actions.showChangeEmailModal}>here</A>.
									</p>
								</div>
								:
								<p className={classes.warning}>
									Note: If you proceed to use the physical mail there will be a charge per item for delivery
									within the US and a charge for international delivery.
								</p>
							}
							<div className={classes.button}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									disabled={invalid || submitting || (hasFullyPaperlessDefault === hasFullyPaperlessValue)}
								>
									{submitting ?
										<CircularProgress
											style={{ color: '#1a96ff' }}
											size={18}
										/>
										: 'Apply'
									}
								</Button>
							</div>
						</form>
						<div className={classes.text}>
							<p>
								Select &quot;Fully paperless&quot; and automatically mark all documents (listed below)
								for electronic delivery.
							</p>
							<p>
								Statements: Statements provide account information including transactions, balances,
								and securities positions. Monthly statements are available electronically or via mail.
								However if you select physical mail there will be a $5 charge per item for delivery
								within the US and a $5 charge for international delivery.
							</p>
							<p>
								Legal Documents: Legal documents may include updated customer agreements, privacy policies,
								and but not limited	to additional disclosures. If you select physical mail there will
								be a $5 charge per item for delivery within the US and a $5 charge for international delivery.
							</p>
							<p>
								Trade Confirmations: Trade confirmations provide a record of successfully executed trades. They are legal
								documents required by industry regulation. Trade confirmations are available electronically or via mail.
								However if you select physical mail there will be a $5 charge per item for delivery within the US
								and a $5 charge for international delivery.
							</p>
							<p>
								Tax documents: Tax documents can include IRS Consolidated Form 1099 for proceeds, dividends, and interest;
								Forms 1099R and 5498 for retirement accounts; and possibly form 1042S for foreign clients.
							</p>
							<A onClick={this.toggleDisclosures}>
								<h2>
									<span style={{ marginRight: '10px' }}>{this.state.hasOpenDisclosures ? '-' : '+'}</span>
									Paperless Disclosures
								</h2>
							</A>
							{this.state.hasOpenDisclosures &&
								<div>
									<p>
										This Paperless Delivery E-Sign Disclosure (Disclosure) applies to the current selected account you
										have with Lime Trading for which you have selected &quot;Paperless Delivery&quot; in the
										&quot;Document Delivery&quot; page.
									</p>
									<p>
										As used in this Disclosure, &quot;Communication&quot; means any Account periodic statement,
										trade confirm, tax document, agreement, disclosure, notice, or other information related to
										your account, including but not limited to information that we are required by law to provide
										to you in writing.
									</p>
									<p>
										Scope of Communications to Be Provided in Electronic Form. You have the option to select
										&quot;Paperless&quot; with respect to an Account you have with Lime Trading, you agree that we may
										provide you with any and all Communications in electronic format, and that we may discontinue
										sending	paper Communications to you, unless and until you withdraw your consent as described below.
										Your consent to receive electronic communications and transactions includes, but is not limited to:
									</p>
									<ul>
										<li>​​All legal and regulatory disclosures and communications associated with your account</li>
										<li>​Periodic statements for your account</li>
										<li>​Trade Confirmations</li>
										<li>​​Tax Documents</li>
										<li>
											Notices or disclosures about a change in the terms of your Account or associated documents
										</li>
										<li>​​Privacy policies and notices</li>
									</ul>
									<p>
										Method of Providing Communications to You in Electronic Form. All Communications that we provide to
										you in electronic form will be provided via e-mail, link to a website where you may find the
										communication and or by accessing your Lime Trading client cabinet where we will send to you at the
										time the information is available.
									</p>
									<p>
										Withdrawing Consent. You may withdraw your consent to receive Communications in electronic form for
										any of your accounts by changing your preferences within the “Documents & Settings” page. At our
										will, we may treat your provision of an invalid e-mail address, e-mail bounce, or the subsequent
										malfunction of a previously	valid e-mail address, as a withdrawal of your consent to receive
										Electronic Communications. We will then, going forward send documents via physical mail until a
										functional e-mail address is on file. Lime Trading will provide the customer with a 30 day notice
										to confirm their new e-mail address and or update delivery information before a physical mail
										delivery fee is charged. We will not impose a fee to process the withdrawal of your consent to
										receive Electronic Communications. Any withdrawal of your consent to receive electronic
										Communications will be effective only after we have a reasonable period of time to process your
										withdrawal.
									</p>
									<p>
										How to Update Your Records. It is your responsibility to provide us with true, accurate and complete
										e-mail address, contact, and other information related to this Disclosure and your Account and to
										maintain and update promptly any changes in this information. You can update information (such as
										your e-mail address) within your client cabinet.
									</p>
									<p>​​By selecting the box you consent to the terms above.</p>
								</div>
							}
						</div>
					</div>
				}
			</Modal>
		);
	}
}

ChangeDeliveryTypeModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	actions: PropTypes.object.isRequired,
	email: PropTypes.string.isRequired,
	hasFullyPaperlessDefault: PropTypes.bool.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: DELIVERY_TYPE_CHANGE_FORM_ID,
		validate: ChangeDeliveryTypeModal.validate,
	}),
)(ChangeDeliveryTypeModal);
