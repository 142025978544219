/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
	profile,
	userAccounts,
	signature,
} from '@redux';
import compose from 'recompose/compose';
import {
	ProgressContainer,
	Button,
} from '@components';
import {
	PersonalInformation,
	InvestmentExpirience,
	InvestmentProfilePanel,
	EmploymentPanel,
	AccountInformation,
	PersonalInformationQuestion,
	InstitutionalQuestion,
} from './components';


const styles = theme => ({
	header: {
		...theme.typography.style.headline1,
		padding: '8px 0',
	},
	subHeader: {
		...theme.typography.style.title,
		color: theme.typography.color.secondary,
	},
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	blockTitle: {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
		padding: '10px 0',
		'& > span': {
			marginLeft: '60px',
			whiteSpace: 'nowrap',
		},
	},
	editable: {
		display: 'flex',
		flexDirection: 'row',
	},
	editItem: {
		display: 'flex',
		padding: '24px 32px 12px 0',
		textAlign: 'right',
		fontSize: '14px',
	},
	editValue: {
		flexGrow: 2,
	},
	editValuePhone: {
		'& div:first-child': {
			display: 'block!important',
		},
	},
	navigationDiv: {
		marginTop: '20px',
		display: 'flex',
		justifyContent: 'center',
		'& > button': {
			margin: '0px 10px',
			padding: '5px',
			width: '120px',
		},
	},
	employmentPanel: {
		marginLeft: '24px',
	},
	accountInformation: {
		'& $noLeftMarginField': {
			marginLeft: '24px',
		},
		'& $preFieldLabel': {
			marginLeft: '24px',
		},
	},
	noLeftMarginField: {},
	preFieldLabel: {},
});

const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
	accounts: userAccounts.selectors.accounts(state),
	userInfo: userAccounts.selectors.userInfo(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		showChangeInvestmentExperienceModal: () => {
			const changeAction = profile.actions.showChangeInvestmentExperienceModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
		showChangeInvestmentProfileModal: () => {
			const changeAction = profile.actions.showChangeInvestmentProfileModal();
			dispatch(signature.actions.collectSignaturesProcessStart(changeAction));
		},
	},
});

class ConfirmProfileNavigation extends React.Component {
	constructor(props) {
		super(props);

		this.components = new Map();

		this.state = {
			index: 0,
		};

		this.composeComponents(props);
	}

	getComponent = () => this.components.get(this.state.index)()

	composeComponents = (props) => {
		const {
			classes,
			actions,
			profile: {
				Guid,
				ClientId,
				InvestmentProfile,
				EmploymentProfile,
				Experiences,
				PendingOrders,
				Type: clientType,
			},
			form,
			accounts,
			userInfo,
		} = props;

		const userGuid = userInfo.Guid.toLowerCase();
		const filteredAccounts = accounts.filter(account =>
			!account.IsClosed &&
			!account.IsDemo &&
			!account.IsPaper &&
			account.Holders.some(h => h.IsPrimary && h.Guid && h.Guid.toLowerCase() === userGuid));

		this.components.set(0, () =>
			(<PersonalInformation
				classes={classes}
			/>));

		let index = 1;
		for (index; index <= filteredAccounts.length; index++) {
			const account = filteredAccounts[index - 1];
			const idkey = `${account.TradeCode}@${account.ClearingFirm}@${index}`;

			this.components.set(index, () => (
				<div key={idkey} className={classes.accountInformation}>
					<div className={classes.blockTitle} >
						{`Account Information - ${account.TradeCode}`}
						<AccountInformation
							classes={classes}
							account={account}
							form={form}
						/>
					</div>
				</div>));
		}

		this.components.set(index++, () => (
			<EmploymentPanel
				profile={{
					Guid,
					ClientId,
					EmploymentProfile,
					PendingOrders,
				}}
				classes={classes}
			/>));
		this.components.set(index++, () => (
			<InvestmentProfilePanel
				profile={{
					InvestmentProfile,
					PendingOrders,
				}}
				classes={classes}
				actions={actions}
			/>));
		this.components.set(index++, () => (
			<InvestmentExpirience
				profile={{
					PendingOrders,
					Experiences,
				}}
				classes={classes}
				actions={actions}
			/>));
		this.components.set(index++, () => (
			<PersonalInformationQuestion
				classes={classes}
				form={form}
			/>));

		if (clientType === 'Institutional') {
			this.components.set(index++, () => (
				<InstitutionalQuestion
					classes={classes}
					form={form}
				/>));
		}
	}

	backClick = () => {
		if (this.state.index > 0) {
			this.setState(state => ({
				index: state.index - 1,
			}));
		}
	}

	nextClick = () => {
		if (this.state.index < this.components.size - 1) {
			this.setState(state => ({
				index: state.index + 1,
			}));
		}
	}

	render() {
		const {
			classes,
			profile: {
				submitting,
			},
		} = this.props;

		return (
			<div className={classes.content}>
				<ProgressContainer submitting={submitting} display="left" disableCheckChildren>
					<React.Fragment>
						<div>
							{ this.getComponent() }
						</div>
						<div className={classes.navigationDiv}>
							{
								this.state.index > 0 &&
								<Button
									type="button"
									onClick={this.backClick}
									variant="contained"
									color="primary"
								>
									Back
								</Button>
							}
							{
								this.state.index !== this.components.size - 1 &&
								<Button
									type="button"
									onClick={this.nextClick}
									variant="contained"
									color="primary"
								>
									Next
								</Button>
							}

						</div>
					</React.Fragment>
				</ProgressContainer>
			</div>
		);
	}
}

ConfirmProfileNavigation.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(ConfirmProfileNavigation);
