import i18n from 'i18next';

const JsonRegExpRuEnDigitsAndPunctuationOnly = '[a-zA-Zа-яА-ЯёЁ]';
const JsonRegExpZhEnDigitsAndPunctuationOnly = '[a-zA-Z\\u4e00-\\u9fff]';
const JsonRegExpEnDigitsAndPunctuationOnly = '[a-zA-Z]';
const orSigns = pattern => '^(?:' + pattern + '|[\\s\\d!"#№$%&\'()*+,-.\\\\/:;<=>?@[\\^_`{|}~«»\\r\\n]|\\])*$';
const RegExpEnRuZhDigitsAndPunctuationOnly = {
	en: {
		pattern: JsonRegExpEnDigitsAndPunctuationOnly,
		errorMessage: 'shared_allowed_only_latin_letters',
		isDefault: true,
	},
	ru: {
		pattern: JsonRegExpRuEnDigitsAndPunctuationOnly,
		errorMessage: 'shared_allowed_only_russianor_latin_letters',
	},
	zh: {
		pattern: JsonRegExpZhEnDigitsAndPunctuationOnly,
		errorMessage: 'shared_allowed_only_chinese_or_latin_letters',
	},
};

/**
 * Возвращает перевод по дикворду
 * @id дикворд;
 * @defaultValue значение по умолчанию если не найен перевод;
 * @options Значения для параметризованного дикворда;
 * @showDicwordsKeys Признак необходимости отображения кода дикворда
 * Внутри компонентов лучше использовать useTranslate, т.к.эта функция не отслеживает изменения стейта
 */
function translate(
	id,
	defaultValue,
	options = null,
) {
	const { showDicwordsKeys } = window.store.getState().app;
	const dw = showDicwordsKeys ? `[[${id}]] ` : '';
	// пока идет инициализация возвращается null, поэтому нужно этом случае принудительно подставлять дефолтное значение.
	// после инициализации t сама возвращает по дефолту id,  поэтому используется defaultValue в опциях.
	return dw + (i18n.t(id, { ...(options || {}), defaultValue }) || defaultValue || id);
}

module.exports = {
	checkLettersForLang(str, lang) {
		const rule = RegExpEnRuZhDigitsAndPunctuationOnly[lang || 'en'] || RegExpEnRuZhDigitsAndPunctuationOnly.en;
		const re = new RegExp(orSigns(rule.pattern), 'gi');
		return re.test(str) ? undefined : rule.errorMessage;
	},
	translate,
};

