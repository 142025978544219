import { SubmissionError } from 'redux-form';
import { all, fork, select, take, takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import moment from 'moment';
import {
	BaseGetSagaHandler,
	callApi,
	linkedAccounts,
	notifications,
	profile,
	signature,
	snackbar,
	userAccounts,
} from '@redux';
import {
	cancelOrder,
	changeMt5Password,
	getAccountCharts,
	getAccounts,
	getAccountInfo,
	getAccountPortfolio,
	getCorporateActions,
	getOldCorporateActions,
	getTradeSystemCredentials,
	getTransactionHistory,
	getMoneyTransactionHistory,
	getNonTradingOrdersHistory,
	getUserInfo,
	refreshReportsAndConfirmations,
	upgradeMarginType,
	upgradeOptionLevel,
	addDemoAccount,
	resetDemoAccount,
	generateSecondAccountVisionAgreement,
	openAdditionalAccount,
} from '@api';
import { constants, env } from '@config';
import { ModalPopups } from '@enums';
import { addHashToUrl, cabinetLog, captureException } from '@global';
import signatureActions from '../signature/actions';
import actions from './actions';
import selectors from './selectors';


function* getUserAccountsRequestSaga() {
	try {
		const response = yield callApi(getAccounts);
		if (!response.data.Success) {
			yield put(actions.getAccountsFailure(response.data.Errors[0]));
			// if (response.data.Errors[0].Code === 'Unauthorized') {
			// 	return false;
			// }
			// throw (response.data.Errors[0].Message);
		} else {
			yield put(actions.getAccountsSuccess(response.data.Accounts));
			yield put(actions.getUserInfo.success({ response: response.data }));
		}
		return true;
	} catch (error) {
		captureException('getUserAccountsRequestSaga', error);
		yield put(actions.getAccountsFailure(error.message));
		console.error(error);
	}
}

function* setCurrentAccountSaga({ payload: { account: { TradeCode, ClearingFirm } } }) {
	localStorage.setItem('curacc', TradeCode);

	yield put(actions.getCurrentAccountBaseDataRequest({ tradeCode: TradeCode, clearerFirm: ClearingFirm }));

	const startDate = new Date(moment().year(), moment().month() - 2, moment().date());
	const endDate = new Date();
	const endDateCorporateActions = new Date(moment().year() + 1, moment().month(), moment().date());

	if (env.firm === constants.LIME_LME) {
		yield put(actions.getMoneyTransactionsHistory.request({
			TradeCode,
			ClearerFirm: ClearingFirm,
			StartDate: moment(startDate).format('YYYYMMDD'),
			EndDate: moment(endDate).format('YYYYMMDD'),
			Page: 1,
		}));

		yield put(actions.getNonTradingOrdersHistory.request({
			TradeCode,
			ClearerFirm: ClearingFirm,
			StartDate: moment(startDate).format('YYYYMMDD'),
			EndDate: moment(endDate).format('YYYYMMDD'),
			Page: 1,
		}));
	} else {
		yield put(actions.getTransactionsHistory.request({
			TradeCode,
			ClearerFirm: ClearingFirm,
			StartDate: moment(startDate).format('YYYYMMDD'),
			EndDate: moment(endDate).format('YYYYMMDD'),
			Page: 1,
		}));
	}

	if (env.firm === constants.LIME_FIN) {
		yield put(actions.getAccountCharts.request({ tradeCode: TradeCode, clearerFirm: ClearingFirm }));

		yield put(actions.getCorporateActions.request({
			tradeCode: TradeCode,
			startDate: moment(startDate).format('YYYYMMDD'),
			endDate: moment(endDateCorporateActions).format('YYYYMMDD'),
			page: 1,
		}));

		if (env.WEBCAB_4449_allow_old_corp_actions) {
			yield put(actions.getOldCorporateActions.request({
				tradeCode: TradeCode,
				startDate: moment(startDate).format('YYYYMMDD'),
				endDate: moment(endDateCorporateActions).format('YYYYMMDD'),
				page: 1,
			}));
		}

		// WEBCAB-4989 блок Gainers & Loosers удален
		// yield put(analytics.actions.getGainersAndLosersRequest());
		yield put(notifications.actions.getNotificationsRequest(TradeCode));
	}
}

function* GetCurrentAccountBaseDataSaga({ payload }) {
	if (env.firm === constants.LIME_NSR) {
		yield put(actions.getAccountPortfolio.request({ tradeCode: payload.tradeCode }));
		yield put(actions.getAccountInfoRequest(payload));
		yield put(linkedAccounts.actions.getLinkedAccounts.request({ tradeCode: payload.tradeCode }));

		yield all([
			take([actions.getAccountPortfolio.SUCCESS, actions.getAccountPortfolio.FAILURE]),
			take([actions.GET_ACCOUNT_INFO_SUCCESS, actions.GET_ACCOUNT_INFO_FAILURE]),
			take([linkedAccounts.actions.getLinkedAccounts.SUCCESS, linkedAccounts.actions.getLinkedAccounts.FAILURE]),
		]);

		// fictitious signatures checking, set true force to avoid checks
		yield put(signatureActions.saveSignatureSuccess());
		yield put(actions.getCurrentAccountBaseDataSuccess());

		return;
	}

	if ([constants.LIME_INT, constants.LIME_LME].some(x => x === env.firm)) {
		yield put(actions.getAccountInfoRequest(payload));
		yield put(linkedAccounts.actions.getLinkedAccounts.request({ tradeCode: payload.tradeCode }));
		yield all([
			take([actions.GET_ACCOUNT_INFO_SUCCESS, actions.GET_ACCOUNT_INFO_FAILURE]),
			take([linkedAccounts.actions.getLinkedAccounts.SUCCESS, linkedAccounts.actions.getLinkedAccounts.FAILURE]),
		]);

		// fictitious signatures checking, set true force to avoid checks
		yield put(signatureActions.saveSignatureSuccess());

		const accountInfo = yield select(userAccounts.selectors.accountInfo);
		yield put(actions.setCurrentAccountBaseParams({
			baseParams: accountInfo.MoneyInfo,
		}));


		yield put(actions.getCurrentAccountBaseDataSuccess());
		yield put(notifications.actions.getNotificationsRequest(payload.tradeCode));
		return;
	}

	yield put(actions.getAccountPortfolio.request({ tradeCode: payload.tradeCode }));
	yield put(actions.getAccountInfoRequest(payload));
	yield put(linkedAccounts.actions.getLinkedAccounts.request({ tradeCode: payload.tradeCode }));
	yield put(signature.actions.checkSignatures.request(payload));

	yield all([
		take([actions.getAccountPortfolio.SUCCESS, actions.getAccountPortfolio.FAILURE]),
		take([actions.GET_ACCOUNT_INFO_SUCCESS, actions.GET_ACCOUNT_INFO_FAILURE]),
		take([linkedAccounts.actions.getLinkedAccounts.SUCCESS, linkedAccounts.actions.getLinkedAccounts.FAILURE]),
		take([signature.actions.checkSignatures.SUCCESS, signature.actions.checkSignatures.FAILURE]),
	]);

	yield put(actions.getCurrentAccountBaseDataSuccess());
}


function* getAccountChartsRequestSaga({ payload }) {
	try {
		const response = yield callApi(getAccountCharts, payload);
		cabinetLog('charts', response);
		yield put(actions.getAccountCharts.success(response.data));
	} catch (error) {
		captureException('getAccountChartsRequestSaga', error);
		yield put(actions.getAccountCharts.failure(error));
	}
}

function* getAccountInfoRequestSaga({ payload: { account } }) {
	try {
		const response = yield callApi(getAccountInfo, account);
		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get Account Info',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.getAccountInfoFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.getAccountInfoSuccess(response.data));
		}
	} catch (error) {
		captureException('getAccountInfoRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get Account Info', text: error.message }));
		yield put(actions.getAccountInfoFailure(error.message));
		console.error(error);
	}
}


function* refreshReportsAndConfirmationsRequestSaga({ payload: { account } }) {
	try {
		let tradeAccount = account;
		if (!account || !account.tradeCode) {
			// set current account waiting
			yield all([
				// Reports and Confirmations required currentTradeAccount
				take(userAccounts.actions.SET_CURRENT_ACCOUNT),
			]);
			tradeAccount = yield select(userAccounts.selectors.currentTradeAccount);
		}
		if ([constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm)) {
			// refreshReportsAndConfirmations not used for Lime International, Lime Ltd and Lending Robot
			yield put(actions.refreshReportsAndConfirmationsSuccess({}));
			return;
		}

		const response = yield callApi(refreshReportsAndConfirmations, tradeAccount);
		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Get Reports',
				text: response.data.Errors[0].Message,
			}));
			yield put(actions.refreshReportsAndConfirmationsFailure(response.data.Errors[0].Message));
		} else {
			yield put(actions.refreshReportsAndConfirmationsSuccess(response.data));
		}
	} catch (error) {
		captureException('refreshReportsAndConfirmationsRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get Reports', text: error.message }));
		yield put(actions.refreshReportsAndConfirmationsFailure(error.message));
	}
}

function* openReportsPageSaga({ payload: { page, account } }) {
	yield* refreshReportsAndConfirmationsRequestSaga({ payload: { account } });
	const reports = yield select(selectors.reportsAndConfirmations);

	if (reports.Success && !reports.loading &&
		reports.Url &&
		!reports.Documents &&
		!reports.Transmissons
	) {
		window.open(reports.Url, '_blank');
	} else {
		yield put(push(page));
	}
}

function* cancelOrderRequestSaga({ payload: { onEndRequest, account, orderCode } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: cancelOrder,
		errorText: 'Cancel Order',
		handler: actions.cancelOrder,
		onEndRequest,
	}, { payload: { tradeCode: account.tradeCode, orderCode } });

	if (isSuccess) {
		const cashActivities = yield select(selectors.cashActivities);
		const {
			StartDate,
			EndDate,
		} = cashActivities.pagination;

		if (env.firm === constants.LIME_LME) {
			yield put(actions.getMoneyTransactionsHistory.request({
				TradeCode: account.tradeCode,
				ClearerFirm: account.clearerFirm,
				StartDate,
				EndDate,
				Page: 1,
			}));

			yield put(actions.getNonTradingOrdersHistory.request({
				TradeCode: account.tradeCode,
				ClearerFirm: account.clearerFirm,
				StartDate,
				EndDate,
				Page: 1,
			}));
		} else {
			yield put(actions.getTransactionsHistory.request({
				TradeCode: account.tradeCode,
				ClearerFirm: account.clearerFirm,
				StartDate,
				EndDate,
				Page: 1,
			}));
		}
	}
}

function* upgradeAccountFormSubmitSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: upgradeMarginType,
		errorText: 'Upgrade Margin Type',
		handler: actions.upgradeAccountFormSubmitHandler,
	}, { payload });

	if (isSuccess) {
		yield put(actions.getAccountInfoRequest({
			tradeCode: payload.TradeCode,
			clearerFirm: payload.Clearer,
		}));
	}
	if (payload.MustUpdateProfile) {
		yield put(profile.actions.getProfileRequest());
	}
}
function* showUpgradeAccountModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.UPGRADE_MARGIN_TYPE)));
}


function* upgradeOptionLevelFormSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: upgradeOptionLevel,
		errorText: 'Upgrade Option Level',
		handler: actions.upgradeOptionLevelForm,
	}, { payload });

	if (isSuccess) {
		yield put(actions.getAccountInfoRequest({
			tradeCode: payload.TradeCode,
			clearerFirm: payload.Clearer,
		}));
	}
	if (payload.MustUpdateProfile) {
		yield put(profile.actions.getProfileRequest());
	}
}
function* showUpgradeOptionLevelModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.UPGRADE_OPTION_LEVEL)));
}

function* getTradeSystemCredentialsSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: getTradeSystemCredentials,
		errorText: 'Get Trade System Credentials',
		handler: actions.getTradeSystemCredentials,
	}, { payload });

	if (isSuccess) {
		yield put(snackbar.actions.showMessage({
			title: 'Get Trade System Credentials',
			text: 'Successfully Submitted! We have sent you an email with login and password information. Please check your inbox.',
			type: 'success',
		}));
	}
}

function* changeMt5PasswordRequestSaga({ payload: { password, tradeCode, clearerFirm } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: changeMt5Password,
		errorText: 'Change MT5 Password',
		handler: actions.changeMt5Password,
	}, {
		payload: {
			newPassword: password,
			tradeCode,
			clearerFirm,
		},
	});
}

function* reloadTransactionHistory(tradeCode, clearerFirm) {
	// reload first page
	const cashActivities = yield select(selectors.cashActivities);
	let {
		StartDate,
		EndDate,
	} = cashActivities.pagination;
	if (env.firm === constants.LIME_LME) {
		yield put(actions.getMoneyTransactionsHistory.request({
			TradeCode: tradeCode,
			ClearerFirm: clearerFirm,
			StartDate,
			EndDate,
			Page: 1,
		}));

		const nonTraidingActivities = yield select(selectors.nonTraidingActivities);

		({ StartDate } = nonTraidingActivities.pagination);
		({ EndDate } = nonTraidingActivities.pagination);

		yield put(actions.getNonTradingOrdersHistory.request({
			TradeCode: tradeCode,
			ClearerFirm: clearerFirm,
			StartDate,
			EndDate,
			Page: 1,
		}));
	} else {
		yield put(actions.getTransactionsHistory.request({
			TradeCode: tradeCode,
			ClearerFirm: clearerFirm,
			StartDate,
			EndDate,
			Page: 1,
		}));
	}
}

function* addDemoAccountSaga() {
	try {
		const response = yield callApi(addDemoAccount);
		if (!response.data.Success) {
			yield put(snackbar.actions.showErrorMessage({
				title: 'Cannot add demo account',
				text: response.data.Errors[0],
			}));

			yield put(actions.addDemoAccount.failure(response.data.Errors[0]));
			return;
		}
	} catch (error) {
		captureException('addDemoAccountSaga', error);
		yield put(snackbar.actions.showErrorMessage({
			title: 'Cannot add demo account',
			text: error.message,
		}));

		yield put(actions.addDemoAccount.failure(error.message));

		console.error(error);
		return;
	}

	yield put(userAccounts.actions.getAccountsRequest());
	const accountAction = yield take([
		userAccounts.actions.GET_ACCOUNTS_SUCCESS,
		userAccounts.actions.GET_ACCOUNTS_FAILURE,
	]);

	if (accountAction.type === userAccounts.actions.GET_ACCOUNTS_FAILURE) {
		yield put(snackbar.actions.showErrorMessage({
			title: 'Demo account',
			text: 'Added demo account, but cannot reload accounts',
		}));
		return;
	}

	const accounts = yield select(userAccounts.selectors.accounts);
	const demoAccount = accounts.find(it => it.IsDemo || it.IsPaper);

	if (demoAccount) {
		yield put(userAccounts.actions.setCurrentAccount(demoAccount));
	}

	yield put(snackbar.actions.showMessage({
		title: 'Demo account',
		text: 'Successfully added demo account',
		type: 'success',
	}));
}

function* resetDemoAccountSaga({ payload }) {
	const tradeCode = yield select(userAccounts.selectors.currentAccount);
	const clearerFirm = yield select(userAccounts.selectors.currentClearer);

	const isSuccess = yield BaseGetSagaHandler(
		{
			apiMethod: resetDemoAccount,
			errorText: 'Cannot reset demo account',
			handler: actions.resetDemoAccountFormSubmit,
		},
		{
			payload: {
				clearer: clearerFirm,
				clearingNumber: tradeCode,
				setCashTo: payload.Amount,
				marginType: payload.MarginType,
				optionLevel: payload.OptionLevel,
			},
		},
	);

	if (isSuccess) {
		yield put(snackbar.actions.showMessage({
			title: 'Reset demo account',
			text: 'Successfully confirmed account reset',
			type: 'success',
		}));

		const startDate = new Date(moment().year(), moment().month() - 2, moment().date());
		const endDate = new Date();
		const endDateCorporateActions = new Date(moment().year() + 1, moment().month(), moment().date());

		yield put(actions.getAccountPortfolio.request({ tradeCode }));

		yield put(actions.getTransactionsHistory.request({
			tradeCode,
			ClearerFirm: clearerFirm,
			StartDate: moment(startDate).format('YYYYMMDD'),
			EndDate: moment(endDate).format('YYYYMMDD'),
			Page: 1,
		}));

		yield put(actions.getAccountCharts.request({ tradeCode, clearerFirm }));

		yield put(actions.getCorporateActions.request({
			tradeCode,
			startDate: moment(startDate).format('YYYYMMDD'),
			endDate: moment(endDateCorporateActions).format('YYYYMMDD'),
			page: 1,
		}));
		if (env.WEBCAB_4449_allow_old_corp_actions) {
			yield put(actions.getOldCorporateActions.request({
				tradeCode,
				startDate: moment(startDate).format('YYYYMMDD'),
				endDate: moment(endDateCorporateActions).format('YYYYMMDD'),
				page: 1,
			}));
		}
	}
}

function* showResetDemoAccountModalSaga() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.RESET_DEMO_ACCOUNT)));
}

function* showOpenSecondAccountModalSaga() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.OPEN_SECOND_ACCOUNT)));
}

function* secondAccountVisionAgreementSaga({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: generateSecondAccountVisionAgreement,
		errorText: 'Something went wrong',
		handler: actions.secondAccountVisionAgreement,
	}, { payload });
}

function* openAdditionalAccountSaga({ payload }) {
	try {
		const response = yield callApi(openAdditionalAccount, payload);
		if (!response.data.Success) {
			yield put(actions.openAdditionalAccount.failure(new SubmissionError({
				_error: response.data.Errors[0].Message,
			})));
		} else {
			yield put(actions.openAdditionalAccount.success());
		}
	} catch (error) {
		window.Raven.captureException(error);
		yield put(actions.openAdditionalAccount.failure(new SubmissionError({
			_error: error.message,
		})));
	}
}

// Watchers
function* watchGetUserAccountsRequest() {
	yield takeEvery(actions.GET_ACCOUNTS_REQUEST, getUserAccountsRequestSaga);
}

function* watchGetUserInfoRequest() {
	yield takeEvery(actions.getUserInfo.REQUEST, BaseGetSagaHandler, {
		apiMethod: getUserInfo,
		errorText: 'Get User Info',
		handler: actions.getUserInfo,
	});
}

function* watchSetCurrentAccount() {
	yield takeEvery(actions.SET_CURRENT_ACCOUNT, setCurrentAccountSaga);
}
function* watchGetCurrentAccountBaseData() {
	yield takeEvery(actions.GET_CURRENT_ACCOUNT_BASE_DATA_REQUEST, GetCurrentAccountBaseDataSaga);
}
function* watchGetAccountChartsRequest() {
	yield takeEvery(actions.getAccountCharts.REQUEST, getAccountChartsRequestSaga);
}
function* watchGetTransactionsHistoryRequest() {
	yield takeEvery(actions.getTransactionsHistory.REQUEST, BaseGetSagaHandler, {
		apiMethod: getTransactionHistory,
		errorText: 'Get Transactions History',
		handler: actions.getTransactionsHistory,
		defaults: { Page: 1, Type: 0, PageSize: 10 },
	});
}

function* watchGetMoneyTransactionsHistoryRequest() {
	yield takeEvery(actions.getMoneyTransactionsHistory.REQUEST, BaseGetSagaHandler, {
		apiMethod: getMoneyTransactionHistory,
		errorText: 'Get Transactions History',
		handler: actions.getMoneyTransactionsHistory,
		defaults: { Page: 1, Type: 0, PageSize: 10 },
	});
}

function* watchGetNonTradingOrdersHistoryRequest() {
	yield takeEvery(actions.getNonTradingOrdersHistory.REQUEST, BaseGetSagaHandler, {
		apiMethod: getNonTradingOrdersHistory,
		errorText: 'Get Non Transactions History',
		handler: actions.getNonTradingOrdersHistory,
		defaults: { Page: 1, Type: 0, PageSize: 10 },
	});
}

function* watchGetAccountPortfolioRequest() {
	yield takeEvery(actions.getAccountPortfolio.REQUEST, BaseGetSagaHandler, {
		apiMethod: getAccountPortfolio,
		errorText: 'Get Account Portfolio',
		handler: actions.getAccountPortfolio,
	});
}

function* watchGetTradeSystemCredentialsRequest() {
	yield takeEvery(actions.getTradeSystemCredentials.REQUEST, getTradeSystemCredentialsSaga);
}
function* watchRefreshReportsAndConfirmationsRequest() {
	yield takeEvery(actions.REFRESH_REPORTS_AND_CONFIRMATIONS_REQUEST, refreshReportsAndConfirmationsRequestSaga);
}
function* watchOpenReportsPage() {
	yield takeEvery(actions.OPEN_REPORTS_PAGE, openReportsPageSaga);
}
function* watchGetAccountInfoRequest() {
	yield takeEvery(actions.GET_ACCOUNT_INFO_REQUEST, getAccountInfoRequestSaga);
}
function* watchGetCorporateActionsRequest() {
	yield takeEvery(actions.getCorporateActions.REQUEST, BaseGetSagaHandler, {
		apiMethod: getCorporateActions,
		errorText: 'Get Corporate Actions',
		handler: actions.getCorporateActions,
		defaults: { page: 1, pageSize: 8, type: 'any' },
	});
}
function* watchGetOldCorporateActionsRequest() {
	yield takeEvery(actions.getOldCorporateActions.REQUEST, BaseGetSagaHandler, {
		apiMethod: getOldCorporateActions,
		errorText: 'Get Old Corporate Actions',
		handler: actions.getOldCorporateActions,
		defaults: { page: 1, pageSize: 8, type: 'any' },
	});
}
function* watchCancelOrderRequest() {
	yield takeEvery(actions.cancelOrder.REQUEST, cancelOrderRequestSaga);
}
function* watchUpgradeAccountFormSubmit() {
	yield takeEvery(actions.upgradeAccountFormSubmitHandler.REQUEST, upgradeAccountFormSubmitSaga);
}
function* watchShowUpgradeAccountModal() {
	yield takeEvery(actions.SHOW_UPGRADE_ACCOUNT_MODAL, showUpgradeAccountModal);
}
function* watchUpgradeOptionLevelForm() {
	yield takeEvery(actions.upgradeOptionLevelForm.REQUEST, upgradeOptionLevelFormSaga);
}
function* watchShowUpgradeOptionLevelModal() {
	yield takeEvery(actions.SHOW_UPGRADE_OPTION_LEVEL_MODAL, showUpgradeOptionLevelModal);
}
function* watchChangeMt5PasswordRequest() {
	yield takeEvery(actions.changeMt5Password.REQUEST, changeMt5PasswordRequestSaga);
}
function* watchaddDemoAccountRequest() {
	yield takeEvery(actions.addDemoAccount.REQUEST, addDemoAccountSaga);
}
function* watchResetDemoAccountRequest() {
	yield takeEvery(actions.resetDemoAccountFormSubmit.REQUEST, resetDemoAccountSaga);
}
function* watchShowResetDemoAccountModal() {
	yield takeEvery(actions.SHOW_RESET_DEMO_ACCOUNT_MODAL, showResetDemoAccountModalSaga);
}
function* watchShowOpenSecondAccountModal() {
	yield takeEvery(actions.SHOW_OPEN_SECOND_ACCOUNT_MODAL, showOpenSecondAccountModalSaga);
}

function* watchSecondAccountVisionAgreement() {
	yield takeEvery(actions.secondAccountVisionAgreement.REQUEST, secondAccountVisionAgreementSaga);
}

function* watchOpenAdditionalAccount() {
	yield takeEvery(actions.openAdditionalAccount.REQUEST, openAdditionalAccountSaga);
}

// Root saga
const rootSaga = [
	fork(watchSetCurrentAccount),
	fork(watchGetCurrentAccountBaseData),
	fork(watchGetAccountChartsRequest),
	fork(watchGetUserAccountsRequest),
	fork(watchGetUserInfoRequest),
	fork(watchGetAccountInfoRequest),
	fork(watchGetAccountPortfolioRequest),
	fork(watchGetTradeSystemCredentialsRequest),
	fork(watchRefreshReportsAndConfirmationsRequest),
	fork(watchOpenReportsPage),
	fork(watchGetTransactionsHistoryRequest),
	fork(watchGetMoneyTransactionsHistoryRequest),
	fork(watchGetNonTradingOrdersHistoryRequest),
	fork(watchGetCorporateActionsRequest),
	fork(watchGetOldCorporateActionsRequest),
	fork(watchCancelOrderRequest),
	fork(watchUpgradeAccountFormSubmit),
	fork(watchShowUpgradeAccountModal),
	fork(watchUpgradeOptionLevelForm),
	fork(watchShowUpgradeOptionLevelModal),
	fork(watchChangeMt5PasswordRequest),
	fork(watchaddDemoAccountRequest),
	fork(watchResetDemoAccountRequest),
	fork(watchShowResetDemoAccountModal),
	fork(watchShowOpenSecondAccountModal),
	fork(watchSecondAccountVisionAgreement),
	fork(watchOpenAdditionalAccount),
];

export default {
	rootSaga,
	getUserAccountsRequestSaga,
	reloadTransactionHistory,
};
