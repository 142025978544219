import { initTableData } from '../extensions';
import actions from './actions';
import auth from '../auth';

const initialState = {
	generateSubmitting: false,
	accountReports: {
		// ...initTableData('CreateDate', 'Desc'),
		Items: [],
		tradeCode: '',
		submitting: true,
		// Pagination: {
		// 	PageSize: 10,
		// 	Page: 0,
		// },
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.getBrokerReports.REQUEST:
		return {
			...state,
			accountReports: {
				...state.accountReports,
				submitting: true,
				error: false,
			},
		};
	case actions.getBrokerReports.SUCCESS:
		return {
			...state,
			accountReports: {
				// ...state.accountReports,
				submitting: false,
				error: false,
				Items: payload.response.Items,
				tradeCode: (payload.response.Items && payload.response.Items.length > 0) ? payload.response.Items[0].AccountCode : '',
			},
		};

	case actions.getBrokerReports.FAILURE:
		return {
			...state,
			accountReports: {
				// ...state.accountReports, // save prev sorting, pagination and filter
				submitting: false,
				error: true,
				Items: [],
				tradeCode: '',
			},
		};

	case actions.generateBrokerReport.REQUEST:
		return {
			...state,
			generateSubmitting: true,
		};
	case actions.generateBrokerReport.SUCCESS:
	case actions.generateBrokerReport.FAILURE:
		return {
			...state,
			generateSubmitting: false,
		};
	case auth.actions.LOGOUT_SUCCESS:
		return { ...initialState };
	default:
		return state;
	}
};
