import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ProgressContainer } from '@components';
import { constants, env } from '@config';
import { userAccounts } from '@redux';
import {
	TransactionsHistory,
	IndividualCashAccount,
	CorporateActions,
	CurrentHoldings,
	TransactionsHistoryLME,
} from './components';
import { J2TAccountBriefingPanel } from './components/IndividualCashAccount/J2TAccountBriefingPanel';
import { AccountBriefingPanelLME } from './components/IndividualCashAccount/AccountBriefingPanelLME';

const styles = theme => ({
	root: {
		border: '1px solid red',
	},
	content: {
		padding: '0 72px',
		[theme.breakpoints.down('xs')]: {
			padding: '0 27px',
		},
	},
	contentItem: {
		marginBottom: '30px',
	},
	endItem: {
		height: '30px',
	},
});

const mapStateToProps = state => ({
	loading: userAccounts.selectors.loading(state),
});

class MainContent extends React.Component {
	render() {
		const { classes, loading } = this.props;
		return (
			<ProgressContainer submitting={loading}>
				<div className={classes.content}>
					{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm) &&
						<React.Fragment>
							<div className={classes.contentItem}>
								{env.firm !== constants.LIME_LME ? <J2TAccountBriefingPanel /> : <AccountBriefingPanelLME />}
							</div>
							{env.firm !== constants.LIME_LME &&
								<div className={classes.contentItem}>
									<CurrentHoldings />
								</div>
							}
							<div className={classes.contentItem}>
								{env.firm === constants.LIME_LME
									? <TransactionsHistoryLME />
									: <TransactionsHistory />
								}
							</div>
						</React.Fragment>
					}
					{(env.firm === constants.LIME_FIN) &&
						<React.Fragment>
							<div className={classes.contentItem}>
								<IndividualCashAccount />
							</div>
							<div className={classes.contentItem}>
								<CurrentHoldings />
							</div>
							<div className={classes.contentItem}>
								<TransactionsHistory />
							</div>
							<div className={classes.contentItem}>
								<CorporateActions />
							</div>
							{env.WEBCAB_4449_allow_old_corp_actions &&
								<div className={classes.contentItem}>
									<CorporateActions isOld />
								</div>
							}
						</React.Fragment>
					}

					<div className={classes.endItem} />
				</div>
			</ProgressContainer>
		);
	}
}

MainContent.propTypes = {
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(MainContent);
