import { AccountStatuses } from '@enums';

const accounts = state => state.userAccounts.accounts.map(acc => ({
	...acc,
	DisplayFullName: acc.TradeCode,
}));

export default {
	accounts,
	platforms: state => state.userAccounts.accountInfo.Platforms,
	tariffs: state => state.userAccounts.accountInfo.Tariffs,
	accountInfo: state => state.userAccounts.accountInfo,
	fundingInfo: state => state.userAccounts.accountInfo.FundingInfo,
	currentTradeAccount: state => ({
		tradeCode: state.userAccounts.currentAccount,
		clearerFirm: state.userAccounts.currentClearer,
	}),
	currentAccount: state => state.userAccounts.currentAccount,
	currentClearer: state => state.userAccounts.currentClearer,
	currentAccountDisplayFullName: (state) => {
		const acc = accounts(state).find(a => a.TradeCode === state.userAccounts.currentAccount);
		return (acc && acc.DisplayFullName) || state.userAccounts.currentAccount;
	},
	selectedAccount: state => accounts(state).find(a => a.TradeCode === state.userAccounts.currentAccount),
	currentAccountData: state => state.userAccounts.currentAccountData,
	currentAccountObject: state => state.userAccounts.currentAccountObject,
	currentAccountIsDemo: state => (state.userAccounts.currentAccountObject && state.userAccounts.currentAccountObject.IsDemo),
	currentAccountIsPaper: state => (state.userAccounts.currentAccountObject && state.userAccounts.currentAccountObject.IsPaper),
	currentAccountStatus: state => state.userAccounts.accountInfo.Status,
	reportsAndConfirmations: state => state.userAccounts.reportsAndConfirmations,
	linkedAccounts: state => state.userAccounts.linkedAccounts,
	linkedAccountsSubmitting: state => state.userAccounts.linkedAccountsSubmitting,
	hasMaxTemplatesCount: state => state.userAccounts.hasMaxTemplatesCount,
	loading: state => state.userAccounts.submittingBaseData,
	cashActivities: state => state.userAccounts.cashActivities,
	nonTraidingActivities: state => state.userAccounts.nonTraidingActivities,
	charts: state => state.userAccounts.charts,
	userInfo: state => state.userAccounts.userInfo,
	corporateActions: state => state.userAccounts.corporateActions,
	oldCorporateActions: state => state.userAccounts.oldCorporateActions,
	upgradeMarginTypeData: state => state.userAccounts.upgradeMarginType,
	upgradeOptionLevelData: state => state.userAccounts.upgradeOptionLevel,
	getTradeSystemCredentialsRequest: state => state.userAccounts.tradeSystemCredentialsRequest,
	getPaperAccount: state => state.userAccounts.accounts && state.userAccounts.accounts.find(a => a.IsPaper && !a.IsClosed),
	getDemoAccount: state => state.userAccounts.accounts && state.userAccounts.accounts.find(a => a.IsDemo),
	anyActiveAccountExists: (state) => {
		const accs = accounts(state);
		return accs && accs.some(acc => !(acc.IsDemo || acc.IsPaper)
			&& (acc.Status === AccountStatuses.ACTIVE || acc.Status === AccountStatuses.RESTRICTED));
	},
	// Real means not demo or paper
	anyRealAccountExists: (state) => {
		const accs = accounts(state);
		return accs && accs.some(acc => !(acc.IsDemo || acc.IsPaper));
	},
	hasOpenAccount: state => state.userAccounts.accounts.some(acc => !acc.IsClosed),
	secondAccountAgreement: state => state.userAccounts.secondAccountAgreement,
};
