import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import {
	userAccounts,
	dashboard,
	linkedAccounts,
	// loginForm,
	agent,
	auth,
	depositFunds,
	etnaAuth,
	ipo,
	nontradingOrders,
	ordersCy,
	notifications,
	tradePlatforms,
	withdrawFunds,
	acatDeposit,
	signalR,
	snackbar,
	profile,
	reset,
	// promotions,
	signature,
	app,
	analytics,
	wallet,
} from './';

import reportReducer from './reports/reducer';

const reducers = {
	auth: auth.reducer,
	dashboard: dashboard.reducer,
	agent: agent.reducer,
	userAccounts: userAccounts.reducer,
	analytics: analytics.reducer,
	form: formReducer,
	ipo: ipo.reducer,
	linkedAccounts: linkedAccounts.reducer,
	// loginForm: loginForm.reducer,
	nontradingOrders: nontradingOrders.reducer,
	ordersCy: ordersCy.reducer,
	notifications: notifications.reducer,
	depositFunds: depositFunds.reducer,
	etnaAuth: etnaAuth.reducer,
	withdrawFunds: withdrawFunds.reducer,
	tradePlatforms: tradePlatforms.reducer,
	acatDeposit: acatDeposit.reducer,
	signalR: signalR.reducer,
	snackbar: snackbar.reducer,
	profile: profile.reducer,
	reset: reset.reducer,
	reports: reportReducer,
	// functionality was closed by task WEBCAB-3983
	// promotions: promotions.reducer,
	router: connectRouter,
	signature: signature.reducer,
	wallet: wallet.reducer,
	[app.CONFIG.STATE_FIELD_NAME]: app.reducer,
};

const composeSortedReducers = history => Object.keys(reducers).sort().reduce((acc, cur) => {
	let value = reducers[cur];
	if (cur === 'router') {
		value = value(history);
	}

	return ({ ...acc, [cur]: value });
}, {});

const createRootReducer = history => combineReducers(composeSortedReducers(history));
export default createRootReducer;
