import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { constants, env } from '@config';
import {
	Ticker,
	Quantity,
	CurrentPrice,
	MarketValue,
	Yield,
} from './components';

const styles = theme => ({
	table: {
		width: '100%',
		verticalAlign: 'top',
		borderCollapse: 'collapse',
		'& th': {
			...theme.typography.style.body,
			color: theme.typography.color.secondary,
			padding: '10px',
		},
		'& td': {
			padding: '10px',
		},
		'& tbody': {
			verticalAlign: 'top',
		},
	},
	tableRow: {
		borderBottom: '1px solid #deeaef',
		'& td': {
		},
	},
});


class CurrentHoldingsTable extends React.Component {
	render() {
		const {
			classes,
			positions,
		} = this.props;
		return (
			<table className={classes.table}>
				<thead>
					<tr>
						<th style={{ textAlign: 'left' }}>Ticker</th>
						<th style={{ textAlign: 'left' }}>Q-ty</th>
						<th style={{ textAlign: 'right' }}>Current price</th>
						<th style={{ textAlign: 'right' }}>
							{env.firm === constants.LIME_NSR ? 'Value' : 'Market value'}
						</th>
						<th style={{ textAlign: 'right' }}>Yield</th>
					</tr>
				</thead>
				<tbody>
					{positions.map((position, index) => (
						<tr className={classes.tableRow} key={index}>
							<td>
								<Ticker
									logoIcon={position.LogoIconUrl || ''}
									companyName={position.CompanyName}
									symbol={position.FriendlyTicker}
								/>
							</td>
							<td>
								<Quantity
									marketValuePercent={position.MarketValuePercent}
									quantity={position.Quantity}
								/>
							</td>
							<td>
								<CurrentPrice
									currentPrice={position.CurrentSymbolPrice}
								/>
							</td>
							<td>
								<MarketValue
									marketValue={position.MarketValue}
								/>
							</td>
							<td>
								<Yield
									gain={position.Gain}
									gainPercent={position.GainPercent}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}
}

CurrentHoldingsTable.propTypes = {
	positions: PropTypes.array.isRequired,
};

export default compose(withStyles(styles))(CurrentHoldingsTable);
