import React from 'react';
import PropTypes from 'prop-types';
import {
	ChangeLink,
	Input,
} from '@components';


const InvestmentExpirience = ({
	profile: {
		PendingOrders,
        Experiences
	},
    classes,
    actions
}) => {

    let stocks = Experiences ? Experiences.find(val => val.Type === 'Stocks') : null;
    stocks = stocks ? stocks.Value : '';
    let options = Experiences ? Experiences.find(val => val.Type === 'Option') : null;
    options = options ? options.Value : '';
    let margins = Experiences ? Experiences.find(val => val.Type === 'Margin') : null;
    margins = margins ? margins.Value : '';
    let mutualFunds = Experiences ? Experiences.find(val => val.Type === 'Mutual Funds') : null;
    mutualFunds = mutualFunds ? mutualFunds.Value : '';
    let shortSales = Experiences ? Experiences.find(val => val.Type === 'Short Sale') : null;
    shortSales = shortSales ? shortSales.Value : '';

    return (
        <React.Fragment>
            <div className={ classes.blockTitle }> 
                Investment Experience
                <span>
                    <ChangeLink
                        onClick={actions.showChangeInvestmentExperienceModal}
                        havePendingOrder={PendingOrders.InvestmentExperience}
                    />
                </span>
            </div>
            <Input placeholder="Stocks" showLabel value={stocks} disabled />
            <Input placeholder="Option" showLabel value={options} disabled />
            <Input placeholder="Margin" showLabel value={margins} disabled />
            <Input placeholder="Mutual Funds" showLabel value={mutualFunds} disabled />
            <Input placeholder="Short Sales" showLabel value={shortSales} disabled />
        </React.Fragment>
    );
}

InvestmentExpirience.propTypes = {
	classes: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

export default InvestmentExpirience;