import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalPopups, CloseAccountFlow } from '@enums';
import { userAccounts, withdrawFunds, acatDeposit } from '@redux';
import { SuccessMessage, ErrorMessage, ProgressContainer } from '@components';
import { WireWithdrawalShortForm, CloseAccountSuggestionPopup } from '@shared';
import { WireManuallyTabComponent } from '../AddBankAccountModal/components';
import { RequireSignatureModal } from '../';

const styles = {};

const mapStateToProps = (state) => {
	const resProps = {
		fromUserAccount: withdrawFunds.selectors.fromUserAccount(state),
		toBankAccount: withdrawFunds.selectors.toBankAccount(state),
		canShow: true,
		loading: false,
		closeAccountValidation: acatDeposit.selectors.closeAccountValidation(state),
	};
	if (resProps.fromUserAccount && resProps.toBankAccount) {
		return resProps;
	}
	// default if not set
	const loading = userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading;
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const linkedAccounts = userAccounts.selectors.linkedAccounts(state);
	const accountStatus = userAccounts.selectors.currentAccountStatus(state);
	const toBankAccount = (loading ? null : linkedAccounts.find(x => x.Type === 'WIRE')) || { Type: 'WIRE' };
	return {
		fromUserAccount: {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		},
		toBankAccount,
		canShow: !!(accountStatus && accountStatus.CanWithdrawal && (toBankAccount.Code || accountStatus.CanWireLink)),
		loading,
		closeAccountValidation: acatDeposit.selectors.closeAccountValidation(state),
	};
};

const WIRE_LINK_AND_WITHDRAWAL_FORM = 'wireLinkAndWithdrawalForm';
class WithdrawFundsWireModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 'unavailable',
			onCloseHandler: props.onClose,
		};
	}

	componentDidMount() {
		this.checkFirstPage();
	}

	componentDidUpdate() {
		this.checkFirstPage();
	}

	onSuccess = () => {
		this.setState({
			step: 'succeeded',
			onCloseHandler: null,
		});
	}

	onFail = () => this.setState({ step: 'failed' });

	getSuccessPopup = () => {
		const {
			onClose,
			closeAccountValidation,
		} = this.props;

		if (this.isAccountClosing && closeAccountValidation.submitSucceeded) {
			return (
				<CloseAccountSuggestionPopup
					from="WIRE"
					onDontCloseAccount={onClose}
				/>
			);
		}

		return (<SuccessMessage
			onClick={onClose}
			text="Order Created"
		/>);
	}

	isAccountClosing = false;

	handleGotoAmount = (result, dispatch, props) => {
		this.setState({ step: 'amountform' });
	}

	checkFirstPage = () => {
		const { step } = this.state;
		const { loading, canShow } = this.props;
		if (step === 'unavailable' && !loading && canShow) {
			const isLinked = !!this.props.toBankAccount.Template;
			this.setState({ step: isLinked ? 'amountform' : 'linkform' });
		}
	}

	createSubmitHandleAction = (values, dispatch, props) => {
		const {
			currentAccountData: {
				baseParams: {
					CashCurrentValue: cash,
					FreeMarginValue: unsettledCash,
					PositionMarket: securitiesMarketValue,
				},
			},
		} = props;

		if (parseFloat(values.wireWithdrawal.Amount) === cash &&
			unsettledCash === 0 &&
			securitiesMarketValue === 0) {
			this.isAccountClosing = true;
		}

		const isLinked = !!this.props.toBankAccount.Template;
		return isLinked ?
			withdrawFunds.actions.withdrawFundsWireForm(values, dispatch) :
			withdrawFunds.actions.linkAndWithdrawFundsWireForm(values, dispatch);
	};


	render() {
		const {
			onClose,
			fromUserAccount,
			toBankAccount,
			loading,
		} = this.props;

		const { step } = this.state;
		return (
			<RequireSignatureModal
				title="Withdraw"
				onClose={this.state.onCloseHandler}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.WITHDRAW_FUNDS_WIRE}`}
			>
				<ProgressContainer submitting={loading}>
					<div>
						{step === 'unavailable' &&
							<ErrorMessage onClick={onClose} text={`Unavailable for account ${fromUserAccount.tradeCode}.`} />
						}
						{step === 'succeeded' &&
							this.getSuccessPopup()
						}
						{step === 'failed' &&
							<ErrorMessage onClick={onClose} text="Could not complete transaction" />
						}
						{step === 'amountform' &&
							<WireWithdrawalShortForm
								enableReinitialize
								forceUnregisterOnUnmount
								/* keepDirtyOnReinitialize */
								form={WIRE_LINK_AND_WITHDRAWAL_FORM}
								toBankAccount={toBankAccount}
								onSubmit={this.createSubmitHandleAction}
								onSuccess={this.onSuccess}
								onSubmitFail={this.onFail}
								closeAccountFlow={CloseAccountFlow.CASH_MANAGMENT}
							/>
						}
						{step === 'linkform' &&
							<WireManuallyTabComponent
								destroyOnUnmount={false}
								forceUnregisterOnUnmount
								form={WIRE_LINK_AND_WITHDRAWAL_FORM}
								enableReinitialize
								onSubmit={this.handleGotoAmount}
								onSubmitFail={this.onFail}
							/>
						}
					</div>
				</ProgressContainer>
			</RequireSignatureModal>
		);
	}
}

WithdrawFundsWireModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	fromUserAccount: PropTypes.object.isRequired,
	toBankAccount: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canShow: PropTypes.bool.isRequired,
	closeAccountValidation: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(WithdrawFundsWireModal);
