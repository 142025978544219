import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ModalPopups } from '@enums';
import { profile } from '@redux';
import {
	CYChangePasswordModal,
	ChangeDeliveryTypeModal,
	ChangeProfileAddressModal,
	ChangeProfilePostAddressModal,
	ChangeProfileEmailModal,
	ChangeProfilePhoneModal,
	ChangeTariffPerShareModal,
	ChangeTariffPerTradeModal,
	ChangeTariffZeroModal,
	ChangeTaxFormModal,
	ChangeInvestmentProfileModal,
	ChangeInvestmentExperienceModal,
	ChangeEmploymentModal,
	ChangeMaritalStatusModal,
	ChangeMarketDataSubscriberStatusModal,
	ChangeMarketDataSubscriptionsModal,
	EnableThirdPartyMarketDataModal,
	ChangeDependentsModal,
	ConfirmProfileModal,
	ChangeTwoFactorAuthorizationModal,
} from './components';

const mapDispatchToProps = dispatch => ({
	actions: {
		hideConfirmProfileEmailModal: () => dispatch(profile.actions.hideConfirmEmailModal()),
		hideProfileModal: modalId => dispatch(profile.actions.hideProfileModal(modalId)),
	},
});

class ProfileModals extends React.Component {
	getProfileModal = (item) => {
		// !!! Modals must be in current order !!!

		switch (item) {
			case ModalPopups.CY_CHANGE_PASSWORD:
				return (
					<CYChangePasswordModal
						key={`${ModalPopups.CY_CHANGE_PASSWORD}Modal`}
						onClose={() => this.closeModal(ModalPopups.CY_CHANGE_PASSWORD)}
					/>);
			case ModalPopups.CHANGE_DELIVERY_TYPE:
				return (
					<ChangeDeliveryTypeModal
						key={`${ModalPopups.CHANGE_DELIVERY_TYPE}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_DELIVERY_TYPE)}
					/>);
			case ModalPopups.CHANGE_PROFILE_ADDRESS:
				return (
					<ChangeProfileAddressModal
						key={`${ModalPopups.CHANGE_PROFILE_ADDRESS}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_PROFILE_ADDRESS)}
					/>);
			case ModalPopups.CHANGE_PROFILE_POST_ADDRESS:
				return (
					<ChangeProfilePostAddressModal
						key={`${ModalPopups.CHANGE_PROFILE_POST_ADDRESS}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_PROFILE_POST_ADDRESS)}
					/>);
			case ModalPopups.CHANGE_PROFILE_EMAIL:
				return (
					<ChangeProfileEmailModal
						key={`${ModalPopups.CHANGE_PROFILE_EMAIL}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_PROFILE_EMAIL)}
					/>);
			case ModalPopups.CHANGE_PROFILE_PHONE:
				return (
					<ChangeProfilePhoneModal
						key={`${ModalPopups.CHANGE_PROFILE_PHONE}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_PROFILE_PHONE)}
					/>);
			case ModalPopups.CHANGE_DEPENDENTS:
				return (
					<ChangeDependentsModal
						key={`${ModalPopups.CHANGE_DEPENDENTS}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_DEPENDENTS)}
					/>);
			case ModalPopups.CHANGE_MARITAL_STATUS:
				return (
					<ChangeMaritalStatusModal
						key={`${ModalPopups.CHANGE_MARITAL_STATUS}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_MARITAL_STATUS)}
					/>);
			case ModalPopups.CHANGE_EMPLOYMENT:
				return (
					<ChangeEmploymentModal
						key={`${ModalPopups.CHANGE_EMPLOYMENT}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_EMPLOYMENT)}
					/>);
			case ModalPopups.CHANGE_INVESTMENT_PROFILE:
				return (
					<ChangeInvestmentProfileModal
						key={`${ModalPopups.CHANGE_INVESTMENT_PROFILE}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_INVESTMENT_PROFILE)}
					/>);
			case ModalPopups.CHANGE_INVESTMENT_EXPERIENCE:
				return (
					<ChangeInvestmentExperienceModal
						key={`${ModalPopups.CHANGE_INVESTMENT_EXPERIENCE}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_INVESTMENT_EXPERIENCE)}
					/>);
			case ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL:
				return (
					<ChangeMarketDataSubscriberStatusModal
						key={`${ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL)}
					/>);
			case ModalPopups.CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL:
				return (
					<ChangeMarketDataSubscriptionsModal
						key={`${ModalPopups.CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL)}
					/>);
			case ModalPopups.ENABLE_THIRD_PARTY_MARKETDATA_MODAL:
				return (
					<EnableThirdPartyMarketDataModal
						key={`${ModalPopups.ENABLE_THIRD_PARTY_MARKETDATA_MODAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.ENABLE_THIRD_PARTY_MARKETDATA_MODAL)}
					/>);
			case ModalPopups.CHANGE_TARIFF_PER_SHARE:
				return (
					<ChangeTariffPerShareModal
						key={`${ModalPopups.CHANGE_TARIFF_PER_SHARE}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_TARIFF_PER_SHARE)}
					/>);
			case ModalPopups.CHANGE_TARIFF_PER_TRADE:
				return (
					<ChangeTariffPerTradeModal
						key={`${ModalPopups.CHANGE_TARIFF_PER_TRADE}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_TARIFF_PER_TRADE)}
					/>);
			case ModalPopups.CHANGE_TARIFF_ZERO:
				return (
					<ChangeTariffZeroModal
						key={`${ModalPopups.CHANGE_TARIFF_ZERO}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_TARIFF_ZERO)}
					/>);
			case ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION:
				return (
					<ChangeTwoFactorAuthorizationModal
						key={`${ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_TWO_FACTOR_AUTHORIZATION)}
					/>);
			case ModalPopups.CONFIRM_PROFILE:
				return (
					<ConfirmProfileModal
						key={`${ModalPopups.CONFIRM_PROFILE}Modal`}
						onClose={false}
					/>);
			case ModalPopups.CHANGE_TAX_FORM_MODAL:
				return (
					<ChangeTaxFormModal
						key={`${ModalPopups.CHANGE_TAX_FORM_MODAL}Modal`}
						onClose={() => this.closeModal(ModalPopups.CHANGE_TAX_FORM_MODAL)}
					/>);
			default:
				return null;
		}
	}
	closeModal = id => this.props.actions.hideProfileModal(id);

	render() {
		const {
			location,
		} = this.props;

		const modals = location.hash && location.hash.substr(1).split('/');
		const topModal = modals && modals.length > 0 && this.getProfileModal(modals && modals.pop());

		return (
			<React.Fragment>
				{topModal}
			</React.Fragment>
		);
	}
}

ProfileModals.propTypes = {
	actions: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default compose(
	withRouter,
	connect(null, mapDispatchToProps),
)(ProfileModals);
