import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { profile, userAccounts } from '@redux';
import {
	ChangeLink,
	ExpansionPanel,
	ProgressContainer,
} from '@components';
import {
	ExpansionPanelHeaderEditable,
	InputEditableItem,
} from '@shared';


const MarketDataSubscriptionPanel = ({ onToggle, panelId, isOpen }) => {
	const dispatch = useDispatch();

	const {
		MarketDataSubscriptions,
		PendingOrders,
		submitting,
	} = useSelector(profile.selectors.profile);

	const subscriptions = MarketDataSubscriptions.reduce((prev, cur) => ({ ...prev, [cur.MarketDataName]: cur.IsOn }), {});
	const hasExistsAccount = useSelector(userAccounts.selectors.anyActiveAccountExists) || false;

	return (
		<ProgressContainer submitting={submitting} disableCheckChildren>
			<ExpansionPanel
				header={
					<ExpansionPanelHeaderEditable title="Market Data Subscriptions">
						{hasExistsAccount &&
							<ChangeLink
								onClick={() => dispatch(profile.actions.showChangeMarketDataSubscriptionsModal())}
								havePendingOrder={PendingOrders.MarketDataSubscription}
							/>
						}
					</ExpansionPanelHeaderEditable>
				}
				id={panelId}
				onToggleOpen={onToggle}
				isOpen={isOpen}
			>
				<InputEditableItem
					title="CTA/UTP NMS Equities"
					value={subscriptions.CTA_UTP ? 'Enabled' : 'Disabled'}
				/>
				<InputEditableItem
					title="OTC"
					value={subscriptions.OTC ? 'Enabled' : 'Disabled'}
				/>
				<InputEditableItem
					title="OPRA"
					value={subscriptions.OPRA ? 'Enabled' : 'Disabled'}
				/>
			</ExpansionPanel>
		</ProgressContainer>
	);
};

MarketDataSubscriptionPanel.propTypes = {
	onToggle: PropTypes.func.isRequired,
	panelId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool,
};

MarketDataSubscriptionPanel.defaultProps = {
	isOpen: false,
};

export default MarketDataSubscriptionPanel;
