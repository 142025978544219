/* eslint-disable no-confusing-arrow */
import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { globalState } from '@global';
import { getAllowedLanguages } from '@config';
import { LanguageRefSwitcher } from '@components';
import DashboardView from './DashboardView/DashboardView';
import LoginView from './LoginView/LoginView';
import { ResetView } from './ResetView/ResetView';
import { ResetPasswordView } from './ResetView/ResetPasswordView';


const PrivateRedirect = ({ location, to, ...rest }) => (
	<Redirect
		to={`${to}${location.search}`}
		{...rest}
	/>
);

const PrivateRoute = ({ component: Component, fullWidth, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if ([LoginView, ResetView, ResetPasswordView].includes(Component)) {
				if (globalState.localToken) {
					return (<PrivateRedirect to="/dashboard" {...props} />);
				}
				return (<Component {...props} />);
			}

			if (globalState.forceCheckAuth) {
				return (<LoginView checkAuth {...props} />);
			}

			if (globalState.localToken) {
				return (<Component {...{ fullWidth, ...props }} />);
			}
			return (<LoginView {...props} />);
		}}
	/>
);


const RootRoutes = (props) => {
	// force auth check on first page load/reload
	globalState.forceCheckAuth = true;

	useEffect(() => {
		// remove force auth check after first load
		globalState.forceCheckAuth = false;
	});

	return (
		<Switch>
			{getAllowedLanguages().map(langItem => (
				<Route path={`/${langItem.language}/*`} key={langItem.language}>
					<LanguageRefSwitcher />
				</Route>
			))}
			<PrivateRoute exact path="/login" component={LoginView} />
			<PrivateRoute path="/confirmEmail/:token" component={DashboardView} />
			<PrivateRoute exact path="/" component={DashboardView} />
			<PrivateRoute path="/account/:accid" component={DashboardView} />
			<PrivateRoute path="/dashboard" component={DashboardView} />
			<PrivateRoute path="/wdashboard" fullWidth component={DashboardView} />
			<PrivateRoute path="/reset/:emailAuthKey" component={ResetPasswordView} />
			<PrivateRoute path="/reset" component={ResetView} />
			<PrivateRedirect to="/login" />
		</Switch>
	);
};

// redux cannot be use here because it conflicts with hot module
export default hot(module)(RootRoutes);
