import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Button } from '@components';
import { signature } from '@redux';
import PropTypes from 'prop-types';

const styles = theme => ({
	root: {
		margin: '24px 74px',
		padding: '10px 16px',
		backgroundColor: theme.palette.color.secondary.limitOrder,
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			margin: '24px 0',
			padding: '10px 16px',
		},
	},
	content: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
	},
	actions: {
	},
	button: {
		color: theme.typography.color.link,
	},
});


const mapDispatchToProps = dispatch => ({
	actions: {
		collectSignaturesProcessStart: () =>
			dispatch(signature.actions.collectSignaturesProcessStart()),
	},
});


class SignatureAlert extends React.Component {
	render() {
		const { classes } = this.props;
		return (
			<div>
				<div className={classes.root}>
					<div className={classes.content}>You’re unable to submit anything without signature.</div>
					<div className={classes.actions}>
						<Button
							onClick={this.props.actions.collectSignaturesProcessStart}
							className={classes.button}
						>
							Add Signature
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

SignatureAlert.propTypes = {
	actions: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(SignatureAlert);
