import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
	profile,
	signature,
	userAccounts
} from '@redux';
import compose from 'recompose/compose';
import { ChangeLink, ProgressContainer } from '@components';
import { toUsaDateFormat } from '@global';

const styles = theme => ({
	taxFormHeader: {
		display: 'flex',
		fontSize: '13.5px',
		color: theme.typography.color.secondary,
		flexWrap: 'wrap',
		padding: '0 24px 8px',
	},
	taxFormRow: {
		display: 'flex',
		fontSize: '18px',
		color: theme.typography.color.disable,
		flexWrap: 'wrap',
		padding: '0 24px 8px',
	},
	taxFormColType: {
		flex: '0 1 150px',
		margin: 'auto 0px',
	},
	taxFormColDate: {
		flex: '0 1 150px',
		margin: 'auto 0px',
	},
	taxFormColLink: {
		flex: '1 0 150px',
	},
	dashboardLink: {
		...theme.typography.color.white,
	},
	expansionPanelStaticContent: {
		margin: '10px 24px 5px',
		color: theme.palette.text.secondary,
	},
});

const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
	hasOpenAccount: userAccounts.selectors.hasOpenAccount(state)
});


const mapDispatchToProps = dispatch => ({
	actions: {
		showChangeTaxFormModal: formType => dispatch(profile.actions.showChangeTaxFormModal(formType)),
	},
});

const getFormType = docType => docType.replaceAll('-', '').toUpperCase();

class TaxForms extends React.Component {
	showTaxFormUpdateModal = docType => () => {
		const { actions } = this.props;
		actions.showChangeTaxFormModal(getFormType(docType));
	}

	render() {
		const {
			classes,
			profile: {
				submitting,
				TaxForms: taxDocs,
				PendingOrders,
			},
			hasOpenAccount,
		} = this.props;

		return (
			<ProgressContainer submitting={submitting} display="left">
				<div>
					{!taxDocs.length ? (
						<div className={classes.expansionPanelStaticContent}>
							No documents to show
						</div>) :
						(
							<div className={classes.taxFormHeader}>
								<div className={classes.taxFormColType}>Document type</div>
								<div className={classes.taxFormColDate}>Signature date</div>
								<div className={classes.taxFormColDate}>Expiration date</div>
							</div>
						)
					}
					{taxDocs.map(doc => (
						<div className={classes.taxFormRow} key={doc.DocType}>
							<div className={classes.taxFormColType}>{doc.DocType}</div>
							<div className={classes.taxFormColDate}>{doc.SignatureDate && toUsaDateFormat(doc.SignatureDate)}</div>
							<div className={classes.taxFormColDate}>{doc.ExpirationDate && (new Date(doc.ExpirationDate) < new Date() ? 'Expired' : toUsaDateFormat(doc.ExpirationDate))}</div>
							<div className={classes.taxFormColLink}>
								{ hasOpenAccount &&
									<ChangeLink
										onClick={this.showTaxFormUpdateModal(doc.DocType)}
										havePendingOrder={
											PendingOrders && PendingOrders.TaxFormUpdate &&
											PendingOrders.TaxFormUpdate.some(x => x === getFormType(doc.DocType))
										}
									/>
								}
							</div>
						</div>
					))}
				</div>
			</ProgressContainer>
		);
	}
}

TaxForms.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
};


export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(TaxForms);
