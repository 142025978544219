import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { auth, profile, userAccounts } from '@redux';
import { constants } from '@config';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	Button,
	ErrorMessage,
	FormInput,
	FormInputPhoneIconNumber,
	ProgressContainer,
	SuccessMessage,
} from '@components';


const styles = {
	root: {
		display: 'block',
		position: 'relative',
	},
	confirmLink: {
		marginTop: '16px',
		paddingLeft: '24px',
	},
	button: {
		paddingTop: '24px',
	},
};

const CHANGE_TWO_FACTOR_AUTHORIZATION_FORM = 'ChangeTwoFactorAuthorizationModal';
const formSelector = formValueSelector(CHANGE_TWO_FACTOR_AUTHORIZATION_FORM);
const emptyIfHidden = phone => ((phone && phone.indexOf('*') >= 0) ? '' : phone);

const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	const loading = userAccounts.selectors.loading(state);
	return {
		initialValues: {
			Phone: emptyIfHidden(userProfile.PersonalInformation.Phone),
		},
		phoneValue: formSelector(state, 'Phone'),
		loading: userProfile.submitting && loading,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		sendConfirmationCode: phone => dispatch(auth.actions.sendConfirmationCodeRequest(phone, constants.EtnaGateConfirmCode)),
	},
});

class SmsAuthenticationForm extends React.Component {
	static validate(values) {
		const errors = {};
		if (!values.Phone) {
			errors.Value = 'required';
		}

		if (!values.ConfirmationCode || values.ConfirmationCode.length < 6) {
			errors.ConfirmationCode = 'required';
		}

		return errors;
	}

	constructor(props) {
		super(props);

		this.state = {
			hasConfirmationCode: false,
			password: '',
			currentCount: 0,
			intervalId: null,
		};
	}

	componentWillUnmount() {
		// use intervalId from the state to clear the interval
		clearInterval(this.state.intervalId);
	}

	onRequestVerificationCode = () => {
		const {
			actions,
			phoneValue,
			reset,
		} = this.props;

		actions.sendConfirmationCode(phoneValue);
		const intervalId = setInterval(this.timer, 1000);
		this.setState({
			hasConfirmationCode: true,
			currentCount: 120,
			intervalId,
		});
		reset();
	}

	timer = () => {
		if (this.state.currentCount === 0) {
			clearInterval(this.state.intervalId);
		} else {
			this.setState({ currentCount: this.state.currentCount - 1 });
		}
	}

	render() {
		const {
			classes,
			onClose,
			handleSubmit,
			invalid,
			pristine,
			submitting,
			submitSucceeded,
			phoneValue,
			loading,
		} = this.props;

		const {
			hasConfirmationCode,
			currentCount,
		} = this.state;

		return (
			<Fragment>
				{submitSucceeded &&
					<SuccessMessage onClick={onClose} text="Your request to change two-factor authentication is processed." />
				}
				{!submitSucceeded && !loading && !phoneValue &&
					<ErrorMessage onClick={onClose} text="Phone number is not specified." />
				}
				{!submitSucceeded && (loading || phoneValue) &&
					<ProgressContainer submitting={loading}>
						<form
							onSubmit={handleSubmit(profile.actions.changeTwoFactorAuthorizationForm)}
							className={classes.root}
						>
							<div className={classes.inputItem}>
								<Field
									component={FormInputPhoneIconNumber}
									name="Phone"
									placeholder="Phone"
									label="Phone"
									onPhoneChange={p => p}
									disabled
								/>
							</div>
							<div className={classes.inputItem}>
								{hasConfirmationCode && currentCount > 0 &&
									<Field
										component={FormInput}
										name="ConfirmationCode"
										placeholder="Verification Code"
										label="Verification Code"
										type="text"
										value={this.state.password}
									/>
								}
								<div className={classes.confirmLink}>
									{hasConfirmationCode &&
										<p>
											In case you have not received the code, please check the phone number above and retry.
										</p>
									}
									{currentCount > 0 &&
										<p>
											You can request new code in {currentCount} seconds
										</p>
									}
								</div>
							</div>
							<div className={classes.button}>
								{hasConfirmationCode && currentCount > 0 &&
									<Button
										fullWidth
										type="submit"
										variant="contained"
										color="primary"
										disabled={invalid || pristine || submitting}
									>
										{submitting ?
											<CircularProgress
												style={{ color: '#1a96ff' }}
												size={18}
											/>
											: 'Confirm'
										}
									</Button>
								}
								{(currentCount === 0 || !hasConfirmationCode) &&
									<Button
										fullWidth
										variant="contained"
										color="primary"
										disabled={!phoneValue}
										onClick={this.onRequestVerificationCode}
									>
										Enable second factor
									</Button>
								}
							</div>
						</form>
					</ProgressContainer>
				}
			</Fragment>
		);
	}
}

SmsAuthenticationForm.defaultProps = {
	phoneValue: '',
};

SmsAuthenticationForm.propTypes = {
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	actions: PropTypes.object.isRequired,
	invalid: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	pristine: PropTypes.bool.isRequired,
	initialValues: PropTypes.object.isRequired,
	phoneValue: PropTypes.string,
	reset: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: CHANGE_TWO_FACTOR_AUTHORIZATION_FORM,
		validate: SmsAuthenticationForm.validate,
	}),
)(SmsAuthenticationForm);
