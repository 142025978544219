/* eslint-disable no-underscore-dangle,no-plusplus,prefer-destructuring */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { constants, env } from '@config';
import { FormInput, Button, A, R, useTranslate } from '@components';
import { app, loginForm } from '@redux';
import { PasswordInput } from './components';

const useStyles = makeStyles(theme => ({
	input: {
		marginTop: '24px',
	},
	inputStyle: {
		border: '1px solid lightgray',
		height: '68px',
		'& input': {
			'&:-webkit-autofill': {
				transition: 'background-color 0.1s ease-in-out 0s',
				'-webkit-box-shadow': '0 0 0 1000px #ffffff inset',
			},
		},
	},
	forgotPassword: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		textAlign: 'right',
		marginTop: '8px',
	},
	formError: {
		...theme.typography.style.body,
		color: theme.typography.color.white,
		backgroundColor: theme.typography.color.red,
		borderRadius: '4px',
		padding: '6px 18px',
		textAlign: 'left',
		marginTop: '15px',

	},
	formErrorShake: {
		'-webkit-animation': 'shake .5s linear',
	},
	'@keyframes shake': {
		'8%, 41%': {
			'-webkit-transform': 'translateX(-10px)',
		},
		'25%, 58%': {
			'-webkit-transform': 'translateX(10px)',
		},
		'75%': {
			'-webkit-transform': 'translateX(-5px)',
		},
		'92%': {
			'-webkit-transform': 'translateX(5px)',
		},
		'0%, 100%': {
			'-webkit-transform': 'translateX(0)',
		},
	},
	submitButton: {
		marginTop: '24px',
	},
	newAccount: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		textAlign: 'center',
		marginTop: '40px',
	},
}));

const useActions = () => {
	const dispatch = useDispatch();
	const actions = {
		setCurrentPage: page => dispatch(push(page)),
	};
	return actions;
};

const LoginFormLocal = (props) => {
	// const [autoFillsCount, setAutoFillsCount] = useState(0);
	const classes = useStyles(props);
	const actions = useActions();
	const language = useSelector(app.selectors.language);
	const translate = useTranslate();
	// const autoFillCompleted = () => setAutoFillsCount(autoFillsCount + 1);

	const {
		handleSubmit,
		pristine,
		invalid,
		error,
		submitting,
	} = props;

	const buttonDisabled = pristine || invalid;
	let anketaUrl = env.anketaSiteUrl.endsWith('/') ? env.anketaSiteUrl : `${env.anketaSiteUrl}/`;
	if (env.allowedLangs) {
		anketaUrl += `${language}/`;
	}

	return (
		<form
			onSubmit={handleSubmit(loginForm.actions.loginFormSubmitHandler)}
			/* ref={this.formRef} */
			autoComplete="off"
			className={classNames({
				[classes.formErrorShake]: error,
			})}
		>
			<div className={classes.input}>
				<Field
					component={FormInput}
					name="username"
					autoComplete="off"
					placeholder={translate('login_form_login', 'Login')}
					className={classes.inputStyle}
					showLabel={false}
				/>
			</div>
			<div className={classes.input} >
				<Field
					component={PasswordInput}
					name="password"
					placeholder={translate('login_form_password', 'Password')}
					autoComplete="off"
					className={classes.inputStyle}
					showLabel={false}
				/>
			</div>
			{[constants.LIME_INT, constants.LIME_NSR, constants.LIME_LME].some(x => x === env.firm) &&
				<div className={classes.forgotPassword}>
					<A href="#" onClick={() => actions.setCurrentPage('/reset')}><R id="forgot_password" defaultValue="Forgot password?" /></A>
				</div>
			}
			{error &&
				<div className={classes.formError}>
					{error.map((errorItem, index) => <div key={index}>{errorItem}</div>)}
				</div>
			}
			<div className={classes.submitButton}>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					disabled={buttonDisabled}
				>
					{submitting ?
						<CircularProgress
							style={{ color: '#ffffff' }}
							size={18}
						/>
						: <R id="login_form_sign_in" defaultValue="Sign In" />
					}
				</Button>
			</div>
			<div className={classes.newAccount}>
				<A target="_blank" href={anketaUrl}><R id="open_new_account" defaultValue="Open New Account" /></A>
			</div>
		</form>
	);
};

LoginFormLocal.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
	form: 'loginForm',
	validate: (values) => {
		const errors = {};
		if (!values.username) {
			errors.username = 'required';
		}
		if (!values.password) {
			errors.password = 'required';
		}
		return errors;
	},
})(LoginFormLocal);
