import { createComponent, mapError } from '../utils';
import InputPassword from './InputPassword';

export default createComponent(
	InputPassword,
	({
		defaultValue,
		...props
	}) => ({
		...mapError(props),
	}),
);
