import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import {
	userAccounts,
	withdrawFunds,
	acatDeposit,
} from '@redux';
import {
	reduxForm,
	Field,
	formValueSelector,
	FieldArray,
} from 'redux-form';
import {
	A,
	FormSelect,
	FormInput,
	Divider,
	FormInputPhoneNumber,
	Button,
	FileInput,
	LimeLogo,
	SuccessMessage,
	SelectField,
} from '@components';
import { constants } from '@config';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
// import LongArrow from './arrow.svg';
// import j2tlogo from './j2t.png';


const formSelector = formValueSelector('acatFundForm');

const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		TradeCode: ownProps.tradeAccount.tradeCode,
		ClearerFirm: ownProps.tradeAccount.clearerFirm,
		TransferInstructionsType: 'Brokerage',
	},
	TransferInstructionsType: formSelector(state, 'TransferInstructionsType'),
	AssetsTransferType: formSelector(state, 'AssetsTransferType'),
	attachmentsValue: formSelector(state, 'Attachments'),
});


const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '10px',
		'& div > div': {
			background: theme.palette.color.primary.attestationBackground,
		},
	},
	phoneInput: {
		marginTop: '10px',
		'& > div > div': {
			width: '100%',
		},
		'& > div > div > div > div': {
			background: theme.palette.color.primary.attestationBackground,
		},
	},
	inputSelectItem: {
		marginTop: '25px',
	},
	item: {
		marginTop: '30px',
		padding: '0 24px',
	},
	divider: {
		marginTop: '30px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	inputContainerMultiline: {
		flexWrap: 'wrap',
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountHelperText: {
		width: '100%',
		textAlign: 'left',
		fontSize: '14px',
		color: theme.typography.color.secondary,
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	textNote: {
		...theme.typography.style.body,
		padding: '24px 24px 0',
	},
	brokeragePartsRow: {
		display: 'flex',
		alignItems: 'flex-end',
		[theme.breakpoints.only('xs')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	brokeragePartsRowNumber: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
	brokeragePartsInputSymbol: {
		marginBottom: '-5px',
	},
	brokeragePartsInputNumberOfShares: {
		marginBottom: '-5px',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: '11px',
		},

	},
	brokeragePartsColumn: {
		display: 'flex',
		alignItems: 'flex-end',
		flex: '1 0 240px',
	},
	selectItem: {
		whiteSpace: 'initial',
	},
	attachFileText: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		marginTop: '24px',
	},
	fileUploader: {
		marginTop: '16px',
	},
});

const renderMutualFundTransferBody = classes => ({
	fields, meta: { error, submitFailed },
}) => {
	if (fields.length < 1) {
		for (let i = 0; i < 3; i += 1) {
			fields.push({
				FundTransferName: '',
				FundTransferSymbol: '',
				FundTransferShares: '',
				FundTransferType: '',
			});
		}
	}

	return (
		<div>
			{fields.map((member, index) => (
				<div key={index}>
					<div className={classes.inputItem}>
						<Field
							name={`${member}.FundTransferName`}
							type="text"
							component={FormInput}
							placeholder="Name of Fund and Account Number (if separate)"
						/>
					</div>
					<div className={classes.inputItem}>
						<Field
							name={`${member}.FundTransferSymbol`}
							type="text"
							component={FormInput}
							placeholder="Symbol"
						/>
					</div>
					<div className={classes.inputItem}>
						<Field
							name={`${member}.FundTransferShares`}
							type="text"
							component={FormInput}
							placeholder="Number of Shares"
						/>
					</div>
					<div className={classes.inputSelectItem}>
						<SelectField
							label="Transfer"
							name={`${member}.FundTransferType`}
							items={[
								{ value: 'InKind', text: 'In kind (securities)' },
								{ value: 'Liquidate', text: 'In cash (liquidate)' },
							]}
							onChange={this.handleChangeEmploymentStatus}
						/>
					</div>
				</div>
			))}
		</div>);
};


const renderBrokeragePartsTransferBody = classes => ({
	fields, meta: { error, submitFailed },
}) => {
	if (fields.length < 1) {
		for (let i = 0; i < 4; i += 1) {
			fields.push({
				AssetsTransferSymbol: '',
				AssetsTransferShares: '',
			});
		}
	}

	return (
		<div>
			{fields.map((member, index) => (
				<div className={classes.brokeragePartsRow} key={index}>
					<div className={classes.brokeragePartsColumn}>
						<div className={classes.brokeragePartsRowNumber}>
							{`${index + 1}.`}
						</div>
						<div className={classes.brokeragePartsInputSymbol}>
							<Field
								name={`${member}.AssetsTransferSymbol`}
								type="text"
								component={FormInput}
								placeholder="Security Symbol or Name"
							/>
						</div>
					</div>

					<div className={classes.brokeragePartsInputNumberOfShares}>
						<Field
							name={`${member}.AssetsTransferShares`}
							type="text"
							component={FormInput}
							placeholder="Number of Shares"
						/>
					</div>
				</div>
			))}
		</div>);
};

class AcatFundForm extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.TradeCode) {
			errors.TradeCode = 'required';
		}

		if (!values.SourceFirmName) {
			errors.SourceFirmName = 'required';
		} else if (values.SourceFirmName.length > constants.maxCharsNumber) {
			errors.SourceFirmName = constants.maxCharsNumberError;
		}

		if (!values.SourceFirmTelephoneNumber) {
			errors.SourceFirmTelephoneNumber = 'required';
		} else if (values.SourceFirmTelephoneNumber.length > constants.maxCharsNumber) {
			errors.SourceFirmTelephoneNumber = constants.maxCharsNumberError;
		}

		if (!values.SourceFirmAddress) {
			errors.SourceFirmAddress = 'required';
		} else if (values.SourceFirmAddress.length > constants.maxCharsNumber) {
			errors.SourceFirmAddress = constants.maxCharsNumberError;
		}

		if (!values.SourceAccountTitle) {
			errors.SourceAccountTitle = 'required';
		} else if (values.SourceAccountTitle.length > constants.maxCharsNumber) {
			errors.SourceAccountTitle = constants.maxCharsNumberError;
		}

		if (!values.SourceAccountNumber) {
			errors.SourceAccountNumber = 'required';
		} else if (values.SourceAccountNumber.length > constants.maxCharsNumber) {
			errors.SourceAccountNumber = constants.maxCharsNumberError;
		}

		if (!values.SourceAccountType) {
			errors.SourceAccountType = 'required';
		}

		if (!values.TransferInstructionsType) {
			errors.TransferInstructionsType = 'required';
		}

		if (values.TransferInstructionsType) {
			if (values.TransferInstructionsType === 'Brokerage') {
				if (!values.AssetsTransferType) {
					errors.AssetsTransferType = 'required';
				}
				if (values.AssetsTransferType === 'Parts') {
					if (values.BrokerageParts) {
						const brokeragePartsErrorsArray = [];

						values.BrokerageParts.forEach((item, index) => {
							const itemErrors = {};
							if (index === 0) {
								if (!item.AssetsTransferSymbol) {
									itemErrors.AssetsTransferSymbol = 'required';
								} else if (item.AssetsTransferSymbol.length > constants.maxCharsNumber) {
									itemErrors.AssetsTransferSymbol = constants.maxCharsNumberError;
								}
								if (!item.AssetsTransferShares) {
									itemErrors.AssetsTransferShares = 'required';
								} else if (item.AssetsTransferShares.length > constants.maxCharsNumber) {
									itemErrors.AssetsTransferShares = constants.maxCharsNumberError;
								}
							}
							if (item.AssetsTransferSymbol && item.AssetsTransferSymbol.length > constants.maxCharsNumber) {
								itemErrors.AssetsTransferSymbol = constants.maxCharsNumberError;
							}
							if (item.AssetsTransferShares && item.AssetsTransferShares.length > constants.maxCharsNumber) {
								itemErrors.AssetsTransferShares = constants.maxCharsNumberError;
							}
							if (itemErrors.AssetsTransferSymbol || itemErrors.AssetsTransferShares) {
								brokeragePartsErrorsArray.push(itemErrors);
							}
						});
						if (brokeragePartsErrorsArray.length > 0) {
							errors.BrokerageParts = { _error: brokeragePartsErrorsArray };
						}
					}
				}
			}
			if (values.TransferInstructionsType === 'MutualFund') {
				if (values.MutualFund) {
					const mutualFundErrorsArray = [];

					values.MutualFund.forEach((item, index) => {
						const itemErrors = {};
						if (index === 0) {
							if (!item.FundTransferName) {
								itemErrors.FundTransferName = 'required';
							} else if (item.FundTransferName.length > constants.maxCharsNumber) {
								itemErrors.FundTransferName = constants.maxCharsNumberError;
							}
							if (!item.FundTransferSymbol) {
								itemErrors.FundTransferSymbol = 'required';
							} else if (item.FundTransferSymbol.length > constants.maxCharsNumber) {
								itemErrors.FundTransferSymbol = constants.maxCharsNumberError;
							}
							if (!item.FundTransferShares) {
								itemErrors.FundTransferShares = 'required';
							} else if (item.FundTransferShares.length > constants.maxCharsNumber) {
								itemErrors.FundTransferShares = constants.maxCharsNumberError;
							}
							if (!item.FundTransferType) {
								itemErrors.FundTransferType = 'required';
							} else if (item.FundTransferType.length > constants.maxCharsNumber) {
								itemErrors.FundTransferType = constants.maxCharsNumberError;
							}
						}
						mutualFundErrorsArray.push(itemErrors);
					});
					errors.MutualFund = mutualFundErrorsArray;
				}
			}
		}


		if (!values.AssetsTransferType) {
			errors.AssetsTransferType = 'required';
		}
		if (!values.Attachments || values.Attachments.length === 0) {
			errors.Attachments = 'required';
		}
		return errors;
	}

	constructor(props) {
		super(props);
		this.state = {
			phoneState: {
				determined: false,
				completed: false,
			},
		};

		this.onFilesUpload = this.onFilesUpload.bind(this);
		this.onPhoneChange = this.onPhoneChange.bind(this);
	}

	onPhoneChange(match) {
		if (match) {
			this.setState({
				phoneState: match,
			});
		}
	}

	onFilesUpload(files) {
		this.props.change('Attachments', files);
	}

	render() {
		const {
			attachmentsValue,
			classes,
			actions,
			onClose,
			tradeAccount,
			handleSubmit,
			selectedBankAccount,
			submitSucceeded,
			TransferInstructionsType,
			AssetsTransferType,
			...props
		} = this.props;

		const noAttachments = (!attachmentsValue || attachmentsValue.length === 0);

		return (
			<div>
				{
					submitSucceeded ? <SuccessMessage
						onClick={onClose}
						text="You've just submitted Transfer Shares form. Our support team will process it shortly."
						buttonText="Back To Platform"
					/> :
						(
							<form
								onSubmit={handleSubmit(acatDeposit.actions.acatDepositFormSubmitHandler)}
								className={classes.root}
							>
								<div className={classes.accountContainer}>
									<div className={classes.row}>
										<div className={classes.logo}>
											<LimeLogo />
										</div>
										<Field component={FormSelect} name="TradeCode" label="To the Account" disabled>
											<MenuItem value={tradeAccount && tradeAccount.tradeCode}>
												<div className={classes.inputContainer}>
													<div className={classes.account}>{tradeAccount && tradeAccount.tradeCode}</div>
												</div>
											</MenuItem>
										</Field>
									</div>
								</div>
								<div className={classes.textNote}>
									Lime Trading accepts transfers of both securities and cash via ACAT.&nbsp;
									To find out if your current broker is an ACAT participant click&nbsp;
									<A target="_blank" href="https://www.dtcc.com/client-center/dtc-directories">here</A>.&nbsp;
									Lime Trading does not accept any OTC securities
								</div>
								<div className={classes.divider}>
									<Divider />
								</div>
								<div className={classes.textNote}>
									Please complete the below information for the brokerage account from where you will be transferring.
								</div>
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="SourceFirmName"
										placeholder="Brokerage Name"
										label="Brokerage Name"
									/>
								</div>
								<div className={classes.phoneInput}>
									<Field
										component={FormInputPhoneNumber}
										name="SourceFirmTelephoneNumber"
										placeholder="Brokerage Contact Number"
										label="Brokerage Contact Number"
										onPhoneChange={this.onPhoneChange}
									/>
								</div>
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="SourceFirmAddress"
										placeholder="Brokerage Address"
										label="Brokerage Address"
									/>
								</div>
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="SourceAccountTitle"
										placeholder="Brokerage Account Title"
										label="Brokerage Account Title"
									/>
								</div>
								<div className={classes.inputItem}>
									<Field
										component={FormInput}
										name="SourceAccountNumber"
										placeholder="Brokerage Account Number"
										label="Brokerage Account Number"
									/>
								</div>
								<div className={classes.inputSelectItem}>
									<SelectField
										label="Account&nbsp;Type&nbsp;Being&nbsp;Transferred"
										name="SourceAccountType"
										items={[
											{ value: 'Individual', text: 'Individual' },
											{ value: 'Joint', text: 'Joint' },
											{ value: 'Trust', text: 'Trust' },
											{ value: 'Corporate', text: 'Corporate' },
											{ value: 'Ira', text: 'Ira' },
										]}
										onChange={this.handleChangeEmploymentStatus}
									/>
								</div>
								<div className={classes.divider}>
									<Divider />
								</div>
								{TransferInstructionsType === 'Brokerage' &&
								<div className={classes.inputSelectItem}>
									<SelectField
										label="Transfer Instructions"
										name="AssetsTransferType"
										items={[
											{
												value: 'All',
												text: (
													<React.Fragment>
														<div className={classes.account}>
															Transfer all my assets
														</div>
														<div className={classes.accountHelperText}>
															money markets may be liquidated
														</div>
													</React.Fragment>
												),
											},
											{ value: 'Parts', text: 'Transfer only part of my account, as detailed below' },
										]}
										onChange={this.handleChangeEmploymentStatus}
									/>
								</div>
								}
								{TransferInstructionsType === 'Brokerage' && AssetsTransferType === 'Parts' &&
								<div>
									<FieldArray
										name="BrokerageParts"
										component={renderBrokeragePartsTransferBody(classes)}
									/>
								</div>
								}
								{TransferInstructionsType === 'MutualFund' &&
								<div>
									<FieldArray
										name="MutualFund"
										component={renderMutualFundTransferBody(classes)}
									/>
								</div>
								}
								<div className={classes.divider}>
									<Divider />
								</div>
								<div className={classes.attachFileText}>
									Please attach a recent statement from the brokerage firm you are transferring out of.
								</div>
								<div className={classes.fileUploader}>
									<FileInput
										onFilesUpdate={files => this.onFilesUpload(files)}
									/>
								</div>
								<div className={classes.button}>
									<Button
										fullWidth
										type="submit"
										variant="contained"
										color="primary"
										disabled={props.invalid || props.submitting || !this.state.phoneState.completed || noAttachments}
									>
										{props.submitting ?
											<CircularProgress
												style={{ color: '#1a96ff' }}
												size={18}
											/>
											: 'Transfer'
										}
									</Button>
								</div>
							</form>
						)
				}
			</div>);
	}
}

AcatFundForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	tradeAccount: PropTypes.object,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: 'acatFundForm',
		validate: AcatFundForm.validate,
	}),
)(AcatFundForm);
