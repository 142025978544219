import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { profile } from '@redux';
import { connect } from 'react-redux';
import {
	formValueSelector,
	reduxForm,
	Field,
} from 'redux-form';
import {
	Button,
	FormInput,
	FormRadioGroup,
	Radio,
} from '@components';

const styles = theme => ({
	root: {
		display: 'block',
		position: 'relative',
	},
	button: {
		paddingTop: '24px',
	},
});
const CHANGE_MARKETDATA_SUBSCRIBER_STATUS_FORM = 'ChangeMarketDataSubscriberStatusForm';
const formSelector = formValueSelector(CHANGE_MARKETDATA_SUBSCRIBER_STATUS_FORM);
const mapStateToProps = (state) => {
	const userProfile = profile.selectors.profile(state);
	return {
		initialValues: {
			MdStatus: userProfile.MarketDataStatus || 'None',
		},
		loading: userProfile.submitting,
		getFormValue: fieldName => formSelector(state, fieldName),
	};
};

class ChangeMarketDataSubscriberStatusForm extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.MdStatus) {
			errors.Value = 'required';
		}
		if (!values.Comment) {
			errors.Comment = 'required';
		}
		return errors;
	}
	onMdStatusChange = () => {
		const { change, initialValues, getFormValue } = this.props;
		if (initialValues.MdStatus === 'None' && getFormValue('MdStatus') !== 'Non-Pro') {
			change('Comment', '');
		}
	}

	render() {
		const {
			classes,
			handleSubmit,
			invalid,
			pristine: pristineAll,
			submitting,
			initialValues,
			getFormValue,
		} = this.props;
		const pristine = pristineAll || getFormValue('MdStatus') === initialValues.MdStatus;
		return (
			<form
				onSubmit={handleSubmit(profile.actions.changeMarketDataSubscriberStatusForm)}
				className={classes.root}
			>
				<Field
					fullWidth
					name="MdStatus"
					orientation="vertical"
					component={FormRadioGroup}
					showLabel
					placeholder="Please enter your new market data subscriber status"
					onChange={this.onMdStatusChange}
				>
					<Radio
						value="Non-Pro"
						label="Non-Professional"
					/>
					<Radio
						value="Pro"
						label="Professional"
					/>
				</Field>
				<p>
					Please choose Non-Professional if you use Market Data solely for managing your own personal investments or the investments of your family.
				</p>
				<p>
					If you are currently employed by a bank or broker, receive Market Data for your business or any other entity or if you are not sure about your status please choose Professional. We will contact you later and assist you to determine your correct status.
				</p>
				{(initialValues.MdStatus !== 'None' || getFormValue('MdStatus') !== 'Non-Pro') &&
					<Field
						fullWidth
						component={FormInput}
						name="Comment"
						placeholder="Comment"
					/>
				}
				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={invalid || submitting || pristine}
					>
						{submitting ?
							<CircularProgress
								style={{ color: '#1a96ff' }}
								size={18}
							/>
							: 'Change Status'
						}
					</Button>
				</div>
			</form>
		);
	}
}

ChangeMarketDataSubscriberStatusForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: CHANGE_MARKETDATA_SUBSCRIBER_STATUS_FORM,
		validate: ChangeMarketDataSubscriberStatusForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.OnSuccess) {
				props.OnSuccess();
			}
		},
	}),
)(ChangeMarketDataSubscriberStatusForm);
