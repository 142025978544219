import React from 'react';

const Svg = (
	<g fill="none" fillRule="evenodd">
		<circle cx="76" cy="76" r="76" fill="#78AAFF" opacity=".201" />
		<circle cx="76" cy="76" r="60" fill="#78AAFF" />
		<rect width="6" height="29.13" x="59.453" y="70.342" fill="#FFF" rx="3" transform="rotate(-45 62.453 84.907)" />
		<rect width="6" height="57.678" x="86.547" y="46.161" fill="#FFF" rx="3" transform="rotate(45 89.547 75)" />
	</g>
);

export default Svg;
