import { ModalPopups } from '@enums';
import actions from './actions';


const initialState = {
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	default:
		return state;
	}
};
