import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	userAccounts,
	withdrawFunds,
} from '@redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	FormSelect,
	FormInput,
	Divider,
	Button,
	FormRadioGroup,
	Radio,
	FormCheckbox,
	LimeLogo,
	SvgIcon,
	WithdrawAmount,
} from '@components';
import { constants } from '@config';
import { cabinetLog } from '@global';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
// import j2tlogo from './j2t.png';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	item: {
		marginTop: '30px',
		paddingLeft: '74px',
	},
	divider: {
		marginTop: '30px',
	},
	bankAccountContainer: {
		marginTop: '-15px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '36px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
});

function MapForm(ownProps) {
	const template = (ownProps && ownProps.toBankAccount && ownProps.toBankAccount.Template)
		? ownProps.toBankAccount.Template
		: null;

	if (!template) return {};

	return {
		...template,
		Name: template.FirstName,
		Surname: template.LastName,
		BankName: template.ReceivingBankName,
		BankRoutingNumber: template.ReceivingBankRoutingNumber,
		BankSwiftCode: template.ReceivingBankSwiftCode,
		BankAccountName: template.ReceivingBankAccountName,
		BankAddress: template.ReceivingBankAddress,
		BankAccountNumber: template.ReceivingBankAccountNumber,
		UseIntermediaryBank: !!template.IntermediaryBankName, // toBoolean
		Additional: template.AdditionalInformation,
	};
}

const WIRE_WITHDRAWAL_FORM_ID = 'wireWithdrawalForm';
const formSelector = formValueSelector(WIRE_WITHDRAWAL_FORM_ID);

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
			Amount: ownProps.amount,
			WireType: 'Domestic',
			...MapForm(ownProps),
		},
		WireTypeValue: formSelector(state, 'WireType'),
		UseIntermediaryBankValue: formSelector(state, 'UseIntermediaryBank'),
		currentAccountData: userAccounts.selectors.currentAccountData(state),
		currentTradeAccount,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
	},
});


class WireWithdrawalForm extends React.Component {
	static getCashToWithdraw(props) {
		const { currentAccountData: { baseParams, submitting } } = props;
		return !submitting && baseParams && baseParams.CashToWithdrawValue;
	}

	static validate(values, props) {
		const errors = {};
		const cashToWithdraw = WireWithdrawalForm.getCashToWithdraw(props);
		const isVision = props.currentTradeAccount.clearerFirm === 'vision';
		const isCor = props.currentTradeAccount.clearerFirm === 'cor';


		if (!values.Name) {
			errors.Name = 'required';
		}

		if (!values.Amount || values.Amount <= 0) {
			errors.Amount = 'required';
		} else if (typeof cashToWithdraw === 'number') {
			if (cashToWithdraw < values.Amount) {
				errors.Amount = 'Available amount for withdrawal is less than requested';
			}
		}

		if (!values.Surname) {
			errors.Surname = 'required';
		}

		if (!values.Address) {
			errors.Address = 'required';
		}

		if (!values.ZipCode) {
			errors.ZipCode = 'required';
		}

		if (!values.Phone) {
			errors.Phone = 'required';
		} else if (!(/^[\d+\-() ]+$/i.test(values.Phone))) {
			errors.Phone = 'required';
		}

		if (!values.BankName) {
			errors.BankName = 'required';
		}

		if (values.WireType === 'International') {
			if (!values.BankSwiftCode) {
				errors.BankSwiftCode = 'required';
			}
		}

		if (values.WireType === 'Domestic') {
			if (!values.BankRoutingNumber) {
				errors.BankRoutingNumber = 'required';
			} else if (!(/^(\d{9})$/.test(values.BankRoutingNumber))) {
				errors.BankRoutingNumber = 'Invalid Number';
			}
		}

		if (!values.BankAccountName) {
			errors.BankAccountName = 'required';
		}

		if ((isVision || isCor) && !values.Comment) {
			errors.Comment = 'required';
		}

		if (!values.BankAccountNumber) {
			errors.BankAccountNumber = 'required';
		} else if (!(/^[0-9a-zA-Z]+$/.test(values.BankAccountNumber))) {
			errors.BankAccountNumber = 'Invalid Number';
		}

		if (values.UseIntermediaryBank) {
			if (!values.IntermediaryBankAccountNumber) {
				errors.IntermediaryBankAccountNumber = 'required';
			} else if (!(/^[0-9a-zA-Z]+$/.test(values.IntermediaryBankAccountNumber))) {
				errors.IntermediaryBankAccountNumber = 'Invalid Number';
			}

			if (values.WireType === 'International') {
				if (!values.IntermediaryBankSwiftCode) {
					errors.IntermediaryBankSwiftCode = 'required';
				}
			}

			if (values.WireType === 'Domestic') {
				if (!values.IntermediaryBankRoutingNumber) {
					errors.IntermediaryBankRoutingNumber = 'required';
				} else if (!(/^(\d{9})$/.test(values.IntermediaryBankRoutingNumber))) {
					errors.IntermediaryBankRoutingNumber = 'Invalid Number';
				}
			}
		}

		return errors;
	}


	render() {
		const {
			classes,
			fromUserAccount,
			handleSubmit,
			selectedBankAccount,
			submitSucceeded,
			amount,
			WireTypeValue,
			UseIntermediaryBankValue,
			theme,
			currentTradeAccount,
			currentAccountData: {
				submitting,
			},
			...props
		} = this.props;

		cabinetLog('Wire Withdrawal', currentTradeAccount, props.toBankAccount);

		const wireType = props.toBankAccount && props.toBankAccount.Template && props.toBankAccount.Template.WireType;
		let commissionText = '';
		if (currentTradeAccount.clearerFirm === 'vision') {
			commissionText = wireType === '1' ? 'Wire fee: $60' : 'Wire fee: $35';
		} else if (currentTradeAccount.clearerFirm === 'cor') {
			commissionText = wireType === '1' ? 'Wire fee: $80' : 'Wire fee: $35';
		}

		return (
			<form
				onSubmit={handleSubmit(withdrawFunds.actions.withdrawFundsWireForm)}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<LimeLogo />
						</div>
						<Field component={FormSelect} name="TradeCode" label="Account" disabled>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentTradeAccount.tradeCode}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<WithdrawAmount
					loading={submitting || false}
					availableAmount={WireWithdrawalForm.getCashToWithdraw(this.props)}
					disabled={amount != null}
					additionalText={commissionText}
					onClick={() => { this.props.actions.refreshAccountPortfolio(currentTradeAccount.tradeCode); }}
				/>
				<div className={classes.divider}>
					<Divider />
				</div>
				<p className={classes.warning}>
					{constants.warningForNonTransferableSecuritiesHolders}
				</p>
				{currentTradeAccount.clearerFirm === 'cor' &&
					<div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="Name"
								placeholder="Name"
								label="Name"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="Surname"
								placeholder="Surname"
								label="Surname"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="Address"
								placeholder="Address"
								label="Address"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="ZipCode"
								placeholder="City State Zip"
								label="City State Zip"
							/>
						</div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="Phone"
								placeholder="Phone Number"
								label="Phone Number"
							/>
						</div>
						<div className={classes.divider}>
							<Divider />
						</div>
					</div>
				}
				<div className={classes.item}>
					<Field
						name="WireType"
						orientation="horizontal"
						component={FormRadioGroup}
					>
						<Radio value="Domestic" label="Domestic" />
						<Radio value="International" label="International" />
					</Field>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="BankName"
						placeholder="Bank Name"
						label="Bank Name"
					/>
				</div>
				{WireTypeValue === 'Domestic' &&
					<div className={classes.inputItem}>
						<Field
							component={FormInput}
							name="BankRoutingNumber"
							placeholder="Bank ABA/Routing Number"
							label="Bank ABA/Routing Number"
						/>
					</div>
				}
				{WireTypeValue === 'International' &&
					<div className={classes.inputItem}>
						<Field
							component={FormInput}
							name="BankSwiftCode"
							placeholder="Swift Code"
							label="Swift Code"
						/>
					</div>
				}
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="BankAccountName"
						placeholder="Bank Account Name"
						label="Bank Account Name"
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="BankAddress"
						placeholder="Bank Address"
						label="Bank Address"
						additionalText="City, State, Country"
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="BankAccountNumber"
						placeholder="Bank Account Number"
						label="Bank Account Number"
					/>
				</div>
				<div className={classes.item}>
					<Field
						component={FormCheckbox}
						name="UseIntermediaryBank"
						label="Use Intermediary Bank"
					/>
				</div>
				{UseIntermediaryBankValue &&
					<div>
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="IntermediaryBankName"
								placeholder="Intermediary Bank Name"
								label="Intermediary Bank Name"
							/>
						</div>
						{WireTypeValue === 'Domestic' &&
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="IntermediaryBankRoutingNumber"
									placeholder="Intermediary ABA/Routing Number"
									label="Intermediary ABA/Routing Number"
								/>
							</div>
						}
						{WireTypeValue === 'International' &&
							<div className={classes.inputItem}>
								<Field
									component={FormInput}
									name="IntermediaryBankSwiftCode"
									placeholder="Intermediary Swift Code"
									label="Intermediary Swift Code"
								/>
							</div>
						}
						<div className={classes.inputItem}>
							<Field
								component={FormInput}
								name="IntermediaryBankAccountNumber"
								placeholder="Intermediary Account Number"
								label="Intermediary Account Number"
							/>
						</div>
					</div>
				}
				<div className={classes.divider}>
					<Divider />
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Comment"
						placeholder="Remark or Comment"
						label="Remark or Comment"
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Additional"
						placeholder="Additional Information"
						label="Additional Information"
					/>
				</div>

				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: '#1a96ff' }}
								size={18}
							/>
							: 'Withdraw'
						}
					</Button>
				</div>
			</form>
		);
	}
}

WireWithdrawalForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	toBankAccount: PropTypes.object,
	selectedBankAccount: PropTypes.object,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: WIRE_WITHDRAWAL_FORM_ID,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess('WIRE');
			}
		},
		validate: WireWithdrawalForm.validate,
	}),
)(WireWithdrawalForm);
