import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	userAccounts,
	withdrawFunds,
} from '@redux';
import {
	reduxForm, formValueSelector,
	Field,
} from 'redux-form';
import {
	A,
	FormSelect,
	Button,
	Divider,
	LimeLogo,
	SuccessMessage,
	SvgIcon,
	WithdrawAmount,
	FileInput,
} from '@components';
import { IraTypes } from '@enums';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';

const styles = theme => ({
	row: {
		display: 'flex',
	},
	amount: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	divider: {
		marginTop: '30px',
	},
	button: {
		marginTop: '45px',
	},
});


const CorWithdrawInstruction = () => (
	<React.Fragment>
		<p>Please download the PDF form then fill and sign. Scan and upload the form for processing</p>
		<p><A target="_blank" href="/static/cor/ira/IRADIST_IRA_Distribution_v3.pdf">IRADIST_IRA_Distribution_v3.pdf</A></p>
		<p>Please note periodic distribution and ACH are not an available method of distribution.</p>
	</React.Fragment>
);
const VisionWithdrawInstruction = ({ iraType }) => {
	switch (iraType) {
	case IraTypes.STRATA: return (
		<React.Fragment>
			<p>Please download the PDF form then fill and sign. Scan and upload the form for processing</p>
			<p><A target="_blank" href="/static/vision/ira/STRATA_Trust_IRA_Distribution_Request.pdf">STRATA_Trust_IRA_Distribution_Request.pdf</A></p>
			<p>Please note Partial recurring transfer and ACH are not an available method of distribution.</p>
		</React.Fragment>
	);
	case IraTypes.EQUITY_TRUST: return (
		<React.Fragment>
			<p>Please download the PDF form then fill and sign. Scan and upload the form for processing</p>
			<p><A target="_blank" href="/static/vision/ira/DistributionAccount-Retirement-DistributionRequestForm(IRASEP&Roth).pdf">DistributionAccount-Retirement-DistributionRequestForm(IRASEP&Roth).pdf</A></p>
			<p>Please note frequency of distribution option and journal are not available as a method of distribution.</p>
		</React.Fragment>
	);
	default: return <React.Fragment />;
	}
};

const WithdrawInstruction = ({ clearer, iraType }) => {
	switch (clearer) {
	case 'cor': return <CorWithdrawInstruction />;
	case 'vision': return <VisionWithdrawInstruction iraType={iraType} />;
	default: return <React.Fragment />;
	}
};

const WITHDRAW_FUNDS_IRA_FORM = 'withdrawFundsIraForm';
const formSelector = formValueSelector(WITHDRAW_FUNDS_IRA_FORM);
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const accountInfo = userAccounts.selectors.accountInfo(state);
	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
			Amount: ownProps.amount,
			Attachments: [],
		},
		getFormValue: fieldName => formSelector(state, fieldName),
		currentTradeAccount,
		currentAccountData: userAccounts.selectors.currentAccountData(state),
		iraType: accountInfo.IraType,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		hideWithdrawFundsIraModal: () => dispatch(withdrawFunds.actions.hideWithdrawFundsIraModal()),
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
	},
});

class IraWithdrawalForm extends React.Component {
	static getCashToWithdraw(props) {
		const { currentAccountData: { baseParams, submitting } } = props;
		return !submitting && baseParams && baseParams.CashToWithdrawValue;
	}

	static validate(values, props) {
		const errors = {};
		const cashToWithdraw = IraWithdrawalForm.getCashToWithdraw(props);

		if (!values.Amount || values.Amount <= 0) {
			errors.Amount = 'required';
		} else if (typeof cashToWithdraw === 'number') {
			if (cashToWithdraw < values.Amount) {
				errors.Amount = 'Available amount for withdrawal is less than requested';
			}
		}
		if (!values.Attachments || values.Attachments.length === 0) {
			errors.Attachments = 'required';
		}
		return errors;
	}

	render() {
		const {
			classes,
			actions,
			amount,
			iraType,
			fromUserAccount,
			getFormValue,
			handleSubmit,
			submitSucceeded,
			change,
			currentTradeAccount,
			currentAccountData: {
				submitting,
			},
			form,
			...props
		} = this.props;

		const noAttachments = (getFormValue('Attachments') || []).length === 0;

		return submitSucceeded ? <SuccessMessage
			onClick={actions.hideWithdrawFundsIraModal}
			text="Banking transaction completed"
			buttonText="Thank you"
		/> :
			(
				<form onSubmit={handleSubmit(withdrawFunds.actions.withdrawFundsIraForm)}>
					<div className={classes.accountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								<LimeLogo />
							</div>
							<Field component={FormSelect} name="TradeCode" label="Account" disabled>
								<MenuItem value={currentTradeAccount.tradeCode}>
									<div className={classes.inputContainer}>
										<div className={classes.account}>{currentTradeAccount.tradeCode}</div>
									</div>
								</MenuItem>
							</Field>
						</div>
					</div>
					<WithdrawAmount
						loading={submitting || false}
						availableAmount={IraWithdrawalForm.getCashToWithdraw(this.props)}
						disabled={amount != null}
						onClick={() => { actions.refreshAccountPortfolio(currentTradeAccount.tradeCode); }}
					/>
					<div className={classes.divider}>
						<Divider />
					</div>
					<WithdrawInstruction clearer={fromUserAccount.clearer} iraType={iraType} />
					<div className={classes.fileUploader}>
						<FileInput
							onFilesUpdate={data => change('Attachments', data)}
						/>
					</div>
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={props.invalid || props.submitting || noAttachments}
						>
							{props.submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								: 'Withdraw'
							}
						</Button>
					</div>
				</form>
			);
	}
}

IraWithdrawalForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	fromUserAccount: PropTypes.shape({
		tradeCode: PropTypes.string.isRequired,
		clearer: PropTypes.string.isRequired,
	}).isRequired,
	iraType: PropTypes.oneOf(Object.values(IraTypes)),
};
IraWithdrawalForm.defaultProps = {
	iraType: null,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: WITHDRAW_FUNDS_IRA_FORM,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess('BROKER');
			}
		},
		validate: IraWithdrawalForm.validate,
	}),
)(IraWithdrawalForm);
