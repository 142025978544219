import { userAccounts, profile } from '@redux';
import { TwoFactorAutenticationType } from '@enums';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
	Button,
	SuccessMessage,
} from '@components';

import {
	formValueSelector,
	reduxForm,
} from 'redux-form';

import { QrCodeStep, AuthCodeForm, GoogleInfoStep } from './components';

const TOTP_STEPS = {
	GOOGLE_INFO: 1,
	QR_CODE: 2,
	AUTH_CODE: 3,
};

const TOTP_TWO_FACTOR_AUTHORIZATION_FORM = 'TotpTwoFactorAuthorization';
const formSelector = formValueSelector(TOTP_TWO_FACTOR_AUTHORIZATION_FORM);

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	navButton: {
		margin: '10px 10px',
		padding: '5px',
		width: '120px',
	},
};

const mapStateToProps = state => ({
	initialValues: {
		SecurityCode: '',
	},
	guid: profile.selectors.profile(state).Guid,
	securityCode: (formSelector(state, 'SecurityCode') || ''),
	clientSecretCode: profile.selectors.profile(state).ClientSecretCode,
});

class TotpAuthenticationForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: TOTP_STEPS.GOOGLE_INFO,
		};
	}

	nextClick = () => {
		this.setState(state => ({
			step: state.step + 1,
		}));
	}

	showNextButton = () => {
		const {
			clientSecretCode,
		} = this.props;

		return (this.state.step === TOTP_STEPS.GOOGLE_INFO || (this.state.step === TOTP_STEPS.QR_CODE && clientSecretCode));
	}

	render() {
		const {
			clientSecretCode,
			classes,
			onClose,
			submitSucceeded,
			handleSubmit,
			submitting,
			...props
		} = this.props;

		return (
			<form onSubmit={handleSubmit(profile.actions.changeTotpAuthentication)}>
				<div className={classes.root}>
					{ submitSucceeded &&
						<SuccessMessage onClick={onClose} text="Your request to change second factor authentication has been processed." />
					}
					{ !submitSucceeded &&
						<Fragment>
							{ this.state.step === TOTP_STEPS.GOOGLE_INFO && <GoogleInfoStep /> }
							{ this.state.step === TOTP_STEPS.QR_CODE && <QrCodeStep /> }
							{ this.state.step === TOTP_STEPS.AUTH_CODE &&
								<AuthCodeForm onClose={onClose} submitting={submitting} {...props} /> }
							{ this.showNextButton() &&
								<Button
									className={classes.navButton}
									fullWidth
									type="button"
									onClick={this.nextClick}
									variant="contained"
									color="primary"
								>
									Next
								</Button>
							}
						</Fragment>
					}
				</div>
			</form>
		);
	}
}

TotpAuthenticationForm.propTypes = {
	classes: PropTypes.object.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	clientSecretCode: PropTypes.string,
};

TotpAuthenticationForm.defaultProps = {
	clientSecretCode: null,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps),
	reduxForm({
		form: TOTP_TWO_FACTOR_AUTHORIZATION_FORM,
		validate: AuthCodeForm.validate,
	}),
)(TotpAuthenticationForm);
