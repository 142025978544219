import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ProgressContainer, A } from '@components';
import { userAccounts } from '@redux';
import { IpoCalendar, IpoOrders } from './components';
import PoweredByClickIPO from './ClickIPO-Logo-Blue-Lettering---Powered-By.png';

const styles = theme => ({
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	header: {
		...theme.typography.style.headline1,
		...theme.typography.color.primary,
		padding: '8px 0',
	},
	contentItem: {
		marginBottom: '30px',
	},
	clickIpoLogoContainer: {
		...theme.typography.style.body,
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		marginBottom: '30px',
	},
	clickIpoLogo: {
		width: '120px',
	},
	textContainer: {
		color: theme.typography.color.secondary,
		paddingRight: '5px',
		textAlign: 'center',
		fontSize: '12px',
	},
});

const mapStateToProps = state => ({
	loading: userAccounts.selectors.loading(state),
});

class IpoContent extends React.Component {
	render() {
		const {
			classes,
			loading,
		} = this.props;

		return (
			<div className={classes.content}>
				<div>
					<div className={classes.header}>
						Initial Public Offering
					</div>
				</div>
				<ProgressContainer submitting={loading}>
					<div className={classes.contentItem}>
						<IpoOrders />
					</div>
					<div className={classes.contentItem}>
						<IpoCalendar />
					</div>
					<div className={classes.clickIpoLogoContainer}>
						<Grid container spacing={4}>
							<Grid item lg={10} md={10} sm={12} xs={12} className={classes.textContainer}>
								<span>Risk of Investing in Initial Public Offerings ("IPOs")
There are specific risks in investing in an Initial Public Offering ("IPO").
Among other things, the stock has not been subject to market valuation.
Those risks are described at length in the prospectus, and we urge you to read the prospectus
carefully to understand those risks before investing. In addition to the prospectus,
									<A href="https://clickipo.com/wp-content/uploads/IPO-Specific-Risks.pdf" upper target="_blank"> read more information </A>
								regarding the significant risks associated with investing in IPOs.
								</span>
							</Grid>
							<Grid item lg={2} md={2} sm={12} xs={12}>
								<img className={classes.clickIpoLogo} src={PoweredByClickIPO} alt="Powered By ClickIPO" />
							</Grid>
						</Grid>
					</div>
				</ProgressContainer>
			</div>
		);
	}
}

IpoContent.propTypes = {
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(IpoContent);
