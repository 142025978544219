import actions from './actions';
import auth from '../auth';

const initialState = {
	requestEmail: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.resetByEmail.REQUEST:
		return {
			...state,
			requestEmail: payload.Email,
		};
	case auth.actions.LOGOUT_SUCCESS:
		return { ...initialState };
	default:
		return state;
	}
};
