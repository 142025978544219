module.exports = {
	OptionLevel: {
		Level0: '0',
		Level1: '1',
		Level2: '2',
		Level3: '3',
		Level4: '4',
		Level5: '5',
	},
};
