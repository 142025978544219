import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { userAccounts } from '@redux';
import { toUsaMoneyFormat } from '@global';
import PropTypes from 'prop-types';
import { ValueChange } from '..';

const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexFlow: 'wrap',
			alignItems: 'flex-start',
		},
	},
	leftColumn: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	rightColumn: {
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			marginTop: '24px',
			'& $valueContainer': {
				flexDirection: 'row-reverse',
			},
		},
		[theme.breakpoints.up('md')]: {
			'& $percentsChange': {
				marginLeft: '0px',
				marginRight: '24px',
			},
		},
	},
	value: {
		...theme.typography.style.subheading,
		color: theme.typography.color.primary,
	},
	valueContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	percentsChange: {
		marginLeft: '24px',
	},
	description: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
	},
});


const mapStateToProps = state => ({
	charts: userAccounts.selectors.charts(state),
});

class ChangeInfo extends React.Component {
	render() {
		const {
			classes,
			points,
		} = this.props;
		const firstVal = (points && points.length > 1 && points[0].V) || 0;
		const yesterdayVal = (points && points.length > 1 && points[points.length - 2].V) || 0;
		const todayVal = (points && points.length > 0 && points[points.length - 1].V) || 0;

		const dayChangeValue = todayVal - yesterdayVal;
		const dayChangePercent = (100 * (todayVal - yesterdayVal)) / yesterdayVal;

		const periodChangeValue = todayVal - firstVal;
		const periodChangePercent = (100 * (todayVal - firstVal)) / firstVal;

		return (
			<div className={classes.root}>
				<div className={classes.leftColumn}>
					<div className={classes.valueContainer}>
						<div className={classes.value}>
							{toUsaMoneyFormat(dayChangeValue)}
						</div>
						<div className={classes.percentsChange}>
							<ValueChange value={dayChangePercent} />
						</div>
					</div>
					<div className={classes.description}>
						Day Change
					</div>
				</div>
				<div className={classes.rightColumn}>
					<div className={classes.valueContainer}>
						<div className={classes.percentsChange}>
							<ValueChange value={periodChangePercent} />
						</div>
						<div className={classes.value}>
							{toUsaMoneyFormat(periodChangeValue)}
						</div>
					</div>
					<div className={classes.description}>
						Period Change
					</div>
				</div>
			</div>
		);
	}
}

ChangeInfo.propTypes = {
	points: PropTypes.arrayOf(PropTypes.shape({
		V: PropTypes.number.isRequired,
	})),
};

ChangeInfo.defaultProps = {
	points: [],
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(ChangeInfo);
