import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalPopups } from '@enums';
import { ipo, userAccounts } from '@redux';
import { Modal, SuccessMessage } from '@components';
import { IpoCreateOrderForm } from '@shared';

const styles = {};

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	ipoCreatingOrder: ipo.selectors.ipoCreatingOrder(state),
});

class IpoCreateOrderModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			succeeded: false,
		};
	}

	onSuccess = () => {
		this.setState({ succeeded: true });
	}

	render() {
		const {
			currentTradeAccount,
			ipoCreatingOrder,
			onClose,
		} = this.props;

		const { succeeded } = this.state;

		return (
			<Modal
				title="IPO Create Order"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.IPO_CREATE_ORDER}`}
			>
				{succeeded ?
					<SuccessMessage onClick={onClose} text="IPO transaction completed" />
					:
					<IpoCreateOrderForm
						currentTradeAccount={currentTradeAccount}
						order={ipoCreatingOrder}
						onSuccess={this.onSuccess}
					/>
				}
			</Modal>
		);
	}
}

IpoCreateOrderModal.propTypes = {
	currentTradeAccount: PropTypes.object.isRequired,
	ipoCreatingOrder: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(IpoCreateOrderModal);
