import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { profile } from '@redux';
import {
	ChangeLink,
	Input,
	InputPhoneIconNumber,
} from '@components';
import EmploymentPanel from './EmploymentPanel';

const valueOrDash = val => val || '-';


const JointHolderInformation = ({
	holder,
	classes,
}) => {
	const allHolders = useSelector(profile.selectors.holders);
	const dispatch = useDispatch();

	const holderProfile = holder ? allHolders.Profiles.find(x => holder.ClientId === x.ClientId) : null;
	const actions = {
		showChangeAddressModal: () => dispatch(profile.actions.showChangeAddressModal(holderProfile)),
		showChangeEmailModal: () => dispatch(profile.actions.showChangeEmailModal({ profile: holderProfile, forceConfirmationEmail: true })),
		showChangePhoneModal: () => dispatch(profile.actions.showChangePhoneModal(holderProfile)),
		showChangeEmploymentModal: () => dispatch(profile.actions.showChangeEmploymentModal(holderProfile)),
	};

	if (!allHolders.loaded || allHolders.error || !holderProfile) {
		return <React.Fragment />;
	}

	return (
		<React.Fragment>
			<div className={classes.editable}>
				<div className={classes.editValue}>
					<Input
						multiline
						style={{ maxWidth: '80%' }}
						placeholder="Address"
						showLabel
						value={valueOrDash(holderProfile.PersonalInformation.Address.FullAddress)}
						disabled
					/>
				</div>
				<div className={classes.editItem}>
					<ChangeLink onClick={actions.showChangeAddressModal} havePendingOrder={holderProfile.PendingOrders.Address} />
				</div>
			</div>
			<div className={classes.editable}>
				<div className={classes.editValue}>
					<Input placeholder="Email" showLabel value={valueOrDash(holderProfile.PersonalInformation.Email)} disabled />
				</div>
				<div className={classes.editItem}>
					<ChangeLink onClick={actions.showChangeEmailModal} havePendingOrder={holderProfile.PendingOrders.Email} />
				</div>
			</div>
			<div className={classes.editable}>
				<div className={`${classes.editValue} ${classes.editValuePhone}`}>
					<InputPhoneIconNumber
						placeholder="Phone"
						showLabel
						onPhoneChange={() => {}}
						onChange={() => {}}
						value={valueOrDash(holderProfile.PersonalInformation.Phone)}
						disabled
						hideNumberMode
					/>
				</div>
				<div className={classes.editItem}>
					<ChangeLink onClick={actions.showChangePhoneModal} havePendingOrder={holderProfile.PendingOrders.Phone} />
				</div>
			</div>
			<EmploymentPanel
				classes={classes}
				profile={holderProfile}
			/>
		</React.Fragment>
	);
};

JointHolderInformation.defaultProps = {
	holder: undefined,
};

JointHolderInformation.propTypes = {
	holder: PropTypes.shape({
		ClientId: PropTypes.number,
		Guid: PropTypes.string,
	}),
	classes: PropTypes.object.isRequired,
};

export default JointHolderInformation;
