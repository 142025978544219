import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';


// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => createStyles({
	mr60: {
		marginRight: '60px',
	},
}));

const ExpansionPanelHeaderEditable = (props) => {
	const {
		title,
		children,
	} = props;
	const classes = useStyles(props);
	return (
		<span>
			<span className={classes.mr60}>{title}</span>
			<span>
				{children}
			</span>
		</span>
	);
};

ExpansionPanelHeaderEditable.propTypes = {
	title: PropTypes.string.isRequired,
};
export default ExpansionPanelHeaderEditable;
