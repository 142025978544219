import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, withTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { dashboard, userAccounts, app } from '@redux';
import { Select, SvgIcon, R } from '@components';
import { createQAAttribute, scrollToTop, toUsaMoneyFormat } from '@global';
import MenuItem from '@material-ui/core/MenuItem';
import { template } from '@templates';
import { env } from '@config';
import { AccountStatuses, AccountClearers } from '@enums';

const styles = theme => ({
	root: {
		backgroundColor: theme.typography.color.primary,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		minHeight: '176px',
		padding: '50px 10px 10px 24px',
		'& > div:first-child, & > div:nth-child(2)': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	},
	rootDefaultImg: {
		backgroundImage: `url(${template.bgLogo}), linear-gradient(-170deg, #009A63	0%, #287233 93%)`,
	},
	select: {
	},
	title: {
		...theme.typography.style.body,
		color: theme.typography.color.white,
	},
	equity: {
		...theme.typography.style.headlineSm,
		color: theme.typography.color.white,
	},
	currentAccountContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		cursor: 'pointer',
		[theme.breakpoints.up('sm')]: {
			marginRight: '-12px',
		},
	},
	currentAccount: {
		...theme.typography.style.body,
		color: theme.typography.color.white,
		display: 'inline-block',
	},
	selectButton: {
		...theme.typography.style.body,
		color: theme.typography.color.white,
		stroke: theme.typography.color.white,
	},
	specialMenuItem: {
		fontSize: '14px',
		color: theme.typography.color.secondary,
	},
	accountCloseDemoText: {
		...theme.typography.style.body,
		color: '#ff6567',
		marginLeft: '50px',
		fontSize: '1rem',
	},
	closedAccountLabelText: {
		...theme.typography.style.body,
		color: '#ff6567',
		fontWeight: '500',
		fontSize: '22px',
	},
	splitterTop: {
		borderTop: '1px solid #deeaef',
	},
	splitterBottom: {
		borderBottom: '1px solid #deeaef',
	},
	refreshButton: {
		color: theme.typography.color.white,
		[theme.breakpoints.up('sm')]: {
			marginRight: '-12px',
		},
	},
	lightToolTip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
});


const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		setCurrentAccount: account => dispatch(userAccounts.actions.setCurrentAccount(account)),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
		addDemoAccount: () => dispatch(userAccounts.actions.addDemoAccount.request()),
		showResetDemoAccountModal: () => dispatch(userAccounts.actions.showResetDemoAccountModal()),
		showOpenSecondAccountModal: () => dispatch(userAccounts.actions.showOpenSecondAccountModal()),
	},
});


const mapStateToProps = (state) => {
	const paperAccount = userAccounts.selectors.getPaperAccount(state);
	const currentAccountObject = userAccounts.selectors.currentAccountObject(state);
	const accounts = userAccounts.selectors.accounts(state);

	return {
		accounts,
		currentAccountData: userAccounts.selectors.currentAccountData(state),
		currentAccount: userAccounts.selectors.currentAccount(state),
		currentAccountDisplayFullName: userAccounts.selectors.currentAccountDisplayFullName(state),
		currentAccountIsDemoOrPaper: userAccounts.selectors.currentAccountIsDemo(state)
			|| userAccounts.selectors.currentAccountIsPaper(state),
		loading: userAccounts.selectors.loading(state),
		showPaperAccountItem: !paperAccount &&
			accounts.some(acc => (acc.Status === AccountStatuses.ACTIVE || acc.Status === AccountStatuses.RESTRICTED) &&
			!acc.IsDemo && acc.ClearerFirm !== AccountClearers.SPC_FUTURES),
		showResetButton: (currentAccountObject && currentAccountObject.IsDemo && !currentAccountObject.IsClosed)
			|| (currentAccountObject && currentAccountObject.IsPaper && !currentAccountObject.IsClosed),
		showOpenAnotherAccount: accounts.some(acc => !acc.IsClosed && acc.ClearerFirm === AccountClearers.VISION),
	};
};

class AccountSelection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showClosedAccounts: false,
			isSelectOpened: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSelectOpen = this.handleSelectOpen.bind(this);
	}

	dontCloseSelect = false;

	handleChange(event) {
		if (event.target.value === 'toggleClosedAccounts') {
			this.setState({ showClosedAccounts: !this.state.showClosedAccounts });
			this.dontCloseSelect = true;
			return;
		}

		if (event.target.value === 'addDemoAccount') {
			this.props.actions.addDemoAccount();
			return;
		}

		if (event.target.value === 'openAnotherAccount') {
			this.props.actions.showOpenSecondAccountModal();
			return;
		}

		const account = this.props.accounts.find(acc => acc.TradeCode === event.target.value);
		this.props.actions.setCurrentPage('/dashboard');
		this.props.actions.setCurrentAccount(account);
		scrollToTop();
		this.props.actions.sidebarClose();
	}

	handleSelectOpen() {
		if (this.dontCloseSelect) {
			this.dontCloseSelect = false;
			this.setState({ isSelectOpened: true });
			return;
		}
		this.setState({ isSelectOpened: !this.state.isSelectOpened });
	}

	handleResetDemoAccount = () => {
		const {
			actions,
		} = this.props;

		actions.showResetDemoAccountModal();
	}

	render() {
		const {
			currentAccountDisplayFullName,
			currentAccountData: { baseParams },
			currentAccountIsDemoOrPaper,
			classes,
			loading,
			accounts,
			theme,
			showPaperAccountItem,
			showResetButton,
			showOpenAnotherAccount,
		} = this.props;
		let closedAccountsExist = false;
		return (
			<div className={classNames(classes.root, classes.rootDefaultImg)}>
				<div className={classes.title} {...createQAAttribute('total_label')}>
					{ currentAccountIsDemoOrPaper ?
						'Paper money' :
						<R id="common_total_account_value" defaultValue="Total Account Value" />}
				</div>
				<div>
					<div className={classes.equity} {...createQAAttribute('total_value')}>
						{ loading || !baseParams ?
							<CircularProgress
								style={{ color: theme.typography.color.white }}
								size={24}
							/>
							: baseParams && toUsaMoneyFormat(baseParams.EquityValue)
						}
					</div>
					{!loading && showResetButton &&
						<Tooltip title="Edit account" classes={{ tooltip: classes.lightToolTip }}>
							<IconButton className={classes.refreshButton} onClick={this.handleResetDemoAccount}>
								<SvgIcon icon="Edit" />
							</IconButton>
						</Tooltip>
					}
				</div>
				{baseParams && baseParams.IsClosed &&
				<div className={classes.closedAccountLabelText}>CLOSED</div>
				}
				<div className={classes.currentAccountContainer} onClick={this.handleSelectOpen}>
					<div className={classes.currentAccount} {...createQAAttribute('current_account')}>
						{currentAccountDisplayFullName}
					</div>
					<div className={classes.selectButton}>
						<IconButton {...createQAAttribute('account_list')}>
							<SvgIcon icon="ArrowDown" />
						</IconButton>
					</div>
					<Select
						value={this.props.currentAccount}
						onChange={this.handleChange}
						open={this.state.isSelectOpened}
						onOpen={this.handleSelectOpen}
						onClose={() => {}}
						isHidden
					>
						{ env.WEBCAB_5839_allow_demo_account &&
							showPaperAccountItem &&
							<MenuItem
								value="addDemoAccount"
								key={222}
								className={`${classes.specialMenuItem} ${classes.splitterBottom}`}
								{...createQAAttribute('paper_item')}
							>
								Add Paper trading account
							</MenuItem>
						}

						{accounts && accounts.map((account, index) => {
							if (account.IsClosed) {
								closedAccountsExist = true;
							}
							if (!account.IsClosed || this.props.currentAccount === account.TradeCode || this.state.showClosedAccounts) {
								if (account.IsClosed) {
									return (
										<MenuItem value={account.TradeCode} key={index} {...createQAAttribute('account_item')}>
											<p>{account.DisplayFullName || account.TradeCode}</p>
											<p className={classes.accountCloseDemoText}>CLOSED</p>
										</MenuItem>);
								}
								if (account.IsDemo || account.IsPaper) {
									return (
										<MenuItem value={account.TradeCode} key={index} {...createQAAttribute('account_item')}>
											{account.DisplayFullName || account.TradeCode}
											<span className={classes.accountCloseDemoText}>DEMO</span>
										</MenuItem>);
								}
								return (
									<MenuItem
										value={account.TradeCode}
										key={index}
										{...createQAAttribute('account_item')}
									>
										{account.DisplayFullName || account.TradeCode}
									</MenuItem>);
							}
							return '';
						})}
						{showOpenAnotherAccount &&
							<MenuItem
								value="openAnotherAccount"
								key={333}
								className={`${classes.specialMenuItem} ${classes.splitterTop}`}
								{...createQAAttribute('another_item')}
							>
								Open another account
							</MenuItem>
						}
						{closedAccountsExist &&
						<MenuItem
							value="toggleClosedAccounts"
							key={111}
							className={`${classes.specialMenuItem} ${classes.splitterTop}`}
							{...createQAAttribute('closed_item')}
						>
							{this.state.showClosedAccounts ?
								<R id="common_hide_closed_accounts" defaultValue="Hide closed accounts" /> :
								<R id="common_show_closed_accounts" defaultValue="Show closed accounts" />
							}
						</MenuItem>
						}
					</Select>

				</div>
			</div>
		);
	}
}

AccountSelection.propTypes = {
	currentAccount: PropTypes.string.isRequired,
	currentAccountDisplayFullName: PropTypes.string.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	accounts: PropTypes.array.isRequired,
	actions: PropTypes.shape({
		setCurrentAccount: PropTypes.func.isRequired,
		addDemoAccount: PropTypes.func.isRequired,
		setCurrentPage: PropTypes.func.isRequired,
		sidebarClose: PropTypes.func.isRequired,
		showOpenSecondAccountModal: PropTypes.func.isRequired,
	}).isRequired,
	currentAccountIsDemoOrPaper: PropTypes.bool,
	showPaperAccountItem: PropTypes.bool.isRequired,
	showResetButton: PropTypes.bool,
	theme: PropTypes.object.isRequired,
	showOpenAnotherAccount: PropTypes.bool.isRequired,
};

AccountSelection.defaultProps = {
	currentAccountIsDemoOrPaper: false,
	showResetButton: false,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
)(AccountSelection);
