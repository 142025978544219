import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import { toUsaFormat, toUsaMoneyFormat } from '@global';

const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between',
		},
	},
	leftColumn: {
		...theme.typography.style.body,
		color: theme.typography.color.secondary,
		display: 'flex',
		flexDirection: 'column',
	},
	rightColumn: {
		[theme.breakpoints.up('sm')]: {
			width: '25%',
		},
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
	},
});

class FeeItem extends React.Component {
	getPrecision = (num) => {
		const s = `${num}`;
		const d = s.indexOf('.') + 1;
		return !d ? 0 : s.length - d;
	};

	render() {
		const {
			item,
			classes,
		} = this.props;

		const maxPrecision = Math.max(this.getPrecision(item.GrossAmount), item.Fees.reduce((prevMax, current) => Math.max(prevMax, this.getPrecision(current.Amount)), 2));

		return (
			<div className={classes.root}>
				<div className={classes.mobileAdapter} />
				<div className={classes.leftColumn}>
					<span>Principal Amount</span>
					{item.Fees.map((fee, i) => <span key={`${i}_name`}>{fee.Name}</span>)}
				</div>
				<div className={classes.rightColumn}>
					<span>
						{item.GrossAmount && toUsaFormat(item.GrossAmount, maxPrecision)}
					</span>
					{item.Fees.map((fee, i) => (
						<span key={`${i}_amount`}>
							{toUsaFormat(fee.Amount, maxPrecision)}
						</span>
					))}
				</div>
			</div>
		);
	}
}

FeeItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	SafeComponent,
)(FeeItem);
