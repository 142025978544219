import { fork, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { cancelIpoOrder, createIpoOrder, getOffering, getOfferings, getOrders } from '@api';
import { ModalPopups } from '@enums';
import { addHashToUrl } from '@global';
import { BaseGetSagaHandler, userAccounts } from '@redux';
import actions from './actions';


function* watchGetOfferingRequest() {
	yield takeEvery(actions.getOffering.REQUEST, BaseGetSagaHandler, {
		apiMethod: getOffering,
		errorText: 'Get IPO Offering',
		handler: actions.getOffering,
	});
}
function* watchGetOfferingsRequest() {
	yield takeEvery(actions.getOfferings.REQUEST, BaseGetSagaHandler, {
		apiMethod: getOfferings,
		errorText: 'Get IPO Offerings',
		handler: actions.getOfferings,
		defaults: { page: 1, pageSize: 10, type: 'any' },
	});
}

function* watchGetOrdersRequest() {
	yield takeEvery(actions.getOrders.REQUEST, BaseGetSagaHandler, {
		apiMethod: getOrders,
		errorText: 'Get IPO Orders',
		handler: actions.getOrders,
		defaults: { page: 1, pageSize: 10, type: 'any' },
	});
}

function* createIpoOrderRequestSaga({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: createIpoOrder,
		errorText: 'Create IPO Order',
		handler: actions.createIpoOrder,
	}, { payload });

	if (isSuccess) {
		// reload first page
		yield put(actions.getOfferings.request({
			TradeCode: payload.TradeCode,
			ClearerFirm: payload.ClearerFirm,
			page: 1,
			pageSize: 10,
		}));

		yield put(actions.getOrders.request({
			TradeCode: payload.TradeCode,
			ClearerFirm: payload.ClearerFirm,
			page: 1,
			pageSize: 10,
		}));
	}
}

function* watchCreateIpoOrderRequest() {
	yield takeEvery(actions.createIpoOrder.REQUEST, createIpoOrderRequestSaga);
}

function* cancelIpoOrderRequestSaga({ payload: { onEndRequest, OrderId } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: cancelIpoOrder,
		errorText: 'Cancel IPO Order',
		handler: actions.cancelIpoOrder,
		onEndRequest,
	}, { payload: { OrderId } });

	if (isSuccess) {
		const currentAccount = yield select(userAccounts.selectors.currentTradeAccount);

		// reload first page
		yield put(actions.getOfferings.request({
			TradeCode: currentAccount.tradeCode,
			ClearerFirm: currentAccount.clearerFirm,
			page: 1,
			pageSize: 10,
		}));

		yield put(actions.getOrders.request({
			TradeCode: currentAccount.tradeCode,
			ClearerFirm: currentAccount.clearerFirm,
			page: 1,
			pageSize: 10,
		}));
	}
}

function* watchCancelOrderRequest() {
	yield takeEvery(actions.cancelIpoOrder.REQUEST, cancelIpoOrderRequestSaga);
}


function* showIpoCreateOrderModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.IPO_CREATE_ORDER)));
}

function* watchShowIpoCreateOrderModal() {
	yield takeEvery(actions.SHOW_IPO_CREATE_ORDER_MODAL, showIpoCreateOrderModal);
}

function* showIpoExtendedInfoMobileModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(addHashToUrl(location, ModalPopups.IPO_EXTENDED_INFO_MOBILE)));
}

function* watchShowIpoExtendedInfoMobileModal() {
	yield takeEvery(actions.SHOW_IPO_EXTENDED_INFO_MOBILE_MODAL, showIpoExtendedInfoMobileModal);
}

// Root saga
const rootSaga = [
	fork(watchGetOfferingRequest),
	fork(watchGetOfferingsRequest),
	fork(watchGetOrdersRequest),
	fork(watchCreateIpoOrderRequest),
	fork(watchCancelOrderRequest),
	fork(watchShowIpoCreateOrderModal),
	fork(watchShowIpoCreateOrderModal),
	fork(watchShowIpoExtendedInfoMobileModal),
];

export default {
	rootSaga,
};
