import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from '@global';
import { ModalPopups } from '@enums';
import { app, userAccounts } from '@redux';
// import { imgMalaysiyanBank } from '@content/paysystemLogos';
import BankAccountItem from '../BankAccountItem/BankAccountItem';

const BankAccountItemMalayBankCY = (props) => {
	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);
	const dispatch = useDispatch();

	const showWithdrawFundsModal = (bankAccount) => {
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};
		dispatch(app.actions.showBaseModal(ModalPopups.CY_WITHDRAWAL_MALAYSIAN_BANK, { userAccount, bankAccount }));
	};

	const handleWithdrawal = (bankAccount) => {
		showWithdrawFundsModal(bankAccount);
	};


	return (
		<BankAccountItem
			isLimeInternationalStyle
			bankAccount={{
				Type: 'MalaysianBankWithdraw',
				displayNumber: translate('orders_selectmethod_malaysianbank_title', 'Malaysian Bank Account'),
				// LogoFullFileName: imgMalaysiyanBank,
			}}
			hasDepositButton={() => false}
			hasWithdrawalButton={() => true}
			onWithdrawFundsModal={handleWithdrawal}
		/>
	);
};

export default BankAccountItemMalayBankCY;
