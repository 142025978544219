import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { app, etnaAuth, userAccounts, profile } from '@redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import {
	ProgressContainer,
	SvgIcon,
} from '@components';
import { createQAAttribute, toUsaDateFormat } from '@global';

const styles = theme => ({
	profileTableHeader: {
		display: 'flex',
		fontSize: '13.5px',
		color: theme.typography.color.secondary,
		padding: '0 24px 16px',
	},
	profileTableRow: {
		display: 'flex',
		fontSize: '18px',
		color: theme.typography.color.disable,
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '4px 24px',
		borderBottom: '1px solid #deeaef',
	},
	profileTableColName: {
		marginRight: '10px',
		flexGrow: 2,
		'$profileTableHeader &': {
			flex: '1 0 50px',
		},
	},
	profileTableColDate: {
		minWidth: '100px',
	},
	profileTableColLink: {
		marginLeft: '10px',
	},
	dashboardLink: {
		...theme.typography.color.white,
	},
	expansionPanelStaticContent: {
		margin: '10px 24px 5px',
		color: theme.palette.text.secondary,
	},
	buttonsContainer: {
		...theme.typography.style.button,
		color: theme.typography.color.link,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		margin: '28px',
	},
	noIcon: {
		display: 'inline-block',
		width: '48px',
	},
	opraSwitcher: {
		margin: '0px 70px',
	},
	lightTooltip: {
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
});
const useStyles = makeStyles(styles);
const useActions = () => {
	const dispatch = useDispatch();
	return ({
		getApiKeys: () => dispatch(etnaAuth.actions.getApiKeys.request()),
		showConfirmModal: payload => dispatch(app.actions.showConfirmModal(payload)),
		revokeApiKey: payload => dispatch(etnaAuth.actions.revokeApiKey.request(payload)),
		showCreateApiKeyModal: () => dispatch(etnaAuth.actions.showCreateApiKeyModal()),
		apiMarketDataAgreement: payload => dispatch(etnaAuth.actions.apiMarketDataAgreement.request(payload)),
		updateMarketDataSubscription: subscription => dispatch(profile.actions.updateMarketDataSubscription(subscription)),
	});
};

const ApiKeysPanel = (props) => {
	const classes = useStyles();
	const apiClients = useSelector(etnaAuth.selectors.clients);
	const hasOpenAccount = useSelector(userAccounts.selectors.hasOpenAccount);
	const actions = useActions();
	const userInfo = useSelector(userAccounts.selectors.userInfo);
	const {
		MarketDataSubscriptions,
	} = useSelector(profile.selectors.profile);

	const opraSubscription = MarketDataSubscriptions.find(sub => sub.MarketDataName === 'OPRA');

	const opraMarketDataName = 'opraMarketData';
	const [state, setState] = useState({
		[opraMarketDataName]: opraSubscription && opraSubscription.Api,
	});

	useEffect(() => {
		actions.getApiKeys();
	}, []);

	const showRevokeModal = client => () => {
		actions.showConfirmModal({
			text: `Please click confirm you want to revoke api key "${client.DisplayName}"`,
			action: onEndRequest => actions.revokeApiKey({
				id: client.ClientId,
				onEndRequest,
			}),
		});
	};

	const handleOpraMarketDataChanged = (event) => {
		const apiIsOn = event.target.checked;
		setState({ [opraMarketDataName]: apiIsOn });
		actions.apiMarketDataAgreement({ isOn: apiIsOn });
		actions.updateMarketDataSubscription({ ...opraSubscription, Api: apiIsOn });
	};

	return (
		<ProgressContainer submitting={apiClients.submitting}>
			<React.Fragment>
				<div>
					{!apiClients.items.length ? (
						<div className={classes.expansionPanelStaticContent} {...createQAAttribute('api_keys_no_keys')}>
							No API Keys to show
						</div>) :
						(
							<div className={classes.profileTableHeader}>
								<div className={classes.profileTableColName} {...createQAAttribute('api_keys_name')}>Name</div>
								<div className={classes.profileTableColDate} {...createQAAttribute('api_keys_generated')}>Generated On</div>
								<div className={classes.profileTableColLink}><div className={classes.noIcon} /></div>
							</div>
						)
					}
					{apiClients.items.map(item => (
						<div className={classes.profileTableRow} key={item.ClientId}>
							<div
								className={classes.profileTableColName}
								{...createQAAttribute(`api_keys_name_${item.DisplayName}`)}
							>
								{item.DisplayName}
							</div>
							<div
								className={classes.profileTableColDate}
								{...createQAAttribute(`api_keys_date_${toUsaDateFormat(item.CreatedAt)}`)}
							>
								{toUsaDateFormat(item.CreatedAt)}
							</div>
							<div className={classes.profileTableColLink}>
								<IconButton onClick={showRevokeModal(item)} {...createQAAttribute('api_keys_delete')}>
									<SvgIcon icon="Delete" />
								</IconButton>
							</div>
						</div>
					))}
				</div>
				{apiClients.items.length > 0 && opraSubscription && opraSubscription.IsOn &&
					<div className={classes.profileTableRow}>
						<div className={classes.profileTableColName} {...createQAAttribute('api_keys_opra_subscription')}>
							OPRA Market data
							<Tooltip
								classes={{ tooltip: classes.lightTooltip }}
								title="To access OPRA Market data by API, please slide the toggle button displayed on your screen.
										OPRA Market data will be accessible for one trading day. The change will take effect in 5 minutes."
								{...createQAAttribute('api_keys_tooltip')}
							>
								<IconButton>
									<SvgIcon icon="Help" htmlColor="black" />
								</IconButton>
							</Tooltip>
						</div>
						<div className={classes.opraSwitcher}>
							<Switch
								checked={state.opraMarketData}
								onChange={handleOpraMarketDataChanged}
								color="primary"
								name={opraMarketDataName}
								inputProps={{ 'aria-label': 'primary checkbox', ...createQAAttribute('api_keys_checkbox') }}
							/>
						</div>
					</div>
				}
				{hasOpenAccount && !userInfo.BlockTradingAccess &&
					<div
						className={classes.buttonsContainer}
						onClick={actions.showCreateApiKeyModal}
						{...createQAAttribute('api_keys_generate')}
					>
						Generate new
					</div>
				}
			</React.Fragment>
		</ProgressContainer>

	);
};

export default ApiKeysPanel;
