import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ProgressContainer, R } from '@components';
import { constants, env } from '@config';
import { userAccounts } from '@redux';
import { CashManagement } from './components';
import { CYCashManagement } from './components/CashManagement/CYCashManagement';
import { PaymentGateCallback } from './components/CashManagement/PaymentGateCallback';

const styles = theme => ({
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	contentItem: {
		marginBottom: '30px',
	},
	endItem: {
		height: '30px',
	},
	header: {
		...theme.typography.style.headline1,
		...theme.typography.color.primary,
		padding: '8px 0',
	},
});

const mapStateToProps = state => ({
	accountInfo: userAccounts.selectors.accountInfo(state),
});


class CashManagementContent extends React.Component {
	render() {
		const { 
			classes,
			accountInfo,
			match,
		} = this.props;

		return (
			<div className={classes.content}>
				<div>
					<div className={classes.header}>
						<R id="common_menu_cash_management" defaultValue="Cash Management" />
					</div>
				</div>
				<div className={classes.contentItem}>
					<Switch>
						<Route exact path={`${match.url}/success`} component={() => <PaymentGateCallback title="success" />} />
						<Route exact path={`${match.url}/fail`} component={() => <PaymentGateCallback title="fail" />} />
						<Route exact path={`${match.url}/complete`} component={() => <PaymentGateCallback title="complete" />} />
					</Switch>
					<ProgressContainer submitting={accountInfo.loading} disableCheckChildren display="none">
						{env.firm === constants.LIME_FIN
							? <CashManagement />
							: <CYCashManagement />
						}
					</ProgressContainer>
				</div>
				<div className={classes.endItem} />
			</div>
		);
	}
}

CashManagementContent.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(CashManagementContent);
