import actions from './actions';
import auth from '../auth';

const initialState = {
	message: null,
	notifications: {
		loading: false,
		pagination: {},
		unreadMessagesCount: 0,
		items: [],
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case actions.SHOW_MESSAGE_MODAL:
		return {
			...state,
			message: payload.message,
		};
	case actions.HIDE_MESSAGE_MODAL:
		return {
			...state,
			message: null,
		};

	case actions.GET_NOTIFICATIONS_REQUEST:
		return {
			...state,
			notifications: {
				loading: true,
				pagination: {},
				unreadMessagesCount: 0,
				items: [],
			},
		};

	case actions.GET_NOTIFICATIONS_SUCCESS:
		return {
			...state,
			notifications: {
				loading: false,
				pagination: {
					...payload.pagination,
				},
				unreadMessagesCount: payload.data.UnreadMessagesCount,
				items: [
					...payload.data.Notifications,
				],
			},
		};

	case actions.GET_MORE_NOTIFICATIONS_REQUEST:
		return {
			...state,
			notifications: {
				loading: true,
				pagination: { ...state.notifications.pagination },
				unreadMessagesCount: state.notifications.unreadMessagesCount,
				items: [...state.notifications.items],
			},
		};

	case actions.GET_MORE_NOTIFICATIONS_SUCCESS:
		return {
			...state,
			notifications: {
				loading: false,
				pagination: {
					...payload.pagination,
				},
				unreadMessagesCount: state.notifications.unreadMessagesCount,
				items: [
					...state.notifications.items,
					...payload.items,
				],
			},
		};

	case actions.MARK_NEWS_AS_READ:
		return {
			...state,
			notifications: {
				pagination: state.notifications.pagination,
				unreadMessagesCount: state.notifications.unreadMessagesCount - 1,
				items: state.notifications.items.map(item => ({
					...item,
					Read: (item.Id === payload.messageId) ? true : item.Read,
				})),
			},
		};

	case actions.markNewsAllAsRead.SUCCESS:
		return {
			...state,
			notifications: {
				...initialState.notifications,
			},
		};
	case auth.actions.LOGOUT_SUCCESS:
		return { ...initialState };
	default:
		return state;
	}
};
