import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { SvgIcon } from '@components';
import {
	OrderExtendedInfo,
	OrderMainInfo,
} from '.';


const styles = theme => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	headContainer: {
		width: '100%',
		marginRight: '14px',
	},
	headContent: {
		...theme.typography.style.title,
		color: theme.palette.color.primary.black,
		width: '100%',
	},
	arrowIcon: {
		stroke: theme.typography.color.secondary,
	},
	content: {
		paddingTop: '16px',
	},
});


class IpoOrderItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	toggleOpen = () => {
		this.setState({
			open: !this.state.open,
		});
	}

	render() {
		const {
			item,
			classes,
		} = this.props;

		const opened = this.state.open;
		const Icon = opened ?
			<SvgIcon className={classes.arrowIcon} icon="ArrowUp" />
			: <SvgIcon className={classes.arrowIcon} icon="ArrowDown" />;

		const contentCssClass = classNames({
			[classes.content]: true,
			[classes.opened]: opened,
			[classes.closed]: !opened,
		});

		return (
			<div className={classes.root}>
				<div className={classes.headContainer}>
					<div className={classes.headContent}>
						<OrderMainInfo item={item} />
					</div>
					{opened &&
						<div className={contentCssClass}>
							<OrderExtendedInfo item={item} />
						</div>
					}
				</div>
				<div>
					<IconButton onClick={this.toggleOpen}>
						{Icon}
					</IconButton>
				</div>
			</div>
		);
	}
}

IpoOrderItem.propTypes = {
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(null, null),
)(IpoOrderItem);
