import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SvgIcon } from '@components';

const styles = theme => ({
	root: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	itemsTextContainer: {
		alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-start',
	},
	itemsText: {
		fontSize: '14px',
		marginTop: 0,
		paddingTop: 0,
		color: theme.typography.color.secondary,
		paddingLeft: '24px',
	},
	itemsRefreshIcon: {
		marginLeft: '5px',
		marginTop: 'auto',
		marginBottom: 'auto',
		cursor: 'pointer',
	},
	itemsLoadingIcon: {
		alignItems: 'center', display: 'flex', justifyContent: 'flex-start', margin: 'auto',
	},
});


class AccountPropsView extends React.Component {

	render() {
		const {
			children,
			classes,
			loading,
			handleRefresh,
			theme,
			...props
		} = this.props;

		return (
			<div className={classes.root}>
				{/* field */}
				<div className={classes.itemsTextContainer}>
					{!loading &&
						<div className={classes.itemsTextContainer}>
							<div className={classes.itemsText}>
								{children}
							</div>
							{handleRefresh && <SvgIcon
								className={classes.itemsRefreshIcon}
								icon="Refresh"
								htmlColor={theme.typography.color.secondary}
								onClick={handleRefresh}
							/>}
						</div>
					}
					{loading &&
						<div className={classes.itemsLoadingIcon}>
							<CircularProgress
								style={{ color: '#1a96ff' }}
								size={24}
							/>
						</div>
					}
				</div>
			</div>

		);
	}
}

AccountPropsView.propTypes = {
	classes: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	handleRefresh: PropTypes.func,
};

AccountPropsView.defaultProps = {
	handleRefresh: undefined,
};

export default compose(
	withStyles(styles),
	withTheme,
)(AccountPropsView);
