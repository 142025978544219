import React, { useEffect, useState }  from 'react';
import { Route, Switch } from 'react-router-dom';
import { DocumentDebtsAlert } from './components';


export const CYAlerts = ({ match }) => {

	return ( 
		<React.Fragment>
			<Switch>
				<Route path={`${match.url}/cashmanagement`} component={() => <DocumentDebtsAlert isMoneyOutPage />} />
				<Route path={`${match.url}`} component={() => <DocumentDebtsAlert />} />
			</Switch>
		</React.Fragment>
	);
}