import moment from 'moment';
import { OperationType } from '@enums';
import actions from './actions';
import authActions from '../auth/actions';
import linkedAccountsActions from '../linkedAccounts/actions';

const initialState = {
	submittingBaseData: false,
	accountInfo: {
		loading: true,
		Platforms: {},
		Tariffs: null,
		PendingOrders: {},
		AccountName: '',
		WireRequisites: {},
		Status: {
			CanAcat: false,
			CanAchLink: false,
			CanAchLinkAuto: false,
			CanWireLink: false,
			CanFund: true,
			CanWithdrawal: true,
			CanMarginUpgrade: false,
			CanOptionsUpgrade: false,
			IsClosed: false,
			IsDisabled: false,
			CanPlatformChange: false,
		},
		IsIra: null,
		IraType: null,
	},
	currentAccount: '',
	currentClearer: '',
	currentAccountData: {
		submitting: true,
		portfolio: [],
		baseParams: null,
	},
	// currentAccountStatus: {
	// 	CanAchLink: false,
	// 	CanFund: true,
	// 	CanWithdrawal: true,
	// 	CanMarginUpgrade: false,
	// 	CanOptionsUpgrade: false,
	// 	IsClosed: false,
	// 	IsDisabled: false,
	// },
	reportsAndConfirmations: {
		loading: true,
		Url: null,
		Documents: [],
		Transmissions: [],
	},
	charts: {},
	hasMaxTemplatesCount: false,
	linkedAccountsSubmitting: false,
	linkedAccounts: [],
	accounts: [],
	cashActivities: {
		loading: true,
		dates: {
			StartDate: new Date(moment().year(), moment().month() - 2, moment().date()),
			EndDate: new Date(),
		},
		type: OperationType.ALL,
		pagination: {
			Page: 1,
		},
		items: [],
	},
	nonTraidingActivities: {
		loading: true,
		dates: {
			StartDate: new Date(moment().year(), moment().month() - 2, moment().date()),
			EndDate: new Date(),
		},
		type: OperationType.ALL,
		pagination: {
			Page: 1,
		},
		items: [],
	},
	userInfo: {
		submitting: false,
	},
	corporateActions: {
		loading: true,
		pagination: {},
		items: [],
	},
	oldCorporateActions: {
		loading: true,
		pagination: {},
		items: [],
	},
	upgradeMarginType: {
		submitting: false,
		tradeAccount: null,
		marginParams: null,
	},
	upgradeOptionLevel: {
		submitting: false,
		tradeAccount: null,
		marginParams: null,
	},
	currentAccountObject: null,
	secondAccountAgreement: {
		submitting: false,
		created: false,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.getUserInfo.REQUEST:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					submitting: true,
				},
			};
		case actions.getUserInfo.SUCCESS:
			return {
				...state,
				userInfo: {
					...payload.response.UserInfo,
					submitting: false,
				},
			};
		case actions.getUserInfo.FAILURE:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					submitting: false,
				},
			};
		case actions.GET_ACCOUNTS_REQUEST:
			return {
				...state,
				submittingBaseData: true,
			};
		case actions.GET_ACCOUNTS_SUCCESS:
			return {
				...state,
				...payload,
				submittingBaseData: false,
			};
		case actions.GET_ACCOUNTS_FAILURE:
			return {
				...state,
				...payload,
				submittingBaseData: false,
			};

		case actions.SET_CURRENT_ACCOUNT:
			return {
				...state,
				submittingBaseData: true,
				currentAccount: payload.account.TradeCode,
				currentClearer: payload.account.ClearerFirm || payload.account.ClearingFirm,
				currentAccountObject: {
					IsDemo: payload.account.IsDemo,
					IsPaper: payload.account.IsPaper,
					IsClosed: payload.account.IsClosed,
				},
				currentAccountData: {
					...initialState.currentAccountData,
				},
				accountInfo: {
					...initialState.accountInfo,
				},
				charts: {},
				hasMaxTemplatesCount: false,
				linkedAccounts: [],
				cashActivities: {
					...initialState.cashActivities,
				},
			};
		case actions.GET_CURRENT_ACCOUNT_BASE_DATA_SUCCESS:
			return {
				...state,
				submittingBaseData: false,
			};

		case actions.REFRESH_REPORTS_AND_CONFIRMATIONS_REQUEST:
			return {
				...state,
				reportsAndConfirmations: {
					...state.reportsAndConfirmations,
					loading: true,
				},
			};
		case actions.REFRESH_REPORTS_AND_CONFIRMATIONS_SUCCESS:
			return {
				...state,
				reportsAndConfirmations: {
					...payload,
					loading: false,
				},
			};
		case actions.REFRESH_REPORTS_AND_CONFIRMATIONS_FAILURE:
			return {
				...state,
				reportsAndConfirmations: {
					...state.reportsAndConfirmations,
					loading: false,
				},
			};

		case actions.getAccountPortfolio.REQUEST:
			return {
				...state,
				currentAccountData: {
					...initialState.currentAccountData,
					submitting: true,
				},
			};
		case actions.SET_ACCOUNT_BASE_PARAMS:
		case actions.getAccountPortfolio.SUCCESS:
			return {
				...state,
				currentAccountData: {
					...payload.response,
					baseParams: {
						...payload.response.baseParams,
						MarketValue: payload.response.portfolio &&
						payload.response.portfolio.Positions &&
						payload.response.portfolio.Positions.reduce((sum, it) => sum + it.MarketValue, 0),
					},
					submitting: false,
				},
			};
		case actions.getAccountPortfolio.FAILURE:
			return {
				...state,
				currentAccountData: {
					...state.currentAccountData,
					submitting: false,
				},
			};


		case actions.getTradeSystemCredentials.REQUEST:
			return {
				...state,
				tradeSystemCredentialsRequest: {
					submitting: true,
				},
			};
		case actions.getTradeSystemCredentials.SUCCESS:
			return {
				...state,
				tradeSystemCredentialsRequest: {
					...payload.response,
					RequestDate: new Date(),
					submitting: false,
				},
			};
		case actions.getTradeSystemCredentials.FAILURE:
			return {
				...state,
				tradeSystemCredentialsRequest: null,
			};
		case actions.GET_ACCOUNT_INFO_REQUEST:
			return {
				...state,
				accountInfo: {
					...initialState.accountInfo,
					loading: true,
				},
			};
		case actions.GET_ACCOUNT_INFO_SUCCESS:
			return {
				...state,
				accountInfo: {
					...payload,
					loading: false,
				},
			};
		case actions.GET_ACCOUNT_INFO_FAILURE:
			return {
				...state,
				accountInfo: {
					...state.accountInfo,
					loading: false,
				},
			};

		case linkedAccountsActions.getLinkedAccounts.FAILURE:
			return {
				...state,
				linkedAccountsSubmitting: false,
			};
		case linkedAccountsActions.getLinkedAccounts.REQUEST:
			return {
				...state,
				linkedAccountsSubmitting: payload.submitting || false,
			};
		case linkedAccountsActions.getLinkedAccounts.SUCCESS:
			return {
				...state,
				hasMaxTemplatesCount: payload.HasMaxTemplatesCount || false,
				linkedAccountsSubmitting: false,
				linkedAccounts: [...payload.LinkedAccounts],
			// currentAccountStatus: {
			// 	CanAchLink: payload.CanAchLink,
			// 	CanFund: payload.CanFund,
			// 	CanWithdrawal: payload.CanWithdrawal,
			// 	CanMarginUpgrade: payload.CanUpgrade,
			// 	CanOptionsUpgrade: payload.CanOptionsUpgrade,
			// 	IsClosed: payload.IsClosed,
			// 	IsDisabled: payload.IsDisabled,
			// },
			};

		case actions.getTransactionsHistory.REQUEST:
			return {
				...state,
				cashActivities: (payload.Page === 1) ? {
					...initialState.cashActivities,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate,
					},
				} : {
					...state.cashActivities,
					loading: true,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate,
					},
				},
			};
		case actions.getTransactionsHistory.SUCCESS:
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
					pagination: {
						...payload.request,
					},
					items: [
						...state.cashActivities.items,
						...payload.response.Items,
					],
				},
			};
		case actions.getTransactionsHistory.FAILURE:
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
				},
			};


		case actions.getMoneyTransactionsHistory.REQUEST:
			return {
				...state,
				cashActivities: (payload.Page === 1) ? {
					...initialState.cashActivities,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate,
					},
				} : {
					...state.cashActivities,
					loading: true,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate,
					},
				},
			};
		case actions.getMoneyTransactionsHistory.SUCCESS:
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
					pagination: {
						...payload.request,
					},
					items: [
						...state.cashActivities.items,
						...payload.response.Items,
					],
				},
			};
		case actions.getMoneyTransactionsHistory.FAILURE:
			return {
				...state,
				cashActivities: {
					...state.cashActivities,
					loading: false,
				},
			};

		case actions.getNonTradingOrdersHistory.REQUEST:
			return {
				...state,
				nonTraidingActivities: (payload.Page === 1) ? {
					...initialState.nonTraidingActivities,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate,
					},
				} : {
					...state.nonTraidingActivities,
					loading: true,
					type: payload.Type,
					dates: {
						StartDate: payload.StartDate,
						EndDate: payload.EndDate,
					},
				},
			};
		case actions.getNonTradingOrdersHistory.SUCCESS:
			return {
				...state,
				nonTraidingActivities: {
					...state.nonTraidingActivities,
					loading: false,
					pagination: {
						...payload.request,
					},
					items: [
						...state.nonTraidingActivities.items,
						...payload.response.Items,
					],
				},
			};
		case actions.getNonTradingOrdersHistory.FAILURE:
			return {
				...state,
				nonTraidingActivities: {
					...state.nonTraidingActivities,
					loading: false,
				},
			};

		case actions.getAccountCharts.SUCCESS:
			return {
				...state,
				charts: { ...payload },
			};

		case actions.getCorporateActions.REQUEST:
			return {
				...state,
				corporateActions: (!payload.page || payload.page === 1) ? {
					...initialState.corporateActions,
				} : {
					...state.corporateActions,
					loading: true,
				},
			};
		case actions.getCorporateActions.SUCCESS:
			return {
				...state,
				corporateActions: {
					loading: false,
					pagination: {
						page: payload.request.page,
						pageSize: payload.request.pageSize,
					},
					items: [
						...state.corporateActions.items,
						...payload.response.Items,
					],
				},
			};
		case actions.getCorporateActions.FAILURE:
			return {
				...state,
				corporateActions: {
					...state.corporateActions,
					loading: false,
				},
			};

		case actions.getOldCorporateActions.REQUEST:
			return {
				...state,
				oldCorporateActions: (!payload.page || payload.page === 1) ? {
					...initialState.oldCorporateActions,
				} : {
					...state.oldCorporateActions,
					loading: true,
				},
			};
		case actions.getOldCorporateActions.SUCCESS:
			return {
				...state,
				oldCorporateActions: {
					loading: false,
					pagination: {
						page: payload.request.page,
						pageSize: payload.request.pageSize,
					},
					items: [
						...state.oldCorporateActions.items,
						...payload.response.Items,
					],
				},
			};
		case actions.getOldCorporateActions.FAILURE:
			return {
				...state,
				oldCorporateActions: {
					...state.oldCorporateActions,
					loading: false,
				},
			};

		case actions.SHOW_UPGRADE_ACCOUNT_MODAL:
			return {
				...state,
				upgradeMarginType: {
					tradeAccount: payload.tradeAccount,
					marginParams: payload.marginParams,
				},
			};
		case actions.SHOW_UPGRADE_OPTION_LEVEL_MODAL:
			return {
				...state,
				upgradeOptionLevel: {
					tradeAccount: payload.tradeAccount,
					marginParams: payload.marginParams,
				},
			};

		case authActions.LOGIN_USER:
			return {
				...initialState,
			};
		case authActions.LOGOUT_SUCCESS:
			return { ...initialState };

		case actions.addDemoAccount.REQUEST:
			return {
				...state,
				submittingBaseData: true,
			};
		case actions.addDemoAccount.SUCCESS:
			return {
				...state,
				submittingBaseData: false,
			};
		case actions.addDemoAccount.FAILURE:
			return {
				...state,
				submittingBaseData: false,
			};
		case actions.secondAccountVisionAgreement.REQUEST:
			return {
				...state,
				secondAccountAgreement: {
					submitting: true,
					created: false,
				},
			};
		case actions.secondAccountVisionAgreement.SUCCESS:
			return {
				...state,
				secondAccountAgreement: {
					submitting: false,
					created: true,
				},
			};
		case actions.secondAccountVisionAgreement.FAILURE:
			return {
				...state,
				secondAccountAgreement: {
					submitting: false,
					created: false,
				},
			};

		default:
			return state;
	}
};
