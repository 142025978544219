import { ModalPopups } from '@enums';
import actions from './actions';
import appActions from '../app/actions';
import auth from '../auth';


const initialState = {
	fromUserAccount: null,
	toBankAccount: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case appActions.SHOW_BASE_MODAL:
			return {
				...state,
				fromUserAccount: [ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER, ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS].includes(payload.modalName)
					? payload.modalData.userAccount : state.fromUserAccount,
				toBankAccount: [ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER, ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS].includes(payload.modalName)
					? payload.modalData.bankAccount : state.toBankAccount,
			};
		case appActions.HIDE_BASE_MODAL:
			return {
				...state,
				fromUserAccount: [ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER, ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS].includes(payload.modalName) ? null : state.fromUserAccount,
				toBankAccount: [ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER, ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS].includes(payload.modalName) ? null : state.toBankAccount,
			};
		case actions.SHOW_WITHDRAW_FUNDS_ACH_MODAL:
			return {
				...state,
				...payload,
			};
		case actions.HIDE_WITHDRAW_FUNDS_ACH_MODAL:
			return {
				...state,
				fromUserAccount: null,
				toBankAccount: null,
			};
		case actions.SHOW_WITHDRAW_FUNDS_WIRE_MODAL:
			return {
				...state,
				...payload,
			};
		case actions.HIDE_WITHDRAW_FUNDS_WIRE_MODAL:
			return {
				...state,
				fromUserAccount: null,
				toBankAccount: null,
			};
		case auth.actions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
