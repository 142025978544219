import { createFormAction } from 'redux-form-saga';

const loginFormSubmitActionPrefix = 'LOGIN_FORM_SUBMIT';
const loginFormActionCreator = createFormAction(loginFormSubmitActionPrefix);

const actions = {
	// actions
	LOGIN_FORM_SUBMIT_REQUEST: loginFormActionCreator.REQUEST,
	LOGIN_FORM_SUBMIT_SUCCESS: loginFormActionCreator.SUCCESS,
	LOGIN_FORM_SUBMIT_FAILURE: loginFormActionCreator.FAILURE,

	loginFormRequest: (email, password) => loginFormActionCreator.request({ email, password }),
	loginFormSuccess: payload => loginFormActionCreator.success(payload),
	loginFormFailure: error => loginFormActionCreator.failure(error),
	loginFormSubmitHandler: loginFormActionCreator,
};

export default actions;
