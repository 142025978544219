import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import R from '../R/R';

const styles = theme => ({
	center: {
		display: 'flex',
		justifyContent: 'center',
	},
	text: {
		...theme.typography.style.title,
		color: theme.typography.color.secondary,
		textAlign: 'center',
	},
});


function hasChildren(elm) {
	if (!elm) {
		return false;
	}
	if (Array.isArray(elm)) {
		return elm.find(item => hasChildren(item));
	}
	return elm.props && elm.props.children;
}

const ProgressContainer = ({
	classes,
	display,
	submitting,
	size,
	disableCheckChildren,
	...props
}) => {
	if (submitting) {
		return (
			<div className={classNames({ [classes.center]: display === 'center' })}>
				{display !== 'none' &&
					<CircularProgress
						style={{ color: '#1a96ff' }}
						size={size}
					/>
				}
			</div>
		);
	}

	if (!disableCheckChildren && !hasChildren(props.children)) {
		return <div className={classes.text}><R id="shared_no_data_available" defaultValue="No data available" /></div>;
	}
	return props.children;
};

ProgressContainer.defaultProps = {
	display: 'center',
	size: 58,
	disableCheckChildren: false,
};

ProgressContainer.propTypes = {
	classes: PropTypes.object.isRequired,
	/**
	 * gorizontal component alignment: left or center.
	 */
	display: PropTypes.string,
	/**
	 * main event: display progress if true.
	 */
	submitting: PropTypes.bool.isRequired,
	/**
	 * main content: display if submitting is false.
	 */
	children: PropTypes.any,

	size: PropTypes.number,
	/**
	 * отключает проверку на наличие элементов внутри и вывод сообщения об их отсутствии
	 */
	disableCheckChildren: PropTypes.bool,
};

export default withStyles(styles)(ProgressContainer);
