import {env} from '@config';


import ruLangImg from '@global/img/RUS.png';
import enLangImg from '@global/img/ENG.png';
import esLangImg from '@global/img/spain.png';
import zhLangImg from '@global/img/china.png';
import plLangImg from '@global/img/poland.png';
import ukLangImg from '@global/img/ukraine.png';
import msLangImg from '@global/img/malaysia3.png';
import koLangImg from '@global/img/korea.png';

// locales for dates and ints in app
// Выбираем по одному, чтобы в сборку попали только нужные данные, а не всё вместе
import intlEn from 'react-intl/locale-data/en'; // Английский
import intlEs from 'react-intl/locale-data/es'; // Испанский
import intlMs from 'react-intl/locale-data/ms'; // Малазийский
import intlPl from 'react-intl/locale-data/pl'; // Польский
import intlRu from 'react-intl/locale-data/ru'; // Русский
import intlUk from 'react-intl/locale-data/uk'; // Украинский
import intlZh from 'react-intl/locale-data/zh'; // Китайский
import intlKo from 'react-intl/locale-data/ko'; // Корейский


const allDicWordLanguages = [
	{
		language: 'en',
		icon: enLangImg,
		displayName: 'English',
		dicWordKey: 'en_lang',
		intlLocale: intlEn,
		sharedTranslate: {},
	},
	{
		language: 'ru',
		icon: ruLangImg,
		displayName: 'Русский',
		dicWordKey: 'ru_lang',
		intlLocale: intlRu,
		sharedTranslate: {},
	},
	{
		language: 'zh',
		icon: zhLangImg,
		displayName: '中文',
		dicWordKey: 'zh_lang',
		intlLocale: intlZh,
		sharedTranslate: {},
	},
	{
		language: 'es',
		icon: esLangImg,
		displayName: 'Español',
		dicWordKey: 'es_lang',
		intlLocale: intlEs,
		sharedTranslate: {},
	},
	{
		language: 'pl',
		icon: plLangImg,
		displayName: 'Polski',
		dicWordKey: 'pl_lang',
		intlLocale: intlPl,
		sharedTranslate: {},
	},
	{
		language: 'uk',
		icon: ukLangImg,
		displayName: 'Українська',
		dicWordKey: 'uk_lang',
		intlLocale: {},
	},
	{
		language: 'ms',
		icon: msLangImg,
		displayName: 'Malay',
		dicWordKey: 'ms_lang',
		intlLocale: {},
	},
	{
		language: 'ko',
		icon: koLangImg,
		displayName: '한국어',
		dicWordKey: 'ko_lang',
		intlLocale: intlKo,
	},
];

export const getAllowedLanguages = () => {
	if (env.allowedLangs) {
		return allDicWordLanguages.filter(x => env.allowedLangs.indexOf(x.language) >= 0);
	}
	return allDicWordLanguages.filter(x => x.language === 'en');
};

// // set allowed languages
export const setAlowedLanguages = (langs) => {
	return langs && langs.length ?
		getAllowedLanguages().filter(item => langs.some(x => x === item.language)) :
		getAllowedLanguages();
};

