import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

import {
	DividendItem,
	OtherItem,
	SplitItem,
} from './components';


const styles = theme => ({
	root: {
	},
});

class Item extends React.Component {
	render() {
		const {
			isOld,
			item,
			classes,
		} = this.props;

		let ItemComponent;

		switch (item.TypeStr) {
		case 'other':
			ItemComponent = OtherItem;
			break;

		case 'dividend':
			ItemComponent = DividendItem;
			break;

		case 'split':
			ItemComponent = SplitItem;
			break;

		default:
			ItemComponent = DividendItem;
		}

		return <ItemComponent item={item} isOld={isOld} />;
	}
}

Item.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(Item);
