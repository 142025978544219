import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, SuccessMessage, ErrorMessage, ProgressContainer, R } from '@components';
import { ordersCy, userAccounts } from '@redux';
import {
	CYWithdrawalMalaysianBankAmountForm,
	CYWithdrawalMalaysianBankConfirmForm,
} from './components';


const CY_WITHDRAWAL_MALAYSIANBANK_FORM_ID = 'CYWithdrawalMalaysianBankForm';
const CYWithdrawalMalaysianBankModal = ({ onClose }) => {
	const [step, setStep] = useState('amountform');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);

	return (
		<Modal
			title={<R id="orders_selectmethod_malaysianbank_title" defaultValue="Malaysian Bank Account" />}
			onClose={onClose}
			bgColor="#f9fcfd"
			textColor="#345464"
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={onClose}
							text={<R id="common_crypto_wallet_completed" defaultValue="Withdrawal order completed" />}
						/>
					}
					{step === 'failed' &&
						<ErrorMessage
							onClick={onClose}
							text={<R id="common_crypto_wallet_failed" defaultValue="Could not complete order" />}
						/>
					}
					{step === 'amountform' &&
						<CYWithdrawalMalaysianBankAmountForm
							destroyOnUnmount={false}
							forceUnregisterOnUnmount
							form={CY_WITHDRAWAL_MALAYSIANBANK_FORM_ID}
							enableReinitialize
							onSubmit={handleGotoPage('confirmform')}
							onSuccess={() => setStep('confirmform')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
					{step === 'confirmform' &&
						<CYWithdrawalMalaysianBankConfirmForm
							form={CY_WITHDRAWAL_MALAYSIANBANK_FORM_ID}
							onSubmit={ordersCy.actions.cyWithdrawalEmoneyForm}
							onSuccess={() => setStep('succeeded')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default CYWithdrawalMalaysianBankModal;
