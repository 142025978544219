import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { userAccounts } from '@redux';
import {
	reduxForm,
	Field,
	formValueSelector,
	getFormValues,
} from 'redux-form';
import {
	FormSelect,
	Divider,
	Button,
	LimeLogo,
	WithdrawAmount,
} from '@components';
import { cabinetLog, toUsaMoneyFormat } from '@global';
import { constants } from '@config';
import { CloseAccountFlow } from '@enums';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import classNames from 'classnames';
// import j2tlogo from './j2t.png';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	item: {
		marginTop: '30px',
		paddingLeft: '74px',
	},
	divider: {
		marginTop: '30px',
	},
	bankAccountContainer: {
		marginTop: '-15px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '36px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	reviewBlock: {
		marginTop: '30px',
		padding: '0px 74px',
	},
	reviewTitle: {
		textAlign: 'center',
		...theme.typography.style.title,
	},
	reviewTable: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	reviewLeft: {
		display: 'flex',
		flexDirection: 'column',
	},
	reviewRight: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	reviewNormal: {
		color: theme.typography.color.primary,
		fontWeight: 500,
	},
	reviewSuccess: {
		color: theme.typography.color.darkGreen,
		fontWeight: 500,
	},
	reviewError: {
		color: theme.typography.color.red,
		fontWeight: 500,
	},
	reviewProgress: {
		paddingLeft: '120px',
	},
	circularProgress: {
		color: theme.typography.color.blue,
	},
});

function MapForm(ownProps) {
	const template = (ownProps && ownProps.toBankAccount && ownProps.toBankAccount.Template)
		? ownProps.toBankAccount.Template
		: null;

	if (!template) return {};

	return {
		...template,
		Name: template.FirstName,
		Surname: template.LastName,
		BankName: template.ReceivingBankName,
		BankRoutingNumber: template.ReceivingBankRoutingNumber,
		BankSwiftCode: template.ReceivingBankSwiftCode,
		BankAccountName: template.ReceivingBankAccountName,
		BankAddress: template.ReceivingBankAddress,
		BankAccountNumber: template.ReceivingBankAccountNumber,
		UseIntermediaryBank: !!template.IntermediaryBankName, // toBoolean
		Additional: template.AdditionalInformation,
		WireType: template.WireType === '1' ? 'International' : 'Domestic',
	};
}

const WIRE_WITHDRAWAL_SHORT_FORM_ID = 'wireWithdrawalShortForm';

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const formSelector = formValueSelector(ownProps.form);
	const formValues = getFormValues(ownProps.form)(state);
	const prevStepValues = ownProps.toBankAccount.Template ? {} : {
		wireLink: formValues.wireLink,
	};
	return {
		linkWireType: formSelector(state, 'wireLink.WireType'),
		initialValues: {
			...prevStepValues,
			CloseAccountFlow: ownProps.closeAccountFlow,
			wireWithdrawal: {
				...MapForm(ownProps),
				TradeCode: currentTradeAccount.tradeCode,
				ClearingFirm: currentTradeAccount.clearerFirm,
				Amount: ownProps.amount,
			},
		},
		currentAccountData: userAccounts.selectors.currentAccountData(state),
		currentTradeAccount,
		closeAccountFlow: ownProps.closeAccountFlow,
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		refreshAccountPortfolio: tradeCode =>
			dispatch(userAccounts.actions.getAccountPortfolio.request({ tradeCode })),
	},
});

const DASH_SIGN = '-';

class WireWithdrawalShortForm extends React.Component {
	static getCashToWithdraw(props) {
		const { currentAccountData: { baseParams, submitting } } = props;
		return !submitting && baseParams && baseParams.CashToWithdrawValue;
	}

	static validate(formvalues, props) {
		const values = formvalues.wireWithdrawal;
		const errors = {};
		const cashToWithdraw = WireWithdrawalShortForm.getCashToWithdraw(props);
		const isVision = props.currentTradeAccount.clearerFirm === 'vision';
		const isCor = props.currentTradeAccount.clearerFirm === 'cor';

		if (!values) {
			return errors;
		}

		if ((props.closeAccountFlow !== CloseAccountFlow.PROFILE) && (isVision || isCor)) {
			if (!values.Comment) {
				errors.Comment = 'required';
			} else if (values.Comment.length > constants.maxCharsNumber) {
				errors.Comment = constants.maxCharsNumberError;
			}
		}

		if (!values.Amount || values.Amount <= 0) {
			errors.Amount = 'required';
		} else if (typeof cashToWithdraw === 'number') {
			if (cashToWithdraw < values.Amount) {
				errors.Amount = 'Available amount for withdrawal is less than requested';
			}
		}

		return { wireWithdrawal: errors };
	}

	constructor(props) {
		super(props);
		this.state = {
			amountToWithdraw: DASH_SIGN,
			withdrawalFee: DASH_SIGN,
			amountToReceive: DASH_SIGN,
			errorText: null,
		};

		this.fee = this.calculateFee();
	}

	componentDidUpdate(prevProps, prevState) {
		if ((!prevState.amountNumber && !this.state.amountNumber) ||
			(prevState.amountNumber === this.state.amountNumber &&
				WireWithdrawalShortForm.getCashToWithdraw(prevProps) === WireWithdrawalShortForm.getCashToWithdraw(this.props))) {
			return;
		}
		this.calculateReviewValues(this.state.amountNumber);
	}

	amountFieldName = 'wireWithdrawal.Amount';

	calculateFee = () => {
		const INTERNATIONAL_VISION_FEE = 60;
		const INTERNATIONAL_COR_FEE = 80;
		const DOMESTIC_FEE = 35;

		const {
			toBankAccount,
			linkWireType,
			currentTradeAccount,
		} = this.props;

		let wireType = toBankAccount && toBankAccount.Template && toBankAccount.Template.WireType;
		if (wireType === undefined && linkWireType !== undefined) {
			wireType = linkWireType === 'International' ? '1' : '0';
		}

		if (currentTradeAccount.clearerFirm === 'vision') {
			return wireType === '1' ? INTERNATIONAL_VISION_FEE : DOMESTIC_FEE;
		} else if (currentTradeAccount.clearerFirm === 'cor') {
			return wireType === '1' ? INTERNATIONAL_COR_FEE : DOMESTIC_FEE;
		}
		return DOMESTIC_FEE;
	}

	calculateReviewValues = (amount) => {
		const availableAmount = WireWithdrawalShortForm.getCashToWithdraw(this.props);
		const amountNumber = +amount;

		let amountToWithdraw;
		let withdrawalFee;
		let amountToReceive;
		let errorText;

		if (amountNumber === 0) {
			amountToWithdraw = DASH_SIGN;
			withdrawalFee = DASH_SIGN;
			amountToReceive = DASH_SIGN;
			errorText = null;
		} else if (amountNumber > availableAmount) {
			amountToWithdraw = DASH_SIGN;
			amountToReceive = DASH_SIGN;
			withdrawalFee = DASH_SIGN;
			errorText = 'Insufficient funds. Please, adjust the amount to withdraw';
		} else if (amountNumber + this.fee <= availableAmount) {
			amountToWithdraw = amountNumber + this.fee;
			withdrawalFee = this.fee;
			amountToReceive = amountNumber;
			errorText = '';
		} else {
			amountToWithdraw = amountNumber;
			withdrawalFee = this.fee;
			amountToReceive = amountNumber - this.fee;
			errorText = '';
		}

		const amountToReceiveAsNum = amountToReceive;

		amountToWithdraw = typeof amountToWithdraw === 'number' ? toUsaMoneyFormat(amountToWithdraw) : amountToWithdraw;
		withdrawalFee = typeof withdrawalFee === 'number' ? toUsaMoneyFormat(withdrawalFee) : withdrawalFee;
		amountToReceive = typeof amountToReceive === 'number' ? toUsaMoneyFormat(amountToReceive) : amountToReceive;

		this.setState({
			amountNumber,
			amountToWithdraw,
			withdrawalFee,
			amountToReceive,
			errorText,
			amountToReceiveAsNum,
		});
	}

	amountChanged = (amount) => {
		this.calculateReviewValues(amount);
	}

	amountAvailableRefresh = () => {
		const {
			actions,
			currentTradeAccount,
		} = this.props;

		actions.refreshAccountPortfolio(currentTradeAccount.tradeCode);
	}

	formSubmit = (values, dispatch, props) => {
		const newValues = { ...values };
		newValues.wireWithdrawal.Amount = this.state.amountToReceiveAsNum;

		return this.props.onSubmit(newValues, dispatch, props);
	}

	render() {
		const {
			classes,
			actions,
			handleSubmit,
			selectedBankAccount,
			submitSucceeded,
			amount,
			currentTradeAccount,
			currentAccountData: {
				submitting,
			},
			linkWireType,
			closeAccountFlow,
			...props
		} = this.props;

		cabinetLog('Wire Withdrawal', currentTradeAccount, props.toBankAccount);

		const cssClasses = classNames({
			[classes.reviewNormal]: this.state.errorText === null,
			[classes.reviewSuccess]: this.state.errorText === '',
			[classes.reviewError]: this.state.errorText,
		});

		return (
			<form
				onSubmit={handleSubmit(this.formSubmit)}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<LimeLogo />
						</div>
						<Field component={FormSelect} name="wireWithdrawal.TradeCode" label="Account" disabled>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentTradeAccount.tradeCode}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<WithdrawAmount
					name="wireWithdrawal.Amount"
					loading={submitting || false}
					availableAmount={WireWithdrawalShortForm.getCashToWithdraw(this.props)}
					disabled={amount != null}
					additionalText={this.state.errorText}
					onClick={this.amountAvailableRefresh}
					onChange={this.amountChanged}
				/>
				{!submitting &&
					<div className={classes.reviewBlock}>
						<div className={classes.reviewTitle}>
							Review
						</div>
						<div className={classes.reviewTable}>
							<div className={classes.reviewLeft}>
								<div>Amount to withdraw</div>
								<div>Withdrawal fee</div>
								<div className={cssClasses}>Amount to receive</div>
							</div>
							<div className={classes.reviewRight}>
								<div>{this.state.amountToWithdraw}</div>
								<div>{this.state.withdrawalFee}</div>
								<div className={cssClasses}>{this.state.amountToReceive}</div>
							</div>
						</div>
					</div>
				}
				{submitting &&
					<div className={classes.reviewProgress}>
						<CircularProgress
							className={classes.circularProgress}
							size={60}
						/>
					</div>
				}
				<div className={classes.divider}>
					<Divider />
				</div>
				<p className={classes.warning}>
					{constants.warningForNonTransferableSecuritiesHolders}
				</p>
				<div className={classes.button}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting || submitting}
					>
						{props.submitting ?
							<CircularProgress
								className={classes.circularProgress}
								size={18}
							/>
							: 'Withdraw'
						}
					</Button>
				</div>
			</form>
		);
	}
}

WireWithdrawalShortForm.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	toBankAccount: PropTypes.object,
	selectedBankAccount: PropTypes.object,
	closeAccountFlow: PropTypes.oneOf([CloseAccountFlow.PROFILE, CloseAccountFlow.CASH_MANAGMENT]).isRequired,
	change: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: WIRE_WITHDRAWAL_SHORT_FORM_ID,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess('WIRE');
			}
		},
		validate: WireWithdrawalShortForm.validate,
		shouldError: () => true,
	}),
)(WireWithdrawalShortForm);
