import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormInput } from '@components';
import { VisibilityButton } from './components';
import PropTypes from 'prop-types';

const styles = {
	inputType: {
		height: 'auto',
	},
};

class PasswordInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isPasswordVisible: false,
		};
		this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
	}

	togglePasswordVisibility() {
		this.setState({
			isPasswordVisible: !this.state.isPasswordVisible,
		});
	}

	render() {
		const { isPasswordVisible } = this.state;
		const { classes, ...props } = this.props;
		return (
			<FormInput
				type={isPasswordVisible ? 'text' : 'password'}
				inputProps={{
					className: classes.inputType,
				}}
				endAdornment={
					<VisibilityButton
						isVisible={isPasswordVisible}
						onClick={this.togglePasswordVisibility}
					/>
				}
				{...props}
			/>
		);
	}
}

PasswordInput.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordInput);
