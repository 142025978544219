import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { userAccounts } from '@redux';
import { ErrorMessage, Modal, ProgressContainer } from '@components';
import { connect } from 'react-redux';
import { IraWithdrawalForm } from './components';

const styles = {};

const mapStateToProps = (state) => {
	const accountInfo = userAccounts.selectors.accountInfo(state);
	// default if not set
	const loading = userAccounts.selectors.loading(state) || accountInfo.loading;
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const accountStatus = userAccounts.selectors.currentAccountStatus(state);

	return {
		fromUserAccount: {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		},
		canShow: loading || (accountStatus && accountStatus.CanWithdrawal && accountInfo.IsIra
			&& (currentTradeAccount.clearerFirm !== 'vision' || accountInfo.IraType)
		),
		loading,
	};
};

class WithdrawFundsIraModal extends React.Component {
	render() {
		const {
			onClose,
			fromUserAccount,
			canShow,
			loading,
		} = this.props;

		return (
			<Modal
				title="Withdraw"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
			>
				<ProgressContainer submitting={loading}>
					<div>
						{!canShow &&
							<ErrorMessage onClick={onClose} text={`Unavailable for account ${fromUserAccount.tradeCode}.`} />
						}
						{canShow &&
							<IraWithdrawalForm
								fromUserAccount={fromUserAccount}
							/>
						}
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

WithdrawFundsIraModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canShow: PropTypes.bool.isRequired,
	fromUserAccount: PropTypes.shape({
		tradeCode: PropTypes.string.isRequired,
		clearer: PropTypes.string.isRequired,
	}).isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(WithdrawFundsIraModal);
