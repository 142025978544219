import {
	generateBrokerReport, getBrokerReports, // getReportFile,
} from '@api';
import { cabinetLog, captureException } from '@global';
import { BaseGetSagaHandler, reports, userAccounts } from '@redux';
import {
	call, fork, put, takeLatest, select,
} from 'redux-saga/effects';
import actions from './actions';


// Watchers
function* generateBrokerReportSaga(payload) {
	yield BaseGetSagaHandler({
		apiMethod: generateBrokerReport,
		errorText: 'Error',
		handler: actions.generateBrokerReport,
	}, payload);

	yield put(actions.getBrokerReports.request({ tradeCode: payload.payload.tradeCode }));
}

function* getAccountReportsSaga(payload) {

	if (payload.payload === undefined) {
		// set current account if not specified
		let currentAccount = yield select(userAccounts.selectors.currentTradeAccount);
		payload.payload = { tradeCode: currentAccount.tradeCode };
	}

	yield BaseGetSagaHandler({
		apiMethod: getBrokerReports,
		errorText: 'Error',
		handler: actions.getBrokerReports,
	}, payload);
}


// function* getReportFileSaga(payload) {
// 	try {
// 		const response = yield call(getReportFile, payload.payload);
// 		const fileName = response.headers['content-disposition'].split('filename=')[1];
// 		const url = window.URL.createObjectURL(new Blob([response.data]));
// 		const link = document.createElement('a');
// 		link.href = url;
// 		link.setAttribute('download', decodeURI(fileName));
// 		document.body.appendChild(link);
// 		link.click();
// 	} catch (error) {
// 		captureException(error);
// 		cabinetLog(error);
// 	}
// }

export function* watchGenerateBrokerReportRequest() {
	yield takeLatest(actions.generateBrokerReport.REQUEST, generateBrokerReportSaga);
}
export function* watchgetUserReportsRequest() {
	yield takeLatest(actions.getBrokerReports.REQUEST, getAccountReportsSaga);
}
// export function* watchGetReportFileRequest() {
// 	yield takeLatest(actions.getReportFile.REQUEST, getReportFileSaga);
// }

// Root saga
const rootSaga = [
	fork(watchGenerateBrokerReportRequest),
	fork(watchgetUserReportsRequest),
	// fork(watchGetReportFileRequest),
];

export default {
	rootSaga,
};
