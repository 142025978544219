import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
	profile,
	userAccounts,
} from '@redux';
import compose from 'recompose/compose';
import { env } from '@config';
import {
	A,
	ChangeLink,
	ExpansionPanel,
	FileDownload,
	ProgressContainer,
	SvgIcon,
} from '@components';
import { InputEditableItem } from '@shared';
import { addHashToUrl, isNumber, scrollTo, toUsaSizeFormat, toUsaDateFormat } from '@global';
import TaxForms from './components/TaxForms';

const styles = theme => ({
	header: {
		...theme.typography.style.headline1,
		...theme.typography.color.primary,
		padding: '8px 0',
	},
	content: {
		...theme.typography.style.body,
		padding: '16px 72px',
		marginBottom: '30px',
		[theme.breakpoints.down('md')]: {
			padding: '8px 24px',
		},
	},
	splitter: {
		height: '30px',
	},
	documentContainer: {
		'&:last-child div': {
			borderBottom: 'none',
		},
	},
	documentItem: {
		...theme.typography.style.body,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 14px',
		width: '100%',
		borderBottom: '1px solid #deeaef',
		'& div': {
			padding: '10px',
		},
		'&>div:first-child': {
			minWidth: '105px',
		},
	},
	documentText: {
		color: 'inherit',
		flexGrow: 2,
	},
	disclosures: {
		...theme.typography.style.title,
		color: theme.typography.color.primary,
		marginTop: '24px',

	},
	expansionPanelStaticContent: {
		margin: '10px 24px 5px',
		color: theme.palette.text.secondary,
	},
	contentItem: {
		marginBottom: '30px',
	},
	endItem: {
		height: '30px',
	},
	downloadProgress: {
		position: 'relative',
		top: '30px',
		marginTop: '-16px',
		left: '18px',
	},
	downloadRootLoading: {
		marginLeft: '-18px',
	},
});

const mapStateToProps = state => ({
	profile: profile.selectors.profile(state),
	currentAccountData: userAccounts.selectors.currentAccountData(state),
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
	accountStatus: userAccounts.selectors.currentAccountStatus(state),
	userInfo: userAccounts.selectors.userInfo(state),
	hasOpenAccount: userAccounts.selectors.hasOpenAccount(state)
});


const mapDispatchToProps = dispatch => ({
	actions: {
		setCurrentPage: page => dispatch(push(page)),
		showChangeDeliveryTypeModal: () => {
			dispatch(profile.actions.showChangeDeliveryTypeModal());
		},
	},
});

const getDocDisplayName = (document) => {
	switch (document.DocTypeExt) {
	case 'just2trade': return 'Lime Account Agreement';
	case 'just2trade_margin': return 'Lime Account Agreement';
	case 'nasdaq': return 'Nasdaq Data Agreement';
	case 'nyse': return 'NYSE Data Agreement';
	case 'otc': return 'OTC Market Data Agreement';
	case 'opra': return 'OPRA Market Data Agreement';
	case 'vision_tcp': return	'Trusted Contact Agreement';
	case 'vision_margin': return 'Margin Supplement';
	case 'vision_day_trading': return 'Margin Supplement';
	case 'vision_options': return 'Options Agreement and Disclosures';
	case 'vision': return 'Vision Individual or Joint Agreement';
	case 'fw8ben': return 'W8BEN';
	case 'fw8ben_joint': return 'W8BEN';
	default:
	}
	const words = document.DocTypeExt ? document.DocTypeExt.split('_') : [];
	const newWords = words.filter(word => word && word.length > 0)
		.map(word => word.charAt(0).toUpperCase() + word.substring(1));
	return newWords.join(' ');
};

class DocumentsAndDisclosures extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openPanelId: 'profile-documents',
		};
	}

	componentDidUpdate() {
		if (this.props.location.hash) {
			scrollTo(this.props.location.hash.substr(1));
		} else if (this.props.location.search) {
			const parsed = qs.parse(this.props.location.search);
			if (parsed && parsed.commission && parsed.commission.toLowerCase() === 'zero') {
				scrollTo('tax-forms');
			}
		}
	}

	showUpgradeOptionLevelModal = () => {
		const { actions, currentTradeAccount, currentAccountData } = this.props;
		actions.showUpgradeOptionLevelModal(currentTradeAccount, currentAccountData.baseParams.MarginOptionParameters);
	}

	showModal = (id) => {
		this.props.history.push(addHashToUrl(this.props.location, id));
	}

	toggleOpen = (open, id) => {
		this.setState({ openPanelId: open ? id : null });
	};

	render() {
		const {
			classes,
			actions,
			profile: {
				submitting,
				PendingOrders,
				HasPaperDelivery,
				Documents: ProfileDocuments,
				TaxForms: DocTaxFoms,
			},
			hasOpenAccount
		} = this.props;
		const Documents = ProfileDocuments.filter(doc => doc.Size > 0);

		return (
			<React.Fragment>
				<div className={classes.content}>
					<div>
						<div className={classes.header}>
							Documents &amp; Disclosures
						</div>
					</div>
					<ProgressContainer submitting={submitting} display="left">
						{DocTaxFoms && DocTaxFoms.length > 0 &&
							<ExpansionPanel
								header="Tax Forms"
								id="tax-forms"
								onToggleOpen={this.toggleOpen}
								isOpen={this.state.openPanelId === 'tax-forms'}
							>
								<TaxForms />
							</ExpansionPanel>
						}
						<ExpansionPanel
							header="Documents"
							id="profile-documents"
							onToggleOpen={this.toggleOpen}
							isOpen={this.state.openPanelId === 'profile-documents'}
						>
							{env.WEBCAB_3520_emailDelivery &&
								<React.Fragment>
									<InputEditableItem
										title="Delivery Type"
										value={HasPaperDelivery ? 'Paper' : 'Paperless'}
										editControl={ hasOpenAccount &&
											<ChangeLink
												onClick={actions.showChangeDeliveryTypeModal}
												havePendingOrder={PendingOrders.DeliveryType}
											/>
										}
									/>
									<div className={classes.splitter} />
								</React.Fragment>
							}

							{/* WEBCAB-3785 - hide Margin Type and Options,
								because access to them will be through the Main Upgrade Account button */}
							{/* <Input placeholder="Margin Type" showLabel value={marginType} disabled />
							<Input placeholder="Options" showLabel value={optionLevel} disabled /> */}
							{(!Documents || !Documents.length) &&
								<div className={classes.expansionPanelStaticContent}>
									There are no documents to show
								</div>
							}

							{Documents && Documents.sort((a, b) => (a.Date < b.Date ? 1 : -1)).map((document, index) => {
								const docDisplayName = getDocDisplayName(document);

								return (
									<div className={classes.documentContainer} key={`doc_${index}`}>
										<FileDownload
											url={`${env.backendUrl}Account/Document/${document.Download}`}
											fileName={docDisplayName}
											classes={{
												progress: classes.downloadProgress,
												rootLoading: classes.downloadRootLoading,
											}}
											showButtonTitleWhenLoading
											buttonTitle={
												<div className={classes.documentItem}>
													<div>
														{toUsaDateFormat(document.Date)}
													</div>
													<SvgIcon icon="DocPdf" />
													<div className={classes.documentText}>
														{docDisplayName}
													</div>
													{isNumber(document.Size) &&
														<div>
															{toUsaSizeFormat(document.Size * 1000)}
														</div>
													}
												</div>
											}
										/>
									</div>
								);
							})}
						</ExpansionPanel>
					</ProgressContainer>
					<div className={classes.contentItem}>
						<div id="disclosures" className={classes.disclosures}>
							<A target="_blank" href={env.disclosuresUrl}>
								Disclosures
							</A>
						</div>
					</div>
					<div className={classes.endItem} />
				</div>
			</React.Fragment>
		);
	}
}

DocumentsAndDisclosures.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	currentAccountData: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};


export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(DocumentsAndDisclosures);
