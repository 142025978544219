import React from 'react';

const Svg = (
	<g >
		<circle cx="4" cy="4" r="4" fill="#1a96ff" opacity=".9" />
	</g>

);

export default Svg;
