import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { acatDeposit } from '@redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import {
	FormSelect,
	FormInput,
	Divider,
	Button,
	LimeLogo,
	SuccessMessage,
	SvgIcon,
} from '@components';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
// import j2tlogo from './j2t.png';


const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '10px',
	},
	inputSelectItem: {
		marginTop: '10px',
		paddingLeft: '25px',
	},
	item: {
		marginTop: '30px',
		padding: '0 24px',
	},
	divider: {
		marginTop: '30px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	selectItem: {
		whiteSpace: 'initial',
	},
});


const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		TradeCode: ownProps.tradeAccount.tradeCode,
		ClearerFirm: ownProps.tradeAccount.clearerFirm,
		TransferInstructions: ownProps.closeAccountMode ? 'All' : null,
	},
});


class AcatOutgoingForm extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.TradeCode) {
			errors.TradeCode = 'required';
		}

		if (!values.ClearerFirm) {
			errors.ClearerFirm = 'required';
		}

		if (!values.DestinationFirmName) {
			errors.DestinationFirmName = 'required';
		}

		if (!values.TransferInstructions) {
			errors.TransferInstructions = 'required';
		}

		if (!values.TransferReason) {
			errors.TransferReason = 'required';
		}

		return errors;
	}

	render() {
		const {
			classes,
			onClose,
			invalid,
			submitting,
			tradeAccount,
			closeAccountMode,
			handleSubmit,
			submitSucceeded,
		} = this.props;

		return (
			<div>
				{
					submitSucceeded ? <SuccessMessage
						onClick={onClose}
						text={<p>Thank you for your time!<br />You will receive an email shortly with further instructions.</p>}
						buttonText={closeAccountMode ? 'Next' : 'Back To Account'}
					/> :
						(
							<form
								onSubmit={handleSubmit(acatDeposit.actions.acatOutgoingFormSubmitHandler)}
								className={classes.root}
							>
								<div className={classes.accountContainer}>
									<div className={classes.row}>
										<div className={classes.logo}>
											<LimeLogo />
										</div>
										<Field component={FormSelect} name="TradeCode" label="From Trade Account" disabled>
											<MenuItem value={tradeAccount && tradeAccount.tradeCode}>
												<div className={classes.inputContainer}>
													<div className={classes.account}>{tradeAccount && tradeAccount.tradeCode}</div>
												</div>
											</MenuItem>
										</Field>
									</div>
								</div>
								<div className={classes.divider}>
									<Divider />
								</div>
								<div className={classes.inputItem}>
									<Field
										name="DestinationFirmName"
										type="text"
										component={FormInput}
										placeholder="Destination Firm Name"
									/>
								</div>
								<div className={classes.inputSelectItem}>
									<Field
										component={FormSelect}
										name="TransferInstructions"
										label="Transfer Instructions"
										disabled={closeAccountMode}
									>
										<MenuItem value="All" className={classes.selectItem}>
											<div className={classes.inputContainer}>
												<div className={classes.account}>
													Transfer all my assets (money markets may be liquidated)
												</div>
											</div>
										</MenuItem>
										<MenuItem value="Parts" className={classes.selectItem}>
											<div className={classes.inputContainer}>
												<div className={classes.account}>
													Transfer only part of my account
												</div>
											</div>
										</MenuItem>
									</Field>
								</div>
								<div className={classes.inputItem}>
									<Field
										name="TransferReason"
										type="text"
										component={FormInput}
										placeholder="Transfer Reason"
										additionalText="Please specify the reason for the transfer"
									/>
								</div>
								<div className={classes.button}>
									<Button
										fullWidth
										type="submit"
										variant="contained"
										color="primary"
										disabled={invalid || submitting}
									>
										{submitting ?
											<CircularProgress
												style={{ color: '#1a96ff' }}
												size={18}
											/>
											: 'Transfer'
										}
									</Button>
								</div>
							</form>
						)
				}
			</div>);
	}
}

AcatOutgoingForm.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	tradeAccount: PropTypes.object.isRequired,
	closeAccountMode: PropTypes.bool,
	handleSubmit: PropTypes.func.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
};

AcatOutgoingForm.defaultProps = {
	closeAccountMode: false,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: 'acatOutgoingForm',
		validate: AcatOutgoingForm.validate,
	}),
)(AcatOutgoingForm);
