import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Button,
	Modal,
	ProgressContainer,
	SuccessMessage,
	ErrorMessage,
	Checkbox,
	FileDownload,
	FormInput,
} from '@components';
import { ModalPopups, ValidationMessageMode, AccountClearers } from '@enums';
import { userAccounts, snackbar } from '@redux';
import { env } from '@config';
import { formatDateAsTimeZone } from '@global';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';

const styles = theme => ({
	radioGroup: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	progressContainer: {
		marginTop: '15px',
	},
	accountChoose: {
		...theme.typography.style.title,
		textAlign: 'center',
		marginBottom: '10px',
	},
	inputItem: {
		'& div > div': {
			...theme.typography.style.body,
			background: theme.palette.color.primary.attestationBackground,
			paddingTop: '20px',
		},
	},
	buttonContinue: {
		marginTop: '10px',
	},
	otherAccountText: {
		textAlign: 'center',
	},
	circularProgress: {
		color: theme.typography.color.blue,
	},
});

const AccountType = {
	INDIVIDUAL: 'Individual',
	OTHER: 'Other',
};

const OPEN_SECOND_ACCOUNT_FORM = 'OpenSecondAccountForm';
const formSelector = formValueSelector(OPEN_SECOND_ACCOUNT_FORM);

const mapStateToProps = (state, props) => ({
	reason: formSelector(state, 'reason'),
	secondAccountAgreement: userAccounts.selectors.secondAccountAgreement(state),
	accountsLoading: userAccounts.selectors.loading(state),
	showAllAccountTypes: userAccounts.selectors.accounts(state).some(acc => !acc.IsClosed
		&& acc.ClearerFirm === AccountClearers.VISION
		&& acc.AccountType === AccountType.INDIVIDUAL),
});

const mapDispatchToProps = (dispatch, props) => ({
	actions: {
		secondAccountAgreement: (payload) => {
			dispatch(userAccounts.actions.secondAccountVisionAgreement.request(payload));
		},
		clearSecondAccountAgreementData: () => {
			dispatch(userAccounts.actions.secondAccountVisionAgreement.failure());
		},
		showError: message => dispatch(snackbar.actions.showErrorMessage({ text: message })),
		openAdditionalAccount: payload => dispatch(userAccounts.actions.openAdditionalAccount.request(payload)),
	},
});

class OpenSecondAccountModal extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.reason) {
			errors.reason = 'Cannot';
		}
	}

	static validateReason = (value) => {
		const maxCharsNumber = 150;
		const regExpReason = /^[A-Za-z0-9\s:;/)(\-.,"']+$/;

		if (!value) {
			return 'Cannot be empty';
		}
		if (value && (value.length > maxCharsNumber || !regExpReason.test(value))) {
			return `Up to ${maxCharsNumber} characters allowed; latin characters and digits only,
				no special characters except for: . , - / " ' : ; () space`;
		}

		return undefined;
	}

	constructor(props) {
		super(props);

		this.state = {
			selectedAccountType: !props.showAllAccountTypes ? AccountType.OTHER : AccountType.INDIVIDUAL,
			agreementLoaded: false,
			agreed: false,
		};
	}

	componentDidMount() {
		this.props.actions.clearSecondAccountAgreementData();
	}

	// Пересчет selected account type-а при открытии окна по прямой ссылыке
	componentDidUpdate(prevProps) {
		if (prevProps.accountsLoading !== this.props.accountsLoading) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ selectedAccountType: !this.props.showAllAccountTypes ? AccountType.OTHER : AccountType.INDIVIDUAL });
		}
	}

	handleDownloadAgreementClicked = () => {
		// Сброс чекбокса потому что он "взводится", когда клиент нажимает на ссылку, которая является частью чекбокса
		this.setState({ agreed: false });
	};

	handleAgreementDownloadSuccess = () => {
		this.setState({ agreementLoaded: true });
	}

	handleAgreementDownloadError = (data) => {
		const { actions } = this.props;
		this.setState({ agreementLoaded: false });
		actions.showError((data && data.Errors && data.Errors[0].Message) || data);
	}

	handleSecondAccountAgreementChanged = (event, value) => {
		this.setState({ agreed: value });
	}

	handleAccountTypeChange = (e) => {
		this.setState({
			selectedAccountType: e.target.value,
		});
	}

	handleContinueClick = () => {
		const {
			actions,
			reason,
		} = this.props;

		actions.secondAccountAgreement({ reason });
	}

	generateAgreementFileName = () => `Second_Account_Request_Vision_unsigned_${formatDateAsTimeZone(new Date(), 'YYYYMMDDHHmmss')}`;

	choseAccountTypeBlock = () => {
		const {
			classes,
			showAllAccountTypes,
		} = this.props;

		return (
			<React.Fragment>
				<div className={classes.accountChoose}>Choose account type</div>
				<RadioGroup
					className={classes.radioGroup}
					value={this.state.selectedAccountType}
					onChange={this.handleAccountTypeChange}
					row
				>
					<FormControlLabel
						value={AccountType.INDIVIDUAL}
						control={<Radio color="primary" />}
						disabled={!showAllAccountTypes}
						label={AccountType.INDIVIDUAL}
					/>
					<FormControlLabel
						value={AccountType.OTHER}
						control={<Radio color="primary" />}
						disabled={!showAllAccountTypes}
						label={AccountType.OTHER}
					/>
				</RadioGroup>
			</React.Fragment>
		);
	}

	otherBlock = () => {
		const {
			onClose,
			classes,
		} = this.props;
		return (
			<div>
				<div className={classes.otherAccountText}>
					To open this type of an account, please, contact our customer support at &nbsp;
					<a href="mailto:support@lime.co">support@lime.co</a>
				</div>
				<Button
					className={classes.buttonContinue}
					onClick={onClose}
					fullWidth
					variant="contained"
					color="primary"
				>
					OK
				</Button>
			</div>
		);
	}

	individualBlock = () => {
		const {
			classes,
			invalid,
			submitting,
			pristine,
			handleSubmit,
			secondAccountAgreement,
		} = this.props;

		return (
			<form onSubmit={handleSubmit(userAccounts.actions.openAdditionalAccount)}>
				<ProgressContainer
					className={classes.progressContainer}
					submitting={secondAccountAgreement.submitting}
					disableCheckChildren
				>
					<div className={classes.inputItem}>
						<Field
							component={FormInput}
							name="reason"
							placeholder="Specify the reason for opening the new account"
							validate={OpenSecondAccountModal.validateReason}
							validationMessageMode={ValidationMessageMode.AFTER_ADDITIONAL}
							multiline
							disabled={secondAccountAgreement.created}
						/>
					</div>
					{!secondAccountAgreement.created &&
						<Button
							className={classes.buttonContinue}
							onClick={this.handleContinueClick}
							disabled={invalid || pristine || secondAccountAgreement.created}
							fullWidth
							variant="contained"
							color="primary"
						>
							Continue
						</Button>
					}
				</ProgressContainer>
				{secondAccountAgreement.created &&
					<div>
						<Checkbox
							label={
								<div>
									I have read, reviewed and agreed to &nbsp;
									<FileDownload
										url={`${env.backendUrl}useraccounts/api_getSecondAccountVisionAgreement`}
										buttonTitle="Second Account Request"
										onClick={this.handleDownloadAgreementClicked}
										fileName={this.generateAgreementFileName()}
										onError={this.handleAgreementDownloadError}
										onSuccess={this.handleAgreementDownloadSuccess}
										clickBubblingStopped
									/>
								</div>
							}
							checked={this.state.agreed}
							onChange={this.handleSecondAccountAgreementChanged}
							disabled={!this.state.agreementLoaded}
						/>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={!this.state.agreed}
						>
							{submitting ?
								<CircularProgress
									className={classes.circularProgress}
									size={18}
								/>
								: 'Submit'
							}
						</Button>
					</div>
				}
			</form>
		);
	}

	render() {
		const {
			onClose,
			submitting,
			accountsLoading,
			submitFailed,
			submitSucceeded,
		} = this.props;

		return (
			<Modal
				title="Second account agreement"
				onClose={onClose}
				bgColor="#f9fcfd"
				textColor="#345464"
				className={`QA-${ModalPopups.SECOND_ACCOUNT_AGREEMENT}`}
			>
				<ProgressContainer submitting={accountsLoading || submitting} disableCheckChildren>
					{submitSucceeded &&
						<SuccessMessage onClick={onClose} text="Your request to open additional account is processed." />
					}
					{submitFailed &&
						<ErrorMessage
							onClick={onClose}
							text="Cannot open additional account. Please try again later."
						/>
					}
					{!submitSucceeded && !submitFailed &&
						this.choseAccountTypeBlock()
					}
					{/** IF OTHER */
						!submitSucceeded && !submitFailed &&
						this.state.selectedAccountType === AccountType.OTHER &&
						this.otherBlock()
					}
					{/** IF INDIVIDUAL */
						!submitSucceeded && !submitFailed &&
						this.state.selectedAccountType === AccountType.INDIVIDUAL &&
						this.individualBlock()
					}
				</ProgressContainer>
			</Modal>
		);
	}
}

OpenSecondAccountModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	actions: PropTypes.object.isRequired,
	showAllAccountTypes: PropTypes.bool.isRequired,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	submitFailed: PropTypes.bool.isRequired,
	submitSucceeded: PropTypes.bool.isRequired,
	pristine: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	secondAccountAgreement: PropTypes.object.isRequired,
	accountsLoading: PropTypes.bool.isRequired,
	reason: PropTypes.string,
};

OpenSecondAccountModal.defaultProps = {
	reason: '',
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: OPEN_SECOND_ACCOUNT_FORM,
		validate: OpenSecondAccountModal.validate,
		enableReinitialize: true,
	}),
)(OpenSecondAccountModal);
