import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { acatDeposit, userAccounts } from '@redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	A,
	FormSelect,
	Divider,
	Button,
	FileInput,
	LimeLogo,
	SuccessMessage,
	SvgIcon,
} from '@components';
import { IraTypes } from '@enums';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';


const ACAT_FUND_IRA_FORM = 'acatFundForm';
const formSelector = formValueSelector(ACAT_FUND_IRA_FORM);

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const accountInfo = userAccounts.selectors.accountInfo(state);
	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
			Attachments: [],
		},
		attachmentsValue: formSelector(state, 'Attachments'),
		iraType: accountInfo.IraType,
	};
};


const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	divider: {
		marginTop: '30px',
	},
	accountContainer: {
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
	attachFileText: {
		...theme.typography.style.body,
		color: theme.typography.color.primary,
		marginTop: '24px',
	},
	fileUploader: {
		marginTop: '16px',
	},
});

class AcatFundForm extends React.Component {
	static validate(values) {
		const errors = {};

		if (!values.Attachments || values.Attachments.length === 0) {
			errors.Attachments = 'required';
		}
		return errors;
	}

	onFilesUpload = files => this.props.change('Attachments', files);

	render() {
		const {
			attachmentsValue,
			classes,
			actions,
			onClose,
			tradeAccount,
			handleSubmit,
			submitSucceeded,
			iraType,
			...props
		} = this.props;

		const noAttachments = (!attachmentsValue || attachmentsValue.length === 0);

		return (
			<div>
				{
					submitSucceeded ? <SuccessMessage
						onClick={onClose}
						text="You've just submitted Transfer Shares form. Our support team will process it shortly."
						buttonText="Back To Platform"
					/> :
						(
							<form
								onSubmit={handleSubmit(acatDeposit.actions.acatDepositIraForm)}
								className={classes.root}
							>
								<div className={classes.accountContainer}>
									<div className={classes.row}>
										<div className={classes.logo}>
											<LimeLogo />
										</div>
										<Field component={FormSelect} name="TradeCode" label="To the Account" disabled>
											<MenuItem value={tradeAccount && tradeAccount.tradeCode}>
												<div className={classes.inputContainer}>
													<div className={classes.account}>{tradeAccount && tradeAccount.tradeCode}</div>
												</div>
											</MenuItem>
										</Field>
									</div>
								</div>
								<div className={classes.divider}>
									<Divider />
								</div>
								{iraType === IraTypes.STRATA &&
									<div className={classes.attachFileText}>
										Fill and submit the ACAT form (<A target="_blank" href="/static/vision/ira/STRATA_Trust_IRA_Transfer_Request.pdf">STRATA_Trust_IRA_Transfer_Request.pdf</A>) along with a copy of your recent IRA statement from contra firm. Please note ACAT transfer is available from another IRA account only.
									</div>
								}
								{iraType === IraTypes.EQUITY_TRUST &&
									<div className={classes.attachFileText}>
										Fill and submit the ACAT form (<A target="_blank" href="/static/vision/ira/EQUITY IRA Transfer Form.pdf">EQUITY IRA Transfer Form.pdf</A>) along with a copy of your recent IRA statement from contra firm. Please note ACAT transfer is available from another IRA account only.
									</div>
								}
								<div className={classes.fileUploader}>
									<FileInput
										onFilesUpdate={files => this.onFilesUpload(files)}
									/>
								</div>
								<div className={classes.button}>
									<Button
										fullWidth
										type="submit"
										variant="contained"
										color="primary"
										disabled={props.invalid || props.submitting || noAttachments}
									>
										{props.submitting ?
											<CircularProgress
												style={{ color: '#1a96ff' }}
												size={18}
											/>
											: 'Transfer'
										}
									</Button>
								</div>
							</form>
						)
				}
			</div>);
	}
}

AcatFundForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	tradeAccount: PropTypes.shape({
		tradeCode: PropTypes.string.isRequired,
		clearerFirm: PropTypes.string.isRequired,
	}).isRequired,
	iraType: PropTypes.oneOf(Object.values(IraTypes)).isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
	reduxForm({
		form: ACAT_FUND_IRA_FORM,
		validate: AcatFundForm.validate,
	}),
)(AcatFundForm);
