import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
	FormCheckbox,
	FormRadioGroup,
	Radio,
} from '@components';

const useStyles = makeStyles(theme => createStyles({
	editValue: {},
	editable: {},
	preFieldLabel: {
		margin: '10px 24px 0px 0px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	withLeftMarginField: {
		marginLeft: '-24px',
	},
	noLeftMarginField: {
		marginLeft: '0px',
	},
}));

const extractPropsClasses = ({
	editValue, editable, preFieldLabel, withLeftMarginField, noLeftMarginField,
}) => ({
	editValue, editable, preFieldLabel, withLeftMarginField, noLeftMarginField,
});

const OptionQuestionType = PropTypes.shape({
	Name: PropTypes.string.isRequired,
	QuestionText: PropTypes.string.isRequired,
	Options: PropTypes.arrayOf(PropTypes.string).isRequired,
});

const CurrentAccountType = PropTypes.shape({
	AccountName: PropTypes.string.isRequired,
	TradeCode: PropTypes.string.isRequired,
});

const RadioQuestion = ({
	classes: _classes,
	form,
	question,
	account,
}) => {
	const classes = useStyles({ classes: extractPropsClasses(_classes) });
	const name = `${question.Name}${account.TradeCode}`;
	return (
		<React.Fragment>
			<div className={classes.editable}>
				<div className={classes.editValue}>
					<p className={classes.preFieldLabel}>
						{question.QuestionText}
					</p>
					<div className={classes.noLeftMarginField}>
						<Field
							name={name}
							orientation="vertical"
							component={FormRadioGroup}
							validationMessageMode="withErrorMessage"
						>
							{question.Options.map((option, index) =>
								(<Radio key={index} value={option} label={option} />))
							}
						</Field>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

RadioQuestion.propTypes = {
	classes: PropTypes.object.isRequired,
	form: PropTypes.string.isRequired,
	question: OptionQuestionType.isRequired,
	account: CurrentAccountType.isRequired,
};

const CheckBoxQuestion = ({
	classes: _classes,
	form,
	question,
	account,
}) => {
	const classes = useStyles({ classes: extractPropsClasses(_classes) });
	return (
		<React.Fragment>
			<div className={classes.editable}>
				<div className={classes.editValue}>
					<p className={classes.preFieldLabel}>
						{question.QuestionText}
					</p>
					{question.Options.map((option, index)=>{
						const name = `${question.Name}${account.TradeCode}[${index}]`;
						return ( 
							<div className={classes.noLeftMarginField} key={index}>
					
								<Field
									name={name}
									component={FormCheckbox}
									label={option}
									format={(value) => (!!value)}
									parse={(value) => (value ? option : '')}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
};
CheckBoxQuestion.propTypes = {
	classes: PropTypes.object.isRequired,
	form: PropTypes.string.isRequired,
	question: OptionQuestionType.isRequired,
	account: CurrentAccountType.isRequired,
};

const AccountInformationQuestion = (props) => {
	
	return (
		<React.Fragment>
			<RadioQuestion
				{...props}
				question={{
					Name: 'ExpectedAnnualTotalOfDeposits',
					QuestionText: 'What is the expected annual total of deposits you plan to make?',
					Options: [
						'0-$10,000',
						'$10,001 - $25,000',
						'$25,001 - $50,000',
						'$50,001 - $100,000',
						'$100,001 - $200,000',
						'$200,001 - $500,000',
						'$500,001+',
					],
				}}
			/>
			<RadioQuestion
				{...props}
				question={{
					Name: 'ExpectedAnnualTotalOfWithdrawals',
					QuestionText: 'What is the expected annual total of withdrawals you plan to make?',
					Options: [
						'0-$10,000',
						'$10,001 - $25,000',
						'$25,001 - $50,000',
						'$50,001 - $100,000',
						'$100,001 - $200,000',
						'$200,001 - $500,000',
						'$500,001+',
					],
				}}
			/>
			<CheckBoxQuestion
				{...props}
				question={{
					Name: 'ProductsExpectedToBeTraded',
					QuestionText: 'Which of these products do you expect to trade?',
					Options: ['Options', 'Stocks below $5'],
				}}
			/>
		</React.Fragment>
	);
};

AccountInformationQuestion.propTypes = {
	classes: PropTypes.object.isRequired,
	form: PropTypes.string.isRequired,
	account: CurrentAccountType.isRequired,
	
};

export default AccountInformationQuestion;
