import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm,	Field, formValueSelector } from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	FileInput,
	FormDatePicker,
	FormInput,
	Button,
	R,
} from '@components';
import { validateSecureString, parseMomentDate } from '@global';
import { userAccounts } from '@redux';
import { RequireConfirmationCodeItem } from '@shared';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	divider: {
		marginTop: '30px',
	},
	fileUploader: {
		marginTop: '16px',
	},
	button: {
		...theme.typography.style.stickyButton,
	},
});


const CY_NON_TRADING_ORDER_IDENTITY_FORM_ID = 'CYNonTradingOrderIdentityForm';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const formSelector = formValueSelector(CY_NON_TRADING_ORDER_IDENTITY_FORM_ID);
	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
		attachmentsPassportFiles: formSelector(state, 'PassportFiles'),
		attachmentsCertificateFiles: formSelector(state, 'CertificateFiles'),
	};
};

class CYNonTradingOrderIdentityForm extends React.Component {

	static validate(formvalues, props) {

		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if ((!values.PassportFiles || values.PassportFiles.length === 0)
			&& (!values.CertificateFiles || values.CertificateFiles.length === 0)) {
			errors.PassportFiles = 'required';
			errors.CertificateFiles = 'required';
		}

		if (!values.IssuedDate) {
			errors.IssuedDate = 'required';
		}

		if (!values.ConfirmationCode) {
			errors.ConfirmationCode = 'required';
		}

		return errors;
	}


	onFilesUpload = (field, files) => this.props.change(field, files);

	render() {
		const {
			attachmentsPassportFiles,
			attachmentsCertificateFiles,
			classes,
			handleSubmit,
			theme,
			...props
		} = this.props;

		const noAttachments = ((!attachmentsPassportFiles || attachmentsPassportFiles.length === 0)
							&& (!attachmentsCertificateFiles || attachmentsCertificateFiles.length === 0));

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<p>
					<R id="common_nontrading_order_identity_passport_files_copy" defaultValue="Copy of pages of ID document" />
				</p>
				<div className={classes.fileUploader}>
					<FileInput
						onFilesUpdate={files => this.onFilesUpload('PassportFiles', files)}
					/>
				</div>
				<p>
					<R id="common_nontrading_order_identity_certificate_files_copy" defaultValue="Copy of certificate of change" />
				</p>
				<div className={classes.fileUploader}>
					<FileInput
						onFilesUpdate={files => this.onFilesUpload('CertificateFiles', files)}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="FullName"
						placeholder={<R id="shared_fullname_copy" defaultValue="Full name" />}
						label="Full name"
						multiline
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Serial"
						placeholder={<R id="common_order_identity_passport_serial_copy" defaultValue="Series" />}
						label="Series"
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Number"
						placeholder={<R id="common_number_Identification_document" defaultValue="Number" />}
						label="Number"
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormDatePicker}
						name="IssuedDate"
						label={<R id="common_passport_getdate_copy" defaultValue="Issued" />}
						parse={parseMomentDate}
						onFocus={() => undefined}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="SubdivisionCode"
						placeholder={<R id="common_passport_subdivision_code_copy" defaultValue="Subdivision code" />}
						label="Subdivision code"
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="IssuedBy"
						placeholder={<R id="common_passport_issued_by_copy" defaultValue="Issuing authority" />}
						label="Issuing authority"
						multiline
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Comment"
						placeholder={<R id="shared_comment" defaultValue="Comment" />}
						label="Comment"
						multiline
						validate={[validateSecureString]}
					/>
				</div>
				<RequireConfirmationCodeItem
					customClasses={classes}
					name="ConfirmationCode"
					buttonTitle={<R id="common_send_button_order" defaultValue="Send" />}
					reason="NontradingOrderApply"
					disabled={props.invalid || props.submitting || noAttachments}
				>

					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.typography.color.white }}
								size={18}
							/>
							: <R id="common_confirm_order" defaultValue="Confirm" />
						}
					</Button>

				</RequireConfirmationCodeItem>

			</form>
		);
	}
}

CYNonTradingOrderIdentityForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		form: CY_NON_TRADING_ORDER_IDENTITY_FORM_ID,
		validate: CYNonTradingOrderIdentityForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			if (errors._error && props.onFail) {
				props.onFail();
			}
		},
	}),
)(CYNonTradingOrderIdentityForm);
