import { useDispatch, useSelector } from 'react-redux';
import { linkedAccounts as snackbar, userAccounts } from '@redux';
import { cabinetLog, captureException } from '@global';
import { useState, useEffect, useRef, useMemo } from 'react';

export const usePaymentGateWidgetHook = (id) => {
	const dispatch = useDispatch();
	const accountInfo = useSelector(state => userAccounts.selectors.accountInfo(state));
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState(false);

	const fundInfo = useSelector(userAccounts.selectors.fundingInfo);

	const generateId = () => Math.random().toString(36).substr(2, 5);

	const randomId = useMemo(generateId, []);
	const paymentGateWidgetId = useRef(id || randomId);
	useEffect(() => {
		if (accountInfo.FundingInfo && accountInfo.FundingInfo.WidgetJsScriptUrl) {

			const script = document.createElement('script');
			script.src = accountInfo.FundingInfo.WidgetJsScriptUrl;
			script.async = true;
			script.addEventListener('load', () => {
				cabinetLog('payment gate widget script has been loaded');
				const configuration = JSON.parse(accountInfo.FundingInfo.WidgetJsConfig);
				PaymentGateWidget(paymentGateWidgetId.current, configuration);
				setLoading(false);
				setSuccess(true);
			});
			script.addEventListener('error', () => {
				console.error('error occured when try load payment gate widget = ', accountInfo.FundingInfo.WidgetJsScriptUrl);
				captureException('Payment gate load error', `error occured when try load payment gate widget = ${accountInfo.FundingInfo.WidgetJsScriptUrl}`);
				dispatch(snackbar.actions.showErrorMessage({ text: 'Payment gate load error' }));
				setLoading(false);
			});
			document.body.appendChild(script);
			cabinetLog('payment gate widget script load');

			return () => {
				cabinetLog('payment gate widget script unload');
				document.body.removeChild(script);
			};
		}
		return () => { };
	}, [fundInfo]);
	return [paymentGateWidgetId.current, loading, success];
};
