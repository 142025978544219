import { env } from '@config';
import { cabinetLog, globalState } from '@global';
import * as signalR from '@microsoft/signalr';
import { auth, reports, signalR as signalRRedux, userAccounts } from '@redux';
import { channel } from 'redux-saga';
import {
	fork, put, select, take, takeEvery,
} from 'redux-saga/effects';

import actions from './actions';

const signalRChannel = channel();

let connection;

function* reconnectSignalRSaga() {
	const authWidget = yield select(auth.selectors.authWidget);
	const isLoggedIn = authWidget && authWidget.isLoggedIn();

	if (isLoggedIn && connection.state === signalR.HubConnectionState.Disconnected) {
		cabinetLog('reconnectSignalR');
		connection.start().catch(() => setTimeout(() => signalRChannel.put(signalRRedux.actions.reconnectSignalR()), 5000));
	}
}

// Watchers
function* connectSignalRSaga() {
	const options = { headers: { Authorization: globalState.localToken } };

	connection = new signalR.HubConnectionBuilder()
		.configureLogging(signalR.LogLevel.None)
		.withUrl(env.signalRUrl, options)
		.build();

	connection.on('ReportReady', (message) => {
		// update Reports
		cabinetLog(`receive method="ReportReady", message="${message}"`);
		signalRChannel.put(reports.actions.getBrokerReports.request());
	});
	connection.onclose((err) => setTimeout(() => signalRChannel.put(signalRRedux.actions.reconnectSignalR()), 5000));
	connection.start().catch((err) => setTimeout(() => signalRChannel.put(signalRRedux.actions.reconnectSignalR()), 5000));
}

function disconnectSignalRSaga() {
	if (connection) {
		cabinetLog('disconnectSignalRSaga');
		connection.stop();
	}
}

function* watchConnectSignalR() {
	yield takeEvery(actions.CONNECT_SIGNAL_R, connectSignalRSaga);
}

function* watchDisconnectSignalR() {
	yield takeEvery(actions.DISCONNECT_SIGNAL_R, disconnectSignalRSaga);
}
function* watchReconnectSignalR() {
	yield takeEvery(actions.RECONNECT_SIGNAL_R, reconnectSignalRSaga);
}

function* watchSignalRChannel() {
	while (true) {
		const action = yield take(signalRChannel);
		yield put(action);
	}
}

// Root saga
const rootSaga = [
	fork(watchConnectSignalR),
	fork(watchDisconnectSignalR),
	fork(watchSignalRChannel),
	fork(watchReconnectSignalR),
];

export default {
	rootSaga,
};
