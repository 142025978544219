
// OBSOLETE: touched should be replaced by visited 
// because touched disables validation when typing, 
// and use validation when losing focus only
export const mapError = ({
	meta: { touched, error, warning } = {},
	input,
	...props
}) =>
	(touched && (error || warning)
		? {
			...props,
			...input,
			error: Boolean(error || warning),
			helpertext: error || warning,
		}
		: { ...input, ...props });

// visited enables validation when typing
export const mapErrorAfterInit = ({
	meta: { visited, error, warning } = {},
	input,
	...props
}) =>
	(visited && (error || warning)
		? {
			...props,
			...input,
			error: Boolean(error || warning),
			helpertext: error || warning,
		}
		: { ...input, ...props });
		
export const isStateLess = Component => !(Component.prototype && Component.prototype.render);

