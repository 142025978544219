import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
	userAccounts,
	depositFunds,
} from '@redux';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import {
	FormSelect,
	BankLogo,
	FormInputNumber,
	Button,
	LimeLogo,
	SuccessMessage,
	SvgIcon,
} from '@components';
import { toUsaMoneyFormat } from '@global';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import LongArrow from './arrow.svg';
// import j2tlogo from './j2t.png';

const styles = theme => ({
	row: {
		display: 'flex',
	},
	amount: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	amountInput: {
		paddingLeft: '70px',
	},
	bankAccountContainer: {},
	accountContainer: {
		marginTop: '-15px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	arrow: {
		paddingLeft: '24px',
		height: '36px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.typography.style.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	accountBalance: {
		width: '100%',
		textAlign: 'right',
	},
	button: {
		marginTop: '45px',
	},
});

const DEPOSIT_ACH_FUNDS_FORM = 'depositAchFundsForm';
const formSelector = formValueSelector(DEPOSIT_ACH_FUNDS_FORM);

const mapStateToProps = (state, props) => ({
	initialValues: {
		bankAccountNumber: props.bankAccount.Number,
		tradeCode: props.tradeAccount.tradeCode,
		clearerFirm: props.tradeAccount.clearerFirm,
	},
	linkedAccounts: userAccounts.selectors.linkedAccounts(state),
	selectedBankAccountNumber: formSelector(state, 'bankAccountNumber'),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		hideDepositFundsModal: () => dispatch(depositFunds.actions.hideDepositFundsModal()),
	},
});

class DepositFundsForm extends React.Component {
	static validate(values) {
		const errors = {};
		if (!values.bankAccountNumber) {
			errors.bankAccountNumber = 'required';
		}
		if (!values.amount) {
			errors.amount = 'required';
		}
		return errors;
	}
	render() {
		const {
			classes,
			actions,
			tradeAccount,
			linkedAccounts,
			handleSubmit,
			selectedBankAccountNumber,
			submitSucceeded,
			...props
		} = this.props;

		const linkedAchAccounts = linkedAccounts.filter(it => it.Type === 'ACH');
		const selectedBankAccount = linkedAchAccounts.find(it => it.Number === selectedBankAccountNumber);

		return submitSucceeded ? <SuccessMessage
			onClick={actions.hideDepositFundsModal}
			text="Banking transaction completed"
			buttonText="Thank you"
		/> :
			(
				<form onSubmit={handleSubmit(depositFunds.actions.depositAchFundsForm)}>
					<div className={classes.bankAccountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								{selectedBankAccount &&
									<BankLogo
										bankAccount={selectedBankAccount}
									/>
								}
							</div>
							<Field component={FormSelect} name="bankAccountNumber" label="Bank Account" disabled>
								{linkedAchAccounts &&
								(linkedAchAccounts.length > 0) &&
								linkedAchAccounts.map((linkedAccount, index) => (
									<MenuItem value={linkedAccount.Number} key={index}>
										<div className={classes.inputContainer}>
											<div className={classes.account}>*{linkedAccount.displayNumber}</div>
											{linkedAccount.Balance &&
												<div className={classes.accountBalance}>
													{toUsaMoneyFormat(linkedAccount.Balance)}
												</div>
											}
										</div>
									</MenuItem>
								))}
							</Field>
						</div>
					</div>
					<div className={classes.arrow}>
						{selectedBankAccount &&
							<img alt="Long Arrow Down" src={LongArrow} />
						}
					</div>
					<div className={classes.accountContainer}>
						<div className={classes.row}>
							<div className={classes.logo}>
								<LimeLogo />
							</div>
							<Field component={FormSelect} name="tradeCode" label="Trade Account" disabled>
								<MenuItem value={tradeAccount.tradeCode}>
									<div className={classes.inputContainer}>
										<div className={classes.account}>{tradeAccount.tradeCode}</div>
									</div>
								</MenuItem>
							</Field>
						</div>
					</div>
					<div className={classes.amount}>
						<Field component={FormInputNumber} name="amount" placeholder="Enter the Amount here" />
					</div>
					<div className={classes.button}>
						<Button
							fullWidth
							type="submit"
							variant="contained"
							color="primary"
							disabled={props.invalid || props.submitting}
						>
							{props.submitting ?
								<CircularProgress
									style={{ color: '#1a96ff' }}
									size={18}
								/>
								: 'Deposit'
							}
						</Button>
					</div>
				</form>
			);
	}
}

DepositFundsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	linkedAccounts: PropTypes.array.isRequired,
	bankAccount: PropTypes.object,
	tradeAccount: PropTypes.object,
	selectedBankAccountNumber: PropTypes.string,
	submitSucceeded: PropTypes.bool,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: DEPOSIT_ACH_FUNDS_FORM,
		validate: DepositFundsForm.validate,
	}),
)(DepositFundsForm);
